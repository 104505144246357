import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsubcategoryService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private userdata: UserdataService,
    private http: HttpClient, @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  /**
   * Show all product Subcategory
   */
   public allProductSubcategory(data: any) {
    return this.http.post(this.BaseUrl + "/allproductsubcategory", data);
  }

  /**
   * Show all product Subcategory
   */
   public detailSubcategory(data: any) {
    return this.http.post(this.BaseUrl + "/fetchproductsubcategorybyid", data);
  }
}
