<div class="reg-form m-0 p-2">
  <form [formGroup]="registerForm" (submit)="registerCustomer()"
    class="row text-left mb-2 loginForm d-flex flex-column px-lg-3 px-md-3 px-2">
    <div class="row">
      <div class="col form-group l-size-reg mob-f-border">
        <label for="exampleInputName">Name</label>
        <input type="text" class="form-control border-0" placeholder="Please Enter Name" formControlName="name"
          [readonly]="isSocialRegister" />
        <small class="text-warning" *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
          Field is required.
        </small>
      </div>
      <div class="col form-group mob-f-border pl-0">
        <label for="exampleInputEmail1">Email</label>
        <input type="email" class="form-control border-0" placeholder="Enter email" formControlName="email"
          [readonly]="isSocialRegister" />
        <small class="text-warning" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
          Valid email is required.
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col form-group mob-f-border" *ngIf="!isSocialRegister">
        <label class="" for=" exampleInputPassword">Password</label>
        <div class="input-group mob-p-eye-bg bg-white rounded">
          <input [type]="tooglePassword ? 'text' : 'password'" class=" border-0 form-control" id="exampleInputPassword"
            placeholder="Password" formControlName="password" />
          <button (click)="toggleEye()" class="btn rounded-0 p-0 px-2  mob-p-eye rounded-end shadow-0" type="button"
            id="button-addon2" data-mdb-ripple-color="dark">
            <i class="material-icons-round center-align d-flex align-middle eye-size1">
              {{ tooglePassword ? "visibility" : "visibility_off" }}
            </i>
          </button>
        </div>
        <small class="text-warning" *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
          Your password must be of at least 8 characters, must contain a capital letter, a small letter, a number, a
          symbol and should not contain space
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5 form-group  border-0">
        <label for="exampleInputCountry">Country Code</label>
        <ng-select [searchFn]="customSearchFn" formControlName="country_code"
          class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
          bindValue="phone_code">
          <ng-template ng-option-tmp let-item="item">
            <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
            item.name
            }}&nbsp;&nbsp;(+{{ item.phone_code }})
          </ng-template>
        </ng-select>
        <small class="text-warning" *ngIf="f.country_code.invalid && (f.country_code.dirty || f.country_code.touched)">
          This field is required.
        </small>
      </div>

      <div class="col-md-7 pl-0 pl-mob form-group mob-f-border">
        <label for="exampleInputContact">Contact Number</label>
        <input type="text" class="form-control border-0" placeholder="Enter Contact Number" formControlName="phone" />
        <!-- <select class="col-md-4 form-control m-lable-size" formControlName="country_code">
          <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
            {{ country.emoji }}&nbsp;&nbsp;{{
            country.name
            }}&nbsp;&nbsp;(+{{ country.phone_code }})
          </option>
        </select> -->
        <small class="text-warning" *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)">
          Valid contact number is required.
        </small>
      </div>
    </div>
    <div class="col form-check d-flex align-items-center mob-f-border">
      <input style="height: 1rem; width: 1rem" type="checkbox" class="form-check-input" id="exampleCheck1"
        formControlName="is_terms_accepted" />
      <label class="form-check-label text-capitalize lh-1" for="exampleCheck1">
        <span class=" form-check-label txt-t-color privacy-font" for="exampleCheck1">
          I've read and accept the
          <a class="text-light txt-t-color" href="https://astroriver.com/terms-and-conditions-of-use/"
            target="_blank">Terms &
            Conditions,</a>
          <a href="https://astroriver.com/privacy-policy/" target="_blank" class="text-light">Privacy Policy.</a>
        </span>
      </label>
      <small class="text-white" *ngIf="
        f.is_terms_accepted.invalid &&
        (f.is_terms_accepted.dirty || f.is_terms_accepted.touched)
      ">
        This field is required.
      </small>
    </div>
    <button type="submit" [disabled]="!registerForm.valid"
      class="mt-3 mb-2 m-0 yellow-btn fw-500 btn bg-active  pt-2 rounded  text-body">
      Sign up
    </button>
    <div class="d-flex">
      <div class="col-md-6 px-1">
        <a class="w-100 text-white mt-2  google-btn rounded d-flex" (click)="signUpWithGoogle()" type="button">
          <svg class="p-1 bg-active mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44" height="38"
            viewBox="0 0 48 48" style="fill: #000000">
            <path fill="#FFC107"
              d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z">
            </path>
            <path fill="#FF3D00"
              d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z">
            </path>
            <path fill="#4CAF50"
              d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z">
            </path>
            <path fill="#1976D2"
              d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z">
            </path>
          </svg>
          <span class="small lh-1 flex-grow-1 text-center tgf-size text-capitalize align-self-center mr-2">
            Signup with<br>Google
          </span>
        </a>
      </div>

      <div class="col-md-6 px-1">
        <button type="button" (click)="regFacebook()"
          class="w-100 p-0 m-0 mt-2 mr-1 rounded  border r-btn-facebook d-flex align-items-center text-white">
          <i class="fi fi-facebook"></i><span class="flex-grow-1 pr-1 small tgf-size h-sbtn-txt text-center">Signup
            with<br>
            Facebook</span>
        </button>
      </div>
    </div>
  </form>
</div>