import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { Autoplay, SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-top-astro',
  templateUrl: './top-astro.component.html',
  styleUrls: ['./top-astro.component.css'],
})
export class TopAstroComponent implements OnInit {
  mul = 2;
  BaseUrl: string = '';
  @Input() sectionTitle:any;
  constructor(
    private userdata: UserdataService,
    private openService: OpenApiService,
    private trusturl: DomSanitizer,
    private orderService: OrderServicesService,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  ngOnInit(): void {
    if (!this.userdata.isIndia()) {
      this.mul = 1;
    }
    this.getTopAstro();
  }
  topList = new Array();
  getTopAstro() {
    //console.log('top');
    var data: any = { startpoint: 0 };
    if (this.userdata.getToken().length > 0) {
      data = { customerId: this.userdata.getCustomerId(), startpoint: 0 };
    }
    this.openService.getAstrologersDashboard(data).subscribe(
      (res: any) => {
        this.topList = res.data;
      },
      (err: any) => console.log('top', err)
    );
  }
  checkOnlineOffLine(astro: any) {
    return astro.is_call_active;
  }
  astroConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 3,
    direction: 'horizontal',
    spaceBetween: 10,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
    effect: 'slide',
  };
  astromConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 'auto',
    direction: 'horizontal',
    spaceBetween: 5,
    autoplay: {
      delay: 5000,
    },
    effect: 'slide',
  };
  /** Notify Astrologer */
  notifyAstrologer(astroId: any) {
    if (this.userdata.isLogin())
      this.orderService
        .notifyAstroOrder({
          astroId: astroId,
          customerId: this.userdata.getId(),
          is_call: true,
        })
        .subscribe(
          (res: any) => {
            this.toast.success(res.message, 'Success');
          },
          (err: any) => {
            this.toast.error(err.error.message, 'Alert');
          }
        );
    else this.toast.error('Please login first!');
  }
}
