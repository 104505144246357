<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Person Detail</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
          &nbsp;&nbsp;>&nbsp;&nbsp;
        </li>
        <li><a (click)="goBack()">Person Detail</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!-- Profile Detail Start-->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <h4 class=" fw-500 mb-3">Person Detail</h4>
      <table class="table user-table border rounded">
        <tbody>
          <tr>
            <td class="active-text fw-500 border-right">Name</td>
            <td>{{ dataList.name }}</td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">Gender</td>
            <td>{{ dataList.gender }}</td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">Mobile</td>
            <td>{{ dataList.mobile }}</td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">Birth Details</td>
            <td>{{ dataList.dob }} {{ dataList.tob }}</td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">Address</td>
            <td>
              {{ dataList.city }}, {{ dataList.state }},
              {{ dataList.country }}
            </td>
          </tr>
          <tr class="active-text fw-500 border-right">
            <td>Marital Status</td>
            <td>{{ dataList.marital_status }}</td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">Employed-In</td>
            <td>{{ dataList.employed_in }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="dataList.is_partner_details">
          <tr>
            <td class="active-text fw-500 border-right">Partner Name</td>
            <td>{{ dataList.partner_name }}</td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">Partner Address</td>
            <td>
              {{ dataList.partner_city }}, {{ dataList.partner_state }},
              {{ dataList.partner_country }}
            </td>
          </tr>
          <tr>
            <td class="active-text fw-500 border-right">
              Partner Birth Details
            </td>
            <td>{{ dataList.partner_dob }} {{ dataList.partner_tob }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class=" my-5 text-center">
      <a [routerLink]="['/update-person', dataList._id]"
        class="btn bg-secondary py-2 col-md-4 col-12 text-white fw-500">UPDATE</a>
    </div>
  </div>
</section>
<!-- Profile Detail End-->

<app-footer></app-footer>