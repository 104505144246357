<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Call Orders</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize text-white">Call Orders</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="container mt-lg-5 mt-3 py-2">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <!-- <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center d-none d-lg-block">
        Call Details
      </h4>
      <h5 class="text-capitalize text-center d-lg-none">Call Details</h5>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div> -->
        <div class="row
    d-flex 
    text-center
    f-style
    my-2">
          <button class="btn btn-sm mr-2 rounded" [ngClass]="{'active':filterType == 'all'}" (click)="getCallOrders()">
            All
          </button>
          <button class="btn btn-sm mx-2 rounded" [ngClass]="{'active':filterType == 'promo'}" (click)="promoCall()">
            Promo Call
          </button>
          <button class="btn btn-sm mx-2 rounded" [ngClass]="{'active':filterType == 'review'}" (click)="noReview()">
            No Review
          </button>
          <button class="btn  btn-sm mx-2 rounded" [ngClass]="{'active':filterType == 'feedback'}" (click)="noFeedback()">
            No Feedback
          </button>
        </div>
        <div class="row">
          <ngx-datatable class=" material cust-table xyz" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{ dir: 'desc' }]">
            <ngx-datatable-column [flexGrow]="1" name="Call ID" prop="ocallId">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/call-order-list/', row.orderserviceId?._id]">{{
                  row.ocallId
                  }}</a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="2" name="Astrologer" prop="astroId.name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.astroId.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="Amount" prop="cost">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.customerCurrencySymbol }}{{ row.cost }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="Duration" prop="cost">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ convertSecondstoTime(row.callDuration) }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="Is Promo Call ?">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.is_promotional_call" class="badge bdg-txt-fw bg-success mr-2 py-2">PROMO</span><span
                  *ngIf="row.is_report_promo_call" class="badge bdg-txt-fw bg-success py-2 mt-1">REPORT PROMO</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Status" prop="order_current_status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.order_current_status.toUpperCase() }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.created_at | date: "medium" }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Action" prop="action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/call-order-list', row?.orderserviceId?._id]"
                  class="btn py-1 px-3 rounded shadow-0 bg-secondary text-white">Detail</a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>