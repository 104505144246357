import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PassDataService } from 'src/app/service/data/pass-data.service';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { ReportDailogComponent } from './report-dailog/report-dailog.component';

@Component({
  selector: 'app-order-add-person',
  templateUrl: './order-add-person.component.html',
  styleUrls: ['./order-add-person.component.css'],
})
export class OrderAddPersonComponent implements OnInit {
  mul = 2;
  peopleList = new Array();
  langList = new Array();
  dataList: any = '';
  isWait = false;
  approvednums: any = [];
  astroKey: any;
  selfId = '';
  isSelfProfile = false;
  today = '';
  // Place Order For
  orderForm = this.formBuilder.group({
    customerId: ['', [Validators.required]],
    astroId: ['', [Validators.required]],
    personId: ['', [Validators.required]],
    question: '',
    langId: '',
    offerId: '',
    is_offer: false,
    reportId: '',
    comment: '',
    comments: '',
    is_promotional_chat: false,
    promoId: '',
  });
  isNew = true;
  waitingForm = {
    customerId: '',
    astroId: '',
    is_chat: false,
    is_call: false,
    is_current_active: false,
    personId: '',
    promoId: '',
    is_offer: false,
    offerId: '',
  };

  constructor(
    private userdata: UserdataService,
    private loginService: LoginService,
    private orderService: OrderServicesService,
    private openService: OpenApiService,
    private toast: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private dataService: PassDataService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private modalService: NgbModal
  ) { }

  recievedData: any;
  ngOnInit(): void {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';

    this.getMyPeople();
    this.resetForm();
    this.selfId = localStorage.getItem('selfprofileId') ?? '';
    this.dataService.data.subscribe((data) => {
      this.recievedData = data;
      //console.log(this.recievedData)
      if (
        this.recievedData.endpoint == null ||
        typeof this.recievedData.endpoint == 'undefined'
      ) {
        window.history.back();
        return;
      }
      if (this.recievedData.endpoint == 'report-astro-list') this.openDialog();
      this.astro();
      this.orderForm.patchValue({ astroId: this.recievedData.astroId });
      //console.log(this.recievedData)
    });
    if (
      this.recievedData.endpoint == 'promo-call-astro-list' ||
      this.recievedData.endpoint == 'promo-chat-astro-list'
    )
      this.orderForm.patchValue({ is_promotional_chat: true });
    if (
      this.recievedData.endpoint == 'promo-astro-list-call' ||
      this.recievedData.endpoint == 'promo-astro-list-chat'
    )
      this.orderForm.patchValue({ promoId: this.recievedData.promoId });
    if (this.userdata.isLogin()) {
      this.waitingForm.customerId = this.userdata.getCustomerId();
      this.orderForm.patchValue({
        customerId: this.userdata.getCustomerId(),
      });
    }
    if (!this.userdata.isIndia()) {
      this.mul = 1;
    }
    this.addValidations();
    this.getCountries();
  }

  reportId = '';
  openDialog() {
    const dialogs = this.dialog.open(ReportDailogComponent, {
      width: '100%',
      data: this.reportId,
      disableClose: true,
    });
    dialogs.afterClosed().subscribe((result) => {
      this.orderForm.patchValue({ reportId: sessionStorage.getItem('bookReport') });
      sessionStorage.removeItem('bookReport')
      this.dialog.closeAll()
    });
  }
  addValidations() {
    switch (this.recievedData.endpoint) {
      case 'chat-astro-list':
      case 'promo-chat-astro-list':
      case 'promo-astro-list-chat':
        this.orderForm.get('comments')?.addValidators(Validators.required);
        break;
      case 'query-astro-list':
        this.orderForm.get('langId')?.addValidators(Validators.required);
        this.orderForm.get('question')?.addValidators(Validators.required);
        break;
      case 'report-astro-list':
        this.orderForm.get('langId')?.addValidators(Validators.required);
        this.orderForm.get('comment')?.addValidators(Validators.required);
        break;
    }
  }
  //showReportList
  personForm: FormGroup = this.formBuilder.group({});

  getMyPeople() {
    this.spinner.show();
    this.loginService
      .myPeople({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.peopleList = res.data;
          this.approvednums = res.approvednums;
          this.approvednums = res.approvednums;
          this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
            (res: any) => {
              this.userdata.setProfileData(res.data)
              this.approvednums.push({ '_id': this.userdata.getCustomerId(), 'mobile': String(res.data.phone) })
              this.userdata.setCurrentWallet(res.data.current_wallet)
            }, err => {
              this.toast.error(err.error.message, 'Error')
            }
          )
        },
        (err) => {
          this.spinner.hide();
          //console.log(err);
        }
      );
  }

  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }
  isAddNewPerson = false;
  isSelectPerson = false;
  formShow = false;
  addNewPerson() {
    this.isNew = true
    this.isAddNewPerson = true;
    this.isSelectPerson = false;
    this.resetForm();
    this.formShow = true;
  }
  selectedPerson(data: any) {
    this.isAddNewPerson = false;
    this.isSelectPerson = true;
    // this.spinner.show();
    // this.loginService.getSinglePerson({ _id: data._id }).subscribe({
    //   next: (v: any) => {
    //     this.updateForm(v.data);
    //     this.spinner.hide();
    //   },
    //   error: (err) => {
    //     this.spinner.hide();
    //   },
    // });
    this.updateForm(data);
  }
  isMobileVerified = false;
  updateForm(v: any) {
    this.personForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });
    // Adding Details to order Form
    this.orderForm.patchValue({ personId: v._id });

    // Adding Details to Waiting object
    this.waitingForm.personId = v._id;

    this.isMobileVerified = v.is_number_verified;
    this.isSelfProfile = v._id == this.selfId;
    this.personForm.patchValue({
      is_self_profile: this.isSelfProfile,
    });
    this.formShow = true;
    this.personForm.patchValue({ _id: v._id });
    this.personForm.patchValue({ name: v.name });
    this.personForm.patchValue({ detail_name: v.detail_name });
    this.personForm.patchValue({ gender: v.gender });
    this.personForm.patchValue({ gender: v.gender });
    this.personForm.patchValue({ mobile: v.mobile });
    var dob = moment(v.dob, ['DD-MM-YYYY']).format('YYYY-MM-DD');
    this.personForm.patchValue({ dob: dob });
    var tob = moment(v.tob, ['hh:mm a']).format('HH:mm');
    this.personForm.patchValue({ tob: tob });
    this.personForm.patchValue({ city: v.city });
    this.personForm.patchValue({ state: v.state });
    this.personForm.patchValue({ country: v.country });
    this.personForm.patchValue({
      marital_status: v.marital_status,
    });
    this.personForm.patchValue({
      employed_in: v.employed_in,
    });
    if (v.is_partner_details) {
      this.personForm.patchValue({
        is_partner_details: v.is_partner_details,
      });
      this.personForm.patchValue({
        partner_name: v.partner_name,
      });
      var partner_dob = moment(v.partner_dob, ['DD-MM-YYYY']).format(
        'YYYY-MM-DD'
      );
      this.personForm.patchValue({ partner_dob: partner_dob });
      var partner_tob = moment(v.partner_tob, ['hh:mm a']).format('HH:mm');
      this.personForm.patchValue({ partner_tob: partner_tob });
      this.personForm.patchValue({
        partner_city: v.partner_city,
      });
      this.personForm.patchValue({
        partner_state: v.partner_state,
      });
      this.personForm.patchValue({
        partner_country: v.partner_country,
      });
    }
    this.personForm.patchValue({
      is_number_verified: v.is_number_verified,
    });
    this.personForm.patchValue({
      country_code: v.country_code,
    });
    this.isNew = false;
  }
  resetForm() {
    this.personForm = this.formBuilder.group({
      customerId: ['', [Validators.required]],
      name: ['', [Validators.required]],
      gender: ['male', [Validators.required]],
      mobile: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      tob: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      marital_status: ['', [Validators.required]],
      employed_in: ['', [Validators.required]],
      is_number_verified: [true, [Validators.required]],
      is_partner_details: false,
      partner_name: '',
      partner_dob: '',
      partner_tob: '',
      partner_city: '',
      partner_state: '',
      partner_country: '',
      detail_name: '',
      is_self_profile: false,
      country_code: ['91', [Validators.required]],
      _id: '',
    });
    this.personForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });
    this.isNew = true;
  }
  get u() {
    return this.personForm.controls;
  }
  goBack() {
    window.history.back();
  }
  onSelfSelect() {
    if (this.personForm.get('is_self_profile')?.value == true) {
      this.personForm.get('detail_name')?.addValidators(Validators.required);
    } else {
      this.personForm.get('detail_name')?.clearValidators();
    }
  }
  onChangePartner() {
    if (this.personForm.get('is_partner_details')?.value == true) {
      //console.log('if')
      this.personForm.get('partner_name')?.addValidators(Validators.required);
      this.personForm.get('partner_dob')?.addValidators(Validators.required);
      this.personForm.get('partner_tob')?.addValidators(Validators.required);
      this.personForm.get('partner_city')?.addValidators(Validators.required);
      this.personForm.get('partner_state')?.addValidators(Validators.required);
      this.personForm.get('partner_country')?.addValidators(Validators.required);
    } else {
      //console.log('else')
      this.personForm.get('partner_name')?.clearValidators();
      this.personForm.get('partner_name')?.updateValueAndValidity();
      this.personForm.get('partner_dob')?.clearValidators();
      this.personForm.get('partner_dob')?.updateValueAndValidity();
      this.personForm.get('partner_tob')?.clearValidators();
      this.personForm.get('partner_tob')?.updateValueAndValidity();
      this.personForm.get('partner_city')?.clearValidators();
      this.personForm.get('partner_city')?.updateValueAndValidity();
      this.personForm.get('partner_state')?.clearValidators();
      this.personForm.get('partner_state')?.updateValueAndValidity();
      this.personForm.get('partner_country')?.clearValidators();
      this.personForm.get('partner_country')?.updateValueAndValidity();
    }
    //console.log(this.personForm.controls)
  }
  astro() {
    this.openService
      .singleAstrologerDetails({ _id: this.recievedData.astroId })
      .subscribe(
        (res: any) => {
          this.checkOffer(res.data);
          this.langList = res.data.lang;
        },
        (err) => { }
      );
  }
  checkOffer(key: any) {
    //console.log('key =>' + JSON.stringify(key))
    switch (this.recievedData.endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
        if (key.isCallOfferApplicable) this.setOffer(key.callofferis._id);
        break;

      case 'chat-astro-list':
      case 'promo-chat-astro-list':
        if (key.isChatOfferApplicable) this.setOffer(key.chatofferis._id);
        break;

      case 'query-astro-list':
        if (key.isQueryOfferApplicable) this.setOffer(key.queryofferis._id);
        break;

      case 'report-astro-list':
        if (key.isReportOfferApplicable) this.setOffer(key.reportofferis._id);
        break;

      default:
        if (key.isCallOfferApplicable) this.setOffer(key.callofferis._id);
        break;
    }
  }
  setOffer(key: string) {
    this.waitingForm.is_offer = true;
    this.waitingForm.offerId = key;
    this.orderForm.patchValue({ is_offer: true });
    this.orderForm.patchValue({ offerId: key });
  }
  getAstro() {
    this.spinner.show()
    this.openService
      .singleAstrologerDetails({ _id: this.recievedData.astroId })
      .subscribe(
        (res: any) => {
          this.astroKey = res.data;
          this.langList = res.data.lang;
          // Adding Details to order form
          this.orderForm.patchValue({ astroId: this.recievedData.astroId });
          // Adding Details to Waiting object
          this.waitingForm.astroId = this.recievedData.astroId;

          if (this.checkOnlineOffLine(res.data)) {

            this.spinner.hide()
            this.myProfile();
          } else {

            this.spinner.hide()
            this.addtoWatingList();
            if (
              this.astroKey.is_current_call_going ||
              this.astroKey.is_current_chat_going
            )
              this.waitingForm.is_current_active = true;
          }
        },
        (err) => { this.spinner.hide() }
      );
  }
  myProfile() {
    this.spinner.show()
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe({
        next: (v: any) => {
          this.spinner.hide()
          //console.log(v.data)
          this.userdata.setProfileData(v.data)
          var profileData = v.data
          this.userdata.setProfileData(v.data)
          if (profileData.is_current_chat || profileData.is_current_call) {
            this.toast.error('Complete order before placing another order.', 'Error')
            return
          }
          else if (this.recievedData.endpoint == ('promo-call-astro-list' || 'promo-chat-astro-list')) {
            if (!profileData.is_allow_promotional || profileData.is_promotional_complete) {
              this.toast.error('You have already requested for 5 mins free promo.', 'Error')
              return
            }
            else if (!v.promoallow) {
              this.toast.error(v.msg, 'Error')
              this.router.navigate(['/recharge-wallet'])
              return
            }
            else {
              /**  Check if user is already in promo waiting list */
              this.orderService.showWaitingList({ customerId: this.userdata.getCustomerId(), is_user_notified: false }).subscribe(
                (res: any) => {
                  var i = res.data.findIndex((x: any) => (x.isFreePromomotionalCall || x.isFreePromomotionalChat))
                  if (i > -1) {
                    this.toast.error('You are already in promo waiting list', 'Error')
                    this.router.navigate(['/wait-list'])
                    return
                  }
                })
            }
          } else {
            this.checkBalance(v.data);
          }
        },
        error: (error) => {
          this.spinner.hide()
        },
      });
  }
  languageSelect(event: any) {
    this.orderForm.patchValue({ langId: event.target.value });
  }
  addQuestion(event: any) {
    //console.log(event.target.value)
    this.orderForm.patchValue({ question: event.target.value });
    this.orderForm.patchValue({ comment: event.target.value });
  }
  onPaste(event: ClipboardEvent) {
    var pastedText = event.clipboardData?.getData('text')
    this.orderForm.patchValue({ question: pastedText });
    this.orderForm.patchValue({ comment: pastedText });
  }
  placeOrder() {
    if (!this.isMobileVerified && (this.recievedData.endpoint == 'call-astro-list' || this.recievedData.endpoint == 'promo-call-astro-list' || this.recievedData.endpoint == 'promo-astro-list-call')) {
      //open dialog
      this.resendOtp()
      this.openVerifyOtpDialog(this.modalContent)
    } else {
      this.getAstro();
    }
  }

  addPerson() {
    if (this.approvednums.some((data: any) => data.mobile === this.personForm.get('mobile')?.value)) {
      this.personForm.patchValue({ is_number_verified: true });
      this.isMobileVerified = true
    } else {
      this.personForm.patchValue({ is_number_verified: false });
      this.isMobileVerified = false
    }
    if (this.isNew) {
      this.orderForm.patchValue({ personId: ' ' })
      if (this.orderForm.invalid) {
        //console.log(this.orderForm.controls)
        this.toast.error('Please Fill Complete Form', 'Error')
        return
      }
    }

    this.spinner.show();
    this.loginService.addPeople(this.personForm.value, this.isNew).subscribe(
      (res: any) => {
        this.spinner.hide();
        //start from here
        if (!res.message.is_number_verified) {
          this.isMobileVerified = false;
        }
        if (this.isNew) {
          this.orderForm.patchValue({ personId: res.message._id })
          this.waitingForm.personId = res.message._id;
        }
        if ((!this.isMobileVerified && (this.recievedData.endpoint == 'call-astro-list' || this.recievedData.endpoint == 'promo-call-astro-list' || this.recievedData.endpoint == 'promo-astro-list-call'))) {
          //open dialog
          this.resendOtp()
          this.openVerifyOtpDialog(this.modalContent)
        } else {
          this.placeOrder()
        }
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  // /**
  //  * Place Wait Order
  //  */

  addtoWatingList() {
    if (this.recievedData.endpoint == 'call-astro-list')
      this.waitingForm.is_call = true;
    else this.waitingForm.is_chat = true;

    this.spinner.show();
    this.orderService.placeWaitOrders(this.waitingForm).subscribe({
      next: (v: any) => {
        this.spinner.hide();
        this.toast.success(v.message);
        this.router.navigate(['/wait-list']);
      },
      error: (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message);
        this.router.navigate(['/home']);
      },
    });
  }
  checkOnlineOffLine(astro: any) {
    switch (this.recievedData.endpoint) {
      case 'call-astro-list':
        return astro.is_call_active;
      case 'chat-astro-list':
        return astro.is_chat_active;
      case 'query-astro-list':
        return astro.is_query_active;
      case 'report-astro-list':
        return astro.is_report_active;
      default:
        return astro.is_call_active;
    }
  }
  checkBalance(res: any) {
    this.spinner.show()
    const balance = res.current_wallet;
    const astroCharges = this.getCharges();
    //console.log(astroCharges, balance)
    //return
    if (astroCharges > balance) {
      this.spinner.hide();
      this.userdata.setCurrentRoute(this.router.url);
      this.toast.error('Please Recharge to place order', 'Alert');
      this.router.navigateByUrl('/recharge-wallet');
      return;
    }
    this.spinner.hide()
    this.generateOrder();
  }

  getCharges() {
    const key = this.astroKey;
    switch (this.recievedData.endpoint) {
      case 'call-astro-list':
      case 'promo-astro-list-call': {
        var charges = 0;
        if (key.isCallOfferApplicable) {
          // Adding fields to order form
          this.orderForm.patchValue({ offerId: key.callofferis._id });
          this.orderForm.patchValue({ is_offer: true });

          // Adding fields to waiting object
          this.waitingForm.offerId = key.callofferis._id;
          this.waitingForm.is_offer = true;
          charges = key.astroCallChargesWithOffer;
        } else {
          charges = key.call_charges * this.mul;
        }
        if (this.recievedData.endpoint == 'promo-astro-list-call')
          return charges * (this.recievedData.duration / 60);
        else return charges * 5;
      }

      case 'chat-astro-list':
      case 'promo-astro-list-chat': {
        var charges = 0;
        if (key.isChatOfferApplicable) {
          // Adding fields to order form
          this.orderForm.patchValue({ offerId: key.chatofferis._id });
          this.orderForm.patchValue({ is_offer: true });

          //Adding fields to waiting object
          this.waitingForm.offerId = key.chatofferis._id;
          this.waitingForm.is_offer = true;
          charges = key.astroChatChargesWithOffer;
        } else {
          charges = key.chat_charges * this.mul;
        }
        if (this.recievedData.endpoint == 'promo-astro-list-chat')
          return charges * (this.recievedData.duration / 60);
        else return charges * 5;
      }

      case 'query-astro-list': {
        var charges = 0;
        if (key.isQueryOfferApplicable) {
          // Adding fields to order form
          this.orderForm.patchValue({ offerId: key.queryofferis._id });
          this.orderForm.patchValue({ is_offer: true });

          //Adding fields to waiting object
          this.waitingForm.offerId = key.queryofferis._id;
          this.waitingForm.is_offer = true;
          charges = key.astroQueryChargesWithOffer;
        } else {
          charges = key.query_charges * this.mul;
        }
        return charges;
      }

      case 'report-astro-list': {
        var charges = 0;
        if (key.isReportOfferApplicable) {
          // Adding fields to order form
          this.orderForm.patchValue({ offerId: key.reportofferis._id });
          this.orderForm.patchValue({ is_offer: true });

          //Adding fields to waiting object
          this.waitingForm.offerId = key.reportofferis._id;
          this.waitingForm.is_offer = true;
          charges = key.astroReportChargesWithOffer;
        } else {
          charges = key.report_charges * this.mul;
        }
        return charges;
      }

      default: {
        var charges = 0;
        if (key.isCallOfferApplicable) {
          // Adding fields to order form
          this.orderForm.patchValue({ offerId: key.callofferis._id });
          this.orderForm.patchValue({ is_offer: true });

          //Adding fields to waiting object
          this.waitingForm.offerId = key.callofferis._id;
          this.waitingForm.is_offer = true;
          charges = key.astroCallChargesWithOffer;
        } else {
          charges = key.call_charges * this.mul;
        }
        return charges * 5;
      }
    }
  }
  generateOrder() {
    this.spinner.show();
    this.orderService
      .placeOrder(this.orderForm.value, this.recievedData.endpoint)
      .subscribe({
        next: (v: any) => {
          this.toast.success(v.message, 'Success');
          this.spinner.hide();
          this.updateUI(v);
        },
        error: (err) => {
          const error = err.error;
          this.spinner.hide();
          if (error.status == 400) {
            this.waitingForm.is_current_active = error.isOnline;
            this.addtoWatingList();
            this.toast.error(error.message);
          }
        },
      });
  }
  updateUI(v: any) {
    switch (this.recievedData.endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
      case 'promo-astro-list-call':
        this.router.navigate(['/call-order-list', v.callId]);
        break;
      case 'chat-astro-list':
      case 'promo-chat-astro-list':
      case 'promo-astro-list-chat':
        this.router.navigate([
          '/chat-order-list',
          v.orderdetail.orderserviceId,
        ]);
        break;
      case 'query-astro-list':
        this.router.navigate(['/query-order-list', v.orderdetail._id]);
        break;
      case 'report-astro-list':
        this.router.navigate(['/report-order-list', v.orderdetail._id]);
        break;
    }
  }
  onCountrySelect(event: any) {
    //console.log('country', event);
  }

  /** Verify Person Phone OTP */
  @ViewChild("content") modalContent: TemplateRef<any> | undefined;
  openVerifyOtpDialog(content: any) {
    this.modalService.open(content, { backdrop: 'static', keyboard: false, centered: true })
  }
  onOtpChange(event: any) {
    if (event.length == 4) {
      this.spinner.show();
      this.orderService.verifyPersonOtp({ _id: this.orderForm.value.personId, otp: event }).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.modalService.dismissAll()
          this.isMobileVerified = true
          this.placeOrder()
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
    }
  }

  resendOtp() {
    this.orderService.sendPersonOtp({ _id: this.orderForm.value.personId }).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji: any) {
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }
  disableDate() {
    return false
  }
}
