import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  BaseUrl: string = ''
  Token: any = '';
  header_object
  constructor(
    private userdata: UserdataService,
    private http: HttpClient, @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
    this.header_object = new HttpHeaders({
      "Authorization": this.userdata.getToken()||"",
      'country_code':this.userdata.getCountryCode()||"in"
    })
  }

  /**
   * Add Support
   */
  public addSupport(data: any) {
    return this.http.post(this.BaseUrl + "/addsupport", data);
  }

  /**
   * Support List
   */
   public supportList(data: any) {//customerId
   return this.http.post(this.BaseUrl + "/allmysupport", data, { headers: this.header_object });
  }

  /**
   * Support Details
   */
   public supportDetail(data: any) {//customerId,_id
   return this.http.post(this.BaseUrl + "/singlesupportbyid", data, { headers: this.header_object });
  }
}
