import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  BaseUrl: string = '';
  Token: any = '';
  header_object;
  constructor(
    private userdata: UserdataService,
    private http: HttpClient,
    @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base;
    this.Token = userdata.getToken();
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
  }

  /**
   * Show all product By Categories
   */
  public getProductByCategory(data: any) {
    return this.http.post(this.BaseUrl + '/allproducts', data);
  }

  /**
   * Show all product detail
   */
  public getProductDetail(data: any) {
    return this.http.post(this.BaseUrl + '/fetchproductbyid', data);
  }

  /**
   * Get Consultant
   */
  public getConsultantList(data: any) {
    return this.http.post(this.BaseUrl + '/showastrologeriwork', data, {
      headers: this.header_object,
    });
  }

  /**
   * Get Single Addon
   */
  public getSingleAddon(data: any) {
    return this.http.post(this.BaseUrl + '/getSingleAddon', data, {
      headers: this.header_object,
    });
  }

  /**
   * Search Product
   */
  public searchProduct(data: any) {
    return this.http.post<any>(this.BaseUrl + '/searchproduct', data);
  }
  products = [];
  searchProductPipe(data: any) {
    return this.products.length
      ? of(this.products)
      : this.http
          .post<any>(this.BaseUrl + '/searchproduct', data)
          .pipe(tap((data) => (this.products = data)));
  }
  public searchProductWithCategory(data: any) {
    return this.http.post(this.BaseUrl + '/searchproductunderacategory', data);
  }

  public searchProductWithSubcategory(data: any) {
    return this.http.post(this.BaseUrl + '/searchproductunderacategory', data);
  }

  public getSubcategoryListByCatId(data: any){
    return this.http.post(this.BaseUrl + '/allproductsubcategory', data);
  }
}
