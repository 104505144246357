<div class="py-3 px-3 bg-light rounded">
  <div class="card">
    <div class="card-body">
      <div class="d-flex flex-column align-items-center text-center">
        <img src="../../../assets/images/astro-logo.png" alt="Admin" class="rounded-circle" width="100">
        <div class="mt-3">
          <h4>#{{myProfile?.customerAutoId}}-{{myProfile?.name}}</h4>
          <p class="text-gray mb-1 small fw-400">{{myProfile?.email}}</p>
          <button class="btn bg-secondary red-btn text-white" (click)="openDialog(logout)">Logout</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-body">
      <ul class="nav nav-pills flex-column mb-auto s-left-padding ">
        <li class="nav-item border-bottom">
          <a routerLink="/dashboard" class="nav-link" aria-current="page" routerLinkActive="active">
            <i class="fa-solid fa-house-chimney"></i>
            Dashboard
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/my-profile" class="nav-link link-dark ">
            <i class="fa-solid fa-id-card-clip"></i>
            Profile
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/call-order-list" class="nav-link link-dark">
            <i class="fa-solid fa-square-phone"></i>
            Call History
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/chat-order-list" class="nav-link link-dark">
            <i class="fa-solid fa-comment-dots"></i>
            Chat History
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/report-order-list" class="nav-link link-dark">
            <i class="fa-solid fa-file-lines"></i>
            Report History
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/query-order-list" class="nav-link link-dark">
            <i class="fa-solid fa-circle-question"></i>
            Query History
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/view-cart" class="nav-link link-dark">
            <i class="fa-solid fa-cart-shopping "></i>
            View Cart
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/wait-list" class="nav-link link-dark">
            <i class="fa-solid fa-hourglass-end"></i>
            Waiting List
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/wallet" class="nav-link link-dark">
            <i class="fa-solid fa-wallet"></i>
            Wallet
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/store-order-list" class="nav-link link-dark">
            <i class="fa-solid fa-bag-shopping"></i>
            My Orders
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/show-promo" class="nav-link link-dark">
            <i class="fa-solid fa-mobile-screen"></i>
            Promo
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/customer-support" class="nav-link link-dark">
            <i class="fa-solid fa-headset"></i>
            Customer Support
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/issue" class="nav-link link-dark">
            <i class="fa-solid fa-file-pen"></i>
            Issue History
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/my-address" class="nav-link link-dark">
            <i class="fa-solid fa-map-location"></i>
            My Addresses
          </a>
        </li>
        <li class="border-bottom">
          <a routerLinkActive="active" routerLink="/festivals" class="nav-link link-dark">
            <i class="fa-solid fa-store"></i>
            Festivals
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Logout Model Start -->
<ng-template #logout let-modal class="logoutModal">
  <div class="row d-flex flex-column m-0">
    <div class="modal-header">
      <h4>Logout!</h4>
    </div>
    <div class="modal-body modal-body m-0 p-2">
      <p class="py-3">Are you sure you wish to log out?</p>
    </div>
    <div class="modal-footer border-0 p-0">
      <button type="button" class="btn bg-green text-white py-2" aria-label="Close" (click)="modal.dismiss('false')">
        Cancel
      </button>
      <button type="button" class="btn bg-secondary py-2 text-white" (click)="modal.close('true')">
        Logout
      </button>
    </div>
  </div>
</ng-template>
<!-- Logout Model End-->