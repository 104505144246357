import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class UserdetailsService {

  loginUserData: BehaviorSubject<any> = new BehaviorSubject<any>(this.userData.getToken())
  constructor(private userData: UserdataService) { }

  onChangeOfUserDetailHandler = (logindata : string | null) => {
    this.loginUserData.next(logindata);
  }
}
