import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserdataService } from '../userdata/userdata.service';
import { UserdetailsService } from '../userdetails/userdetails.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  Token: any = '';
  header_object;
  BASEURI: string = '';
  constructor(
    private http: HttpClient,
    private userdata: UserdataService,
    @Inject('BASE_URL') _baseUrl: string,
    private userDetailService: UserdetailsService
  ) {
    this.BASEURI = _baseUrl;
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
    //this.userdata.getToken() = userdata.getToken()
  }

  login(form: any) {
    //console.log('serv', form);
    return this.http.post(this.BASEURI + '/logincustomer', form);
  }
  register(form: any) {
    return this.http.post(this.BASEURI + '/registercustomer', form);
  }

  verifyEmailOtp(form: any) {
    return this.http.post(this.BASEURI + '/verifyotpemail', form);
  }

  resendEmailOtp(form: any) {
    return this.http.post(this.BASEURI + '/sendotponmail', form);
  }

  verifyMobileOtp(form: any) {
    return this.http.post(this.BASEURI + '/verifyotp', form);
  }

  resendMobileOtp(form: any) {
    return this.http.post(this.BASEURI + '/resendtopphone', form);
  }

  logout(form: any) {
    this.userDetailService.onChangeOfUserDetailHandler(null);
    return this.http.post(this.BASEURI + '/logoutcustomer', form, {
      headers: this.header_object,
    });
  }

  sendOtpForgetPassword(form: any) {
    return this.http.post(this.BASEURI + '/forgetpassword', form);
  }

  verifyOtpForgetPassword(form: any) {
    return this.http.post(this.BASEURI + '/checkotpforget', form);
  }

  changePassword(form: any) {
    return this.http.post(this.BASEURI + '/changepasswordforget', form);
  }

  updatePassword(data: any) {
    return this.http.post(this.BASEURI + '/changeownpassword', data, {
      headers: this.header_object,
    });
  }

  myProfile(data: any) {
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
    return this.http.post(this.BASEURI + '/myprofile', data, {
      headers: this.header_object,
    });
  }

  myPeople(data: any) {
    //customerId
    return this.http.post(this.BASEURI + '/mypeople', data, {
      headers: this.header_object,
    });
  }

  getSinglePerson(data: any) {
    //_id
    return this.http.post<any>(this.BASEURI + '/fetchpersonbyid', data, {
      headers: this.header_object,
    });
  }

  updatePerson(data: any) {
    return this.http.post(this.BASEURI + '/updateperson', data, {
      headers: this.header_object,
    });
  }

  addPeople(data: any, isNew: boolean) {
    if (isNew)
      return this.http.post(this.BASEURI + '/addperson', data, {
        headers: this.header_object,
      });
    else
      return this.http.post(this.BASEURI + '/updateperson', data, {
        headers: this.header_object,
      });
  }

  hidePersonProfile(data: any) {
    return this.http.post(this.BASEURI + '/hidemyprofile', data, {
      headers: this.header_object,
    });
  }

  notification(data: any) {
    return this.http.post(this.BASEURI + '/mynotifications', data, {
      headers: this.header_object,
    });
  }
}
