<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Chat Details</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li>
          <a class="text-capitalize text-white" [routerLink]="['/chat-order-list']">Chat Orders</a>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-lg-5 mt-30">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>

      <div class="col-md-9">

        <!-- chat accept box -->
        <div class="mb-5 py-3 border bg-light-pink" *ngIf="isShowChatAcceptReject">
          <div class="row  text-center">
            <div class="col">
              <div class="py-1 h5 fw-600 text-red">Chat Accepted by Expert
              </div>
            </div>
          </div>
          <div class="row  text-center">
            <div class="col">
              <div class="py-1 text-dark">Expert has joined the chat. Please accept to join & start chatting</div>
            </div>
          </div>
          <!-- <div class="  text-center ">
            <div class="col">
              <div class="pb-3 ">Time Left: <span class="fw-600">12:40</span></div>
            </div>
          </div> -->
          <div class="row  text-center justify-content-center my-2">
            <div class="mx-2">
              <a type="button" class="btn btn-success btn-sm text-white px-5" (click)="acceptChat(true)">Accept</a>
            </div>
            <div class="mx-2">
              <a type="button" class="btn btn-danger btn-sm text-white px-5" (click)="acceptChat(false)">Reject</a>
            </div>
          </div>
        </div>
        <!-- chat accept box end -->
        <h5 class="text-capitalize fw-600">Chat Details</h5>
            <div class = "row mt-3">
              <div class="col-md-8">
                <div class="input-group input-group-sm mb-3">
                  <input type="text" class="form-control" disabled value = "{{dataList?.chatId?.share_chat_url}}">
                  <div class="input-group-append">
                    <span class="input-group-text cursor-pointer" id="basic-addon2" (click) = "copyToClipboard()">
                      <i class="fa fa-clipboard" title="Copy URL to clipboard"></i>
                    </span>
                  </div>
                </div>

                <div class = "mt-1 d-flex justify-content-start" *ngIf = "copy">
                  <small class = "mr-2">Share Chat on:  </small>
                  <a class = "mr-2" target="_blank" href="https://www.facebook.com/"><i
                    class="fa-brands fa-facebook-square fb-icon-1"></i></a>
                  <a target="_blank" href="https://web.whatsapp.com/"><i
                    class="fa-brands fa-whatsapp  text-success"></i></a>
              </div>
              </div>
            </div>
            <!-- <a (click)="copyToClipboard()"><i class="fa-solid fa-share-nodes"></i></a>
            <div class = "mt-1 d-flex justify-content-start" *ngIf = "copy">
                <a class = "mr-2" target="_blank" href="https://www.facebook.com/"><i
                  class="fa-brands fa-facebook-square fb-icon-1"></i></a>
                <a target="_blank" href="https://web.whatsapp.com/"><i
                  class="fa-brands fa-whatsapp  text-success"></i></a>
            </div> -->
        
        <div class="row mt-3">
          <div class="col-sm-8">
            
            

            <table class="rounded table table-stripped table-bordered">
              <tbody>
                <tr>
                  <th>Order Number</th>
                  <td># {{ dataList.chatId?.ochatId }}</td>
                </tr>
                <tr>
                  <th>Order Date</th>
                  <td>{{ dataList.chatId?.created_at | date: "medium" }}</td>
                </tr>
                <tr *ngIf="dataList.chatId?.is_promotional_chat">
                  <th>Status</th>
                  <td class="text-uppercase">
                    <span *ngIf="
                    dataList.chatId?.is_promotional_chat &&
                    !dataList.chatId?.is_paid_promotional_chat &&
                    !dataList.chatId?.is_report_promo_chat &&
                    !dataList.chatId?.is_direct_promo_chat
                  " class="badge bg-primary text-capitalize">COMPLEMENTARY PROMO</span>
                    <span *ngIf="
                    dataList.chatId?.is_promotional_chat &&
                    dataList.chatId?.is_paid_promotional_chat &&
                    !dataList.chatId?.is_report_promo_chat &&
                    !dataList.chatId?.is_direct_promo_chat
                  " class="badge bg-warning text-capitalize">PAID PROMO</span>
                    <span *ngIf="
                    dataList.chatId?.is_promotional_chat &&
                    !dataList.chatId?.is_paid_promotional_chat &&
                    dataList.chatId?.is_report_promo_chat &&
                    !dataList.chatId?.is_direct_promo_chat
                  " class="badge bg-info text-capitalize">REPORT PROMO</span>
                    <span *ngIf="
                    dataList.chatId?.is_promotional_chat &&
                    !dataList.chatId?.is_paid_promotional_chat &&
                    !dataList.chatId?.is_report_promo_chat &&
                    dataList.chatId?.is_direct_promo_chat
                  " class="badge bg-success text-capitalize">FREE PROMO</span>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="text-uppercase">
                    <span class="badge bg-info text-capitalize">{{
                      dataList.chatId?.order_current_status
                      }}</span>
                  </td>
                </tr>
                <tr>
                  <th>Astrologer</th>
                  <td>
                    {{
                    dataList.astroId?.displayname == "" ||
                    dataList.astroId?.displayname == null ||
                    dataList.astroId?.displayname == undefined
                    ? dataList.astroId?.name
                    : dataList.astroId?.displayname
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Astrologer Charges</th>
                  <td>
                    {{ dataList.chatId?.customerCurrencySymbol
                    }}{{ dataList.chatId?.astroCostPerMinute }}/min
                  </td>
                </tr>
                <tr>
                  <th>Chat Duration</th>
                  <td>
                    {{ convertSecondstoTime(dataList.chatId?.totalchatDuration) }}
                  </td>
                </tr>
                <tr>
                  <th>Total Charges</th>
                  <td>
                    {{ dataList.chatId?.customerCurrencySymbol
                    }}{{ dataList.chatId?.customer_amount }}
                  </td>
                </tr>
                <tr *ngIf="dataList?.chatId?.is_feedback_given">
                  <th>Feedback</th>
                  <td>
                    {{ dataList?.chatId?.feedback_value }}
                    <button class="btn btn-link" (click)="open(feedback,'feedback')"
                      *ngIf="checkThreeDays(dataList?.chatId?.created_at)">
                      Change
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataList?.chatId?.is_astro_replied_feedback">
                  <th>Feedback Reply</th>
                  <td>{{ dataList?.chatId?.astro_feedback_reply }}</td>
                </tr>
                <tr *ngIf="dataList?.chatId?.is_order_reviewed">
                  <th>Rating</th>
                  <td>
                    {{ dataList?.chatId?.customer_review }} -
                    <span class="p-2 border border-danger">{{ dataList?.chatId?.customer_review_stars }}
                      <i class="fa fa-star text-danger"></i></span>
                    <button class="btn btn-link" (click)="open(review,'review')"
                      *ngIf="checkSevenDays(dataList?.chatId?.created_at)">
                      Change
                    </button>
                  </td>
                </tr>
                <tr *ngIf="dataList?.chatId?.is_astro_replied_review">
                  <th>Rating Reply</th>
                  <td>{{ dataList?.chatId?.astro_review_reply }}</td>
                </tr>
                <tr *ngIf="dataList.chatId?.is_end &&
                !dataList.chatId?.is_feedback_given &&
                !dataList.chatId?.is_order_reviewed &&
                checkSevenDays(dataList.chatId?.created_at)">
                  <td colspan="2" class="text-center yellow-btn ">
                    <button class="btn btn-block btn-primary border-0 px-5" (click)="open(feedback,'feedback')">
                      Give Feedback
                    </button>
                  </td>
                </tr>
                <tr
                  *ngIf="dataList.chatId?.is_end && !dataList.chatId?.is_order_reviewed  && checkSevenDays(dataList?.chatId?.created_at)">
                  <td colspan="2">
                    <button class="btn-block btn btn-primary " (click)="open(review,'review')">
                      Give Rating
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Feedback Section-->
            <ng-template #feedback let-c="close" let-d="dismiss">
              <div class="modal-header pb-0">
                <h4 class="modal-title" id="modal-basic-title">Feedback</h4>
                <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                    class="material-icons text-white">close</span></button>
              </div>
              <div class="modal-body">
                <form [formGroup]="feedbackForm" (submit)="giveFeedback()">
                  <div class="row mt-1">
                    <div class="col-md-12">
                      <div class="form-group">
                        <!-- <label for=""><strong>Give Feedback: </strong></label> -->
                        <textarea class="form-control" formControlName="feedback_value" rows="5"></textarea>
                        <div *ngIf="
            g.feedback_value.invalid &&
            (g.feedback_value.dirty || g.feedback_value.touched)
          " class="alert alert-danger mt-1">
                          Required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="user-wrap d-flex justify-content-center">
                        <button type="submit" class="col-4 btn bg-red text-white mt-1 mb-2 p-0"
                          [disabled]="!feedbackForm.valid">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
            <!-- Review Section-->
            <ng-template #review let-c="close" let-d="dismiss">
              <div class="modal-header pb-0">
                <h4 class="modal-title" id="modal-basic-title">Review</h4>
                <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                    class="material-icons text-white">close</span></button>
              </div>
              <div class="modal-body">
                <form [formGroup]="reviewForm" (submit)="giveReview()">
                  <div class="row mt-1">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for=""><strong>Rate: </strong></label>
                        <br />
                        <ngb-rating formControlName="customer_review_stars" [max]="5">
                          <ng-template let-fill="fill" let-index="index">
                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                          </ng-template>
                        </ngb-rating>
                        <div *ngIf="
            r.customer_review_stars.invalid &&
            (r.customer_review_stars.dirty ||
              r.customer_review_stars.touched)
          " class="alert alert-danger mt-1">
                          Required.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for=""><strong>Review: </strong></label>
                        <textarea class="form-control" formControlName="customer_review" rows="5"></textarea>
                        <div *ngIf="
            r.customer_review.invalid &&
            (r.customer_review.dirty || r.customer_review.touched)
          " class="alert alert-danger mt-1">
                          Required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="user-wrap d-flex justify-content-center">
                        <button type="submit" class="col-4 btn bg-red text-white mt-1 mb-2 p-0"
                          [disabled]="!reviewForm.valid">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
            <div class="row d-flex justify-content-between">
              <div class="col m-0 p-0">
                <button type="button" class="btn btn-block bg-secondary py-2 text-white fw-500 shadow-0 rounded"
                  (click)="open(personDetail,'')">
                  Person Details
                </button>
                <!-- Person Detail Section-->
                <ng-template #personDetail let-c="close" let-d="dismiss">
                  <div class="modal-header pb-0">
                    <h4 class="modal-title" id="modal-basic-title">Person Detail</h4>
                    <button type="button" class="btn btn-close c-btn" aria-label="Close"
                      (click)="d('Cross click')"><span class="material-icons text-white">close</span></button>
                  </div>
                  <div class="modal-body">
                    <table class="table table-bordered table-stripped">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{{ dataList.personId?.name }}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>{{ dataList.personId?.gender }}</td>
                        </tr>
                        <tr>
                          <td>Mobile</td>
                          <td>{{ dataList.personId?.mobile }}</td>
                        </tr>
                        <tr>
                          <td>Birth Details</td>
                          <td>
                            {{ dataList.personId?.dob }}
                            {{ dataList.personId?.tob }}
                          </td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            {{ dataList.personId?.city }},
                            {{ dataList.personId?.state }},
                            {{ dataList.personId?.country }}
                          </td>
                        </tr>
                        <tr>
                          <td>Marital Status</td>
                          <td>{{ dataList.personId?.marital_status }}</td>
                        </tr>
                        <tr>
                          <td>Employed-In</td>
                          <td>{{ dataList.personId?.employed_in }}</td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="dataList.personId?.is_partner_details">
                        <tr>
                          <td>Partner Name</td>
                          <td>{{ dataList.personId?.partner_name }}</td>
                        </tr>
                        <tr>
                          <td>Partner Address</td>
                          <td>
                            {{ dataList.personId?.partner_city }},
                            {{ dataList.personId?.partner_state }},
                            {{ dataList.personId?.partner_country }}
                          </td>
                        </tr>
                        <tr>
                          <td>Partner Birth Details</td>
                          <td>
                            {{ dataList.personId?.partner_dob }}
                            {{ dataList.personId?.partner_tob }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </div>

              <div class="col m-0 p-0">
                <div class="col-md-12 d-flex flex-column" *ngIf="!dataList.is_customer_issue_created">
                  <button type="button" class="btn cus-border-active py-2 text-body fw-500 shadow-0"
                    (click)="open(issue,'')">
                    Need Help?
                  </button>
                  <!-- Add Issue Section-->
                  <ng-template #issue let-c="close" let-d="dismiss">
                    <div class="modal-header pb-0">
                      <h4 class="modal-title" id="modal-basic-title">Issue</h4>
                      <button type="button" class="btn btn-close c-btn" aria-label="Close"
                        (click)="d('Cross click')"><span class="material-icons text-white">close</span></button>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="issueForm" (submit)="addIssue()">
                        <div class="form-group">
                          <label for="issue_title">Issue Title</label>
                          <div class="input-group">
                            <input type="text" class="form-control" formControlName="issue_title" />
                          </div>
                          <div *ngIf="
                            i.issue_title.invalid &&
                            (i.issue_title.dirty || i.issue_title.touched)
                          " class="alert alert-danger mt-1">
                            Required.
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="issue_description">Description</label>
                          <div class="input-group">
                            <textarea class="form-control" formControlName="issue_description"></textarea>
                          </div>
                          <div *ngIf="
                            i.issue_description.invalid &&
                            (i.issue_description.dirty ||
                              i.issue_description.touched)
                          " class="alert alert-danger mt-1">
                            Required.
                          </div>
                        </div>
                        <button type="submit" class="
                          btn
                          bg-secondary
                          text-white
                          fw-500
                          shadow-0
                          p-0
                        " [disabled]="!issueForm.valid">
                          Save
                        </button>
                      </form>
                    </div>
                  </ng-template>
                </div>
                <div class="col-md-12" *ngIf="dataList.is_customer_issue_created">
                  <button type="button" class="btn bg-secondary py-2 btn-block text-white"
                    (click)="open(issueDetail,'')">
                    Issue Details
                  </button>
                  <!-- Issue Detail Section-->
                  <ng-template #issueDetail let-c="close" let-d="dismiss">
                    <div class="modal-header pb-0">
                      <h4 class="modal-title" id="modal-basic-title">Issue Detail</h4>
                      <button type="button" class="btn btn-close c-btn" aria-label="Close"
                        (click)="d('Cross click')"><span class="material-icons text-white">close</span></button>
                    </div>
                    <div class="modal-body">
                      <table class="table table-bordered table-stripped">
                        <tbody>
                          <tr>
                            <td>Issue ID</td>
                            <td>#{{ dataList.customer_issueId?.issueId }}</td>
                          </tr>
                          <tr>
                            <td>Amount</td>
                            <td>
                              {{ dataList?.chatId?.customerCurrencySymbol
                              }}{{ dataList.customer_issueId?.amount }}
                            </td>
                          </tr>
                          <tr>
                            <td>Issue Title</td>
                            <td>{{ dataList.customer_issueId?.issue_title }}</td>
                          </tr>
                          <tr>
                            <td>Issue Description</td>
                            <td>
                              {{ dataList.customer_issueId?.issue_description }}
                            </td>
                          </tr>
                          <tr *ngIf="dataList.customer_issueId?.is_reply_by_system">
                            <td>Reply</td>
                            <td>
                              {{ dataList.customer_issueId?.reply_by_system }}
                            </td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td>
                              <span *ngIf="dataList.customer_issueId?.is_issue_solved"
                                class="badge badge-pill bg-success">SOLVED</span>
                              <span *ngIf="!dataList.customer_issueId?.is_issue_solved"
                                class="badge badge-pill bg-danger">PENDING</span>
                            </td>
                          </tr>
                          <tr *ngIf="dataList.customer_issueId?.is_issue_refunded">
                            <td>Refund</td>
                            <td>
                              <span *ngIf="dataList.customer_issueId?.is_amount_refunded">
                                {{ dataList?.chatId?.customerCurrencySymbol
                                }}{{ dataList.customer_issueId?.amount_refunded }}
                              </span>
                              <span *ngIf="dataList.customer_issueId?.is_promo_refunded">
                                condition pending
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"><button [routerLink]="['/issue',dataList?.customer_issueId?._id]"
                                class="w-25 mx-auto p-0 mt-0 btn-block btn bg-red text-white">View Reply</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-template>
                </div>
              </div>

              <div class="col m-0 p-0" *ngIf="dataList.is_remedy_suggested">
                <div class="col-md-12" *ngIf="dataList.is_remedy_suggested">
                  <button type="button" class="btn btn-primary py-2 btn-block"
                    [routerLink]="['/list-remedy',dataList?._id]" [attr.aria-expanded]="!isRemedyCollapsed"
                    aria-controls="collapseExample">
                    Remedy Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-md-12 mb-4 chat-body bg-inactive">
                <div *ngFor="let chat of dataList.chatId?.chatinfo">
                  <div class="container-chat lighter" *ngIf="chat.type == 3">
                    <p *ngIf="!chat.isImage" [innerHTML]="chat.message"></p>
                    <a *ngIf="chat.isImage" target="_blank" [href]="getOpenUrl(chat.message)">File Attached. Click To
                      View.</a>
                    <span class="time-right">{{ chat.date | date: "medium" }}</span>
                    <span *ngIf="!chat.is_to_show" class="time-right">This message will only appear to you.</span>
                  </div>

                  <div class="container-chat darker" *ngIf="chat.type == 1 || chat.type == 2">
                    <ng-container *ngIf="chat.is_to_show">
                      <p *ngIf="!chat.isImage" [innerHTML]="chat.message" class="text-white"></p>
                      <a *ngIf="chat.isImage" target="_blank" [href]="getOpenUrl(chat.message)" class="text-white">File
                        Attached. Click To View.</a>
                      <span class="time-left">{{ chat.date | date: "medium" }}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>