import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { WalletService } from 'src/app/service/wallet/wallet.service';

@Component({
  selector: 'app-wallet-transaction',
  templateUrl: './wallet-transaction.component.html',
  styleUrls: ['./wallet-transaction.component.css']
})
export class WalletTransactionComponent implements OnInit {

  dataList:any = []
  currentWallet:any = ''
  reservedAmount:any = '' 
  currencySymbol="₹"
  currencyCode = "INR"
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  constructor(
    private userdata: UserdataService,
    private walletService: WalletService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getMyWallet()
  }

  getMyWallet(){
    this.spinner.show()
    this.walletService.myWalletHistory({customerId:this.userdata.getCustomerId()}).subscribe(
      (res:any)=>{
        this.currencySymbol = res.currencySymbol
       this.currencyCode= res.currencyCode
        this.dataList = res.data
        this.currentWallet = res.customerwallet - res.waiting_amount 
        this.reservedAmount = res.waiting_amount
        this.spinner.hide()
      },err=>{
        this.toast.error(err.error.message,'Error')
        this.spinner.hide()
      }
    )
  }

}
