<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">All Festivals</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li>All Festivals</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- hs sidebar Start -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="rounded">
          <div class="hs_blog_right_search_wrapper s-bar-bg">
            <input type="text" #searchInput (keyup)="searchKey(searchInput.value)" placeholder="Search" />
            <button type="submit" (click)="searchFestival(searchInput.value)">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>

        <!-- <div class="row mt-3">
          <div class="hs_blog_left_sidebar_main_wrapper">
            <div class="row">
              <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="festivals.length == 0">
                <h2>No Festival List Available.</h2>
              </div>
              <div class="col  card shadow" *ngFor="let festival of festivals">
                <div class="">
                  <div class="hs_blog_cont_heading_wrapper">
                    <h2 [innerHTML]="festival?.festivalId?.festivalName"></h2>
                    <div class="hs_blog_box1_img_wrapper mt-2">
                      <img [src]="getSanitizeUrl(festival?.festivalId?.festivalImage)" alt="festival_img"
                        class="size-100 rounded" />
                    </div>
                    <div class="text-justify line-height-4" [innerHTML]="festival?.festivalId?.festivalDescription"></div>
                  </div>
                  <a class="text-white bg-secondary px-3 py-2 rounded mt-2"
                    [routerLink]="['/single-festival/', festival?._id]">Read
                    More</a>
                </div>
              </div>
            </div>
          </div>
        </div> -->






        <!-- my code wiil be here -->
        <div class="container mt-2 p-0">
          <div class="row">

            <!-- no data available -->
            <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3" *ngIf="festivals.length == 0">
              <section class="container  order-bg1 mt-5 mb-5 py-3">
                <div class=" row n-data-img justify-content-center">
                  <img src="../../../../assets/images/no-data.svg" />
                </div>
                <div>
                </div>
              </section>
            </div>
            <div class="col-md-6 mt-3" *ngFor="let festival of festivals">
              <!-- card start here -->
              <div class="card shadow border-0">
                <img [src]="getSanitizeUrl(festival?.festivalId?.festivalImage)" alt="festival_img"
                  class="p-2 card-img-top">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <h4 class="card-title font-weight-semibold" [innerHTML]="festival?.festivalId?.festivalName"></h4>
                    <span class="font-weight-bold">{{festival?.festivalDate | date:'fullDate'}}</span>
                  </div>
                  <p [title]="festival?.festivalId?.festivalDescription" class="card-text text-muted blog_description"
                    [innerHTML]="festival?.festivalId?.festivalDescription">
                  </p>
                  <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                  <a class="btn btn-primary bg-secondary border-0 rounded mt-2"
                    [routerLink]="['/festivals/', festival?._id]">Read
                    More</a>
                </div>
              </div>
              <!-- card end here -->
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>
<app-footer></app-footer>