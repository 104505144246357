<app-header></app-header>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Reset Password
      </h3>
      <ul
        class="fw-500 text-white w-100 float-right d-flex justify-content-end"
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <i class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </i>
        </li>
        <li class="text-capitalize">Reset Password</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <h4 class="fw-500">Reset Password</h4>
    </div>
    <div class="row mt-3">
      <form
        *ngIf="!showCheckOtp && !isChangePassword"
        [formGroup]="sendOtpForm"
        (submit)="requestOtpForget()"
        class="d-flex flex-column col-md-6 border rounded"
      >
        <div class="form-group">
          <label for="exampleInputEmail1">Email address</label>
          <input
            type="email"
            class="form-control"
            formControlName="email"
            placeholder="Enter email"
          />
        </div>
        <button
          type="submit"
          class="btn bg-secondary text-white fw-500"
          [disabled]="!sendOtpForm.valid"
        >
          Submit
        </button>
      </form>
      <div *ngIf="showCheckOtp && !isChangePassword">
        <div class="text-center">
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{ length: 4 }"
          ></ng-otp-input>
        </div>
      </div>
      <form
        *ngIf="isChangePassword"
        [formGroup]="changePasswordForm"
        (submit)="changePassword()"
        class="row"
      >
        <div class="form-group col-md-6 offset-md-3">
          <label for="exampleInputPassword">New Password</label>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            placeholder="Enter Password"
          />
        </div>
        <div
          *ngIf="c.password.invalid && (c.password.dirty || c.password.touched)"
          class="alert alert-danger mt-1"
        >
        Your password must be of at least 8 characters, must contain a capital letter, a small letter, a number, a symbol and should not contain space.
        </div>
        <button
          type="submit"
          class="btn btn-primary col-md-6 offset-md-3"
          [disabled]="!changePasswordForm.valid"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</section>
<app-footer></app-footer>
