<app-header></app-header>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Daily Horoscope
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Daily Horoscope</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<ngx-spinner></ngx-spinner>
<!-- Talk to astrologer page start-->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row new-product d-flex flex-column position-relative">
      <h4 class="text-capitalize text-center d-none d-lg-block">
        Daily Horoscope
      </h4>
      <h5 class="text-capitalize text-center d-lg-none">Daily Horoscope</h5>
      <h4 class="text-center"><span></span></h4>
    </div>
    <div class="row mt-3 my-lg-5 ">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let data of dataList">
        <div class="hs_sign_left_tabs_wrapper hs_cn_tabs_wrapper">
          <div class="hs_slider_tabs_icon_wrapper hs_cn_tabs_icon_wrapper ">
            <img [src]="getSanitizeUrl(data.image)" alt="team-img" class="img rounded horo-img" />
          </div>
          <div class="hs_slider_tabs_icon_cont_wrapper">
            <ul class="mob-res-horoscope">
              <li>
                <a [routerLink]="['/daily-horoscope/', data.name]" class="hs_tabs_btn">{{ data.name }}</a>
              </li>
              <li>{{ data.time }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Talk to astrologer page end-->
<app-footer></app-footer>