import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PassDataService {
  private userData = new BehaviorSubject({});
  data = this.userData.asObservable();
  constructor() {}

  startOrder(data: any) {
    this.userData.next(data);
  }
}
