<!-- Testimonials Start -->
<hr />
<section class="mt-5 d-none d-lg-block" *ngIf="footerEndpoint != 'contact-us' && footerEndpoint != 'home'">
  <div class="row">
    <div class="container">
      <div class="row new-product d-flex flex-column justify-content-center">
        <h4 class="text-capitalize text-center">testimonials</h4>
        <h4><span></span></h4>
      </div>
    </div>
  </div>
  <div class="row bg-inactive mt-5">
    <div class="container">
      <mdb-carousel isControl="true" class="
          d-none d-lg-block d-md-block
          carousel-multi-item
          multi-animation
          mt-5
          pb-3
          testi-carousel
        " typ="'carousel-multi-item'" animatio="'slide'">
        <mdb-carousel-item class="row" *ngFor="let list of testimonialList">
          <div class="col-4 py-3" *ngFor="let data of list">
            <div class="
                h-75
                card
                pro-test
                shadow
                p-4
                mt-3
                fn-1
                lh-2
                position-relative
              ">
              <i class="fi fi-quote-a-left position-absolute bottom-1 end-1"></i>
              <i class="fi fi-quote-a-right position-absolute start-1 top-1"></i>
              <p class="line-size-6">{{ data.description }}</p>
            </div>
            <div class="d-flex mt-2">
              <img src="../../../assets/images/64X69.png" class="size-50 mr-2 rounded-circle" />
              <p class="line-size-8 mx-2 ml-3">
                <strong>{{ data.person_name }}</strong> <br />
                <span class="fn-1">{{ data.person_location }}</span>
              </p>
            </div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</section>

<!-- Testimonials Start -->

<!-- Partner Start -->
<section *ngIf="footerEndpoint != 'contact-us'" class="mt-lg-5 mt-3">
  <div class="row new-product d-flex flex-column justify-content-center">
    <h4 class="d-none d-lg-block text-capitalize text-center">
      astro river in news
    </h4>
    <h5 class="d-lg-none text-capitalize text-center">astro river in news</h5>
    <h4><span></span></h4>
  </div>
  <div class="mt-3 mt-lg-5 py-3 row bg-inactive" style="filter: grayscale(100%)">
    <div class="container">
      <swiper [config]="partnerConfig" class="d-flex justify-content-center">
        <ng-template swiperSlide>
          <img class="w-100" src="../../../assets/images/media/media1.png" />
        </ng-template>
        <ng-template swiperSlide>
          <img class="w-100" src="../../../assets/images/media/media2.png" />
        </ng-template>
        <ng-template swiperSlide>
          <img class="w-100" src="../../../assets/images/media/media3.png" />
        </ng-template>
        <ng-template swiperSlide>
          <img class="w-100" src="../../../assets/images/media/media4.png" />
        </ng-template>
      </swiper>
    </div>
  </div>
</section>
<!-- Partner End -->
<!-- NewsLetter Start -->
<section class="py-lg-5 py-3 newsletter" *ngIf="footerEndpoint != 'contact-us'">
  <div class="container text-center">
    <h2 class="fw-600 mb-2">Subscribe</h2>
    <p class="mb-3">
      Receive Your Daily, Weekly, Monthly & Annual HOROSCOPES and Much More...
    </p>
    <div class="d-flex justify-content-center">
      <div class="input-group w-75">
        <input type="email" class="form-control shadow-sm img-rounded-tbl text-white h-search py-2"
          placeholder="Email Address" aria-label="email address" aria-describedby="button-addon2"
          style="background: #00000085" />
        <a href="https://zc.vg/MIMhR" target="_blank" class="
            btn
            shadow-0
            bg-red
            text-white
            fw-500
            text-uppercase
            px-3
            fn-1
            py-2
            m-0
           img-rounded-tbr
          " type="button" id="button-addon2" data-mdb-ripple-color="dark">
          subscribe
        </a>
      </div>
    </div>
  </div>
</section>
<!-- NewsLetter End -->
<!-- Payment Start -->
<section class="d-none d-lg-block my-5" *ngIf="footerEndpoint != 'contact-us'">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="card h-100" style="background: #6cbf33">
          <div class="card-header text-white text-capitalize mx-2 my-3" style="background: #1e8301">
            payment methods
          </div>
          <div class="card-body py-0 pb-2">
            <p class="text-white mb-3">
              We accept all major credit and Debit Cards and payment methods.
            </p>
            <div class="row px-1">
              <div class="col-3 p-0 px-1">
                <img class="w-100" src="../../../assets/icons/master.jpg" />
              </div>
              <div class="col-3 p-0 px-1">
                <img class="w-100" src="../../../assets/icons/paypal.jpg" />
              </div>
              <div class="col-3 p-0 px-1">
                <img class="w-100" src="../../../assets/icons/visa.jpg" />
              </div>
              <div class="col-3 p-0 px-1">
                <img class="p-2 h-100 w-100 bg-active" src="../../../assets/icons/upi.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="card h-100">
          <div class="card-header text-white text-capitalize" style="background: #763136">
            delivery in 72 hours
          </div>
          <div class="card-body pl-2 pr-1">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="../../../assets/icons/delivery-iocn.jpg" class="rounded-circle size-70" />
              </div>
              <div class="flex-grow-1 ml-2 ms-3">
                We put best efforts to ensure that services and products that
                your order reach you within 72 hours.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="card h-100">
          <div class="card-header text-white text-capitalize" style="background: #396d78">
            100% satisfaction guarantee
          </div>
          <div class="card-body pl-2 pr-1">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="../../../assets/icons/satisfaction.jpg" class="rounded-circle size-70" />
              </div>
              <div class="flex-grow-1 ml-2 ms-3">
                By associating with us, you can be assured of premium services
                and high-quality products with super fast delivery. Your
                satisfaction, our motivation!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Mobile -->
<section class="d-lg-none py-3 bg-white">
  <div class="d-flex payment text-center">
    <div class=" col-4 text-center">
      <img src="../../../assets/images/home/payment-3.png" class="w-50" />
      <p class="pay-head-txt lh-1 mt-2">Private & Confidential</p>
    </div>
    <div class="col-4  text-center">
      <img src="../../../assets/images/home/payment-1.png" class="w-50" />
      <p class="pay-head-txt lh-1 mt-2">Verified <br>Astrologer</p>
    </div>
    <div class="col-4  text-center">
      <img src="../../../assets/images/home/payment-2.png" class="w-50" />
      <p class="pay-head-txt lh-1 mt-2">100% Secure Payment</p>
    </div>

  </div>
</section>
<!-- End -->
<!-- Payment END -->
<!-- hs footer wrapper Start -->
<section class="thick-border">
  <div class="row bg-dark-red">
    <div class="container p-5 px-lg-0">
      <div class="row text-left">
        <div class="col-lg-4 col-md-4 col-12">
          <img src="assets/images/logo png white astroriver.png" alt="footer_logo" class="w-50 img-responsive" />
          <h3 class="
              mt-3
              mb-2
              text-left
              d-none d-lg-bock
              secondary-text
              fn-4
              fw-600
            ">
            +91&nbsp;70091-27641
          </h3>
          <h4 class="mt-3 mb-2 text-left d-lg-none secondary-text fw-600">
            +91&nbsp;70091-27641
          </h4>
          <p class="my-4 text-justify text-white" style="font-size: 0.9rem">
            AstroRiver is the best astrology website for online Astrology
            predictions. Talk to Astrologer on call and get answers to all your
            worries by seeing the future life through Astrology Kundli
            Predictions from the best Astrologers from India.
          </p>
          <div class="text-white header-icon">
            <a target="_blank" href="http://www.facebook.com/astroriverdotcom">
              <i class="fi fi-facebook"></i>
            </a>
            <a target="_blank" href="http://www.twitter.com/astroriver">
              <i class="fi fi-twitter"></i>
            </a>
            <a target="_blank" href="https://www.instagram.com/astroriverofficial/">
              <i class="fi fi-instagram"></i>
            </a>
            <a target="_blank" href="http://www.pinterest.com/astroriver">
              <i class="fi fi-pinterest"></i>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/astro-river/">
              <i class="fi fi-linkedin"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 sm-margin-top-3 xs-margin-top-3">
          <h5 class="mb-2 fw-600 secondary-text text-capitalize">
            Quick Links
          </h5>
          <ul class="ftr-font">
            <li><a routerLink="/coming-soon">Your Life</a></li>
            <li><a routerLink="/coming-soon">Remedies</a></li>
            <li><a routerLink="/astro-shop">Astro Shop</a></li>
            <li><a routerLink="/report-astro-list">Get Report</a></li>
            <li>
              <a routerLink="/call-astro-list">Talk to Astrologers</a>
            </li>
            <li>
              <a routerLink="/chat-astro-list">Chat with Astrologers</a>
            </li>
          </ul>
          <h5 class="my-2 fw-600 secondary-text text-capitalize">
            quick links
          </h5>
          <ul class="ftr-font">
            <li><a routerLink="/about-us">About Us</a></li>
            <li><a routerLink="/contact-us">Contact Us</a></li>
            <li><a [routerLink]="['/page', 'faq']">FAQ's</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-3 col-6 xs-margin-top-3 xs-margin-top-3">
          <h5 class="mb-2 fw-600 secondary-text text-capitalize">
            useful links
          </h5>
          <ul class="ftr-font">
            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
            <!-- <li><a routerLink="/pricing-policy">Pricing Policy</a></li> -->
            <li>
              <a routerLink="/terms-conditions">Terms & Conditions</a>
            </li>
            <li><a routerLink="/disclaimer">Disclaimer</a></li>
            <li>
              <a routerLink="/return-policy">Shipping & Returns Policy</a>
            </li>
            <li>
              <a routerLink="/refund-policy">Refund & Cancellation Policy</a>
            </li>
            <li>
              <a href="https://careers.astroriver.com" target="_blank">Jobs & Career</a>
            </li>
          </ul>
          <h5 class="my-2 fw-600 secondary-text text-capitalize">
            Astrologer links
          </h5>
          <ul class="ftr-font">
            <li>
              <a class="" href="https://astro.astroriver.com/#/">Astrologer Signup</a>
            </li>
            <li>
              <a class="" href="https://astro.astroriver.com/#/login">Astrologer Login</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 col-6 xs-margin-top-3 sm-margin-top-3 p-0">
          <h5 class="mb-2 fw-600 secondary-text text-capitalize">
            download mobile app
          </h5>
          <a target="_blank" href="https://apps.apple.com/in/app/astroriver/id1575901251"><img
              src="../../../assets/icons/app_store.png" class="w-100  rounded" /></a><br />
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.astroriver.customer"><img
              src="../../../assets/icons/play_store.png" class="w-100 rounded mt-2" /></a>
          <div class="col-12 p-0 mt-4 text-center">
            <img class="col-4 p-0 px-1 rounded" src="../../../assets/icons/master.jpg" />
            <img class="col-4 p-0 px-1 rounded" src="../../../assets/icons/visa.jpg" />
            <img class="col-4 p-0 px-1 rounded" src="../../../assets/icons/paypal.jpg" />
          </div>
        </div>
      </div>
      <!-- hs footer wrapper End -->
    </div>
  </div>
  <!----Go to Top Button Start-->
  <div class="btm-arr-btn bg-dark">
    <a class="go-top" (click)="goToTop()"><i class="fa-solid fa-arrow-up text-white"></i></a>
  </div>

  <!----Go to Top Button End-->
  <div class="row bg-copy-right justify-content-center d-flex d-none d-lg-block">
    <p class="py-3 text-white fn-2 text-center mb-0">
      Copyright © {{ current_year }} Astro River. All Rights Reserved.
    </p>
  </div>
</section>
<!-- hs footer wrapper end -->