import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-report-order-list',
  templateUrl: './report-order-list.component.html',
  styleUrls: ['./report-order-list.component.css']
})
export class ReportOrderListComponent implements OnInit {

  dataList:any = []
  filterType = 'all'
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  
  constructor(
    private userdata: UserdataService,
    private orderService: OrderServicesService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getReportOrders()
  }

  getReportOrders(){
    this.filterType = 'all'
    this.spinner.show()
    this.orderService.showReportOrders({customerId:this.userdata.getCustomerId()}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
        //console.log(res)
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  pendingReport(){
    this.filterType = 'answer'
    this.spinner.show()
    this.orderService.showReportOrders({customerId:this.userdata.getCustomerId(),is_astro_answer:false}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

  noReview(){
    this.filterType = 'review'
    this.spinner.show()
    this.orderService.showReportOrders({customerId:this.userdata.getCustomerId(),is_order_reviewed:false,is_astro_answer:true}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

  noFeedback(){
    this.filterType = 'feedback'
    this.spinner.show()
    this.orderService.showReportOrders({customerId:this.userdata.getCustomerId(),is_astro_answer:true,is_feedback_given:false}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

}
