import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueService } from 'src/app/service/issue/issue.service';
import { LoginService } from 'src/app/service/login/login.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css'],
})
export class ReportDetailsComponent implements OnInit {
  dataList: any = '';
  id: any = '';
  /**
   *
   * Collapse
   */
  public isPersonCollapsed = true;
  public isIssueGenerateCollapsed = true;
  public isIssueCollapsed = true;
  public isRemedyCollapsed = true;
  /**------------------ */
  constructor(
    private orderService: OrderServicesService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private issueService: IssueService,
    private router: Router,
    private loginService: LoginService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getSingleService();
    this.issueChatForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });
  }
  moreLess = true;
  descToggle() {
    this.moreLess = !this.moreLess;
  }
  serverTimeFormat: any;
  remedy: any;
  getSingleService() {
    this.orderService
      .singleServiceOrders({
        customerId: this.userdata.getCustomerId(),
        _id: this.id,
      })
      .subscribe(
        (res: any) => {
          if (res.suggested_remedy != null) {
            this.remedy = res.suggested_remedy;
          }
          this.dataList = res.data;
          var serverTime = moment(res.server_current_time);
          this.serverTimeFormat = serverTime.utc().format('YYYY-MM-DD');
          if (this.dataList?.reportId?.is_feedback_given) {
            this.feedbackForm.patchValue({ feedback_value: this.dataList.reportId?.feedback_value })
          }
          if(this.dataList?.reportId?.is_order_reviewed){
            this.reviewForm.patchValue({ customer_review_stars: this.dataList?.reportId?.customer_review_stars })
            this.reviewForm.patchValue({ customer_review: this.dataList?.reportId?.customer_review })
          }
          if (this.dataList.is_customer_issue_created) {
            this.issueChatForm.patchValue({
              _id: this.dataList.customer_issueId._id,
            });
          }
          // var date = moment("12/12/2020");
          // var startdateComponent = date.utc().format('YYYY-MM-DD');
          // var startDate = moment(startdateComponent, 'YYYY-MM-DD');
          // var endDate = moment(this.serverTimeFormat, 'YYYY-MM-DD');
          // var dayDiff = endDate.diff(startDate, 'days');
        },
        (err) => {
          this.router.navigateByUrl('/report-order-list');
        }
      );
  }

  /**
   * Feedback
   */
  feedbackForm: FormGroup = new FormGroup({
    customerId: new FormControl(''),
    feedback_value: new FormControl('', [Validators.required]),
    reportId: new FormControl(''),
  });

  get g() {
    return this.feedbackForm.controls;
  }

  giveFeedback() {
    var msg = this.feedbackForm.get('feedback_value')?.value.trim();
    var count = (msg.match(/\d/g) || []).length;
    var emailsArray = msg.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    );
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire(
        'Invalid Feedback',
        "This message cannot be sent as our policy doesn't allows to share number or email",
        'error'
      );
      return;
    }
    this.feedbackForm.patchValue({ feedback_value: msg });
    this.spinner.show();
    this.feedbackForm.patchValue({ reportId: this.dataList.reportId?._id });
    this.feedbackForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.orderService.feedbackReportOrders(this.feedbackForm.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toast.success(res.message, 'Success');
        this.changeFeedback = false;
        this.getSingleService();
        this.modalService.dismissAll()
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Check Seven days
   */
  checkSevenDays(val: any) {
    var str = val;
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    var enddateComponent = moment().utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(this.serverTimeFormat, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 7) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check three days
   */
  checkThreeDays(val: any) {
    var str = val;
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    var enddateComponent = moment().utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(this.serverTimeFormat, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 3) {
      return true;
    } else {
      return false;
    }
  }

  changeFeedback = false;
  changeReview = false;
  showFeedbackForm = false;
  showReviewForm = false;

  updateFeedback() {
    this.changeFeedback = !this.changeFeedback;
  }

  updateReview() {
    this.changeReview = !this.changeReview;
  }

  showFeedbackFormDetails() {
    this.showFeedbackForm = !this.showFeedbackForm;
  }

  showReviewFormDetails() {
    this.showReviewForm = !this.showReviewForm;
  }

  /**
   * Reply
   */
  reviewForm: FormGroup = new FormGroup({
    reportId: new FormControl(''),
    customerId: new FormControl(''),
    customer_review_stars: new FormControl(3, [Validators.required]),
    customer_review: new FormControl('', [Validators.required]),
  });

  get r() {
    return this.reviewForm.controls;
  }

  giveReview() {
    var msg = this.reviewForm.get('customer_review')?.value.trim();
    var count = (msg.match(/\d/g) || []).length;
    var emailsArray = msg.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    );
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire(
        'Invalid Review',
        "This message cannot be sent as our policy doesn't allows to share number or email",
        'error'
      );
      return;
    }
    this.reviewForm.patchValue({ customer_review: msg });
    this.spinner.show();
    this.reviewForm.patchValue({ reportId: this.dataList.reportId?._id });
    this.reviewForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.orderService.reviewReportOrders(this.reviewForm.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toast.success(res.message, 'Success');
        this.changeReview = false;
        this.getSingleService();
        this.modalService.dismissAll()
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }
  currentRate = 4;

  /**
   * Issue
   */
  issueForm: FormGroup = new FormGroup({
    customerId: new FormControl(''),
    issue_type: new FormControl('2', [Validators.required]),
    is_service_issue: new FormControl(true, [Validators.required]),
    issue_title: new FormControl('', [Validators.required]),
    issue_description: new FormControl('', [Validators.required]),
    orderserviceId: new FormControl(''),
    amount: new FormControl(''),
  });

  get i() {
    return this.issueForm.controls;
  }

  addIssue() {
    this.issueForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.issueForm.patchValue({ orderserviceId: this.dataList._id });
    this.issueForm.patchValue({ amount: this.dataList.customer_amount });
    this.issueService.addIssue(this.issueForm.value).subscribe(
      (res: any) => {
        this.getSingleService();
        this.toast.success(res.message, 'Success');
        this.modalService.dismissAll()
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Connect Report Promo
   */
  getPromoCall(promotype: any) {
    /**
     * Check if person phone is verified
     */
    this.loginService
      .getSinglePerson({ _id: this.dataList.personId?._id })
      .subscribe(
        (res: any) => {
          if (!res.data.is_number_verified) {
            var orderObject = {
              customerId: this.userdata.getCustomerId,
              connect: promotype,
              reportId: this.dataList.reportId?._id,
              orderCheck: 'reportpromo',
            };
            sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
            this.router.navigateByUrl('/verify-call-otp/' + res.data._id);
            return;
          }
          /**
           * Check If User is busy
           */
          this.loginService
            .myProfile({ _id: this.userdata.getCustomerId() })
            .subscribe((res: any) => {
              this.userdata.setProfileData(res.data)
              if (res.data.is_current_call || res.data.is_current_chat) {
                Swal.fire(
                  "Call can't be connected",
                  'Complete your ongoing order to place another one',
                  'info'
                );
                return;
              }
              /**
               * Place Order
               */
              this.orderService
                .promoReportOrders({
                  customerId: this.userdata.getCustomerId,
                  connect: promotype,
                  reportId: this.dataList.reportId?._id,
                })
                .subscribe(
                  (res: any) => {
                    this.toast.success(res.message, 'Success');
                    this.getSingleService();
                  },
                  (err) => {
                    this.toast.error(err.error.message, 'Alert');
                  }
                );
            });
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /** Open Feedback modal */
  open(content: any, data: any) {
    if (data == 'feedback') {
      this.showFeedbackForm = !this.showFeedbackForm;
    }
    if (data == 'review') {
      this.showReviewForm = !this.showReviewForm;
    }
    this.modalService.open(content);
  }

  /** Reply Issue */
   issueChatForm: FormGroup = new FormGroup({
    customerId: new FormControl(''),
    _id: new FormControl('', [Validators.required]),
    reply_by_system: new FormControl('', [Validators.required]),
    is_reply_by_customer: new FormControl(true),
  });

  replyIssue() {
    this.issueService.replyIssue(this.issueChatForm?.value).subscribe(
      (res: any) => {
        this.getSingleService();
        this.toast.success(res.message, 'Success');
        this.issueChatForm.patchValue({reply_by_system:''})
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Format Text
   */
   textFormat(value:any) {
    return value.replace(/(?:\r\n|\r|\n)/g, "<br>")
  }
}
