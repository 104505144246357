import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-customer-sidebar',
  templateUrl: './customer-sidebar.component.html',
  styleUrls: ['./customer-sidebar.component.css']
})
export class CustomerSidebarComponent implements OnInit {

  constructor(private userdata: UserdataService, private loginService:LoginService, private toast: ToastrService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    this.getMyProfile()
  }

  myProfile: any;
  getMyProfile() {
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          this.myProfile = res.data;
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  openDialog(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.logout();
        }
      );
  }

  /**
   * Logout
   */
   logout() {
    this.loginService
      .logout({
        is_web: true,
        email: this.userdata.getEmail(),
        fcm_token: this.userdata.getFcm(),
      })
      .subscribe(
        (res: any) => {
          this.userdata.removeData();
          this.toast.success(res.message, 'Success');
          // this.modalService.dismissAll();
          this.router.navigateByUrl('/home');
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        },
        () => {}
      );
  }

}
