import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from 'src/app/service/booking/booking.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { ProductService } from 'src/app/service/product/product.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cart-product-details',
  templateUrl: './cart-product-details.component.html',
  styleUrls: ['./cart-product-details.component.css']
})
export class CartProductDetailsComponent implements OnInit {

  BaseUrl: string = '';
  dataList: any = [];
  product_id: any;
  astrologerName: string = '';
  code: any;
  saveOrderForm: FormGroup = new FormGroup({
    customerId: new FormControl(''),
    productId: new FormControl(''),
    astroId: new FormControl(null),
    consultantId: new FormControl(null),
    addOns: new FormControl(''),
    quantity: new FormControl(
      '1',
      Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
    ),
    addressId: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl('', Validators.email),
    countryCode: new FormControl(''),
    phone: new FormControl('', Validators.pattern('[0-9]{7,10}')),
    gender: new FormControl(''),
    maritalStatus: new FormControl(''),
    comments: new FormControl(''),
    dob: new FormControl(''),
    tob: new FormControl(''),
    isClearCart: new FormControl(false),
  });
  closeResult: string = '';

  get f() {
    return this.saveOrderForm.controls;
  }

  alladdons: any = [];

  constructor(
    private product: ProductService,
    private booking: BookingService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private trusturl: DomSanitizer,
    private openService: OpenApiService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  goBack() {
    window.history.back();
  }
  today: any;
  ngOnInit(): void {
    this.saveOrderForm.patchValue({ customerId: this.userdata.getId() });
    this.saveOrderForm.patchValue({
      productId: this.route.snapshot.paramMap.get('id'),
    });
    this.product_id = this.route.snapshot.paramMap.get('id');
    //this.astrologerName = localStorage.getItem('astrologerName')
    this.getFieldList();
    this.getConsultant();
    this.getCountries();
    this.code = this.userdata.isIndia();
    this.today = moment().format('YYYY-MM-DD');
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  isNameOn: Boolean = false;
  isEmailOn: Boolean = false;
  isPhoneOn: Boolean = false;
  isQuantityOn: Boolean = false;
  isMaritalStatusOn: Boolean = false;
  isGenderOn: Boolean = false;
  isCommentsOn: Boolean = false;
  isAddressOn: Boolean = false;
  isRingSizeOn: Boolean = false;
  isDobOn: Boolean = false;
  isTobOn: Boolean = false;
  isAddonList = '';
  is_addon_associate = '';
  addonarray: any = new Array();
  isAstrologerAssociate: Boolean = false;
  activeAddon = -1;
  /**Check if address id already exist in cart */
  isAddressExist = false
  /**----------- */
  getFieldList() {
    this.spinner.show();
    this.product.getProductDetail({ _id: this.product_id }).subscribe(
      (res: any) => {
        //console.log(res);
        // console.log("Addon detail",res.data.addon_detail)
        // console.log("Addon assos",res.data.is_addon_associate)
        this.is_addon_associate = res.data.is_addon_associate;
        this.isNameOn = res.data.fields_applied.isNameOn;
        this.isEmailOn = res.data.fields_applied.isEmailOn;
        this.isPhoneOn = res.data.fields_applied.isPhoneOn;
        this.isQuantityOn = res.data.fields_applied.isQuantityOn;
        this.isMaritalStatusOn = res.data.fields_applied.isMaritalStatusOn;
        this.isGenderOn = res.data.fields_applied.isGenderOn;
        this.isCommentsOn = res.data.fields_applied.isCommentsOn;
        this.isAddressOn = res.data.fields_applied.isAddressOn;
        this.isRingSizeOn = res.data.fields_applied.isRingSizeOn;
        this.isDobOn = res.data.fields_applied.isDobOn;
        this.isTobOn = res.data.fields_applied.isTobOn;
        this.isAstrologerAssociate = res.data.is_astrologer_associate;
        if (this.isNameOn) {
          this.saveOrderForm.patchValue({ name: this.userdata.getName() });
        }
        if (this.isEmailOn) {
          this.saveOrderForm.patchValue({ email: this.userdata.getEmail() });
        }
        if (this.isPhoneOn) {
          this.saveOrderForm.patchValue({ countryCode: '91' });
        }
        if (this.isGenderOn) {
          this.saveOrderForm.patchValue({ gender: 'male' });
        }
        // this.isAddonList = res.data.addon_detail
        this.addonarray = res.data.addon_detail;
        this.dataList = res.data;
        console.log('data list ', this.dataList)
        if (res.cartData.addressId != null && res.cartData.addressId != undefined && res.cartData.addressId != '') {
          this.isAddressExist = true
          this.saveOrderForm.patchValue({ addressId: res?.cartData?.addressId?._id })
        }
        //console.log(this.dataList);
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Get Consultant
   */
  consultantarray: any = [];
  getConsultant() {
    this.spinner.show();
    this.product
      .getConsultantList({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          //console.log(res.data);
          this.spinner.hide();
          this.consultantarray = res.data;
          // console.log(this.consultantarray)
        },
        (err: any) => {
          this.spinner.hide();
          this.consultantarray = [];
        }
      );
  }
  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }
  /**
   * Remove more Addons
   */
  deleteAddon(i: any) {
    this.newAddonArray.splice(i, 1);
  }

  /*
   * Add More Addons
   */
  addonForm: any;
  addonObject: any;
  newAddonArray: any;
  selectedAddon: any = [];
  getaddon(data: any, index: any, event: any) {
    //console.log('yesssssssss')
    //console.log(this.alladdons,'start')
    this.addonForm = {
      addOnId: data._id,
      productId: this.product_id,
    };
    this.spinner.show();
    this.product.getSingleAddon(this.addonForm).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.addOnData.addon_type == 1) {
          this.newAddonArray = {
            addOnName: res.addOnData.addon_name,
            addOnImage: res.addOnData.addon_image,
            addOnPrice: res.addOnData.addon_price,
            addon_usd_price: res.addOnData.addon_usd_price,
            addOnSize: size,
          }
        } else {
          this.newAddonArray = {
            addOnName: res.addOnData.addon_name,
            addOnImage: res.addOnData.addon_image,
            addOnPrice: res.addOnData.addon_price,
            addon_usd_price: res.addOnData.addon_usd_price,
          }
        }
        if (!event.target.checked) {
          //console.log('include')
          if (this.selectedAddon.includes(index)) {
            var objIndex = this.selectedAddon.findIndex((item: any) => item == index);
            this.selectedAddon.splice(objIndex, 1);
            var addOnIndex = this.alladdons.findIndex((item: any) => item.addOnName == res.addOnData.addon_name);
            this.alladdons.splice(addOnIndex, 1);
          }
        } else {
          //console.log('not include')
          if (data.addon_type == 1) {
            var size: any = '';
            var nosize: any = '';
            size = (<HTMLInputElement>document.getElementById('ring' + index)).value;
            nosize = (<HTMLInputElement>document.getElementById('nosize' + index)).checked;
          }
          if (data.addon_type == 1 && ((size == '' || size == null || typeof size == 'undefined') && (nosize == false || nosize == 'false'))) {
            this.toast.error('Please Enter Ring Size', 'Alert');
            event.target.checked = false
            return;
          }
          if(nosize == true || nosize == 'true'){
            this.newAddonArray.addOnSize = 'not sure'
          }else{
            this.newAddonArray.addOnSize = size
          }
          this.selectedAddon.push(index);
          this.alladdons.push(this.newAddonArray)
        }
        //console.log('out of loop')         
        this.saveOrderForm.patchValue({
          addOns: this.alladdons,
        });
        this.modalService.dismissAll();
        //console.log(this.alladdons)
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Show address popup
   */
  addressList: any = [];
  isSelectAddress: boolean = true;
  isAddNewAddress: boolean = false;
  isUpdateAddress: boolean = false;
  isAddressSelected: boolean = false;
  selectedAddress: any = '';

  addAddressForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    customerId: new FormControl(''),
    countryCodePhoneNum: new FormControl('91', Validators.required),
    phoneNum: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{7,10}'),
    ]),
    countryCodeAltPhoneNum: new FormControl('91'),
    alternatePhoneNum: new FormControl(
      '',
      Validators.pattern('[0-9]{7,10}')
    ),
    flatNo: new FormControl('', Validators.required),
    locality: new FormControl('', Validators.required),
    landmark: new FormControl(''),
    city: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    state: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    country: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    pinCode: new FormControl('', [
      Validators.required
    ]),
  });

  updateAddressForm: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    customerId: new FormControl(''),
    countryCodePhoneNum: new FormControl('91', Validators.required),
    phoneNum: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{7,10}'),
    ]),
    countryCodeAltPhoneNum: new FormControl('91'),
    alternatePhoneNum: new FormControl(
      '',
      Validators.pattern('[0-9]{7,10}')
    ),
    flatNo: new FormControl('', Validators.required),
    locality: new FormControl('', Validators.required),
    landmark: new FormControl(''),
    city: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    state: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    country: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    pinCode: new FormControl('', [
      Validators.required
    ]),
  });

  get a() {
    return this.addAddressForm.controls;
  }

  get u() {
    return this.updateAddressForm.controls;
  }

  openAddressPopup(content: any) {
    this.isSelectAddress = true;
    this.isAddNewAddress = !this.isSelectAddress;
    this.isUpdateAddress = !this.isSelectAddress;
    this.getMyAddressList();
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      scrollable: true,
    });
  }

  addressBackPage() {
    this.isSelectAddress = true;
    this.isAddNewAddress = !this.isSelectAddress;
    this.isUpdateAddress = !this.isSelectAddress;
  }

  getMyAddressList() {
    this.spinner.show();
    this.booking
      .getAddressesList({ customerId: this.userdata.getId(), status: true })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.addressList = res.result;
          //console.log(this.addressList);
        },
        (err: any) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  showAddNewAddressForm() {
    this.isAddNewAddress = true;
    this.isSelectAddress = !this.isAddNewAddress;
    this.isUpdateAddress = !this.isAddNewAddress;
  }

  selectAddress(data: any) {
    this.isAddressSelected = true;
    this.saveOrderForm.patchValue({ addressId: data._id });
    this.selectedAddress = data;
    this.modalService.dismissAll();
  }

  saveAddress() {
    this.spinner.show();
    this.addAddressForm.patchValue({ customerId: this.userdata.getId() });
    this.booking.addAddress(this.addAddressForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
        this.getMyAddressList();
        this.isSelectAddress = true;
        this.isAddNewAddress = !this.isSelectAddress;
        this.isUpdateAddress = !this.isSelectAddress;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  deleteAddress(id: any) {
    Swal.fire({
      title: 'Delete Address!',
      text: 'Are you sure, you want to delete this address?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.booking.deleteAddress({ status: false, _id: id }).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.toast.success(res.message, 'Success');
            this.getMyAddressList();
          },
          (err: any) => {
            this.spinner.hide();
            this.toast.error(err.error.message, 'Alert');
          }
        );
      }
    });
  }

  showUpdateAddressForm(address: any) {
    this.isUpdateAddress = true;
    this.isSelectAddress = !this.isUpdateAddress;
    this.isAddNewAddress = !this.isUpdateAddress;
    this.updateAddressForm.patchValue({ _id: address._id });
    this.updateAddressForm.patchValue({ name: address.name });
    this.updateAddressForm.patchValue({ customerId: this.userdata.getId() });
    this.updateAddressForm.patchValue({
      countryCodePhoneNum: address.countryCodePhoneNum,
    });
    this.updateAddressForm.patchValue({ phoneNum: address.phoneNum });
    this.updateAddressForm.patchValue({
      countryCodeAltPhoneNum: address.countryCodeAltPhoneNum,
    });
    this.updateAddressForm.patchValue({
      alternatePhoneNum: address.alternatePhoneNum,
    });
    this.updateAddressForm.patchValue({ flatNo: address.flatNo });
    this.updateAddressForm.patchValue({ locality: address.locality });
    this.updateAddressForm.patchValue({ landmark: address.landmark });
    this.updateAddressForm.patchValue({ city: address.city });
    this.updateAddressForm.patchValue({ state: address.state });
    this.updateAddressForm.patchValue({ country: address.country });
    this.updateAddressForm.patchValue({ pinCode: address.pinCode });
  }

  updateAddress() {
    this.spinner.show();
    this.booking.updateAddress(this.updateAddressForm.value).subscribe(
      (res: any) => {
        this.isSelectAddress = true;
        this.isAddNewAddress = !this.isSelectAddress;
        this.isUpdateAddress = !this.isSelectAddress;
        this.getMyAddressList();
        this.spinner.hide();
        this.toast.success(res.message, 'Success');
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Save Order
   */
  saveOrder() {
    this.formModel.customerId = this.userdata.getCustomerId();
    this.formModel.productId = this.product_id;
    // this.formModel.astroId = localStorage.getItem('astrologerId')
    if (!this.isAstrologerAssociate){
      this.saveOrderForm.patchValue({astroId: undefined})
    }
    if (this.saveOrderForm.value.consultantId == null){
      this.saveOrderForm.patchValue({consultantId: undefined})
    }
    if (
      this.isAstrologerAssociate &&
      (this.saveOrderForm.get('astroId')?.value == '' || this.saveOrderForm.get('astroId')?.value == null)
    ) {
      this.toast.error('Astrologer is Required', 'Alert');
      return;
    }

    if (this.isNameOn && this.saveOrderForm.get('name')?.value == '') {
      this.toast.error('Name is Required', 'Alert');
      return;
    }
    if (this.isEmailOn && this.saveOrderForm.get('email')?.value == '') {
      this.toast.error('Email is Required', 'Alert');
      return;
    }
    if (
      this.isPhoneOn &&
      (this.saveOrderForm.get('phone')?.value == '' ||
        this.saveOrderForm.get('countryCode')?.value == '')
    ) {
      this.toast.error('Country Code and Phone is Required', 'Alert');
      return;
    }
    if (this.isGenderOn && this.saveOrderForm.get('gender')?.value == '') {
      this.toast.error('Gender is Required', 'Alert');
      return;
    }
    if (this.isDobOn && this.saveOrderForm.get('dob')?.value == '') {
      this.toast.error('Date of Birth is Required', 'Alert');
      return;
    }
    if (this.isTobOn && this.saveOrderForm.get('tob')?.value == '') {
      this.toast.error('Time of Birth is Required', 'Alert');
      return;
    }
    if (
      this.isMaritalStatusOn &&
      this.saveOrderForm.get('maritalStatus')?.value == ''
    ) {
      this.toast.error('Marital Status is Required', 'Alert');
      return;
    }
    if (this.isCommentsOn && this.saveOrderForm.get('comments')?.value == '') {
      this.toast.error('Remark is Required', 'Alert');
      return;
    }
    if (this.isQuantityOn && this.saveOrderForm.get('quantity')?.value == '') {
      this.toast.error('Quantity is Required', 'Alert');
      return;
    }
    if (this.isAddressOn && this.saveOrderForm.get('addressId')?.value == '') {
      this.toast.error('Address is Required', 'Alert');
      return;
    }
    // console.log(this.saveOrderForm.value)
    // return
    this.spinner.show();
    this.booking.saveOrder(this.saveOrderForm.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        //this.router.navigateByUrl('/checkout/' + res.result._id);
        if (res.success) {
          this.router.navigateByUrl('/view-cart');
        } else {
          if (res.isAstroProductInCart) {
            this.reOrder(res.message)
          } else {
            this.toast.error(res.message, 'Alert')
          }
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error('Alert');
      }
    );
  }

  reOrder(msg: any) {
    Swal.fire({
      title: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Clear Cart'
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log('clicked')
        this.saveOrderForm.patchValue({ isClearCart: true })
        this.saveOrder()
      }
    })
  }


  formModel: any = {
    customerId: '',
    productId: '',
    astroId: '',
    consultantId: '',
    addOns: '',
    quantity: '',
    addressId: '',
    name: '',
    email: '',
    phone: '',
    countryCode: '',
    gender: '',
    maritalStatus: '',
  };

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  /** Increase Decrease Quantity */
  incDecQuantity(val: any) {
    var quantity = Number(this.saveOrderForm.value.quantity)
    if (val == 'minus') {
      if (quantity > 1) {
        quantity = quantity - 1
      }
    }
    if (val == 'add') {
      quantity += 1
    }
    this.saveOrderForm.patchValue({ quantity: quantity })
  }
  /** Show Product Price */
  getCurrentPrice(product: any) {
    if (product.is_direct_price){
      return this.code ? product.price : product.usd_price || 0;
    }else if (product.is_astrologer_associate){
      return this.code ? product?.astrologerList[0].price : product?.astrologerList[0].usd_price || 0;
    }
  }
  getOrginialPrice(product: any) {
    return this.code
      ? product.original_amount
      : product.usd_original_amount || 0;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji: any) {
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }

  /** Ring Size validation */
  ringSizeChange(event:any,i:any,data:any){
    if(event.target.checked){
      (<HTMLInputElement>document.getElementById('ring' + i)).readOnly = true;
      (<HTMLInputElement>document.getElementById('ring' + i)).value = '';
    }else{
      (<HTMLInputElement>document.getElementById('ring' + i)).readOnly = false;
      (<HTMLInputElement>document.getElementById('selectAddon'+i)).checked = false;
      this.addonForm = {
        addOnId: data._id,
        productId: this.product_id,
      };
      //this.spinner.show();
      this.product.getSingleAddon(this.addonForm).subscribe(
        (res: any) => {
          if (this.selectedAddon.includes(i)) {
            var objIndex = this.selectedAddon.findIndex((item: any) => item == i);
            this.selectedAddon.splice(objIndex, 1);
            var addOnIndex = this.alladdons.findIndex((item: any) => item.addOnName == res.addOnData.addon_name);
            this.alladdons.splice(addOnIndex, 1);
          }
        });
        console.log(this.alladdons)
    }    
  }

  disableDate() {
    return false;
  }

}
