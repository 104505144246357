import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FirebaseServiceService } from 'src/app/service/firebase/firebase-service.service';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ScrollToBottomDirective } from './scroll-to-bottom.directive';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';
import Swal from 'sweetalert2';
import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, update } from "firebase/database";

@Component({
  selector: 'app-ongoing-chat-customer',
  templateUrl: './ongoing-chat-customer.component.html',
  styleUrls: ['./ongoing-chat-customer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OngoingChatCustomerComponent implements OnInit, OnDestroy {
  @Input() ordeServiceId = '';
  @ViewChild(ScrollToBottomDirective)
  scroll: any;
  /**----------------------------- */
  id: string = '';
  dataList: any = '';
  chatId: string = '';
  chatsRef: any = '';
  chatList: any = [];
  maxTimeDuration = 0;
  BaseUrl: any;
  ifLessThanFiveMinutesLeft = false
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderServicesService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private db: AngularFireDatabase,
    private firebaseService: FirebaseServiceService,
    private router: Router,
    private _lightbox: Lightbox,
    private cdref: ChangeDetectorRef,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  serverTimeFormat: any;

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnInit(): void {
    this.ordeServiceId = localStorage.getItem('orderServiceID') ?? '';
    this.id = this.ordeServiceId;
    this.getServiceOrderDetail();
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  getServiceOrderDetail() {
    this.orderService
      .singleServiceOrders({
        _id: this.id,
        customerId: this.userdata.getCustomerId(),
      })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          if (this.dataList.chatId?.is_end) {
            localStorage.removeItem('orderServiceID');
            this.userdata.setIsChatGoing(false)
            window.location.reload()
          }
          this.chatId = this.dataList.chatId._id;
          var serverTime = moment(res.server_current_time);
          this.serverTimeFormat = serverTime
            .utc()
            .format('YYYY-MM-DD hh:mm:ss');
          // var timeTosendAsInput = moment(serverTimeFormat, 'YYYY-MM-DD hh:mm:ss')
          // console.log('timeTosendAsInput', timeTosendAsInput)
          // console.log('server_current_time',res.server_current_time)

          if (this.dataList.chatId.is_customer_acceptance_used && this.dataList.chatId.is_customer_accept && this.dataList.chatId.is_astrologer_accept) {
            /**
          * Updated code for time left according to balance
          */
            //this.maxTimeDuration = this.dataList.chatId.maxChatDuration
            var totalDuration = this.dataList.chatId.maxChatDuration;
            var date = moment(this.dataList.chatId.customer_accepting_time);
            var accept = date.utc().format('YYYY-MM-DD hh:mm:ss');
            var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
            var acceptTime = moment(accept, 'YYYY-MM-DD hh:mm:ss');
            // var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
            var currentTime = moment(
              this.serverTimeFormat,
              'YYYY-MM-DD hh:mm:ss'
            );
            var secondsDiff = currentTime.diff(acceptTime, 'seconds');
            this.maxTimeDuration = totalDuration - secondsDiff;

            // console.log('totalDuration= ', totalDuration, 'date= ', date, 'accept= ', accept, 'current= ', current, 'acceptTime= ', acceptTime, 'currentTime= ', currentTime, 'secondsDiff= ', secondsDiff, 'maxTimeDuration=', this.maxTimeDuration)
            /**----------------------------------------------------------- */
            this.startCountdown(this.maxTimeDuration);
          }

          this.chatsRef = this.db.object('chat/' + this.chatId);
          this.retrieveAllChats();
          this.childAdded();
          this.childChanged();
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /**
   * Retrieve all Chat from Backend
   */
  retrieveAllChats() {
    this.firebaseService
      .getChatList(this.chatId)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
      .subscribe(
        (chat: any) => {
          this.chatList = chat;
          if (this.chatList.length <= 0) {
            localStorage.removeItem('orderServiceID');
            this.userdata.setIsChatGoing(false)
            this.router.navigateByUrl('/chat-order-list/' + this.dataList._id);
          }
        },
        (error: any) => {
          //console.log(error);
        }
      );
  }

  /**
   * Child Added
   */

  childAdded() {
    var custName = this.dataList.customerId.name
    var astroName = this.dataList.astroId.name;
    var id = this.dataList._id;
    var toast = this.toast;
    var router = this.router;
    var demo = this;
    const db = getDatabase();
    const commentsRef = ref(db, 'chat/' + this.chatId);
    const chatId = this.chatId
    onChildAdded(commentsRef, function (snapshot: any) {
      var data = snapshot.val();
      var key = snapshot.key;
      //console.log('childAdded', data);
      if (data.message == 'Customer has accepted the chat') {
        demo.customerChatChange()
        return
      }
      if (data.message == custName + ' has ended the chat') {
        toast.success('Customer has ended the chat.', 'Success')
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
        return
      }
      if (data?.message?.includes(' has ended the chat')) {
        toast.success('Astrologer has ended the chat.', 'Success');
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
        //router.navigateByUrl('/chat-order-list/' + id);
      }
      if (
        typeof data.is_read != 'undefined' &&
        !data.is_read &&
        data.type == 2
      ) {
        //console.log('read')
        const commentsRefNew = ref(db, 'chat/' + chatId + '/' + key);
        update(commentsRefNew, { is_read: true })
        //demo.chatsRef.child(key).update({ is_read: true });
        //console.log(data, i, key)
      }
      if (key == '- Typing') {
        if (data.customer == true) {
          demo.astrologerTyping = true;
        } else {
          demo.astrologerTyping = false;
        }
      }
    });
    // this.chatsRef.on('child_added', function (snapshot: any) {
    //   var data = snapshot.val();
    //   var key = snapshot.key;
    //   if (data.message.includes(' has ended the chat')) {
    //     toast.success('Astrologer has ended the chat.', 'Success');
    //     router.navigateByUrl('/chat-order-list/' + id);
    //   }
    //   if (
    //     typeof data.is_read != 'undefined' &&
    //     !data.is_read &&
    //     data.type == 2
    //   ) {
    //     //console.log('read')
    //     demo.chatsRef.child(key).update({ is_read: true });
    //     //console.log(data, i, key)
    //   }
    //   if (key == '- Typing') {
    //     if (data.customer == true) {
    //       demo.astrologerTyping = true;
    //     } else {
    //       demo.astrologerTyping = false;
    //     }
    //   }
    // });
  }

  /**
   * Child Changed
   */
  astrologerTyping = false;
  childChanged() {
    var demo = this;
    const db = getDatabase();
    const commentsRef = ref(db, 'chat/' + this.chatId);
    onChildChanged(commentsRef, function (snapshot: any) {
      var data = snapshot.val();
      console.log("msg in firebase--", data.message)
      //console.log('childChanged', data);
      if (data.message.toString().toLowerCase() == 'customer has accepted the chat') {
        demo.customerChatChange()
        return
      }
      if (data.message.toString().toLowerCase() == 'customer missed the chat') {
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
      }
      if (data.message.toString().toLowerCase() == 'customer has rejected the chat') {
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
      }
      if (data.message.toString().toLowerCase() == 'astrologer missed the chat') {
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        console.log("Here in astro miss the chat***********")
        console.log("Reloading route***********")
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
      }
      if (data.message == 'astrologer has rejected the chat') {
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
      }
      if (typeof data.maxChatDuration != 'undefined') {
        //demo.customerChatChange()
        let currentUrl = demo.router.url;
        demo.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            demo.router.navigate([currentUrl]);
          });
      }
      var key = snapshot.key;
      if (key == '- Typing') {
        if (data.astrologer == true) {
          demo.astrologerTyping = true;
        } else {
          demo.astrologerTyping = false;
        }
      }
    });

    // this.chatsRef.on('child_changed', function (snapshot: any) {
    //   var data = snapshot.val();
    //   console.log('childChanged', data);
    //   if (typeof data.maxChatDuration != 'undefined') {
    //     //demo.customerChatChange()
    //     let currentUrl = demo.router.url;
    //     demo.router
    //       .navigateByUrl('/', { skipLocationChange: true })
    //       .then(() => {
    //         demo.router.navigate([currentUrl]);
    //       });
    //   }
    //   var key = snapshot.key;
    //   if (key == '- Typing') {
    //     if (data.astrologer == true) {
    //       demo.astrologerTyping = true;
    //     } else {
    //       demo.astrologerTyping = false;
    //     }
    //   }
    // });
  }

  /**
   * On Input focus show customer typing true
   */
  onKeyUpInput(event: any) {
    //console.log(event)
    const db = getDatabase();
    const commentsRef = ref(db, 'chat/' + this.chatId + '/- Typing');
    if (event.target.value.length > 0) {
      update(commentsRef, { customer: true })
      //this.chatsRef.child('- Typing').update({ customer: true });
    } else {
      update(commentsRef, { customer: false })
      //this.chatsRef.child('- Typing').update({ customer: false });
    }
  }

  /**
   * On Input Blur show customer typing true
   */
  onBlurInput(event: any) {
    //console.log('blur')
    this.chatsRef.child('- Typing').update({ customer: false });
  }

  /**
   * Send Chat Message
   */
  isImageUpload = false;
  sendMsgForm = new FormGroup({
    chatId: new FormControl(''),
    customerId: new FormControl(''),
    message: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    is_to_show: new FormControl(true),
    isImage: new FormControl(false),
    isFile: new FormControl(false),
  });

  get f() {
    return this.sendMsgForm.controls;
  }

  sendMsg() {
    this.sendMsgForm.patchValue({ chatId: this.chatId });
    this.sendMsgForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.sendMsgForm.patchValue({ name: this.userdata.getName() });
    if (!this.isImageUpload) {
      var msg = this.sendMsgForm.get('message')?.value.trim();
      if (msg != '' && msg != null) {
        var count = (msg.match(/\d/g) || []).length;
        var emailsArray = msg.match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
        );
        if (count == 10 || (emailsArray != null && emailsArray.length)) {
          this.sendMsgForm.patchValue({ is_to_show: false });
          //console.log(this.sendMsgForm.get('is_to_show')?.value, 'if')
        }
        this.sendMsgForm.patchValue({ message: msg });
        const data = new FormData();
        data.append('chatId', this.sendMsgForm.get('chatId')?.value);
        data.append('customerId', this.sendMsgForm.get('customerId')?.value);
        data.append('message', this.sendMsgForm.get('message')?.value);
        data.append('name', this.sendMsgForm.get('name')?.value);
        data.append('is_to_show', this.sendMsgForm.get('is_to_show')?.value);
        data.append('isImage', this.sendMsgForm.get('isImage')?.value);
        data.append('isFile', this.sendMsgForm.get('isFile')?.value);
        this.orderService.saveChatMsg(this.sendMsgForm.value).subscribe(
          (res: any) => {
            this.sendMsgForm.reset();
            this.sendMsgForm.patchValue({ message: '' });
            this.sendMsgForm.patchValue({ is_to_show: true });
            this.sendMsgForm.patchValue({ isImage: false });
            this.sendMsgForm.patchValue({ isFile: false });
            this.f;
            //console.log(this.sendMsgForm.get('is_to_show')?.value, 'result')
          },
          (err: any) => {
            this.toast.error(err.error.message, 'Alert');
          }
        );
      }
    }
    if (this.isImageUpload) {
      const data = new FormData();
      data.append('chatId', this.sendMsgForm.get('chatId')?.value);
      data.append('customerId', this.sendMsgForm.get('customerId')?.value);
      data.append('message', this.sendMsgForm.get('message')?.value);
      data.append('name', this.sendMsgForm.get('name')?.value);
      data.append('is_to_show', this.sendMsgForm.get('is_to_show')?.value);
      data.append('isImage', this.sendMsgForm.get('isImage')?.value);
      data.append('isFile', this.sendMsgForm.get('isFile')?.value);
      //console.log(this.sendMsgForm.get('is_to_show')?.value, 'after if')
      this.orderService.saveChatMsg(data).subscribe(
        (res: any) => {
          this.sendMsgForm.reset();
          this.sendMsgForm.patchValue({ message: '' });
          this.sendMsgForm.patchValue({ is_to_show: true });
          this.sendMsgForm.patchValue({ isImage: false });
          this.sendMsgForm.patchValue({ isFile: false });
          this.f;
          //console.log(this.sendMsgForm.get('is_to_show')?.value, 'result')
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
    }
  }

  checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      this.sendMsg();
    }
  }

  /**
   * End Chat
   */

  endChatForm = new FormGroup({
    chatId: new FormControl(''),
    customerId: new FormControl(''),
    message: new FormControl(''),
    // duration: new FormControl(''),
    is_customer_end_chat: new FormControl(true),
  });

  get e() {
    return this.sendMsgForm.controls;
  }

  endChat(id?: any) {
    clearInterval(this.interval)
    var msg = '';
    this.endChatForm.patchValue({ chatId: this.chatId });
    this.endChatForm.patchValue({ customerId: this.userdata.getCustomerId() });
    if (typeof id == 'undefined' || id == null || id == '') {
      msg = this.userdata.getName() + ' has ended the chat';
    }
    if (typeof id != 'undefined' && id == 1) {
      msg =
        'The chat has ended due to low wallet balance. You may continue the conversation by recharging and connecting again. For any help, please message support';
    }
    this.endChatForm.patchValue({ message: msg });
    var date = moment(this.dataList.chatId?.customer_accepting_time);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var accept = date.utc().format('YYYY-MM-DD hh:mm:ss');
    var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
    var acceptTime = moment(accept, 'YYYY-MM-DD hh:mm:ss');
    // var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
    var currentTime = moment(this.serverTimeFormat, 'YYYY-MM-DD hh:mm:ss');
    var secondsDiff = currentTime.diff(acceptTime, 'seconds');
    this.endChatForm.patchValue({ duration: secondsDiff });
    this.orderService.endChat(this.endChatForm.value).subscribe(
      (res: any) => {
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // this.router.navigate(['/chat-order-list', res.orderdetail._id]);
        window.location.reload();
      },
      (err: any) => {
        localStorage.removeItem('orderServiceID');
        localStorage.setItem('isChatGoing', 'false')
        // /this.router.navigate(['/chat-order-list', this.dataList._id]);
        this.toast.error(err.error.message, 'Alert');
        window.location.reload();
      }
    );
  }

  /**
   * Timer
   */
  counter: any;
  interval: any;
  startCountdown(seconds: any) {
    let counter = seconds;

    this.interval = setInterval(() => {
      //   console.log("in time change interval")
      this.convertHMS(counter);
      counter--;
      if (counter < 300)
        this.ifLessThanFiveMinutesLeft = true

      if (counter < 0) {
        clearInterval(this.interval);
        //console.log('Ding!');
        this.endChat(1);
      }
    }, 1000);
  }

  hours: any;
  minutes: any;
  seconds: any;
  convertHMS(value: any) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    this.hours = Math.floor(sec / 3600); // get hours
    this.minutes = Math.floor((sec - this.hours * 3600) / 60); // get minutes
    this.seconds = sec - this.hours * 3600 - this.minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (this.hours < 10) {
      this.hours = '0' + this.hours;
    }
    if (this.minutes < 10) {
      this.minutes = '0' + this.minutes;
    }
    if (this.seconds < 10) {
      this.seconds = '0' + this.seconds;
    }
    //console.log(this.hours + ':' + this.minutes + ':' + this.seconds);
    //return this.hours+':'+minutes+':'+this.seconds; // Return is HH : MM : SS
  }

  /**
   * M3- Attachements
   */

  /**
   * Get attachment type
   */
  checkExtenstion(val: any) {
    var fileName, fileExtension;
    fileName = val;
    fileExtension = fileName
      .substr(fileName.lastIndexOf('.') + 1)
      .toLowerCase();
    if (
      fileExtension == 'jpg' ||
      fileExtension == 'png' ||
      fileExtension == 'jpeg'
    ) {
      return 0;
    } else if (
      fileExtension == 'doc' ||
      fileExtension == 'docx' ||
      fileExtension == 'txt'
    ) {
      return 1;
    } else if (
      fileExtension == 'xlsx' ||
      fileExtension == 'xls' ||
      fileExtension == 'csv'
    ) {
      return 2;
    } else if (fileExtension == 'pdf') {
      return 3;
    } else {
      return 4;
    }
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  public getOpenUrl(url: string) {
    // return this.trusturl.bypassSecurityTrustUrl(
    //   'http://docs.google.com/viewer?url=' + this.BaseUrl + url
    // );
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  private _albums: any = [];
  open(val: any): void {
    this._albums = [];
    const album = {
      src: this.BaseUrl + val,
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, {
      wrapAround: true,
      showImageNumberLabel: true,
      fitImageInViewPort: true,
      centerVertically: true,
    });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onFileSelected(evnt: any) {
    if (evnt.target.files.length > 0) {
      if (evnt.target.files && evnt.target.files[0]) {
        var ext = evnt.target.files[0].name.split('.').pop();
        const allowed_ext = ['png', 'jpeg', 'jpg', 'pdf', 'csv', 'doc', 'docx', 'xlsx']
        const allowed_types = [
          'image/png',
          'image/jpeg',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/plain',
          'text/csv',
          'application/doc',
          'application/ms-doc',
          'application/msword',
          'application/excel',
          'application/vnd.ms-excel',
        ];
        if (!allowed_types.includes(evnt.target.files[0].type) && !allowed_ext.includes(ext.toLowerCase())) {
          //console.log(evnt.target.files[0])
          Swal.fire(
            'Invalid File Extension',
            'Only Images,Doc, PDF, Excel are allowed',
            'error'
          );
          return;
        } else {
          //console.log(evnt.target.files[0].type);
          this.sendMsgForm.patchValue({
            message: evnt.target.files[0],
          });
          this.sendMsgForm.patchValue({
            isImage: true,
          });
          if (
            evnt.target.files[0].type ==
            ('image/png' || 'image/jpeg' || 'image/jpg')
          ) {
            this.sendMsgForm.patchValue({
              isFile: false,
            });
            // console.log(
            //   'if',
            //   this.sendMsgForm.get('isFile')?.value,
            //   evnt.target.files[0].type
            // );
          } else {
            this.sendMsgForm.patchValue({
              isFile: true,
            });
            // console.log(
            //   'else',
            //   this.sendMsgForm.get('isFile')?.value,
            //   evnt.target.files[0].type
            // );
          }

          this.isImageUpload = true;
          //console.log('herer');
          this.sendMsg();
        }
      }
    }
  }

  customerChatChange() {
    this.orderService
      .singleServiceOrders({
        _id: this.id,
        customerId: this.userdata.getCustomerId(),
      }).subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.chatId = this.dataList.chatId._id;
          var serverTime = moment(res.server_current_time);
          this.serverTimeFormat = serverTime
            .utc()
            .format('YYYY-MM-DD hh:mm:ss');
          if (this.dataList.chatId.is_customer_acceptance_used && this.dataList.chatId.is_customer_accept) {
            //console.log('yesssssssssssssssss')
            /**
           * Updated code for time left according to balance
           */
            //this.maxTimeDuration = this.dataList.chatId.maxChatDuration
            var totalDuration = this.dataList.chatId.maxChatDuration
            var date = moment(this.dataList.chatId.customer_accepting_time);
            var accept = date.utc().format('YYYY-MM-DD hh:mm:ss');
            //var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
            var currentdate = moment(res.server_current_time);//for server time
            var current = currentdate.utc().format('YYYY-MM-DD hh:mm:ss');//for server time
            var acceptTime = moment(accept, 'YYYY-MM-DD hh:mm:ss');
            var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
            var secondsDiff = currentTime.diff(acceptTime, 'seconds');
            this.maxTimeDuration = totalDuration - secondsDiff
            /**----------------------------------------------------------- */
            //console.log(this.maxTimeDuration,'1')
          }
          if (this.dataList.is_end) {
            this.router.navigateByUrl('/chat-order-list/' + this.dataList._id)
          }
          // this.startCountdown(this.maxTimeDuration)
          //console.log(this.maxTimeDuration,'2')
          this.chatsRef = this.db.object('chat/' + this.chatId);
          this.retrieveAllChats()
          //this.childAdded()
          /**
           * Time
           */
          let counters = this.maxTimeDuration;

          const interval = setInterval(() => {
            //const sec = parseInt(counters, 10); // convert value to number if it's string
            this.hours = Math.floor(counters / 3600); // get hours
            this.minutes = Math.floor((counters - (this.hours * 3600)) / 60); // get minutes
            this.seconds = counters - (this.hours * 3600) - (this.minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (this.hours < 10) { this.hours = "0" + this.hours; }
            if (this.minutes < 10) { this.minutes = "0" + this.minutes; }
            if (this.seconds < 10) { this.seconds = "0" + this.seconds; }
            //this.convertHMS(counters)
            //console.log(counter);
            counters--;

            if (counters < 300)
              this.ifLessThanFiveMinutesLeft = true
            if (counters < 0) {
              clearInterval(interval);
              //console.log('Ding!');
              if (this.dataList.chatId.is_customer_acceptance_used && this.dataList.chatId.is_customer_accept) {
                this.endChat(1)
              }
            }
          }, 1000);
          /**----------------------- */
        }, err => {
          this.toast.error(err.error.message, 'Error')
        }
      )
  }
}
