import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OpenApiService {
  Token: any = '';

  BASEURI: string = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
    this.BASEURI = _baseUrl;
  }
  getPanchang(data: any) {
    return this.http.post(this.BASEURI + '/getVedicKundli', data);
  }
  getLanguages(form: any) {
    return this.http.post(this.BASEURI + '/alllanguages', form);
  }

  getCountryList() {
    return this.http.get<any>(this.BASEURI + '/country');
  }

  /**
   * Show All Report List to customer
   */
  public showReportList(data: any) {
    return this.http.post(this.BASEURI + '/allreports', data);
  }

  /**
   * Show Single Astrologer By Id
   */
  public singleAstrologerDetails(data: any) {
    return this.http.post(this.BASEURI + '/fetchastrologerbyid', data);
  }

  /**
   * Get Zodiac for daily horoscope
   */
  public dailyHoroscopeZodiac(data: any) {
    // return this.http.post(this.BASEURI + '/zodiacsign', data);
    return this.http.post(this.BASEURI + '/scope', data);
  }

  /**
   * Get horoscope for daily horoscope
   */
  public dailyHoroscope(data: any) {
    return this.http.post(this.BASEURI + '/todayhoroscope', data);
  }

  /**
   * Get Single Customization
   */
  public getSingleCustomization(data: any) {
    return this.http.post(this.BASEURI + '/showsinglcustomization', data);
  }

  /**
   * Dashboard astrologers
   */
  public getAstrologersDashboard(data: any) {
    return this.http.post(this.BASEURI + '/dashboardourastrloger', data);
  }

  /**
   * Get Kundli
   */
  public getKundli(data: any) {
    return this.http.post(this.BASEURI + '/getkundli', data);
  }

  /**
   * Get Customer Banner
   */
  public getCustomerBanner(data: any) {
    return this.http.post(this.BASEURI + '/bannerfilters', data);
  }

  /**
   * Get Country Code
   */
  public getCountryCode() { 
    var country = localStorage.getItem('countryCode');
    if (country == null) {
      this.http.get('https://ipinfo.io/json?token=7b4a6e3851fc79').subscribe(
        (res: any) => {
          localStorage.setItem('countryCode', res.country.toLowerCase());
          localStorage.setItem('loc', res.loc);
          localStorage.setItem('timezone', res.timezone);
          localStorage.setItem('region', res.region);
          return res.country.toLowerCase();
        },
        (err: any) => {
          return 'in';
        }
      );
    } 
    return country;
  }

  /**
   * Get latLong
   */
   public getLatLon() { 
    var location = localStorage.getItem('loc');
    if (location == null) {
      this.http.get('https://ipinfo.io/json?token=7b4a6e3851fc79').subscribe(
        (res: any) => {
          localStorage.setItem('countryCode', res.country.toLowerCase());
          localStorage.setItem('loc', res.loc);
          localStorage.setItem('timezone', res.timezone);
          localStorage.setItem('region', res.region);
          //console.log(res)
          return res.loc;
        },
        (err: any) => {
          return 'in';
        }
      );
    } 
    return location;
  }

  /* GET Testimonials*/
  getTestimonials() {
    return this.http.post(this.BASEURI + '/alltestimonials', { status: true });
  }

  /*Get Pages*/
  getPage(slug: string) {
    return this.http.post(this.BASEURI + '/getpage', { pageSlug: slug });
  }

  /** Get Geo Location for Kundli */
  getGeoLocation(data: any) {
    return this.http.post(this.BASEURI + '/getVedicKundli', data);
  }

  /** Contact Us */
  addContactUs(data: any) {
    return this.http.post(this.BASEURI + '/addContact', data);
  }

  /** Show open chat */
  shareChat(data: any) {
    return this.http.post(this.BASEURI + '/singlechatorderWeb', data);
  }
}
