import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ScrolltopService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router) { 
  }

  setScrollTop() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: any) => {
        document.body.scrollTop = 0;
      });
    }
  }
}
