import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return 'https://apiv3.astroriver.com/api';
  // return 'http://192.168.1.9:8085/api';
}

export function getBaseImageUrl() {
  // return 'http://192.168.1.16:8085/'
  return 'https://apiv3.astroriver.com/';
  // return 'http://192.168.1.9:8085/';

}

const  providers =[
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'BASE_IMAGE_URL', useFactory: getBaseImageUrl, deps: [] },
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));
