import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-share-chat',
  templateUrl: './share-chat.component.html',
  styleUrls: ['./share-chat.component.css']
})
export class ShareChatComponent implements OnInit {
  BaseUrl: any;
  id: any;
  dataList: any;
  constructor(private route: ActivatedRoute,private router: Router,
    private _lightbox: Lightbox,
    private openService: OpenApiService,
    private cdref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getShareChatDetail();
  }

  getShareChatDetail(){
    this.spinner.show()
    this.openService.shareChat({_id:this.id}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },(err:any)=>{
        this.toast.error(err.error.message,'Error')
        this.spinner.hide()
      }
    )
  }

  public getOpenUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

}
