<app-header #headerOne></app-header>
<ngx-spinner></ngx-spinner>

<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        {{ dataList.displayname ? dataList.displayname : dataList.name }}
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Astrologer Detail</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!--Detail Start -->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-4 d-flex flex-column">
        <div class="rounded shadow card position-relative">
          <!-- <img
            class="bg-active rounded-circle shadow position-absolute astro-img"
            [src]="getSanitizeUrl(dataList.profile_image)"
          /> -->
          <img class="bg-active rounded-circle shadow position-absolute astro-img"
            [src]="BaseUrl+dataList.profile_image" >
          <div class="card-header b-expert-top bg-active">
            <h5 class="fw-500 text-center mt-5 mb-3 pt-3">
              {{ dataList.displayname ? dataList.displayname : dataList.name }}
            </h5>
            <div class="row list-inline   text-center">
              <div class="col-lg-3 col-md-3 border-right col-6 p-0">
                <i class="center-align material-icons-outlined dull-green-text">
                  phone </i>&nbsp;&nbsp;{{ dataList.total_call_taken }}
              </div>
              <div class="col-lg-3 col-md-3 border-right col-6 p-0">
                <i class="center-align material-icons-outlined orange-text">
                  chat </i>&nbsp;&nbsp;{{ dataList.total_chat_taken }}
              </div>
              <div class="col-lg-3 col-md-3 border-right col-6 p-0">
                <i class="center-align material-icons-outlined active-text">
                  contact_support </i>&nbsp;&nbsp;{{ dataList.total_query_taken }}
              </div>
              <div class="col-lg-3 col-md-3 col-6 p-0">
                <i class="center-align material-icons-outlined blue-text">
                  description </i>&nbsp;&nbsp;{{ dataList.total_report_taken }}
              </div>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="row px-2 ">
              <div class="col-lg-3 col-md-3 col-6 p-0 px-1 mar-btm">
                <a (click)="buttonAction('call-astro-list')">
                  <div class="
                      p-0
                      col-12
                      py-2
                      bg-dull-green
                      shadow-sm
                      rounded
                      fw-500
                      text-center text-white
                    ">
                    Call
                  </div>
                </a>
              </div>
              <div class="col-lg-3 col-md-3 col-6 p-0 px-1">
                <a (click)="buttonAction('chat-astro-list')">
                  <div class="
                      p-0
                      col-12
                      bg-orange
                      rounded
                      shadow-sm
                      fw-500
                      py-2
                      text-center text-white
                    ">
                    Chat
                  </div>
                </a>
              </div>
              <div class="col-lg-3 col-md-3 col-6 p-0 px-1">
                <a (click)="buttonAction('query-astro-list')">
                  <div class="
                      p-0
                      col-12
                      shadow-sm
                      bg-secondary
                      rounded
                      fw-500
                      py-2
                      text-center text-white
                    ">
                    Query
                  </div>
                </a>
              </div>
              <div class="col-lg-3 col-md-3 col-6 p-0 px-1">
                <a (click)="buttonAction('report-astro-list')">
                  <div class="
                      p-0
                      col-12
                      bg-blue
                      shadow-sm
                      rounded
                      fw-500
                      py-2
                      text-center text-white
                    ">
                    Report
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="card-footer bg-active border d-flex flex-column">
            <table class="custom-table">
              <tr class="w-100 height45 border-bottom">
                <td>
                  <p class="active-text">
                    <strong class="active-text mr-2">Experience</strong>
                  </p>
                </td>
                <td class="p-0">
                  <p class="text-body mb-1">{{ dataList.experience }} Years</p>
                </td>
              </tr>
              <tr class="w-100 height45 border-bottom">
                <td>
                  <p class="fw-500 mb-1">
                    <strong class="active-text mr-2">Language</strong>
                  </p>
                </td>
                <td class="p-0">
                  <span *ngFor="let lang of dataList.lang; let i = index" class="fn-1 text-inactive  py-1">{{
                    lang.langId?.name
                    }}{{
                    dataList.lang.length > 1 && i < dataList.lang.length - 1 ? ", " : "" }}&nbsp;</span>
                </td>
              </tr>
              <tr class="w-100 height45">
                <td>
                  <p class="active-text mb-1">
                    <strong class="active-text mr-2">Skills</strong>
                  </p>
                </td>
                <td class="p-0">
                  <span *ngFor="let skill of dataList.skill; let i = index" class="fn-1 text-inactive  py-1">{{
                    skill.skillId?.skillname
                    }}{{
                    dataList.skill.length > 1 && i < dataList.skill.length - 1 ? ", " : "" }}&nbsp;</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="order-bg rounded shadow-sm my-3">
          <div class="row">
            <div class="
                fw-500
                text-body
                col-md-6
                border-right
                p-0
                text-center
                py-3
              ">
              <i class="
                  center-align
                  material-icons-outlined
                  dull-green-text
                  mr-2
                ">
                phone </i>{{ dataList.total_call_taken }} Mins
            </div>
            <div class="fw-500 text-body col-md-6 p-0 text-center py-3">
              <i class="center-align material-icons-outlined orange-text mr-2">
                chat </i>{{ dataList.total_chat_taken }} Mins
            </div>
          </div>
        </div>

        <!-- <div class="m-0 card rounded shadow row d-flex flex-column p-3">
          <h5 class="fw-600 text-uppercase">About Astrologer</h5>
          <span *ngIf="astroBio" class="my-3">{{ dataList.short_bio }}</span>
          <span class="my-3" *ngIf="!astroBio">{{ dataList.long_bio }}</span>

          <button
            type="button"
            (click)="toggleBio()"
            class="
              text-capitalize
              btn
              py-2
              bg-secondary
              rounded
              shadow-0
              m-0
              text-white
            "
          >
            Show <span *ngIf="astroBio">More</span
            ><span *ngIf="!astroBio">Less</span>
          </button>
        </div> -->
      </div>
      <div class="col-md-8 pl-5 p-left">
        <h4 class="fw-500 text-body">Charges</h4>
        <div class="row mt-2">
          <div class="col-lg-3 col-md-3 col-6 mar-btm">
            <div class="p-0 col-12 call-bg shadow-sm rounded d-flex flex-column">
              <p class="px-1 py-3 text-center fw-500" *ngIf="dataList.isCallOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.astroCallChargesWithOffer }}/Min.
              </p>
              <p class="px-1 py-3 text-center fw-500" *ngIf="!dataList.isCallOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.call_charges * mul }}/Min.
              </p>
              <span class="rounded-bottom py-2 bg-dull-green text-white text-center">
                <a (click)="buttonAction('call-astro-list')">
                  <i class="center-align material-icons-outlined mr-2">
                    phone </i>Call</a>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-6 mar-btm">
            <div class="p-0 col-12 chat-bg shadow-sm rounded d-flex flex-column">
              <p class="px-1 py-3 text-center fw-500" *ngIf="dataList.isChatOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.astroChatChargesWithOffer }}/Min.
              </p>
              <p class="px-1 py-3 text-center fw-500" *ngIf="!dataList.isChatOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.chat_charges * mul }}/Min.
              </p>

              <span class="rounded-bottom py-2 bg-orange text-white text-center">
                <a (click)="buttonAction('chat-astro-list')"><i class="center-align material-icons-outlined mr-2">
                    chat
                  </i>
                  Chat</a>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-6">
            <div class="p-0 col-12 query-bg shadow-sm rounded d-flex flex-column">
              <p class="px-1 py-3 text-center fw-500" *ngIf="dataList.isQueryOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.astroQueryChargesWithOffer }}/Query
              </p>
              <p class="px-1 py-3 text-center fw-500" *ngIf="!dataList.isQueryOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.query_charges * mul }}/Query
              </p>
              <span class="rounded-bottom py-2 bg-secondary text-white text-center">
                <a (click)="buttonAction('query-astro-list')"><i class="center-align material-icons-outlined mr-2">
                    contact_support
                  </i>
                  Query</a>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-6">
            <div class="p-0 col-12 report-bg shadow-sm rounded d-flex flex-column">
              <p class="px-1 py-3 text-center fw-500" *ngIf="dataList.isReportOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.astroReportChargesWithOffer }}/Report
              </p>
              <p class="px-1 py-3 text-center fw-500" *ngIf="!dataList.isReportOfferApplicable">
                {{ dataList?.currencySymbol }}
                {{ dataList?.report_charges * mul }}/Report
              </p>
              <span class="rounded-bottom py-2 bg-blue text-white text-center">
                <a (click)="buttonAction('report-astro-list')"><i class="center-align material-icons-outlined mr-2">
                    description
                  </i>
                  Report</a>
              </span>
            </div>
          </div>
        </div>
        <div class="my-4  rounded order-bg shadow-sm  d-flex flex-column p-3">
          <h5 class="fw-600 text-uppercase">About Astrologer</h5>
          <span *ngIf="astroBio" class="my-3">{{ dataList.short_bio }}</span>
          <span class="my-2 f-size" *ngIf="!astroBio">{{ dataList.long_bio }}</span>

          <button type="button" (click)="toggleBio()" class="
                              text-capitalize
                              btn
                              btn-danger
                              py-1
                              m-btn-show
                              col-md-4
                              col-lg-4
                              col-4 mt-1  
                              bg-secondary
                              rounded
                              shadow-0
                              m-0
                              text-white
                            ">
            Show <span *ngIf="astroBio">More</span><span *ngIf="!astroBio">Less</span>
          </button>
        </div>
        <div class="row mt-4 m-0">
          <h4 class="text-body mb-2">Reviews</h4>
          <ngx-datatable class="material" [rows]="reviews" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
            [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{ dir: 'desc' }]">
            <ngx-datatable-column [resizeable]="false" [flexGrow]="1" name="Rating">
              <ng-template let-row="row" [sortable]="false" ngx-datatable-cell-template>
                <ng-template #t let-fill="fill">
                  <span class="star" [class.full]="fill === 100">
                    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                  </span>
                </ng-template>
                <ngb-rating class="" [max]="5" [(rate)]="row.customer_review_stars" [readonly]="true"></ngb-rating>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [flexGrow]="3" name="Review" prop="customer_review">
              <ng-template let-row="row" [sortable]="false" ngx-datatable-cell-template>
                {{ row.customer_review }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" [flexGrow]="1" name="Date" prop="created_at">
              <ng-template let-row="row" [sortable]="false" ngx-datatable-cell-template>
                {{ row.created_at | date: "medium" }}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</section>
<!-- Detail End -->
<app-footer></app-footer>