<app-header></app-header>
<ngx-spinner></ngx-spinner>

<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">About Us</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          d-flex
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">About Us</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- Main banner Start -->
<section class="about-sct-bg">
  <div class="jumbotron">
    <div class="container">
      <h1 class="text-uppercase heading-sm">Welcome to</h1>
      <h2 class="text-uppercase fw-800 heading-lg">
        <span class="active-text">astro</span>river
      </h2>
      <!-- <p>
        <a class="btn btn-md yellow-btn" href="javascript:void(0)" role="button" (click)="scroll(target)">Learn more »</a>
      </p> -->
    </div>
  </div>
</section>
<!-- Main banner end -->
<section class="border-bottom highlight-sec">
  <div class="bg-white py-4">
    <div class="container">
      <div class="row align-items-center g-0">
        <!-- Features -->
        <div class="col-md-4 mb-lg-0 mb-4">
          <div class="d-flex align-items-center">
            <img class="size-60 rounded-circle cus-border-active p-2 mr-2" src="../../../assets/icons/trust.png" />
            <div class="ms-3">
              <h4 class="mb-0 font-weight-bold font-30">Trusted</h4>
              <p class="mb-0 font-weight-bold">Since 2000</p>
            </div>
          </div>
        </div>
        <!-- Features -->
        <div class="col-md-4 mb-lg-0 mb-4">
          <div class="d-flex align-items-center">
            <img class="size-60 rounded-circle cus-border-active p-2 mr-2" src="../../../assets/icons/smile.png" />
            <div class="ms-3">
              <h4 class="mb-0 font-weight-bold font-30">50,000,000</h4>
              <p class="mb-0 font-weight-bold">Happy Customers</p>
            </div>
          </div>
        </div>
        <!-- Features -->
        <div class="col-md-4 mb-lg-0 mb-4">
          <div class="d-flex align-items-center">
            <img class="size-60 rounded-circle cus-border-active p-2 mr-2" src="../../../assets/icons/world.png" />
            <div class="ms-3">
              <h4 class="mb-0 font-weight-bold font-30">Users From</h4>
              <p class="mb-0 font-weight-bold">180 Countries</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- who we are start -->
<section class="my-3 my-lg-5">
  <div class="container">
    <div class="row">
      <div class="col-md-5 order-md-1">
        <img src="../../../assets/images/about-us/img-1.png" class="w-100" />
      </div>
      <div class="col-md-7 order-md-2 about-text">
        <h4 class="text-uppercase fw-800 text-left">
          <span class="active-text">who</span>&nbsp;we are?
        </h4>
        <p class="mt-3 p-line-h30 text-justify">
          Astro River is a team of passionate individuals who are dedicated to providing quality astrology content and
          services. Our team consists of astrologers, healers, counsellors, life coaches, psychologists, writers, and
          researchers from across the globe who are committed to helping people get quality services and learn how it
          can impact their lives.</p>
          <p class="mt-3 p-line-h-30 text-justify">
            AstroRiver is the best astrology website for online Astrology predictions. With a wide selection of Vedic
          astrologers, Tarot readers, Numerologists, Vastu consultants, and other specialists on call and chat at
          Astrotalk, you can talk to these experts and obtain answers to all of your problems like marriage, love,
          health, career or anything else. We aim for a worry-free, peaceful life for you and have helped millions
          attain the same in the last one and a half decade.
          </p>
      </div>
    </div>
  </div>
</section>
<!-- who we are end -->

<!-- what we do start -->
<section class="">
  <div class="container">
    <div class="row pt-3">
      <div class="col-md-7 order-1 about-text">
        <h4 class="text-uppercase fw-800 text-left">
          <span class="active-text">what</span>&nbsp;we do?
        </h4>
        <p class="mt-3 p-line-h30 text-justify">
          We provide a variety of services that focus on helping people understand and make the most of astrology. Our
          services include personal readings, online astrology, group readings, counselling and coaching, workshops and
          events, online poojans, astrological articles, healing services and more.
        </p>
        <p class="mt-3 p-line-h30 text-justify">
          We also offer a wide range of products through our online store, AstroShop. A huge range of astrological
          articles, online puja services at the holiest places in India, personalised and group healing sessions, high
          quality gemstones, rudrakshas, yantras, learning resources and much more is available through AstroShop. We
          offer articles, videos and other resources about astrology, inspiration, and positive thinking to help people
          make the most of their lives. Our team is committed to helping you achieve your goals and live a happy,
          fulfilled life.
        </p>
      </div>
      <div class="col-md-5 order-md-2">
        <img src="../../../assets/images/about-us/img-2.png" class="w-100" />
      </div>
    </div>
  </div>
</section>
<!-- what we do end -->

<!-- why astro start -->
<section class="my-5 my-lg-5 bg-inactive" #target>
  <div class="container">
    <div class="row">
      <div class="col-md-5 rounded-logo">
        <div class="rounded-circle circle-logo bg-active ml-n5">
          <img src="../../../assets/images/about-us/circle-logo.png" class="w-100" />
        </div>
        <img src="../../../assets/images/new_logo.png" class="position-absolute w-25" style="top: 45%; left: 32%" />
      </div>
      <div class="col-md-7 py-5">
        <h4 class="text-uppercase fw-800 text-left">
          <span class="active-text">why astro </span> river
        </h4>
        <p class="mt-3 p-line-h30 text-justify">
          At AstroRiver, we believe that everyone has the potential to live a happy and fulfilling life. We are here to
          help you create that life. That's our goal, our passion and our reason for existence.
        </p>
        <p class="mt-3 p-line-h30 text-justify">
          We are here to help you live your best life. Whether you are looking for guidance on your personal journey or
          wanting to learn more about astrology, we can offer insights and support. Our goal is to provide you with the
          tools and resources you need to make informed decisions, connect with your higher self, and create a life that
          is in alignment with your deepest desires.
        </p>
        <p class="mt-3 p-line-h30 text-justify">
          Our team is composed of individuals who are passionate about helping others achieve their potential. We have a
          wide range of skills and experience, and we are committed to offering quality services that can help you
          transform your life.</p>
      </div>
    </div>
  </div>
</section>
<!-- why astro end -->

<!-- our founder start -->
<section class="">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h4 class="text-uppercase fw-800 mb-2">
          <span class="active-text">our</span>&nbsp;founder
        </h4>
        <p class="p-line-h30">
          Astro River is a brainchild of Dr Armaan Sharrma, a renowned astrologer and life coach. He drives his
          inspiration from his father, Late Pt. Manmohan Kumar, an accomplished astrologer and occult researcher whose
          legacy is now being carried over by his son and daughter-in-law, Dr Nisha Shharma.

        </p>
      </div>
    </div>
    <div class="row mt-3 mt-lg-5 mt-md-5 our-founder">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-5">
        <div class="card green">
          <div class="card-body p-0">
            <img src="../../../assets/images/about-us/manmohan.jpg" class="w-100" />
          </div>
          <div class="card-footer bg-active">
            <h5 class="fw-500 font-25 mb-2">Late Pt. Manmohan Kumar</h5>
            <p class="text-justify">
              Late Pt. Manmohan Kumar (b. 1954, d. 2012) was a civil engineer, a government officer, and a devoted
              researcher of Astrology and the occult who devoted his life to learning about this ancient science. He
              propagated an amalgamation of Vedic Astrology & Red Book, the two most popular methodologies followed in
              Astrology. As a result of this, he was able to prescribe easy remedies which worked almost instantly and
              without any side effects. His expertise in remedies and accuracy of predictions has helped change the
              lives of many.
              His spouse Dr. Geeta Dogra is a retired senior journalist and a prolific writer/poet. She brought the
              knowledge of Astrology through ancient texts and personal works/researches she had received from her
              maternal grandfather Pt. Parsram Mishra who held the position of Royal Astrologer in the court of Raja
              Hari Singh (Jammu - reign 1925-1952 ). His knowledge of Astrology grew to heights never before seen due to
              her encouragement, which is having a major influence on millions today.

            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-5">
        <div class="card red">
          <div class="card-body p-0">
            <img src="../../../assets/images/about-us/nisha.jpg" class="w-100" />
          </div>
          <div class="card-footer bg-active">
            <h5 class="fw-500 font-25 mb-2">Dr Nisha Shharma</h5>
            <p class="text-justify">
              Dr Nisha is the CEO of Astro River, and is the founder of Astro Jewels, a subsidiary of Astro River which
              deals exclusively in fine Gemstones and astrological jewellery.
              She is an International Astrologer and an experienced Gemologist with 12+ years of experience in various
              occult fields, especially in Vedic Astrology, Numerology & Tarot. In her illustrious astrological career,
              she has advised numerous celebrities, politicians and people from various walks of life. Mrs. Shharma is also an author. Her works have been recognized and duly rewarded by many reputed occult organisations
              across the globe. She has also spoken at various platforms and teaches Astrology, Numerology and Tarot
              with utmost passion.
              <!-- Dr. Nisha Shharma joined Astro River in 2012 with a keen interest & knowledge in occult, and at the same time with a strong educational background in computer science and technology. She is a post-graduate in Information Technology and holds a passion for it. Her knowledge and experience in technology led to the foundation of Astro River's new 'avatar' and led to the fulfilment of a dream that once was an imagination. -->
              At Astro River, she believes in a culture of a light-hearted discipline. She believes that since it is the
              mission of Astro River to infuse peace and love in people's lives, the internal culture of the
              organisation should reflect the same. At the same time, she works profusely and devotedly to spread the
              organisation's mission across the globe with her amazing team.

            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-5">
        <div class="card yellow">
          <div class="card-body p-0">
            <img src="../../../assets/images/about-us/armaan.jpg" class="w-100" />
          </div>
          <div class="card-footer bg-active">
            <h5 class="fw-500 font-25 mb-2">Dr Armaan Sharrma</h5>
            <p class="text-justify">
              Dr Armaan Sharrma is the founder of Astro River, and a world-renowned Astrologer & Numerologist from India. He has performed over 15,000+ in-depth personal readings and innumerable general astro readings in his career span of more than 22+ years. He has been a regular adviser to many political figures, bureaucrats and cine artists. He however caters to men and women from all ages, professions, nations & backgrounds.
              He is the founder of the Armaan Sharrma Foundation (ASF), a non-profit organisation whose goal is to promote and develop the practice of astrology, numerology and other allied sciences like palmistry, face reading etc. for the benefit of society at large. 
              <!-- Under the banner of this foundation, he has conducted many free astrology and numerology seminars for the general public.  -->
              <!-- The foundation also works in various other fields such as prevention of child trafficking and also caters to many other social issues. -->
              Known as the love guru of modern times, his inspirational seminars are life changing and he has helped many rediscover their lost love for life, master the art of positive thinking, develop ultra self-confidence, and become more effective and reach their goals through his intense personal sessions.
              
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <a class="btn btn-md yellow-btn text-uppercase" href="javascript:void(0)" role="button" [routerLink]="['/message-ceo']">Read More</a>
      </div>
    </div>
  </div>
</section>
<!-- our founder end -->

<!-- our story start -->
<section class="my-lg-5 position-relative py-5 our-story-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-8 our-story order-md-1">
        <h4 class="fw-700 text-uppercase">
          <span class="text-white p-3 font-30 bg-secondary">Our</span>&nbsp; story
        </h4>
        <p class="mt-4 p-line-h30">
          Astro River is the result of years of hardships faced by Late Pt. Manmohan Kumar, who decided to learn Astrology himself after he was informed that his own chart contained the yogas for becoming an exceptional, true Astrologer. Being a firm believer in Astrology, but mostly misguided by the so-called masters of Astrology, he devoted his life to learning and researching Astrology and to discover true masters. His years of study and experience have benefitted thousands, and this was passed on to his son, Dr. Armaan Sharma, who founded Astro River to continue his father's legacy. Pt. Manmohan Kumar left for heavenly abode in 2012.
        </p>
      </div>
      <div class="col-md-4 d-flex align-items-center our-story-mob order-md-2">
        <img src="../../../assets/images/about-us/our-story.jpg" class="w-100 shadow" />
      </div>
    </div>
  </div>
</section>
<!-- our story end -->

<!-- our misson start -->
<section class="mt-4">
  <div class="container">
    <div class="row misson">
      <div class="col-md-6 misson-card">
        <div class="card shadow p-2">
          <div class=" card-body bg-green-light position-relative rounded d-flex flex-column">
            <h5 class="fw-600 text-center text-uppercase mb-1">Our Mission</h5>
            <p class="text-center">AstroRiver's mission is to provide a platform for people from all walks of life to get in touch with the science of Astrology, and understand how it can help them transform their lives. Our goal is to spread knowledge and peace of mind to individuals all around the world who feel overwhelmed by their fast-paced lives.
            </p>
            <img src="../../../assets/images/about-us/flag.png"
              class=" bg-active border size-70 position-absolute p-1 rounded-circle "
              style="top: -2.5rem; border-width: 3px !important" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow p-2">
          <div class=" card-body bg-secondary-2-light position-relative rounded d-flex flex-column ">
            <h5 class="fw-600 text-center text-uppercase mb-1">Our Vision</h5>
            <p class="text-center">AstroRiver envisions a future where individuals are in touch with their higher selves, and are able to live their lives in alignment with their deepest desires. We see a world where people are able to use astrology as a tool for self-awareness and personal growth. We believe that everyone has the potential to create a life that is rich, meaningful, and fulfilling.</p>
            <img src="../../../assets/images/about-us/microscope.png"
              class=" bg-active border size-70 position-absolute p-1 rounded-circle "
              style="top: -2.5rem; border-width: 3px !important" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- our misson end -->

<!-- our astro start -
<section class="my-3 mt-lg-5">
  <div class="container">
    <a class="nav-left"></a><a class="nav-right"></a>
    <swiper class="" [config]="astroConfig">
      <ng-template class="py-3 h-100" swiperSlide *ngFor="let key of topList">
        <div class="position-relative h-100">
          <img
            [src]="getSanitizeUrl(key.profile_image)"
            class="position-absolute rounded-circle size-60 img-shape"
          />
          <div class="outer-shape">
            <div class="new-shape"></div>
          </div>
          <div class="card-detail">
            <div class="card-body position-relative">
              <div class="astro-dot-1"></div>
              <div class="astro-dot-2"></div>
            </div>
            <div class="border-0 bg-active card-footer d-flex flex-column">
              <h4 classs="active-text text-capitalize">
                {{ key.displayname ? key.displayname : key.name }}
              </h4>
              <p class="fw-500">
                <span *ngFor="let skill of key.skill"
                  >{{ skill.skillId?.skillname
                  }}{{ key.skill.length > 1 ? ", " : "" }}</span
                >
              </p>
              <p class="inactive-text fn-1">
                <span *ngFor="let lg of key.lang"
                  >{{ lg.langId?.name
                  }}{{ key.lang.length > 1 ? ", " : "" }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</section>-->
<!-- our astro end -->
<!-- Top Astro Start -->
<section class="mt-5">
  <app-top-astro [sectionTitle]="sectionTitle"></app-top-astro>
</section>
<!-- Top Astro End -->
<!-- counter start -->
<section class="">
  <div class="container">
    <div class="row"></div>
  </div>
</section>
<!-- counter end -->

<app-footer></app-footer>