<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">query order</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Query order</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <!-- <div class="row new-product d-flex flex-column">
      <h4 class="d-none d-lg-block text-capitalize text-center">query order</h4>
      <h5 class="d-lg-none text-capitalize text-center">query order</h5>
      <h4 class="text-center"><span></span></h4>
    </div> -->
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="ml-3
          mr-3
          d-flex 
          f-style
          my-2
          ">
          <button class="btn btn-sm mr-2 rounded" [ngClass]="{'active':filterType == 'all'}" (click)="getQueryOrders()">
            All
          </button>
          <button class="btn btn-sm mx-2" [ngClass]="{'active':filterType == 'answer'}" (click)="pendingQuery()">
            No Answer
          </button>
          <button class="btn btn-sm mx-2 " [ngClass]="{'active':filterType == 'review'}" (click)="noReview()">
            No Review
          </button>
          <button class="btn btn-sm mx-2" [ngClass]="{'active':filterType == 'feedback'}" (click)="noFeedback()">

            No Feedback
          </button>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
          <ngx-datatable class="material cust-table shadow-0" [rows]="dataList" [columnMode]="'force'"
            [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
            [sorts]="[{ dir: 'desc' }]">
            <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="oquestionId">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/query-order-list/', row?.orderserviceId?._id]" title="View Details">{{
                  row?.oquestionId
                  }}</a>
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column [flexGrow]="4" name="Question">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.question }}
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column [flexGrow]="1" name="Amount" prop="customer_amount">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.customerCurrencySymbol }}{{ row?.customer_amount }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="2" name="Status" prop="order_current_status">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.order_current_status }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.created_at | date: "medium" }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Action" prop="action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/query-order-list', row?.orderserviceId?._id]"
                  class="btn py-1 px-3 rounded shadow-0 bg-secondary text-white">Detail</a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>