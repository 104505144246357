<app-header #headerOne></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Promo for you
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Promo for you</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!-- Atro List Start -->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="">
          <h4 class="fw-500">Available Promos</h4>
        </div>
        <div class="row" *ngIf="dataList?.length > 0">
          <div class="my-3 col-lg-6 col-md-6 c-l-sm-12 col-xs-12" *ngFor="let key of dataList">
            <div class="h-100 w-100 card trans astro-box p-2">
              <div class="h-100 w-100 card-body bg-astro py-2">
                <div class="h-100 row p-1 position-relative">
                  <div class="col-3 p-0 pr-1">
                    <div class="d-flex flex-column align-items-center">
                      <a [routerLink]="['/astrologer', key.astroId._id]"><img
                          class="shadow size-60 border-white rounded-circle"
                          [src]="getSanitizeUrl(key.astroId.profile_image)" /></a>
                      <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astroId.astrologer_rating" [readonly]="true">
                      </ngb-rating>
                      <span class="fn-1 inactive-text fw-500">({{ key.astroId.astrologer_rating }})</span>
                    </div>
                  </div>
                  <span class="material-icons position-absolute"
                    [ngClass]="checkOnlineOffLine(key) ? 'online' : 'offline'">
                    fiber_manual_record
                  </span>
                  <a class="position-absolute noti pointer text-white" [ngClass]="{
                      'bg-green': key.isPaid,
                      'bg-secondary': !key.isPaid
                    }"><span *ngIf="key.isPaid; else free">Paid</span>
                    <ng-template #free>Free</ng-template>
                  </a>
                  <button (click)="buttonAction(key)" style="z-index: 100" class="
                      float-right
                      shadow-0
                      btn
                      px-2
                      py-1
                      mx-2
                      rounded
                      bg-secondary
                      fw-500
                      text-white
                      position-absolute
                      bottom-0
                      end-0
                      d-flex
                    ">
                    <i class="material-icons fn-2 lh-24 py-0">
                      {{ getButton(key) }}</i>
                    {{ getTitle(key) }}
                  </button>

                  <div class="col-9 p-0 pl-1">
                    <div class="d-flex flex-column">
                      <span class="text-capitalize fw-600">{{
                        key.astroId.displayname
                        ? key.astroId.displayname
                        : key.astroId.name
                        }}</span>
                      <p class="fn-1 inactive-text line-size-1">
                        <span class="text-capitalize" *ngFor="let skill of key.astroId.skill; let i = index">{{
                          skill.skillId?.skillname
                          }}{{
                          key.astroId.skill.length > 1 &&
                          i < key.astroId.skill.length - 1 ? ", " : "" }}</span>
                      </p>
                      <p class="fn-1 inactive-text">
                        <strong>Exp:</strong> {{ key.astroId.experience }}
                        {{ key.astroId.experience > 1 ? "Years" : "Year" }}
                      </p>
                      <p class="fn-1 inactive-text line-size-1">
                        <span class="text-capitalize" *ngFor="let lg of key.astroId.lang; let i = index">{{
                          lg.langId?.name
                          }}{{
                          key.astroId.lang.length > 1 &&
                          i < key.astroId.lang.length - 1 ? ", " : "" }}</span>
                      </p>
                      <ul class="fn-1 w-100">
                        <li class="d-inline" *ngIf="isOffer(key); else price">
                          <s class="inactive-text">
                            {{ key?.astroId.currencySymbol }}
                            {{ getPrice(key) }}</s>&nbsp;
                          {{ key?.astroId.currencySymbol }}
                          {{ getOffer(key) }}
                        </li>
                        <ng-template #price>
                          <li class="d-inline">
                            {{ key?.astroId.currencySymbol }}
                            {{ getPrice(key) }}
                          </li>
                        </ng-template>
                      </ul>
                      <span class="fn-0 active-text">{{
                        convertSecondstoTime(key.duration)
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="dataList?.length == 0">
          <div class="col-12">
            <section class="container  order-bg1 mt-2 mb-5 py-3">
              <div class=" row n-data-img justify-content-center">
                <img src="../../../../assets/images/no-data.svg" />
              </div>
              <div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Atro List End -->
<app-footer></app-footer>