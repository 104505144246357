import { HttpClient} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  BaseUrl: string = '';
  Token: any = '';

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base;
  }
  getWithFeaturedBlog() {
    const data = { isFeaturedRequired: true, isApproved: true };
    return this.http.post(this.BaseUrl + '/allBlogs', data);
  }

  public getAllBlogs(data: any) {
    return this.http.post(this.BaseUrl + '/allblogsweb', data);
  }

  public getBlogList(data: any) {
    return this.http.post(this.BaseUrl + '/allblogs', data);
  }

  public getSingleBlogById(id: any) {
    //return this.http.post(this.BaseUrl + '/getSingleBlog', id);
    return this.http.post(this.BaseUrl + '/getSingleBlogForWeb', id);
  }

  public getBlogCategory(data: any) {
    return this.http.post(this.BaseUrl + '/allblogcategory', data);
  }
}
