<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Blog Detail</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
          &nbsp;&nbsp;>&nbsp;&nbsp;
        </li>
        <li>Blog Detail</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- hs sidebar Start -->
<section class="mt-lg-5 mt-3">
  <div class="container glob-blog">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div class="">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <div class="">
                <div class="w-100 mb-4">
                  <span
                    class="rounded px-2 py-2 badge-success text-uppercase">{{blogDetails?.categoryId?.categoryname}}</span>
                </div>
                <h2 [innerHTML]="blogDetails?.blogTitle" class="font-weight-bold mb-2"></h2>
                <div class="d-flex mb-3 mt-3">
                  <div class="img-blog-writer">
                    <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" class="lh-3" />
                  </div>
                  <div class="align-middle w-name text-muted">by <b class=" text-danger">{{blogDetails?.userName}}
                    </b>{{blogDetails?.created_at | date:'dd-MM-yyyy'}}
                  </div>
                </div>

                <div class="hs_blog_box1_img_wrapper">
                  <!-- <img [lazyLoad]="BaseUrl+blogDetails?.blogImage" [defaultImage]="defaultSingleBlogImage"
                    class="img-fluid pb-4" /> -->
                    <img class = "img-fluid pb-4" [src] = "getSanitizeUrl(blogDetails?.blogImage)">
                </div>
                <div class="">
                  <div class="">
                    <!-- <h2 [innerHTML]="blogDetails?.blogTitle"></h2>
                    <h4><span></span></h4> -->
                    <div id="blog_description" class="" [innerHTML]="blogDetails?.blogDescription"></div>
                  </div>
                </div>
              </div>
              <div class="p-2 border bg-light rounded mt-3 ">
                <div class="d-flex mb-2">
                  <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" class="b-writer-img" />
                  <div class="d-flex align-items-center">
                    <div class="ml-2">
                      <h6 class="txt-red fw-700">{{blogDetails?.userName}}</h6>
                    </div>
                  </div>

                </div>
                <div class="ml-1 mt-1 d-flex">
                  <ul class="b-social-icon-s">
                    <a href="https://www.facebook.com/astroriverdotcom" target="_blank"><i
                  class="fa-brands fa-facebook p-1 rounded facebook text-white mx-1"></i></a>
                  <a href="https://api.whatsapp.com/send?phone=917009127641 " target="_blank"><i class="fa-brands fa-whatsapp p-1 rounded bg-success text-white mx-1"></i></a>
                  <a href="https://astroriver.quora.com/ " target="_blank"><i class="fa-brands fa-quora p-1 rounded quora text-white mx-1"></i></a>
                  <a href="https://www.pinterest.com/astroriver/ " target="_blank"><i class="fa-brands fa-pinterest-p p-1 rounded bg-danger text-white mx-1"></i></a>
                  <a href="https://twitter.com/astroriver/" target="_blank"><i class="fa-brands fa-twitter p-1 rounded bg-info text-white mx-1"></i></a>
                  <a href="https://www.instagram.com/astroriverofficial/" target="_blank"><i class="fa-brands fa-instagram p-1 rounded instagram text-white mx-1"></i></a>
                    <!-- <i class="fa-solid fa-arrow-up-right-from-square p-1 fa-2xl text-muted"></i> -->

                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class=" mt-3 order-bg rounded">
                        <h1>dsfsdf</h1>
                  </div> -->
      </div>

      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <!-- Social icons start  -->
        <div class="hs_blog_right_sidebar_main_wrapper">

          <div class="border-bottom">
            <h5 class="mb-2"><b><span class="text-danger">FOLLOW</span> US</b></h5>
          </div>
          <div class="d-flex">
            <div class="mt-3  s-btn-size">
              <a href="https://www.facebook.com/astroriverdotcom" target="_blank"
                class="btn btn-block btn-info  px-2 border-0 facebook s-icons-text text-white"><i
                  class="material-icons align-middle pr-2">facebook</i>Facebook</a>
            </div>
            <div class="mt-3  s-btn-size">
              <a href="https://www.linkedin.com/company/astro-river/" target="_blank"
                class="btn  btn-block btn-success  border-0  s-icons-text px-2"><i
                  class="fab fa-linkedin align-middle mr-2 fa-lg text-white"></i>LinkedIn</a>
            </div>

          </div>
          <div class="d-flex">
            <div class="mt-2 s-btn-size">
              <a href="https://astroriver.quora.com" target="_blank"
                class="btn btn-block btn-dark  px-4  border-0 quora s-icons-text text-white"><i
                  class="material-icons align-middle mr-2">quora</i>Quora</a>
            </div>
            <div class="mt-2 s-btn-size ">
              <a href="https://www.pinterest.com/astroriver/" target="_blank"
                class="btn btn-block btn-info  border-0 bg-danger s-icons-text px-3 text-white"><i
                  class="fab fa-pinterest-p align-middle mr-2 fa-lg "></i>Pinterest</a>
            </div>
          </div>
          <div class="d-flex">
            <div class="mt-2 s-btn-size">
              <a href="https://twitter.com/astroriver" target="_blank"
                class="btn btn-block btn-info border-0 bg-info s-icons-text text-white"><i
                  class="fab fa-twitter align-middle mr-2 fa-lg text-white"></i>Twitter</a>
            </div>
            <div class="mt-2 s-btn-size">
              <a href="https://www.instagram.com/astroriverofficial/" target="_blank"
                class="btn btn-block btn-info  border-0 instagram s-icons-text px-2 text-white"><i
                  class="fab fa-instagram align-middle mr-2 fa-lg "></i>Instagram</a>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="hs_blog_right_search_wrapper">
                <input type="text" #searchInput placeholder="Search" (keyup)="searchBlog(searchInput.value)" />
                <button type="submit" (click)="searchBlog(searchInput.value)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="isSearch">
              <div class="hs_blog_right_cate_list_heading_wrapper">
                <h2>Search Results</h2>
              </div>
              <div class="hs_blog_right_cate_list_cont_wrapper">
                <ul>
                  <li *ngIf="searchResult.length == 0">No Blog Found.</li>
                  <li *ngFor="let search of searchResult">
                    <a href="javascript:void(0)" (click)="showSearchedBlog(search._id)">{{ search.blogTitle }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="hs_blog_right_cate_list_heading_wrapper">
                <h2>Blog CATEGORIES</h2>
              </div>
              <div class="hs_blog_right_cate_list_cont_wrapper">
                <ul>
                  <li>
                    <a>{{ blogDetails?.categoryId.categoryname }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
        </div>
        <!-- Social icons End -->
        <!-- latest blog start here -->
        <div class="hs_blog_right_sidebar_main_wrapper mt-4">

          <div class="border-bottom">
            <h5 class="mb-2"><b><span class="text-danger">RECENT</span> BLOGS</b></h5>
          </div>
          <div *ngFor="let latestBlog of latestBlogs.slice(0,4)" class=" d-flex mt-3">
            <div class = "btn-cursor d-flex" [routerLink]="['/blog', latestBlog?.slug]">
              <img [src]="getSanitizeUrl(latestBlog?.blogImage)" class="w-25 h-img " />
              <div class="ml-2">
                <h6 class="text-truncate text-danger font-weight-bold small">
                  {{latestBlog?.categoryId?.categoryname}}
                </h6>
                <p class="text-break small text-muted lh-2 side-txt">{{latestBlog?.blogTitle}}</p>
                <p class="text-break small text-muted lh-2 side-txt glob-blog"
                  [innerHTML]="latestBlog?.blogsmallDescription"></p>
                <div class="small">
                  <span class="text-muted ">{{latestBlog?.userName}}</span>/ <span
                    class="material-icons align-middle fa-lg text-muted">
                    calendar_month
                  </span>
                  {{latestBlog?.created_at | date:'dd-MM-yyyy'}}
                </div>
  
              </div>
            </div>
            

          </div>
        </div>
        <!-- latest blog end here -->
        <div class="hs_blog_right_sidebar_main_wrapper mt-4" *ngIf = "featuredBlog?.length > 0">

          <div class="border-bottom">
            <h5 class="mb-2"><b><span class="text-danger">RELATED</span> BLOGS</b></h5>
          </div>
          <div class="b-bar d-flex mt-3">
            <img [src]="getSanitizeUrl(featuredBlog?.blogImage)" class="w-100 h-img1 " />
            <div class="content-blog">
              <p class="text-break  text-white lh-2 side-txt">{{featuredBlog?.blogTitle}}
              </p>
              <div class="small">
                <span class="text-white">{{featuredBlog?.userName}}</span>/ <span
                  class="material-icons align-middle fa-lg text-white">
                  calendar_month
                </span>
                {{featuredBlog?.created_at | date:'dd-MM-yyyy'}}
              </div>

            </div>

          </div>
          <div *ngFor="let relatedBlog of relatedBlogs" class=" d-flex mt-2">
            <img [src]="getSanitizeUrl(relatedBlog?.blogImage)" class="w-25 h-img " />
            <div class="ml-2">
              <h6 class="text-truncate text-danger font-weight-bold small">
                {{relatedBlog?.categoryId?.categoryname }}
              </h6>
              <p class="text-break small text-muted lh-2 side-txt">{{relatedBlog?.blogTitle}}</p>
              <div class="small">
                <span class="text-muted ">{{relatedBlog?.userName}}</span>/ <span
                  class="material-icons align-middle fa-lg text-muted">
                  calendar_month
                </span>
                {{relatedBlog?.created_at | date:'dd-MM-yyyy'}}
              </div>

            </div>

          </div>
        </div>
        <div class="hs_blog_right_sidebar_main_wrapper mt-4">

          <div class="border-bottom bg-danger rounded">
            <h6 class="p-2"><span class="text-white">Other categories</span></h6>
          </div>
          <div class="order-bg pl-2">
            <ul class="">
              <li class="border-bottom py-1" *ngFor="let category of otherCategories">
                  <a [routerLink] = "['/blog-categories', category?.categoryname, category?._id]">
                    {{category?.categoryname}}
                  </a> 
              </li>
            </ul>
          </div>
        </div>
        <div class="hs_blog_right_sidebar_main_wrapper mt-4" *ngIf="blogDetails?.metaTags != ('' && null && undefined)">

          <div class="border-bottom">
            <h5 class="mb-2"><b>TAGS</b></h5>
          </div>
          <div class="mt-2">
            <ul class="row small">
              <li *ngFor="let tag of blogDetails?.metaTags.split(',')"><button
                  class=" alert-danger border-0 rounded px-2 py-1 ml-2 mb-2">{{tag}}</button></li>
            </ul>
          </div>

        </div>
        <div class="hs_blog_right_sidebar_main_wrapper mt-4">
          <img [routerLink]="['/chat-astro-list']" src="assets/images/product/second-banner1.jpg" class="w-100 cursor-pointer mb-2">
          <img [routerLink]="['/call-astro-list']" src="assets/images/product/talk-banner1.jpg" class="w-100 cursor-pointer mt-2">
        </div>
      </div>
    </div>

    <div class="mb-5 mt-3">
      <div class="text-center ">
        <h4 class="mb-2"><b><span class="text-danger">YOU </span>MIGHT LIKE</b></h4>
      </div>
      <div class="row gx-2">
        <div *ngFor="let randomBlog of randomBlogs" class="col-12 col-md-3">
          <div class="b-bar1 d-flex mt-3 btn-cursor" [routerLink]="['/blog', randomBlog?.slug]">
            <img [src]="getSanitizeUrl(randomBlog?.blogImage)" class="w-100 h-img2" />
            <div class="content-blog1">
              <div class="d-flex align-middle">
                <span
                  class=" btn-danger bg-red btn-sm round-btn py-1 b-radius">{{randomBlog?.categoryId?.categoryname}}</span>
                
              </div>
              <span class="text-white small  align-middle mt-1">
                {{randomBlog?.userName}} / {{randomBlog?.created_at | date:'dd-MM-yyyy'}}
              </span>
              <p [title]="randomBlog?.blogTitle" class="text-break text-white side-txt  mt-1">
                {{randomBlog?.blogTitle}}
              </p>
              <div class="small">
                <p class="text-break text-white side-txt" [innerHTML]="randomBlog?.blogsmallDescription"></p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Top Astro Start -->
<section>
  <app-top-astro [sectionTitle]="sectionTitle"></app-top-astro>
</section>
<!-- Top Astro End -->

<app-footer></app-footer>
<!-- hs footer wrapper End -->