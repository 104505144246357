import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChatBotHeaderService } from 'src/app/service/chat-bot/chat-bot-header.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat-order-list',
  templateUrl: './chat-order-list.component.html',
  styleUrls: ['./chat-order-list.component.css'],
})
export class ChatOrderListComponent implements OnInit {
  dataList: any = [];
  filterType = 'all'
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  constructor(
    private userdata: UserdataService,
    private orderService: OrderServicesService,
    private router: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private headerBot: ChatBotHeaderService
  ) { }

  ngOnInit(): void {
    this.getChatOrders();
  }

  getChatOrders() {
    this.filterType = 'all'
    this.spinner.show()
    this.orderService
      .showChatOrders({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.spinner.hide()
        },
        (err: any) => {
          this.spinner.hide()
          this.toast.error(err.error.message, 'Error')
        }
      );
  }

  noReview() {
    this.spinner.show()
    this.filterType = 'review'
    this.orderService
      .showChatOrders({
        customerId: this.userdata.getCustomerId(),
        is_order_reviewed: false,
        is_order_completed: true,
      })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.spinner.hide()
        },
        (err: any) => {
          this.spinner.hide()
          //console.log(err)
        }
      );
  }

  noFeedback() {
    this.spinner.show()
    this.filterType = 'feedback'
    this.orderService.showChatOrders({ customerId: this.userdata.getCustomerId(), is_feedback_given: false, }).subscribe(
      (res: any) => {
        this.dataList = res.data;
        this.spinner.hide()
      },
      (err: any) => {
        this.spinner.hide()
        //console.log(err)
      }
    );
  }

  promoChat() {
    this.spinner.show()
    this.filterType = 'promo'
    this.orderService
      .showChatOrders({
        customerId: this.userdata.getCustomerId(),
        is_promotional_chat: true,
      })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.spinner.hide()
        },
        (err: any) => {
          this.spinner.hide()
          //console.log(err)
        }
      );
  }

  public convertSecondstoTime(val: any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0');
    return timeString;
  }

  /**
   * Check status for redirection
   */
  checkStatus(key: any) {
    //console.log(key)
    if (
      key.is_end ||
      key.order_current_status == 'Customer Reject' ||
      key.order_current_status == 'Chat Timeout' ||
      key.order_current_status == 'Pending' ||
      key.order_current_status == 'Astro Rejected'
    ) {
      //console.log('if')
      this.router.navigateByUrl('/chat-order-list/' + key.orderserviceId?._id);
    } else if (
      key.is_astrologer_accept &&
      key.is_astrologer_acceptance_used &&
      key.order_current_status == 'Astro Accepted'
    ) {
      //console.log('else if')
      let timerInterval: any;
      this.startTimer();
      /**
       * Update popup time code
       */
      var date = moment(key.customer_notification_time);
      var notify = date.utc().format('YYYY-MM-DD hh:mm:ss');
      var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
      var notifyTime = moment(notify, 'YYYY-MM-DD hh:mm:ss');
      var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
      var secondsDiff = currentTime.diff(notifyTime, 'seconds') + 10;
      var timeLeft = 180 - secondsDiff;
      if (timeLeft <= 0) {
        Swal.fire(
          'Timeout',
          "Chat request can't be accepted due to chat timeout.",
          'error'
        );
        return;
      }
      /**---------------------------------- */
      Swal.fire({
        title: 'New Chat Request Recieved',
        html:
          'Hey, Customer Just Accept the chat Astrologer is there' +
          '<br/><br/>Time Left: <b></b>.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ACCEPT',
        cancelButtonText: 'REJECT',
        allowOutsideClick: false,
        timer: timeLeft * 1000,
        timerProgressBar: true,
        didOpen: () => {
          //Swal.showLoading()
          timerInterval = setInterval(() => {
            const content = Swal.getHtmlContainer();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = String(
                  moment.utc(Swal.getTimerLeft()).format('mm:ss')
                );
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.value) {
          this.orderService
            .acceptRejectChat({ chatId: key._id, is_customer_accept: true })
            .subscribe(
              (res: any) => {
                // this.router.navigateByUrl(
                //   '/chat-ongoing/' + key.orderserviceId?._id
                // );
                this.headerBot.openBot(key.orderserviceId?._id);
              },
              (err: any) => {
                this.toast.error(err.error.message, 'Alert');
              }
            );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.orderService
            .acceptRejectChat({
              chatId: key._id,
              is_customer_accept: false,
            })
            .subscribe(
              (res: any) => {
                this.toast.success(res.message, 'Success');
              },
              (err: any) => {
                this.toast.error(err.error.message, 'Alert');
              }
            );
        }
      });
    } else if (
      key.order_current_status == 'Chat Ongoing' ||
      key.order_current_status == 'Chat ongoing' ||
      key.order_current_status == 'Chat Started'
    ) {
      //console.log('else if 2')
      //this.router.navigateByUrl('/chat-ongoing/' + key.orderserviceId?._id);
      this.router.navigateByUrl('/chat-order-list/' + key.orderserviceId?._id);
    } else {
      //console.log('else')
      this.router.navigateByUrl('/chat-order-list/' + key.orderserviceId?._id);
    }
  }

  /**
   * Counter For 3 minutes
   */
  counter: any;
  startTimer() {
    this.counter = { min: 3, sec: 0 }; // choose whatever you want
    let intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.min -= 1;
        this.counter.sec = 59;
      } else this.counter.sec -= 1;
      if (this.counter.min === 0 && this.counter.sec == 0)
        clearInterval(intervalId);
    }, 1000);
  }
}
