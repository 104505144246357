<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">My Account</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/home']" class="text-white">Home</a>
        </li>
        <li>
          <i class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </i>
        </li>
        <li>My Account</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!-- User Details Start -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="bg-light p-3 d-flex flex-row search align-items-end justify-content-between">
                <div class="bg-green rounded py-2 px-4 bb-balance">
                  <h6 class="text-center text-white fn-0">Your Balance</h6>
                  <h4 class="fn-2 fw-500 text-white">
                    {{ wallet | currency: myProfile?.currencyCode }}
                  </h4>
                </div>
                <div>
                  <a (click)="goToRecharge()" class="shadow-0 btn cus-border-green p-0 py-2 m-0 px-3 mx-2 text-body">
                    Recharge
                  </a>
                  <button (click)="openChangePassword()" type="button"
                    class="fw-500  btn m-0 py-2 border-white rounded bg-green shadow-0 text-white">Change
                    Password</button>
                </div>
              </div>
              <div class="row m-0 mt-4" *ngIf="isChangePassword">
                <form [formGroup]="passwordForm" class="w-100 card" (submit)="changePassword()">
                  <div class="card-header bg-active">
                    <h5 class="text-primary mb-2">Change Password</h5>
                  </div>
                  <div class="card-body pb-0 row">
                    <div class="form-group col-lg-6 col-md-6 col-12 m-0">
                      <label class="cus-label text-uppercase" for="exampleInputPassword1">Current Password</label>
                      <div class="input-group">
                        <input [type]="currentPasswordT ? 'text' : 'password'"
                          class="border border-right-0 form-control" id="exampleInputPassword1"
                          placeholder="Enter Your Current Password." formControlName="oldpassword" />
                        <button (click)="currentPasswordToggle()" class="
                            btn
                            border border-left-0
                            rounded-0
                            p-0
                            px-2
                            btn
                            bg-active
                            rounded-end
                            shadow-0
                          " type="button" id="button-addon2" data-mdb-ripple-color="dark">
                          <i class="material-icons-round center-align">
                            {{ currentPasswordT ? "visibility" : "visibility_off" }}
                          </i>
                        </button>
                      </div>
                      <small class="text-warning" *ngIf="
                          f.oldpassword.invalid &&
                          (f.oldpassword.dirty || f.oldpassword.touched)
                        ">
                        This field is required.
                      </small>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-12 m-0">
                      <label class="cus-label text-uppercase" for="exampleInputPassword1">New Password</label>
                      <div class="input-group">
                        <input [type]="newPasswordT ? 'text' : 'password'" class="form-control border border-right-0"
                          id="exampleInputPassword1" formControlName="newpassword" placeholder="Password" />
                        <button (click)="newPasswordToggle()" class="
                            border border-left-0
                            btn
                            rounded-0
                            p-0
                            px-2
                            btn
                            bg-active
                            rounded-end
                            shadow-0
                          " type="button" id="button-addon2" data-mdb-ripple-color="dark">
                          <i class="material-icons-round center-align">
                            {{ newPasswordT ? "visibility" : "visibility_off" }}
                          </i>
                        </button>
                      </div>
                      <small class="text-warning" *ngIf="
                          f.newpassword.invalid &&
                          (f.newpassword.dirty || f.newpassword.touched)
                        ">
                        This field is required.
                      </small>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-12 m-0 mt-2">
                      <label class="cus-label text-uppercase" for="exampleInputPassword1">Confirm New Password</label>
                      <div class="input-group">
                        <input [type]="confirmPasswordT ? 'text' : 'password'"
                          class="form-control border border-right-0" id="exampleInputPassword1"
                          formControlName="confirmpassword" placeholder="Password" />
                        <button (click)="confirmPasswordToggle()" class="
                            btn
                            border border-left-0
                            rounded-0
                            p-0
                            px-2
                            btn
                            bg-active
                            rounded-end
                            shadow-0
                          " type="button" id="button-addon2" data-mdb-ripple-color="dark">
                          <i class="material-icons-round center-align">
                            {{ confirmPasswordT ? "visibility" : "visibility_off" }}
                          </i>
                        </button>
                      </div>
                      <small class="text-warning" *ngIf="
                          f.confirmpassword.invalid &&
                          (f.confirmpassword.dirty || f.confirmpassword.touched)
                        ">
                        This field is required.
                      </small>
                    </div>
                  </div>
                  <div class="card-fotter bg-active py-2 text-center">
                    <button type="submit" class="btn bg-secondary py-2 px-5 text-white"
                      [disabled]="!passwordForm.valid">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 mt-4">
              <div class="card shadow">
                <div class="card-header bg-secondary-2">
                  <h4 class="fw-500 text-capitalize">basic information</h4>
                </div>
                <div class="card-body p-0">
                  <table class="table user-table">
                    <tr>
                      <td class="text-capitalize active-text border-right">Name</td>
                      <td class="text-capitalize">{{ myProfile?.name }}</td>
                    </tr>
                    <tr>
                      <td class="text-capitalize active-text border-right">Email</td>
                      <td class="text-lowercase">{{ myProfile?.email }}</td>
                    </tr>
                    <tr>
                      <td class="text-capitalize active-text border-right">Phone</td>
                      <td class="text-capitalize">
                        +{{ myProfile?.country_code }}&nbsp;{{ myProfile?.phone }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-capitalize active-text border-right">Wallet</td>
                      <td class="text-capitalize">
                        {{ wallet | currency: myProfile?.currencyCode }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- Profile Detail Start-->
            <section class="col-lg-12 col-md-12 col-12 mt-4" >
              <div class="container">
                <div class="row" *ngIf="myProfile?.selfprofileId != null">
                  <h4 class="cus-border-bottom fw-500">Person Profile</h4>
                  <div class="m-0 row w-100 mt-3">
                    <div class="pl-0 col-lg-6 col-md-6 col-12 p-profile-mob">
                      <div class="card">
                        <table class="table user-table">
                          <tr>
                            <td class="text-capitalize active-text border-right">
                              Full Name
                            </td>
                            <td>
                              <ng-container *ngIf="myProfile?.detail_name != undefined; else name">{{
                                myProfile?.selfprofileId?.detail_name }}</ng-container>
                              <ng-template #name>
                                {{ myProfile?.selfprofileId?.name }}
                              </ng-template>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-capitalize active-text border-right">Gender</td>
                            <td class="text-capitalize">
                              {{ myProfile?.selfprofileId?.gender }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-capitalize active-text border-right">
                              Birth details
                            </td>
                            <td>
                              <strong>DOB:</strong>{{ myProfile?.selfprofileId?.dob }}<br />
                              <strong>TOB:</strong>{{ myProfile?.selfprofileId?.tob }}<br />
                            </td>
                          </tr>
                          <tr>
                            <td class="text-capitalize active-text border-right">
                              Place Of Birth
                            </td>
                            <td>
                              {{ myProfile?.selfprofileId?.city }},
                              {{ myProfile?.selfprofileId?.state }},
                              {{ myProfile?.selfprofileId?.country }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="pr-0 col-lg-6 col-md-6 col-12 p-profile-mob1">
                      <div class="card">
                        <table class="table user-table">
                          <tr>
                            <td class="text-capitalize active-text border-right">Mobile</td>
                            <td>{{ myProfile?.selfprofileId?.mobile }}</td>
                          </tr>
                          <tr>
                            <td class="text-capitalize active-text border-right">
                              Employed-In
                            </td>
                            <td>{{ myProfile?.selfprofileId?.employed_in }}</td>
                          </tr>
                          <tr>
                            <td class="text-capitalize active-text border-right">
                              Marital Status
                            </td>
                            <td>{{ myProfile?.selfprofileId?.marital_status }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="card w-100">
                    <div class="card-header d-flex bg-active">
                      <h4 class="fw-500 flex-grow-1">Other Profile</h4>
                      <a routerLink="/add-person" class="text-capitalize green-text"><i
                          class="material-icons-round center-align">
                          add_circle_outline </i>Add new Profile</a>
                    </div>
                    <div class="card-body p-0">
                      <table class="table user-table table-bordered">
                        <thead class="bg-secondary text-white">
                          <th width="10%">S.no</th>
                          <th width="50%">Name</th>
                          <th width="40%">Action</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let user of peopleList; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ user.name }}</td>
                            <td>
                              <a [routerLink]="['/my-profile', user._id]" type="button" class="
                      text-white
                      btn-sm
                      text-capitalize
                      bg-green
                      py-1
                      mx-1
                      shadow-0
                      border
                      fw-500
                      border-white
                      rounded
                    ">
                                View
                              </a>
                              <a [routerLink]="['/update-person', user._id]" type="button" class="
                      btn-sm
                      text-capitalize
                      bg-secondary-2
                      text-body
                      py-1
                      mx-1
                      shadow-0
                      border
                      fw-500
                      border-white
                      rounded
                    ">
                                update
                              </a>
                              <button (click)="hidePersonProfile(user._id)" type="button" class="
                      text-white
                      btn-sm
                      text-capitalize
                      bg-blue
                      py-1
                      mx-1
                      shadow-0
                      border
                      fw-500
                      border-white
                      rounded
                    ">
                                delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Profile Detail End -->
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
<!-- User Details End-->



<!-- Logout Model Start -->
<ng-template #logout let-modal class="logoutModal">
  <div class="row d-flex flex-column m-0">
    <div class="modal-header">
      <h4>Logout!</h4>
    </div>
    <div class="modal-body modal-body m-0 p-2">
      <p class="py-3">Are you sure you wish to log out?</p>
    </div>
    <div class="modal-footer border-0 p-0">
      <button type="button" class="btn bg-green text-white py-2" aria-label="Close" (click)="modal.dismiss('false')">
        Cancel
      </button>
      <button type="button" class="btn bg-secondary py-2 text-white" (click)="modal.close('true')">
        Logout
      </button>
    </div>
  </div>
</ng-template>
<!-- Logout Model End-->

<app-footer></app-footer>