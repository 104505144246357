<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="w-100">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Chat</h3>
      <ul
        class="fw-500 text-white w-100 float-right d-flex justify-content-end"
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li>
          <a [routerLink]="['/store-order-list']" class="text-white">Store Orders</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li>
          <a [routerLink]="['/store-order-list',dataList?.orderId?._id]" class="text-white">Order Detail</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li><a>Chat</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-5" id="page-content">
  <div class="container">
    <div class="row rounded border">
      <div class="card card-bordered">
        <div class="card-header">
          <h4 class="card-title"><strong>Group Chat</strong></h4>
        </div>
        <div
          class="mt-4 ps-container ps-theme-default ps-active-y"
          id="chat-content"
          style="overflow-y: scroll !important; height: 400px !important"
          #scrollMe
          [scrollTop]="scrollMe.scrollHeight"
        >
          <ng-container *ngFor="let chat of chatList">
            <div
              class="media media-chat media-chat-reverse"
              *ngIf="chat.key == '- Initial'"
            >
              <div class="astro-chat media-body">
                <div class="initial-msg" [innerHtml]="chat.message"></div>
              </div>
            </div>
            <div
              *ngIf="
                chat.key != '- Initial' &&
                (chat.type == 2 || chat.type == 1) &&
                chat.is_to_show
              "
              class="media media-chat"
            >
              <img
                class="avatar"
                src="https://img.icons8.com/color/36/000000/administrator-male.png"
                alt="..."
              />
              <div class="mr-5 astro-chat media-body" *ngIf="!chat.isImage">
                <p class="block user-name m-0 p-0 mt-1 text-white">
                  {{ chat.name }}
                </p>
                <p class="inline-block m-0">{{ chat.message }}</p>
                <p class="meta">
                  <time datetime="2018">{{ chat.date | date: "medium" }}</time>
                </p>
              </div>
              <div class="media-body" *ngIf="chat.isImage">
                <p class="block user-name m-0 p-0">{{ chat.name }}</p>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 0"
                  ><img
                    [src]="getSanitizeUrl(chat.message)"
                    class="img-chat"
                    (click)="open(chat.message)"
                /></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/doc.png"
                      class="img-chat-doc" /></a
                ></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/excel.png"
                      class="img-chat-doc" /></a
                ></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/pdf-2.png"
                      class="img-chat-doc" /></a
                ></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/unkown.png"
                      class="img-chat-doc" /></a
                ></span>
                <p class="meta">
                  <time datetime="2018">{{ chat.date | date: "medium" }}</time>
                </p>
              </div>
            </div>
            <div
              *ngIf="chat.key != '- Initial' && chat.type == 3"
              class="media media-chat media-chat-reverse"
            >
              <div class="ml-5 cus-chat media-body" *ngIf="!chat.isImage">
                <p>{{ chat.message }}</p>
                <span
                  class="hidden-message text-danger"
                  *ngIf="!chat.is_to_show"
                  >This message will only appear to you.</span
                >
                <p class="meta" *ngIf="chat.is_to_show">
                  <time datetime="2018">{{ chat.date | date: "medium" }}</time
                  ><img
                    *ngIf="
                      chat.is_read_admin && chat.is_read_astrologer;
                      else notSeen
                    "
                    src="../../../../../assets/images/svgs/seen.svg"
                    style="width: 15px; margin-left: 0.2em"
                  />
                  <ng-template #notSeen>
                    <img
                      src="../../../../../assets/images/svgs/not-seen.svg"
                      style="width: 15px; margin-left: 0.2em"
                    />
                  </ng-template>
                </p>
              </div>
              <div class="media-body" *ngIf="chat.isImage">
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 0"
                  ><img
                    [src]="getSanitizeUrl(chat.message)"
                    class="img-chat"
                    (click)="open(chat.message)"
                /></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/doc.png"
                      class="img-chat-doc" /></a
                ></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/excel.png"
                      class="img-chat-doc" /></a
                ></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/pdf-2.png"
                      class="img-chat-doc" /></a
                ></span>
                <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"
                  ><a [href]="getOpenUrl(chat.message)" target="_blank"
                    ><img
                      src="../../../../../assets/icons/unkown.png"
                      class="img-chat-doc" /></a
                ></span>
                <p class="meta" *ngIf="chat.is_to_show">
                  <time datetime="2018">{{ chat.date | date: "medium" }}</time
                  ><img
                    *ngIf="chat.is_read; else notSeen"
                    src="../../../../../assets/images/svgs/seen.svg"
                    style="width: 15px; margin-left: 0.2em"
                  />
                  <ng-template #notSeen>
                    <img
                      src="../../../../../assets/images/svgs/double-not-seen.svg"
                      style="width: 15px; margin-left: 0.2em"
                    />
                  </ng-template>
                </p>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="astrologerTyping">
          {{ dataList.astroId.displayname }} is Typing....
        </div>
        <div *ngIf="adminTyping">Admin is Typing....</div>
        <div *ngIf="isChatComplete" class="alert alert-danger">
          Order Has Been Completed.
        </div>
        <div *ngIf="!isChatComplete" class="publisher bt-1 border-light">
          <img
            class="avatar avatar-xs"
            src="https://img.icons8.com/color/36/000000/administrator-male.png"
            alt="..."
          />
          <form
            [formGroup]="sendMsgForm"
            (submit)="sendMsg()"
            (keydown)="checkEnterPressed($event)"
          >
            <textarea
              (keyup)="onKeyUpInput($event)"
              (blur)="onBlurInput($event)"
              class="publisher-input"
              placeholder="Write something"
              formControlName="message"
              id="send"
              rows="1"
            ></textarea>
            <button
              [disabled]="!sendMsgForm.valid"
              class="btn btn-sm btn-danger ml-1 mr-1"
              type="submit"
              data-abc="true"
              title="Send Message"
            >
              <i class="fa fa-paper-plane"></i>
            </button>
            <ng-container>
              <input
                type="file"
                class="file-input"
                (change)="onFileSelected($event)"
                #fileUpload
              />
              <span
                class="d-inline-block btn btn-sm btn-info add-attachment"
                (click)="fileUpload.click()"
              >
                <i class="fa fa-paperclip"></i>
              </span>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
