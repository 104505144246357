import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-verify-email-otp',
  templateUrl: './verify-email-otp.component.html',
  styleUrls: ['./verify-email-otp.component.css']
})
export class VerifyEmailOtpComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginService, private toast: ToastrService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private userdata: UserdataService, private location: Location) { }

  ngOnInit(): void {
    this.resendOtp()
  }

  onOtpChange(event: any) {
    if (event.length == 4) {
      this.loginService.verifyEmailOtp({ email: this.userdata.getEmail(), otp: event }).subscribe(
        (res: any) => {
          localStorage.setItem("isEmailVerify", 'true')
          this.toast.success(res.message, 'Success')
          let call = sessionStorage.getItem('call');
          let pro_detail = sessionStorage.getItem('product-detail');
          if (call !== null) {
            sessionStorage.removeItem('call');
            this.router.navigate(['/astrologer', call]);
            return;
          }else if(sessionStorage.getItem('admin-promo') == 'yes'){
            sessionStorage.removeItem('admin-promo');
            this.router.navigate(['/show-promo']);
          }
          else if(pro_detail !== null) {
            sessionStorage.removeItem('product-detail');
            this.router.navigate(['/product-detail', pro_detail]);
            return;
          }else{
            this.location.back()
          }
        }, err => {
          this.toast.error(err.error.message, 'Error')
        }
      )
    }
  }

  resendOtp() {
    this.loginService.resendEmailOtp({ email: this.userdata.getEmail() }).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

}
