<!--<app-header></app-header>
<ngx-spinner></ngx-spinner>-->
<!-- BradCrumbs Start -->
<!--<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Chat</h3>
      <ul
        class="fw-500 text-white w-100 float-right d-flex justify-content-end"
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round"> arrow_forward_ios </span>
        </li>
        <li class="text-capitalize">Chat</li>
      </ul>
    </div>
  </div>
</section>-->
<!--BradCrumbs End -->

<!-- <section class="">
  <div class="padding">
    <div class="row container d-flex justify-content-center">
      <div class="col-md-8 add-border"> -->
<div class="card card-bordered" style="height: 94%">
  <div class="card-header d-flex flex-column align-items-start">
    <div class="d-flex w-100 justify-content-between align-items-center">
      <h4 class="card-title">
        <strong> {{ dataList.astroId?.displayname }}</strong>
        <a [routerLink]="['/recharge-chat-amount',id]" title="Recharge Wallet"><i
            class="ml-2 fa-solid fa-wallet wallet-icon"></i></a>
      </h4>
      <!-- <div class="flex-grow-1"></div> -->
      <button (click)="endChat()"
        *ngIf="!dataList.chatId?.is_end && dataList?.chatId?.is_astrologer_accept && dataList?.chatId?.is_customer_accept"
        class="mychat-btn btn-sm mr-2 shadow-0 rounded btn bg-secondary px-3 py-2 text-white fw-500 rounded d-flex justify-content-center mt-1">
        End Chat
      </button>
    </div>
    <a class="text-inactive fn-1" href="#" data-abc="true">Time Left: {{ hours }}:{{ minutes }}:{{ seconds }}</a>
  </div>
  <div *ngIf="ifLessThanFiveMinutesLeft" class="d-flex justify-content-center bg-light p-1">
    <p class="rechargeText text-black text-sm mt-2 mr-2"> You have less than 5 minutes left </p>
    <a [routerLink]="['/recharge-chat-amount',id]" title="Recharge Wallet"
      class="btn-sm shadow-0 rounded btn bg-secondary p-2 text-white text-sm rounded d-flex justify-content-center">Recharge
    </a>
  </div>
  <div class="ps-container h-100 overflow-auto" id="chat-content" #scrollMe [scrollTop]="scrollMe.scrollHeight">
    <ng-container *ngFor="let chat of chatList">
      <div class="media media-chat media-chat-reverse" *ngIf="chat.key == '- Initial'">
        <div class="astro-chat media-body">
          <div class="initial-msg text-white" [innerHtml]="chat.message"></div>
        </div>
      </div>
      <div *ngIf="chat.key != '- Initial' && chat.type == 2 && chat.is_to_show" class="media media-chat">
        <div class="astro-chat media-body" *ngIf="!chat.isImage">
          <p class="inline-block">{{ chat.message }}</p>
          <p class="meta">
            <time datetime="2018">{{ chat.date | date: "medium" }}</time>
          </p>
        </div>
        <div class="media-body" *ngIf="chat.isImage">
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 0"><img [src]="getSanitizeUrl(chat.message)"
              class="img-chat" (click)="open(chat.message)" /></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/doc.png" class="img-chat-doc" /></a></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/excel.png" class="img-chat-doc" /></a></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/pdf-2.png" class="img-chat-doc" /></a></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/unkown.png" class="img-chat-doc" /></a></span>
          <p class="meta">
            <time datetime="2018">{{ chat.date | date: "medium" }}</time>
          </p>
        </div>
      </div>
      <div *ngIf="chat.key != '- Initial' && chat.type == 3" class="media media-chat media-chat-reverse">
        <div class="cus-chat media-body" *ngIf="!chat.isImage">
          <p>{{ chat.message }}</p>
          <span class="hidden-message text-danger" *ngIf="!chat.is_to_show">This message will only appear to you.</span>
          <p class="meta" *ngIf="chat.is_to_show">
            <time datetime="2018">{{ chat.date | date: "medium" }}</time><img *ngIf="chat.is_read; else notSeen"
              src="../../../../../assets/images/svgs/seen.svg" style="width: 15px; margin-left: 0.2em" />
            <ng-template #notSeen>
              <img src="../../../../../assets/images/svgs/double-not-seen.svg"
                style="width: 15px; margin-left: 0.2em" />
            </ng-template>
          </p>
        </div>
        <div class="media-body" *ngIf="chat.isImage">
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 0"><img [src]="getSanitizeUrl(chat.message)"
              class="img-chat" (click)="open(chat.message)" /></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 1"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/doc.png" class="img-chat-doc" /></a></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 2"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/excel.png" class="img-chat-doc" /></a></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 3"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/pdf-2.png" class="img-chat-doc" /></a></span>
          <span class="p-2" *ngIf="checkExtenstion(chat.message) == 4"><a [href]="getOpenUrl(chat.message)"
              target="_blank"><img src="../../../../../assets/icons/unkown.png" class="img-chat-doc" /></a></span>
          <p class="meta" *ngIf="chat.is_to_show">
            <time datetime="2018">{{ chat.date | date: "medium" }}</time><img *ngIf="chat.is_read; else notSeen"
              src="../../../../../assets/images/svgs/seen.svg" style="width: 15px; margin-left: 0.2em" />
            <ng-template #notSeen>
              <img src="../../../../../assets/images/svgs/double-not-seen.svg"
                style="width: 15px; margin-left: 0.2em" />
            </ng-template>
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="text-info" *ngIf="astrologerTyping">
    {{ dataList.astroId.displayname }} is Typing....
  </div>
  <div *ngIf="dataList.chatId?.is_end" class="alert alert-danger">
    The Chat has Ended.
  </div>
  <div
    *ngIf="!dataList.chatId?.is_end && dataList?.chatId?.is_astrologer_accept && dataList?.chatId?.is_customer_accept"
    class="bg-inactive border p-2">
    <form [formGroup]="sendMsgForm" (submit)="sendMsg()" (keydown)="checkEnterPressed($event)">
      <textarea (keyup)="onKeyUpInput($event)" (blur)="onBlurInput($event)" class="publisher-input"
        placeholder="Write something" formControlName="message" id="send" rows="1"></textarea>
      <button [disabled]="!sendMsgForm.valid" class="size-40 rounded-circle bg-secondary text-white m-0 shadow"
        type="submit" data-abc="true" title="Send Message">
        <i class="fa fa-paper-plane"></i>
      </button>
      <ng-container>
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />
        <button type="button" class="
            size-40
            text-white
            rounded-circle
            btn-info
            add-attachment
            shadow
            ml-2
            m-0
          " (click)="fileUpload.click()">
          <i class="fa fa-paperclip"></i>
        </button>
      </ng-container>
    </form>
  </div>
</div>
<!-- </div>
    </div>
  </div>
</section> -->
<!-- <app-footer></app-footer> -->