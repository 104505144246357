<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Booking</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li><a (click)="goBack()"> Booking</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <h3 class="fw-500">Book Now</h3>
    </div>
    <div class="row mt-lg-5 mt-3 rounded border p-3">
      <form [formGroup]="saveOrderForm" class="w-100" (submit)="saveOrder()">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="inputPassword4">Product Name</label><br />
            <span>{{ dataList.productname }}</span>
          </div>
          <div class="form-group col-md-4">
            <label for="inputPassword4">Referred By</label>
            <ng-select [items]="consultantarray" placeholder="Select Consultant" formControlName="consultantId"
              bindLabel="_id.name" bindValue="_id._id">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div style="margin: 10px 0" class="card">
                  <div class="card-body">
                    <p class="card-text d-flex">
                      <span>
                        <img height="50" width="50" [src]="getSanitizeUrl(item._id.profile_image)" />
                      </span>
                      <span>{{ item._id.name }}</span>
                    </p>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group col-md-4" *ngIf="isAstrologerAssociate">
            <label for="astroId">Select Astrologer</label>
            <ng-select [items]="dataList.astrologerList" placeholder="Select Astrologer" formControlName="astroId"
              bindLabel="astroId.name" bindValue="astroId._id">
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div style="margin: 10px 0" class="card">
                  <div class="card-body">
                    <h5 class="card-title">{{ item.astroId.displayname }}</h5>
                    <p class="card-text d-flex">
                      <span>
                        <img height="50" width="50" [src]="getSanitizeUrl(item.astroId.profile_image)" />
                        <br />
                        <ng-template #t let-fill="fill">
                          <span class="star" [class.full]="fill === 100">
                            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                          </span>
                        </ng-template>
                        <ngb-rating [(rate)]="item.astroId.astrologer_rating" [starTemplate]="t" [readonly]="true"
                          [max]="5"></ngb-rating>
                      </span>
                      <span>{{ item.astroId.name }}<br />
                        <p *ngIf="code; else usd">
                          {{ item.price | currency: "INR":"symbol-narrow" }}
                        </p>
                        <ng-template #usd>
                          {{ item.usd_price | currency: "USD":"symbol-narrow" }}
                        </ng-template>
                      </span>
                    </p>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group col-md-4" *ngIf="isNameOn">
            <label for="inputPassword4">Name*</label>
            <input type="text" class="form-control" formControlName="name" />
            <div *ngIf="f.name.invalid && (f.name.touched || f.name.dirty)">
              <small class="error-msg text-danger font-10">Name is required. Please Enter a Valid Name</small>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isEmailOn">
            <label for="inputPassword4">Email*</label>
            <input type="text" class="form-control" formControlName="email" />
            <div *ngIf="f.email.invalid && (f.email.touched || f.email.dirty)">
              <small class="error-msg text-danger font-10">Email is required. Please Enter a Valid Email</small>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isPhoneOn">
            <label for="inputPassword4">Contact Number*</label>
            <div class="form-row">
              <div class="col-md-5">
                <!-- <select name="countryCode" class="form-control" formControlName="countryCode">
                  <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                    {{ country.emoji }}&nbsp;&nbsp;{{
                    country.name
                    }}&nbsp;&nbsp;(+{{ country.phone_code }})
                  </option>
                </select> -->
                <ng-select [searchFn]="customSearchFn" formControlName="countryCode"
                  class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
                  bindValue="phone_code">
                  <ng-template ng-option-tmp let-item="item">
                    <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                    item.name
                    }}&nbsp;&nbsp;(+{{ item.phone_code }})
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-md">
                <input type="text" class="form-control" name="phone " formControlName="phone" />
                <div *ngIf="f.phone.invalid && (f.phone.touched || f.phone.dirty)">
                  <small class="error-msg text-danger font-10">Phone Number is required. Please Enter a Valid Phone
                    Number</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isGenderOn">
            <label for="inputPassword4">Gender</label><br />
            <div class="custom-control-inline">
              <input type="radio" value="male" formControlName="gender" />&nbsp;
              <label> Male</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" value="female" formControlName="gender" />&nbsp;
              <label> Female</label>
            </div>
            <div *ngIf="f.gender.invalid && (f.gender.touched || f.gender.dirty)">
              <small class="error-msg text-danger font-10">Gender is required. Please Enter a Valid Gender</small>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isDobOn">
            <label for="inputPassword4">Date Of Birth*</label>
            <input type="date" class="form-control" formControlName="dob" [max]="today" />
            <div *ngIf="f.dob.invalid && (f.dob.touched || f.dob.dirty)">
              <small class="error-msg text-danger font-10">dob is required. Please Enter a Valid Date of Birth</small>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isTobOn">
            <label for="inputPassword4">Time Of Birth*</label>
            <input type="time" class="form-control" formControlName="tob" />
            <div *ngIf="f.tob.invalid && (f.tob.touched || f.tob.dirty)">
              <small class="error-msg text-danger font-10">tob is required. Please Enter a Valid Time of Birth</small>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isMaritalStatusOn">
            <label for="inputPassword4">Marital Status</label><br />
            <select class="form-control" formControlName="maritalStatus">
              <option value="" selected disabled>Select Marital Status</option>
              <option>Single</option>
              <option>Married</option>
              <option>Widowed</option>
              <option>Divorced</option>
            </select>
          </div>
          <div class="form-group col-md-4" *ngIf="isCommentsOn">
            <label for="inputPassword4">Mention Remark/Problem</label><br />
            <div class="custom-control-inline">
              <textarea cols="50" class="form-control" formControlName="comments"
                placeholder="Mention your Remark/Problem"></textarea>
            </div>
            <div *ngIf="
                f.comments.invalid && (f.comments.touched || f.comments.dirty)
              ">
              <small class="error-msg text-danger font-10">Comments is required.</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md" *ngIf="is_addon_associate">
            <label for="inputPassword4">Select Addons</label>
            <div class="row">
              <div *ngFor="let item of addonarray; let i = index" class="col-3">
                <div class="card position-relative">
                  <input (click)="getaddon(item, i)" type="checkbox" class="position-absolute end-0 m-1" value="{{ i }}"
                    [checked]="i == selectedAddon" />
                  <div class="card-body p-2 d-flex">
                    <div class="flex-shrink-0">
                      <img class="size-50 rounded border" [src]="getSanitizeUrl(item.addon_image)" />
                    </div>
                    <div class="flex-grow-1 ml-2">
                      <h6>{{ item.addon_name }}</h6>
                      <span class="fn-0">
                        <span *ngIf="code; else usd">{{
                          item.addon_price | currency: "INR":"symbol-narrow"
                          }}</span>
                        <ng-template #usd>
                          <span>{{
                            item.addon_usd_price
                            | currency: "USD":"symbol-narrow"
                            }}</span>
                        </ng-template>
                      </span>
                      <p class="pt-2 d-flex flex-column" *ngIf="item.addon_type == 1">
                        <input type="text" class="form-control mt-0" placeholder="Ring Size" [id]="'ring' + i" />
                        <small class="my-1">Ring Size is Required:</small>
                        <small class="card-text text-danger" *ngIf="item.is_price_may_vary">Note: Final Amount may
                          vary.</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="newAddonArray?.length" class="my-2">
              <table class="table table-striped table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Cost</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of newAddonArray; index as i">
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ data["addOnName"] }}
                      <span *ngIf="
                          data.addOnSize != undefined &&
                          data.addOnSize != '' &&
                          data.addOnSize != null
                        ">
                        (Ring Size: {{ data["addOnSize"] }})</span>
                    </td>
                    <td>
                      <p *ngIf="code; else usd">
                        {{
                        data["addOnPrice"] | currency: "INR":"symbol-narrow"
                        }}
                      </p>
                      <ng-template #usd>{{
                        data["addon_usd_price"]
                        | currency: "USD":"symbol-narrow"
                        }}</ng-template>
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger" (click)="deleteAddon(i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isQuantityOn">
            <label for="inputPassword4">Quantity*</label>
            <input readonly type="text" class="form-control" formControlName="quantity" />
            <div *ngIf="
                f.quantity.invalid && (f.quantity.touched || f.quantity.dirty)
              ">
              <small class="error-msg text-danger font-10">Quantity is required. Please Enter a Valid Number</small>
            </div>
          </div>
          <div class="form-group col-md-4" *ngIf="isAddressOn">
            <label for="inputPassword4">Address</label>
            <ng-template #address let-modal>
              <div class="modal-header" style="padding-top: 30px !important">
                <h4 class="modal-title" id="modal-basic-title pt-2">
                  My Address
                </h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="row modal-body m-0 pt-0">
                <ng-container *ngIf="isSelectAddress">
                  <button type="button" (click)="showAddNewAddressForm()"
                    class="btn btn-outline-green btn-block col-md-12 mb-2">
                    Add Address
                  </button>
                  <div class="card col-12" *ngFor="let address of addressList">
                    <div class="card-body">
                      <h5 class="card-title mb-1">{{ address.name }}</h5>
                      <p class="card-text mb-1">
                        <ng-container>Address. </ng-container>{{ address.flatNo }}, {{ address.locality }},
                        {{ address.city }}, {{ address.state }},
                        {{ address.country }}, {{ address.pinCode }}
                      </p>
                      <h6 class="card-subtitle mb-2 text-muted">
                        <ng-container>Contact No. </ng-container>
                        +{{ address.countryCodePhoneNum }}-{{
                        address.phoneNum
                        }}
                      </h6>
                      <a href="javascript:void(0)" class="card-link text-success"
                        (click)="selectAddress(address)">Select</a>
                      <a href="javascript:void(0)" class="card-link" (click)="deleteAddress(address._id)">Delete</a>
                      <a href="javascript:void(0)" class="card-link text-info"
                        (click)="showUpdateAddressForm(address)">Update</a>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="isAddNewAddress">
                  <form class="col-md-12 mt-4" [formGroup]="addAddressForm" (submit)="saveAddress()">
                    <div class="row">
                      <div class="col-3 form-group">
                        <label for="name">Name*</label>
                        <input type="text" class="form-control" placeholder="Example: Home, Office, Work etc."
                          formControlName="name" />
                        <div *ngIf="
                            a.name.invalid && (a.name.touched || a.name.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Name is required. Please Enter a Valid
                            Name</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="flatNo">Flat/House Number*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Flat or House Number"
                          formControlName="flatNo" />
                        <div *ngIf="
                            a.flatNo.invalid &&
                            (a.flatNo.touched || a.flatNo.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Flat No is Required</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="locality">Locality*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Locality"
                          formControlName="locality" />
                        <div *ngIf="
                            a.locality.invalid &&
                            (a.locality.touched || a.locality.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Locality is Required</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="landmark">LandMark</label>
                        <input type="text" class="form-control" placeholder="Enter Your landmark (Optional)"
                          formControlName="landmark" />
                        <div *ngIf="
                            a.landmark.invalid &&
                            (a.landmark.touched || a.landmark.dirty)
                          ">
                          <small class="error-msg text-danger font-10">LandMark is Required</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 form-group">
                        <label for="name">Contact Number*</label>
                        <div class="d-flex">
                          <!-- <select name="countryCode" class="form-control w-40" formControlName="countryCodePhoneNum">
                            <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                              {{ country.emoji }}&nbsp;&nbsp;{{
                              country.name
                              }}&nbsp;&nbsp;(+{{ country.phone_code }})
                            </option>
                          </select> -->
                          <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
                            class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                            bindLabel="phone_code" bindValue="phone_code">
                            <ng-template ng-option-tmp let-item="item">
                              <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                              item.name
                              }}&nbsp;&nbsp;(+{{ item.phone_code }})
                            </ng-template>
                          </ng-select>
                          <input type="text" class="form-control w-60" placeholder="Enter Contact Number"
                            formControlName="phoneNum" />
                        </div>
                        <div *ngIf="
                            (a.countryCodePhoneNum.invalid &&
                              (a.countryCodePhoneNum.touched ||
                                a.countryCodePhoneNum.dirty)) ||
                            (a.phoneNum.invalid &&
                              (a.phoneNum.touched || a.phoneNum.dirty))
                          ">
                          <small class="error-msg text-danger font-10">Contact Number is required. Please Enter a Valid
                            Contact Number.</small>
                        </div>
                      </div>
                      <div class="col-6 form-group">
                        <label for="name">Alternate Number(Optional)</label>
                        <div class="d-flex">
                          <!-- <select class="form-control w-40" formControlName="countryCodeAltPhoneNum">
                            <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                              {{ country.emoji }}&nbsp;&nbsp;{{
                              country.name
                              }}&nbsp;&nbsp;(+{{ country.phone_code }})
                            </option>
                          </select> -->
                          <ng-select [searchFn]="customSearchFn" formControlName="countryCodeAltPhoneNum"
                            class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                            bindLabel="phone_code" bindValue="phone_code">
                            <ng-template ng-option-tmp let-item="item">
                              <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                              item.name
                              }}&nbsp;&nbsp;(+{{ item.phone_code }})
                            </ng-template>
                          </ng-select>
                          <input type="text" class="form-control w-60" placeholder="Enter Alternate Number"
                            formControlName="alternatePhoneNum" />
                        </div>
                        <div *ngIf="
                            (a.countryCodeAltPhoneNum.invalid &&
                              (a.countryCodeAltPhoneNum.touched ||
                                a.countryCodeAltPhoneNum.dirty)) ||
                            (a.alternatePhoneNum.invalid &&
                              (a.alternatePhoneNum.touched ||
                                a.alternatePhoneNum.dirty))
                          ">
                          <small class="error-msg text-danger font-10">Please Enter a Valid Alternate Contact
                            Number.</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3 form-group">
                        <label for="city">City*</label>
                        <input type="text" class="form-control" placeholder="Enter Your City" formControlName="city" />
                        <div *ngIf="
                            a.city.invalid && (a.city.touched || a.city.dirty)
                          ">
                          <small class="error-msg text-danger font-10">City is required.</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="state">State*</label>
                        <input type="text" class="form-control" placeholder="Enter Your State"
                          formControlName="state" />
                        <div *ngIf="
                            a.state.invalid &&
                            (a.state.touched || a.state.dirty)
                          ">
                          <small class="error-msg text-danger font-10">State is required.</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="country">Country*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Country"
                          formControlName="country" />
                        <div *ngIf="
                            a.country.invalid &&
                            (a.country.touched || a.country.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Country is required.</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="pinCode">Pincode*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Pincode"
                          formControlName="pinCode" />
                        <div *ngIf="
                            a.pinCode.invalid &&
                            (a.pinCode.touched || a.pinCode.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Pincode is required.</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7 offset-md-5 form-group">
                        <button type="submit" class="btn btn-outline-green btn-block"
                          [disabled]="addAddressForm.invalid">
                          Submit
                        </button>
                      </div>
                      <div class="col-md-12 text-center form-group text-danger" *ngIf="addAddressForm.invalid">
                        Fields with * are required.
                      </div>
                    </div>
                  </form>
                </ng-container>
                <ng-container *ngIf="isUpdateAddress">
                  <form class="col-md-12 mt-4" [formGroup]="updateAddressForm" (submit)="updateAddress()">
                    <div class="row">
                      <div class="col-3 form-group">
                        <label for="name">Name*</label>
                        <input type="text" class="form-control" placeholder="Example: Home, Office, Work etc."
                          formControlName="name" />
                        <div *ngIf="
                            u.name.invalid && (u.name.touched || u.name.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Name is required. Please Enter a Valid
                            Name</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="flatNo">Flat/House Number*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Flat or House Number"
                          formControlName="flatNo" />
                        <div *ngIf="
                            u.flatNo.invalid &&
                            (u.flatNo.touched || u.flatNo.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Flat No is Required</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="locality">Locality*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Locality"
                          formControlName="locality" />
                        <div *ngIf="
                            u.locality.invalid &&
                            (u.locality.touched || u.locality.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Locality is Required</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="landmark">LandMark(Optional)</label>
                        <input type="text" class="form-control" placeholder="Enter Your landmark (Optional)"
                          formControlName="landmark" />
                        <div *ngIf="
                            u.landmark.invalid &&
                            (u.landmark.touched || u.landmark.dirty)
                          ">
                          <small class="error-msg text-danger font-10">LandMark is Required</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 form-group">
                        <label for="name">Contact Number*</label>
                        <div class="d-flex">
                          <!-- <select class="form-control w-40" formControlName="countryCodePhoneNum">
                            <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                              {{ country.emoji }}&nbsp;&nbsp;{{
                              country.name
                              }}&nbsp;&nbsp;(+{{ country.phone_code }})
                            </option>
                          </select> -->
                          <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
                            class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                            bindLabel="phone_code" bindValue="phone_code">
                            <ng-template ng-option-tmp let-item="item">
                              <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                              item.name
                              }}&nbsp;&nbsp;(+{{ item.phone_code }})
                            </ng-template>
                          </ng-select>
                          <input type="text" class="form-control w-60" placeholder="Enter Contact Number"
                            formControlName="phoneNum" />
                        </div>
                        <div *ngIf="
                            (u.countryCodePhoneNum.invalid &&
                              (u.countryCodePhoneNum.touched ||
                                u.countryCodePhoneNum.dirty)) ||
                            (u.phoneNum.invalid &&
                              (u.phoneNum.touched || u.phoneNum.dirty))
                          ">
                          <small class="error-msg text-danger font-10">Contact Number is required. Please Enter a Valid
                            Contact Number.</small>
                        </div>
                      </div>
                      <div class="col-6 form-group">
                        <label for="name">Alternate Number(Optional)</label>
                        <div class="d-flex">
                          <!-- <select class="form-control w-40" formControlName="countryCodeAltPhoneNum">
                            <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                              {{ country.emoji }}&nbsp;&nbsp;{{
                              country.name
                              }}&nbsp;&nbsp;(+{{ country.phone_code }})
                            </option>
                          </select> -->
                          <ng-select [searchFn]="customSearchFn" formControlName="countryCodeAltPhoneNum"
                            class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                            bindLabel="phone_code" bindValue="phone_code">
                            <ng-template ng-option-tmp let-item="item">
                              <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                              item.name
                              }}&nbsp;&nbsp;(+{{ item.phone_code }})
                            </ng-template>
                          </ng-select>
                          <input type="text" class="form-control w-60" placeholder="Enter Alternate Number"
                            formControlName="alternatePhoneNum" />
                        </div>
                        <div *ngIf="
                            (u.countryCodeAltPhoneNum.invalid &&
                              (u.countryCodeAltPhoneNum.touched ||
                                u.countryCodeAltPhoneNum.dirty)) ||
                            (u.alternatePhoneNum.invalid &&
                              (u.alternatePhoneNum.touched ||
                                u.alternatePhoneNum.dirty))
                          ">
                          <small class="error-msg text-danger font-10">Please Enter a Valid Alternate Contact
                            Number.</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3 form-group">
                        <label for="city">City*</label>
                        <input type="text" class="form-control" placeholder="Enter Your City" formControlName="city" />
                        <div *ngIf="
                            u.city.invalid && (u.city.touched || u.city.dirty)
                          ">
                          <small class="error-msg text-danger font-10">City is required.</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="state">State*</label>
                        <input type="text" class="form-control" placeholder="Enter Your State"
                          formControlName="state" />
                        <div *ngIf="
                            u.state.invalid &&
                            (u.state.touched || u.state.dirty)
                          ">
                          <small class="error-msg text-danger font-10">State is required.</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="country">Country*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Country"
                          formControlName="country" />
                        <div *ngIf="
                            u.country.invalid &&
                            (u.country.touched || u.country.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Country is required.</small>
                        </div>
                      </div>
                      <div class="col-3 form-group">
                        <label for="pinCode">Pincode*</label>
                        <input type="text" class="form-control" placeholder="Enter Your Pincode"
                          formControlName="pinCode" />
                        <div *ngIf="
                            u.pinCode.invalid &&
                            (u.pinCode.touched || u.pinCode.dirty)
                          ">
                          <small class="error-msg text-danger font-10">Pincode is required.</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="
                          col-md-12
                          d-flex
                          justify-content-center
                          form-group
                        ">
                        <button type="button" class="btn btn-outline-info mr-4" (click)="addressBackPage()">
                          Back
                        </button>
                        <button type="submit" class="btn btn-outline-info" [disabled]="updateAddressForm.invalid">
                          Submit
                        </button>
                      </div>
                      <div class="col-md-12 text-center form-group text-danger" *ngIf="updateAddressForm.invalid">
                        Fields with * are required.
                      </div>
                    </div>
                  </form>
                </ng-container>
              </div>
              <div class="modal-footer p-0">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close()">
                  Close
                </button>
              </div>
            </ng-template>
            <button type="button" class="form-control btn btn-sm btn-outline-green" (click)="openAddressPopup(address)">
              {{ isAddressSelected ? "Change Address" : "Select Address" }}
            </button>
            <div *ngIf="isAddressSelected" class="my-2">
              <div class="card col-12">
                <div class="card-body">
                  <h5 class="card-title mb-1">{{ selectedAddress.name }}</h5>
                  <p class="card-text mb-1">
                    <ng-container>Address. </ng-container>{{ selectedAddress.flatNo }},
                    {{ selectedAddress.locality }}, {{ selectedAddress.city }},
                    {{ selectedAddress.state }}, {{ selectedAddress.country }},
                    {{ selectedAddress.pinCode }}
                  </p>
                  <h6 class="card-subtitle mb-2 text-muted">
                    <ng-container>Contact No. </ng-container>
                    +{{ selectedAddress.countryCodePhoneNum }}-{{
                    selectedAddress.phoneNum
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <hr />
            <button type="submit" class="btn bg-secondary px-3 py-2 m-0 text-white fw-500 rounded"
              [disabled]="!saveOrderForm.valid">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<app-footer></app-footer>