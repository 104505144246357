import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/service/product/product.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-astrologer',
  templateUrl: './astrologer.component.html',
  styleUrls: ['./astrologer.component.css']
})
export class AstrologerComponent implements OnInit {

  BaseUrl:string = '';
  dataList:any = new Array
  product_id:any
  constructor(
    private product : ProductService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private route : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngOnInit(): void {
    this.product_id = this.route.snapshot.paramMap.get('_id')
    // console.log(this.userdata.getCustomerId())
    this.getAstrologerList()
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  productdata:any
  getAstrologerList(){
    this.spinner.show()
    this.productdata={
      _id : this.product_id
    }
    this.product.getProductDetail(this.productdata).subscribe(
      (res:any)=>{
        this.spinner.hide()
        this.dataList = res.data.astrologerList
        // console.log(this.dataList)
      },err=>{
        this.spinner.hide()
      }
    )
  }

  booking(evnt:any,name:any){
    localStorage.setItem('astrologerId',evnt)
    localStorage.setItem('astrologerName',name)
    // console.log('astrologer Id', localStorage.getItem('astrologerId'))
    this.router.navigateByUrl('/order-product/'+this.product_id)
  }
}
