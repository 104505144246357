import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FestivalsService } from 'src/app/service/festivals/festivals.service';

@Component({
  selector: 'app-festival-details',
  templateUrl: './festival-details.component.html',
  styleUrls: ['./festival-details.component.css']
})
export class FestivalDetailsComponent implements OnInit {
  BaseUrl: any;festivalList:any;current_year:any
  constructor(
    private festivalsService: FestivalsService,
    private route: ActivatedRoute,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  id: any;
  blogDetails: any;
  ngOnInit(): void {
    this.current_year=(new Date()).getFullYear()
    this.route.params.subscribe(routeParams => {
      this.id = routeParams['id']
      this.getSingleFestival()
      this.getAllFestivals()
    })
  }

  // get single festival
  festival: any = [];
  getSingleFestival() {
    this.festivalsService.getSingleFestivalById({ _id: this.id }).subscribe(
      (res: any) => {
        this.festival = res.data;
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  //get all festival list
  getAllFestivals() {
    this.festivalsService.getAllFestivals({year:this.current_year}).subscribe(
      (res: any) => {
        this.festivalList = res.data;
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  searchFestival(key: any) {
    this.festivalsService.searchFestivals({ search: key }).subscribe(
      (res: any) => {
        this.festival = res.data;
      }, err => {
      }
    )
  }

}


