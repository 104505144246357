import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookingService } from 'src/app/service/booking/booking.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { AddressDialogComponent } from './address-dialog/address-dialog.component';

@Component({
  selector: 'app-my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.css'],
})
export class MyAddressComponent implements OnInit {
  addressList = new Array();
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  constructor(
    private userdata: UserdataService,
    private booking: BookingService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.getAddressesList();
  }
  getAddressesList() {
    this.spinner.show();
    this.booking
      .getAddressesList({ customerId: this.userdata.getId(), status: true })
      .subscribe({
        next: (v) => {
          this.spinner.hide();
          this.addressList = v.result;
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }
  addressId = '';
  openDialog(id: string) {
    this.addressId = id;
    this.addAddress();
  }
  addAddress() {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      width: '100%',
      data: this.addressId,
      disableClose: true,
    })
    dialogRef.afterClosed().subscribe(result => {
      this.getAddressesList()
    });
  }
}
