import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserdataService {
  BaseUrl = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') _base: string) {
    this.BaseUrl = _base;
  }

  public setData(userdata: any) {
    //login data
    //console.log('userdata', userdata);
    localStorage.setItem('token', userdata.token);
    localStorage.setItem(
      'regCountryCode',
      userdata.customerdetail.regCountryCode
    );
    localStorage.setItem(
      'selfprofileId',
      userdata.customerdetail.selfprofileId
    );

    localStorage.setItem('email', userdata.data.email);
    localStorage.setItem('isSocialLogin', userdata.data.is_social_login);
    localStorage.setItem('name', userdata.data.name);
    localStorage.setItem('phone', userdata.data.phone);
    localStorage.setItem('id', userdata.customerdetail._id);
    localStorage.setItem('userId', userdata.data._id);
    localStorage.setItem('customerId', userdata.customerdetail._id);
    localStorage.setItem('autoId', userdata.customerdetail.customerAutoId);
    localStorage.setItem('pic', userdata.customerdetail.profile_image);
    localStorage.setItem(
      'isPhoneVerify',
      userdata.customerdetail.is_otp_verified
    );
    localStorage.setItem(
      'isEmailVerify',
      userdata.customerdetail.is_email_verified
    );
    localStorage.setItem(
      'currentWallet',
      userdata.customerdetail.current_wallet
    );
    localStorage.setItem(
      'isPromo',
      userdata.customerdetail.is_allow_promotional
    );
    localStorage.setItem(
      'isCallGoing',
      String(userdata.customerdetail.is_current_call)
    );
    localStorage.setItem(
      'isChatGoing',
      String(userdata.customerdetail.is_current_chat)
    );
  }

  public setProfileData(data:any){
    localStorage.setItem(
      'regCountryCode',
      data.regCountryCode
    );
    localStorage.setItem(
      'selfprofileId',
      data.selfprofileId ? data.selfprofileId?._id : null
    );

    localStorage.setItem('email', data.email);
    localStorage.setItem('name', data.name);
    localStorage.setItem('phone', data.phone);
    //localStorage.setItem('id', data._id);
    //localStorage.setItem('customerId', data._id);
    localStorage.setItem(
      'isPhoneVerify',
      data.is_otp_verified
    );
    localStorage.setItem(
      'isEmailVerify',
      data.is_email_verified
    );
    localStorage.setItem(
      'currentWallet',
      data.current_wallet
    );
    localStorage.setItem(
      'isPromo',
      data.is_allow_promotional
    );
    localStorage.setItem(
      'isCallGoing',
      String(data.is_current_call)
    );
    localStorage.setItem(
      'isChatGoing',
      String(data.is_current_chat)
    );
  }

  public getToken() {
    return localStorage.getItem('token') || '';
  }
  public getRegion() {
    return localStorage.getItem('region') || '';
  }
  public getEmail() {
    return localStorage.getItem('email') || '';
  }
  public getLatLong() {
    return localStorage.getItem('loc') || '';
  }
  public getIsSocialLogin() {
    return localStorage.getItem('isSocialLogin') || '';
  }
  public isLogin() {
    return this.getToken().length > 0 && this.getPhoneVerify() == 'true';
  }

  public getName() {
    return localStorage.getItem('name') || '';
  }

  public getPhone() {
    return localStorage.getItem('phone') || '';
  }

  public getId() {
    return localStorage.getItem('id') || '';
  }

  public getAutoId() {
    return localStorage.getItem('autoId') || '';
  }

  public getPic() {
    return localStorage.getItem('pic') || '';
  }

  public getUserId() {
    return localStorage.getItem('userId') || '';
  }

  public getCustomerId() {
    return localStorage.getItem('customerId') || '';
  }
  public isIndia() {
    return localStorage.getItem('countryCode') == 'in';
  }
  public getCountryCode() {
    return localStorage.getItem('countryCode') || '';
  }
  public getRegCountry() {
    return localStorage.getItem('regCountryCode') || '';
  }
  /**
   * Phone Verify
   */
  getNotifications(data: any) {
    return this.http.post<any>(this.BaseUrl + '/mynotifications', data);
  }
  public setPhoneVerify(data: any) {
    localStorage.setItem('isPhoneVerify', String(data));
    //console.log(localStorage.getItem("isPhoneVerify"))
  }

  public getPhoneVerify() {
    return localStorage.getItem('isPhoneVerify');
  }

  /**
   * Email Verify
   */

  public setEmailVerify(data: any) {
    localStorage.setItem('isEmailVerify', String(data));
    //console.log(localStorage.getItem("isEmailVerify"))
  }

  public getEmailVerify() {
    return localStorage.getItem('isEmailVerify');
  }

  public getOrderObject() {
    return sessionStorage.getItem('orderObject');
  }

  /**
   * Current Wallet
   */

  public setCurrentWallet(data: any) {
    localStorage.setItem('currentWallet', data);
    //console.log(localStorage.getItem("currentWallet"))
  }

  public getCurrentWallet() {
    return localStorage.getItem('currentWallet');
  }

  /**
   * Promo Service
   */

  public setIsPromo(data: any) {
    localStorage.setItem('isPromo', String(data));
    //console.log(localStorage.getItem("isPromo"))
  }

  public getIsPromo() {
    return localStorage.getItem('isPromo');
  }

  /**
   * Call Ongoing
   */

  public setIsCallGoing(data: any) {
    localStorage.setItem('isCallGoing', String(data));
    //console.log(localStorage.getItem("isCallGoing"))
  }

  public getIsCallGoing() {
    return localStorage.getItem('isCallGoing');
  }

  /**
   * Chat Ongoing
   */

  public setIsChatGoing(data: any) {
    localStorage.setItem('isChatGoing', String(data));
    //console.log(localStorage.getItem("isChatGoing"))
  }

  public getIsChatGoing() {
    return localStorage.getItem('isChatGoing');
  }

  /**
   * FCM
   */
  public setFcm(fcm: any) {
    localStorage.setItem('fcm', fcm);
  }

  public getFcm() {
    return localStorage.getItem('fcm');
  }

  /** set current route */
  public setCurrentRoute(route: any) {
    sessionStorage.setItem('currentUrl', route);
  }

  /** get current route */
  public getCurrentRoute() {
    return sessionStorage.getItem('currentUrl');
  }

  /** remove current route */
  public removeCurrentRoute() {
    sessionStorage.removeItem('currentUrl');
  }

  public removeData() {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('isSocialLogin');
    localStorage.removeItem('phone');
    localStorage.removeItem('id');
    localStorage.removeItem('autoId');
    localStorage.removeItem('pic');
    localStorage.removeItem('userId');
    localStorage.removeItem('customerId');
    localStorage.removeItem('isPhoneVerify');
    localStorage.removeItem('isEmailVerify');
    localStorage.removeItem('currentWallet');
    localStorage.removeItem('isPromo');
    localStorage.removeItem('fcm');
    localStorage.removeItem('regCountryCode');
    sessionStorage.removeItem('product-detail')
    sessionStorage.removeItem('call')
  }
}
