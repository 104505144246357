import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PassDataService } from 'src/app/service/data/pass-data.service';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-astrologer-details',
  templateUrl: './astrologer-details.component.html',
  styleUrls: ['./astrologer-details.component.css'],
})
export class AstrologerDetailsComponent implements OnInit {
  BaseUrl: string = '';
  dataList: any = new Array();
  id: string = '';
  reviews: any = new Array();
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  isCallGoing: any;
  isChatGoing: any;
  isWaitingGoing: any;
  constructor(
    private trusturl: DomSanitizer,
    private userdata: UserdataService,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private openService: OpenApiService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: PassDataService,
    @Inject('BASE_IMAGE_URL') _imageurl: any, private title: Title
  ) {
    this.BaseUrl = _imageurl;
  }

  mul = 2;myProfile:any;
  ngOnInit(): void {
    if (!this.userdata.isIndia()) {
      this.mul = 1;
    }
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.getAstroDetails();
    if (this.userdata.getToken() != null && this.userdata.getToken() != '') {
      this.loginService
        .myProfile({ _id: this.userdata.getCustomerId() })
        .subscribe(
          (res: any) => {
          this.myProfile = res.data;
            this.userdata.setProfileData(res.data)
            this.isCallGoing = res.data.is_current_call;
            this.isChatGoing = res.data.is_current_chat;
            this.isWaitingGoing = res.data.is_waiting;
            this.spinner.hide();
          },
          (err) => {
            this.toast.error('Something went wrong', 'Alert');
          }
        );
    }
  }

  public getSanitizeUrl(url: string) {
    //return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
    return this.BaseUrl+url
  }
  getAstroDetails() {
    this.spinner.show()
    var data = {};
    if (
      this.userdata.getToken() != null &&
      typeof this.userdata.getToken() != 'undefined'
    ) {
      // data = { _id: this.id, customerId: this.userdata.getCustomerId() };
      data = { _id: this.id, customerId: this.userdata.getCustomerId() };
    } else {
      // data = { _id: this.id };
      data = { _id: this.id };
    }
    this.openService.singleAstrologerDetails(data).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.title.setTitle(`Astro River - ${res.data.displayname}`)
        this.dataList = res.data;
        this.reviews = res.reviews;
        this.spinner.hide()
      },
      (err) => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }
  astroBio = false;
  public toggleBio() {
    this.astroBio = !this.astroBio;
  }

  @ViewChild("headerOne") headerOne:any;
  endpoint:any
  buttonAction(key: string) {
    // console.log(" id is ", this.id)
    this.endpoint = key
    if (!this.userdata.isLogin()) {
      sessionStorage.setItem('call', this.id);
      this.toast.error('Please login first');
      this.headerOne?.openFromAnotherComponent()
      return;
    }else if(this.userdata.getEmailVerify() == ('false' || false)){
      sessionStorage.setItem('call', this.id);
      this.toast.error('Please Verify Email');
      this.router.navigateByUrl('/verify-email-otp')
      return
    }else if (!this.checkBalance()) {
      Swal.fire({
        title: 'Insufficient Balance',
        text: 'Minimum Amount required in wallet is ' + this.myProfile?.currencySymbol + ' ' + this.astroCharges,
        iconHtml: '<i class="fn-4 fa-solid fa-wallet text-primary border-0"></i>',
        confirmButtonText: 'Recharge Wallet',
        confirmButtonColor: '#19af0f'
      }).then((result:any) => {
        if(result.isConfirmed){
          sessionStorage.setItem('call', this.dataList._id);
          this.router.navigateByUrl('/recharge-wallet')
        }
      })
      return
    }
    else{
      this.placeOrder(key);
    }
  }
  placeOrder(endpoint: string) {
    this.router.navigateByUrl('/order-list-person');
    this.dataService.startOrder({
      astroId: this.dataList._id,
      endpoint: endpoint,
    });
  }


  getCharges() {
    const key = this.dataList;
    switch (this.endpoint) {
      case 'call-astro-list':
      case 'promo-astro-list-call': {
        var charges = 0;
        if (key.isCallOfferApplicable) {
          charges = key.astroCallChargesWithOffer;
        } else {
          charges = key.call_charges * this.mul;
        }
        return charges * 5;
      }

      case 'chat-astro-list':
      case 'promo-astro-list-chat': {
        var charges = 0;
        if (key.isChatOfferApplicable) {
          charges = key.astroChatChargesWithOffer;
        } else {
          charges = key.chat_charges * this.mul;
        }
        return charges * 5;
      }

      case 'query-astro-list': {
        var charges = 0;
        if (key.isQueryOfferApplicable) {
          charges = key.astroQueryChargesWithOffer;
        } else {
          charges = key.query_charges * this.mul;
        }
        return charges;
      }

      case 'report-astro-list': {
        var charges = 0;
        if (key.isReportOfferApplicable) {
          charges = key.astroReportChargesWithOffer;
        } else {
          charges = key.report_charges * this.mul;
        }
        return charges;
      }

      default: {
        var charges = 0;
        if (key.isCallOfferApplicable) {
          charges = key.astroCallChargesWithOffer;
        } else {
          charges = key.call_charges * this.mul;
        }
        return charges * 5;
      }
    }
  }

  astroCharges: number = 0
  checkBalance() {
    //this.spinner.show()
    const balance = this.myProfile.current_wallet;
    this.astroCharges = this.getCharges();
    // console.log(astroCharges, balance)
    //return
    if (this.astroCharges > balance) {
      return false
    } else {
      return true
    }
  }
  // checkOfferValue(val: any, id: any) {
  //   var charges = 0;
  //   if (id == 'call') {
  //     if (val.isnewforastro) {
  //       if (val.callofferis.user_type == 1 || val.callofferis.user_type == 3) {
  //         charges =
  //           val.call_charges * 2 -
  //           val.call_charges * 2 * (val.callofferis.offer_discount / 100);
  //       } else {
  //         charges = val.call_charges * 2;
  //       }
  //     } else {
  //       if (val.callofferis.user_type == 1 || val.callofferis.user_type == 2) {
  //         charges =
  //           val.call_charges * 2 -
  //           val.call_charges * 2 * (val.callofferis.offer_discount / 100);
  //       } else {
  //         charges = val.call_charges * 2;
  //       }
  //     }
  //   }
  //   if (id == 'chat') {
  //     if (val.isnewforastro) {
  //       if (val.chatofferis.user_type == 1 || val.chatofferis.user_type == 3) {
  //         charges =
  //           val.chat_charges * 2 -
  //           val.chat_charges * 2 * (val.chatofferis.offer_discount / 100);
  //       } else {
  //         charges = val.chat_charges * 2;
  //       }
  //     } else {
  //       if (val.chatofferis.user_type == 1 || val.chatofferis.user_type == 2) {
  //         charges =
  //           val.chat_charges * 2 -
  //           val.chat_charges * 2 * (val.chatofferis.offer_discount / 100);
  //       } else {
  //         charges = val.chat_charges * 2;
  //       }
  //     }
  //   }

  //   if (id == 'query') {
  //     if (val.isnewforastro) {
  //       if (
  //         val.queryofferis.user_type == 1 ||
  //         val.queryofferis.user_type == 3
  //       ) {
  //         charges =
  //           val.query_charges * 2 -
  //           val.query_charges * 2 * (val.queryofferis.offer_discount / 100);
  //       } else {
  //         charges = val.query_charges * 2;
  //       }
  //     } else {
  //       if (
  //         val.queryofferis.user_type == 1 ||
  //         val.queryofferis.user_type == 2
  //       ) {
  //         charges =
  //           val.query_charges * 2 -
  //           val.query_charges * 2 * (val.queryofferis.offer_discount / 100);
  //       } else {
  //         charges = val.query_charges * 2;
  //       }
  //     }
  //   }

  //   if (id == 'report') {
  //     if (val.isnewforastro) {
  //       if (
  //         val.reportofferis.user_type == 1 ||
  //         val.reportofferis.user_type == 3
  //       ) {
  //         charges =
  //           val.report_charges * 2 -
  //           val.report_charges * 2 * (val.reportofferis.offer_discount / 100);
  //       } else {
  //         charges = val.report_charges * 2;
  //       }
  //     } else {
  //       if (
  //         val.reportofferis.user_type == 1 ||
  //         val.reportofferis.user_type == 2
  //       ) {
  //         charges =
  //           val.report_charges * 2 -
  //           val.report_charges * 2 * (val.reportofferis.offer_discount / 100);
  //       } else {
  //         charges = val.report_charges * 2;
  //       }
  //     }
  //   }

  //   return charges;
  // }
}
