<div class="card">
  <div class="card-header">
    <h4 class="fw-500">Alert!</h4>
  </div>
  <div class="card-body">
    <p>Remove yourself from waitlist of expert?</p>
  </div>
  <div class="border-top card-fotter d-flex justify-content-end">
    <button mat-button [mat-dialog-close]="true" class="btn py-2 px-3 bg-secondary shadow-0 mr-2 text-white fw-500">
      Yes
    </button>
    <button mat-button mat-dialog-close class="btn py-2 px-3 bg-inactive shadow-0 fw-500">
      No
    </button>
  </div>
</div>