<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">General Prediction</h3>
            <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end 
        ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>General Prediction</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<section class="container my-5">
    <div class="row gx-5">
        <div class="col-12 my-2">
            <swiper [config]="zodiacConfig">
                <ng-template swiperSlide *ngFor="let sign of originalZodiaList; let i=index">
                    <div class="
                    mt-4
                    mx-3
                    position-relative
                    p-4
                    d-flex
                    justify-content-end
                    border pointer
                  " [ngClass]="{'horoscope-active-bg':i+1 == zodiac, 'horoscope-bg':i+1 != zodiac}" (click)="changeZodiac(i)">
                        <a>
                            <img [src]="getSanitizeUrl(sign.image)" class="
                        bg-active
                        p-2
                        border border-white
                        rounded-circle
                        position-absolute
                        horoscope-img
                      " />
                        </a>
                        <div>
                            <h5 class="text-center fw-500" [ngClass]="{'text-dark':i+1 == zodiac, 'text-white':i+1 != zodiac}">
                                {{ sign.name }}
                            </h5>
                            <span [ngClass]="{'text-dark':i+1 == zodiac, 'text-white':i+1 != zodiac}">{{ sign.time }}</span>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
        <div class="col-12 col-md-3 col-lg-3 my-2">
            <div class="border rounded shadow-sm">
                <table class="table  table-hover rounded">
                    <tbody>
                        <tr>
                            <th scope="col" class="bg-secondary  border-0 rounded-top h5 text-white"><i class="fa-solid fa-hand-dots p-1 text-warning"></i> Prediction</th>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'DAILY'" (click)="changeSubTab('DAILY')">
                            <td>DAILY MOON</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'WEEKLY'" (click)="changeSubTab('WEEKLY')">
                            <td>WEEKLY MOON</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'DAILYSUN'" (click)="changeSubTab('DAILYSUN')">
                            <td>DAILY SUN</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'WEEKLYSUN'" (click)="changeSubTab('WEEKLYSUN')">
                            <td>WEEKLY SUN</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 col-md-9 col-lg-9 my-2">
            <div class="mb-3  rounded p-3 bg-red-light">
                <h5 class="mb-1 txt-red">Lucky Color(s)</h5>
                <p class="text-justify text-capitalize">{{response?.lucky_color}}</p>

            </div>
            <div class="mb-3  rounded p-3 bg-yellow-light">
                <h5 class="mb-1 txt-red">Lucky Number</h5>
                <p class="text-justify">{{getJoin(response?.lucky_number)}}</p>
            </div>

            <div class="mb-3  rounded p-3 bg-green-light">
                <h5 class="mb-1 txt-red">Prediction</h5>
                <p class="text-justify">{{response?.bot_response?.status?.split_response}} {{response?.bot_response?.finances?.split_response}} {{response?.bot_response?.physique?.split_response}} {{response?.bot_response?.relationship?.split_response}} {{response?.bot_response?.career?.split_response}} {{response?.bot_response?.travel?.split_response}} {{response?.bot_response?.family?.split_response}} {{response?.bot_response?.friends?.split_response}} {{response?.bot_response?.health?.split_response}} {{response?.bot_response?.total_score?.split_response}}</p>
            </div>
        </div>
    </div>
</section>