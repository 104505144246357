import { Component, Inject, OnInit } from '@angular/core';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { Autoplay, SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
SwiperCore.use([Navigation, Autoplay]);
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit {
  BaseUrl = '';sectionTitle = 'Top Astrologers'
  constructor(
    private trusturl: DomSanitizer,
    private _openService: OpenApiService,
    private _userService: UserdataService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngOnInit(): void {
    this.getTopAstro();
  }
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  topList = new Array();
  getTopAstro() {
    this.spinner.show();
    var data: any = { startpoint: 0 };
    if (this._userService.getToken().length > 0) {
      data = { customerId: this._userService.getCustomerId(), startpoint: 0 };
    }
    this._openService.getAstrologersDashboard(data).subscribe(
      (res: any) => {
        this.topList = res.data;
        this.spinner.hide();
      },
      (err: any) => {
        //console.log('test', err);
        this.spinner.hide();
      }
    );
  }
  // /    autoplay: {
  //     delay: 5000,
  //   },
  astroConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 4,
    direction: 'horizontal',
    spaceBetween: 40,

    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
    effect: 'slide',
  };

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
