<app-header></app-header>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Chat Placed</h3>
      <ul
        class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        "
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Chat Placed</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="card w-100 p-4">
      <h1 class="display-4 text-primary">Chat Placed Successfully.</h1>
      <p class="lead text-black">
        You chat order has been placed with Astrologer. Please wait until we
        connect you with astrologer.
      </p>
      <hr class="my-4" />
      <div>
        <a
          class="rounded btn px-5 py-2 bg-secondary fw-500 text-white"
          [routerLink]="['/home']"
          role="button"
          >Back To Home</a
        >
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
