<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
      <div class="container d-flex">
        <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">{{id}}</h3>
        <ul class="
            d-none d-lg-flex
            fw-500
            text-white
            w-100
            float-right
            justify-content-end
          ">
          <li>
            <a [routerLink]="['/']" class="text-white">Home</a>
          </li>
          <li>
            <span class="fn-2 material-icons-round bottom-align">
              arrow_forward_ios
            </span>
          </li>
          <li>
            <a class="text-capitalize text-white" hre="#">{{id}}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- BradCrumbs End -->
<div class="container">
    <div class="row p-4">
        <div class="col-md-12" [innerHTML]="pageContent"></div>
      </div>
</div>
<app-footer></app-footer>