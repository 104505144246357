<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Report Details
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize text-white">
          <a class="text-white" [routerLink]="['/report-order-list']">Report Orders</a>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="row">
          <h4 class="fw-500">Report Detail</h4>
        </div>
        <div class="row mt-3">
          <table class="table table-stripped table-bordered">
            <tbody>
              <tr>
                <th>Order Number</th>
                <td># {{ dataList.reportId?.oreportId }} <span
                    class="badge badge-success">{{dataList?.reportId?.order_current_status }}</span></td>
              </tr>
              <tr>
                <th>Order Date</th>
                <td>{{ dataList.reportId?.created_at | date: "medium" }}</td>
              </tr>
              <tr>
                <th>Astrologer</th>
                <td>
                  {{
                  dataList.astroId?.displayname == "" ||
                  dataList.astroId?.displayname == null ||
                  dataList.astroId?.displayname == undefined
                  ? dataList.astroId?.name
                  : dataList.astroId?.displayname
                  }}
                </td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  {{ dataList.reportId?.customerCurrencySymbol
                  }}{{ dataList.reportId?.customer_amount }}
                </td>
              </tr>
              <tr>
                <th>Report Name</th>
                <td>{{ dataList.reportdetilId?.reportname }}</td>
              </tr>
              <tr>
                <td colspan="2"><strong class="font-weight-bold">Report Description:</strong>
                  <p [class.line-size-5]="moreLess">
                    {{ dataList.reportdetilId?.reportdescription }}
                  </p>
                  <button (click)="descToggle()" type="button" class="btn py-2 px-2 fn-1 my-3 bg-secondary text-white">
                    Show <span *ngIf="moreLess">More</span><span *ngIf="!moreLess">Less</span>
                  </button>
                </td>
              </tr>
              <tr>
                <th>Language</th>
                <td>{{ dataList.langId?.name }}</td>
              </tr>
              <tr *ngIf="dataList.reportId?.is_astro_answer">
                <td colspan="2">
                  <div class="fix-answer-height">
                    <strong class="font-weight-bold">Answer:</strong><br> 
                    <div [innerHTML]="textFormat(dataList.reportId?.astro_answer)"></div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="dataList.reportId?.is_feedback_given">
                <th>Feedback</th>
                <td>
                  {{ dataList.reportId?.feedback_value }}
                  <button class="btn btn-link" (click)="open(feedback,'feedback')"
                    *ngIf="checkThreeDays(dataList.reportId?.created_at)">
                    Change
                  </button>
                </td>
              </tr>
              <tr *ngIf="dataList.reportId?.is_astro_replied_feedback">
                <th>Feedback Reply</th>
                <td>{{ dataList.reportId?.astro_feedback_reply }}</td>
              </tr>
              <tr *ngIf="dataList.reportId?.is_order_reviewed">
                <th>Rating</th>
                <td>
                  {{ dataList.reportId?.customer_review }} -
                  <span class="p-2 border border-danger">{{ dataList.reportId.customer_review_stars }}
                    <i class="fa fa-star text-danger"></i></span>
                  <button class="btn btn-link" (click)="open(review,'review')"
                    *ngIf="checkSevenDays(dataList.reportId?.created_at)">
                    Change
                  </button>
                </td>
              </tr>
              <tr *ngIf="dataList.reportId?.is_astro_replied_review">
                <th>Rating Reply</th>
                <td>{{ dataList.reportId?.astro_review_reply }}</td>
              </tr>
              <tr *ngIf="
                  dataList.reportId?.is_astro_answer &&
                  !dataList.reportId?.is_feedback_given &&
                  !dataList.reportId?.is_order_reviewed &&
                  checkThreeDays(dataList.reportId?.created_at)
                ">
                <td colspan="2" class="text-center yellow-btn ">
                  <button class="btn btn-block btn-primary border-0 px-5" (click)="open(feedback,'feedback')">
                    Give Feedback
                  </button>
                </td>
              </tr>
              <tr *ngIf="
                  dataList.reportId?.is_astro_answer &&
                  !dataList.reportId?.is_order_reviewed &&
                  checkSevenDays(dataList.reportId?.created_at)
                ">
                <td colspan="2">
                  <button class="btn-block btn btn-primary " (click)="open(review,'review')">
                    Give Rating
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Feedback Section-->
          <ng-template #feedback let-c="close" let-d="dismiss">
            <div class="modal-header pb-0">
              <h4 class="modal-title" id="modal-basic-title">Feedback</h4>
              <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                  class="material-icons text-white">close</span></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="feedbackForm" (submit)="giveFeedback()" *ngIf="
              dataList.reportId?.is_astro_answer && checkThreeDays(dataList.reportId?.created_at)">
                <div class="row mt-1">
                  <div class="col-md-12">
                    <div class="form-group">
                      <!-- <label for=""><strong>Give Feedback: </strong></label> -->
                      <textarea class="form-control" formControlName="feedback_value" rows="5"></textarea>
                      <div *ngIf="
                      g.feedback_value.invalid &&
                      (g.feedback_value.dirty || g.feedback_value.touched)
                    " class="alert alert-danger mt-1">
                        Required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="user-wrap d-flex justify-content-center">
                      <button type="submit" class="col-4 btn bg-red text-white mt-1 mb-2 p-0"
                        [disabled]="!feedbackForm.valid">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
          <!-- Review Section-->
          <ng-template #review let-c="close" let-d="dismiss">
            <div class="modal-header pb-0">
              <h4 class="modal-title" id="modal-basic-title">Review</h4>
              <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                  class="material-icons text-white">close</span></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="reviewForm" (submit)="giveReview()"
                *ngIf="dataList.reportId?.is_astro_answer && checkSevenDays(dataList.reportId?.created_at)">
                <div class="row mt-1">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for=""><strong>Rate: </strong></label>
                      <br />
                      <ngb-rating formControlName="customer_review_stars" [max]="5">
                        <ng-template let-fill="fill" let-index="index">
                          <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                        </ng-template>
                      </ngb-rating>
                      <div *ngIf="
                      r.customer_review_stars.invalid &&
                      (r.customer_review_stars.dirty ||
                        r.customer_review_stars.touched)
                    " class="alert alert-danger mt-1">
                        Required.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for=""><strong>Review: </strong></label>
                      <textarea class="form-control" formControlName="customer_review" rows="5"></textarea>
                      <div *ngIf="
                      r.customer_review.invalid &&
                      (r.customer_review.dirty || r.customer_review.touched)
                    " class="alert alert-danger mt-1">
                        Required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="user-wrap d-flex justify-content-center">
                      <button type="submit" class="col-4 btn bg-red text-white mt-1 mb-2 p-0"
                        [disabled]="!reviewForm.valid">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
        </div>
        <!-- <div class="row" *ngIf="dataList.reportId?.is_astro_answer">
          <div class="col-md-12 text-dark mb-1 font-weight-bold">
            Free Complementary Promo
          </div>
          <ng-container *ngIf="!dataList.reportId?.promotional_call_used">
            <div class="col-md-5">
              <button type="button" class="btn btn-success btn-block"
                [disabled]="!checkSevenDays(dataList.reportId?.created_at)" (click)="getPromoCall('call')">
                Call
              </button>
            </div>
            <div class="col-md-2 text-center">OR</div>
            <div class="col-md-5">
              <button type="button" class="btn btn-primary bg-orange btn-block"
                [disabled]="!checkSevenDays(dataList.reportId?.created_at)" (click)="getPromoCall('chat')">
                Chat
              </button>
            </div>
            <small class="col-md-12 text-muted mt-2"><strong>Note:</strong> You can use this Free Complementary Promo
              call
              or chat only within 7 days</small>
          </ng-container>
          <ng-container *ngIf="dataList.reportId?.promotional_call_used">
            <small class="col-md-12 text-muted mt-1">You have successfully availed your
              <strong>Free Complementary 5 Minute
                <span *ngIf="dataList.reportId?.is_call_used_in_promo">Call</span><span
                  *ngIf="dataList.reportId?.is_chat_used_in_promo">Chat</span>
              </strong></small>
          </ng-container>
        </div> -->

        <div class="row d-flex justify-content-between my-5">
          <div class="col">
            <button type="button" class="btn btn-block bg-secondary py-2 text-white fw-500 shadow-0 rounded"
              (click)="open(personDetail,'')">
              Person Details
            </button>
            <!-- Person Detail Section-->
            <ng-template #personDetail let-c="close" let-d="dismiss">
              <div class="modal-header pb-0">
                <h4 class="modal-title" id="modal-basic-title">Person Detail</h4>
                <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                    class="material-icons text-white">close</span></button>
              </div>
              <div class="modal-body">
                <table class="table table-bordered table-stripped">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{{ dataList.personId?.name }}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{{ dataList.personId?.gender }}</td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td>{{ dataList.personId?.mobile }}</td>
                    </tr>
                    <tr>
                      <td>Birth Details</td>
                      <td>
                        {{ dataList.personId?.dob }}
                        {{ dataList.personId?.tob }}
                      </td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>
                        {{ dataList.personId?.city }},
                        {{ dataList.personId?.state }},
                        {{ dataList.personId?.country }}
                      </td>
                    </tr>
                    <tr>
                      <td>Marital Status</td>
                      <td>{{ dataList.personId?.marital_status }}</td>
                    </tr>
                    <tr>
                      <td>Employed-In</td>
                      <td>{{ dataList.personId?.employed_in }}</td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="dataList.personId?.is_partner_details">
                    <tr>
                      <td>Partner Name</td>
                      <td>{{ dataList.personId?.partner_name }}</td>
                    </tr>
                    <tr>
                      <td>Partner Address</td>
                      <td>
                        {{ dataList.personId?.partner_city }},
                        {{ dataList.personId?.partner_state }},
                        {{ dataList.personId?.partner_country }}
                      </td>
                    </tr>
                    <tr>
                      <td>Partner Birth Details</td>
                      <td>
                        {{ dataList.personId?.partner_dob }}
                        {{ dataList.personId?.partner_tob }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>

          <div class="col">
            <div class="col-md-12 d-flex flex-column" *ngIf="!dataList.is_customer_issue_created">
              <button type="button" class="btn cus-border-active py-2 text-body fw-500 shadow-0"
                (click)="open(issue,'')">
                Need Help?
              </button>
              <!-- Add Issue Section-->
              <ng-template #issue let-c="close" let-d="dismiss">
                <div class="modal-header pb-0">
                  <h4 class="modal-title" id="modal-basic-title">Issue</h4>
                  <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                      class="material-icons text-white">close</span></button>
                </div>
                <div class="modal-body">
                  <form [formGroup]="issueForm" (submit)="addIssue()">
                    <div class="form-group">
                      <label for="issue_title">Issue Title</label>
                      <div class="input-group">
                        <input type="text" class="form-control" formControlName="issue_title" />
                      </div>
                      <div *ngIf="
                        i.issue_title.invalid &&
                        (i.issue_title.dirty || i.issue_title.touched)
                      " class="alert alert-danger mt-1">
                        Required.
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="issue_description">Description</label>
                      <div class="input-group">
                        <textarea class="form-control" formControlName="issue_description"></textarea>
                      </div>
                      <div *ngIf="
                        i.issue_description.invalid &&
                        (i.issue_description.dirty ||
                          i.issue_description.touched)
                      " class="alert alert-danger mt-1">
                        Required.
                      </div>
                    </div>
                    <button type="submit" class="
                      btn
                      bg-secondary
                      text-white
                      fw-500
                      shadow-0
                      p-0
                    " [disabled]="!issueForm.valid">
                      Save
                    </button>
                  </form>
                </div>
              </ng-template>
            </div>
            <div class="col-md-12 p-0" *ngIf="dataList.is_customer_issue_created">
              <button type="button" class="btn bg-secondary py-2 btn-block text-white" (click)="open(issueDetail,'')">
                Issue Details
              </button>
              <!-- Issue Detail Section-->
              <ng-template #issueDetail let-c="close" let-d="dismiss">
                <div class="modal-header pb-0">
                  <h4 class="modal-title" id="modal-basic-title">Issue Detail</h4>
                  <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                      class="material-icons text-white">close</span></button>
                </div>
                <div class="modal-body">
                  <table class="table table-bordered table-stripped">
                    <tbody>
                      <tr>
                        <td>Issue ID</td>
                        <td>#{{ dataList.customer_issueId?.issueId }}</td>
                      </tr>
                      <tr>
                        <td>Amount</td>
                        <td>
                          {{ dataList.reportId?.customerCurrencySymbol
                          }}{{ dataList.customer_issueId?.amount }}
                        </td>
                      </tr>
                      <tr>
                        <td>Issue Title</td>
                        <td>{{ dataList.customer_issueId?.issue_title }}</td>
                      </tr>
                      <tr>
                        <td>Issue Description</td>
                        <td>
                          {{ dataList.customer_issueId?.issue_description }}
                        </td>
                      </tr>
                      <tr *ngIf="dataList.customer_issueId?.is_reply_by_system">
                        <td>Reply</td>
                        <td>
                          {{ dataList.customer_issueId?.reply_by_system }}
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          <span *ngIf="dataList.customer_issueId?.is_issue_solved"
                            class="badge badge-pill bg-success">SOLVED</span>
                          <span *ngIf="!dataList.customer_issueId?.is_issue_solved"
                            class="badge badge-pill bg-danger">PENDING</span>
                        </td>
                      </tr>
                      <tr *ngIf="dataList.customer_issueId?.is_issue_refunded">
                        <td>Refund</td>
                        <td>
                          <span *ngIf="dataList.customer_issueId?.is_amount_refunded">
                            {{ dataList.reportId?.customerCurrencySymbol
                            }}{{ dataList.customer_issueId?.amount_refunded }}
                          </span>
                          <span *ngIf="dataList.customer_issueId?.is_promo_refunded">
                            condition pending
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"><button [routerLink]="['/issue',dataList?.customer_issueId?._id]"
                            class="w-25 mx-auto p-0 mt-0 btn-block btn bg-red text-white">View Reply</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col" *ngIf="dataList.is_remedy_suggested">
            <div class="col-md-12" *ngIf="dataList.is_remedy_suggested">
              <button type="button" class="btn btn-warning btn-block" [routerLink]="['/list-remedy',dataList?._id]"
                [attr.aria-expanded]="!isRemedyCollapsed" aria-controls="collapseExample">
                Remedy Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>