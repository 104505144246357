import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root',
})
export class OrderServicesService {
  BaseUrl: string = '';
  Token: any = '';
  header_object;
  constructor(
    private userdata: UserdataService,
    private http: HttpClient,
    @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base;
    this.Token = userdata.getToken();
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
  }

  /**
   * Astrologer List
   */
  getAstrologerList(data: any, endpoint: string) {
    switch (endpoint) {
      case 'call-astro-list':
      case 'promo-call-astro-list':
        return this.http.post(this.BaseUrl + '/allcallastrologer', data);
      case 'chat-astro-list':
      case 'promo-chat-astro-list':
        return this.http.post(this.BaseUrl + '/allchatastrologer', data);
      case 'query-astro-list':
        return this.http.post(this.BaseUrl + '/allqueryastrologer', data);
      case 'report-astro-list':
        return this.http.post(this.BaseUrl + '/allreportastrologer', data);
      default:
        return this.http.post(this.BaseUrl + '/allcallastrologer', data);
    }
  }
  // Place order
  placeOrder(data: any, endpoint: string) {
    switch (endpoint) {
      case 'promo-astro-list-chat':
      case 'promo-astro-list-call':
        return this.http.post(this.BaseUrl + '/availpromocall', data, {
          headers: this.header_object,
        });
      case 'promo-call-astro-list':
        return this.http.post(this.BaseUrl + '/makePromotionalCallv2', data, {
          headers: this.header_object,
        });
      case 'promo-chat-astro-list':
        return this.http.post(this.BaseUrl + '/createpromochatv2', data, {
          headers: this.header_object,
        });
      case 'call-astro-list':
        return this.http.post(this.BaseUrl + '/make-call', data, {
          headers: this.header_object,
        });
      case 'chat-astro-list':
        return this.http.post(this.BaseUrl + '/chat-order', data, {
          headers: this.header_object,
        });
      case 'query-astro-list':
        return this.http.post(this.BaseUrl + '/addorderquestion', data, {
          headers: this.header_object,
        });
      case 'report-astro-list':
        return this.http.post(this.BaseUrl + '/addorderreport', data, {
          headers: this.header_object,
        });
      default:
        return this.http.post(this.BaseUrl + '/make-call', data, {
          headers: this.header_object,
        });
    }
  }
  /**
   * Service Orders
   */
  public singleServiceOrders(data: any) {
    //console.log('data', data);
    return this.http.post(this.BaseUrl + '/singleserviceorder', data, {
      headers: this.header_object,
    });
  }

  /**
   * View Query Orders
   */
  public showQueryOrders(data: any) {
    return this.http.post(this.BaseUrl + '/myquestionorder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Query Orders
   */
  public placeQueryOrders(data: any) {
    return this.http.post(this.BaseUrl + '/addorderquestion', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Feedback to query
   */
  public feedbackQueryOrders(data: any) {
    return this.http.post(this.BaseUrl + '/givenfeedbackonquestion', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Review to query
   */
  public reviewQueryOrders(data: any) {
    return this.http.post(this.BaseUrl + '/startratequestion', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Report Orders
   */
  public placeReportOrders(data: any) {
    return this.http.post(this.BaseUrl + '/addorderreport', data, {
      headers: this.header_object,
    });
  }

  /**
   * Connect Report Promo Order
   */
  public promoReportOrders(data: any) {
    return this.http.post(this.BaseUrl + '/connectmypromocall', data, {
      headers: this.header_object,
    });
  }

  /**
   * View Report Orders
   */
  public showReportOrders(data: any) {
    return this.http.post(this.BaseUrl + '/myreportorder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Feedback to Report
   */
  public feedbackReportOrders(data: any) {
    return this.http.post(this.BaseUrl + '/givenfeedbackonreport', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Review to Report
   */
  public reviewReportOrders(data: any) {
    return this.http.post(this.BaseUrl + '/startratereport', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Call Order
   */
  public placeCallOrders(data: any) {
    return this.http.post(this.BaseUrl + '/make-call', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Call Promo
   */
  public placeCallPromoOrders(data: any) {
    return this.http.post(this.BaseUrl + '/make-promotional-call', data, {
      headers: this.header_object,
    });
  }

  /**
   * Send Person Mobile OTP
   */
  public sendPersonOtp(data: any) {
    return this.http.post(this.BaseUrl + '/otpsendprofile', data, {
      headers: this.header_object,
    });
  }

  /**
   * Verify Person Mobile OTP
   */
  public verifyPersonOtp(data: any) {
    return this.http.post(this.BaseUrl + '/verifyprofileotp', data, {
      headers: this.header_object,
    });
  }

  /**
   * View Call Orders
   */
  public showCallOrders(data: any) {
    return this.http.post(this.BaseUrl + '/mycallorder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Feedback to Call
   */
  public feedbackCallOrders(data: any) {
    return this.http.post(this.BaseUrl + '/feedbackcall', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Review to Call
   */
  public reviewCallOrders(data: any) {
    return this.http.post(this.BaseUrl + '/startratecall', data, {
      headers: this.header_object,
    });
  }

  /**
   * Add to waiting list
   */
  public placeWaitOrders(data: any) {
    return this.http.post(this.BaseUrl + '/addtowaitlist', data, {
      headers: this.header_object,
    });
  }

  /**
   * Show waiting list
   */
  public showWaitingList(data: any) {
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
    return this.http.post<any>(this.BaseUrl + '/mywaitinglist', data, {
      headers: this.header_object,
    });
  }

  /**
   * Remove waiting list
   */
  public removeWaitingList(data: any) {
    return this.http.post(this.BaseUrl + '/reviewfromwaitinglist', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Chat Orders
   */
  public placeChatOrders(data: any) {
    return this.http.post(this.BaseUrl + '/chat-order', data, {
      headers: this.header_object,
    });
  }

  /**
   * View Chat Orders
   */
  public showChatOrders(data: any) {
    return this.http.post(this.BaseUrl + '/mychatorder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Feedback to Chat
   */
  public feedbackChatOrders(data: any) {
    return this.http.post(this.BaseUrl + '/feedbackchat', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Review to Chat
   */
  public reviewChatOrders(data: any) {
    return this.http.post(this.BaseUrl + '/startratechat', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Chat Promo
   */
  public placeChatPromoOrders(data: any) {
    return this.http.post(this.BaseUrl + '/createpromochat', data, {
      headers: this.header_object,
    });
  }

  /**
   * Accept/Reject Chat Request
   */
  public acceptRejectChat(data: any) {
    return this.http.post(this.BaseUrl + '/acceptchatcustomer', data, {
      headers: this.header_object,
    });
  }

  /**
   * Save chat message
   */
  public saveChatMsg(data: any) {
    return this.http.post(this.BaseUrl + '/savechatmsgcustomer', data, {
      headers: this.header_object,
    });
  }

  /**
   * End chat
   */
  public endChat(data: any) {
    return this.http.post(this.BaseUrl + '/endchatcust', data, {
      headers: this.header_object,
    });
  }

  /**
   * Promo Orders Assigned By admin
   */
  getPromoOrderList(data: any) {
    //customerId,is_used
    return this.http.post(this.BaseUrl + '/mypromocalls', data, {
      headers: this.header_object,
    });
  }

  availPromoOrder(data: any) {
    return this.http.post(this.BaseUrl + '/availpromocall', data, {
      headers: this.header_object,
    });
  }

  /** if astrologer is offline then notify astrologer */
  notifyAstroOrder(data: any) {
    return this.http.post(this.BaseUrl + '/addmetonotifylist', data, {
      headers: this.header_object,
    });
  }

  /**
   * Store Order
   */

  /** List Store Order */
  showStoreOrders(data: any) {
    return this.http.post(this.BaseUrl + '/getMyOrders', data, {
      headers: this.header_object,
    });
  }

  /** Store Order Details */
  detailStoreOrders(data: any) {
    return this.http.post(this.BaseUrl + '/getOrderById', data, {
      headers: this.header_object,
    });
  }

  /**
   * Give Review to store order
   */
  public reviewStoreOrders(data: any) {
    return this.http.post(this.BaseUrl + '/addReview', data, {
      headers: this.header_object,
    });
  }

  /**
   * Cancel store order
   */
  public cancelStoreOrders(data: any) {
    return this.http.post(this.BaseUrl + '/cancelOrder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Group Chat
   */
  public listGroupChat(data: any) {
    //customerId
    return this.http.post(this.BaseUrl + '/customergroupchats', data, {
      headers: this.header_object,
    });
  }

  public detailGroupChat(data: any) {
    //_id
    return this.http.post(this.BaseUrl + '/singlegroupchat', data, {
      headers: this.header_object,
    });
  }

  public sendMsgGroupChat(data: any) {
    //chatId,message,name,is_to_show,type
    return this.http.post(this.BaseUrl + '/sendmsgingroup', data, {
      headers: this.header_object,
    });
  }

  /** Get My Cart */
  public getMyCart(data: any) {
    //customerId
    return this.http.post(this.BaseUrl + '/getMyCart', data, {
      headers: this.header_object,
    });
  }

  /** Remove Product From cart */
  public removeProductFromCart(data: any) {
    //customerId,orderProductId
    return this.http.post(this.BaseUrl + '/removeFromCart', data, {
      headers: this.header_object,
    });
  }

  /** Increase Decrease Quanity of Product From cart */
  public changeProductQtyInCart(data: any) {
    //customerId,orderProductId,isIncrease
    return this.http.post(this.BaseUrl + '/changeCartItemQuantity', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Order From Cart
   */
   public placeCartOrder(data: any) {
    return this.http.post(this.BaseUrl + '/placeOrderFromCart', data, {
      headers: this.header_object,
    });
  }

  /**
   * Change Address In Cart
   */
   public changeAddressInCart(data: any) {
    return this.http.post(this.BaseUrl + '/changeCartAddress', data, {
      headers: this.header_object,
    });
  }

  /**
   * Apply Coupon On Cart
   */
   public applyCouponOnCart(data: any) {
    return this.http.post(this.BaseUrl + '/applyCouponOnCart', data, {
      headers: this.header_object,
    });
  }

  /**
   * Remove Coupon From Cart
   */
   public removeCouponFromCart(data: any) {
    return this.http.post(this.BaseUrl + '/removeCouponFromCart', data, {
      headers: this.header_object,
    });
  }

}
