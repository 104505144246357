<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Store Order</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li><a>Store Order</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="my-lg-5 my-3">
  <div class="container">
    <div class="row gx-2">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="">
          <!-- <div class="row">
              <h3 class="fw-500 text-capitalize">Store Orders</h3>
            </div> -->
          <div class="row ">
            <ngx-datatable class="material cust-table shadow-0 rounded border" [rows]="dataList" [columnMode]="'force'"
              [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
              [sorts]="[{ dir: 'desc' }]">
              <ngx-datatable-column [flexGrow]="1" name="Order ID" prop="orderId">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a [routerLink]="['/store-order-list/', row._id]">{{
                    row.orderId
                    }}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="3" name="Product" prop="productId.productname">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span [title]="row.productId?.productname">{{
                    row.productId?.productname
                    }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="1" name="Amount" prop="grandTotal">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{
                  row.grandTotal
                  | currency: (row.isForeignOrder ? "USD" : "INR"):"symbol-narrow"
                  }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="2" name="Status" prop="order_current_status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="badge bdg-txt-fw bg-primary p-2" *ngIf="row.orderStatus == 1">Placed</span>
                  <span class="badge bdg-txt-fw bg-warning p-2" *ngIf="row.orderStatus == 2">Confirmed</span>
                  <span class="badge bdg-txt-fw bg-info p-2" *ngIf="row.orderStatus == 3">Shipped</span>
                  <span class="badge bdg-txt-fw bg-warning p-2" *ngIf="row.orderStatus == 4">Out For Delivery</span>
                  <span class="badge bdg-txt-fw bg-success p-2" *ngIf="row.orderStatus == 5">Delivered</span>
                  <span class="badge bdg-txt-fw bg-primary mr-1 p-2" *ngIf="row.orderStatus == 6">Cancelled</span>
                  <span
                    class="badge bdg-txt-fw bg-warning py-2 mt-1" *ngIf="row.orderStatus == 6 && row.refundStatus == 0">Not
                    Refunded</span>
                    <span class="badge bdg-txt-fw bg-info p-2 mt-1"
                    *ngIf="row.orderStatus == 6 && row.refundStatus == 1">Refund Requested</span>
                    <span
                    class="badge bdg-txt-fw bg-success p-2 mt-1"
                    *ngIf="row.orderStatus == 6 && row.refundStatus == 2">Refund
                    Approved</span>
                    <span class="badge bg-primary p-2 mt-1"
                    *ngIf="row.orderStatus == 6 && row.refundStatus == 3">Refund Rejected</span>
                    <span class="badge bg-success p-2 mt-1"
                    *ngIf="row.orderStatus == 6 && row.refundStatus == 4">Refund Complete</span>
                  <span class="badge bdg-txt-fw bg-success p-2"
                    *ngIf="row.orderStatus == 7 || row.orderStatus == 8">Complete</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="2" name="Date" prop="createdAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.createdAt | date: "medium" }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="1" name="Action" prop="group_chat_id">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button *ngIf="row.group_chat_id" type="button"
                    class="btn btn-sm shadow-0 bg-green px-2 py-1 rounded text-white"
                    [routerLink]="['/order-chat', row.group_chat_id]">
                    Chat
                  </button>
                  <button type="button"
                    class="btn btn-sm shadow-0 bg-secondary px-2 py-1 mt-1 rounded text-white"
                    [routerLink]="['/store-order-list', row._id]">
                    Detail
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
<app-footer></app-footer>