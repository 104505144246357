import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { UserdataService } from '../userdata/userdata.service';
import { mergeMapTo, mergeMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  token: any = '';

  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private userdata: UserdataService
  ) {}
  requestPermission() {
    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .subscribe(
        (token: any) => {
          this.userdata.setFcm(token);
        },
        (error: any) => {
          //console.log(error);
        }
      );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      //console.log(payload)
      this.currentMessage.next(payload);
      const NotificationOptions = {
        body: payload.notification.body,
        data: payload.data,
        icon: payload.notification.icon
      }
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration:any) => {
        registration.showNotification(payload.notification.title, NotificationOptions);
      });
    });
  }
  deleteToken() {
    this.angularFireMessaging.getToken
      .pipe(
        mergeMap((token: any) => this.angularFireMessaging.deleteToken(token))
      )
      .subscribe((token) => {
        //console.log('Token deleted!');
      });
  }
}
