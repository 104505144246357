import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root',
})
export class SideNavServiceService {
  private appComponent: any;
  private isOpen = false;
  public setInit(appComponent: AppComponent) {
    this.appComponent = appComponent;
  }
  public toggleSideBar() {
    this.isOpen = !this.isOpen;
    this.appComponent.toggleView = this.isOpen;
  }
  public isToggle() {
    return this.isOpen;
  }
}
