import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-query-order-list',
  templateUrl: './query-order-list.component.html',
  styleUrls: ['./query-order-list.component.css']
})
export class QueryOrderListComponent implements OnInit {

  dataList:any = []
  filterType = 'all'
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  constructor(
    private userdata: UserdataService,
    private orderService: OrderServicesService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) { }

  isCallGoing:boolean=false
  isChatGoing:boolean=false
  ngOnInit(): void {
    this.getQueryOrders()
    // this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
    //   (res: any) => {
    //     // console.log('myprofile', res)
    //     this.isCallGoing = res.data.is_current_call
    //     this.isChatGoing = res.data.is_current_chat
    //     this.spinner.hide()
    //   }, err=> {
    //     this.toast.error(err.error.message, 'Alert');
    //   })
  }

  getQueryOrders(){
    this.filterType = 'all'
    this.spinner.show()
    this.orderService.showQueryOrders({customerId:this.userdata.getCustomerId()}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  pendingQuery(){
    this.filterType = 'answer'
    this.spinner.show()
    this.orderService.showQueryOrders({customerId:this.userdata.getCustomerId(),is_astro_answer:false}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

  noReview(){
    this.filterType = 'review'
    this.spinner.show()
    this.orderService.showQueryOrders({customerId:this.userdata.getCustomerId(),is_order_reviewed:false,is_astro_answer:true}).subscribe(
      (res:any)=>{
        this.spinner.hide()
        this.dataList = res.data
      },err=>{
        this.spinner.hide()
      }
    )
  }

  noFeedback(){
    this.filterType = 'feedback'
    this.spinner.show()
    this.orderService.showQueryOrders({customerId:this.userdata.getCustomerId(),is_astro_answer:true,is_feedback_given:false}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

}
