<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
      <div class="container d-flex">
        <h3 class="fw-600 fn-3 text-white w-100">Contact us</h3>
        <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
          <li>
            <a [routerLink]="['/']" class="text-white">Home</a>
          </li>
          <li>
            <span class="fn-2 material-icons-round bottom-align">
              arrow_forward_ios
            </span>
          </li>
  
          <li>Contact us</li>
        </ul>
      </div>
    </div>
  </section>
  <!-- BradCrumbs End -->
  <!-- Main Banner Start -->
  <section class="ng-gradient-grey">
    <div class="container">
      <div class="row">
        <div class="col-md-6 py-5 nor-text order-1">
          <h4 class="fw-500 fn-4 text-uppercase font-anton">contact us</h4>
          <p class="my-2">
            AstroRiver provides excellent customer service that is sure to satisfy your needs. Our highly skilled and
            experienced team is always ready to help you with any questions you may have. Contact us today to learn more!
          </p>
          <a (click)="scroll(target)" href="javascript:void(0)" type="button"
            class="m-0 btn bg-green py-2 px-3 text-white text-uppercase">
            message us
          </a>
        </div>
        <div class="col-md-6 order-md-2">
          <img src="assets/images/contact-us/contact-illustration.png" class="w-100 h-100" />
        </div>
      </div>
    </div>
  </section>
  <!-- Main Banner End -->
  
  <!-- Get in touch Start -->
  <section class="my-5">
    <div class="container mb-90">
      <div class="row">
        <div class="col-12 text-center">
          <h4 class="font-anton text-capitalize hed-mob">
            questions or comments?<br /> <span class="active-text">get in touch</span>
          </h4>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="
              rounded-circle
              size-60
              justify-content-center
              align-items-center
              d-flex
              g-i-t
              position-absolute
              bg-secondary
            ">
            <span class="material-icons-round text-white fn-4">support_agent</span>
          </div>
          <div class="col-md-12 rounded p-0">
            <div class="p-n2 h-100 w-100 position-absolute blur-shadow"></div>
            <div class="bg-active col-12 p-2 rounded">
              <div class="
                  rounded
                  p-3
                  align-items-center
                  card
                  d-flex
                  flex-column
                  border-0
                  bg-secondary-light
                ">
                <h5 class="fw-500 text-capitalize active-text">get support</h5>
                <p class="fn-1 text-center my-2">
                  We operate completely over the web. But then, sharing a cup of
                  coffee needs more than that.
                </p>
                <button class="
                    w-100
                    text-lowercase
                    rounded
                    py-2
                    btn
                    fw-500
                    bg-active
                    active-text
                    m-0
                  ">
                  www.astroriver.com
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-md-4 my-mob">
          <div class="
              rounded-circle
              size-60
              justify-content-center
              align-items-center
              d-flex
              bg-green
              g-i-t
              position-absolute
            ">
            <span class="text-white material-icons-round fn-4">phone_in_talk</span>
          </div>
          <div class="col-12 rounded p-0">
            <div class="p-n2 h-100 w-100 position-absolute blur-shadow"></div>
            <div class="bg-active col-12 p-2 rounded">
              <div class="
                  rounded
                  p-3
                  align-items-center
                  card
                  d-flex
                  flex-column
                  border-0
                  bg-green-light
                ">
                <h5 class="fw-500 text-capitalize green-text">call us</h5>
                <p class="fn-1 text-center my-2">
                  We are available Monday to Saturday from 09:30 AM to 06:00 PM. Let's have <br>a talk!
                </p>
                <a href="tel:917009127641" class="
                    w-100
                    text-lowercase
                    rounded
                    py-2
                    btn
                    fw-500
                    bg-active
                    green-text
                    m-0
                  ">
                  +91 70091-27641
                </a>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-md-4">
          <div class="
              rounded-circle
              size-60
              justify-content-center
              align-items-center
              d-flex
              bg-orange
              g-i-t
              position-absolute
            ">
            <span class="material-icons-round text-white fn-4">mail_outline</span>
          </div>
          <div class="col-12 rounded p-0">
            <div class="p-n2 h-100 w-100 position-absolute blur-shadow"></div>
            <div class="bg-active col-12 p-2 rounded">
              <div class="
                  rounded
                  p-3
                  align-items-center
                  card
                  d-flex
                  flex-column
                  border-0
                  bg-orange-light
                ">
                <h5 class="fw-500 text-capitalize orange-text">get support</h5>
                <p class="fn-1 text-center my-2">
                  Please get in touch with us at our official email for any official support or <br>inquiries.
                </p>
                <a href="mailto:info@astroriver.com" class="
                    w-100
                    text-lowercase
                    rounded
                    py-2
                    fw-500
                    btn
                    bg-active
                    orange-text
                    m-0
                  ">
                  info@astroriver.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Get in touch End -->
  
  <!-- stay connected start -->
  <section class="">
    <div class="container">
      <div class="row m-0">
        <div class="col-md-6">
          <div class="w-100 h-100 rounded position-absolute connect-bg"></div>
          <div class="col-12 p-0 position-relative">
            <div class="w-100 h-100 position-absolute blur-shadow"></div>
            <div class="border rounded col-12 p-1 bg-active">
              <img src="assets/images/contact-us/connect-stay.png" class="w-100 bg-inactive rounded p-5" />
            </div>
          </div>
        </div>
        <div class="col-md-6 mt25-mob">
          <h4 class="font-anton text-capitalize stay-con mb-3">stay connected</h4>
          <div class="row pb-4">
            <div class="col-4 cursor-pointer" (click)="openSocialLink('facebook')">
              <div class="
                  col-12
                  py-2
                  socl-icon
                  border
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <i class="fn-4 fi fi-facebook fb-text"></i>
              </div>
            </div>
            <div class="col-4 cursor-pointer" (click)="openSocialLink('linkedin')">
              <div class="
                  col-12
                  py-2
                  socl-icon
                  border
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <i class="fn-4 fi fi-linkedin wa-text"></i>
              </div>
            </div>
            <div class="col-4 cursor-pointer" (click)="openSocialLink('instagram')">
              <div class="
                  col-12
                  py-2
                  socl-icon
                  border
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <i class="fn-4 fi fi-instagram insta-text"></i>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4 cursor-pointer" (click)="openSocialLink('quora')">
              <div class="
                  col-12
                  py-2
                  socl-icon
                  border
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <i class="fn-4 fi fi-quora quora-text"></i>
              </div>
            </div>
            <div class="col-4 cursor-pointer" (click)="openSocialLink('pinterest')">
              <div class="
                  col-12
                  py-2
                  socl-icon
                  border
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <i class="fn-4 fi fi-pinterest pin-text"></i>
              </div>
            </div>
            <div class="col-4 cursor-pointer" (click)="openSocialLink('twitter')">
              <div class="
                  col-12
                  py-2
                  socl-icon
                  border
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <i class="fn-4 fi fi-twitter tweet-text"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- stay connected end -->
  
  <section class="my-5">
    <div class="container">
      <div class="row rounded border bg-inactive p-3 position-relative">
        <div class="position-absolute top-0 start-0 end-0 mt-3" style="z-index: 100">
          <div class="d-flex justify-content-center">
            <h4 class="
                border border-white
                px-5
                text-center
                py-2
                font-anton
                fn-3
                text-capitalize
                bg-secondary-2
                rounded
              ">
              need help for order?
            </h4>
          </div>
        </div>
        <div class="col-12 mt-3 rounded bg-yellow-light pt-5 pb-3">
          <div class="row">
            <div class="col-md mb-2 d-flex">
              <a href="https://support.astroriver.com/ " class="
                  fw-600
                  flex-grow-1
                  btn
                  py-1
                  fn-2
                  rounded
                  bg-active
                  cus-border-yellow
                  text-capitalize
                ">
                Track your order
              </a>
            </div>
            <div class="col-md mb-2 d-flex">
              <a href="https://support.astroriver.com" class="
                  fw-600
                  flex-grow-1
                  btn
                  py-1
                  fn-2
                  rounded
                  bg-active
                  cus-border-yellow
                  text-capitalize
                ">
                Shipping
              </a>
            </div>
            <div class="col-md mb-2 d-flex">
              <a href="https://support.astroriver.com/ " class="
                  fw-600
                  flex-grow-1
                  btn
                  py-1
                  fn-2
                  rounded
                  bg-active
                  cus-border-yellow
                  text-capitalize
                ">
                Returns
              </a>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-2 mb-2 d-flex dis-mob-n">&nbsp;</div>
            <div class="col-md mb-2 d-flex">
              <a [routerLink]="['faq']" class="
                  fw-600
                  flex-grow-1
                  btn
                  py-1
                  fn-2
                  rounded
                  bg-active
                  cus-border-yellow
                  text-capitalize
                ">
                Product FAQs
              </a>
            </div>
            <div class="col-md mb-2 d-flex">
              <a href="https://support.astroriver.com/" class="
                  fw-600
                  flex-grow-1
                  btn
                  py-1
                  fn-2
                  rounded
                  bg-active
                  cus-border-yellow
                  text-capitalize
                ">
                Visit Online Support Cenrter
              </a>
            </div>
            <div class="col-md-2 mb-2 d-flex dis-mob-n">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div class="row m-0 mob-pad">
        <div class="col-md-6 p-0">
          <div class="col-12 call-card position-relative press">
            <div class="mask"></div>
            <img src="assets/images/contact-us/inquery-press.jpg" class="w-100 rounded" />
            <div class="
                d-flex
                flex-column
                position-absolute
                justify-content-center
                align-items-center
                start-0
                end-0
                top-0
                bottom-0
                font-mob
              ">
              <h4 class="text-center fw-600 text-uppercase text-white">
                for<br />press inquiries
              </h4>
              <button class="btn btn-block py-2 mt-2 fw-600 bg-secondary-2">
                Click Here
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <div class="p-2 col-12 chat-card position-relative inquiry">
            <div class="mask"></div>
            <img src="assets/images/contact-us/img-inquery.jpg" class="w-100 rounded" />
            <div class="
                d-flex
                flex-column
                position-absolute
                justify-content-center
                align-items-center
                start-0
                end-0
                top-0
                bottom-0
                font-mob
              ">
              <h4 class="fw-600 text-uppercase text-white py-2">for inquiries</h4>
              <h4 class="fw-600 text-uppercase secondary-text">
                influencers/collaborations
              </h4>
              <button class="btn btn-block py-2 mt-2 fw-600 bg-secondary-2">
                Click Here
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- form start -->
  <section class="main-form cont-frm" #target>
    <div class="container">
      <div class="row bg-inactive border rounded">
        <div class="col-md-6 d-flex flex-column cus-form">
          <h4 class="active-text fw-500 text-capitalize">write us</h4>
          <h6 class="fw-600 py-2">
            We are just a click away. Get in touch with us anytime by using the form on the right.
          </h6>
          <span></span>
          <p class="fn-1">
            Please feel free to send us any official inquiries, feedback, suggestions or anything else using this form. We
            will get back to you within 48 hours. Need quick support? Use the support portal for that.
          </p>
        </div>
        <div class="col-md-6 over-frm" id="contactus">
          <form [formGroup]="contactForm" class="border bg-active rounded row px-3 py-4" (ngSubmit)="submit()">
            <div class="col-12 mb-3">
              <h4 class="fw-600 text-center text-capitalize">get in touch</h4>
            </div>
            <div class="col-6">
              <div class="form-outline">
                <input placeholder="Name*" type="text" formControlName="name" class="form-control form-control-sm" />
                <small class="active-text" *ngIf="
                contactForm.controls.name.invalid &&
                (contactForm.controls.name.touched || contactForm.controls.name.dirty)
              ">Name is required.</small>
              </div>
            </div>
            <div class="col-6">
              <div class="form-outline">
                <input placeholder="Email*" type="email" formControlName="email" class="form-control form-control-sm" />
                <small class="active-text" *ngIf="
                contactForm.controls.email.invalid &&
                (contactForm.controls.email.touched || contactForm.controls.email.dirty)
              ">Valid Email is required.</small>
              </div>
            </div>
            <div class="col-12 my-3">
              <div class="form-outline">
                <input placeholder="+91 | Enter phone number*" type="text" formControlName="phone"
                  class="form-control form-control-sm" />
                <small class="active-text" *ngIf="
                contactForm.controls.phone.invalid &&
                (contactForm.controls.phone.touched || contactForm.controls.phone.dirty)
              ">Valid Phone number with country code is required.</small>
              </div>
            </div>
            <div class="col-12">
              <div class="form-outline">
                <input placeholder="Subject*" type="text" formControlName="subject"
                  class="form-control form-control-sm" />
                <small class="active-text" *ngIf="
                contactForm.controls.subject.invalid &&
                (contactForm.controls.subject.touched || contactForm.controls.subject.dirty)
              ">Subject is required.</small>
              </div>
            </div>
            <div class="col-12 my-3">
              <div class="form-outline">
                <textarea placeholder="Message*" type="text" formControlName="message"
                  class="form-control form-control-sm"></textarea>
                <small class="active-text" *ngIf="
                contactForm.controls.message.invalid &&
                (contactForm.controls.message.touched || contactForm.controls.message.dirty)
              ">Message is required.</small>
              </div>
            </div>
            <div class="col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="terms" />
                <label class="form-check-label inactive-text fn-0" for="flexCheckDefault">
                  ‘I hereby give permission to be contacted by AstroRiver representatives via email/call/sms/social media
                  even if my number is in the DND list’
                </label>
              </div>
            </div>
            <div class="col-12 text-center mt-3">
              <button [disabled]="contactForm.invalid" type="submit"
                class="px-5 text-uppercase btn bg-green py-2 rounded text-white">send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- form end -->