<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- hs breadcrumb Start -->
<div class="hs_indx_title_main_wrapper">
    <div class="hs_title_img_overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
                <div class="hs_indx_title_left_wrapper">
                    <h2>Kundli</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
                <div class="hs_indx_title_right_wrapper text-capitalize">
                    <ul>
                        <li><a [routerLink]="['/home']">Home</a> &nbsp;&nbsp;&nbsp;&gt; </li>
                        <li>Kundli</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /hs breadcrumb -->
<!-- hs about ind wrapper Start -->
<div class="hs_about_indx_main_wrapper hs_about_indx_inner_main_wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3>MahaDasha</h3>
                <table class="table table-bordered">
                    <tr>
                        <td *ngFor="let maha of dataList.mahadasha.mahadasha">{{maha}}</td>
                    </tr>
                    <tr>
                        <td *ngFor="let maha of dataList.mahadasha.mahadasha_order">{{maha}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-12">
                <h3>Antardasha</h3>
                <div class="row">
                    <table class="table table-sm table-bordered col-6">
                        <ng-container *ngFor="let antardasha of dataList.antardasha.antardashas">
                            <tr *ngFor="let antar of antardasha">
                                <td>{{antar}}</td>
                            </tr>
                        </ng-container>
                    </table>
                    <table class="table table-sm table-bordered col-6">
                        <ng-container *ngFor="let antardasha of dataList.antardasha.antardasha_order">
                            <tr *ngFor="let antar of antardasha">
                                <td>{{antar}}</td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="col-12">
                <h3>Basic</h3>
                <small style="word-wrap: break-word;" >{{show(dataList.basic)}}</small>
            </div>
        </div>
    </div>
</div>
<!-- hs about ind wrapper End -->
<app-footer></app-footer>