import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  constructor(
    private userdata: UserdataService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    this.getNotifications();
  }
  notificationList = new Array();
  getNotifications() {
    this.userdata
      .getNotifications({ customerId: this.userdata.getCustomerId() })
      .subscribe({
        next: (v) => {
          this.notificationList = v.data;
        },
        error: (error) => {
          //console.log(error);
        },
      });
  }
  openDialog(id: string) {
    //console.log(id);
  }
}
