import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-report-dailog',
  templateUrl: './report-dailog.component.html',
  styleUrls: ['./report-dailog.component.css'],
})
export class ReportDailogComponent implements OnInit {
  constructor(
    private openService: OpenApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReportDailogComponent>,
    private router: Router,
    private _toaster: ToastrService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  BaseUrl = '';
  reports = new Array();
  selectedId = '';
  ngOnInit(): void {
    this.getReports();
  }
  getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  private getReports() {
    this.openService.showReportList({ status: true }).subscribe({
      next: (v: any) => {
        this.reports = v.data;
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }
  onReportSelect(report: any) {
    if (this.data != report._id) {
      this.data = report._id
      sessionStorage.setItem('bookReport',this.data)
    }
    else {
      this.data = '';
    }
  }
  closeDialog() {
    if (
      this.data != null &&
      typeof this.data != 'undefined' &&
      this.data != ''
    ) {
      this.dialogRef.close();
    } else {
      this._toaster.error('Please select a report first');
    }
  }

  goBack() {
    this.dialogRef.close();
    this.router.navigateByUrl('/report-astro-list')
  }
}
