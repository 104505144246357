<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Order Product</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li>Order Product</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!--Content Start-->
<section class="container">
  <form [formGroup]="saveOrderForm" (submit)="saveOrder()">
    <div class="py-5">
      <div class=" mb-3">
        <div class="">

          <div class=" mb-2">
            <h5 class="font-weight-semibold text-muted">Product Details</h5>
          </div>
          <div class="order-bg rounded">
            <div class="card-body ">
              <div class="row">
                <div class="col-md-2">
                  <div class="p-0">
                    <img [src]="getSanitizeUrl(dataList?.product_image?.[0])" class="w-100 rounded" />
                  </div>
                </div>
                <div class="col-md-10 p-detail-mob">
                  <h5 class="text-truncate">{{dataList.productname}}</h5>

                  <p class=" mb-2 text-justify small text-muted" [innerHTML]="dataList.productdescription"></p>
                  <div class="d-flex flex-row align-items-center mb-1" >
                    <ng-container *ngIf="dataList.is_offer_applied; else offerNotApplied">
                      <h4 class="mb-1 me-1 font-weight-bold">{{getCurrentPrice(dataList) | currency: (code ? "INR" :
                        "USD"):"symbol-narrow"
                        }}</h4>
                      <span class="text-danger ml-2"><s>{{getOrginialPrice(dataList) | currency: (code ? "INR" :
                          "USD"):"symbol-narrow"
                          }}</s></span>
                    </ng-container>
                    <ng-template #offerNotApplied>
                      <h4 class="mb-1 me-1 font-weight-bold">{{getCurrentPrice(dataList) | currency: (code ? "INR" :
                        "USD"):"symbol-narrow"
                        }}</h4>
                    </ng-template>
                  </div>
                  <div class="d-flex flex-column" *ngIf="isQuantityOn">
                    <strong class="font-weight-semibold">Quantity: </strong>

                    <div class="qty mt-2">
                      <span (click)="incDecQuantity('minus')" class="minus bg-dark">-</span>
                      <input type="number" class="count " name="qty" formControlName="quantity">
                      <span (click)="incDecQuantity('add')" class="plus bg-dark">+</span>
                    </div>
                    <!-- <button class="btn btn-warning  btn-sm" type="button">Details</button>
                    <button class="btn btn-danger btn-sm mt-2" type="button">
                      Add to Cart
                    </button> -->
                  </div>
                </div>
                <!-- <div class="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">



                </div> -->
              </div>
            </div>
          </div>
          <!--Add On-->
          <ng-container *ngIf="dataList?.is_addon_associate">
            <div class="mb-2 mt-3">
              <h5 class="font-weight-semibold text-muted">Select Add-ons</h5>
            </div>
            <div class="d-flex">
              <div *ngFor="let item of addonarray; let i = index" class="col-md-4  d-flex order-bg rounded p-0 ml-2">
                <div class="col-md-3 p-2">
                  <img [src]="getSanitizeUrl(item.addon_image)" class="w-100 ps-0" />
                </div>
                <div class="col p-2">
                  <h6 class=" mb-0">{{ item.addon_name }}</h6>
                  <span>
                    <span *ngIf="code; else usd">{{
                      item.addon_price | currency: "INR":"symbol-narrow"
                      }}</span>

                    <ng-template #usd>
                      <span>{{
                        item.addon_usd_price
                        | currency: "USD":"symbol-narrow"
                        }}</span>
                    </ng-template>
                  </span>
                  <div class="mt-1" *ngIf="item.addon_type == 1">
                    <p class="">
                      <input type="text" class="form-control py-1" placeholder="Enter ring size" [id]="'ring' + i">
                    </p>
                  </div>
                  <div class="mt-2" *ngIf="item.addon_type == 1">
                    <span class="text-muted ps-0 small"><input type="checkbox" class="mr-1" [id]="'nosize' + i"
                        (change)="ringSizeChange($event,i,item)" />Don't know ring size.</span>
                  </div>
                  <div class="mt-2" *ngIf="item.addon_type == 1">
                    <span class="text-muted ps-0 small">Ring Size is Required !</span>
                    <div class="alert border-danger text-danger small col mt-1  py-1"><span>Note: Final Amount
                        may
                        vary.</span>
                    </div>
                  </div>
                </div>
                <div class="form-check  col-md-1  d-flex justify-content-center p-2">
                  <input class="form-check-input p-2" type="checkbox" [id]="'selectAddon' + i" value="{{ i }}"
                    (change)="getaddon(item, i,$event)">
                </div>

              </div>
            </div>


          </ng-container>


        </div>
      </div>

      <!-- Experts Details Start Here -->

      <div class=" mb-2 ">
        <h5 class="font-weight-semibold text-muted">Expert Details</h5>
      </div>
      <div class="rounded order-bg">
        <div class=" card-body py-3">
          <div class="d-flex justify-content-evently exp-detail-mob">
            <!--Refered By-->
            <div class="form-group col-md-4">
              <label for="inputPassword4">Referred By</label>
              <ng-select [items]="consultantarray" placeholder="Select Consultant" formControlName="consultantId"
                bindLabel="_id.name" bindValue="_id._id">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="card c-style-drop">
                    <div class="card-body">
                      <p class="card-text d-flex align-items-center">
                        <span>
                          <img height="50" width="50" class="border rounded"
                            [src]="getSanitizeUrl(item._id.profile_image)" />
                        </span>
                        <span class="ml-2 align-middle"> {{ item._id.name }}</span>
                      </p>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <!--Choose Astrologer-->
            <div class="form-group col-md-4" *ngIf="isAstrologerAssociate">
              <label for="astroId">Select Astrologer</label>
              <ng-select [items]="dataList.astrologerList" placeholder="Select Astrologer" formControlName="astroId"
                bindLabel="astroId.name" bindValue="astroId._id">
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="card c-style-drop">
                    <div class="card-body">
                      <h5 class="card-title">{{ item.astroId.displayname }}</h5>
                      <p class="card-text d-flex">
                        <span>
                          <img height="50" width="50" class="border rounded"
                            [src]="getSanitizeUrl(item.astroId.profile_image)" />
                          <br />
                          <ng-template #t let-fill="fill">
                            <span class="star" [class.full]="fill === 100">
                              <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                            </span>
                          </ng-template>
                          <ngb-rating [(rate)]="item.astroId.astrologer_rating" [starTemplate]="t" [readonly]="true"
                            [max]="5"></ngb-rating>
                        </span>
                        <span>{{ item.astroId.name }}<br />
                          <p *ngIf="code; else usd">
                            {{ item.price | currency: "INR":"symbol-narrow" }}
                          </p>
                          <ng-template #usd>
                            {{ item.usd_price | currency: "USD":"symbol-narrow" }}
                          </ng-template>
                        </span>
                      </p>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <!--Select Address-->
            <div class="form-group col-md-4" *ngIf="isAddressOn && !isAddressExist">
              <label for="inputPassword4">Address</label>
              <ng-template #address let-modal>
                <div class="modal-header my-add-design border-0">
                  <h5 class="modal-title text-white text-capitalize" id="modal-basic-title pt-2 mb-0">
                    My Address
                  </h5>
                  <button type="button" class="close text-white " aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true" class="h2">&times;</span>
                  </button>
                </div>
                <div class="row modal-body m-0 pt-0 justify-content-end order-bg">
                  <ng-container *ngIf=" isSelectAddress">
                    <button type="button" (click)="showAddNewAddressForm()" class="btn btn-primary col-md-3 mb-2 pt-1">
                      Add Address
                    </button>
                    <div class="card border-0 shadow col-12  mt-2 mb-3" *ngFor="let address of addressList">
                      <div class="card-body">
                        <h5 class="card-title mb-1">{{ address.name }}</h5>
                        <p class="card-text mb-1">
                          <ng-container>Address. </ng-container>{{ address.flatNo }}, {{ address.locality }},
                          {{ address.city }}, {{ address.state }},
                          {{ address.country }}, {{ address.pinCode }}
                        </p>
                        <h6 class="card-subtitle mb-2 text-muted">
                          <ng-container>Contact No. </ng-container>
                          +{{ address.countryCodePhoneNum }}-{{
                          address.phoneNum
                          }}
                        </h6>
                        <a href="javascript:void(0)" class="card-link btn btn-outline-success py-1"
                          (click)="selectAddress(address)">Select</a>
                        <a href="javascript:void(0)" class="card-link btn btn-outline-danger py-1"
                          (click)="deleteAddress(address._id)">Delete</a>
                        <a href="javascript:void(0)" class="card-link btn btn-outline-info py-1"
                          (click)="showUpdateAddressForm(address)">Update</a>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isAddNewAddress">
                    <form class="col-md-12 mt-4" [formGroup]="addAddressForm" (submit)="saveAddress()">
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <label for="name" class="m-lable-size">Name*</label>
                          <input type="text" class="form-control m-lable-size"
                            placeholder="Example: Home, Office, Work etc." formControlName="name" />
                          <div *ngIf="
                              a.name.invalid && (a.name.touched || a.name.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Name is required. Please Enter a Valid
                              Name</small>
                          </div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="flatNo" class="m-lable-size">Flat/House Number*</label>
                          <input type="text" class="form-control m-lable-size"
                            placeholder="Enter Your Flat or House Number" formControlName="flatNo" />
                          <div *ngIf="
                              a.flatNo.invalid &&
                              (a.flatNo.touched || a.flatNo.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Flat No is Required</small>
                          </div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="locality" class="m-lable-size">Locality*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your Locality"
                            formControlName="locality" />
                          <div *ngIf="
                              a.locality.invalid &&
                              (a.locality.touched || a.locality.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Locality is Required</small>
                          </div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="landmark" class="m-lable-size">LandMark</label>
                          <input type="text" class="form-control m-lable-size"
                            placeholder="Enter Your landmark (Optional)" formControlName="landmark" />
                          <div *ngIf="
                              a.landmark.invalid &&
                              (a.landmark.touched || a.landmark.dirty)
                            ">
                            <small class="error-msg text-danger font-10">LandMark is Required</small>
                          </div>
                        </div>
                      </div>
                      <div class="row ">
                        <div class="col-md-12 form-group">
                          <label for="name" class="m-lable-size">Contact Number*</label>
                          <div class="d-flex">
                            <!-- <select name="countryCode" class="col-md-3 form-control m-lable-size"
                              formControlName="countryCodePhoneNum">
                              <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                                {{ country.emoji }}&nbsp;&nbsp;{{
                                country.name
                                }}&nbsp;&nbsp;(+{{ country.phone_code }})
                              </option>
                            </select> -->
                            <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
                              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                              bindLabel="phone_code" bindValue="phone_code">
                              <ng-template ng-option-tmp let-item="item">
                                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                item.name
                                }}&nbsp;&nbsp;(+{{ item.phone_code }})
                              </ng-template>
                            </ng-select>
                            <input type="text" class="form-control col-md-9 m-lable-size"
                              placeholder="Enter Contact Number" formControlName="phoneNum" />
                          </div>
                          <div *ngIf="
                              (a.countryCodePhoneNum.invalid &&
                                (a.countryCodePhoneNum.touched ||
                                  a.countryCodePhoneNum.dirty)) ||
                              (a.phoneNum.invalid &&
                                (a.phoneNum.touched || a.phoneNum.dirty))
                            ">
                            <small class="error-msg text-danger font-10">Contact Number is required. Please Enter a
                              Valid
                              Contact Number.</small>
                          </div>
                        </div>
                        <div class="col-md-12 form-group ">
                          <label for="name" class="m-lable-size">Alternate Number(Optional)</label>
                          <div class="d-flex">
                            <!-- <select class="form-control col-md-3 m-lable-size" formControlName="countryCodeAltPhoneNum">
                              <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                                {{ country.emoji }}&nbsp;&nbsp;{{
                                country.name
                                }}&nbsp;&nbsp;(+{{ country.phone_code }})
                              </option>
                            </select> -->
                            <ng-select [searchFn]="customSearchFn" formControlName="countryCodeAltPhoneNum"
                              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                              bindLabel="phone_code" bindValue="phone_code">
                              <ng-template ng-option-tmp let-item="item">
                                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                item.name
                                }}&nbsp;&nbsp;(+{{ item.phone_code }})
                              </ng-template>
                            </ng-select>
                            <input type="text" class="col-md-9 mt-0 ml-1 form-control m-lable-size "
                              placeholder="Enter Alternate Number" formControlName="alternatePhoneNum" />
                          </div>
                          <div *ngIf="
                              (a.countryCodeAltPhoneNum.invalid &&
                                (a.countryCodeAltPhoneNum.touched ||
                                  a.countryCodeAltPhoneNum.dirty)) ||
                              (a.alternatePhoneNum.invalid &&
                                (a.alternatePhoneNum.touched ||
                                  a.alternatePhoneNum.dirty))
                            ">
                            <small class="error-msg text-danger font-10">Please Enter a Valid Alternate Contact
                              Number.</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <label for="city" class="m-lable-size">City*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your City"
                            formControlName="city" />
                          <div *ngIf="
                              a.city.invalid && (a.city.touched || a.city.dirty)
                            ">
                            <small class="error-msg text-danger font-10 ">City is required.</small>
                          </div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="state" class="m-lable-size">State*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your State"
                            formControlName="state" />
                          <div *ngIf="
                              a.state.invalid &&
                              (a.state.touched || a.state.dirty)
                            ">
                            <small class="error-msg text-danger font-10">State is required.</small>
                          </div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="country" class="m-lable-size">Country*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your Country"
                            formControlName="country" />
                          <div *ngIf="
                              a.country.invalid &&
                              (a.country.touched || a.country.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Country is required.</small>
                          </div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="pinCode" class="m-lable-size">Pincode*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your Pincode"
                            formControlName="pinCode" />
                          <div *ngIf="
                              a.pinCode.invalid &&
                              (a.pinCode.touched || a.pinCode.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Pincode is required.</small>
                          </div>
                        </div>
                      </div>




                      <!-- 
                      <div class="row justify-content-center">
                        <div class="">
                          <button type="button" class="btn btn-outline-info mr-4" (click)="addressBackPage()">
                            Back
                          </button>
                        </div>

                        <div class=" form-group">
                          <button type="submit" class="p-0 btn btn-success bg-success btn-sm border-0"
                            [disabled]="addAddressForm.invalid">
                            Submit
                          </button>
                        </div>
                        <div class="col-md-12 text-center form-group text-danger" *ngIf="addAddressForm.invalid">
                          Fields with * are required.
                        </div>
                      </div> -->

                      <div class="container">
                        <div class="row justify-content-center">
                          <div class="col-4 col-md-4">
                            <button type="button" class="btn btn-outline-info btn-sm w-100 "
                              (click)="addressBackPage()">
                              Back
                            </button>
                          </div>
                          <div class="col-4 col-md-4">
                            <button type="submit" class="p-0 btn btn-success bg-success btn-sm btn-sm w-100"
                              [disabled]="addAddressForm.invalid">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>








                    </form>
                  </ng-container>
                  <ng-container *ngIf="isUpdateAddress">
                    <form class="col-md-12 mt-4" [formGroup]="updateAddressForm" (submit)="updateAddress()">
                      <div class="row">
                        <div class="col-3 form-group">
                          <label for="name" class="m-lable-size">Name*</label>
                          <input type="text" class="form-control m-lable-size"
                            placeholder="Example: Home, Office, Work etc." formControlName="name" />
                          <div *ngIf="
                              u.name.invalid && (u.name.touched || u.name.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Name is required. Please Enter a Valid
                              Name</small>
                          </div>
                        </div>
                        <div class="col-3 form-group">
                          <label for="flatNo" class="m-lable-size">Flat/House Number*</label>
                          <input type="text" class="form-control m-lable-size"
                            placeholder="Enter Your Flat or House Number" formControlName="flatNo" />
                          <div *ngIf="
                              u.flatNo.invalid &&
                              (u.flatNo.touched || u.flatNo.dirty)
                            ">
                            <small class="error-msg text-danger font-10 m-lable-size">Flat No is Required</small>
                          </div>
                        </div>
                        <div class="col-3 form-group">
                          <label for="locality" class="m-lable-size">Locality*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your Locality"
                            formControlName="locality" />
                          <div *ngIf="
                              u.locality.invalid &&
                              (u.locality.touched || u.locality.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Locality is Required</small>
                          </div>
                        </div>
                        <div class="col-3 form-group">
                          <label for="landmark" class="m-lable-size">LandMark(Optional)</label>
                          <input type="text" class="form-control m-lable-size"
                            placeholder="Enter Your landmark (Optional)" formControlName="landmark" />
                          <div *ngIf="
                              u.landmark.invalid &&
                              (u.landmark.touched || u.landmark.dirty)
                            ">
                            <small class="error-msg text-danger font-10">LandMark is Required</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 form-group">
                          <label for="name" class="m-lable-size">Contact Number*</label>
                          <div class="d-flex">
                            <!-- <select class="form-control w-40" formControlName="countryCodePhoneNum">
                              <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                                {{ country.emoji }}&nbsp;&nbsp;{{
                                country.name
                                }}&nbsp;&nbsp;(+{{ country.phone_code }})
                              </option>
                            </select> -->
                            <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
                              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                              bindLabel="phone_code" bindValue="phone_code">
                              <ng-template ng-option-tmp let-item="item">
                                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                item.name
                                }}&nbsp;&nbsp;(+{{ item.phone_code }})
                              </ng-template>
                            </ng-select>
                            <input type="text" class="form-control m-lable-size " placeholder="Enter Contact Number"
                              formControlName="phoneNum" />
                          </div>
                          <div *ngIf="
                              (u.countryCodePhoneNum.invalid &&
                                (u.countryCodePhoneNum.touched ||
                                  u.countryCodePhoneNum.dirty)) ||
                              (u.phoneNum.invalid &&
                                (u.phoneNum.touched || u.phoneNum.dirty))
                            ">
                            <small class="error-msg text-danger font-10">Contact Number is required. Please Enter a
                              Valid
                              Contact Number.</small>
                          </div>
                        </div>
                        <div class="col-6 form-group">
                          <label for="name" class="m-lable-size">Alternate Number(Optional)</label>
                          <div class="d-flex">
                            <!-- <select class="form-control m-lable-size w-40" formControlName="countryCodeAltPhoneNum">
                              <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                                {{ country.emoji }}&nbsp;&nbsp;{{
                                country.name
                                }}&nbsp;&nbsp;(+{{ country.phone_code }})
                              </option>
                            </select> -->
                            <ng-select [searchFn]="customSearchFn" formControlName="countryCodeAltPhoneNum"
                              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                              bindLabel="phone_code" bindValue="phone_code">
                              <ng-template ng-option-tmp let-item="item">
                                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                item.name
                                }}&nbsp;&nbsp;(+{{ item.phone_code }})
                              </ng-template>
                            </ng-select>
                            <input type="text" class="form-control m-lable-size w-60"
                              placeholder="Enter Alternate Number" formControlName="alternatePhoneNum" />
                          </div>
                          <div *ngIf="
                              (u.countryCodeAltPhoneNum.invalid &&
                                (u.countryCodeAltPhoneNum.touched ||
                                  u.countryCodeAltPhoneNum.dirty)) ||
                              (u.alternatePhoneNum.invalid &&
                                (u.alternatePhoneNum.touched ||
                                  u.alternatePhoneNum.dirty))
                            ">
                            <small class="error-msg text-danger font-10">Please Enter a Valid Alternate Contact
                              Number.</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3 form-group">
                          <label for="city" class="m-lable-size">City*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your City"
                            formControlName="city" />
                          <div *ngIf="
                              u.city.invalid && (u.city.touched || u.city.dirty)
                            ">
                            <small class="error-msg text-danger font-10">City is required.</small>
                          </div>
                        </div>
                        <div class="col-3 form-group">
                          <label for="state" class="m-lable-size">State*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your State"
                            formControlName="state" />
                          <div *ngIf="
                              u.state.invalid &&
                              (u.state.touched || u.state.dirty)
                            ">
                            <small class="error-msg text-danger font-10">State is required.</small>
                          </div>
                        </div>
                        <div class="col-3 form-group">
                          <label for="country" class="m-lable-size">Country*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your Country"
                            formControlName="country" />
                          <div *ngIf="
                              u.country.invalid &&
                              (u.country.touched || u.country.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Country is required.</small>
                          </div>
                        </div>
                        <div class="col-3 form-group">
                          <label for="pinCode" class="m-lable-size">Pincode*</label>
                          <input type="text" class="form-control m-lable-size" placeholder="Enter Your Pincode"
                            formControlName="pinCode" />
                          <div *ngIf="
                              u.pinCode.invalid &&
                              (u.pinCode.touched || u.pinCode.dirty)
                            ">
                            <small class="error-msg text-danger font-10">Pincode is required.</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="
                            col-md-12
                            d-flex
                            justify-content-center
                            form-group
                          ">
                          <div>
                            <button type="button" class="btn btn-outline-info mr-4" (click)="addressBackPage()">
                              Back
                            </button>
                          </div>
                          <div>
                            <button type="submit" class="btn btn-success btn-sm bg-success border-0" [disabled]="
                            updateAddressForm.invalid">
                              Submit
                            </button>
                          </div>

                        </div>
                        <div class="col-md-12 text-center form-group text-danger" *ngIf="updateAddressForm.invalid">
                          Fields with * are required.
                        </div>
                      </div>
                    </form>
                  </ng-container>
                </div>
                <div class="modal-footer text-white p-0">
                  <button type="button" class="btn btn-danger bg-danger text-white btn-sm px-3 mb-2 mt-2 mr-3"
                    (click)="modal.close()">
                    Close
                  </button>
                </div>
              </ng-template>
              <button type="button" class="form-control btn btn-sm btn-primary" (click)="openAddressPopup(address)">
                {{ isAddressSelected ? "Change Address" : "Select Address" }}
              </button>
            </div>
          </div>

        </div>
      </div>


      <div class="container p-0 mt-3">
        <div class="row gx-1">
          <div class="col" *ngIf="isAddressSelected">
            <div class="mb-2 mt-3">
              <h5 class="text-muted">Address Detail</h5>
            </div>
            <div class="shadow-sm py-3 border border-info rounded pl-3">
              <i class="fa-solid fa-location-dot"></i>
              <span class="text-muted">
                {{ selectedAddress.name }} - {{ selectedAddress.flatNo }},
                {{ selectedAddress.locality }}, {{ selectedAddress.city }},
                {{ selectedAddress.state }}, {{ selectedAddress.country }},
                {{ selectedAddress.pinCode }}
              </span>
            </div>
          </div>
          <div class="col"
            *ngIf="isNameOn || isEmailOn || isGenderOn || isPhoneOn || isMaritalStatusOn || isDobOn || isTobOn || isCommentsOn">
            <!-- form start here -->
            <div class="mb-2 mt-3">
              <h5 class="text-muted">Please Fill Details</h5>
            </div>

            <div class="p-3 rounded mt-2 border">
              <!-- Person details start -->
              <div class="form-row">
                <div class="form-group col-md-6" *ngIf="isNameOn">
                  <label for="inputAddress" class="small text-muted">Name *</label>
                  <input type="text" placeholder="Please Enter Name*" class="form-control" formControlName="name" />
                  <div *ngIf="f.name.invalid && (f.name.touched || f.name.dirty)">
                    <small class="error-msg text-danger font-10">Name is required. Please Enter a Valid Name</small>
                  </div>
                </div>
                <div class="form-group col-md-6" *ngIf="isEmailOn">
                  <label for="inputAddress" class="small text-muted">Email *</label>
                  <input type="text" placeholder="Enter Email*" class="form-control" formControlName="email" />
                  <div *ngIf="f.email.invalid && (f.email.touched || f.email.dirty)">
                    <small class="error-msg text-danger font-10">Email is required. Please Enter a Valid Email</small>
                  </div>
                </div>

                <div class="form-group col-md-6" *ngIf="isGenderOn">
                  <label for="inputZip" class="small text-muted">Select Gender</label>
                  <select class="form-control" aria-label="Select Gender" formControlName="gender">
                    <option value="" disabled selected>Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <div *ngIf="f.gender.invalid && (f.gender.touched || f.gender.dirty)">
                    <small class="error-msg text-danger font-10">Gender is required. Please Enter a Valid Gender</small>
                  </div>
                </div>
                <div class="form-group col-md-6" *ngIf="isPhoneOn">
                  <label for="inputPassword4" class="m-lable-size">Contact Number*</label>
                  <div class="form-row">
                    <div class="col-md-5">
                      <!-- <select name="countryCode" class="form-control" formControlName="countryCode">
                        <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                          {{ country.emoji }}&nbsp;&nbsp;{{
                          country.name
                          }}&nbsp;&nbsp;(+{{ country.phone_code }})
                        </option>
                      </select> -->
                      <ng-select [searchFn]="customSearchFn" formControlName="countryCode"
                        class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList"
                        bindLabel="phone_code" bindValue="phone_code">
                        <ng-template ng-option-tmp let-item="item">
                          <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                          item.name
                          }}&nbsp;&nbsp;(+{{ item.phone_code }})
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="col-md">
                      <input type="text" class="form-control" name="phone " formControlName="phone" />
                      <div *ngIf="f.phone.invalid && (f.phone.touched || f.phone.dirty)">
                        <small class="error-msg text-danger font-10">Phone Number is required. Please Enter a Valid
                          Phone
                          Number</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-md-6" *ngIf="isMaritalStatusOn">
                  <label for="inputZip" class="small text-muted">Marital Status *</label>
                  <select class="form-control" formControlName="maritalStatus">
                    <option value="" selected disabled>Select Status*</option>
                    <option>Single</option>
                    <option>Married</option>
                    <option>Widowed</option>
                    <option>Divorced</option>
                  </select>
                </div>
                <div class="form-group col-md-6" *ngIf="isDobOn">
                  <label for="inputZip" class="small text-muted">Date Of Birth *</label>
                  <input type="date" class="form-control" formControlName="dob" [max]="today" (keydown)="disableDate()"/>
                  <div *ngIf="f.dob.invalid && (f.dob.touched || f.dob.dirty)">
                    <small class="error-msg text-danger font-10">dob is required. Please Enter a Valid Date of
                      Birth</small>
                  </div>
                </div>
                <div class="form-group col" *ngIf="isTobOn">
                  <label for="inputZip" class="small text-muted">Time Of Birth *</label>
                  <input type="time" class="form-control" formControlName="tob" />
                  <div *ngIf="f.tob.invalid && (f.tob.touched || f.tob.dirty)">
                    <small class="error-msg text-danger font-10">tob is required. Please Enter a Valid Time of
                      Birth</small>
                  </div>
                </div>
                <div class="form-group col-md-12 col-md-6" *ngIf="isCommentsOn">
                  <textarea formControlName="comments" placeholder="Comment" class="form-control"></textarea>
                  <div *ngIf="f.comments.invalid && (f.comments.touched || f.comments.dirty)
              ">
                    <small class="error-msg text-danger font-10">Comments is required.</small>
                  </div>
                </div>
              </div>

            </div>
            <!-- Person details end -->

          </div>
        </div>
      </div>
      <!-- Experts Details End Here -->
      <div class="row mt-3">
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-danger btn-sm mt-2 px-5 py-2" [disabled]="!saveOrderForm.valid">
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  </form>

</section>

<!--Content End-->
<app-footer></app-footer>