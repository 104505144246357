<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Verify Number
      </h3>
      <ul
        class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        "
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Verify Number</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-md-5 mt-3">
  <div class="container">
    <div class="row">
      <h4 class="fw-500">Verify Your Phone Number</h4>
    </div>
    <div class="row mt-3">
      <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
        <form>
          <div class="text-center">
            <ng-otp-input
              (onInputChange)="onOtpChange($event)"
              [config]="{ length: 4 }"
            ></ng-otp-input>
          </div>
          <div class="text-center">
            <button class="btn btn-link text-danger" (click)="resendOtp()">
              Resend OTP.
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
