import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  BaseUrl: string = '';
  Token: any = '';
  header_object;
  constructor(
    private userdata: UserdataService,
    private http: HttpClient,
    @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base;
    this.Token = userdata.getToken();
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
  }

  /**
   * Get Consultant
   */
  public getConsultantList(data: any) {
    return this.http.post(this.BaseUrl + '/showastrologeriwork', data, {
      headers: this.header_object,
    });
  }

  /**
   * Add Address
   */
  saveAddress(data: any, id: string) {
    if (id.length > 0)
      return this.http.post(this.BaseUrl + '/updateAddress', data, {
        headers: this.header_object,
      });
    else
      return this.http.post(this.BaseUrl + '/addAddress', data, {
        headers: this.header_object,
      });
  }
  public addAddress(data: any) {
    return this.http.post(this.BaseUrl + '/addAddress', data, {
      headers: this.header_object,
    });
  }

  /**
   * Get All Addresses
   */
  public getAddressesList(data: any) {
    return this.http.post<any>(this.BaseUrl + '/getMyAddresses', data, {
      headers: this.header_object,
    });
  }

  /**
   * Single Addresses
   */
  public getSingleAddressList(data: any) {
    return this.http.post<any>(this.BaseUrl + '/getSingleAddressbyid', data, {
      headers: this.header_object,
    });
  }

  /**
   * Update Addresses
   */
  public updateAddress(data: any) {
    //_id,fullform
    return this.http.post(this.BaseUrl + '/updateAddress', data, {
      headers: this.header_object,
    });
  }

  /**
   * Delete Addresses
   */
  public deleteAddress(data: any) {
    //_id,status:false
    return this.http.post(this.BaseUrl + '/deleteAddress', data, {
      headers: this.header_object,
    });
  }

  /**
   * Save Order
   */
  public saveOrder(data: any) {
    return this.http.post(this.BaseUrl + '/saveOrder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Checkout
   */
  public checkOut(data: any) {
    return this.http.post(this.BaseUrl + '/getCheckoutInfo', data, {
      headers: this.header_object,
    });
  }

  /**
   * Coupon
   */
  public applyCoupon(data: any) {
    return this.http.post(this.BaseUrl + '/applyCoupon', data, {
      headers: this.header_object,
    });
  }

  /**
   * Remove Coupon
   */
  public removeCoupon(data: any) {
    return this.http.post(this.BaseUrl + '/removeCoupon', data, {
      headers: this.header_object,
    });
  }

  /**
   * Place Order
   */
  public placeOrder(data: any) {
    return this.http.post(this.BaseUrl + '/placeOrder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Razor Pay
   */
  public razorPay(data: any) {
    return this.http.post(this.BaseUrl + '/razorPay', data, {
      headers: this.header_object,
    });
  }
}
