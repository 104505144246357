import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { SwiperOptions } from 'swiper';
import * as $ from 'jquery';
import { JsService } from 'src/app/service/js/js.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  constructor(private openService: OpenApiService, private router: Router, private jsService: JsService) {
  }
  testimonialList = new Array();
  testimonialData = new Array();
  current_year: any;
  footerEndpoint: any;
  ngOnInit(): void {
    this.jsService.callJsClass()
    const url = this.router.url.split('/');
    this.footerEndpoint = url[1];
    this.getTestimonial();
    this.current_year = new Date().getFullYear();
  }
  getTestimonial() {
    this.openService.getTestimonials().subscribe(
      (res: any) => {
        this.testimonialData = res.data;
        var limit = res.data.length / 3;
        var i = 0;
        while (i < limit) {
          var j = 0;
          var start = i * 3;
          var temp = [];
          while (j < 3) {
            temp[j] = res.data[start + j];
            j++;
          }
          this.testimonialList[i] = temp;
          i++;
        }
      },
      (err: any) => {
        //console.log('test', err)
      }
    );
  }
  partnerCellNumber =
    window.innerWidth < 510 ? 1 : window.innerWidth < 850 ? 3 : 4;
  partnerConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: this.partnerCellNumber,
    direction: 'horizontal',
    spaceBetween: 10,
    autoplay: {
      delay: 5000,
    },
    effect: 'slide',
  };

  goToTop() {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
  }

  fadeMenuWrap() {
    //console.log('function')
    var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPos > 300) {
      //console.log('if')
    } else {
      // console.log('else')
    }
  }

  // @HostListener('mousewheel', ['$event'])
  // onMousewheel(event: any) {
  //   console.log(event)
  // }

  @HostListener('body:scroll', ['$event']) onScroll(event: any) {
    //console.log(event)
  }

}
