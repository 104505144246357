import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { Chat } from './chat';

@Injectable({
  providedIn: 'root'
})
export class FirebaseServiceService {

  private dbPath = '/chat';
  private dbGroupPath = '/groupchat';

  chatsRef: any
  chatsRefId:any

  constructor(private db: AngularFireDatabase) {
    this.chatsRef = db.list(this.dbPath);
    //this.chatsRefId = db.list(this.dbPath+'/'+);
  }
 
  getChatList(id:any): AngularFireList<Chat> {
    return this.db.list(this.dbPath+'/'+id);
  }

  getGroupChatList(id:any): AngularFireList<Chat> {
    return this.db.list(this.dbGroupPath+'/'+id);
  }
}
