<app-header></app-header>
<!-- hs About Title Start -->
<div class="hs_indx_title_main_wrapper">
  <div class="hs_title_img_overlay"></div>
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
              <div class="hs_indx_title_left_wrapper">
                  <h2>Verify your Email</h2>
              </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
              <div class="hs_indx_title_right_wrapper">
                  <ul>
                    <li><a [routerLink]="['/home']">Home</a> &nbsp;&nbsp;&nbsp;&gt; </li>
                      <li>Verify your Email</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- hs About Title End -->
<div class="hs_service_main_wrapper pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <div class="hs_kd_five_heading_sec_wrapper">
                    <h2>Verify Your Email</h2>
                    <h4><span></span></h4>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <form>
                    <div class="text-center">
                        <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4}"></ng-otp-input>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-link text-danger" (click)="resendOtp()">Resend OTP.</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
