<div class="container d-none d-lg-block p-0" [ngClass]="isSignUp ? 'right-panel-active ' : 'left-panel-active '"
  id="container" name="container">
  <div class="px-lg-3 px-4 py-3 d-flex flex-column bg-secondary sign-in-container" *ngIf="isSignUp">
    <h3 class="
        d-none d-lg-block
        text-white text-center
        mb-2
        mt-4
        text-uppercase
        fw-600
      ">
      Create Account
    </h3>
    <h5 class="d-lg-none text-white text-center mb-2 text-uppercase fw-600">
      Create Account
    </h5>
    <app-register [registerData]="registerData"></app-register>
  </div>

  <div class="px-lg-3 px-4 py-5 d-flex flex-column bg-secondary sign-in-container" *ngIf="isSignIn">
    <h3 class="fw-600 text-white text-center mb-2 text-uppercase">Sign in</h3>
    <app-login></app-login>
  </div>
  <div class="overlay-container">
    <!-- close button added code Start -->
    <div class="d-flex  p-2" [ngClass]="isSignUp ? 'justify-content-start  ' : 'justify-content-end  '">
      <a (click)="close()" class="p-2"><i class=" text-red fa-solid fa-circle-xmark fa-xl"></i></a>
    </div>
    <!-- close button added code End -->
    <div class="overlay">
      <div class="d-flex flex-column overlay-panel overlay-left">
        <img src="../../../assets/images/sign-in.png" class="w-100" />
        <h5 class="my-3 fw-500">
          To keep connected with us please login with your personal info
        </h5>
        <button class="btn bg-secondary w-100 m-0 rounded py-2 text-white fw-500" id="signIn" (click)="signInButton()">
          Login
        </button>
      </div>

      <div class="d-flex flex-column overlay-panel overlay-right">
        <img src="../../../assets/images/sign-up.png" class="w-100" />
        <h5 class="my-3 fw-500">
          Enter your personal details and start journey with us
        </h5>
        <button class="btn bg-secondary w-100 m-0 rounded py-2 text-white fw-500" id="signUp" (click)="signUpButton()">
          Sign Up
        </button>
      </div>
    </div>
  </div>
</div>

<div class="d-lg-none">
  <div class="text-center" name="container">
    <div class="py-4 px-3 form-container sign-in-container-sm d-flex flex-column" *ngIf="showLogin">
      <h3 class="fw-600 text-white text-uppercase">Login</h3>
      <app-login></app-login>

      <span class="mb-1  fw-500 txt-blk-mob">or </span>
      <button type="button" class="btn  bg-white mob-c-btn active-text m-0 py-2 px-0 fw-600"
        (click)="changeMobileForm()">
        Create an account
      </button>
    </div>
    <div class="py-2 px-3 form-container sign-in-container-sm d-flex sign-up-container flex-column" *ngIf="!showLogin">
      <h3 class="fw-500 text-capitalize text-white my-2">create account</h3>
      <app-register [registerData]="registerData"></app-register>

      <span class="mb-1 text-white fw-500 txt-blk-mob">or </span>
      <button type="button" class=" btn bg-white mob-c-btn active-text m-0 py-2 px-0 fw-600"
        (click)="changeMobileForm()">
        Sign In
      </button>
    </div>
  </div>
</div>