<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Daily Horoscope
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">
          <a [routerLink]="['/daily-horoscope']" class="text-white">Daily Horoscope</a>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- hs sidebar Start -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="
                  hs_sign_left_tabs_wrapper hs_sign_left_tabs_border_wrapper1
                ">
                <div class="hs_slider_tabs_icon_wrapper">
                  <img [src]="getSanitizeUrl(imageName)" alt="team-img" class="img rounded horo-img" />
                </div>
                <div class="
                    hs_slider_tabs_icon_cont_wrapper hs_ar_tabs_heading_wrapper
                  ">
                  <ul class="sign-name">
                    <li>
                      <a href="#" class="hs_tabs_btn">{{ id }}</a>
                    </li>
                    <li>{{ dataList.date_range }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2 ">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">

              <div class="p-2 horoscope-order-bg rounded">
                <!-- <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <div class="hs_ar_first_sec_img_wrapper">
                  <img [src]="getSanitizeUrl(imageName)" [alt]="id" />
                </div>
              </div> -->


                <p class="text-justify">{{ dataList.description }}</p>



              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 order-bg detail-zodiac pt-2 pb-2 rounded ">
              <div class="  
                ">
                <ul>
                  <li>
                    <div class="hs_pr_icon_wrapper">
                      <i class="fa fa-circle"></i>
                    </div>
                    <div class="hs_pr_icon_cont_wrapper hs_ar_icon_cont_wrapper">
                      <p><span>Lucky Colors</span> : {{ dataList.color }}</p>
                    </div>
                  </li>
                  <li>
                    <div class="hs_pr_icon_wrapper">
                      <i class="fa fa-circle"></i>
                    </div>
                    <div class="hs_pr_icon_cont_wrapper hs_ar_icon_cont_wrapper">
                      <p>
                        <span>Lucky Numbers</span> : {{ dataList.lucky_number }}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="hs_pr_icon_wrapper">
                      <i class="fa fa-circle"></i>
                    </div>
                    <div class="hs_pr_icon_cont_wrapper hs_ar_icon_cont_wrapper">
                      <p><span>Lucky Time</span> : {{ dataList.lucky_time }}</p>
                    </div>
                  </li>
                  <li>
                    <div class="hs_pr_icon_wrapper">
                      <i class="fa fa-circle"></i>
                    </div>
                    <div class="hs_pr_icon_cont_wrapper hs_ar_icon_cont_wrapper">
                      <p><span> Mood </span>: {{ dataList.mood }}</p>
                    </div>
                  </li>
                  <li>
                    <div class="hs_pr_icon_wrapper">
                      <i class="fa fa-circle"></i>
                    </div>
                    <div class="hs_pr_icon_cont_wrapper hs_ar_icon_cont_wrapper">
                      <p>
                        <span> Compatibility </span>:
                        {{ dataList.compatibility }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- hs sidebar End -->
<app-footer></app-footer>