<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Remedy-List</h3>
            <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                </li>
                <li>
                    <i class="fn-2 material-icons-round bottom-align">
                        arrow_forward_ios
                    </i>
                </li>
                <li>
                    <a *ngIf="dataList?.is_query_order" [routerLink]="['/query-order-list',id]" class="text-white">Query
                        Detail</a>
                    <a *ngIf="dataList?.is_report_order" [routerLink]="['/report-order-list',id]"
                        class="text-white">Report Detail</a>
                    <a *ngIf="dataList?.is_chat_order" [routerLink]="['/chat-order-list',id]" class="text-white">Chat
                        Detail</a>
                    <a *ngIf="dataList?.is_call_order" [routerLink]="['/call-order-list',id]" class="text-white">Call
                        Detail</a>
                </li>
                <li>
                    <i class="fn-2 material-icons-round bottom-align">
                        arrow_forward_ios
                    </i>
                </li>
                <li class="text-capitalize">Remedy-List</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<!-- body container start here -->
<div class="container mt-5">
    <div class="row">
        <div class="col-md-3">
            <app-customer-sidebar></app-customer-sidebar>
        </div>
        <div class="col-md-9">
            <ng-container *ngIf="dataList?.is_query_order">
                <h4 class=" mb-4"><span class="font-weight-bold">Query - #{{dataList?.queryId?.oquestionId}}</span>
                    ({{dataList?.queryId?.question}})</h4>
            </ng-container>
            <ng-container *ngIf="dataList?.is_report_order">
                <h4 class=" mb-4"><span class="font-weight-bold">Report - #{{dataList?.reportId?.oreportId}}</span></h4>
            </ng-container>
            <ng-container *ngIf="dataList?.is_call_order">
                <h4 class=" mb-4"><span class="font-weight-bold">Call - #{{dataList?.callId?.ocallId}}</span></h4>
            </ng-container>
            <ng-container *ngIf="dataList?.is_chat_order">
                <h4 class=" mb-4"><span class="font-weight-bold">Chat - #{{dataList?.chatId?.ochatId}}</span></h4>
            </ng-container>
            <ul class="nav nav-pills mb-4 tab-h-style">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active bg-warning text-dark':showSuggested}" aria-current="page"
                        (click)="showSuggested=true">Suggested Remedies</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active bg-warning text-dark ml-2':!showSuggested}"
                        (click)="showSuggested=false">Purchased Remedies</a>
                </li>

                <!-- <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                </li> -->
            </ul>
            <ng-container *ngIf="showSuggested">
                <section class="container  order-bg1 mt-5 mb-5 py-3" *ngIf="totalSuggestedRemedy == 0">
                    <div class=" row n-data-img justify-content-center">
                        <img src="../../../../assets/images/empty-cart.svg" />
                    </div>
                    <div>
                        <p class="h5 text-center mt-2 ">No Remedy Available.</p>

                    </div>
                </section>
                <ng-container *ngFor="let data of dataList?.remedysuggestIds">
                    <div class="card shadow border-0 card-body mt-3"
                        *ngIf="!data?.remedyId?.isPaid && !data?.remedyId?.isSuggestedProductBought">
                        <div
                            class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row rounded">

                            <div class="remedy-body col">
                                <div class="mb-1 text-right">
                                    <span class="small text-info"><i
                                            class="fa-regular fa-calendar border-right pr-1 pl-1"></i>
                                        {{data?.remedyId?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}</span>
                                </div>
                                <h5 class="font-weight-semibold">
                                    <a href="#" data-abc="true" class="fw-500 text-dark">Free</a>
                                </h5>
                                <p class="mb-1 mt-1 text-justify small pr-1">{{data?.remedyId?.remedy_description}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="card shadow border-0 card-body mt-3"
                        *ngIf="data?.remedyId?.isPaid && !data?.remedyId?.isSuggestedProductBought">
                        <div
                            class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row rounded">
                            <div class="mr-1 mb-3 mb-lg-0"
                                *ngIf="data?.remedyId?.suggestedProductId != undefined && data?.remedyId?.suggestedProductId != null">

                                <img [src]="getSanitizeUrl(data?.remedyId?.suggestedProductId?.product_image[0])"
                                    class="remedy-img rounded">

                            </div>

                            <div class="remedy-body "
                                [ngClass]="{'col-md-7':data?.remedyId?.suggestedProductId?.product_image.length > 0,'col':data?.remedyId?.suggestedProductId == undefined && data?.remedyId?.suggestedProductId == null}">
                                <div class="mb-1">
                                    <span class="small text-info"><i
                                            class="fa-regular fa-calendar border-right pr-1 pl-1"></i>
                                        {{data?.remedyId?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}</span>
                                </div>
                                <h5 class="font-weight-semibold"
                                    *ngIf="data?.remedyId?.suggestedProductId != undefined && data?.remedyId?.suggestedProductId != null">
                                    <a *ngIf="(data?.remedyId?.suggestedProductId == undefined && data?.remedyId?.suggestedProductId == null) || !data?.remedyId?.suggestedProductId?.status"
                                        data-abc="true"
                                        class="fw-500 text-dark">{{data?.remedyId?.suggestedProductId?.productname}}</a>
                                    <a href="#"
                                        [routerLink]="['/product-detail',data?.remedyId?.suggestedProductId?._id]"
                                        *ngIf="(data?.remedyId?.suggestedProductId == undefined && data?.remedyId?.suggestedProductId == null) || data?.remedyId?.suggestedProductId?.status"
                                        data-abc="true"
                                        class="fw-500 text-dark">{{data?.remedyId?.suggestedProductId?.productname}}</a>
                                </h5>
                                <h5 class="font-weight-semibold"
                                    *ngIf="data?.remedyId?.suggestedProductId == undefined || data?.remedyId?.suggestedProductId == null">
                                    <a href="#" data-abc="true"
                                        class="fw-500 text-dark">{{data?.remedyId?.product_name}}</a>
                                </h5>

                                <p class="mb-1 mt-1 text-justify small pr-1">{{data?.remedyId?.remedy_description}}</p>
                                <div class=" d-flex"
                                    *ngIf="data?.remedyId?.suggestedastroId != null && data?.remedyId?.suggestedastroId != undefined">
                                    <div class="">
                                        <span class="text-danger fw-600 small border-right pr-3">Suggested</span>
                                    </div>

                                    <div class="ml-3">
                                        <span class="fw-600 small">Expert: <span
                                                class="text-success">{{data?.remedyId?.suggestedastroId?.name}}</span></span>
                                    </div>
                                </div>
                            </div>

                            <div class=" mt-3 mt-lg-0 ml-lg-3 text-center">
                                <h4 class="mb-0 fw-600 text-success">{{data?.remedyId?.remedyPrice | currency: (code ?
                                    "INR" :
                                    "USD"):"symbol-narrow"}}</h4>


                                <!-- <div class="text-muted">2349 reviews</div> -->
                                <!-- <button type="button" class="btn btn-outline-primary btn-sm  mt-4 "><i
                                        class="icon-cart-add mr-2"></i>
                                    Read More</button> -->
                                <button [routerLink]="['/product-detail',data?.remedyId?.suggestedProductId?._id]"
                                    type="button" class="btn btn-danger btn-sm  mt-2 text-white"
                                    [disabled]="(data?.remedyId?.suggestedProductId == undefined && data?.remedyId?.suggestedProductId == null) || !data?.remedyId?.suggestedProductId?.status"><i
                                        class="icon-cart-add mr-2"></i>
                                    Book Now</button><br>
                                <small class="text-danger"
                                    *ngIf="(data?.remedyId?.suggestedProductId == undefined && data?.remedyId?.suggestedProductId == null) || !data?.remedyId?.suggestedProductId?.status">Will
                                    be soon available in Astro Mall.</small>

                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!showSuggested">
                <section class="container  order-bg1 mt-5 mb-5 py-3" *ngIf="totalPurchasedRemedy == 0">
                    <div class=" row n-data-img justify-content-center">
                        <img src="../../../../assets/images/empty-cart.svg" />
                    </div>
                    <div>
                        <p class="h5 text-center mt-2 ">No Remedy Available.</p>

                    </div>
                </section>
                <ng-container *ngFor="let data of dataList?.remedysuggestIds">
                    <div class="card shadow border-0 card-body mt-3"
                        *ngIf="!data?.remedyId?.isPaid && data?.remedyId?.isSuggestedProductBought">
                        <div
                            class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row rounded">


                            <div class="remedy-body col">
                                <div class="mb-1 text-right">
                                    <span class="small text-info"><i
                                            class="fa-regular fa-calendar border-right pr-1 pl-1"></i>
                                        {{data?.remedyId?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}</span>
                                </div>
                                <!-- <h5 class="font-weight-semibold">
                                    <a href="#" data-abc="true" class="fw-500 text-dark">Lorem ipsum dolor sit</a>
                                </h5> -->

                                <p class="mb-1 mt-1 text-justify small pr-1">{{data.remedy_description}}</p>
                                <!--         
                                <button type="button" class="btn btn-outline-primary mt-2 btn-sm"><i
                                        class="icon-cart-add mr-2"></i>
                                    Read More</button> -->
                            </div>
                        </div>
                    </div>
                    <div class="card shadow border-0 card-body mt-3"
                        *ngIf="data?.remedyId?.isPaid && data?.remedyId?.isSuggestedProductBought">
                        <div
                            class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row rounded">
                            <div class="mr-1 mb-3 mb-lg-0"
                                *ngIf="data?.remedyId?.suggestedProductId?.productname != undefined && data?.remedyId?.suggestedProductId?.productname != null">

                                <img [src]="getSanitizeUrl(data?.remedyId?.suggestedProductId?.product_image[0])"
                                    class="remedy-img rounded">

                            </div>

                            <div class="remedy-body col-md-7">
                                <div class="mb-1">
                                    <span class="small text-info"><i
                                            class="fa-regular fa-calendar border-right pr-1 pl-1"></i>
                                        {{data?.remedyId?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}</span>
                                </div>
                                <h5 class="font-weight-semibold"
                                    *ngIf="data?.remedyId?.suggestedProductId?.productname != undefined && data?.remedyId?.suggestedProductId?.productname != null">
                                    <a href="#"
                                        [routerLink]="['/product-detail/',data?.remedyId?.suggestedProductId?._id]"
                                        data-abc="true"
                                        class="fw-500 text-dark">{{data?.remedyId?.suggestedProductId?.productname}}</a>
                                </h5>
                                <h5 class="font-weight-semibold"
                                    *ngIf="data?.remedyId?.suggestedProductId?.productname == undefined || data?.remedyId?.suggestedProductId?.productname == null">
                                    <a href="#" data-abc="true"
                                        class="fw-500 text-dark">{{data?.remedyId?.product_name}}</a>
                                </h5>

                                <p class="mb-1 mt-1 text-justify small pr-1">{{data?.remedyId?.remedy_description}}</p>
                                <div class=" d-flex"
                                    *ngIf="data?.remedyId?.suggestedastroId != null && data?.remedyId?.suggestedastroId != undefined">
                                    <div class="">
                                        <span class="text-danger fw-600 small border-right pr-3">Suggested</span>
                                    </div>

                                    <div class="ml-3">
                                        <span class="fw-600 small">Expert: <span
                                                class="text-success">{{data?.remedyId?.suggestedastroId?.name}}</span></span>
                                    </div>
                                </div>
                            </div>

                            <div class=" mt-3 mt-lg-0 ml-lg-3 text-center">
                                <h4 class="mb-0 fw-600 text-success">{{data?.remedyId?.remedyPrice | currency: (code ?
                                    "INR" :
                                    "USD"):"symbol-narrow"}}</h4>


                                <!-- <div class="text-muted">2349 reviews</div> -->
                                <!-- <button type="button" class="btn btn-outline-primary btn-sm  mt-4 "><i
                                        class="icon-cart-add mr-2"></i>
                                    Read More</button> -->
                                <button type="button" class="btn btn-danger btn-sm  mt-2 text-white"><i
                                        class="icon-cart-add mr-2"></i>
                                    Booked</button>

                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

    </div>


</div>

<!-- body container end here -->

<app-footer></app-footer>