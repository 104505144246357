<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Issue List</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize text-white">Issue List</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-3 mt-lg-5">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="
         row
          d-flex 
          f-style
          my-2
          ">
          <button [ngClass]="{'active':tab == 'all'}" class="btn btn-sm mr-2" (click)="getMyWallet()">
            All
          </button>
          <button [ngClass]="{'active':tab == 'wallet'}" class="btn btn-sm mr-2" (click)="walletIssue()">
            Wallet Issues
          </button>
          <button [ngClass]="{'active':tab == 'service'}" class="btn btn-sm mx-2" (click)="serviceIssue()">
            Service Issues
          </button>
          <button [ngClass]="{'active':tab == 'pending'}" class="btn btn-sm mx-2 " (click)="pendingIssue()">
            Pending Issues
          </button>
          <button [ngClass]="{'active':tab == 'solved'}" class="btn btn-sm mx-2" (click)="solvedIssue()">
            Solved Issues
          </button>
        </div>
        <div class="row">
          <ngx-datatable class="material" [rows]="dataList" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{ dir: 'desc' }]">
            <ngx-datatable-column name="Issue ID" prop="issueId" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/issue/', row._id]" title="View Details">{{
                  row.issueId
                  }}</a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="badge bdg-txt-fw bg-primary text-white py-2 fw-normal"
                  *ngIf="row.is_wallet_issue">WALLET</span>
                <span class="badge bdg-txt-fw bg-info py-2 fw-normal" *ngIf="row.is_service_issue">SERVICE</span>
                <span class="badge bdg-txt-fw bg-danger py-2 fw-normal" *ngIf="row.is_order_issue">ORDER</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Amount" prop="amount" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.is_order_issue"><span *ngIf="row.orderId.isForeignOrder; else usd">&#36;</span>
                  <ng-template #usd>&#8377;</ng-template>
                </span>
                <span *ngIf="row.is_wallet_issue">{{
                  row.walletId.currencySymbol
                  }}</span>
                <span *ngIf="row.is_service_issue">{{
                  row.orderserviceId.customerCurrencySymbol
                  }}</span>{{ row.amount }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Issue" prop="issue_title" [flexGrow]="3">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.issue_title }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="badge bdg-txt-fw bg-success py-2 fw-normal"
                  *ngIf="row.is_issue_solved; else pend">SOLVED</span>
                <ng-template #pend><span class="badge bdg-txt-fw bg-danger py-2 fw-normal">PENDING</span></ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date" [flexGrow]="2" prop="created_at">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.created_at | date: "medium" }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="action" [flexGrow]="2" prop="Action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a *ngIf="row.orderserviceId?.is_chat_order" class="btn py-1 px-3 bg-secondary text-white"
                  [routerLink]="['/chat-order-list', row.orderserviceId?._id]">
                  Detail
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- page end-->
<app-footer></app-footer>