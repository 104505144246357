import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { UserdetailsService } from 'src/app/service/userdetails/userdetails.service';

@Component({
  selector: 'app-login-update',
  templateUrl: './login-update.component.html',
  styleUrls: ['./login-update.component.css'],
})
export class LoginUpdateComponent implements OnInit {
  showLogin: boolean = true;
  constructor(
    private authService: SocialAuthService,
    private userdataService: UserdataService,
    private loginService: LoginService,
    private router: Router,
    private userDetailsService: UserdetailsService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      //console.log(user);
    });
  }

  isSignUp = false;
  isSignIn = true;

  signUpButton() {
    this.isSignUp = true;
    this.isSignIn = false;
  }

  signInButton() {
    this.isSignUp = false;
    this.isSignIn = true;
  }

  /**
   * Login with Google
   */
  public signInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((data) => {
        this.loginForm.patchValue({ social_id: data.id });
        this.loginForm.patchValue({ password: data.id });
        // this.loginSocialForm.patchValue({email:data.email})
        this.signOut();
        this.onLogin();
      })
      .catch((err: any) => {
        //console.log(err)
        //this.toast.error(err,'Error')
      });
  }

  /**
   * Login with Facebook
   */
  fbLoginOptions = {
    scope:
      'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
  };
  public signInWithFacebook(): void {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((data) => {
        //console.log(data);
        this.signOut();
        //this.onLogin()
      })
      .catch((err: any) => {
        //console.log(err)
        //this.toast.error(err,'Error')
      });
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    is_terms_accepted: new FormControl(true, [Validators.requiredTrue]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
    is_social_login: new FormControl(true),
    social_id: new FormControl(''),
  });

  get f() {
    return this.loginForm.controls;
  }

  isLoggedIn: boolean = false;
  public onLogin() {
    this.loginForm.patchValue({ fcm_token: this.userdataService.getFcm() });
    this.loginForm.patchValue({ is_social_login: true });
    this.loginService.login(this.loginForm.value).subscribe(
      (res: any) => {
        this.userdataService.setData(res);
        // console.log(res.customerdetail.is_otp_verified)
        if (!res.customerdetail.is_otp_verified) {
          this.router.navigate(['/verify-phone-otp', res.data.email]);
        } else {
          this.userDetailsService.onChangeOfUserDetailHandler(res.token);
          this.isLoggedIn = true;
          // console.log('this.isLoggedIn-----', this.isLoggedIn)
          // console.log(
          //   this.userDetailsService.onChangeOfUserDetailHandler(res.token)
          // );
          this.modalService.dismissAll();
          this.toast.success('Login Successful', 'Success');
          this.router.navigateByUrl('/home');
          // location.reload()
        }
        //console.log(res)
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Register with Google
   */
  registerData: any = '';
  public signUpWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((data) => {
        this.registerData = data;
        this.signOut();
      })
      .catch((err: any) => {
        //console.log(err);
      });
  }

  public signUpWithFacebook(): void {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((data) => {
        //console.log(data);

        this.signOut();
      })
      .catch((err: any) => {
        //console.log(err);
      });
  }

  public signOut(): void {
    this.authService.signOut(true);
  }

  changeMobileForm() {
    this.showLogin = !this.showLogin;
  }

  close() {
    this.modalService.dismissAll()
  }
}
