import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PassDataService } from 'src/app/service/data/pass-data.service';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-show-promo-admin-list',
  templateUrl: './show-promo-admin-list.component.html',
  styleUrls: ['./show-promo-admin-list.component.css'],
})
export class ShowPromoAdminListComponent implements OnInit {
  dataList: any = [];
  BaseUrl: any = '';
  isActiveOnOrder: boolean = false;
  constructor(
    private router: Router,
    private dataService: PassDataService,
    private orderService: OrderServicesService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private trusturl: DomSanitizer,
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  mul = 2;
  ngOnInit(): void {
    if (!this.userdata.isIndia()) {
      this.mul = 1;
    }
    this.getMyProfile()
    this.getPromoList();
  }
  getButton(key: any) {
    if (key.is_call) return 'phone_in_talk';
    else return 'chat';
  }
  getTitle(key: any) {
    if (key.is_call) return 'Call';
    else return 'Chat';
  }
  getPromoList() {
    this.spinner.show();
    this.orderService
      .getPromoOrderList({
        customerId: this.userdata.getCustomerId(),
        is_used: false,
        status: true,
      })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.dataList = res.data;
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  checkOnlineOffLine(key: any) {
    if (key.is_call) return key.astroId.is_call_active;
    else return key.astroId.is_chat_active;
  }

  convertSecondstoTime(val: any) {
    return `Promo Duration: ${(val / 60).toFixed(2)} Mins`;
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();
    var timeString;
    if (hours > 0) {
      timeString =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0') +
        ' Hrs.';
    } else {
      timeString =
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0') +
        ' Min.';
    }
    return timeString;
  }
  getPrice(key: any) {
    //console.log('price', key);
    if (key.is_call) return `${key.astroId.call_charges * this.mul}/Min.`;
    else return `${key.astroId.chat_charges * this.mul}/Min.`;
  }
  isOffer(key: any) {
    //console.log('is', key);
    if (key.is_call) return key.astroId.isCallOfferApplicable;
    else return key.astroId.isChatOfferApplicable;
  }
  getOffer(key: any) {
    //console.log('offer', key);
    if (key.is_call) return `${key.astroId.astroCallChargesWithOffer}/Min.`;
    else return `${key.astroId.astroChatChargesWithOffer}/Min.`;
  }
  isCallGoing: boolean = false;
  isChatGoing: boolean = false;
  isWaitingGoing: boolean = false;

  path = 'promo-astro-list-call';
  astroKey: any;
  @ViewChild("headerOne") headerOne: any;
  buttonAction(key: any) {
    this.astroKey = key;
    // console.log(this.astroKey)
    // return
    if (key.is_call) this.path = 'promo-astro-list-call';
    else this.path = 'promo-astro-list-chat';
    if (!this.userdata.isLogin()) {
      sessionStorage.setItem('admin-promo', 'yes');
      this.toast.error('Please login first');
      this.headerOne?.openFromAnotherComponent()
      return;
    } else if (this.userdata.getEmailVerify() == ('false' || false)) {
      sessionStorage.setItem('admin-promo', 'yes');
      this.toast.error('Please Verify Email');
      this.router.navigateByUrl('/verify-email-otp')
      return
    } 
    else if (!this.checkBalance()) {
      Swal.fire({
        title: 'Insufficient Balance',
        text: 'Minimum Amount required in wallet is ' + this.myProfile?.currencySymbol + ' ' + this.astroCharges,
        iconHtml: '<i class="fn-4 fa-solid fa-wallet text-primary border-0"></i>',
        confirmButtonText: 'Recharge Wallet',
        confirmButtonColor: '#19af0f'
      }).then((result:any) => {
        if(result.isConfirmed){
          sessionStorage.setItem('admin-promo', 'yes');
          this.router.navigateByUrl('/recharge-wallet')
        }
      })
      return
    }
    else{      
      this.placeOrder();
    }
  }
  isOfferApplicable = false;
  offerId = '';
  placeOrder() {
    this.spinner.hide();
    this.router.navigateByUrl('/order-list-person');
    this.dataService.startOrder({
      astroId: this.astroKey.astroId._id,
      endpoint: this.path,
      duration: this.astroKey.duration,
      paid: this.astroKey.isPaid,
      promoId: this.astroKey._id,
    });
  }

  /**Wallet currencycode */
  myProfile: any;
  getMyProfile() {
    this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
      (res: any) => {
        this.userdata.setProfileData(res.data)
        this.myProfile = res.data;
        this.userdata.setCurrentWallet(this.myProfile.current_wallet)
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  getCharges() {
    const key = this.astroKey.astroId;
    if(this.astroKey.is_call){
      var charges = 0;
      if (key.isCallOfferApplicable) {
        charges = key.astroCallChargesWithOffer;
      } else {
        charges = key.call_charges * this.mul;
      }
      return charges * (this.astroKey.duration / 60);
    }else{
      var charges = 0;
      if (key.isChatOfferApplicable) {
        charges = key.astroChatChargesWithOffer;
      } else {
        charges = key.chat_charges * this.mul;
      }
      return charges * (this.astroKey.duration / 60);
    }
  }

  astroCharges: number = 0
  checkBalance() {
    //this.spinner.show()
    const balance = this.myProfile.current_wallet;
    this.astroCharges = this.getCharges();
    // console.log(astroCharges, balance)
    //return
    if (this.astroCharges > balance) {
      return false
    } else {
      return true
    }
  }
  
}
