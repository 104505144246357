<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Panchang</h3>
            <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end 
        ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>Panchang</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<section class="container my-5">
    <div class="row gx-5">
        <div class="col-12 col-md-12 col-lg-12 my-2">
            <div class="row ">
                <div class=" col">
                    <div class="p-3 border-0 bg-red rounded">
                        <h6 class="fw-600 text-warning">Rashi : <span
                                class=" text-white">{{kundliResponse?.rasi?.name}}</span></h6>
                    </div>
                </div>
                <div class="col">
                    <div class="p-3 border-0 bg-warning rounded">
                        <h6 class="fw-600 txt-red"> <span class=" text-dark">{{region}}
                            </span>
                            ( {{ todaysDate }} )<label class="datepicker">
                                <span class="material-icons mt-1">
                                    calendar_month
                                    </span>
                                <input type="date" (change)="changeDate($event)" [(ngModel)]="datepicker"/>  
                              </label>
                        </h6>
                    </div>
                </div>
            </div>

            <div class="row my-2">
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm bg-light">
                        <h5 class="fw-600 text-dark">Tithi</h5>
                        <hr>
                        <h6 class="txt-red">{{kundliResponse?.tithi?.name}}</h6>
                        <p class="fw-500 small text-dark">{{kundliResponse?.tithi?.start}} -
                            {{kundliResponse?.tithi?.end}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm bg-light">
                        <h5 class=" fw-600 text-dark">Karan</h5>
                        <hr>
                        <h6 class="txt-red">{{kundliResponse?.karana?.name}}</h6>
                        <p class="fw-500 small text-dark">{{kundliResponse?.karana?.start}} -
                            {{kundliResponse?.karana?.end}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm bg-light">
                        <h5 class=" fw-600 text-dark">Yog</h5>
                        <hr>
                        <h6 class="txt-red">{{kundliResponse?.yoga?.name}}</h6>
                        <p class=" fw-500 small text-dark">{{kundliResponse?.yoga?.start}} -
                            {{kundliResponse?.yoga?.end}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm bg-light">
                        <h5 class="fw-600 text-dark">Nakshatra</h5>
                        <hr>
                        <h6 class="txt-red">{{kundliResponse?.nakshatra?.name}}</h6>
                        <p class=" fw-500 small text-dark">{{kundliResponse?.nakshatra?.start}} -
                            {{kundliResponse?.nakshatra?.end}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm ">
                        <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                <img src="../../../../assets/images/sunrise.png " /> Sunrise:</span>
                            {{kundliResponse?.advanced_details?.sun_rise}}
                        </h6>
                        <hr class="m-0">
                        <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                <img src="../../../../assets/images/sunset.png " /> Sunset:</span>
                            {{kundliResponse?.advanced_details?.sun_set}}
                        </h6>

                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1 ">
                    <div class="p-3 border rounded shadow-sm ">
                        <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                <img src="../../../../assets/images/moon-rise.png " /> Moonrise:</span>
                            {{kundliResponse?.advanced_details?.moon_rise}}
                        </h6>
                        <hr class="m-0">
                        <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                <img src="../../../../assets/images/moon-set.png " /> Moonset:</span>
                            {{kundliResponse?.advanced_details?.moon_set}}
                        </h6>

                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm bg-red">
                        <h5 class="fw-600 text-white">Rahukaal</h5>
                        <hr class="bg-white">
                        <p class=" small text-white">{{kundliResponse?.rahukaal}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border rounded shadow-sm bg-green">
                        <h5 class="fw-600 text-white">Gulika</h5>
                        <hr class="bg-white">
                        <p class=" small text-white">{{kundliResponse?.gulika}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3  border-white rounded bg-orange shadow-sm">
                        <h5 class="fw-600 text-white">Yamakanta</h5>
                        <hr class="bg-white">
                        <p class=" small text-white">{{kundliResponse?.yamakanta}}</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 col-lg-6 my-1">
                    <div class="p-3 border-white rounded shadow-sm bg-warning">
                        <h5 class=" fw-600 text-white">Ayanamsa</h5>
                        <hr class="bg-white
                                    ">
                        <p class=" small text-white">{{kundliResponse?.ayanamsa?.name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>