import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-order-list-person',
  templateUrl: './report-order-list-person.component.html',
  styleUrls: ['./report-order-list-person.component.css'],
})
export class ReportOrderListPersonComponent implements OnInit {
  peopleList: any = [];
  langList: any = [];
  orderObject: any = '';
  dataList: any = '';
  isAddNewPerson = false;
  isWait = false;
  approvednums = [];

  constructor(
    private userdata: UserdataService,
    private loginService: LoginService,
    private orderService: OrderServicesService,
    private openService: OpenApiService,
    private toast: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getMyPeople();
    this.orderObject = JSON.parse(this.userdata.getOrderObject() || '{}');
    if (this.orderObject.orderType === 'report') {
      this.isReportOrder = true;
    }
    this.getAstroProfile(this.orderObject.astroId);
    this.personForm.patchValue({ mobile: this.userdata.getPhone() });
    this.getCountries();
  }
  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => {},
    });
  }
  getMyPeople() {
    this.loginService
      .myPeople({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.peopleList = res.data;
          this.approvednums = res.approvednums;
        },
        (err) => {}
      );
  }

  getSinglePerson(id: any) {
    this.loginService.getSinglePerson({ _id: id }).subscribe(
      (res: any) => {
        this.dataList = res.data;
        if (!this.dataList.is_number_verified) {
          this.isMobileVerified = false;
        }
        this.updatePersonForm.patchValue({ _id: this.dataList._id });
        this.updatePersonForm.patchValue({ name: this.dataList.name });
        this.updatePersonForm.patchValue({ gender: this.dataList.gender });
        this.updatePersonForm.patchValue({ gender: this.dataList.gender });
        this.updatePersonForm.patchValue({ mobile: this.dataList.mobile });
        var dob = moment(this.dataList.dob, ['DD-MM-YYYY']).format(
          'YYYY-MM-DD'
        );
        this.updatePersonForm.patchValue({ dob: dob });
        var tob = moment(this.dataList.tob, ['hh:mm a']).format('HH:mm');
        this.updatePersonForm.patchValue({ tob: tob });
        // this.updatePersonForm.patchValue({dob: this.dataList.dob})
        // this.updatePersonForm.patchValue({tob: this.dataList.tob})
        this.updatePersonForm.patchValue({ city: this.dataList.city });
        this.updatePersonForm.patchValue({ state: this.dataList.state });
        this.updatePersonForm.patchValue({ country: this.dataList.country });
        this.updatePersonForm.patchValue({
          marital_status: this.dataList.marital_status,
        });
        this.updatePersonForm.patchValue({
          employed_in: this.dataList.employed_in,
        });
        this.updatePersonForm.patchValue({
          is_partner_details: this.dataList.is_partner_details,
        });
        this.updatePersonForm.patchValue({
          partner_name: this.dataList.partner_name,
        });
        var partner_dob = moment(this.dataList.partner_dob, [
          'DD-MM-YYYY',
        ]).format('YYYY-MM-DD');
        this.updatePersonForm.patchValue({ partner_dob: partner_dob });
        var partner_tob = moment(this.dataList.partner_tob, ['hh:mm a']).format(
          'HH:mm'
        );
        this.updatePersonForm.patchValue({ partner_tob: partner_tob });
        // this.updatePersonForm.patchValue({partner_dob: this.dataList.partner_dob})
        // this.updatePersonForm.patchValue({partner_tob: this.dataList.partner_tob})
        this.updatePersonForm.patchValue({
          partner_city: this.dataList.partner_city,
        });
        this.updatePersonForm.patchValue({
          partner_state: this.dataList.partner_state,
        });
        this.updatePersonForm.patchValue({
          partner_country: this.dataList.partner_country,
        });
        this.updatePersonForm.patchValue({
          is_number_verified: this.dataList.is_number_verified,
        });
        this.updatePersonForm.patchValue({
          country_code: this.dataList.country_code,
        });
      },
      (err) => {
        this.toast.error(err.error.message);
      }
    );
  }

  getAstroProfile(id: any) {
    this.openService.singleAstrologerDetails({ _id: id }).subscribe(
      (res: any) => {
        this.langList = res.data.lang;
      },
      (err) => {
        this.toast.error(err.error.message);
      }
    );
  }

  /**
   *
   * Report Order
   */
  isReportOrder = false;
  isPersonSelectedReport = false;

  reportForm = new FormGroup({
    customerId: new FormControl(''),
    astroId: new FormControl(''),
    personId: new FormControl(''),
    reportId: new FormControl(''),
    langId: new FormControl('', [Validators.required]),
    comment: new FormControl('', [Validators.required]),
    is_offer: new FormControl(''),
    offerId: new FormControl(''),
  });

  get r() {
    return this.reportForm.controls;
  }

  reportOrderSelected(id: any) {
    this.isAddNewPerson = false;
    this.isPersonSelectedReport = true;
    var orderObject = {
      customerId: this.userdata.getCustomerId(),
      astroId: this.orderObject.astroId,
      orderType: 'report',
      personId: id,
      reportId: this.orderObject.reportId,
      is_offer: this.orderObject.is_offer,
      offerId: this.orderObject.offerId,
    };
    sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
    this.getSinglePerson(id);
  }

  placeReportOrder() {
    this.spinner.show();
    /**
     * Check if user is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          if (res.data.is_current_call || res.data.is_current_chat) {
            Swal.fire(
              "Order can't be placed",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          var waitingAmount = res.data.waiting_amount;
          var currentWallet = res.data.current_wallet;
          var customerWalletAmount = currentWallet - waitingAmount;
          var astroReportCharges = this.langList.report_charges * 2;
          if (currentWallet < astroReportCharges) {
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error('Please Recharge to place order', 'Alert');
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          if (astroReportCharges > customerWalletAmount) {
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error('Please Recharge to place order', 'Alert');
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          /**
           * Place report order
           */
          var data = JSON.parse(this.userdata.getOrderObject() || '{}');
          //console.log(data)
          this.reportForm.patchValue({ customerId: data.customerId });
          this.reportForm.patchValue({ astroId: data.astroId });
          this.reportForm.patchValue({ personId: data.personId });
          this.reportForm.patchValue({ reportId: data.reportId });
          this.reportForm.patchValue({ is_offer: data.is_offer });
          this.reportForm.patchValue({ offerId: data.offerId });
          // console.log(this.reportForm.value,data.is_offer,data.offerId)
          // return
          this.orderService.placeReportOrders(this.reportForm.value).subscribe(
            (res: any) => {
              this.toast.success(res.message, 'Success');
              // sessionStorage.setItem("isPromo",'false')
              this.router.navigateByUrl('/report-order-list');
            },
            (err) => {
              this.toast.error(err.error.message, 'Alert');
            }
          );
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  showAddPersonForm() {
    this.isPersonSelectedReport = false;
    this.isAddNewPerson = true;
  }

  partnerDetails = false;

  onChange(event: any) {
    this.partnerDetails = !this.partnerDetails;
  }

  personForm = new FormGroup({
    customerId: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    gender: new FormControl('male', [Validators.required]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{10,}'),
    ]),
    dob: new FormControl('', [Validators.required]),
    tob: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    marital_status: new FormControl('', [Validators.required]),
    employed_in: new FormControl('', [Validators.required]),
    is_partner_details: new FormControl(false, [Validators.required]),
    partner_name: new FormControl(''),
    partner_dob: new FormControl(''),
    partner_tob: new FormControl(''),
    partner_city: new FormControl(''),
    partner_state: new FormControl(''),
    partner_country: new FormControl(''),
    detail_name: new FormControl(''),
    is_self_profile: new FormControl(true, [Validators.required]),
    is_number_verified: new FormControl(true, [Validators.required]),
    country_code: new FormControl('91'),
  });

  get p() {
    return this.personForm.controls;
  }

  addPerson() {
    var dob = moment(this.personForm.get('dob')?.value, ['YYYY-MM-DD']).format(
      'DD-MM-YYYY'
    );
    this.personForm.patchValue({ dob: dob });
    var tob = moment(this.personForm.get('tob')?.value, ['HH:mm']).format(
      'hh:mm a'
    );
    this.personForm.patchValue({ tob: tob });
    var partner_dob = moment(this.personForm.get('partner_dob')?.value, [
      'YYYY-MM-DD',
    ]).format('DD-MM-YYYY');
    this.personForm.patchValue({ partner_dob: partner_dob });
    var partner_tob = moment(this.personForm.get('partner_tob')?.value, [
      'HH:mm',
    ]).format('hh:mm a');
    this.personForm.patchValue({ partner_tob: partner_tob });
    if (
      this.approvednums.some(
        (data: any) => data.mobile === this.personForm.get('mobile')?.value
      )
    ) {
      this.personForm.patchValue({ is_number_verified: true });
    } else {
      this.personForm.patchValue({ is_number_verified: false });
    }
    this.personForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.loginService.addPeople(this.personForm.value, true).subscribe(
      (res: any) => {
        if (!res.message.is_number_verified) {
          this.isMobileVerified = false;
        }
        var orderObject;
        if (this.isReportOrder) {
          orderObject = {
            customerId: this.userdata.getCustomerId(),
            astroId: this.orderObject.astroId,
            orderType: 'report',
            personId: res.message._id,
            reportId: this.orderObject.reportId,
            is_offer: this.orderObject.is_offer,
            offerId: this.orderObject.offerId,
          };
          sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
          this.placeReportOrder();
        }
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Verify Person Mobile Number
   */
  isMobileVerified = true;

  /** update person profile */

  updatePersonForm = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    gender: new FormControl('male', [Validators.required]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{10,}'),
    ]),
    dob: new FormControl('', [Validators.required]),
    tob: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    marital_status: new FormControl('', [Validators.required]),
    employed_in: new FormControl('', [Validators.required]),
    is_partner_details: new FormControl(false, [Validators.required]),
    partner_name: new FormControl(''),
    partner_dob: new FormControl(''),
    partner_tob: new FormControl(''),
    partner_city: new FormControl(''),
    partner_state: new FormControl(''),
    partner_country: new FormControl(''),
    detail_name: new FormControl(''),
    is_number_verified: new FormControl(true, [Validators.required]),
    country_code: new FormControl('91'),
  });

  get u() {
    return this.updatePersonForm.controls;
  }

  onUpdatePersonHandler() {
    //console.log(this.personForm.get('tob').value)
    //return
    if (
      this.approvednums.some(
        (data: any) =>
          data.mobile === this.updatePersonForm.get('mobile')?.value
      )
    ) {
      this.updatePersonForm.patchValue({ is_number_verified: true });
    } else {
      this.updatePersonForm.patchValue({ is_number_verified: false });
    }
    var dob = moment(this.updatePersonForm.get('dob')?.value, [
      'YYYY-MM-DD',
    ]).format('DD-MM-YYYY');
    this.updatePersonForm.patchValue({ dob: dob });
    var tob = moment(this.updatePersonForm.get('tob')?.value, ['HH:mm']).format(
      'hh:mm a'
    );
    this.updatePersonForm.patchValue({ tob: tob });
    var partner_dob = moment(this.updatePersonForm.get('partner_dob')?.value, [
      'YYYY-MM-DD',
    ]).format('DD-MM-YYYY');
    this.updatePersonForm.patchValue({ partner_dob: partner_dob });
    var partner_tob = moment(this.updatePersonForm.get('partner_tob')?.value, [
      'HH:mm',
    ]).format('hh:mm a');
    this.updatePersonForm.patchValue({ partner_tob: partner_tob });
    this.loginService.updatePerson(this.updatePersonForm.value).subscribe(
      (res: any) => {
        if (!res.message.is_number_verified) {
          this.isMobileVerified = false;
        }
        var orderObject;
        if (this.isReportOrder) {
          orderObject = {
            customerId: this.userdata.getCustomerId(),
            astroId: this.orderObject.astroId,
            orderType: 'report',
            personId: res.message._id,
            is_offer: this.orderObject.is_offer,
            offerId: this.orderObject.offerId,
          };
          sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
          var dob = moment(this.updatePersonForm.get('dob')?.value, [
            'DD-MM-YYYY',
          ]).format('YYYY-MM-DD');
          this.updatePersonForm.patchValue({ dob: dob });
          var tob = moment(this.updatePersonForm.get('tob')?.value, [
            'hh:mm a',
          ]).format('HH:mm');
          this.updatePersonForm.patchValue({ tob: tob });
          var partner_dob = moment(
            this.updatePersonForm.get('partner_dob')?.value,
            ['DD-MM-YYYY']
          ).format('YYYY-MM-DD');
          this.updatePersonForm.patchValue({ partner_dob: partner_dob });
          var partner_tob = moment(
            this.updatePersonForm.get('partner_tob')?.value,
            ['hh:mm a']
          ).format('HH:mm');
          this.updatePersonForm.patchValue({ partner_tob: partner_tob });
          this.toast.success('Profile Updated Successfully', 'Success');
        }
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  updatePartnerDetails = false;
  onChangePartner(event: any) {
    this.updatePartnerDetails = !this.updatePartnerDetails;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji:any){
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }
}
