import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { defaultSingleBlogImage } from 'src/app/endpoints';
import { BlogsService } from 'src/app/service/blogs/blogs.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {
  BaseUrl: any;sectionTitle = 'Our Astrologers'
  constructor(
    private blogService: BlogsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any, private title: Title) {
    this.BaseUrl = _imageurl
  }

  id: any;
  blogDetails: any;
  relatedBlogs: any = [];
  latestBlogs: any = [];
  otherCategories: any;
  randomBlogs: any = [];
  featuredBlog: any = [];
  defaultSingleBlogImage = defaultSingleBlogImage;
  ngOnInit(): void {
    this.route.params.subscribe(paramMap =>{
      this.id = paramMap['id']
      this.getSingleBlogById()
    })
    
  }

  getSingleBlogById() {
    this.spinner.show()
    this.blogService.getSingleBlogById({ slug: this.id }).subscribe((res: any) => {

      this.title.setTitle(`Astro River - ${res.data?.blogTitle}`)

      // console.log('res is ', res)
      this.blogDetails = res.data;
      this.latestBlogs = res.latestBlogs
      this.featuredBlog = res.relatedBlogs[0];

      // console.log('featured blog is ', this.featuredBlog)

      res.relatedBlogs.splice(0,1);
      this.relatedBlogs = res.relatedBlogs
      this.otherCategories = res.otherCategories;
      this.randomBlogs = res.randomBlogs;
      this.spinner.hide()
    }, (err: any) => {
      this.spinner.hide()
      this.router.navigateByUrl('/blog-list')
    })
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  isSearch:boolean = false
  searchResult:any
  searchBlog(key: any) {
    this.isSearch = false
    if(key.length >= 2){
      this.isSearch = true
      this.blogService.getAllBlogs({isApproved:true,status:true, search: key }).subscribe(
        (res: any) => {
          this.searchResult = res.data;
        }, err => {
          
        }
      )
    }else{
      this.isSearch = false
    }
  }
  showSearchedBlog(key:any) {
    this.blogService.getSingleBlogById({ _id: key }).subscribe((res: any) => {
      this.blogDetails = res.data;
    }, (err: any) => {
      //console.log(err.message)
    })
  }
}
