<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Dashboard</h3>
            <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                </li>
                <li>
                    <i class="fn-2 material-icons-round bottom-align">
                        arrow_forward_ios
                    </i>
                </li>
                <li class="text-capitalize">Dashboard</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<!-- body container start here -->
<div class="container mt-5">
    <div class="row">
        <div class="col-md-3">
            <app-customer-sidebar></app-customer-sidebar>
        </div>
        <div class="col-md-9 ">
            <div class="bg-light p-3 d-flex flex-row search align-items-end justify-content-between">
                <div class="bg-green rounded py-2 px-4">
                    <h6 class="text-center text-white fn-0">Your Balance</h6>
                    <h4 class="fn-2 fw-500 text-white">
                        {{ walletBalance | currency: myProfile?.currencyCode }}
                    </h4>
                </div>
                <a (click)="goToRecharge()" class="shadow-0 btn cus-border-green p-0 py-2 m-0 px-3 mx-2 text-body">
                    Recharge
                </a>
            </div>
            <div class="">
                <div class="row">
                    <div class="col-12">
                        <h5 class="fw-600 text-capitalize mt-5">recent call orders</h5>
                        <table class="table table-sm table-bordered table-responsive-sm mt-2">
                            <thead>
                                <tr class="bg-warning">
                                    <th>CallID</th>
                                    <th>Astrologer</th>
                                    <th>Amount</th>
                                    <th>Duration</th>
                                    <th>Is Promo Call?</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="dataCallList.length > 0">
                                <tr *ngFor="let row of dataCallList.slice(0, 5)">
                                    <td><a
                                            [routerLink]="['/call-order-list/', row?.orderserviceId?._id]">{{row?.ocallId}}</a>
                                    </td>
                                    <td>{{ row?.astroId.name }}</td>
                                    <td>{{ row?.customerCurrencySymbol }}{{ row?.cost }}</td>
                                    <td>{{ convertSecondstoTime(row?.callDuration) }}</td>
                                    <td><span *ngIf="row?.is_promotional_call"
                                            class="badge bdg-txt-fw bg-success mr-2 py-2">PROMO</span><span
                                            *ngIf="row?.is_report_promo_call"
                                            class="badge bdg-txt-fw bg-success py-2 mt-1">REPORT PROMO</span></td>
                                    <td>{{ row?.order_current_status.toUpperCase() }}</td>
                                    <td>{{ row?.created_at | date: "medium" }}</td>
                                    <td> <a [routerLink]="['/call-order-list', row?.orderserviceId?._id]"
                                            class="btn btn-sm py-1 px-3 rounded shadow-0 bg-secondary text-white">Detail</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="dataCallList.length == 0">
                                <tr><td colspan="8">No Order Placed.</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12">
                        <h5 class="fw-600 text-capitalize mt-5">recent chat orders </h5>
                        <table class="table table-sm table-bordered table-responsive-sm mt-2">
                            <thead>
                                <tr class="bg-warning">
                                    <th>ChatID</th>
                                    <th>Astrologer</th>
                                    <th>Amount</th>
                                    <th>Duration</th>
                                    <th>Is Promo Call?</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="dataChatList.length > 0">
                                <tr *ngFor="let row of dataChatList.slice(0, 5)">
                                    <td><a
                                            [routerLink]="['/chat-order-list/', row?.orderserviceId?._id]">{{row?.ochatId}}</a>
                                    </td>
                                    <td>{{ row?.astroId.name }}</td>
                                    <td>{{ row?.customerCurrencySymbol }}{{ row?.cost }}</td>
                                    <td>{{ convertSecondstoTime(row?.chatDuration) }}</td>
                                    <td><span *ngIf="row?.is_promotional_call"
                                            class="badge bdg-txt-fw bg-success mr-2 py-2">PROMO</span><span
                                            *ngIf="row?.is_report_promo_call"
                                            class="badge bdg-txt-fw bg-success py-2 mt-1">REPORT PROMO</span></td>
                                    <td>{{ row?.order_current_status.toUpperCase() }}</td>
                                    <td>{{ row?.created_at | date: "medium" }}</td>
                                    <td> <a [routerLink]="['/chat-order-list', row?.orderserviceId?._id]"
                                            class="btn btn-sm py-1 px-3 rounded shadow-0 bg-secondary text-white">Detail</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="dataChatList.length == 0">
                                <tr><td colspan="8">No Order Placed.</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12">
                        <div class="position-relative mt-4">
                            <h5 class="fw-600 text-capitalize">daily horoscope</h5>
                            <!-- <a class="
                                nav-left
                                left
                                d-flex
                                justify-content-center
                                align-items-center
                                size-30
                                text-white
                                rounded-circle
                                position-absolute
                                border border-white
                                bg-dark
                              "><i class="fi fi-angle-left"></i></a>
                            <a class="
                                nav-right
                                d-flex
                                justify-content-center
                                align-items-center
                                size-30
                                text-white
                                rounded-circle
                                position-absolute
                                border border-white
                                bg-dark
                              "><i class="fi fi-angle-right"></i></a> -->
                            <swiper [config]="zodiacConfig">
                                <ng-template swiperSlide *ngFor="let sign of originalZodiaList">
                                    <div class="
                                    mt-4
                                    mx-3
                                    position-relative
                                    horoscope-bg
                                    p-4
                                    d-flex
                                    justify-content-end
                                  ">
                                        <a [routerLink]="['/daily-horoscope', sign.name]">
                                            <img [src]="getSanitizeUrl(sign.image)" class="
                                        bg-active
                                        p-2
                                        border border-white
                                        rounded-circle
                                        position-absolute
                                        horoscope-img
                                      " />
                                        </a>
                                        <div class="text-white">
                                            <h5 class="text-center text-white fw-500">
                                                {{ sign.name }}
                                            </h5>
                                            <span class="text-white">{{ sign.time }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </swiper>
                        </div>
                    </div>
                    <div class="col-12">
                        <h5 class="fw-600 text-capitalize mt-5">meet our astrologer</h5>
                        <div class="row">
                            <div class="my-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"
                                *ngFor="let key of dataList.slice(0, 6)">
                                <div class="w-100 card trans astro-box p-2 shadow">
                                    <div class="card-body bg-astro">
                                        <div class="row p-1">
                                            <img class="astro-img" [src]="getSanitizeUrl(key.profile_image)" />
                                            <span class="material-icons position-absolute mt-2"
                                                [ngClass]="key.is_call_active ? 'online' : 'offline'">
                                                fiber_manual_record
                                            </span>
                                            <div class="col-12 ml-5 pl-4 pr-5"
                                                style="top: -16%; right: -2%; font-size: 0.9rem">
                                                <span class="text-capitalize fw-600">{{
                                                    key.displayname ? key.displayname : key.name
                                                    }}</span>
                                                <p class="skill inactive-text">
                                                    <span class="text-capitalize" *ngFor="let skill of key.skill">{{
                                                        skill.skillId?.skillname
                                                        }}{{ key.skill.length > 1 ? ", " : "" }}</span>
                                                </p>
                                                <p class="inactive-text">
                                                    <strong>Exp:</strong> {{ key.experience }}
                                                    {{ key.experience > 1 ? "Years" : "Year" }}
                                                </p>
                                            </div>
                                        </div>
                                        <p class="mx-n2">
                                            <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astrologer_rating"
                                                [readonly]="true"></ngb-rating>

                                            <a [routerLink]="['/astrologer/', key._id]" style="float: right"><button
                                                    class="round-button" style="font-size: 0.75rem">
                                                    <i class="far fa-eye"></i>
                                                    View Profile
                                                </button></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>

<!-- body container end here -->

<app-footer></app-footer>