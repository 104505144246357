import { Location } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { razorpayKey } from 'src/app/endpoints';
import { LoginService } from 'src/app/service/login/login.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { WalletService } from 'src/app/service/wallet/wallet.service';
declare var Razorpay: any;

@Component({
  selector: 'app-chat-wallet-recharge',
  templateUrl: './chat-wallet-recharge.component.html',
  styleUrls: ['./chat-wallet-recharge.component.css'],
})
export class ChatWalletRechargeComponent implements OnInit {
  dataList: any = [];
  isAmountSelected = false;
  amount = 0;
  extra = 0;
  total = 0;
  tax = 0;
  currencyCode = 'INR';
  currencySymbol = '';
  extraPercentage = 0;
  isCustomAmount = false;
  previousUrl: any;
  currentUrl: any;
  id: any
  constructor(
    private walletService: WalletService,
    private userdata: UserdataService,
    private router: Router,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    private modalService: NgbModal,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.rechargeForm.patchValue({ currentchatserviceId: this.id });
    this.wallet = Number(this.userdata.getCurrentWallet() ?? '0');
    this.showRechargeAmount();
    this.previousUrl = this.userdata.getCurrentRoute();
    this.getMyProfile()
  }

  rechargeForm = new FormGroup({
    customerId: new FormControl(''),
    is_recharge_order: new FormControl(true),
    amount: new FormControl(''),
    gst_amount: new FormControl(''),
    final_amount: new FormControl(''),
    is_order_completed: new FormControl(true),
    txnId: new FormControl(''),
    is_coupon: new FormControl(''),
    percentage: new FormControl(''),
    is_auto: new FormControl(''),
    is_current_chat: new FormControl(true),
    currentchatserviceId: new FormControl(''),
  });

  get f() {
    return this.rechargeForm.controls;
  }

  showRechargeAmount() {
    this.walletService.showRechargeAmount({ status: true }).subscribe(
      (res: any) => {
        this.currencyCode = res.currencyCode;
        this.currencySymbol = res.currencySymbol;
        this.dataList = res.data;
      },
      (err) => { }
    );
  }

  selectedId = ''
  selectAmount(id: any, amount: any, extra: any) {
    this.selectedId = id
    this.isAmountSelected = true;
    this.isCustomAmount = false;
    this.amount = amount;
    this.extra = amount * (extra / 100);
    this.extra = Number(this.extra.toFixed(2));
    this.tax = amount * 0.18;
    this.tax = Number(this.tax.toFixed(2));
    if (!this.userdata.isIndia()) {
      this.tax = 0;
    }
    this.total = Number(this.amount) + Number(this.tax);
    this.total = Number(this.total.toFixed(2));
    this.extraPercentage = extra;
    this.rechargeForm.patchValue({ is_coupon: true });
    this.rechargeForm.patchValue({ is_auto: true });
    this.isCouponApplied = false
  }

  isError = false;
  checkCustomAmount(event: any) {
    this.isError = false;
    //var value = event.target.value;
    var value = event;
    if (
      value.match(/^\d+\.?\d*$/) == null ||
      typeof value.match(/^\d+\.?\d*$/) == 'undefined'
    ) {
      this.isError = true;
      return;
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.substring(0, value.length - 1);
    }
    this.selectCustomAmount(value);
  }

  selectCustomAmount(value: any) {
    this.selectedId = ''
    this.isCustomAmount = true;
    this.isAmountSelected = false;
    this.amount = value;
    this.extra = 0;
    this.tax = value * 0.18;
    this.tax = Number(this.tax.toFixed(2));
    if (!this.userdata.isIndia()) {
      this.tax = 0;
    }
    this.total = Number(this.amount) + Number(this.tax);
    this.total = Number(this.total.toFixed(2));
    this.extraPercentage = 0;
    this.rechargeForm.patchValue({ is_coupon: false });
    this.rechargeForm.patchValue({ is_auto: false });
    this.isCouponApplied = false
  }

  /** Wallet Top Bar */
  wallet = 0;
  myProfile: any;
  getMyProfile() {
    this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
      (res: any) => {
        this.userdata.setProfileData(res.data)
        this.myProfile = res.data;
        this.userdata.setCurrentWallet(this.myProfile.current_wallet)
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  options = {
    key: razorpayKey, // Enter the Key ID generated from the Dashboard
    amount: '', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: this.currencyCode,
    name: this.userdata.getName(),
    description: 'Using AstroRiver Service',
    order_id: '', //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: (res: any) => {
      this.spinner.show();
      this.rechargeForm.patchValue({
        customerId: this.userdata.getCustomerId(),
      });
      this.rechargeForm.patchValue({ amount: this.amount });
      this.rechargeForm.patchValue({ gst_amount: this.tax });
      this.rechargeForm.patchValue({ final_amount: this.total });
      this.rechargeForm.patchValue({ txnId: res.razorpay_payment_id });
      this.rechargeForm.patchValue({ percentage: this.extraPercentage });
      this.walletService.rechargeAmount(this.rechargeForm.value).subscribe(
        (res: any) => {
          this.walletService
            .myWalletHistory({ customerId: this.userdata.getCustomerId() })
            .subscribe((res: any) => {
              localStorage.setItem('currentWallet', res.customerwallet);
            });
          this.toast.success(res.message, 'Success');
          //this.location.back()
          /** check if previous url is avilable or not */
          let call = sessionStorage.getItem('call');
          if (call !== null) {
            sessionStorage.removeItem('call');
            this.router.navigate(['/astrologer', call]);
          }
          if (
            this.previousUrl !== null ||
            typeof this.previousUrl !== 'undefined'
          ) {
            this.router.navigateByUrl(this.previousUrl);
            this.userdata.removeCurrentRoute();
          }
          this.router.navigateByUrl('/wallet');
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
    },
    prefill: {
      name: this.userdata.getName(),
      email: this.userdata.getEmail(),
      contact: this.userdata.getPhone(),
    },
  };

  rzp1: any;

  rechargeAmount() {
    if (this.isCustomAmount && this.isError) {
      this.toast.error('Please Enter Valid Amount', 'Alert');
      return;
    }
    // call api to create order_id
    this.walletService
      .razorpayOrderId({ amount: this.total, currency: this.currencyCode })
      .subscribe(
        (res: any) => {
          this.payWithRazor(res?.value?.id, res?.key, res?.value?.amount)
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Error')
        }
      );
  }

  razorPayObj: any;

  payWithRazor(order_id: any, key: any, amount: any) {
    const options: any = {
      key: key,
      amount: amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: this.currencyCode,
      name: this.userdata.getName(), // company name or product name
      description: 'Using AstroRiver Service',  // product description
      order_id: order_id, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      // theme: {
      //   color: '#0c238a'
      // }
    };
    options.handler = ((response: any, error: any) => {
      this.rechargeForm.patchValue({ txnId: response.razorpay_payment_id });
      this.confirmOrder()
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      //console.log('Transaction cancelled.');
    });
    const rzp = new Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', ((response: any) => {
      //if payment is unsuccessful
    }));
  }

  confirmOrder() {
    this.spinner.show()
    this.rechargeForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });
    this.rechargeForm.patchValue({ amount: this.amount });
    this.rechargeForm.patchValue({ gst_amount: this.tax });
    this.rechargeForm.patchValue({ final_amount: this.total });
    this.rechargeForm.patchValue({ percentage: this.extraPercentage });
    this.walletService.rechargeAmount(this.rechargeForm.value).subscribe(
      (res: any) => {
        this.walletService.myWalletHistory({ customerId: this.userdata.getCustomerId() })
          .subscribe((res: any) => {
            localStorage.setItem('currentWallet', res.customerwallet);
          });
        this.toast.success(res.message, 'Success');
        this.zone.run(() => {
          this.router.navigateByUrl('/chat-order-list/'+this.id);
        });
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /** Apply Coupon */
  isCouponApplied: boolean = false
  couponCashBack = 0;
  couponCode = '';
  open(content: any) {
    this.modalService.open(content)
  }

  applyCoupon(val: any) {
    if (val.length > 0) {
      var data: any = {
        is_coupon: true,
        couponcode: val,
        is_auto: false,
        customerId: this.userdata.getCustomerId(),
        amount: this.amount
      }
      this.walletService.checkIsCouponApplicable(data).subscribe(
        (res: any) => {
          if (res.success) {
            this.couponCashBack = res.couponCashback
            this.couponCode = val
            this.rechargeForm.patchValue({ is_coupon: true });
            this.rechargeForm.patchValue({ is_auto: false });
            this.rechargeForm.patchValue({ couponcode: val });
            this.isCouponApplied = true
            this.modalService.dismissAll()
          } else {
            this.toast.error(res.message, 'Error')
          }
        }, (err: any) => {
          this.toast.error(err.error.message, 'Error')
        }
      )
    }
  }

  removeCoupon() {
    this.isCouponApplied = false
    this.rechargeForm.patchValue({ is_coupon: false });
    this.rechargeForm.patchValue({ couponcode: '' });
  }

  changeCustomAmount(val: any) {
    if (val == '' || val == 0) {
      this.isAmountSelected = false;
      this.isCustomAmount = false;
      this.selectedId = ''
    }
  }
}
