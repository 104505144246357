import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-zodiac',
  templateUrl: './zodiac.component.html',
  styleUrls: ['./zodiac.component.css']
})
export class ZodiacComponent implements OnInit {

  BaseUrl = '';dataList:any = []
  constructor(
    private openService: OpenApiService,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,@Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
    }

  ngOnInit(): void {
    this.getZodiac()
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  getZodiac(){
    this.spinner.show()
    this.openService.dailyHoroscopeZodiac({}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }
}
