import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IssueService } from 'src/app/service/issue/issue.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { ScrollToBottomDirective } from '../../ongoing-chat-customer/scroll-to-bottom.directive';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.css'],
})
export class SupportDialogComponent implements OnInit {
  @Input() supportId = '';
  @ViewChild(ScrollToBottomDirective)
  scroll: any;
  constructor(
    private formBuilder: FormBuilder,
    private userdata: UserdataService,
    private issueService: IssueService
  ) {}
  chatForm = this.formBuilder.group({
    customerId: ['', [Validators.required]],
    is_customer: true,
    is_reply_by_customer: true,
    _id: '',
    support_title: ['', [Validators.required]],
    support_description: ['', [Validators.required]],
    reply_by_system: ['', [Validators.required]],
  });
  ngOnInit(): void {
    this.supportId = localStorage.getItem('supportID') ?? '';
    if (this.supportId.length > 0) this.getChat();
    this.chatForm.patchValue({ customerId: this.userdata.getCustomerId() });
  }
  chatList = new Array();
  isTitle = false;
  isIssueSolved=false;
  getChat(): void {
    this.issueService
      .getSupportChat({
        customerId: this.userdata.getCustomerId(),
        _id: this.supportId,
      })
      .subscribe({
        next: (v) => {
          //console.log(v)
          this.isIssueSolved = v.data.is_issue_solved
          this.chatForm.patchValue({ _id: this.supportId });
          this.chatList = v.data.replylogs;
          this.isTitle = true;
          this.chatForm.patchValue({ support_title: v.data.support_title });
        },
        error: (err) => {
          //console.log('err', err);
        },
      });
  }
  onTypeChange(event: any) {
    this.chatForm.patchValue({ reply_by_system: event.target.value });
  }
  sendMsg() {
    this.issueService.addSupport(this.chatForm.value, !this.isTitle).subscribe({
      next: (v) => {
        this.chatForm.patchValue({ reply_by_system: '' });
        this.chatForm.patchValue({ support_description: '' });
        if (this.isTitle) this.chatList = v.data.replylogs;
        else console.log('support', v);
      },
      error: (error) => {
        //console.log('err', error);
      },
    });
  }
}
