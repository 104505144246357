<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Blog Categories</h3>
            <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>Blog Categories</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<!-- hs sidebar Start -->


<section>

    <div class="container my-4">
        <h2 class="font-weight-bold mb-3"> {{name}}</h2>
        <div class="row gx-5 my-2" *ngIf = "categoryWiseBlog.length > 0">
            <div class="col-md-4 mb-4" *ngFor="let categoryBlog of categoryWiseBlog">
                <div class="card shadow-sm">
                    <img [src]="getSanitizeUrl(categoryBlog?.blogImage)"
                        class="card-img-top bc-height bg-light p-2 " alt="...">
                    <div class="card-body">
                        <h5 class="card-title">{{categoryBlog?.blogTitle}}</h5>
                        <p class = "text-muted small">{{categoryBlog?.userName}} / {{categoryBlog?.created_at | date: 'dd-MM-yyyy'}}</p>
                        <p class="card-text line-size-3 my-1" [innerHTML] = "categoryBlog?.blogsmallDescription"></p>
                        <!-- <p class="card-text my-1" [innerHTML] = "categoryBlog?.blogsmallDescription"></p> -->
                        <a [routerLink]="['/blog', categoryBlog?.slug]" class="btn btn-success my-2 btn-block">View Details</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row gx-5 my-2 mt-4" *ngIf = "categoryWiseBlog.length == 0">
            <h3>No Blogs Yet.</h3>
        </div>

    </div>
</section>

<app-footer></app-footer>
<!-- hs footer wrapper End -->