import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from 'src/app/service/booking/booking.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';
declare var Razorpay: any;

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.css']
})
export class CartDetailsComponent implements OnInit {
  dataList: any = [];
  BaseUrl: any;
  code: any;
  cur: any = 'INR';

  constructor(private spinner: NgxSpinnerService, private toast: ToastrService, private router: Router, private orderService: OrderServicesService, private userdata: UserdataService, private booking: BookingService, private modalService: NgbModal, private openService: OpenApiService, private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl: any) { this.BaseUrl = _imageurl; }

  ngOnInit(): void {
    this.code = this.userdata.isIndia();
    this.getCartDetail();
    if (this.code) {
      this.cur = 'INR'
    } else {
      this.cur = "USD"
    }
  }

  /** Get cart */
  getCartDetail() {
    this.spinner.show();
    this.orderService.getMyCart({ customerId: this.userdata.getCustomerId() }).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.dataList = res.data;
        if (this.dataList?.addressId != null) {
          this.isAddressSelected = true
          this.selectedAddress = this.dataList?.addressId
        }
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }


  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  /** Remove Product From Cart */
  removeProductFromCart(_id: string) {
    Swal.fire({
      title: 'Remove Product From Cart!',
      text: 'Are you sure, you want to remove this product from cart?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.orderService.removeProductFromCart({ customerId: this.userdata.getCustomerId(), orderProductId: _id }).subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res.success) {
              this.getCartDetail()
              this.toast.success(res.message, 'Success')
            } else {
              this.toast.error(res.message, 'Alert')
            }
          },
          (err: any) => {
            this.spinner.hide();
            this.toast.error(err.error.message, 'Alert');
          }
        );
      }
    });
  }

  /** Increase Decrease Quantity Of Product In Cart */
  inceDecQuantity(data: any, isIncrease: boolean) {
    //console.log(data?.userDetails?.quantity, isIncrease)
    if ((data?.userDetails?.quantity > 1 && !isIncrease) || isIncrease) {
      this.spinner.show();
      this.orderService.changeProductQtyInCart({ customerId: this.userdata.getCustomerId(), orderProductId: data?._id, isIncrease: isIncrease }).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.success) {
            this.getCartDetail()
            this.toast.success(res.message, 'Success')
          } else {
            this.toast.error(res.message, 'Alert')
          }
        },
        (err: any) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
    }
  }

  /** Place Order */
  rzp1: any;
  placeOrder() {
    // call api to create order_id
    this.booking
      .razorPay({ amount: (this.dataList.grandTotal).toFixed(2), isProductOrder: true })
      .subscribe(
        (res: any) => {
          this.payWithRazor(res?.value?.id, res?.key)
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert')
        }
      );

  }
  razorPayObj: any;

  payWithRazor(order_id: any, key: any) {
    const options: any = {
      key: key,
      amount: this.dataList.grandTotal * 100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: this.cur,
      name: this.userdata.getName(), // company name or product name
      description: 'Using AstroRiver Service',  // product description
      order_id: order_id, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      // theme: {
      //   color: '#0c238a'
      // }
    };
    options.handler = ((response: any, error: any) => {
      this.razorPayObj = {
        customerId: this.userdata.getCustomerId(),
        transactionId: response?.razorpay_payment_id,
        isOrderPaid: true
      }
      // options.response = response;
      // console.log(response);
      // console.log(options);
      // call your backend api to verify payment signature & capture transaction
      this.confirmOrder()
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      //console.log('Transaction cancelled.');
    });
    const rzp = new Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', ((response: any) => {
      // console.log(response.error.code,response.error.description,response.error.source,response.error.step,response.error.reason,response.error.metadata.order_id,response.error.metadata.payment_id);
      this.razorPayObj = {
        customerId: this.userdata.getCustomerId(),
        transactionId: response.error.metadata.payment_id,
        isOrderPaid: false
      }
      this.confirmOrder()
    }));
  }

  confirmOrder() {
    this.spinner.show();
    this.orderService.placeCartOrder(this.razorPayObj).subscribe(
      (res: any) => {
        if (res.success) {
          if (this.razorPayObj.isOrderPaid) {
            this.toast.success(res.message, 'Success');
            this.router.navigateByUrl('/store-order-list');
          }
        } else {
          this.toast.error(res.message, 'Alert')
        }
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**AddOnPopUp */
  addOnData: any;
  openAddOnPopUp(content: any, addOns: any) {
    this.addOnData = addOns
    this.modalService.open(content);
  }

  /** Change address popup */
  addressList: any = [];
  isSelectAddress: boolean = true;
  isAddNewAddress: boolean = false;
  isUpdateAddress: boolean = false;
  isAddressSelected: boolean = false
  selectedAddress: any = '';
  changeAddressObj: any = {}

  addAddressForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    customerId: new FormControl(''),
    countryCodePhoneNum: new FormControl('91', Validators.required),
    phoneNum: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{7,10}'),
    ]),
    countryCodeAltPhoneNum: new FormControl('91'),
    alternatePhoneNum: new FormControl(
      '',
      Validators.pattern('[0-9]{7,10}')
    ),
    flatNo: new FormControl('', Validators.required),
    locality: new FormControl('', Validators.required),
    landmark: new FormControl(''),
    city: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    state: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    country: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    pinCode: new FormControl('', [
      Validators.required
    ]),
  });

  updateAddressForm: FormGroup = new FormGroup({
    _id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    customerId: new FormControl(''),
    countryCodePhoneNum: new FormControl('91', Validators.required),
    phoneNum: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{7,10}'),
    ]),
    countryCodeAltPhoneNum: new FormControl('91'),
    alternatePhoneNum: new FormControl(
      '',
      Validators.pattern('[0-9]{7,10}')
    ),
    flatNo: new FormControl('', Validators.required),
    locality: new FormControl('', Validators.required),
    landmark: new FormControl(''),
    city: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    state: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    country: new FormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]{3,}'),
    ]),
    pinCode: new FormControl('', [
      Validators.required
    ]),
  });

  get a() {
    return this.addAddressForm.controls;
  }

  get u() {
    return this.updateAddressForm.controls;
  }
  openAddressPopup(content: any) {
    this.getCountries()
    this.isSelectAddress = true;
    this.isAddNewAddress = !this.isSelectAddress;
    this.isUpdateAddress = !this.isSelectAddress;
    this.getMyAddressList();
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      scrollable: true,
    });
  }

  addressBackPage() {
    this.isSelectAddress = true;
    this.isAddNewAddress = !this.isSelectAddress;
    this.isUpdateAddress = !this.isSelectAddress;
  }

  getMyAddressList() {
    this.spinner.show();
    this.booking
      .getAddressesList({ customerId: this.userdata.getId(), status: true })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.addressList = res.result;
          //console.log(this.addressList);
        },
        (err: any) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  showAddNewAddressForm() {
    this.isAddNewAddress = true;
    this.isSelectAddress = !this.isAddNewAddress;
    this.isUpdateAddress = !this.isAddNewAddress;
  }

  selectAddress(data: any) {
    this.isAddressSelected = true;
    this.changeAddressObj = { addressId: data._id, customerId: this.userdata.getCustomerId() }
    this.changeAddressInCart()
    this.selectedAddress = data;
    this.modalService.dismissAll();
  }

  saveAddress() {
    this.spinner.show();
    this.addAddressForm.patchValue({ customerId: this.userdata.getId() });
    this.booking.addAddress(this.addAddressForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
        this.getMyAddressList();
        this.isSelectAddress = true;
        this.isAddNewAddress = !this.isSelectAddress;
        this.isUpdateAddress = !this.isSelectAddress;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  deleteAddress(id: any) {
    Swal.fire({
      title: 'Delete Address!',
      text: 'Are you sure, you want to delete this address?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.booking.deleteAddress({ status: false, _id: id }).subscribe(
          (res: any) => {
            this.spinner.hide();
            this.toast.success(res.message, 'Success');
            this.getMyAddressList();
          },
          (err: any) => {
            this.spinner.hide();
            this.toast.error(err.error.message, 'Alert');
          }
        );
      }
    });
  }

  showUpdateAddressForm(address: any) {
    this.isUpdateAddress = true;
    this.isSelectAddress = !this.isUpdateAddress;
    this.isAddNewAddress = !this.isUpdateAddress;
    this.updateAddressForm.patchValue({ _id: address._id });
    this.updateAddressForm.patchValue({ name: address.name });
    this.updateAddressForm.patchValue({ customerId: this.userdata.getId() });
    this.updateAddressForm.patchValue({
      countryCodePhoneNum: address.countryCodePhoneNum,
    });
    this.updateAddressForm.patchValue({ phoneNum: address.phoneNum });
    this.updateAddressForm.patchValue({
      countryCodeAltPhoneNum: address.countryCodeAltPhoneNum,
    });
    this.updateAddressForm.patchValue({
      alternatePhoneNum: address.alternatePhoneNum,
    });
    this.updateAddressForm.patchValue({ flatNo: address.flatNo });
    this.updateAddressForm.patchValue({ locality: address.locality });
    this.updateAddressForm.patchValue({ landmark: address.landmark });
    this.updateAddressForm.patchValue({ city: address.city });
    this.updateAddressForm.patchValue({ state: address.state });
    this.updateAddressForm.patchValue({ country: address.country });
    this.updateAddressForm.patchValue({ pinCode: address.pinCode });
  }

  updateAddress() {
    this.spinner.show();
    this.booking.updateAddress(this.updateAddressForm.value).subscribe(
      (res: any) => {
        this.isSelectAddress = true;
        this.isAddNewAddress = !this.isSelectAddress;
        this.isUpdateAddress = !this.isSelectAddress;
        this.getMyAddressList();
        this.spinner.hide();
        this.toast.success(res.message, 'Success');
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }

  changeAddressInCart() {
    this.spinner.show();
    this.orderService.changeAddressInCart(this.changeAddressObj).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.success) {
          this.getCartDetail()
          this.toast.success(res.message, 'Success')
        } else {
          this.toast.error(res.message, 'Alert')
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /** Apply Coupon */
  applyCoupon() {
    var couponCode = (<HTMLInputElement>document.getElementById(`couponCode`)).value
    this.spinner.show();
    this.orderService.applyCouponOnCart({ customerId: this.userdata.getCustomerId(), couponCode: couponCode }).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.success) {
          this.getCartDetail()
          this.toast.success(res.message, 'Success')
        } else {
          this.toast.error(res.message, 'Alert')
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  removeCoupon() {
    this.spinner.show();
    this.orderService.removeCouponFromCart({ customerId: this.userdata.getCustomerId() }).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.success) {
          this.getCartDetail()
          this.toast.success(res.message, 'Success')
        } else {
          this.toast.error(res.message, 'Alert')
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji:any){
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }

}
