import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat-order-list-person',
  templateUrl: './chat-order-list-person.component.html',
  styleUrls: ['./chat-order-list-person.component.css'],
})
export class ChatOrderListPersonComponent implements OnInit {
  peopleList: any = [];
  langList: any = [];
  orderObject: any = '';
  dataList: any = '';
  isAddNewPerson = false;
  isWait = false;
  approvednums = [];
  astroData: any = '';
  today: any;
  constructor(
    private userdata: UserdataService,
    private loginService: LoginService,
    private orderService: OrderServicesService,
    private openService: OpenApiService,
    private toast: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.today = moment().format('YYYY-MM-DD');
    this.getMyPeople();
    this.orderObject = JSON.parse(this.userdata.getOrderObject() || '{}');
    if (this.orderObject.orderType === 'chat') {
      this.isChatOrder = true;
      if (this.orderObject.orderCheck === 'wait') {
        this.isWait = true;
      }
      this.getAstroProfile(this.orderObject.astroId);
    }
    if (this.orderObject.orderType === 'chatPromo') {
      this.isChatPromo = true;
    }
    this.personForm.patchValue({ mobile: this.userdata.getPhone() });
    this.getCountries();
  }
  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }
  selectedRadio: any;
  getMyPeople() {
    this.loginService
      .myPeople({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.peopleList = res.data;
          this.approvednums = res.approvednums;
        },
        (err: any) => { }
      );
  }

  getSinglePerson(id: any) {
    this.loginService.getSinglePerson({ _id: id }).subscribe(
      (res: any) => {
        this.dataList = res.data;
        if (!this.dataList.is_number_verified) {
          this.isMobileVerified = false;
        }
        this.updatePersonForm.patchValue({ _id: this.dataList._id });
        this.updatePersonForm.patchValue({ name: this.dataList.name });
        this.updatePersonForm.patchValue({ gender: this.dataList.gender });
        this.updatePersonForm.patchValue({ gender: this.dataList.gender });
        this.updatePersonForm.patchValue({ mobile: this.dataList.mobile });
        var dob = moment(this.dataList.dob, ['DD-MM-YYYY']).format(
          'YYYY-MM-DD'
        );
        this.updatePersonForm.patchValue({ dob: dob });
        var tob = moment(this.dataList.tob, ['hh:mm a']).format('HH:mm');
        this.updatePersonForm.patchValue({ tob: tob });
        // this.updatePersonForm.patchValue({ dob: this.dataList.dob })
        //this.updatePersonForm.patchValue({ tob: this.dataList.tob })
        this.updatePersonForm.patchValue({ city: this.dataList.city });
        this.updatePersonForm.patchValue({ state: this.dataList.state });
        this.updatePersonForm.patchValue({ country: this.dataList.country });
        this.updatePersonForm.patchValue({
          marital_status: this.dataList.marital_status,
        });
        this.updatePersonForm.patchValue({
          employed_in: this.dataList.employed_in,
        });
        this.updatePersonForm.patchValue({
          is_partner_details: this.dataList.is_partner_details,
        });
        this.updatePersonForm.patchValue({
          partner_name: this.dataList.partner_name,
        });
        var partner_dob = moment(this.dataList.partner_dob, [
          'DD-MM-YYYY',
        ]).format('YYYY-MM-DD');
        this.updatePersonForm.patchValue({ partner_dob: partner_dob });
        var partner_tob = moment(this.dataList.partner_tob, ['hh:mm a']).format(
          'HH:mm'
        );
        this.updatePersonForm.patchValue({ partner_tob: partner_tob });
        // this.updatePersonForm.patchValue({ partner_dob: this.dataList.partner_dob })
        // this.updatePersonForm.patchValue({ partner_tob: this.dataList.partner_tob })
        this.updatePersonForm.patchValue({
          partner_city: this.dataList.partner_city,
        });
        this.updatePersonForm.patchValue({
          partner_state: this.dataList.partner_state,
        });
        this.updatePersonForm.patchValue({
          partner_country: this.dataList.partner_country,
        });
        this.updatePersonForm.patchValue({
          is_number_verified: this.dataList.is_number_verified,
        });
        this.updatePersonForm.patchValue({
          country_code: this.dataList.country_code,
        });
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  getAstroProfile(id: any) {
    this.openService.singleAstrologerDetails({ _id: id }).subscribe(
      (res: any) => {
        this.astroData = res.data;
        this.langList = res.data.lang;
        if ((this.isWait = true)) {
          if (
            this.astroData.is_chat_active &&
            !this.astroData.is_current_call_going &&
            !this.astroData.is_current_chat_going
          ) {
            this.isWait = false;
          }
        }
      },
      (err: any) => {
        this.toast.error(err.error.message);
      }
    );
  }

  showAddPersonForm() {
    this.isPersonSelectedChat = false;
    this.isAddNewPerson = true;
  }

  partnerDetails = false;

  onChange(event: any) {
    this.partnerDetails = !this.partnerDetails;
  }

  personForm = new FormGroup({
    customerId: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    gender: new FormControl('male', [Validators.required]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{10,}'),
    ]),
    dob: new FormControl('', [Validators.required]),
    tob: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    marital_status: new FormControl('', [Validators.required]),
    employed_in: new FormControl('', [Validators.required]),
    is_partner_details: new FormControl(false, [Validators.required]),
    partner_name: new FormControl(''),
    partner_dob: new FormControl(''),
    partner_tob: new FormControl(''),
    partner_city: new FormControl(''),
    partner_state: new FormControl(''),
    partner_country: new FormControl(''),
    detail_name: new FormControl(''),
    is_self_profile: new FormControl(true, [Validators.required]),
    is_number_verified: new FormControl(true, [Validators.required]),
    country_code: new FormControl('91'),
  });

  get p() {
    return this.personForm.controls;
  }

  addPerson() {
    // if(this.personForm.get('mobile')?.value !== this.userdata.getPhone()){
    //   this.personForm.patchValue({is_number_verified:false})
    // }
    var dob = moment(this.personForm.get('dob')?.value, ['YYYY-MM-DD']).format(
      'DD-MM-YYYY'
    );
    this.personForm.patchValue({ dob: dob });
    var tob = moment(this.personForm.get('tob')?.value, ['HH:mm']).format(
      'hh:mm a'
    );
    this.personForm.patchValue({ tob: tob });
    var partner_dob = moment(this.personForm.get('partner_dob')?.value, [
      'YYYY-MM-DD',
    ]).format('DD-MM-YYYY');
    this.personForm.patchValue({ partner_dob: partner_dob });
    var partner_tob = moment(this.personForm.get('partner_tob')?.value, [
      'HH:mm',
    ]).format('hh:mm a');
    this.personForm.patchValue({ partner_tob: partner_tob });
    if (
      this.approvednums.some(
        (data: any) => data.mobile === this.personForm.get('mobile')?.value
      )
    ) {
      this.personForm.patchValue({ is_number_verified: true });
    } else {
      this.personForm.patchValue({ is_number_verified: false });
    }
    this.personForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.loginService.addPeople(this.personForm.value, true).subscribe(
      (res: any) => {
        if (!res.message.is_number_verified) {
          this.isMobileVerified = false;
        }
        var orderObject;
        if (this.isChatOrder) {
          orderObject = {
            customerId: this.userdata.getCustomerId(),
            astroId: this.orderObject.astroId,
            orderType: 'chat',
            personId: res.message._id,
            is_offer: this.orderObject.is_offer,
            offerId: this.orderObject.offerId,
          };
          sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
          if (this.isWait) {
            this.waitForm.patchValue({ is_chat: true });
            this.waitForm.patchValue({ is_call: false });
            this.placeWaitOrder();
          } else {
            this.placeChatOrder();
          }
        }
        if (this.isChatPromo) {
          orderObject = {
            customerId: this.userdata.getCustomerId(),
            orderType: 'chatPromo',
            personId: res.message._id,
          };
          sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
          this.placeChatPromoOrder();
        }
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Place Wait Order
   */
  placeWaitOrder() {
    this.spinner.show();
    if (this.isChatOrder) {
      if (this.isWait) {
        this.waitForm.patchValue({ is_chat: true });
        this.waitForm.patchValue({ is_call: false });
      }
      /**
       * Check if user is busy
       */
      this.loginService
        .myProfile({ _id: this.userdata.getCustomerId() })
        .subscribe(
          (res: any) => {
            this.userdata.setProfileData(res.data)
            // if (res.data.is_waiting) {
            //   this.spinner.hide();
            //   Swal.fire(
            //     'Unable to place chat-wait order.',
            //     "You have already joined an Astrologer's waiting list.",
            //     'error'
            //   );
            //   return;
            // }
            if (res.data.is_current_call || res.data.is_current_chat) {
              this.spinner.hide();
              Swal.fire(
                "Order can't be placed",
                'Complete your ongoing order to place another one',
                'info'
              );
              return;
            }
            /*
             * Check if wallet is recharged after detucting reserved amount
             */
            var charges = 0;
            if (
              this.astroData.astroofferId == null ||
              !this.astroData.astroofferId?.is_chat_offer_activate ||
              this.astroData.chatofferis == null
            ) {
              charges = this.astroData.chat_charges * 2;
            } else {
              if (this.astroData.isnewforastro) {
                if (
                  this.astroData.chatofferis.user_type == 1 ||
                  this.astroData.chatofferis.user_type == 3
                ) {
                  charges =
                    this.astroData.chat_charges * 2 -
                    this.astroData.chat_charges *
                    2 *
                    (this.astroData.chatofferis.offer_discount / 100);
                } else {
                  charges = this.astroData.chat_charges * 2;
                }
              } else {
                if (
                  this.astroData.chatofferis.user_type == 1 ||
                  this.astroData.chatofferis.user_type == 2
                ) {
                  charges =
                    this.astroData.chat_charges * 2 -
                    this.astroData.chat_charges *
                    2 *
                    (this.astroData.chatofferis.offer_discount / 100);
                } else {
                  charges = this.astroData.chat_charges * 2;
                }
              }
            }
            var waitingAmount = res.data.waiting_amount;
            var currentWallet = res.data.current_wallet;
            var customerWalletAmount = currentWallet - waitingAmount;
            var astroCharges = charges * 5; //05-08-2021
            if (currentWallet < astroCharges) {
              this.spinner.hide();
              this.userdata.setCurrentRoute(this.router.url);
              this.toast.error('Please Recharge to place order', 'Alert');
              this.router.navigateByUrl('/recharge-wallet');
              return;
            }
            if (astroCharges > customerWalletAmount) {
              this.spinner.hide();
              this.userdata.setCurrentRoute(this.router.url);
              this.toast.error('Please Recharge to place order', 'Alert');
              this.router.navigateByUrl('/recharge-wallet');
              return;
            }
            /**
             * Place Chat Promo Order
             */
            var data = JSON.parse(this.userdata.getOrderObject() || '{}');
            this.waitForm.patchValue({ customerId: data.customerId });
            this.waitForm.patchValue({ astroId: data.astroId });
            this.waitForm.patchValue({ personId: data.personId });
            this.openService
              .singleAstrologerDetails({ _id: data.astroId })
              .subscribe(
                (res: any) => {
                  this.waitForm.patchValue({
                    is_current_active: res.data.is_current_active,
                  });
                  this.orderService
                    .placeWaitOrders(this.waitForm.value)
                    .subscribe(
                      (res: any) => {
                        this.spinner.hide();
                        this.toast.success(res.message, 'Success');
                        this.router.navigateByUrl('/wait-list');
                      },
                      (err: any) => {
                        this.spinner.hide();
                        this.toast.error(err.error.message, 'Alert');
                      }
                    );
                },
                (err: any) => {
                  this.spinner.hide();
                  this.toast.error('Astrologer Not Available', 'Alert');
                  this.router.navigateByUrl('/');
                }
              );
          },
          (err: any) => {
            this.spinner.hide();
            this.toast.error(err.error.message, 'Alert');
          }
        );
    }
  }

  waitForm = new FormGroup({
    customerId: new FormControl(''),
    astroId: new FormControl(''),
    is_chat: new FormControl(''),
    is_call: new FormControl(''),
    is_current_active: new FormControl(''),
    personId: new FormControl(''),
  });

  get w() {
    return this.waitForm.controls;
  }

  /**
   * Promo Chat Order
   */
  isChatPromo = false;
  isPersonSelectedChatPromo = false;

  chatPromoForm = new FormGroup({
    customerId: new FormControl(''),
    personId: new FormControl(''),
    is_promotional_chat: new FormControl(true),
  });

  get pch() {
    return this.chatPromoForm.controls;
  }

  chatPromoOrderSelected(id: any) {
    this.isAddNewPerson = false;
    this.isPersonSelectedChatPromo = true;
    var orderObject = {
      customerId: this.userdata.getCustomerId(),
      orderType: 'chatPromo',
      personId: id,
    };
    sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
    this.getSinglePerson(id);
  }

  placeChatPromoOrder() {
    //Five minute free chat - waitlist not applicable
    this.spinner.show();
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          /**
           * Check if user is busy
           */
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.spinner.hide();
            Swal.fire(
              "Order can't be placed",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          /**
           * Check if wallet is recharged after detucting reserved amount
           */
          // var waitingAmount = res.data.waiting_amount
          // var currentWallet = res.data.current_wallet
          // var customerWalletAmount = currentWallet - waitingAmount
          // var astroCharges = this.langList.chat_charges * 2
          // if (astroCharges > customerWalletAmount) {
          //   this.spinner.hide()
          //   this.userdata.setCurrentRoute(this.router.url)
          //   this.toast.error('Please Recharge to place order', 'Error')
          //   this.router.navigateByUrl('/recharge-wallet')
          //   return
          // }
          /**
           * Place Chat Promo Order
           */
          var data = JSON.parse(this.userdata.getOrderObject() || '{}');
          this.chatPromoForm.patchValue({ customerId: data.customerId });
          this.chatPromoForm.patchValue({ personId: data.personId });
          this.orderService
            .placeChatPromoOrders(this.chatPromoForm.value)
            .subscribe(
              (res: any) => {
                if (res.success) {
                  this.spinner.hide();
                  this.toast.success(res.message, 'Success');
                  // sessionStorage.setItem("isPromo",'false')
                  this.router.navigateByUrl('/chat-placed');
                } else {
                  this.spinner.hide();
                  this.toast.error(res.message, 'Alert');
                }
              },
              (err: any) => {
                this.spinner.hide();
                this.toast.error(err.error.message, 'Alert');
              }
            );
        },
        (err: any) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /**
   * Verify Person Mobile Number
   */
  isMobileVerified = true;

  /**
   * Chat Order
   */
  isChatOrder = false;
  isPersonSelectedChat = false;

  chatForm = new FormGroup({
    customerId: new FormControl(''),
    astroId: new FormControl(''),
    personId: new FormControl(''),
    comments: new FormControl('', [Validators.required]),
    is_offer: new FormControl(''),
    offerId: new FormControl(''),
  });

  get ch() {
    return this.chatForm.controls;
  }

  chatOrderSelected(id: any) {
    this.isAddNewPerson = false;
    this.isPersonSelectedChat = true;
    var orderObject = {
      customerId: this.userdata.getCustomerId(),
      astroId: this.orderObject.astroId,
      orderType: 'chat',
      personId: id,
      is_offer: this.orderObject.is_offer,
      offerId: this.orderObject.offerId,
    };
    sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
    this.getSinglePerson(id);
  }

  placeChatOrder() {
    this.spinner.show();
    /**
     * Check if user is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.spinner.hide();
            Swal.fire(
              "Order can't be placed",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          // if (res.data.is_waiting) {
          //   this.spinner.hide();
          //   Swal.fire(
          //     "Order can't be placed",
          //     'You are already added in an astrologer waiting list.',
          //     'info'
          //   );
          //   return;
          // }
          /** Check if wallet is recharged after detucting reserved amount */
          //Check offer and wallet amount -- 001
          var charges = 0;
          if (
            this.astroData.astroofferId == null ||
            !this.astroData.astroofferId?.is_chat_offer_activate ||
            this.astroData.chatofferis == null
          ) {
            charges = this.astroData.chat_charges * 2;
          } else {
            if (this.astroData.isnewforastro) {
              if (
                this.astroData.chatofferis.user_type == 1 ||
                this.astroData.chatofferis.user_type == 3
              ) {
                charges =
                  this.astroData.chat_charges * 2 -
                  this.astroData.chat_charges *
                  2 *
                  (this.astroData.chatofferis.offer_discount / 100);
              } else {
                charges = this.astroData.chat_charges * 2;
              }
            } else {
              if (
                this.astroData.chatofferis.user_type == 1 ||
                this.astroData.chatofferis.user_type == 2
              ) {
                charges =
                  this.astroData.chat_charges * 2 -
                  this.astroData.chat_charges *
                  2 *
                  (this.astroData.chatofferis.offer_discount / 100);
              } else {
                charges = this.astroData.chat_charges * 2;
              }
            }
          }
          var waitingAmount = res.data.waiting_amount;
          var currentWallet = res.data.current_wallet;
          var customerWalletAmount = currentWallet - waitingAmount;
          var astroCharges = charges * 5; //001 05-08-2021
          if (currentWallet < astroCharges) {
            this.spinner.hide();
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error('Please Recharge to place order', 'Alert');
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          if (astroCharges > customerWalletAmount) {
            this.spinner.hide();
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error('Please Recharge to place order', 'Alert');
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          /**
           * Place Chat Order
           */
          var data = JSON.parse(this.userdata.getOrderObject() || '{}');
          //console.log(this.userdata.getOrderObject()|| '{}')
          this.chatForm.patchValue({ customerId: data.customerId });
          this.chatForm.patchValue({ astroId: data.astroId });
          this.chatForm.patchValue({ personId: data.personId });
          //03-08-2021
          this.chatForm.patchValue({ is_offer: data.is_offer });
          this.chatForm.patchValue({ offerId: data.offerId });
          //console.log(this.chatForm.value)
          //return
          this.orderService.placeChatOrders(this.chatForm.value).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.toast.success(res.message, 'Success');
              //  sessionStorage.setItem("isPromo",'false')
              this.router.navigateByUrl('/chat-placed');
            },
            (err: any) => {
              //console.log(err)
              if (err.error.status == 400) {
                this.spinner.hide();
                this.toast.success(
                  "Astrologer is currently Busy. You'll be added to astrologer's waiting List.",
                  'Success'
                );
                this.isWait = true;
                this.placeWaitOrder();
              } else {
                this.spinner.hide();
                this.toast.error(err.error.message, 'Alert');
              }
            }
          );
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /** update person profile */

  updatePersonForm = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    gender: new FormControl('male', [Validators.required]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{10,}'),
    ]),
    dob: new FormControl('', [Validators.required]),
    tob: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    marital_status: new FormControl('', [Validators.required]),
    employed_in: new FormControl('', [Validators.required]),
    is_partner_details: new FormControl(false, [Validators.required]),
    partner_name: new FormControl(''),
    partner_dob: new FormControl(''),
    partner_tob: new FormControl(''),
    partner_city: new FormControl(''),
    partner_state: new FormControl(''),
    partner_country: new FormControl(''),
    detail_name: new FormControl(''),
    is_number_verified: new FormControl(true, [Validators.required]),
    country_code: new FormControl('91'),
  });

  get u() {
    return this.updatePersonForm.controls;
  }

  onUpdatePersonHandler() {
    //console.log(this.personForm.get('tob')?.value)
    //return
    if (
      this.approvednums.some(
        (data: any) =>
          data.mobile === this.updatePersonForm.get('mobile')?.value
      )
    ) {
      this.updatePersonForm.patchValue({ is_number_verified: true });
    } else {
      this.updatePersonForm.patchValue({ is_number_verified: false });
    }
    var dob = moment(this.updatePersonForm.get('dob')?.value, [
      'YYYY-MM-DD',
    ]).format('DD-MM-YYYY');
    this.updatePersonForm.patchValue({ dob: dob });
    var tob = moment(this.updatePersonForm.get('tob')?.value, ['HH:mm']).format(
      'hh:mm a'
    );
    this.updatePersonForm.patchValue({ tob: tob });
    var partner_dob = moment(this.updatePersonForm.get('partner_dob')?.value, [
      'YYYY-MM-DD',
    ]).format('DD-MM-YYYY');
    this.updatePersonForm.patchValue({ partner_dob: partner_dob });
    var partner_tob = moment(this.updatePersonForm.get('partner_tob')?.value, [
      'HH:mm',
    ]).format('hh:mm a');
    this.updatePersonForm.patchValue({ partner_tob: partner_tob });
    this.loginService.updatePerson(this.updatePersonForm.value).subscribe(
      (res: any) => {
        if (!res.message.is_number_verified) {
          this.isMobileVerified = false;
        }
        var orderObject;
        if (this.isChatOrder) {
          orderObject = {
            customerId: this.userdata.getCustomerId(),
            astroId: this.orderObject.astroId,
            orderType: 'chat',
            personId: res.message._id,
            is_offer: this.orderObject.is_offer,
            offerId: this.orderObject.offerId,
          };
          sessionStorage.setItem('orderObject', JSON.stringify(orderObject));
          // this.placeQueryOrder()
          // //console.log(res)
        }
        var dob = moment(this.updatePersonForm.get('dob')?.value, [
          'DD-MM-YYYY',
        ]).format('YYYY-MM-DD');
        this.updatePersonForm.patchValue({ dob: dob });
        var tob = moment(this.updatePersonForm.get('tob')?.value, [
          'hh:mm a',
        ]).format('HH:mm');
        this.updatePersonForm.patchValue({ tob: tob });
        var partner_dob = moment(
          this.updatePersonForm.get('partner_dob')?.value,
          ['DD-MM-YYYY']
        ).format('YYYY-MM-DD');
        this.updatePersonForm.patchValue({ partner_dob: partner_dob });
        var partner_tob = moment(
          this.updatePersonForm.get('partner_tob')?.value,
          ['hh:mm a']
        ).format('HH:mm');
        this.updatePersonForm.patchValue({ partner_tob: partner_tob });
        this.toast.success('Profile Updated Successfully', 'Success');
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  updatePartnerDetails = false;
  onChangePartner(event: any) {
    this.updatePartnerDetails = !this.updatePartnerDetails;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji: any) {
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }
  disableDate()
  {
    return false;
  }
}
