<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Store Orders
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li>
          <a [routerLink]="['/store-order-list']" class="text-white">Store Orders</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize text-white">Order Detail</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="row">
          <h5>Store Order Detail</h5>
        </div>
        <div class="row mt-3">
          <table class="table table-stripped table-bordered">
            <tbody>
              <tr>
                <th>Order Number</th>
                <td># {{ dataList.orderId }}</td>
              </tr>
              <tr>
                <th>Order Date</th>
                <td>{{ dataList.createdAt | date: "medium" }}</td>
              </tr>
              <tr>
                <th>Product Name</th>
                <td>{{ dataList.productId?.productname }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td class="text-uppercase">
                  <span class="badge bdg-txt-fw bg-primary p-2" *ngIf="dataList.orderStatus == 1">Placed</span>
                  <span class="badge bdg-txt-fw bg-warning p-2" *ngIf="dataList.orderStatus == 2">Confirmed</span>
                  <span class="badge bdg-txt-fw bg-info p-2" *ngIf="dataList.orderStatus == 3">Shipped</span>
                  <span class="badge bdg-txt-fw bg-warning p-2" *ngIf="dataList.orderStatus == 4">Out For Delivery</span>
                  <span class="badge bdg-txt-fw bg-success p-2" *ngIf="dataList.orderStatus == 5">Delivered</span>
                  <span class="badge bdg-txt-fw bg-primary mr-1 p-2" *ngIf="dataList.orderStatus == 6">Cancelled</span><span
                    class="badge bdg-txt-fw bg-warning p-2" *ngIf="dataList.orderStatus == 6 && dataList.refundStatus == 0">Not
                    Refunded</span><span class="badge bdg-txt-fw p-2 bg-info "
                    *ngIf="dataList.orderStatus == 6 && dataList.refundStatus == 1">Refund Requested</span><span
                    class="badge bdg-txt-fw bg-success p-2"
                    *ngIf="dataList.orderStatus == 6 && dataList.refundStatus == 2">Refund
                    Approved</span><span class="badge bdg-txt-fw bg-primary p-2"
                    *ngIf="dataList.orderStatus == 6 && dataList.refundStatus == 3">Refund Rejected</span><span class="badge bdg-txt-fw bg-success"
                    *ngIf="dataList.orderStatus == 6 && dataList.refundStatus == 4">Refund Complete</span>
                  <span class="badge bdg-txt-fw p-2 bg-info"
                    *ngIf="dataList.orderStatus == 7 && !dataList.isOrderCompleted">Compelete</span>
                  <span class="badge bdg-txt-fw p-2 bg-success"
                    *ngIf="dataList.orderStatus == 8 && dataList.isOrderCompleted">Complete</span>
                </td>
              </tr>
              <tr *ngIf="dataList.astroId != null && dataList.astroId != undefined">
                <th>Astrologer</th>
                <td>
                  {{
                  dataList.astroId?.displayname == "" ||
                  dataList.astroId?.displayname == null ||
                  dataList.astroId?.displayname == undefined
                  ? dataList.astroId?.name
                  : dataList.astroId?.displayname
                  }}
                </td>
              </tr>
              <tr>
                <th>Product Amount</th>
                <td>
                  <span *ngIf="
                      dataList.userDetails?.quantity != null &&
                      dataList.userDetails?.quantity != '' &&
                      dataList.userDetails?.quantity != undefined &&
                      dataList.userDetails?.quantity > 1
                    ">Qty. {{ dataList.userDetails?.quantity }} X </span>{{
                  dataList.productPrice
                  | currency
                  : (dataList?.isForeignOrder ? "USD" : "INR")
                  : "symbol-narrow"
                  }}
                </td>
              </tr>
              <tr *ngIf="
                  dataList.addOnsTotal != null &&
                  dataList.addOnsTotal != '' &&
                  dataList.addOnsTotal != undefined &&
                  dataList.addOnsTotal > 0
                ">
                <th>Add-On Amount</th>
                <td>
                  {{
                  dataList.addOnsTotal
                  | currency
                  : (dataList?.isForeignOrder ? "USD" : "INR")
                  : "symbol-narrow"
                  }}
                </td>
              </tr>
              <tr *ngIf="dataList.isCouponApplied">
                <th>Discount</th>
                <td>
                  {{
                  dataList.couponDiscount
                  | currency
                  : (dataList?.isForeignOrder ? "USD" : "INR")
                  : "symbol-narrow"
                  }}
                </td>
              </tr>
              <tr>
                <th>Sub Total</th>
                <td>
                  {{
                  dataList.payableAmountWithoutGst
                  | currency
                  : (dataList?.isForeignOrder ? "USD" : "INR")
                  : "symbol-narrow"
                  }}
                </td>
              </tr>
              <tr>
                <th>GST @{{ dataList.gstPercent }}%</th>
                <td>
                  {{
                  dataList.gst
                  | currency
                  : (dataList?.isForeignOrder ? "USD" : "INR")
                  : "symbol-narrow"
                  }}
                </td>
              </tr>
              <tr>
                <th>Total Paid Amount</th>
                <td>
                  {{
                  dataList.grandTotal
                  | currency
                  : (dataList?.isForeignOrder ? "USD" : "INR")
                  : "symbol-narrow"
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <table class="table table-striped">
                    <tr class="bg-warning">
                      <th colspan="2">Add-Ons</th>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                    </tr>
                    <tr *ngFor="let addOn of dataList?.addOns">
                      <td>{{addOn?.addOnName}} <span *ngIf="addOn?.addOnSize != '' && addOn?.addOnSize != undefined && addOn?.addOnSize != null">(Size: {{addOn?.addOnSize}})</span></td>
                      <td>{{dataList?.isForeignOrder ? addOn?.addon_usd_price: addOn?.addOnPrice | currency
                        : (dataList?.isForeignOrder ? "USD" : "INR")
                        : "symbol-narrow"
                        }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr *ngIf="dataList.isOrderReviewed">
                <th>Review</th>
                <td>
                  {{ dataList.reviewId?.customerReviewComments }}
                  <button class="btn btn-link" (click)="updateReview()" *ngIf="checkSevenDays(dataList.orderCompletedDate)">
                    Change
                  </button>
                </td>
              </tr>
              <tr *ngIf="
                  dataList.isOrderReviewed && dataList.reviewId?.reviewReply != ''
                ">
                <th>Review Reply</th>
                <td>{{ dataList.reviewId?.reviewReply }}</td>
              </tr>
              <tr *ngIf="
                  !dataList.isOrderReviewed &&
                  dataList.isOrderCompleted &&
                  checkSevenDays(dataList.orderCompletedDate)
                ">
                <td colspan="2">
                  <button class="btn btn-primary btn-block" (click)="showReviewFormDetails()">
                    Review Order
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
    
          <!-- Review Section-->
          <form [formGroup]="reviewForm" (submit)="giveReview()" *ngIf="
              showReviewForm &&
              dataList.isOrderCompleted &&
              !dataList.isOrderReviewed &&
              checkSevenDays(dataList.createdAt)
            ">
            <div class="row mt-1">
              <div class="col-md-12">
                <div class="form-group">
                  <label for=""><strong>Review: </strong></label>
                  <textarea class="form-control" formControlName="customerReviewComments" rows="5"></textarea>
                  <div *ngIf="
                      r.customerReviewComments.invalid &&
                      (r.customerReviewComments.dirty ||
                        r.customerReviewComments.touched)
                    " class="alert alert-danger mt-1">
                    Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="user-wrap">
                  <button type="submit" class="btn btn-primary mt-1 mb-2" [disabled]="!reviewForm.valid">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <form [formGroup]="reviewForm" (submit)="giveReview()" *ngIf="
              dataList.isOrderReviewed &&
              changeReview &&
              checkSevenDays(dataList.createdAt)
            ">
            <div class="row mt-1">
              <!-- <div class="col-md-12">
                                <div class="form-group">
                                    <label for=""><strong>Rate: </strong></label>
                                    <br />
                                    <ngb-rating formControlName="customerReviewStars" [max]="5">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100"
                                                [class.bad]="index < 3">&#9733;</span>
                                        </ng-template>
                                    </ngb-rating>
                                    <div *ngIf="r.customerReviewStars.invalid && (r.customerReviewStars.dirty || r.customerReviewStars.touched)"
                                        class="alert alert-danger mt-1">
                                        Required.
                                    </div>
                                </div>
                            </div> -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for=""><strong>Review: </strong></label>
                  <textarea class="form-control" formControlName="customerReviewComments" rows="5"></textarea>
                  <div *ngIf="
                      r.customerReviewComments.invalid &&
                      (r.customerReviewComments.dirty ||
                        r.customerReviewComments.touched)
                    " class="alert alert-danger mt-1">
                    Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="user-wrap">
                  <button type="submit" class="btn btn-primary mt-1 mb-2" [disabled]="!reviewForm.valid">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row mt-3">
          <div *ngIf="dataList?.group_chat_id != null" class="col-md-6 mb-3">
            <button type="button" class="w-100 btn py-2 bg-secondary text-white fw-500"
              [routerLink]="['/order-chat', dataList.group_chat_id]">
              Chat
            </button>
          </div>
          <div class="col-md-6" *ngIf="
              dataList.orderStatus == 0 ||
              dataList.orderStatus == 1 ||
              dataList.orderStatus == 2
            ">
            <button type="button" class="w-100 btn cus-border-active rounded py-2 text-body" (click)="collapse.toggle()"
              [attr.aria-expanded]="!isIssueCollapsed" aria-controls="collapseExample">
              Cancel Order
            </button>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isIssueCollapsed">
              <div class="card">
                <div class="card-body">
                  <form [formGroup]="cancelOrderForm" (submit)="cancelOrder()">
                    <div class="form-group">
                      <label for="userComments">Reason</label>
                      <div class="input-group">
                        <textarea class="form-control" formControlName="userComments"></textarea>
                      </div>
                      <small *ngIf="
                          c.userComments.invalid &&
                          (c.userComments.dirty || c.userComments.touched)
                        " class="alert alert-danger mt-1">
                        Required.
                      </small>
                    </div>
                    <button type="submit" class="btn btn-primary" [disabled]="!cancelOrderForm.valid">
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <button type="button" class="w-100 btn py-2 bg-success text-white fw-500" (click)="collapse.toggle()"
              [attr.aria-expanded]="!isPersonCollapsed" aria-controls="collapseExample">
              Person Details
            </button>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isPersonCollapsed">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered table-stripped">
                    <tbody>
                      <tr *ngIf="
                          dataList.userDetails?.name != null &&
                          dataList.userDetails?.name != '' &&
                          dataList.userDetails?.name != undefined
                        ">
                        <td>Name</td>
                        <td>{{ dataList.userDetails?.name }}</td>
                      </tr>
                      <tr *ngIf="
                          dataList.userDetails?.email != null &&
                          dataList.userDetails?.email != '' &&
                          dataList.userDetails?.email != undefined
                        ">
                        <td>Email</td>
                        <td>{{ dataList.userDetails?.email }}</td>
                      </tr>
                      <tr *ngIf="
                          dataList.userDetails?.gender != null &&
                          dataList.userDetails?.gender != '' &&
                          dataList.userDetails?.gender != undefined
                        ">
                        <td>Gender</td>
                        <td>{{ dataList.userDetails?.gender }}</td>
                      </tr>
                      <tr *ngIf="
                          dataList.userDetails?.phoneNumber != null &&
                          dataList.userDetails?.phoneNumber != '' &&
                          dataList.userDetails?.phoneNumber != undefined
                        ">
                        <td>Mobile</td>
                        <td>
                          +{{ dataList.userDetails?.countryCode }}-{{
                          dataList.userDetails?.phoneNumber
                          }}
                        </td>
                      </tr>
                      <tr *ngIf="
                          (dataList.userDetails?.dob != null &&
                            dataList.userDetails?.dob != '' &&
                            dataList.userDetails?.dob != undefined) ||
                          (dataList.userDetails?.tob != null &&
                            dataList.userDetails?.tob != '' &&
                            dataList.userDetails?.tob != undefined)
                        ">
                        <td>Birth Details</td>
                        <td>
                          {{ dataList.userDetails?.dob }}
                          {{ dataList.userDetails?.tob }}
                        </td>
                      </tr>
    
                      <tr *ngIf="
                          dataList.userDetails?.maritalStatus != null &&
                          dataList.userDetails?.maritalStatus != '' &&
                          dataList.userDetails?.maritalStatus != undefined
                        ">
                        <td>Marital Status</td>
                        <td>{{ dataList.userDetails?.maritalStatus }}</td>
                      </tr>
                      <tr *ngIf="
                          dataList.userDetails?.addressId != null &&
                          dataList.userDetails?.addressId != '' &&
                          dataList.userDetails?.addressId != undefined
                        ">
                        <td>Address</td>
                        <td>
                          {{ dataList.userDetails?.addressId?.flatNo }},
                          {{ dataList.userDetails?.addressId?.locality }},
                          {{ dataList.userDetails?.addressId?.city }},
                          {{ dataList.userDetails?.addressId?.state }},
                          {{ dataList.userDetails?.addressId?.country }},
                          {{ dataList.userDetails?.addressId?.pinCode }}
                        </td>
                      </tr>
                      <tr *ngIf="
                          dataList.userDetails?.quantity != null &&
                          dataList.userDetails?.quantity != '' &&
                          dataList.userDetails?.quantity != undefined
                        ">
                        <td>Quantity</td>
                        <td>{{ dataList.userDetails?.quantity }}</td>
                      </tr>
                      <tr *ngIf="
                          dataList.userDetails?.comments != null &&
                          dataList.userDetails?.comments != '' &&
                          dataList.userDetails?.comments != undefined
                        ">
                        <td>Comments</td>
                        <td>{{ dataList.userDetails?.comments }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!dataList.is_customer_issue_created">
            <button type="button" class="btn w-100 py-2 text-body fw-500 bg-secondary-2" (click)="open(issue,'')">
              Need Help?
            </button>
            <!-- Add Issue Section-->
            <ng-template #issue let-c="close" let-d="dismiss">
              <div class="modal-header pb-0">
                <h4 class="modal-title" id="modal-basic-title">Issue</h4>
                <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                    class="material-icons text-white">close</span></button>
              </div>
              <div class="modal-body">
                <form [formGroup]="issueForm" (submit)="addIssue()">
                  <div class="form-group">
                    <label for="issue_title">Issue Title</label>
                    <div class="input-group">
                      <input type="text" class="form-control" formControlName="issue_title" />
                    </div>
                    <div *ngIf="
                      i.issue_title.invalid &&
                      (i.issue_title.dirty || i.issue_title.touched)
                    " class="alert alert-danger mt-1">
                      Required.
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="issue_description">Description</label>
                    <div class="input-group">
                      <textarea class="form-control" formControlName="issue_description"></textarea>
                    </div>
                    <div *ngIf="
                      i.issue_description.invalid &&
                      (i.issue_description.dirty ||
                        i.issue_description.touched)
                    " class="alert alert-danger mt-1">
                      Required.
                    </div>
                  </div>
                  <button type="submit" class="
                    btn
                    bg-secondary
                    text-white
                    fw-500
                    shadow-0
                    p-0
                  " [disabled]="!issueForm.valid">
                    Save
                  </button>
                </form>
              </div>
            </ng-template>
          </div>
          <div class="col-md-6" *ngIf="dataList.is_customer_issue_created">
            <button type="button" class="btn btn-primary btn-block" (click)="open(issueDetail,'')"
              [attr.aria-expanded]="!isIssueCollapsed" aria-controls="collapseExample">
              Issue Details
            </button>
            <!-- Issue Detail Section-->
            <ng-template #issueDetail let-c="close" let-d="dismiss">
              <div class="modal-header pb-0">
                <h4 class="modal-title" id="modal-basic-title">Issue Detail</h4>
                <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                    class="material-icons text-white">close</span></button>
              </div>
              <div class="modal-body">
                <table class="table table-bordered table-stripped">
                  <tbody>
                    <tr>
                      <td>Issue ID</td>
                      <td>#{{ dataList.customer_issueId?.issueId }}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>
                        {{ dataList.reportId?.customerCurrencySymbol
                        }}{{ dataList.customer_issueId?.amount }}
                      </td>
                    </tr>
                    <tr>
                      <td>Issue Title</td>
                      <td>{{ dataList.customer_issueId?.issue_title }}</td>
                    </tr>
                    <tr>
                      <td>Issue Description</td>
                      <td>
                        {{ dataList.customer_issueId?.issue_description }}
                      </td>
                    </tr>
                    <tr *ngIf="dataList.customer_issueId?.is_reply_by_system">
                      <td>Reply</td>
                      <td>
                        {{ dataList.customer_issueId?.reply_by_system }}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        <span *ngIf="dataList.customer_issueId?.is_issue_solved"
                          class="badge badge-pill bg-success">SOLVED</span>
                        <span *ngIf="!dataList.customer_issueId?.is_issue_solved"
                          class="badge badge-pill bg-danger">PENDING</span>
                      </td>
                    </tr>
                    <tr *ngIf="dataList.customer_issueId?.is_issue_refunded">
                      <td>Refund</td>
                      <td>
                        <span *ngIf="dataList.customer_issueId?.is_amount_refunded">
                          {{ dataList.reportId?.customerCurrencySymbol
                          }}{{ dataList.customer_issueId?.amount_refunded }}
                        </span>
                        <span *ngIf="dataList.customer_issueId?.is_promo_refunded">
                          condition pending
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2"><button [routerLink]="['/issue',dataList?.customer_issueId?._id]" class="w-25 mx-auto p-0 mt-0 btn-block btn bg-red text-white">View Reply</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </div>
          <div class="col-md-6" *ngIf="dataList.is_remedy_suggested">
            <button type="button" class="btn btn-primary btn-block" (click)="collapse.toggle()"
              [attr.aria-expanded]="!isRemedyCollapsed" aria-controls="collapseExample">
              Remedy Details
            </button>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isRemedyCollapsed">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered table-stripped">
                    <tbody>
                      <tr>
                        <td>Remedy ID</td>
                        <td>{{ dataList.remedysuggestId?.rsId }}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{{ dataList.remedysuggestId?.remedy_name }}</td>
                      </tr>
                      <tr>
                        <td>Link</td>
                        <td>{{ dataList.remedysuggestId?.remedy_link }}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                          {{ dataList.remedysuggestId?.remedy_description }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>