<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- hs About Title Start -->
<div class="hs_indx_title_main_wrapper">
    <div class="hs_title_img_overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
                <div class="hs_indx_title_left_wrapper">
                    <h2>Support Details</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
                <div class="hs_indx_title_right_wrapper">
                    <ul>
                        <li><a [routerLink]="['/home']">Home</a> &nbsp;&nbsp;&nbsp;&gt; </li>
                        <li>Support Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- hs About Title End -->
<div class="hs_service_main_wrapper pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <div class="hs_kd_five_heading_sec_wrapper">
                    <h2>Support Detail</h2>
                    <h4><span></span></h4>
                </div>
            </div>
            <div class="col-sm-8">
                <table class="table table-stripped table-bordered">
                    <tbody>
                        <tr>
                            <th>Support ID</th>
                            <td># {{dataList.supportId}}</td>
                        </tr>
                        <tr>
                            <th>Title</th>
                            <td>{{dataList.support_title}}</td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{{dataList.support_description}}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td class="text-uppercase"><span class="badge bdg-txt-fw bg-success"
                                    *ngIf="dataList.is_issue_solved">SOLVED</span><span
                                    class="badge bdg-txt-fw bg-danger" *ngIf="!dataList.is_issue_solved">PENDING</span>
                            </td>
                        </tr>
                        <tr>
                            <th>Created On</th>
                            <td>{{dataList.created_at |date :'medium'}} </td>
                        </tr>
                        <tr *ngIf="dataList.is_reply_by_system">
                            <th>Solution</th>
                            <td>{{dataList.reply_by_system}} </td>
                        </tr>
                        <tr *ngIf="dataList.is_reply_by_system">
                            <th>Reply On</th>
                            <td>{{dataList.replyDate |date :'medium'}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>