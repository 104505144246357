<app-header></app-header>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Place Order</h3>
      <ul
        class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        "
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Place Order</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="d-none d-lg-block text-capitalize text-center">place order</h4>
      <h5 class="d-lg-none text-capitalize text-center">place order</h5>
      <h4 class="text-center"><span></span></h4>
    </div>
    <div class="row mt-lg-5 mt-3">
      <div class="col-md-6" *ngIf="isQueryOrder">
        <h4 class="mb-2">Choose Profile</h4>
        <table class="table table-bordered table-stripped">
          <ng-container *ngFor="let data of peopleList">
            <tr *ngIf="!data.is_hide">
              <th>{{ data.name }}</th>
              <td>
                <button
                  class="btn bg-secondary py-2 px-3 rounded text-white"
                  (click)="queryOrderSelected(data._id)"
                >
                  Select
                </button>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td colspan="2">
              <button
                class="btn bg-secondary py-2 px-3 rounded text-white"
                (click)="showAddPersonForm()"
              >
                ADD NEW PROFILE
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-md-6" *ngIf="isQueryOrder && isPersonSelectedQuery">
        <!-- update selected user profile -->
        <form [formGroup]="updatePersonForm">
          <div class="form-row">
            <div class="form-group col-md-6">
              <h4>Person Profile</h4>
            </div>
            <div class="form-group col-md-6">
              <button
                type="submit"
                class="btn bg-secondary py-2 px-3 rounded text-white"
                [disabled]="!updatePersonForm.valid"
                (click)="onUpdatePersonHandler()"
              >
                Update Profile
              </button>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Name *</label>
              <input type="text" class="form-control" formControlName="name" />
              <div
                *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress2">Gender *</label><br />
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                formControlName="gender"
              >
                <mat-radio-button
                  class="example-radio-button mr-2"
                  value="male"
                >
                  Male
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" value="female">
                  Female
                </mat-radio-button>
              </mat-radio-group>
              <div
                *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputCity">Country Code *</label>
              <!-- <select class="form-control" formControlName="country_code">
                <option
                  *ngFor="let country of countryList"
                  value="{{ country.phone_code }}"
                >
                  {{ country.emoji }}&nbsp;&nbsp;{{
                    country.name
                  }}&nbsp;&nbsp;(+{{ country.phone_code }})
                </option>
              </select> -->
              <ng-select [searchFn]="customSearchFn" formControlName="country_code"
                class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
                bindValue="phone_code">
                <ng-template ng-option-tmp let-item="item">
                  <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                  item.name
                  }}&nbsp;&nbsp;(+{{ item.phone_code }})
                </ng-template>
              </ng-select>
              <div
                *ngIf="
                  u.country_code.invalid &&
                  (u.country_code.dirty || u.country_code.touched)
                "
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputState">Mobile Number *</label>
              <input
                type="text"
                class="form-control"
                formControlName="mobile"
                id="inputZip"
              />
              <div
                *ngIf="u.mobile.invalid && (u.mobile.dirty || u.mobile.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Marital Status *</label>
              <select class="form-control" formControlName="marital_status">
                <option value="" selected disabled>Select Status</option>
                <option>Single</option>
                <option>Married</option>
                <option>Widowed</option>
                <option>Divorced</option>
              </select>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Employed-In *</label>
              <select class="form-control" formControlName="employed_in">
                <option value="" selected disabled>Select Occupation</option>
                <option>Private Job</option>
                <option>Govt Job</option>
                <option>Not Employed</option>
                <option>Student</option>
                <option>None of the above</option>
              </select>
            </div>
            <!-- <div class="form-group col-lg-12 col-md-12 col-6">
                              <label for="inputZip">Language</label>
                              <select class="form-control" formControlName="langId">
                                  <option value="" selected disabled>Select Language</option>
                                  <option *ngFor="let lang of langList" [value]="lang._id">{{lang.name}}</option>
                              </select>
                          </div> -->
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Date Of Birth *</label>
              <input type="date" class="form-control" formControlName="dob" [max]="today"/>
              <div
                *ngIf="u.dob.invalid && (u.dob.dirty || u.dob.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Time Of Birth *</label>
              <input type="time" class="form-control" formControlName="tob" />
              <div
                *ngIf="u.tob.invalid && (u.tob.dirty || u.tob.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Place Of Birth (City) *</label>
              <input type="text" class="form-control" formControlName="city" />
              <div
                *ngIf="u.city.invalid && (u.city.dirty || u.city.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">State *</label>
              <input type="text" class="form-control" formControlName="state" />
              <div
                *ngIf="u.state.invalid && (u.state.dirty || u.state.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Country *</label>
              <input
                type="text"
                class="form-control"
                formControlName="country"
              />
              <div
                *ngIf="
                  u.country.invalid && (u.country.dirty || u.country.touched)
                "
                class="alert alert-danger mt-1"
              ></div>
            </div>
            <!-- <div class="form-group col-lg-12 col-md-12 col-6">
                              <label for="inputZip">Comments</label>
                              <input type="text" class="form-control" formControlName="comments">
                          </div> -->
            <div class="form-group col-md-12">
              <label for="inputEmail4">Link Partner's Details? *</label><br />
              <mat-radio-group
                class="example-radio-group"
                formControlName="is_partner_details"
                (change)="onChangePartner($event)"
              >
                <mat-radio-button
                  class="example-radio-button mr-2"
                  [value]="false"
                >
                  Yes
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="true">
                  No
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-row" *ngIf="updatePartnerDetails">
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputPassword4">Partner's Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_name"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputPassword4">Partner's Date Of Birth</label>
              <input
                type="date"
                class="form-control"
                formControlName="partner_dob"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's Time Of Birth</label>
              <input
                type="time"
                class="form-control"
                formControlName="partner_tob"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's Birth City</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_city"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's State</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_state"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's Country</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_country"
              />
            </div>
          </div>
        </form>
        <form [formGroup]="queryForm" (submit)="placeQueryOrder()">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>Language</td>
                <td>
                  <select class="form-control" formControlName="langId">
                    <option value="" selected>Choose Language</option>
                    <option
                      *ngFor="let lang of langList"
                      [value]="lang.langId._id"
                    >
                      {{ lang.langId.name }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Query</td>
                <td>
                  <textarea
                    formControlName="question"
                    placeholder="Ask query"
                    class="form-control"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <button
                    type="submit"
                    class="btn bg-secondary py-2 px-3 rounded text-white"
                    [disabled]="!queryForm.valid"
                  >
                    Place Order
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="col-md-6" *ngIf="isAddNewPerson">
        <form [formGroup]="personForm">
          <div class="form-row">
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputEmail4">Profile Type *</label><br />
              <mat-radio-group
                class="example-radio-group"
                formControlName="is_self_profile"
              >
                <mat-radio-button
                  class="example-radio-button mr-2"
                  [value]="true"
                >
                  Self
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="false">
                  Other
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="inputPassword4">Profile Name</label>
            <input
              type="text"
              class="form-control"
              formControlName="detail_name"
              placeholder="Eg. Mother, Father, Daughter, Son etc."
            />
          </div>
          <div class="form-group col-md-12">
            <label for="inputAddress">Name *</label>
            <input type="text" class="form-control" formControlName="name" />
            <div
              *ngIf="p.name.invalid && (p.name.dirty || p.name.touched)"
              class="alert alert-danger mt-1"
            >
              Required.
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="inputAddress2">Gender *</label><br />
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              formControlName="gender"
            >
              <mat-radio-button class="example-radio-button mr-2" value="male">
                Male
              </mat-radio-button>
              <mat-radio-button class="example-radio-button" value="female">
                Female
              </mat-radio-button>
            </mat-radio-group>
            <div
              *ngIf="p.name.invalid && (p.name.dirty || p.name.touched)"
              class="alert alert-danger mt-1"
            >
              Required.
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputCity">Country Code *</label>
              <!-- <select class="form-control" formControlName="country_code">
                <option
                  *ngFor="let country of countryList"
                  value="{{ country.phone_code }}"
                >
                  {{ country.emoji }}&nbsp;&nbsp;{{
                    country.name
                  }}&nbsp;&nbsp;(+{{ country.phone_code }})
                </option>
              </select> -->
              <ng-select [searchFn]="customSearchFn" formControlName="country_code"
                class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
                bindValue="phone_code">
                <ng-template ng-option-tmp let-item="item">
                  <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                  item.name
                  }}&nbsp;&nbsp;(+{{ item.phone_code }})
                </ng-template>
              </ng-select>
              <div
                *ngIf="
                  p.country_code.invalid &&
                  (p.country_code.dirty || p.country_code.touched)
                "
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>

            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputState">Mobile Number *</label>
              <input
                type="text"
                class="form-control"
                formControlName="mobile"
                id="inputZip"
              />
              <div
                *ngIf="p.mobile.invalid && (p.mobile.dirty || p.mobile.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Marital Status *</label>
              <select class="form-control" formControlName="marital_status">
                <option value="" selected disabled>Select Status</option>
                <option>Single</option>
                <option>Married</option>
                <option>Widowed</option>
                <option>Divorced</option>
              </select>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Employed-In *</label>
              <select class="form-control" formControlName="employed_in">
                <option value="" selected disabled>Select Occupation</option>
                <option>Private Job</option>
                <option>Govt Job</option>
                <option>Not Employed</option>
                <option>Student</option>
                <option>None of the above</option>
              </select>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Date Of Birth *</label>
              <input type="date" class="form-control" formControlName="dob" [max]="today"/>
              <div
                *ngIf="p.dob.invalid && (p.dob.dirty || p.dob.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Time Of Birth *</label>
              <input type="time" class="form-control" formControlName="tob" />
              <div
                *ngIf="p.tob.invalid && (p.tob.dirty || p.tob.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Place Of Birth (City) *</label>
              <input type="text" class="form-control" formControlName="city" />
              <div
                *ngIf="p.city.invalid && (p.city.dirty || p.city.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">State *</label>
              <input type="text" class="form-control" formControlName="state" />
              <div
                *ngIf="p.state.invalid && (p.state.dirty || p.state.touched)"
                class="alert alert-danger mt-1"
              >
                Required.
              </div>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputZip">Country *</label>
              <input
                type="text"
                class="form-control"
                formControlName="country"
              />
              <div
                *ngIf="
                  p.country.invalid && (p.country.dirty || p.country.touched)
                "
                class="alert alert-danger mt-1"
              ></div>
            </div>
            <div class="form-group col-md-12">
              <label for="inputEmail4">Link Partner's Details? *</label><br />
              <mat-radio-group
                class="example-radio-group"
                formControlName="is_partner_details"
                (change)="onChange($event)"
              >
                <mat-radio-button
                  class="example-radio-button mr-2"
                  [value]="false"
                >
                  Yes
                </mat-radio-button>
                <mat-radio-button class="example-radio-button" [value]="true">
                  No
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-row" *ngIf="partnerDetails">
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputPassword4">Partner's Name</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_name"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputPassword4">Partner's Date Of Birth</label>
              <input
                type="date"
                class="form-control"
                formControlName="partner_dob"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's Time Of Birth</label>
              <input
                type="time"
                class="form-control"
                formControlName="partner_tob"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's Birth City</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_city"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's State</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_state"
              />
            </div>
            <div class="form-group col-lg-12 col-md-12 col-6">
              <label for="inputAddress">Partner's Country</label>
              <input
                type="text"
                class="form-control"
                formControlName="partner_country"
              />
            </div>
          </div>
        </form>
        <form [formGroup]="queryForm" *ngIf="isQueryOrder">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>Language</td>
                <td>
                  <select class="form-control" formControlName="langId">
                    <option value="" selected>Choose Language</option>
                    <option
                      *ngFor="let lang of langList"
                      [value]="lang.langId._id"
                    >
                      {{ lang.langId.name }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Query</td>
                <td>
                  <textarea
                    formControlName="question"
                    placeholder="Ask query"
                    class="form-control"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <button
                    type="submit"
                    class="btn bg-secondary py-2 px-3 rounded text-white"
                    [disabled]="!personForm.valid || !queryForm.valid"
                    (click)="addPerson()"
                  >
                    Save
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
