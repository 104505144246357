<h2 mat-dialog-title>Select A Report</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <ng-container *ngFor="let report of reports">
      <div class="col-12 col-md-4">
        <div (click)="onReportSelect(report)" class="pointer card  mb-4" [class.selected-report]="data == report._id">
          <div class="card-header  coupon-bg">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img class="size-70 rounded border" [src]="getSanitizeUrl(report.report_image)" />
              </div>
              <div class="flex-grow-1 ms-3 active-text ml-2">
                {{ report.reportname }}
              </div>
            </div>
          </div>
          <div class="card-body line-size-5 small text-justify py-2"
            style="height: 136px !important; overflow-y: scroll;" [title]="report.reportdescription">
            {{ report.reportdescription }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" (click)="goBack()" class="btn bg-primary py-2 px-5 mr-2 text-white fw-500">
    Close
  </button>
  <button type="button" (click)="closeDialog()" class="btn bg-secondary py-2 px-5 text-white fw-500">
    Continue
  </button>
</mat-dialog-actions>