<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Waiting List</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Waiting List</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <!-- <div class="row new-product d-flex flex-column">
          <h4 class="d-none d-lg-block text-capitalize text-center">
            waiting list
          </h4>
          <h5 class="d-lg-none text-capitalize text-center">wating list</h5>
          <h4 class="text-center"><span></span></h4>
        </div> -->
        <div class="row table-responsive" *ngIf="dataList.length > 0">
          <table class="table table-bordered cust-table table-stripped">
            <thead>
              <tr class="th-bg">
                <th>ID</th>
                <th>Astrologer</th>
                <th>Type</th>
                <th>Waiting Time</th>
                <th>Service Duration</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of dataList">
                <td>#{{ data.waitingId }}</td>
                <td>
                  {{
                  data.astroId?.displayname == "" ||
                  data.astroId?.displayname == null ||
                  data.astroId?.displayname == undefined
                  ? data.astroId?.name
                  : data.astroId?.displayname
                  }}
                </td>
                <td>
                  <span *ngIf="data.is_call" class="badge bdg-txt-fw bg-primary text-white">CALL</span>
                  <span *ngIf="data.is_chat" class="badge bdg-txt-fw bg-info text-white">CHAT</span>
                </td>
                <td>{{ convertSecondstoTime(data.last_wait_time) }}</td>
                <td>{{ convertSecondstoTime(data.duration) }}</td>
                <td>{{ data.created_at | date: "medium" }}</td>
                <td>
                  <button class="btn bg-secondary px-3 py-2 text-white fw-500 rounded" (click)="confirmFirst(data._id)">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" *ngIf="dataList.length == 0">
          <div class="col-12">
            <section class="container  order-bg1 mb-5 py-3">
              <div class=" row n-data-img justify-content-center">
                <img src="../../../../assets/images/no-data.svg" />
              </div>
              <div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>