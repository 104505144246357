import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {

  walletBalance = 0;BaseUrl:any;zodiacData = new Array();
  originalZodiaList = new Array();
  signIndex = 0;
  signName = '';
  singleZodiacSign: any;
  dataList: any = [];
  dataCallList: any = [];
  dataChatList: any = [];
  constructor(private userdata: UserdataService, private router: Router, private toast: ToastrService, private openService: OpenApiService, private orderService: OrderServicesService, private loginService:LoginService ,private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngOnInit(): void {
    this.walletBalance = Number(this.userdata.getCurrentWallet()) ?? 0;
    this.getZodiac();
    this.getCallAstrologerList()
    this.getCallOrders()
    this.getChatOrders();
    this.getMyProfile()
  }

  goToRecharge() {
    if (this.userdata.isLogin()) this.router.navigateByUrl('/recharge-wallet');
    else this.toast.error('Please login to recharge');
  }
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  previousSign() {
    this.signIndex--;
    this.getSingleSign();
  }
  nextSign() {
    this.signIndex++;
    this.getSingleSign();
  }
  getSingleSign() {
    this.signName = this.originalZodiaList[this.signIndex].name;
    this.getSignInfo();
  }
  getZodiac() {
    this.openService.dailyHoroscopeZodiac({ isRandomRequired: true }).subscribe(
      (res: any) => {
        this.singleZodiacSign = res.randomZodiacData;
        this.originalZodiaList = res.data;
        this.signName = this.originalZodiaList[0].name;
        this.getSignInfo();
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }
  zodiacImage = '';
  getSignInfo() {
    this.openService.dailyHoroscope({ sign: this.signName }).subscribe(
      (res: any) => {
        this.singleZodiacSign = res.data;
        this.zodiacImage = res.image;
      },
      (err: any) => {}
    );
  }

  //Swiper Configurations

  zodiacConfig: SwiperOptions = {
    slidesPerView: 3,
    direction: 'horizontal',
    spaceBetween: 5,
    effect: 'slide',
    loop: true,
    autoplay: {
      delay: 2000,
    },
    scrollbar: false,
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
  };

  getCallAstrologerList() {
    var data: any = { startpoint: 0 };
    if (this.userdata.getToken().length > 0) {
      data = { customerId: this.userdata.getCustomerId(), startpoint: 0 };
    }
    this.openService.getAstrologersDashboard(data).subscribe(
      (res: any) => {
        this.dataList = res.data;
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  getCallOrders(){
    this.orderService.showCallOrders({customerId:this.userdata.getCustomerId()}).subscribe(
      (res:any)=>{
        this.dataCallList = res.data
        //console.log(res)
      },err=>{
        //console.log(err)
      }
    )
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
    var timeString
    if(hours >0){
      timeString = hours.toString().padStart(2, '0')
                + ':' + minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0')+' Hrs.';
    }else{
      timeString = minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0')+' Min.';
    }
    return timeString
  } 

  getChatOrders() {
    this.orderService
      .showChatOrders({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.dataChatList = res.data;
        },
        (err: any) => {
          //console.log(err)
        }
      );
  }

  /**Wallet currencycode */
  myProfile: any;
  getMyProfile() {
    this.loginService.myProfile({ _id: this.userdata.getCustomerId()}).subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          this.myProfile = res.data;
          this.userdata.setCurrentWallet(this.myProfile.current_wallet)
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

}
