<app-header></app-header>
<ngx-spinner></ngx-spinner>
<a href="javascript:void(0)" class="float" *ngIf="isPromo" (click)="promoOrder()">
</a>
<!-- hs Slider Start -->
<section *ngIf="banners.length > 0">
  <div class="container-fluid p-0 slider-mob pointer" style="height: 728px">
    <swiper [config]="homeBannerConfig" class="home-banner ">
      <ng-template swiperSlide *ngFor="let banner of banners; let i = index" width="{{ screenWidth }}" class="s-size">
        <img usemap="#image-map" class="w-100 h-100" [src]="getSanitizeUrl(banner.bannerInfo?.banner_image)" alt=""
          (click)="bannerLink(banner)" />
        <!-- <img usemap="#image-map" class="w-100 h-100" src="../../../assets/images/slider-2.jpg" /> -->

        <map name="image-map">
          <area shape="rect" coords="22,22,231,22" href="https://www.facebook.com/" />
          <area shape="rect" coords="233,22,443,22" href="https://www.google.com/" />
        </map>
      </ng-template>
    </swiper>
  </div>
</section>
<!-- Call Chat buttons Start-->
<section class="d-none d-lg-block bg-active" style="margin-top: -5rem">
  <div class="container">
    <div class="row d-flex">
      <div class="mx-2 flex-fill service-box trans" style="border-color: #4f970f">
        <a [routerLink]="['/call-astro-list']">
          <div class="quarter" style="background: #84b657; border-color: #4f970f">
            <span class="material-icons-round"> phone_in_talk </span>
          </div>
          <h4 class="fw-500 text-capitalize py-3 text-center">CALL</h4>
        </a>
      </div>

      <div class="mx-2 flex-fill service-box trans" style="border-color: #ff1a24">
        <a [routerLink]="['/chat-astro-list']">
          <div class="quarter" style="background: #ff6065; border-color: #ff1a24">
            <span class="material-icons-round"> chat </span>
          </div>
          <h4 class="fw-500 text-capitalize py-3 text-center">CHAT</h4>
        </a>
      </div>
      <div class="mx-2 flex-fill service-box trans" style="border-color: #ff5f01">
        <a [routerLink]="['/report-astro-list']">
          <div class="quarter" style="background: #ff8e4f; border-color: #ff5f01">
            <span class="material-icons-round"> description </span>
          </div>
          <h4 class="fw-500 text-capitalize py-3 text-center">GET REPORT</h4>
        </a>
      </div>

      <div class="mx-2 flex-fill service-box trans" style="border-color: #017c60">
        <a [routerLink]="['/query-astro-list']">
          <div class="quarter" style="background: #4da491; border-color: #017c60">
            <span class="material-icons-round"> help </span>
          </div>
          <h4 class="fw-500 text-capitalize py-3 text-center">ASK QUERY</h4>
        </a>
      </div>

      <div class="mx-2 flex-fill service-box trans" style="border-color: #eba600">
        <a [routerLink]="['/astro-shop']">
          <div class="quarter" style="background: #f3c14e; border-color: #eba600">
            <span class="material-icons-round"> store </span>
          </div>
          <h4 class="fw-500 text-capitalize py-3 text-center">ASTRO SHOP</h4>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="d-lg-none px-1 pt-2 pb-3 d-flex justify-content-center">
  <swiper [config]="callConfig" class="callSwipe">
    <ng-template swiperSlide class="pt-4 pl-4">
      <div [routerLink]="['/call-astro-list']" style="height: 5rem; width: 5rem" class="
          d-flex
          align-items-center
          justify-content-center
          position-relative
          rounded
          shadow
          cus-border-green
        ">
        <div class="div-img position-absolute rounded-circle bg-green">
          <span class="material-icons-round text-white p-2 fn-4">
            phone_in_talk
          </span>
        </div>
        <h6 class="fw-500 text-center">Call</h6>
      </div>
    </ng-template>
    <ng-template swiperSlide class="pt-4 pl-4">
      <div [routerLink]="['/chat-astro-list']" style="height: 5rem; width: 5rem" class="
          d-flex
          align-items-center
          justify-content-center
          position-relative
          rounded
          shadow
          cus-border-pink
        ">
        <div class="div-img position-absolute rounded-circle bg-pink">
          <span class="material-icons-round p-2 fn-4 text-white"> chat </span>
        </div>
        <h6 class="fw-500 text-center">Chat</h6>
      </div>
    </ng-template>
    <ng-template swiperSlide class="pt-4 pl-4">
      <div [routerLink]="['/query-astro-list']" style="height: 5rem; width: 5rem" class="
          d-flex
          align-items-center
          justify-content-center
          position-relative
          rounded
          shadow
          cus-border-orange
        ">
        <div class="div-img position-absolute rounded-circle bg-orange">
          <span class="material-icons-round p-2 fn-4 text-white"> help </span>
        </div>
        <h6 class="fw-500 text-center">Query</h6>
      </div>
    </ng-template>
    <ng-template swiperSlide class="pt-4 pl-4">
      <div [routerLink]="['/report-astro-list']" style="height: 5rem; width: 5rem" class="
          d-flex
          align-items-center
          justify-content-center
          position-relative
          rounded
          shadow
          cus-border-green-dark
        ">
        <div class="div-img position-absolute rounded-circle bg-dark-green">
          <span class="material-icons-round p-2 fn-4 text-white">
            description
          </span>
        </div>
        <h6 class="fw-500 text-center">Report</h6>
      </div>
    </ng-template>
    <ng-template swiperSlide class="pt-4 pl-4">
      <div [routerLink]="['/astro-shop']" style="height: 5rem; width: 5rem" class="
          d-flex
          align-items-center
          justify-content-center
          position-relative
          rounded
          shadow
          cus-border-yellow
        ">
        <div class="div-img position-absolute rounded-circle bg-secondary-2">
          <span class="material-icons-round p-2 fn-4 text-white"> store </span>
        </div>
        <h6 class="fw-500 text-center">Shop</h6>
      </div>
    </ng-template>
  </swiper>
</section>
<!-- Call Chat buttons End-->
<!-- Talk to astrologer page Start-->
<section class="d-none d-lg-block mt-5 py-5 bg-inactive">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">Talk to Astrologers</h4>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div>

    <div class="row mt-5">
      <div class="my-3 col-lg-3 col-md-4 col-sm-6 col-12" *ngFor="let key of dataList.slice(0, 8)">
        <div class="w-100 card trans astro-box p-2 shadow">
          <div class="card-body bg-astro">
            <div class="row p-1">
              <img class="astro-img" [src]="getSanitizeUrl(key?.profile_image)" />
              <span class="material-icons position-absolute mt-2"
                [ngClass]="key?.is_call_active ? 'online' : 'offline'">
                fiber_manual_record
              </span>
              <div class="col-12 ml-5 pl-4 pr-5" style="top: -16%; right: -2%; font-size: 0.9rem">
                <span class="text-capitalize fw-600">{{
                  key?.displayname ? key?.displayname : key?.name
                  }}</span>
                <p class="skill inactive-text">
                  <span class="text-capitalize" *ngFor="let skill of key?.skill">{{ skill?.skillId?.skillname
                    }}{{ key?.skill?.length > 1 ? ", " : "" }}</span>
                </p>
                <p class="inactive-text">
                  <strong>Exp:</strong> {{ key?.experience }}
                  {{ key?.experience > 1 ? "Years" : "Year" }}
                </p>
              </div>
            </div>
            <div class="mx-n2 d-flex justify-content-between align-items-center">
              <p class="text-center">
                <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astrologer_rating" [readonly]="true"></ngb-rating>
                <span class="font-10 rating-number d-block p-0 mt-n2">({{key.astrologer_rating}})</span>
              </p>

              <a [routerLink]="['/astrologer/', key?._id]" style="float: right"><button class="round-button"
                  style="font-size: 0.75rem">
                  <i class="far fa-eye"></i>
                  View Profile
                </button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Mobile -->
<section class="py-4 d-lg-none bg-inactive">
  <div class="row d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">talk to astrologer</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mt-3 px-4 d-flex justify-content-center">
    <swiper [config]="latestConfig" class="astroSwiper">
      <ng-template class="pt-3" swiperSlide *ngFor="let key of dataList.slice(0, 4)">
        <div class="card position-relative mt-5 text-center bg-active">
          <img style="top: -3.3rem; left: 22%" [src]="getSanitizeUrl(key?.profile_image)"
            class="shadow size-100 position-absolute rounded-circle" />
          <div class="card-body bg-astro p-1 pt-5">
            <div class="d-flex flex-column">
              <p class="mx-n2">
                <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astrologer_rating" [readonly]="true"></ngb-rating>
                <span class="font-10 rating-number d-block p-0 mt-n2">({{key.astrologer_rating}})</span>
              </p>
              <span class="text-capitalize fw-600 fn-2">{{
                key?.displayname ? key?.displayname : key?.name
                }}</span>
              <p class="skill inactive-text fn-1">
                <span class="text-capitalize" *ngFor="let skill of key?.skill">{{ skill?.skillId?.skillname
                  }}{{ key?.skill?.length > 1 ? ", " : "" }}</span>
              </p>
              <p class="inactive-text fn-1">
                <strong>Exp:</strong> {{ key?.experience }}
                {{ key?.experience > 1 ? "Years" : "Year" }}
              </p>
            </div>
          </div>
          <div class="card-footer p-2 d-flex border-0 bg-astro shadow">
            <a [routerLink]="['/astrologer/', key?._id]"
              class="btn bg-secondary p-2 flex-grow-1 rounded fw-500 text-white"><i class="far fa-eye"></i>&nbsp;View
              Profile</a>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="mt-3 text-center">
    <a routerLink="/call-astro-list" class="fw-500 btn text-white bg-green text-capitalize rounded-pill">
      View More
    </a>
  </div>
</section>
<!-- Talk to astrologer page end-->
<!-- HoroScope for you page Start-->
<section class="d-none d-lg-block hr-for-u py-5">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">Daily Horoscope
      </h4>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div>
    <div class="row position-relative mt-4">
      <a class="
          nav-left
          left
          d-flex
          justify-content-center
          align-items-center
          size-30
          text-white
          rounded-circle
          position-absolute
          border border-white
          bg-dark
        "><i class="fi fi-angle-left"></i></a>
      <a class="
          nav-right
          d-flex
          justify-content-center
          align-items-center
          size-30
          text-white
          rounded-circle
          position-absolute
          border border-white
          bg-dark
        "><i class="fi fi-angle-right"></i></a>
      <swiper [config]="zodiacConfig">
        <ng-template swiperSlide *ngFor="let sign of originalZodiaList">
          <div class="
              mt-4
              mx-3
              position-relative
              horoscope-bg
              p-4
              d-flex
              justify-content-end
            ">
            <a [routerLink]="['/daily-horoscope', sign.name]">
              <img [src]="getSanitizeUrl(sign.image)" class="
                  bg-active
                  p-2
                  border border-white
                  rounded-circle
                  position-absolute
                  horoscope-img
                " />
            </a>
            <div class="text-white cursor-pointer" [routerLink]="['/daily-horoscope', sign.name]">
              <h5 class="text-center text-white fw-500">
                {{ sign.name }}
              </h5>
              <span class="text-white">{{ sign.time }}</span>
            </div>
          </div>
        </ng-template>
        <!-- <mdb-carousel-item
          class="row pl-5 py-4 d-none d-lg-block d-md-block"
          *ngFor="let zodiac of zodiacData"
        >
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-6 px-3"
            *ngFor="let sign of zodiac"
          >
            <div
              class="
                px-3
                position-relative
                horoscope-bg
                d-flex
                flex-column
                d-lg-none d-md-none
              "
            >
              <img
                [src]="getSanitizeUrl(sign.image)"
                class="p-2 border border-white rounded-circle"
              />
              <h4>{{ sign.name }}</h4>
            </div>
          </div>
        </mdb-carousel-item> -->
      </swiper>
    </div>
  </div>
</section>
<!-- Mobile -->
<section class="py-4 d-lg-none">
  <div class="row d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">Daily Horoscope
    </h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mt-3 px-4">
    <swiper [config]="zodiacConfig">
      <ng-template swiperSlide *ngFor="let sign of originalZodiaList">
        <div class="d-flex flex-column justify-content-center cursor-pointer"
          [routerLink]="['/daily-horoscope', sign.name]">
          <img [src]="getSanitizeUrl(sign.image)" class="w-75 rounded-circle border align-self-center" />
          <h4 class="text-center fn-1 fw-600 m-mob">{{ sign.name }}</h4>
        </div>
      </ng-template>
    </swiper>
  </div>
</section>
<!-- HoroScope for you page end-->
<!-- Latest Start -->
<section class="d-none d-lg-block my-5" *ngIf="featuredProduct != undefined && featuredProduct != null">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">latest products</h4>
      <h4 class="text-center"><span></span></h4>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-right mb-4">
        <span [routerLink]="['/astro-shop']" class="active-text font-weight-bold cursor-pointer">VIEW ALL</span>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6">
        <div class="card border-0  pb-3 f-height">
          <div class="card-header bg-secondary pb-1 border-0">
            <h4 class="text-center text-white text-uppercase fw-600">
              Featured
            </h4>
          </div>
          <div class="card-body p-0  px-3 bg-secondary rounded-bottom" style="min-height:9rem">
            <a [routerLink]="['/product-detail', featuredProduct.product_slug]"><img
                *ngIf="featuredProduct.product_image.length > 0; else noimg" class="w-100 h-100 rounded-top"
                [src]="getSanitizeUrl(featuredProduct.product_image[0])" alt="..." />
              <ng-template #noimg>
                <img class="w-100 h-100" src="../../../assets/images/kundli.png" alt="..." />
              </ng-template>
            </a>
          </div>
          <div class="
              card-footer
              bg-active
              mx-3
              
              border
              px-2
              pt-1
              pb-2
              d-flex
              flex-column
              rouned-bottom
            ">
            <h5 [routerLink]="['/product-detail', featuredProduct.product_slug]"
              class="fn-2 fw-600 text-captilize mt-1 text-black cursor-pointer">
              {{ featuredProduct.productname }}
            </h5>

            <ng-container *ngIf="featuredProduct.is_direct_price; else AstrologerPrice">
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                getCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                &nbsp;<del class="inactive-text small" *ngIf="featuredProduct.is_offer_applied">{{
                  getOrginialPrice(featuredProduct)
                  | currency: (code ? "INR" : "USD"):"symbol-narrow"
                  }}</del>&nbsp;
                <span class="txt-red fw-600" *ngIf="featuredProduct.is_offer_applied">{{
                  featuredProduct.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-container>
            <ng-template #AstrologerPrice>
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                getAstrologerCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                <span class="txt-red" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-template>
            <a [routerLink]="['/order-product', featuredProduct._id]" type="button" class="
                btn
                fw-500
                bg-secondary-2
                rounded
                px-5
                py-2
                m-0
                mt-2
                shadow-0
                dark-text
              ">
              Add To Cart
            </a>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let latest of latestProduct; let i = index">
        <div *ngIf="i < 3" class="col-md-3 col-sm-6">
          <div class="product-grid rounded w-100">
            <div class="product-image">
              <a [routerLink]="['/product-detail', latest.product_slug]" class="image">
                <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
              </a>
              <span class="product-sale-label fn-1 fw-500">{{ latest.offer_percentage }}% OFF</span>
            </div>
            <div class="product-content1">
              <h3 class="fn-2 fw-600 text-uppercase text-black text-left text-truncate">
                <a [routerLink]="['/product-detail', latest.product_slug]">{{ latest.productname }}</a>
              </h3>
              <div class="price fn-1 fw-500 my-1 text-left">
                {{
                getCurrentPrice(latest)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                <span>{{
                  getOrginialPrice(latest)
                  | currency: (code ? "INR" : "USD"):"symbol-narrow"
                  }}</span>
              </div>
              <div class="product-button-group">
                <a class="
                    add-to-cart
                    fn-1
                    fw-500
                    text-uppercase
                    rounded
                    dark-text
                  " [routerLink]="['/order-product', latest._id]"><i class="fa fa-shopping-bag"></i>&nbsp;ADD TO
                  CART</a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<!-- Mobile -->
<section class="py-4 d-lg-none bg-inactive">
  <div class="row d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">latest product</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mt-3 d-flex justify-content-center mx-3">
    <swiper [config]="latestConfig" class="latestSwiper">
      <ng-template swiperSlide *ngFor="let latest of latestProduct; let i = index">
        <div style="width: 17rem" *ngIf="i == 0" class="product-grid rounded bg-secondary p-2">
          <h5 class="text-white text-uppercase fw-500 mt-2">featured</h5>
          <div class="product-image">
            <a [routerLink]="['/product-detail', latest.product_slug]" class="image w-100" style="height: 12rem">
              <img class="pic-1 rounded-top" [src]="getSanitizeUrl(latest.product_image[0])" />
              <img class="pic-2 rounded-top" [src]="getSanitizeUrl(latest.product_image[0])" />
            </a>
            <span class="product-sale-label fn-1 fw-500">{{ latest.offer_percentage }}% OFF</span>
          </div>
          <div class="product-content bg-active mx-2 mb-2 mt-n2 rounded-bottom">
            <h3 class="fn-2 fw-600 text-capitalize text-black">
              <a href="#">{{ latest.productname }}</a>
            </h3>
            <div class="price fn-1 fw-500">
              {{
              getCurrentPrice(latest)
              | currency: (code ? "INR" : "USD"):"symbol-narrow"
              }}
              <span>{{
                getOrginialPrice(latest)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}</span>
            </div>
            <div class="product-button-group">
              <a class="dark-text add-to-cart fn-1 fw-500 text-uppercase rounded bg-secondary-2"
                [routerLink]="['/order-product', latest._id]"><i class="fa fa-shopping-bag"></i>&nbsp;ADD TO CART</a>
            </div>
          </div>
        </div>
        <div style="width: 17rem" *ngIf="i > 0" class="product-grid rounded ml-3">
          <div class="product-image">
            <a href="#" class="image">
              <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
              <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
            </a>
            <span class="product-sale-label fn-1 fw-500">{{ latest.offer_percentage }}% OFF</span>
          </div>
          <div class="product-content">
            <h3 class="fn-2 fw-600 text-capitalize text-black">
              <a href="#">{{ latest.productname }}</a>
            </h3>
            <div class="price fn-1 fw-500 my-1 text-left">
              {{
              getCurrentPrice(latest)
              | currency: (code ? "INR" : "USD"):"symbol-narrow"
              }}
              <span>{{
                getOrginialPrice(latest)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}</span>
            </div>
            <div class="product-button-group">
              <a class="add-to-cart fn-1 fw-500 text-uppercase rounded dark-text"
                [routerLink]="['/order-product', latest._id]"><i class="fa fa-shopping-bag"></i>&nbsp;ADD TO CART</a>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</section>
<!-- End Latest Product -->

<!-- Free Kundli -->
<section class="free-kundli-bg d-none d-lg-block py-5">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center text-white">free kundli</h4>
      <h4 class="text-center bd-white"><span></span></h4>
    </div>
    <div class="row mt-5">
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 mb-2">
        <div class="h-100 card" style="background: #fccd00">
          <div class="card-body">
            <h5 class="mb-1 fw-600">KUNDALI</h5>
            <form [formGroup]="kundliForm" (ngSubmit)="getKundli()">
              <div class="form-group m-0 mb-2">
                <label>Name</label>
                <input class="form-control" formControlName="name" type="text" placeholder="Your name" />
              </div>
              <div class="form-group m-0 mb-2">
                <label>Date of Birth</label>
                <input class="form-control" formControlName="dob" type="date" placeholder="dd/mm/YYYY" [max]="today" />
              </div>
              <div class="form-group m-0 mb-2">
                <label>Time of Birth</label>
                <input class="form-control" formControlName="tob" type="time" placeholder="Time Of Birth" />
              </div>
              <div class="form-group m-0 mb-2">
                <label>Birth Place</label>
                <ng-select [items]="locationList" formControlName="place" bindLabel="name" bindValue="name"
                  (keyup)="getLocation($event)" (change)="selectLocation($event)">
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <p>{{item?.name}} ({{item.country}})</p>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group m-0 mb-2">
                <label>Gender</label>
                <select class="form-control" formControlName="gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div class="d-flex">
                <button type="submit" class="
                    m-0
                    mt-4
                    flex-fill
                    btn
                    text-center text-uppercase
                    kundli-btn
                    p-0
                    py-2
                  " [disabled]="kundliForm.invalid">generate kundali</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 mb-2">
        <div class="h-100 card">
          <div class="card-body" style="padding: 1.25rem 0px">
            <h5 class="text-center fw-600">PANCHANG</h5>
            <p class="text-center fw-500 my-2">
              {{ region }} ( {{ todaysDate }} )
            </p>
            <table class="w-100 table-bordered panchang" *ngIf="panchang != null && panchang != undefined">
              <tr>
                <td>Tithi</td>
                <td>{{ panchang?.tithi?.name }}</td>
              </tr>
              <tr>
                <td>Nakshatra</td>
                <td>{{ panchang?.nakshatra?.name }}</td>
              </tr>
              <tr>
                <td>Karna</td>
                <td>{{ panchang?.karana?.name }}</td>
              </tr>
              <tr>
                <td>Yoga</td>
                <td>{{ panchang?.yoga?.name }}</td>
              </tr>
              <tr>
                <td>Rasi</td>
                <td>{{ panchang?.rasi?.name }}</td>
              </tr>
              <tr>
                <td>Rahukaal</td>
                <td>{{ panchang?.rahukaal }}</td>
              </tr>
              <tr>
                <td>Gulika</td>
                <td>{{ panchang?.gulika }}</td>
              </tr>
              <tr>
                <td>Yamakanta</td>
                <td>{{ panchang?.yamakanta }}</td>
              </tr>
            </table>
            <div class="d-flex mt-3 pt-2 px-2">
              <a [routerLink]="['/panchang']" class="
                  flex-fill
                  btn
                  text-center text-uppercase
                  kundli-btn
                  p-0
                  py-2
                ">view detail</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 mb-2">
        <div class="h-100 card">
          <div class="card-body p-0">
            <img class="kundli" src="../../../assets/images/home/janam-kundli.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="py-4 d-lg-none px-3">
  <div class="d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">free kundli</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mt-3 m-0">
    <div class="col-3 p-1 t-size-mob-kunldi">
      <a (click)="openKundliForm(content)">
        <div class="
            col-12
            p-0
            d-flex
            flex-column
            border
            align-item-center
            rounded
            align-items-center
          ">
          <img src="../../../assets/icons/kundli.png" class="size-40" />
          <span class="text-center active-text text-capitalize fw-400">Kundli</span>
        </div>
      </a>
    </div>
    <div class="col-3 p-1 t-size-mob-kunldi">
      <a [routerLink]="['/panchang']">
        <div class="
            col-12
            p-0
            d-flex
            flex-column
            border
            align-item-center
            rounded
            align-items-center
          ">
          <img src="../../../assets/icons/panchang.png" class="size-40" />
          <span class="text-center active-text text-capitalize fw-400">Panchang</span>
        </div>
      </a>
    </div>
    <div class="col-3 p-1 t-size-mob-kunldi">
      <a [routerLink]="['/prediction']">
        <div class="
            col-12
            p-0
            d-flex
            flex-column
            border
            align-item-center
            rounded
            align-items-center
          ">
          <img src="../../../assets/icons/janam-kundli.png" class="size-40" />
          <span class="text-center active-text text-capitalize fw-400">Prediction</span>
        </div>
      </a>
    </div>
    <div class="col-3 p-1 t-size-mob-kunldi">
      <a [routerLink]="['/match-making']">
        <div class="
            col-12
            p-0
            d-flex
            flex-column
            border
            align-item-center
            rounded
            align-items-center
          ">
          <img src="../../../assets/icons/love-search.png" class="size-40" />
          <span class="text-center active-text text-capitalize fw-400">Match Making</span>
        </div>
      </a>
    </div>
  </div>
</section>

<!--End Free Kundli -->

<!-- Match Making Start -->
<section class="d-none d-lg-block bg-inactive py-5">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">match making & prediction</h4>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div>

    <div class="row mt-5">
      <div class="postion-relative col-lg-6 col-mg-6 col-sm-12 col-12 mt-3">
        <div class="card shadow border-0">
          <div class="p-0 card-body row rounded">
            <div class="p-0 pl-3 col-5 text-center">
              <img class="w-100 h-75 rounded-left" src="../../../assets/images/match.png" />
              <img style="left: 28%; bottom: 24%" class="w-50 position-absolute"
                src="../../../assets/images/love.png" /><br />
              <span style="left: 37%; bottom: 40%" class="text-white fn-3 fw-600 position-absolute">33/35</span>
              <span class="match-text">It's a match</span><br />
              <a [routerLink]="['/match-making']" class="
                  btn
                  text-white
                  px-5
                  py-2
                  mt-3
                  rounded
                  text-capitalize
                  bg-pink
                ">
                Match Now
              </a>
            </div>
            <div class="p-0 col-7 px-1">
              <h3 class="pt-3 text-center">
                <span class="text-pink">MATCH</span> MAKING
              </h3>
              <table class="fw-600 text-center match-table">
                <tr>
                  <td>Matchmaking is the key to finding the will of heaven. You can now match any two charts at the
                    click of a button using AstroRiver's exclusive and completely free matchmaking service.
                  </td>
                </tr>
                <tr>
                  <td>The AI does all the work. All you need to do is enter the birth details of yourself and your
                    partner, and our software will generate a detailed report within seconds.

                  </td>
                </tr>
                <tr>
                  <td class="border-bottom-0">Get a detailed analysis of each of the 36 Ashtakoota parameters and
                    Dashakoota points.
                    If you still have difficulty understanding it, our expert Astrologers are here to help.

                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--Daily prediction code commented after 04-06-2022 meeting with client-->
      <!-- <div class="col-lg-6 col-mg-6 col-sm-12 col-12 mt-3 xs-margin-top-6">
        <div class="h-100 card shadow border-0" style="padding: 0.1rem">
          <div class="card-header bg-active d-flex">
            <h4 class="active-text fw-500 text-uppercase w-100">
              daily
              <span class="text-black">prediction</span>
            </h4>
            <div class="w-100 text-right">
              <a class="rounded bg-secondary text-white px-2 py-1 mr-1 fn-2" (click)="previousSign()"
                [class.cus-disabled]="signIndex == 0"><i class="fas fa-chevron-left"></i></a><a
                class="rounded bg-secondary text-white px-2 py-1 ml-1 fn-2"
                [class.cus-disabled]="signIndex == originalZodiaList.length - 1" (click)="nextSign()"><i
                  class="fas fa-chevron-right"></i></a>
            </div>
          </div>

          <div class="pb-0 card-body row">
            <div class="text-center col-lg-3 col-md-3 col-sm-12 col-12">
              <img class="mb-2 zodiac-image" [src]="getSanitizeUrl(zodiacImage)" />
              <h5 class="text-uppercase mt-1 active-text fn-2 fw-500">
                {{ signName }}
              </h5>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 col-12">
              <p class="fw-400 card-text text-justify">
                {{ singleZodiacSign?.description }}
              </p>
            </div>
            <div class="col-12 mt-2">
              <table class="w-100 text-center text-capitalize horoscope-table">
                <tr colspan="1" class="bg-secondary" style="height: 2rem">
                  <td class="text-white border border-white w-50">
                    lucky number
                  </td>
                  <td class="text-white border border-white">lucky color</td>
                </tr>
                <tr colspan="2" style="background: #ddd; height: 2rem">
                  <td class="border border-white w-50">
                    {{ singleZodiacSign?.lucky_number }}
                  </td>
                  <td class="border border-white">
                    {{ singleZodiacSign?.color }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="card-footer bg-active border-0 d-flex flex-column">
            <a [routerLink]="['/daily-horoscope']"
              class="btn m-0 mb-3 py-2 fn-1 dark-text text-center text-uppercase bg-secondary-2 rounded">
              <i class="fas fa-search"></i>&nbsp;&nbsp;&nbsp;find your own
              sunsign
            </a>
          </div>
        </div>
      </div> -->
      <!--Added Weekly Prediction Code after 04-06-2022 Meeting with client-->
      <ng-template #content let-c="close" let-d="dismiss">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h-100 card" style="background: #fccd00">
              <div class="card-body">
                <h5 class="mb-1 fw-600">KUNDALI</h5>
                <form [formGroup]="kundliForm" (ngSubmit)="getKundli()">
                  <div class="form-group m-0 mb-2">
                    <label>Name</label>
                    <input class="form-control" formControlName="name" type="text" placeholder="Your name" />
                  </div>
                  <div class="form-group m-0 mb-2">
                    <label>Date of Birth</label>
                    <input class="form-control" formControlName="dob" type="date" placeholder="dd/mm/YYYY"
                      [max]="today" />
                  </div>
                  <div class="form-group m-0 mb-2">
                    <label>Time of Birth</label>
                    <input class="form-control" formControlName="tob" type="time" placeholder="Time Of Birth" />
                  </div>
                  <div class="form-group m-0 mb-2">
                    <label>Birth Place</label>
                    <ng-select [items]="locationList" formControlName="place" bindLabel="name" bindValue="name"
                      (keyup)="getLocation($event)" (change)="selectLocation($event)">
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <p>{{item?.name}} ({{item.country}})</p>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="form-group m-0 mb-2">
                    <label>Gender</label>
                    <select class="form-control" formControlName="gender">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div class="d-flex">
                    <button type="submit" class="
                        m-0
                        mt-4
                        flex-fill
                        btn
                        text-center text-uppercase
                        kundli-btn
                        p-0
                        py-2
                      " [disabled]="kundliForm.invalid">generate kundali</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="col-lg-6 col-mg-6 col-sm-12 col-12 mt-3 xs-margin-top-6">
        <div class="h-100 card shadow border-0" style="padding: 0.1rem">
          <div class="card-header bg-active d-flex">
            <h4 class="active-text fw-500 text-uppercase w-100">
              Weekly <span class="text-black">Horoscope</span>
            </h4>
          </div>

          <div class="pb-0 card-body row">
            <div class="col-12 mt-2">
              Want to know what this week has in store for you? Check out our exclusive free online weekly horoscope and
              get detailed predictions about your love life, career, and more. Click to access your personalized online
              horoscope.

            </div>
          </div>

          <div class="card-footer bg-active border-0 d-flex flex-column">
            <a [routerLink]="['/prediction']"
              class="btn m-0 mb-3 py-2 fn-1 dark-text text-center text-uppercase bg-secondary-2 rounded">
              <i class="fas fa-search"></i>&nbsp;&nbsp;&nbsp;Read All Predictions
            </a>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!--/.portfolio-area-->
  </div>
</section>
<!-- Match Making End -->

<!-- Our services Start -->
<section class="py-5 d-none d-lg-block">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">Our Services
      </h4>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div>
    <div class="row mt-5">
      <div class="main-timeline">
        <!-- start experience section-->
        <div class="timeline">
          <div class="icon icon-1"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date"> 01 </span>
              <div [routerLink]="['/chat-astro-list']"
                class="rounded-circle position-absolute icon-img glowing-icon cursor-pointer">
                <img src="../../../assets/icons/wifi-w.png" />
              </div>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Live Consultations</h5>
            <p class="description">
              Consult Live With An Astrologer/Expert From The Privacy of Your Home. Call an Astrologer or Chat with an
              Astrologer using the AstroRiver website or iOS/Android apps. It can't get easier than this!
            </p>
          </div>
        </div>
        <!-- end experience section-->

        <!-- start experience section-->
        <div class="timeline">
          <div class="icon icon-2"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date text-right"> 02 </span>
              <div [routerLink]="['/astro-shop']" class="rounded-circle position-absolute icon-img cursor-pointer"
                style="left: 55%">
                <img src="../../../assets/icons/namaste-w.png" />
              </div>
            </div>
          </div>
          <div class="timeline-content text-start">
            <h5 class="title">Online Pujans
            </h5>
            <p class="description">
              Online Rituals & Poojas performed at various holy places across India, personalised for you. Detailed,
              Powerful Yajnas & Poojans Conducted & Performed by Highly Experienced Brahmins all under AstroRiver's able
              guidance.

            </p>
          </div>
        </div>
        <!-- end experience section-->

        <!-- start experience section-->
        <div class="timeline">
          <div class="icon icon-3"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date"> 03 </span>
              <div [routerLink]="['/report-astro-list']"
                class="rounded-circle position-absolute icon-img cursor-pointer">
                <img class="pl-1 pt-1" src="../../../assets/icons/result-w.png" />
              </div>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Detailed Astrology Reports
            </h5>
            <p class="description">
              Get the best Astrological Reports, Completely Personalised & Written by Highly Experienced Astrologers.
              Know all about your future and be ready for what's coming your way and stay prepared for it!

            </p>
          </div>
        </div>
        <!-- end experience section-->

        <!-- start experience section-->
        <div class="timeline">
          <div class="icon icon-4"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date text-right"> 04 </span>
              <div [routerLink]="['/astro-shop']" class="rounded-circle position-absolute icon-img cursor-pointer"
                style="left: 55%">
                <img src="../../../assets/icons/fire-w.png" />
              </div>
            </div>
          </div>
          <div class="timeline-content text-start">
            <h5 class="title">Vedic Remedies
            </h5>
            <p class="description">
              Powerful, Inexpensive & Time-Tested Vedic Remedies chosen/prepared by the best on AstroRiver's panel.
              Discover the finest astrological gemstones, rudrakshas, yantras and much more exclusively on AstroShop.

            </p>
          </div>
        </div>
        <!-- end experience section-->
      </div>
    </div>
  </div>
</section>
<section class="py-4 d-lg-none bg-inactive px-3">
  <div class="d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">our services</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="d-flex flex-column m-serv text-center mt-3 align-items-center">
    <div [routerLink]="['/chat-astro-list']"
      class="mt-4 mb-3 p-1 rounded-circle glowing-icon glowing-icon cursor-pointer">
      <img class="bg-secondary rounded-circle w-100 size-60 p-2" src="../../../assets/icons/wifi-w.png" />
    </div>
    <h5 class="fn-2 fw-500 my-2">Live Consultations
    </h5>
    <p class="fn-1 text-justify">
      Consult Live With An Astrologer/Expert From The Privacy of Your Home. Call an Astrologer or Chat with an
      Astrologer using the AstroRiver website or iOS/Android apps. It can't get easier than this!

    </p>
    <div [routerLink]="['/astro-shop']" class="mt-4 mb-3 p-1 rounded-circle glowing-icon cursor-pointer">
      <img class="bg-secondary rounded-circle w-100 size-60 p-2" src="../../../assets/icons/namaste-w.png" />
    </div>
    <h5 class="fn-2 fw-500 my-2">Online Pujans
    </h5>
    <p class="fn-1 text-justify">
      Online Rituals & Poojas performed at various holy places across India, personalised for you. Detailed, Powerful
      Yajnas & Poojans Conducted & Performed by Highly Experienced Brahmins all under AstroRiver's able guidance.

    </p>
    <div [routerLink]="['/report-astro-list']" class="mt-4 mb-3 p-1 rounded-circle glowing-icon cursor-pointer">
      <img class="bg-secondary rounded-circle w-100 size-60 p-2" src="../../../assets/icons/result-w.png" />
    </div>
    <h5 class="fn-2 fw-500 my-2">Detailed Astrology Reports
    </h5>
    <p class="fn-1 text-justify">
      Get the best Astrological Reports, Completely Personalised & Written by Highly Experienced Astrologers. Know all
      about your future and be ready for what's coming your way and stay prepared for it!

    </p>
    <div [routerLink]="['/astro-shop']" class="mt-4 mb-3 p-1 rounded-circle glowing-icon cursor-pointer">
      <img class="bg-secondary rounded-circle w-100 size-60 p-2" src="../../../assets/icons/fire-w.png" />
    </div>
    <h5 class="fn-2 fw-500 my-2">Vedic Remedies
    </h5>
    <p class="fn-1 text-justify">
      Powerful, Inexpensive & Time-Tested Vedic Remedies chosen/prepared by the best on AstroRiver's panel. Discover the
      finest astrological gemstones, rudrakshas, yantras and much more exclusively on AstroShop.

    </p>
  </div>
</section>
<!-- Our services End -->

<!-- How it works Start -->
<section class="d-none d-lg-block bg-inactive py-5">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">how astroRiver works</h4>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div>

    <div class="row mt-5">
      <div [routerLink]="['/login']" class="
          col-lg-4 col-md-4 col-sm-12 col-12
          px-5
          mt-3
          text-center
          steps pointer
        ">
        <img class="hw-i-w" style="border-color: #2492a3" src="../../../assets/images/home/signin.jpg" />
        <h5 class="fw-500 text-capitalize mt-3 text-center">
          <span class="active-text">step 1: </span>register yourself
        </h5>
        <span></span>
      </div>
      <div [routerLink]="['/recharge-wallet']" class="
          col-lg-4 col-md-4 col-sm-12 col-12
          px-5
          mt-3
          text-center
          steps pointer
        ">
        <img class="hw-i-w" style="border-color: #609d25" src="../../../assets/images/home/wallet.jpg" />
        <h5 class="fw-500 text-capitalize mt-3 text-center mx-n3">
          <span class="active-text">step 2: </span>recharge your wallet
        </h5>
        <span></span>
      </div>
      <div [routerLink]="['/call-astro-list']" class="col-lg-4 col-md-4 col-sm-12 col-12 px-5 mt-3 text-center pointer">
        <img class="hw-i-w" style="border-color: #ffb514" src="../../../assets/images/home/cont-us.jpg" />
        <h5 class="fw-500 text-capitalize mt-3 text-center mx-n3">
          <span class="active-text">step 3: </span>contact our expert
        </h5>
      </div>
    </div>
  </div>
</section>
<section class="d-lg-none py-4">
  <div class="d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">how astroRiver works</h5>
    <h4 class=""><span></span></h4>
  </div>

  <div class="row mt-3 d-flex flex-column align-items-center">
    <div [routerLink]="['/login']" class="px-5 text-center m-steps position-relative">
      <img class="hw-i-w" style="border-color: #2492a3" src="../../../assets/images/home/signin.jpg" />
      <h5 class="fw-500 text-capitalize mt-3 text-center">
        <span class="active-text">step 1: </span>register yourself
      </h5>
      <span></span>
    </div>
    <div [routerLink]="['/recharge-wallet']" class="px-5 text-center m-steps position-relative mt-5">
      <img class="hw-i-w" style="border-color: #609d25" src="../../../assets/images/home/wallet.jpg" />
      <h5 class="fw-500 text-capitalize mt-3 text-center mx-n3">
        <span class="active-text">step 2: </span>recharge your wallet
      </h5>
      <span></span>
    </div>
    <div [routerLink]="['/call-astro-list']" class="px-5 text-center mt-5">
      <img class="hw-i-w" style="border-color: #ffb514" src="../../../assets/images/home/cont-us.jpg" />
      <h5 class="fw-500 text-capitalize mt-3 text-center mx-n3">
        <span class="active-text">step 3: </span>contact our expert
      </h5>
    </div>
  </div>
</section>
<!-- How it works End -->

<!-- Visson-Mission Start -->

<section class="d-none d-lg-block my-5">
  <div class="row new-product d-flex flex-column">
    <h4 class="text-capitalize text-center">about astro river</h4>
    <h4 class="text-center bd-dark"><span></span></h4>
  </div>
  <div class="row mt-5">
    <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-justify story">
      <h2 class="text-capitalize fw-500 text-white fw-600">our story</h2>
      <p class="text-white mt-3">
        The name ‘AstroRiver’ is inspired from ‘Akash Ganga’, an endless stream of planets contained in the galaxy
        ‘Milky Way’. The planets and their synchronised movement across the sky is nature's plan, and they influence our
        lives in invisible ways. Ancient sages have long striven to understand their language – “the language of stars”'
        and brought to us the blessing of Astrology.
        AstroRiver was founded by Dr Armaan Sharrma, a Vedic Astrologer under the guidance of his father, Sh. Manmohan
        Kumar (d. 2012) who was an exceptional Astrologer with an experience of over 40+ years. Their vision was quite
        simple - to provide 'genuine' astrological services to as many people as possible in the world. Astrology is
        mostly misunderstood as it no more a part of our educational curriculum due to the British Raj (which has
        thankfully ended) and many misuse it to create fear in people's mind.
        Since then, AstroRiver has become a one stop destination serving over 50 lakh+ clients globally and offers live
        consultations with some of the best astrologers in the world, online puja services, and detailed reports on
        various aspects of life.

      </p>
      <a [routerLink]="['/about-us']" class="
          fn-1
          bg-secondary-2
          btn
          text-uppercase
          border border-white
          rounded
          px-5
          py-2
          fw-600
          m-0
          mt-3
        ">
        know more
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-justify vision">
      <h2 class="text-capitalize fw-500 fw-600">our vision</h2>
      <p class="mt-3">
        AstroRiver's vision is to make astrology accessible and helpful to as many people as possible. We strive to
        provide quality astrological services at an affordable price so that everyone can benefit from the knowledge and
        guidance that astrology has to offer. Our Vision is to bring the most accurate astrological predictions to your
        doorstep coupled with an amazing customer service.
        AstroRiver emerged as a movement with the aim of preserving traditional astrological values while also
        incorporating modern solutions to issues like stress, mental health and similar others which are a part of
        today's fast life. All living beings are continuously bathed in various energies which define our daily lives.
        Nature has blessed us with a powerful tool to study the impact of energies around us and even influence them to
        some extent through remedies. Astrology is truly a blessing.
        Our mission is to make your life easier and to put you back in control of your life. It's completely possible by
        an amalgamation of astrological guidance, mental techniques and some discipline. Astrology alone can give you
        peace of mind and prepare you for the best and the worst.

      </p>
      <a [routerLink]="['/about-us']" class="
          fn-1
          btn
          bg-secondary
          text-white text-uppercase
          border border-white
          rounded
          px-5
          py-2
          fw-600
          m-0
          mt-3
          m-mob
        ">
        know more
      </a>
    </div>
  </div>
</section>
<section class="py-4 d-lg-none bg-inactive">
  <div class="d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">about astro river</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mis-vis mx-3 mt-3 d-flex justify-content-center">
    <div class="position-relative mr-2">
      <img height="{{ halfWidth }}" width="{{ halfWidth }}" src="../../../assets/images/home/hand.jpg"
        class="rounded" />
      <h5 class="
          position-absolute
          w-100
          text-center text-capitalize
          fw-600
          rounded-bottom
        ">
        <a [routerLink]="['/about-us']" class="text-dark"> Our Story</a>
      </h5>
    </div>
    <div class="position-relative ml-2">
      <img height="{{ halfWidth }}" width="{{ halfWidth }}" src="../../../assets/images/home/idea.jpg"
        class="rounded" />
      <h5 class="
          position-absolute
          w-100
          text-center text-capitalize
          fw-600
          rounded-bottom
        ">
        <a [routerLink]="['/about-us']" class="text-dark">Our Vision</a>
      </h5>
    </div>
  </div>
</section>
<!-- Visson-Mission End -->
<!-- hs Counter wrapper Start -->
<section class="d-none d-lg-block">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">our success count</h4>
      <h4 class="text-center bd-dark"><span></span></h4>
    </div>
    <div class="row my-5">
      <div class="col position-relative counter">
        <div class="position-absolute counter-img">
          <img class="w-100" src="../../../assets/new_icon/customer.png" />
        </div>
        <h4 class="font-anton">120+</h4>
        <circle-progress [percent]="100" [radius]="75" [outerStrokeWidth]="12" [innerStrokeWidth]="0"
          [outerStrokeColor]="'#ca0715'" [innerStrokeColor]="'#C7E596'" [animation]="true" [showImage]="true"
          [animationDuration]="300"></circle-progress>
        <h5 class="text-center mt-3 fw-500">
          Million trusted clients across the Globe
        </h5>
      </div>

      <div class="col position-relative counter">
        <div class="position-absolute counter-img">
          <img class="w-100" src="../../../assets/new_icon/calendar.png" />
        </div>
        <h4 class="font-anton">200+</h4>
        <circle-progress [percent]="100" [radius]="75" [outerStrokeWidth]="12" [innerStrokeWidth]="0"
          [outerStrokeColor]="'#ca0715'" [innerStrokeColor]="'#C7E596'" [animation]="true" [showImage]="true"
          [animationDuration]="300"></circle-progress>
        <h5 class="text-center mt-3 fw-500">
          Years of<br />
          Experience
        </h5>
      </div>
      <div class="col position-relative counter">
        <div class="position-absolute counter-img">
          <img class="w-100" src="../../../assets/new_icon/hand_scope.png" />
        </div>
        <h4 class="font-anton">450+</h4>
        <circle-progress [percent]="100" [radius]="75" [showImage]="true" [outerStrokeWidth]="12" [innerStrokeWidth]="0"
          [outerStrokeColor]="'#ca0715'" [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300">
        </circle-progress>
        <h5 class="text-center mt-3 fw-500">
          Types of <br />
          Horoscopes
        </h5>
      </div>
      <div class="col position-relative counter">
        <div class="position-absolute counter-img">
          <img class="w-100" src="../../../assets/new_icon/diploma.png" />
        </div>
        <h4 class="font-anton">150+</h4>
        <circle-progress [percent]="100" [radius]="75" [showImage]="true" [outerStrokeWidth]="12" [innerStrokeWidth]="0"
          [outerStrokeColor]="'#ca0715'" [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300">
        </circle-progress>
        <h5 class="text-center mt-3 fw-500">
          Qualified <br />
          Astrologers
        </h5>
      </div>
      <div class="col position-relative counter">
        <div class="position-absolute counter-img">
          <img class="w-100" src="../../../assets/new_icon/success.png" />
        </div>
        <h4 class="font-anton">100+</h4>
        <circle-progress [percent]="100" [radius]="75" [showImage]="true" [outerStrokeWidth]="12" [innerStrokeWidth]="0"
          [outerStrokeColor]="'#ca0715'" [innerStrokeColor]="'#C7E596'" [animation]="true" [animationDuration]="300">
        </circle-progress>
        <h5 class="text-center mt-3 fw-500">
          Success<br />
          Horoscope
        </h5>
      </div>
    </div>
  </div>
</section>
<!-- Message from CEO Start -->
<section class="border-top d-none d-lg-block my-5">
  <div class="container">
    <div class="row mt-5">
      <div class="col-lg-6 col-md-6 col-12 col-sm-12 postion-relative">
        <img src="../../../assets/images/nisha-profile.jpg" class="w-100 border border-white rounded c-shadow" />
        <img src="../../../assets/images/home/ceo-b.jpg" class="
            w-50
            border border-white
            rounded
            c-shadow
            position-absolute
            d-lg-block d-md-block
          " style="left: -10%; bottom: 0%" />
      </div>
      <div class="col-lg-6 col-md-6 col-12 col-sm-12 d-flex flex-column">
        <h3 class="fw-600 text-uppercase mb-2">message from CEO</h3>
        <p class="text-justify d-inline-block w-100 mt-2">
          After spending over 10+ years with AstroRiver, I feel very privileged to be writing this message as the CEO.
          During this journey, I have also had the privilege of being the Founder of <strong><em>Astro
              Jewels</em></strong>, our line of fine astrological jewellery and exquisite gemstones.
          <strong><em>Astro River</em></strong> began it's journey in 2006, and our team has spent the past decade
          continuing to touch human lives, helping them to grow and progress, achieve peace of mind and solve their
          love, career and health challenges through the sheer power of Astrology. We are proud to have reached 50 Lakh+
          customers around the globe (that's a Million!) And we're not done yet!
        </p>
        <p class="text-justify d-inline-block w-100 mt-2">AstroRiver has always been about so much more than just
          providing world-class astrological services. It's also
          AstroRiver is much more than just an astrology app, it is a way of life.</p>
        <p class="text-justify d-inline-block w-100 mt-2">Our team of dedicated professionals comprising world-class
          Astrologers, Counsellors, Life Coaches and
          Consultants specialising in different fields strive to offer the best possible insights and guidance about
          your love life, career, or anything else you might need help with. We are constantly innovating and expanding
          our services to make sure that we can offer you the best possible astrological insights and guidance.</p>
        <p>
          <a [routerLink]="['/message-ceo']" class="
              fn-1
              m-0
              btn
              bg-secondary-2
              text-uppercase
              rounded
              dark-text
              px-5
              py-3
              fw-600
              mt-3
            ">know more
          </a>
        </p>
      </div>
    </div>
  </div>
</section>
<section class="py-4 d-lg-none">
  <div class="d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">message from ceo</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class=" align-items-top mt-4 px-3">
    <div class="msg-ceo">
      <img src="../../../assets/images/nisha-profile.jpg" class="w-100 border rounded" />
      <h5 class="fw-500 text-center my-2">Nisha Sharma</h5>
    </div>
    <div class="flex-grow-1 ml-2 line-height-5 text-justify">
      Dr Nisha is one of the Senior Astrologers in the Astro River’s team. She
      works as the Managing Director of Astro River, and is the founder of Astro
      Jewels, a subsidiary of Astro River which deals exclusively in fine
      Gemstones and astrological jewellery. She is an International Astrologer
      and an experienced Gemologist with 12+ years of experience in various
      occult fields, especially in Astrology, Vastu & Tarot. In her illustrious
      astrological career, she has advised numerous celebrities, politicians and
      people from various walks of life. Ms. Sharma is also an author. Her works
      have been recognized and duly rewarded by many reputed occult
      organisations across the globe. She has also spoken at various platforms
      and teaches Astrology and Tarot with utmost passion.
      <a [routerLink]=" ['/message-ceo']" class="
          text-white
          btn
          shadow-0
          bg-secondary-2
          rounded
          border-white
          dark-text
        ">Know More</a>
    </div>
  </div>
</section>

<!-- Message from CEO End -->

<!-- Testimonial Start -->
<section class="bg-secondary d-none d-lg-block py-5">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center text-white">
        what our clients say
      </h4>
      <h4 class="text-center bd-white"><span class="bg-active"></span></h4>
    </div>

    <div class="row position-relative text-center">
      <div class="font-anton text-uppercase astro-river position-absolute w-100">
        astro river
      </div>
      <mdb-carousel [isControls]="true" class="
          mt-5
          d-none d-lg-block d-md-block
          carousel-multi-item
          multi-animation
          testi-carousel
        " [type]="'carousel-multi-item'" [animation]="'slide'">
        <mdb-carousel-item class="row" *ngFor="let testimonial of testimonialList">
          <ng-container class="" *ngFor="let item of testimonial">
            <div class="col-4">
              <div class="bubble text-justify p-4 fn-1">
                <i class="fi fi-quote-a-left position-absolute"></i>
                <i class="fi fi-quote-a-right position-absolute"></i>
                <p class="line-size-8 mx-2">
                  {{ item?.description }}
                </p>
              </div>
              <div class="bubble-pointer"></div>
              <div class="ml-3 d-flex">
                <img src="../../../assets/images/header/top_user.jpg"
                  class="size-60 rounded-circle border border-white" />
                <div class="d-flex flex-column ml-2">
                  <h4 class="fn-2 fw-600 secondary-text text-capitalize">
                    {{ item?.person_name }}
                  </h4>

                  <span class="text-white fn-1">{{
                    item?.person_location
                    }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</section>
<section class="py-3 d-lg-none bg-inactive">
  <div class="d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">what our clients say</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mt-4 mx-3">
    <swiper [config]="blogConfig" class="">
      <ng-template swiperSlide *ngFor="let item of testimonialData">
        <div class="d-flex flex-column px-1 h-100">
          <div class="bubble text-justify p-4 fn-1 shadow" style="height: 10.5rem">
            <i class="fi fi-quote-a-left position-absolute"></i>
            <i class="fi fi-quote-a-right position-absolute"></i>
            <p class="line-size-4">
              {{ item.description }}
            </p>
          </div>
          <div class="bubble-poin mt-4 position-absolute" style="bottom: 17% !important"></div>
          <div class="ml-3 d-flex mt-2">
            <img src="../../../assets/images/header/top_user.jpg" class="size-60 rounded-circle border border-white" />
            <div class="d-flex flex-column ml-2">
              <h4 class="fn-2 fw-600 secondary-text mob-txt-dark">{{ item.person_name }}</h4>
              <span class="fn-1">{{ item.person_location }}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</section>
<!-- Testimonial End -->

<!-- Blogs Start -->
<section class="my-lg-5 my-3" *ngIf="blogs.length > 0">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="d-none d-lg-block fw-600 text-capitalize text-center">
        latest blog post
      </h4>
      <h5 class="d-lg-none fw-600 text-capitalize text-center">
        latest blog post
      </h5>
      <h4 class=""><span></span></h4>
    </div>

    <div class="row mt-lg-5 mt-md-3 mt-xs-3 mt-sm-3 glob-blog">
      <div class="card border-0 col-3 p-0 d-none d-lg-block">
        <div class="card-header bg-secondary border-0">
          <h4 class="fn-2 fw-600 text-center text-uppercase text-white">
            FEATURED
          </h4>
        </div>
        <div class="card-body p-0 px-3 bg-secondary rounded-bottom" style="height: 15rem">
          <img class="w-100 h-100" [src]="getSanitizeUrl(featuredBlog.blogImage)" />
        </div>
        <div class="
            card-footer
            bg-active
            mx-3
            shadow-3
            border
            px-2
            pt-1
            pb-2
            d-flex
            flex-column
          ">
          <small class="active-text cursor-pointer"
            [routerLink]="['/blog-categories', featuredBlog?.categoryId.categoryname, featuredBlog?.categoryId._id]">{{
            featuredBlog?.categoryId.categoryname
            }}</small>
          <h4 class="fw-600 py-1">
            {{ featuredBlog.blogTitle }}
          </h4>
          <p class="text-muted fn-0">{{featuredBlog?.userName}} /<i
              class="fa fa-calendar text-warning mx-1"></i>{{featuredBlog?.created_at |
            date:'mediumDate'}}</p>

          <p class="blog-desc fn-1" [innerHTML]="featuredBlog.blogsmallDescription"></p>
          <br />
          <a [routerLink]="['/blog', featuredBlog.slug]" class="
              shadow-0
              btn
              bg-secondary-2
              text-capitalize
              
              fw-500
              dark-text
            ">
            Know More
          </a>
        </div>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12 col-12 ">
        <div class="border-bottom m-2 pb-2 d-flex align-items-top" *ngFor="let blog of blogs.slice(0, 3)">
          <div class="d-lg-none mr-1">
            <img class="rounded" width="{{ blogImage }}" height="{{ blogImage }}"
              [src]="getSanitizeUrl(blog?.blogImage)" />
          </div>
          <div class="flex-grow-1 d-flex flex-column ml-1 ">
            <h4 [routerLink]="['/blog-categories', blog?.categoryId?.categoryname, blog?.categoryId?._id]"
              class="fn-2 fw-500 mb-1 active-text cursor-pointer">
              {{ blog?.categoryId.categoryname }}
            </h4>

            <h5 [routerLink]="['/blog', blog.slug]" class="fn-1 cursor-pointer font-weight-bold mb-1">{{ blog?.blogTitle
              }}</h5>
            <small class="inactive-text">{{blog?.userName}} / {{
              blog?.created_at | date: "medium"
              }}</small>
            <p class="line-size-3 fn-1 blg-text" [innerHTML]="blog?.blogsmallDescription"></p>
            <span><a [routerLink]="['/blog', blog.slug]" class="
                  btn
                  shadow-0
                  bg-secondary-2
                  text-capitalize
                  px-3
                  py-2
                  dark-text
                  rounded
                  m-0
                  my-1
                " type="button">
                Know more <i class="fas fa-chevron-right"></i>
              </a>
            </span>
          </div>
          <div class="d-none d-lg-block">
            <img class="blog-image" [src]="getSanitizeUrl(blog?.blogImage)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Blogs End -->
<!-- With Mobile Banner -->
<section class="mt-5 position-relative d-none d-lg-block">
  <img class="w-100" src="../../../assets/images/home/mobile-bg.jpg" />
  <div class="d-flex align-items-start flex-column w-50 position-absolute" style="top: 25%; left: 8%">
    <h2 class="text-capitalize text-white fn-3 fw-500">Download & Enjoy</h2>
    <p class="fn-1 text-white fw-500 my-2">
      Whether you're wondering about your love life, career prospects, or anything else, our expert astrologers can help
      you find answers. And because our consultants are available 24/7, you can consult with us anytime, anywhere. So
      why wait? Get started on your journey to self-discovery today with the AstroRiver App. Download today!***

    </p>
    <div class="d-flex">
      <a target="_blank" href="https://apps.apple.com/in/app/astroriver/id1575901251" class="
          btn
          bg-secondary-2
          text-uppercase
          fw-600
          py-2
          dark-text
          fn-2
          rounded
          border border-white
          mr-2
        ">
        <i class="fab fa-apple fn-4 top-align"></i>&nbsp;&nbsp;app store
      </a>
      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.astroriver.customer" class="
          btn
          dark-text
          bg-secondary-2
          text-uppercase
          fw-600
          py-2
          fn-2
          rounded
          border border-white
        ">
        <i class="fab fa-google-play fn-4 top-align"></i>&nbsp;&nbsp;google play
      </a>
    </div>
  </div>

  <img src="../../../assets/images/home/mobile.png" class="row position-absolute mobile-image" />
</section>
<!-- END -->

<!--fotter-->
<app-footer></app-footer>

<!--fotter-->