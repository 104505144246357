<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">View Cart</h3>
            <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                </li>
                <li>
                    <span class="fn-2 material-icons-round bottom-align">
                        arrow_forward_ios
                    </span>
                </li>

                <li>View Cart</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<!-- container start -->
<section class="container" *ngIf="dataList?.products?.length > 0">
    <div class="table-responsive mt-5">
        <h5 class="mb-2"><b>Your Orders</b></h5>
        <table class="table cart-th table-bordered">
            <!-- table heading Start here -->
            <tr class="th-row">
                <th>Items</th>
                <th>Qty.</th>
                <th>Price</th>
                <th>Add-ons Price</th>
                <th>Sub-Total</th>
                <th>Coupon Discount</th>
                <th>GST(%)</th>
                <th>Total</th>
                <th>Action</th>
            </tr>
            <!-- table heading End here -->

            <!-- table body Start here -->
            <tr *ngFor="let product of dataList?.products">
                <td class="p-0">
                    <div class="d-flex">

                        <div class="col-md-3 p-2">
                            <img [src]="getSanitizeUrl(product?.orderProductId?.productId?.product_image?.[0])"
                                class="w-100 ps-0" />
                        </div>
                        <div class="align-middle">
                            <div class=" col p-2">
                                <h6 class=" mb-0 text-truncate">{{product?.orderProductId?.productId?.productname}}</h6>
                                <span *ngIf="product?.orderProductId?.addOns?.length > 0"
                                    class="text-info font-weight-semibold pointer"
                                    (click)="openAddOnPopUp(addOnPopUp,product?.orderProductId?.addOns)"><u>See
                                        Add-ons</u></span>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="col-md-12 quantity"
                        *ngIf="product?.orderProductId?.productId?.fields_applied?.isQuantityOn;else noQuantity">
                        <!-- <input id="quantity" type="number" [value]="product?.orderProductId?.userDetails?.quantity" class="form-control quantity-input" (change)="inceDecQuantity(product?.orderProductId?._id,$event)" min="1"> -->
                        <div class="d-flex flex-row qty"><span (click)="inceDecQuantity(product?.orderProductId,false)"
                                class="minus bg-dark">-</span>
                            <input type="number" class="count text-center pl-2"
                                [value]="product?.orderProductId?.userDetails?.quantity" min="1" size="2" disabled>
                            <span (click)="inceDecQuantity(product?.orderProductId,true)" class="plus bg-dark">+</span>
                        </div>
                    </div>
                    <ng-template #noQuantity>
                        <div class="col-md-12 quantity text-center">
                            1
                        </div>
                    </ng-template>
                </td>
                <td>{{ product?.orderProductId?.subTotal | currency: (code ? "INR" :
                    "USD"):"symbol-narrow"}}</td>
                <td>{{ product?.orderProductId?.addOnsTotal | currency: (code ? "INR" :
                    "USD"):"symbol-narrow"}}</td>
                <td>{{ product?.orderProductId?.payableAmountWithoutGst | currency: (code ? "INR" :
                    "USD"):"symbol-narrow"}}</td>
                <td>{{product?.orderProductId?.couponDiscount | currency: (code ? "INR" : "USD"):"symbol-narrow"}}</td>
                <td>{{product?.orderProductId?.gst | currency: (code ? "INR" : "USD"):"symbol-narrow"}}
                    ({{product?.orderProductId?.gstPercent}}%)</td>
                <td class="text-success font-weight-bold">{{product?.orderProductId?.grandTotal | currency: (code ?
                    "INR" :
                    "USD"):"symbol-narrow"}}</td>
                <td><i class="material-icons text-danger pointer"
                        (click)="removeProductFromCart(product?.orderProductId?._id)">delete</i></td>
            </tr>
            <!-- table body end here -->


        </table>
    </div>


    <div class="container p-0 mt-3">
        <div class="row gx-1">
            <div *ngIf="!dataList?.isCouponApplied;else appliedCoupon"
                [ngClass]="{'col-md-6':dataList?.addressId != null,'col-md-12':dataList?.addressId == null}">
                <div class="coupon mt-5">
                    <input type="text" id="couponCode" class="form-control pl-0" placeholder="Enter Coupon Code">
                    <button class="btn btn-danger border-0 w-40" (click)="applyCoupon()">Apply</button>
                </div>
            </div>
            <ng-template #appliedCoupon>
                <div [ngClass]="{'col-md-6':dataList?.addressId != null,'col-md-12':dataList?.addressId == null}">
                    <ngb-alert class="mt-4 py-3" (closed)="removeCoupon()" type="success">
                        <strong class="text-uppercase text-dark">{{dataList?.couponCode}}</strong><br>
                        You save saved additional {{dataList?.couponDiscount | currency: (code ? "INR" :
                        "USD"):"symbol-narrow"}}.
                    </ngb-alert>
                </div>
            </ng-template>
            <div class="col-md-6" *ngIf="dataList?.addressId != null">
                <div class=" d-flex justify-content-end">
                    <div class="">
                        <button class="btn btn-info" (click)="openAddressPopup(address)">Change Address</button>
                    </div>
                </div>
                <div class="shadow-sm py-3 border border-info rounded pl-3 mt-2">
                    <i class="fa-solid fa-location-dot"></i>
                    <span class="text-muted">
                        {{ selectedAddress.name }} - {{ selectedAddress.flatNo }},
                        {{ selectedAddress.locality }}, {{ selectedAddress.city }},
                        {{ selectedAddress.state }}, {{ selectedAddress.country }},
                        {{ selectedAddress.pinCode }}
                    </span>
                </div>
                <!--Address Popup-->
                <ng-template #address let-modal>
                    <div class="modal-header my-add-design border-0">
                        <h5 class="modal-title text-white text-capitalize" id="modal-basic-title pt-2 mb-0">
                            My Address
                        </h5>
                        <button type="button" class="close text-white " aria-label="Close"
                            (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true" class="h2">&times;</span>
                        </button>
                    </div>
                    <div class="row modal-body m-0 pt-0 justify-content-end order-bg">
                        <ng-container *ngIf=" isSelectAddress">
                            <button type="button" (click)="showAddNewAddressForm()"
                                class="btn btn-primary col-md-3 mb-2 pt-1">
                                Add Address
                            </button>
                            <div class="card border-0 shadow col-12  mt-2 mb-3" *ngFor="let address of addressList">
                                <div class="card-body">
                                    <h5 class="card-title mb-1">{{ address.name }}</h5>
                                    <p class="card-text mb-1">
                                        <ng-container>Address. </ng-container>{{ address.flatNo }}, {{ address.locality
                                        }},
                                        {{ address.city }}, {{ address.state }},
                                        {{ address.country }}, {{ address.pinCode }}
                                    </p>
                                    <h6 class="card-subtitle mb-2 text-muted">
                                        <ng-container>Contact No. </ng-container>
                                        +{{ address.countryCodePhoneNum }}-{{
                                        address.phoneNum
                                        }}
                                    </h6>
                                    <a href="javascript:void(0)" class="card-link btn btn-outline-success py-1"
                                        (click)="selectAddress(address)">Select</a>
                                    <a href="javascript:void(0)" class="card-link btn btn-outline-danger py-1"
                                        (click)="deleteAddress(address._id)">Delete</a>
                                    <a href="javascript:void(0)" class="card-link btn btn-outline-info py-1"
                                        (click)="showUpdateAddressForm(address)">Update</a>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isAddNewAddress">
                            <form class="col-md-12 mt-4" [formGroup]="addAddressForm" (submit)="saveAddress()">
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label for="name" class="m-lable-size">Name*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Example: Home, Office, Work etc." formControlName="name" />
                                        <div *ngIf="
                                  a.name.invalid && (a.name.touched || a.name.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Name is required. Please Enter
                                                a Valid
                                                Name</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="flatNo" class="m-lable-size">Flat/House Number*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Flat or House Number" formControlName="flatNo" />
                                        <div *ngIf="
                                  a.flatNo.invalid &&
                                  (a.flatNo.touched || a.flatNo.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Flat No is Required</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="locality" class="m-lable-size">Locality*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Locality" formControlName="locality" />
                                        <div *ngIf="
                                  a.locality.invalid &&
                                  (a.locality.touched || a.locality.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Locality is Required</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="landmark" class="m-lable-size">LandMark</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your landmark (Optional)" formControlName="landmark" />
                                        <div *ngIf="
                                  a.landmark.invalid &&
                                  (a.landmark.touched || a.landmark.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">LandMark is Required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-12 form-group">
                                        <label for="name" class="m-lable-size">Contact Number*</label>
                                        <div class="d-flex align-items-baseline">
                                            <!-- <select name="countryCode" class="col-md-3 form-control m-lable-size"
                                                formControlName="countryCodePhoneNum">
                                                <option *ngFor="let country of countryList"
                                                    value="{{ country.phone_code }}">
                                                    {{ country.emoji }}&nbsp;&nbsp;{{
                                                    country.name
                                                    }}&nbsp;&nbsp;(+{{ country.phone_code }})
                                                </option>
                                            </select> -->
                                            <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
                                                class="c-size p-0 m-0 border-0 mob-f-bor form-control"
                                                [items]="countryList" bindLabel="phone_code" bindValue="phone_code">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                                    item.name
                                                    }}&nbsp;&nbsp;(+{{ item.phone_code }})
                                                </ng-template>
                                            </ng-select>
                                            <input type="text" class="col-md-9 mt-0 ml-1 form-control m-lable-size"
                                                placeholder="Enter Contact Number" formControlName="phoneNum" />
                                        </div>
                                        <div *ngIf="
                                  (a.countryCodePhoneNum.invalid &&
                                    (a.countryCodePhoneNum.touched ||
                                      a.countryCodePhoneNum.dirty)) ||
                                  (a.phoneNum.invalid &&
                                    (a.phoneNum.touched || a.phoneNum.dirty))
                                ">
                                            <small class="error-msg text-danger font-10">Contact Number is required.
                                                Please Enter a
                                                Valid
                                                Contact Number.</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12 form-group ">
                                        <label for="name" class="m-lable-size">Alternate Number(Optional)</label>
                                        <div class="d-flex align-items-basline">
                                            <!-- <select class="form-control col-md-3 m-lable-size"
                                                formControlName="countryCodeAltPhoneNum">
                                                <option *ngFor="let country of countryList"
                                                    value="{{ country.phone_code }}">
                                                    {{ country.emoji }}&nbsp;&nbsp;{{
                                                    country.name
                                                    }}&nbsp;&nbsp;(+{{ country.phone_code }})
                                                </option>
                                            </select> -->
                                            <ng-select [searchFn]="customSearchFn"
                                                formControlName="countryCodeAltPhoneNum"
                                                class="c-size p-0 m-0 border-0 mob-f-bor form-control"
                                                [items]="countryList" bindLabel="phone_code" bindValue="phone_code">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                                    item.name
                                                    }}&nbsp;&nbsp;(+{{ item.phone_code }})
                                                </ng-template>
                                            </ng-select>
                                            <input type="text" class="col-md-9 mt-0 ml-1 form-control m-lable-size "
                                                placeholder="Enter Alternate Number"
                                                formControlName="alternatePhoneNum" />
                                        </div>
                                        <div *ngIf="
                                  (a.countryCodeAltPhoneNum.invalid &&
                                    (a.countryCodeAltPhoneNum.touched ||
                                      a.countryCodeAltPhoneNum.dirty)) ||
                                  (a.alternatePhoneNum.invalid &&
                                    (a.alternatePhoneNum.touched ||
                                      a.alternatePhoneNum.dirty))
                                ">
                                            <small class="error-msg text-danger font-10">Please Enter a Valid Alternate
                                                Contact
                                                Number.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label for="city" class="m-lable-size">City*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your City" formControlName="city" />
                                        <div *ngIf="
                                  a.city.invalid && (a.city.touched || a.city.dirty)
                                ">
                                            <small class="error-msg text-danger font-10 ">City is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="state" class="m-lable-size">State*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your State" formControlName="state" />
                                        <div *ngIf="
                                  a.state.invalid &&
                                  (a.state.touched || a.state.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">State is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="country" class="m-lable-size">Country*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Country" formControlName="country" />
                                        <div *ngIf="
                                  a.country.invalid &&
                                  (a.country.touched || a.country.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Country is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label for="pinCode" class="m-lable-size">Pincode*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Pincode" formControlName="pinCode" />
                                        <div *ngIf="
                                  a.pinCode.invalid &&
                                  (a.pinCode.touched || a.pinCode.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Pincode is required.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <button type="button" class="btn btn-outline-info mr-4" (click)="addressBackPage()">
                                        Back
                                    </button>
                                    <div class=" form-group">
                                        <button type="submit" class="p-0 btn btn-success bg-success btn-sm border-0"
                                            [disabled]="addAddressForm.invalid">
                                            Submit
                                        </button>
                                    </div>
                                    <div class="col-md-12 text-center form-group text-danger"
                                        *ngIf="addAddressForm.invalid">
                                        Fields with * are required.
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                        <ng-container *ngIf="isUpdateAddress">
                            <form class="col-md-12 mt-4" [formGroup]="updateAddressForm" (submit)="updateAddress()">
                                <div class="row">
                                    <div class="col-3 form-group">
                                        <label for="name" class="m-lable-size">Name*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Example: Home, Office, Work etc." formControlName="name" />
                                        <div *ngIf="
                                  u.name.invalid && (u.name.touched || u.name.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Name is required. Please Enter
                                                a Valid
                                                Name</small>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <label for="flatNo" class="m-lable-size">Flat/House Number*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Flat or House Number" formControlName="flatNo" />
                                        <div *ngIf="
                                  u.flatNo.invalid &&
                                  (u.flatNo.touched || u.flatNo.dirty)
                                ">
                                            <small class="error-msg text-danger font-10 m-lable-size">Flat No is
                                                Required</small>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <label for="locality" class="m-lable-size">Locality*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Locality" formControlName="locality" />
                                        <div *ngIf="
                                  u.locality.invalid &&
                                  (u.locality.touched || u.locality.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Locality is Required</small>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <label for="landmark" class="m-lable-size">LandMark(Optional)</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your landmark (Optional)" formControlName="landmark" />
                                        <div *ngIf="
                                  u.landmark.invalid &&
                                  (u.landmark.touched || u.landmark.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">LandMark is Required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 form-group">
                                        <label for="name" class="m-lable-size">Contact Number*</label>
                                        <div class="d-flex">
                                            <!-- <select class="form-control w-40" formControlName="countryCodePhoneNum">
                                                <option *ngFor="let country of countryList"
                                                    value="{{ country.phone_code }}">
                                                    {{ country.emoji }}&nbsp;&nbsp;{{
                                                    country.name
                                                    }}&nbsp;&nbsp;(+{{ country.phone_code }})
                                                </option>
                                            </select> -->
                                            <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
                                                class="c-size p-0 m-0 border-0 mob-f-bor form-control"
                                                [items]="countryList" bindLabel="phone_code" bindValue="phone_code">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                                    item.name
                                                    }}&nbsp;&nbsp;(+{{ item.phone_code }})
                                                </ng-template>
                                            </ng-select>
                                            <input type="text" class="form-control m-lable-size "
                                                placeholder="Enter Contact Number" formControlName="phoneNum" />
                                        </div>
                                        <div *ngIf="
                                  (u.countryCodePhoneNum.invalid &&
                                    (u.countryCodePhoneNum.touched ||
                                      u.countryCodePhoneNum.dirty)) ||
                                  (u.phoneNum.invalid &&
                                    (u.phoneNum.touched || u.phoneNum.dirty))
                                ">
                                            <small class="error-msg text-danger font-10">Contact Number is required.
                                                Please Enter a
                                                Valid
                                                Contact Number.</small>
                                        </div>
                                    </div>
                                    <div class="col-6 form-group">
                                        <label for="name" class="m-lable-size">Alternate Number(Optional)</label>
                                        <div class="d-flex">
                                            <!-- <select class="form-control m-lable-size w-40"
                                                formControlName="countryCodeAltPhoneNum">
                                                <option *ngFor="let country of countryList"
                                                    value="{{ country.phone_code }}">
                                                    {{ country.emoji }}&nbsp;&nbsp;{{
                                                    country.name
                                                    }}&nbsp;&nbsp;(+{{ country.phone_code }})
                                                </option>
                                            </select> -->
                                            <ng-select [searchFn]="customSearchFn"
                                                formControlName="countryCodeAltPhoneNum"
                                                class="c-size p-0 m-0 border-0 mob-f-bor form-control"
                                                [items]="countryList" bindLabel="phone_code" bindValue="phone_code">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                                                    item.name
                                                    }}&nbsp;&nbsp;(+{{ item.phone_code }})
                                                </ng-template>
                                            </ng-select>
                                            <input type="text" class="form-control m-lable-size w-60"
                                                placeholder="Enter Alternate Number"
                                                formControlName="alternatePhoneNum" />
                                        </div>
                                        <div *ngIf="
                                  (u.countryCodeAltPhoneNum.invalid &&
                                    (u.countryCodeAltPhoneNum.touched ||
                                      u.countryCodeAltPhoneNum.dirty)) ||
                                  (u.alternatePhoneNum.invalid &&
                                    (u.alternatePhoneNum.touched ||
                                      u.alternatePhoneNum.dirty))
                                ">
                                            <small class="error-msg text-danger font-10">Please Enter a Valid Alternate
                                                Contact
                                                Number.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 form-group">
                                        <label for="city" class="m-lable-size">City*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your City" formControlName="city" />
                                        <div *ngIf="
                                  u.city.invalid && (u.city.touched || u.city.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">City is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <label for="state" class="m-lable-size">State*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your State" formControlName="state" />
                                        <div *ngIf="
                                  u.state.invalid &&
                                  (u.state.touched || u.state.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">State is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <label for="country" class="m-lable-size">Country*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Country" formControlName="country" />
                                        <div *ngIf="
                                  u.country.invalid &&
                                  (u.country.touched || u.country.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Country is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group">
                                        <label for="pinCode" class="m-lable-size">Pincode*</label>
                                        <input type="text" class="form-control m-lable-size"
                                            placeholder="Enter Your Pincode" formControlName="pinCode" />
                                        <div *ngIf="
                                  u.pinCode.invalid &&
                                  (u.pinCode.touched || u.pinCode.dirty)
                                ">
                                            <small class="error-msg text-danger font-10">Pincode is required.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="
                                col-md-12
                                d-flex
                                justify-content-center
                                form-group
                              ">
                                        <button type="button" class="btn btn-outline-info mr-4"
                                            (click)="addressBackPage()">
                                            Back
                                        </button>
                                        <button type="submit" class="btn btn-success btn-sm bg-success border-0"
                                            [disabled]="
                                updateAddressForm.invalid">
                                            Submit
                                        </button>
                                    </div>
                                    <div class="col-md-12 text-center form-group text-danger"
                                        *ngIf="updateAddressForm.invalid">
                                        Fields with * are required.
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                    </div>
                    <div class="modal-footer text-white p-0">
                        <button type="button" class="btn btn-danger bg-danger text-white btn-sm px-3 mb-2 mt-2 mr-3"
                            (click)="modal.close()">
                            Close
                        </button>
                    </div>
                </ng-template>
            </div>


        </div>
    </div>

    <div class="table-responsive  table-striped mt-3">
        <h5 class="mb-2"><b>Order Summary</b></h5>
        <table class=" table cart-th1 table-bordered">
            <!-- table heading Start here -->
            <tr class="order-summary">
                <th>Title</th>
                <th>Amount.</th>
            </tr>
            <!-- table heading End here -->

            <tr>
                <td>
                    <h6 class=" mb-0 text-truncate">Total Items</h6>
                </td>
                <td>{{dataList?.totalItems}}</td>

            </tr>
            <tr>
                <td>
                    <h6 class=" mb-0 text-truncate">Add-on price</h6>
                </td>
                <td>{{dataList?.addOnsTotal | currency: (code ? "INR" : "USD"):"symbol-narrow"}}</td>

            </tr>
            <tr>
                <td>
                    <h6 class=" mb-0 text-truncate">Subtotal</h6>
                </td>
                <td>{{dataList?.subTotal | currency: (code ? "INR" : "USD"):"symbol-narrow"}}</td>

            </tr>
            <tr class="alert-danger" *ngIf="dataList?.isCouponApplied">
                <td>
                    Coupon Discount
                </td>
                <td>-{{dataList?.couponDiscount | currency: (code ? "INR" : "USD"):"symbol-narrow"}}</td>

            </tr>
            <tr>
                <td>
                    <h6 class=" mb-0 text-truncate">GST</h6>
                </td>
                <td>{{dataList?.totalGst | currency: (code ? "INR" : "USD"):"symbol-narrow"}}</td>

            </tr>
            <tr class="order-bg">
                <td>
                    <h5 class=" mb-0 text-truncate">Grand Total</h5>
                </td>
                <td>
                    <h5 class=" mb-0 text-truncate">{{dataList?.grandTotal | currency: (code ? "INR" :
                        "USD"):"symbol-narrow"}}</h5>
                </td>

            </tr>
            <tr>
                <td colspan="2" class="text-center"><button (click)="placeOrder()" class="btn btn-success">Place
                        Order</button></td>
            </tr>
        </table>
    </div>
    <!--Addon PopUp-->
    <ng-template #addOnPopUp let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title mt-2" id="modal-basic-title">Add-On Details</h4>
            <button type="button" class="btn btn-close c-btn" aria-label="Close" (click)="d('Cross click')"><span
                    class="material-icons text-white">close</span></button>
        </div>
        <div class=" modal-body">
            <p>Items ({{addOnData.length}})</p>

            <div class="row px-2 mt-2">
                <div class="col-12 mb-2 p-2 rounded bg-yellow-light" *ngFor="let addOn of addOnData">
                    <div class="d-flex">
                        <div class="col-md-2 p-2">
                            <img class="img img-thumbnail ps-0" [src]="getSanitizeUrl(addOn.addOnImage)">
                        </div>
                        <div class="align-middle">
                            <div class="col p-2">
                                <h6 class="mb-0 text-truncate">{{addOn.addOnName}}</h6>
                                <p>{{addOn?.addOnPrice | currency: (code ? "INR" :
                                    "USD"):"symbol-narrow"}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-template>
</section>
<section class="container  order-bg1 mt-5 mb-5 py-3" *ngIf="dataList?.products?.length == 0">
    <div class=" row n-data-img justify-content-center">
        <img src="../../../../assets/images/empty-cart.svg" />
    </div>
    <div>
        <p class="h5 text-center mt-2 ">Your Cart is Empty</p>
        <p class="h6 text-center mt-2 text-muted">Add something to make me happy!</p>

    </div>
</section>
<!-- container end -->
<app-footer></app-footer>