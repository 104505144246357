<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Customer Support
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize text-white">Customer Support</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-3 mt-lg-5">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between align-items-center">
          <table class="table-sm table-bordered mr-1">
            <tr>
              <th>Email:</th>
              <td>support@astroriver.com</td>
            </tr>
            <tr>
              <th>Whatsapp Number:</th>
              <td>+91-7009127641</td>
            </tr>
            <tr>
              <th>Support Phone Number:</th>
              <td>+91-7009127641</td>
            </tr>
          </table>
          <!-- <h4 class="fw-500">Customer Support</h4> -->
          <div>
            <button type="button" class="btn py-2 px-3 text-white bg-secondary shadow-0" (click)="open(support)">
              New Support
            </button>
          </div>
          <!-- Add Aupport Section-->
          <ng-template #support let-c="close" let-d="dismiss">
            <div class="modal-header pb-0">
              <h4 class="modal-title" id="modal-basic-title">Issue</h4>
              <button type="button" class="btn btn-close c-btn" aria-label="Close"
                (click)="d('Cross click')"><span class="material-icons text-white">close</span></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="supportForm" (submit)="addSupport()">
                <div class="form-group">
                  <label for="support_title">Issue Title</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="support_title" />
                  </div>
                  <div *ngIf="
                    i.support_title.invalid &&
                    (i.support_title.dirty || i.support_title.touched)
                  " class="alert alert-danger mt-1">
                    Required.
                  </div>
                </div>
                <div class="form-group">
                  <label for="support_description">Description</label>
                  <div class="input-group">
                    <textarea class="form-control" formControlName="support_description"></textarea>
                  </div>
                  <div *ngIf="
                    i.support_description.invalid &&
                    (i.support_description.dirty ||
                      i.support_description.touched)
                  " class="alert alert-danger mt-1">
                    Required.
                  </div>
                </div>
                <button type="submit" class="
                  btn
                  bg-secondary
                  text-white
                  fw-500
                  shadow-0 p-0
                " [disabled]="!supportForm.valid">
                  Save
                </button>
              </form>
            </div>
          </ng-template>
        </div>
        <div class="row mt-3">
          <ngx-datatable class="material cust-table" [rows]="supportList" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{ dir: 'desc' }]">
            <ngx-datatable-column name="Support ID" prop="supportId" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a [routerLink]="['/issue/', row._id]" title="View Details">{{
                  row.supportId
                  }}</a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Issue" prop="support_title" [flexGrow]="3">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.support_title }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" [flexGrow]="1">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="badge bdg-txt-fw bg-danger p-2" *ngIf="row.is_issue_solved; else pend">CLOSED</span>
                <ng-template #pend><span class="badge bdg-txt-fw bg-success p-2">OPEN</span></ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date" [flexGrow]="2" prop="created_at">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.created_at | date: "medium" }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="action" [flexGrow]="2" prop="Action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a class="btn py-1 px-3 bg-secondary rounded py-1 text-white" (click)="showChat(row._id)">
                  Show
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- page end-->
<app-footer></app-footer>