import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  dataList = new Array<any>();
  page=1
  pageSize=10
  constructor(
    private loginService: LoginService,
    private userdata: UserdataService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getNotification()
  }

  getNotification(){
    this.loginService.notification({customerId:this.userdata.getCustomerId()}).subscribe(
      (res:any)=>{
        this.dataList = res.data
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
