import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserdataService } from '../service/userdata/userdata.service';
import { OpenApiService } from '../service/open-api/open-api.service';
import { ToastrService } from 'ngx-toastr';
import { UserdetailsService } from '../service/userdetails/userdetails.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userDataService: UserdataService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var headers: any;
    var obj:any = {}
    var token = this.userDataService.getToken();
    var countryCode = this.userDataService.getCountryCode();
    if (token != null && token.length > 0) {
      obj.Authorization = token
      headers = req.headers.set('Authorization', token);
    }
    if (countryCode != (null && '' && undefined)) {
      obj.country_code = countryCode
      headers = req.headers.set('country_code', countryCode);
    }
    obj.device = 'web'
    headers = req.headers.set('device', 'web');
    //console.log('headers',headers)
    //const clonedReq = req.clone({ headers: headers });
    const clonedReq = req.clone({
      setHeaders: obj
    });
    return next.handle(clonedReq).pipe(
      tap(
        (succ) => { },
        (err: any) => {
          if (err.status == 401) {
            this.userDataService.removeData();
            this.router.navigateByUrl('/login');
          }
        }
      )
    );
  }
}
