<div class="card card-bordered" style="height: 94%">
  <!-- <div class="card-header d-flex flex-column align-items-start">
   
  </div> -->
  <div
    class="ps-container h-100 overflow-auto"
    id="chat-content"
    #scrollMe
    [scrollTop]="scrollMe.scrollHeight"
    *ngIf="chatList"
  >
    <ng-container *ngFor="let chat of chatList">
      <!-- <div class="media media-chat">
        <div class="astro-chat media-body">
          <p class="inline-block">{{ chat.message }}</p>
          <p class="meta">
            <time datetime="2018">{{ chat.date | date: "medium" }}</time>
          </p>
        </div>
      </div> -->
      <ng-container *ngIf="chat.isCustomerReply">
        <div class="media media-chat media-chat-reverse">
          <div
            class="media-body px-1"
            [ngClass]="chat.isCustomerReply ? 'cus-chat' : 'astro-chat'"
          >
            <p>{{ chat.new_reply }}</p>
  
            <p class="meta fn-0">
              {{ chat.replyDate | date: "medium" }}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!chat.isCustomerReply">
        <div class="media media-chat media-chat">
          <div
            class="media-body px-1"
            [ngClass]="chat.isCustomerReply ? 'cus-chat' : 'astro-chat'"
          >
            <p>{{ chat.new_reply }}</p>
  
            <p class="meta fn-0">
              {{ chat.replyDate | date: "medium" }}
            </p>
          </div>
        </div>
      </ng-container>
      
    </ng-container>
  </div>
  <div class="bg-inactive border p-2" *ngIf="!isIssueSolved">
    <form
      [formGroup]="chatForm"
      class="d-flex flex-column"
      (submit)="sendMsg()"
    >
      <input
        *ngIf="!isTitle"
        class="form-control"
        type="text"
        placeholder="Title"
        formControlName="support_title"
      />
      <div class="d-flex">
        <textarea
          (keyup)="onTypeChange($event)"
          class="form-control"
          placeholder="Write something"
          formControlName="support_description"
          rows="1"
        ></textarea>
        <button
          [disabled]="!chatForm.valid"
          class="
            btn
            size-40
            rounded-circle
            bg-secondary
            text-white
            m-0
            shadow-0
          "
          type="submit"
          title="Send Message"
        >
          <i class="fa fa-paper-plane"></i>
        </button>
      </div>
    </form>
  </div>
</div>
<!-- </div>
    </div>
  </div>
</section> -->
<!-- <app-footer></app-footer> -->
