import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { IssueService } from 'src/app/service/issue/issue.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.css'],
})
export class IssueListComponent implements OnInit {
  dataList = new Array();
  currentWallet = '';
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  constructor(
    private userdata: UserdataService,
    private issueService: IssueService
  ) {}

  tab:string = 'all'
  ngOnInit(): void {
    this.getMyWallet();
  }

  getMyWallet() {
    this.tab = 'all'
    this.issueService
      .issueList({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.currentWallet = res.customerwallet;
        },
        (err: any) => {}
      );
  }

  /**
   * Wallet Issues
   */
  walletIssue() {
    this.tab = 'wallet'
    this.issueService
      .issueList({ customerId: this.userdata.getId(), is_wallet_issue: true })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          //console.log(this.dataList)
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }

  /**
   * Service Issue
   */
  serviceIssue() {
    this.tab = 'service'
    this.issueService
      .issueList({ customerId: this.userdata.getId(), is_service_issue: true })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          //console.log(this.dataList)
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }

  /**
   * Pending(Not replied) Issue
   */
  pendingIssue() {
    this.tab = 'pending'
    this.issueService
      .issueList({
        customerId: this.userdata.getId(),
        is_reply_by_system: false,
      })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          //console.log(this.dataList)
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }

  /**
   * Solved Issue
   */
  solvedIssue() {
    this.tab = 'solved'
    this.issueService
      .issueList({
        customerId: this.userdata.getId(),
        is_reply_by_system: true,
        is_issue_solved: true,
      })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          //console.log(this.dataList)
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }
}
