<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Recharge Wallet
      </h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Recharge Wallet</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<ngx-spinner></ngx-spinner>
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="col-lg-12 col-md-12 col-12 p-0">
      <div class="bg-light p-3 d-flex flex-row search align-items-end justify-content-between">
        <div class="bg-green rounded py-2 px-4 bb-balance">
          <h6 class="text-center text-white fn-0">Your Balance</h6>
          <h4 class="fn-2 fw-500 text-white">
            {{ wallet | currency: myProfile?.currencyCode }}
          </h4>
        </div>
        <div>
          <a [routerLink]="['/my-profile']" class="shadow-0 btn cus-border-green p-0 py-2 m-0 px-3 mx-2 text-body">My
            Profile</a>
        </div>
      </div>
    </div>

    <!-- <div class="row my-3">
      <div class="col-md-6 col-lg-6 col-6">
        <div class="d-flex mb-3">
          <div class="col-md-9 pl-0">
            <input type="amount" class="form-control" placeholder="Enter Custom Amount" id="custom_amount"
              aria-describedby="emailHelp">
          </div>
          <div class="col-md-3 align-middle p-0">
            <a href="#" class="btn-sm btn-danger btn-block p-2 text-center"> Recharge</a>
          </div>

        </div>
        <div class=" row my-3">
          <div class="col-md-4 my-2">
            <div class="card shadow rech-card border-0 p-1">
              <div class="px-3 py-3  rech-card-border text-center">
                <h3 class="fw-500">₹250</h3>
                <div class="bg-white px-3 py-1 rounded mt-2">
                  <h6 class="my-1 fw-400">Exter<span> 10% </span></h6>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-4 my-2">
            <div class="card shadow rech-card border-0 p-1">
              <div class="px-3 py-3  rech-card-border text-center">
                <h3 class="fw-500">₹250</h3>
                <div class="bg-white px-3 py-1 rounded mt-2">
                  <h6 class="my-1 fw-400">Exter<span> 10% </span></h6>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-4 my-2">
            <div class="card shadow rech-card border-0 p-1">
              <div class="px-3 py-3  rech-card-border text-center">
                <h3 class="fw-500">₹250</h3>
                <div class="bg-white px-3 py-1 rounded mt-2">
                  <h6 class="my-1 fw-400">Exter<span> 10% </span></h6>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
      <div class="col my-2">

      </div>
    </div> -->
    <div class="row my-3">
      <div
        [ngClass]="{'col-md-6 col-lg-6 col-12 ':isAmountSelected || isCustomAmount,'col-md-12 col-lg-12 col-12':!isAmountSelected && !isCustomAmount}">
        <div class="d-flex mb-3">
          <div class="col-md-9 p-0">
            <input type="amount" class="form-control" placeholder="Enter Custom Amount" #custom_amount
              (keyup)="changeCustomAmount(custom_amount.value)" aria-describedby="emailHelp">
            <small class="text-danger" *ngIf="isError">Please enter valid amount. Eg: 100, 150, 200 ..</small>
          </div>
          <div class="col-md-3 align-middle">
            <a (click)="checkCustomAmount(custom_amount.value)"
              class="btn-sm btn-danger btn-block p-2 text-center text-white"> Submit</a>
          </div>
          <!-- <div class="col-md-3 align-middle p-0">
            <a href="#" class="btn-sm btn-primary btn-block p-2 text-center"> Apply coupon</a>
          </div> -->

        </div>
        <div class="row my-3">
          <div class="col-md-4 my-3" *ngFor="let data of dataList"
            (click)="selectAmount(data._id,data.amount, data.percentge)">
            <div class="card shadow rech-card p-1"
              [ngClass]="{'border border-danger':selectedId == data._id, 'border-0':selectedId != data._id }">
              <div class="px-3 py-3  rech-card-border text-center">
                <h3 class="fw-500">{{ currencySymbol }}{{ data.amount }}</h3>
                <div class="bg-white px-3 py-1 rounded mt-2">
                  <h6 class="my-1 fw-400">Extra<span> {{ data.percentge }}% </span></h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- form section -->
      <div class="col" *ngIf="isAmountSelected || isCustomAmount">
        <div class="card p-4 order-bg">
          <div class="row">
            <div class="col-md-8 text-dark fw-500">
              Total Rechargeable Amount
            </div>
            <div class="col-md-4">{{ currencySymbol }}{{ this.amount }}</div>
          </div>
          <ng-container *ngIf="this.extra > 0">
            <hr />
            <div class="row">
              <div class="col-8 text-dark fw-500">Cashback</div>
              <div class="col-4">{{ currencySymbol }}{{ this.extra }}</div>
            </div>
          </ng-container>
          <hr />
          <div class="row">
            <div class="col-8 text-dark fw-500">GST (18%)</div>
            <div class="col-4">{{ currencySymbol }}{{ this.tax }}</div>
          </div>
          <hr />
          <div class="row">
            <div class="col-8 text-dark font-weight-bold">
              Total Payable Amount
            </div>
            <div class="col-4">{{ currencySymbol }}{{ this.total }}</div>
          </div>
          <ng-container *ngIf="isCustomAmount">
            <hr />
            <div class="row px-2" *ngIf="!isCouponApplied">

              <div class=" col-12 alert alert-successfont-weight-bold shadow rounded coupon-style"
                (click)="open(content)"> <i class="fas fa-tags mx-1"></i>Apply
                Coupon<i class="fa-solid fa-arrow-right float-right align-middle p-0 m-0"></i>
              </div>
            </div>
            <div class=" row px-2 " *ngIf="isCouponApplied">
              <div class="col-12 p-0 coupon-bg shadow rounded px-2 py-2 d-flex justify-content-between">
                <div
                  class="p-2 col-12  coupon-style1 rounded align-items-center font-weight-semibold d-flex justify-content-between">
                  <i class="fa-solid fa-check-double fa-xl mx-2"></i>
                  Cashback Amount Earned: {{ currencySymbol }}{{couponCashBack}} (Code: {{couponCode}})
                  <button type="button" class="close text-success " aria-label="Close" (click)="removeCoupon()"><span
                      aria-hidden="true" class="h2 text-dark">&times;</span></button>

                </div>

              </div>
            </div>
            <!--Apply Coupon-->
            <ng-template #content let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Apply Coupon</h4>
                <button type="button" class="close text-white " aria-label="Close"
                  (click)="modal.dismiss('Cross click')"><span aria-hidden="true" class="h2">&times;</span></button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="mb-3">
                    <!-- <label for="dateOfBirth">Date of birth</label> -->
                    <div class="input-group">
                      <input #couponCode class="form-control" placeholder="Enter Coupon Code">
                      <button class="btn btn-outline-danger mt-0" type="button"
                        (click)="applyCoupon(couponCode.value)">Apply</button>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
          </ng-container>
          <div class="d-flex mt-3">
            <button class="btn bg-secondary rounded text-white fw-500 py-2" (click)="rechargeAmount()">
              Recharge
            </button>
          </div>
        </div>
      </div>
      <!-- form section end -->
    </div>
  </div>


</section>
<app-footer></app-footer>