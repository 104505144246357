<app-header #headerOne></app-header>
<ngx-spinner></ngx-spinner>

<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        {{ breadcrumb }}
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">
          {{ breadcrumb }}
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!-- Search and Filter Start -->
<section class="my-3">
  <div class="container">
    <div class="d-flex search align-items-end mob-res-order-search">
      <div *ngIf="isLogin" class="bg-green rounded px-3 py-2 bb-balance col-md-2 col-12 mob-res-order-search ">
        <h6 class="text-center text-white fn-0">Your Balance</h6>
        <h4 class="fn-2 fw-500 text-center text-white mt-1">
          {{ walletBalance | currency: myProfile?.currencyCode }}
        </h4>
      </div>
      <a *ngIf="isLogin" (click)="goToRecharge()"
        class="shadow-0 btn cus-border-green  py-2  mx-3 px-2 mb-0 text-body col-md-2 col-12 mob-res-order-search">
        Recharge
      </a>
      <div class="d-lg-none"></div>
      <div class="input-group d-none d-flex col-md-7 col-12 pl-0 pr-0 mob-res-order-search">
        <input type="text" class="form-control img-rounded-tbr1" placeholder="Search" aria-label="Recipient's username"
          aria-describedby="button-addon2" (keyup)="astroName($event)" />
        <button
          class="mob-s-btn shadow-0 btn btn-danger img-rounded-tbr col-md-2 p-0 m-0  mob-res-order-search col-12 text-white "
          type="button" id="button-addon2" mdbRipple rippleColor="danger" (click)="searchAstroClick()">
          Search
        </button>
      </div>
      <div ngbDropdown class="col-md-1 col-12 mob-res-order-search
      ">
        <button class="shadow-0 rounded fltr-style border-danger bg-white" id="dropdownBasic1" ngbDropdownToggle><i
            class="fa-solid fa-arrow-down-wide-short px-1 icn-rev"></i>
          <!-- <i class="fa-solid fa-filter  "></i> -->
        </button>
        <div class="filter p-0" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <ul class="list-group">
            <li ngbDropdownItem class="list-group-item" [ngClass]="{ 'filter-active': filterType == 1 }">
              <a class="" (click)="applyFilter('call_charges', '-1', 1)">Price: High to Low</a>
            </li>
            <li ngbDropdownItem class="list-group-item" [ngClass]="{ 'filter-active': filterType == 2 }">
              <a class="" (click)="applyFilter('call_charges', '1', 2)">
                Price: Low to High</a>
            </li>
            <li ngbDropdownItem class="list-group-item" [ngClass]="{ 'filter-active': filterType == 3 }">
              <a class="" (click)="applyFilter('experience', '-1', 3)">Experience: High to Low</a>
            </li>
            <li ngbDropdownItem class="list-group-item" [ngClass]="{ 'filter-active': filterType == 4 }">
              <a class="" (click)="applyFilter('experience', '1', 4)">Experience: Low to High</a>
            </li>
            <li ngbDropdownItem class="list-group-item" [ngClass]="{ 'filter-active': filterType == 5 }">
              <a class="" (click)="applyFilter('total_call_taken', '-1', 5)">Orders: High to Low</a>
            </li>
            <li ngbDropdownItem class="list-group-item" [ngClass]="{ 'filter-active': filterType == 6 }">
              <a class="" (click)="applyFilter('total_call_taken', '1', 6)">Orders: Low to High</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-none input-group d-lg-none mt-3">
      <input type="text" class="form-control" placeholder="Search" aria-label="Recipient's username"
        aria-describedby="button-addon2" (keyup)="astroName($event)" />
      <button class="shadow-0 btn p-0 bg-secondary m-0 text-white px-2" type="button" id="button-addon2" mdbRipple
        rippleColor="danger" (click)="searchAstroClick()">
        Search
      </button>
    </div>
  </div>
</section>
<!-- Search and Filter End -->
<section>
  <div class="container">
    <div class="col-12 p-0">
      <div class="alert alert-danger text-center" role="alert" *ngIf="endpoint.includes('promo')">
        In this promo offer you can avail free
        {{ endpoint.includes("chat") ? "chat" : "call" }} for 5 minutes
      </div>
    </div>
  </div>
</section>
<!-- Atro List Start -->
<section>
  <div class="container">
    <div class="row ctm-box">
      <div class=" w-size my-3  col-md-4 col-12" *ngFor="let key of dataList">
        <div class="h-100 w-100 card trans astro-box p-2 c-pointer-common">
          <div class="h-100 w-100 card-body bg-astro py-2">
            <div class="h-100 row p-1 position-relative">
              <div class="col-3 p-0 pr-1">
                <div class="d-flex flex-column align-items-center">
                  <a routerLink="../astrologer/{{ key._id }}"><img class="shadow size-60 border-white rounded-circle"
                      [src]="getSanitizeUrl(key.profile_image)" /></a>
                      <!-- <a routerLink="../astrologer/{{ key.displayname }}"><img class="shadow size-60 border-white rounded-circle"
                        [src]="getSanitizeUrl(key.profile_image)" /></a> -->
                  <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astrologer_rating" [readonly]="true"></ngb-rating>
                  <span class="txt-size-list inactive-text fw-500">({{ key.astrologer_rating }})</span>
                </div>
              </div>
              <span class="material-icons position-absolute" [ngClass]="checkOnlineOffLine(key) ? 'online' : 'offline'">
                fiber_manual_record
              </span>
              <a (click)="notifyAstrologer(key._id)" class="position-absolute noti pointer text-muted"><i
                  class="material-icons-outlined b-icon">
                  notifications_active
                </i></a>
              <!-- <div class=" my-1">
                <button [disabled]="price(key) < 1" style="z-index: 100" (click)="buttonAction(key)" class="
                                                    float-right
                                                    shadow-0
                                                    btn
                                                    btn-sm
                                                    px-2 
                                                    pb-1 
                                                    pt-1
                                                    pr-3 
                                                    mr-1
                                                    rounded
                                                    bg-secondary
                                                    fw-500
                                                    text-white
                                                    position-absolute
                                                    bottom-0
                                                    end-0
                                                    d-flex
                                                    align-items-center
                                                  ">
                  <i class="material-icons fn-2 lh-24 py-0 mx-1">
                    {{ button_icon }}
                  </i>
                  {{ button_title }}
                </button>
              </div> -->


              <div class="col-md-9 col-9 p-0 pl-1">
                <div routerLink="../astrologer/{{ key._id }}" class="">
                  <!-- <div routerLink="../astrologer/{{ key.displayname }}" class=""> -->
                  <span class="text-capitalize fw-600">{{
                    key.displayname ? key.displayname : key.name
                    }}</span>
                  <p class="txt-size-list inactive-text line-size-1">
                    <span class="text-capitalize" *ngFor="let skill of key.skill; let i = index">{{
                      skill.skillId?.skillname
                      }}{{
                      key.skill.length > 1 && i < key.skill.length - 1 ? ", " : "" }}</span>
                  </p>
                  <p class="txt-size-list inactive-text">
                    Exp: {{ key.experience }}
                    {{ key.experience > 1 ? "Years" : "Year" }}
                  </p>
                  <p class="txt-size-list inactive-text line-size-1">
                    <span class="text-capitalize" *ngFor="let lg of key.lang; let i = index">{{ lg.langId?.name
                      }}{{
                      key.lang.length > 1 && i < key.lang.length - 1 ? ", " : "" }}</span>
                  </p>
                  <ul class="price txt-size-list w-100 mb-4" *ngIf="endpoint.includes('promo')">
                    <li class="d-inline" *ngIf="isOffer(key); else price">
                      <s class="txt-red-wfw">
                        {{ key?.currencySymbol }}
                        {{ getOffer(key) }}</s>
                    </li>
                    <ng-template #price>
                      <li class="d-inline">
                        <s class="txt-red-wfw">
                          {{ key?.currencySymbol }}
                          {{ getPrice(key) }}
                        </s>
                      </li>
                    </ng-template>
                    <li class="d-inline">{{ key?.currencySymbol }}1/Min</li>
                  </ul>
                  <ul class="price txt-size-list w-100" *ngIf="!endpoint.includes('promo')">
                    <li class="d-inline" *ngIf="isOffer(key); else price">
                      <s class="txt-red-wfw">
                        {{ key?.currencySymbol }}
                        {{ getPrice(key) }}</s>&nbsp;
                      {{ key?.currencySymbol }}
                      {{ getOffer(key) }}
                    </li>
                    <ng-template #price>
                      <li class="d-inline">
                        {{ key?.currencySymbol }}
                        {{ getPrice(key) }}
                      </li>
                    </ng-template>
                  </ul>
                  <span class="fn-0 active-text" *ngIf="
                      endpoint != 'query-astro-list' &&
                      endpoint != 'report-astro-list'
                    ">{{ convertSecondstoTime(key) }}</span>
                </div>
                <div class="m-card-qr">
                  <button [disabled]="price(key) < 1 || checkDisablePossibility(key)" style="z-index: 100"
                    (click)="buttonAction(key)" class="
                                                                                  float-right
                                                                                  shadow-0
                                                                                  btn
                                                                                  btn-sm
                                                                                  px-2 
                                                                                  pt-1
                                                                                  pb-1
                                                                                  pr-3 
                                                                                  mr-1
                                                                                  rounded
                                                                                  bg-secondary
                                                                                  fw-500
                                                                                  text-white
                                                                                  position-absolute
                                                                                  bottom-0
                                                                                  end-0
                                                                                  d-flex
                                                                                  align-items-center
                                                                                ">
                    <i class="material-icons fn-2 lh-24 py-0 mx-1">
                      {{ button_icon }}
                    </i>
                    {{ button_title }}
                  </button>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>
      <div class="col-12" *ngIf="dataList?.length == 0">
        <section class="container  order-bg1 mt-2 mb-5 py-3">
          <div class=" row n-data-img justify-content-center">
            <img src="../../../../assets/images/no-data.svg" />
          </div>
          <div>
          </div>
        </section>
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="totalPages > 1">
      <button (click)="loadMore()" class="
          shadow-0
          btn
          rounded-pill
          px-4
          py-2
          text-capitalize
          bg-green
          text-white
        ">
        <i class="fi fi-spinner-refresh text-white"></i>&nbsp;&nbsp;Load More
      </button>
    </div>
  </div>
  <!-- <div class="my-5 text-center">
    <a href="#" class="fn-2 fw-500 btn text-white bg-green py-2 px-5 rounded">
      Load More
    </a>
  </div> -->
</section>
<!-- Atro List End -->

<!-- Info cards Start -->
<section class="mt-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-6">
        <div class="d-flex rounded py-2 px-3 cus-border-active1 ">
          <img class="rounded-circle size-60" src="../../../assets/images/product/astrologer.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            Verified Expert<br>
            <b class="mt-1">Astrologers</b>
          </h4>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="d-flex rounded py-2 px-3 cus-border-green">
          <img class="rounded-circle size-60" src="../../../assets/images/astro/rupee.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            Money Back<br>
            <b class="mt-1">Guarantee</b>
          </h4>
        </div>
      </div>
      <div class="col-lg-3 col-6 m-lg-0 mt-3">
        <div class="d-flex rounded py-2 px-3 cus-border-orange">
          <img class="rounded-circle size-60  p-1" src="../../../assets/images/product/money.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            100% <b>Safe</b><br>
            <b class="mt-1">& Secure</b>
          </h4>
        </div>
      </div>
      <div class="col-lg-3 col-6 m-lg-0 mt-3">
        <div class="d-flex rounded py-2 px-3 cus-border-secondary">
          <img class="rounded-circle size-60" src="../../../assets/images/product/24-by-7.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            Support <strong>24/7</strong>
          </h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Info cards Start -->

<!-- How it works Start -->
<section class="py-5 my-5 d-none d-lg-block bg-inactive">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-center text-capitalize">how astroriver works</h4>
      <h4><span></span></h4>
    </div>
    <div class="row pt-4">
      <div class="
          col-lg-4 col-md-4 col-sm-12 col-xs-12
          px-5
          mt-3
          text-center 
          steps
        ">
        <img class="hw-i-w" style="border-color: #2492a3" src="../../../assets/images/home/signin.jpg" />
        <h5 class="fw-500 text-capitalize mt-3 text-center">
          <span class="active-text">step 1: </span>register yourself
        </h5>
        <span></span>
      </div>
      <div class="
          col-lg-4 col-md-4 col-sm-12 col-xs-12
          px-5
          mt-3
          text-center
          steps
        ">
        <img class="hw-i-w" style="border-color: #609d25" src="../../../assets/images/home/wallet.jpg" />
        <h5 class="fw-500 text-capitalize mt-3 text-center mx-n3">
          <span class="active-text">step 2: </span>recharge your wallet
        </h5>
        <span></span>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 px-5 mt-3 text-center">
        <img class="hw-i-w" style="border-color: #ffb514" src="../../../assets/images/home/cont-us.jpg" />
        <h5 class="fw-500 text-capitalize mt-3 text-center mx-n3">
          <span class="active-text">step 3: </span>contact our expert
        </h5>
      </div>
    </div>
    <!-- /.row -->
  </div>
</section>
<section class="d-lg-none py-3">
  <div class="d-flex flex-column new-product">
    <h5 class="text-center text-capitalize">how astro river works</h5>
    <h4><span></span></h4>
  </div>

  <div class="row mt-5">
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 px-5 text-center m-steps">
      <img class="hw-i-w" style="border-color: #2492a3" src="../../../assets/images/home/signin..jpg" />
      <h5 class="fw-500 txt-size-list text-capitalize my-2 text-center">
        <span class="active-text">step 1: </span>register yourself
      </h5>
      <span></span>
    </div>
    <div class="
        col-lg-4 col-md-4 col-sm-12 col-xs-12
        px-5
        text-center
        m-steps
        mt-5
      ">
      <img class="hw-i-w" style="border-color: #609d25" src="../../../assets/images/home/wallet.jpg" />
      <h5 class="fw-500 txt-size-list text-capitalize my-2 text-center mx-n3">
        <span class="active-text">step 2: </span>recharge your wallet
      </h5>
      <span></span>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 px-5 text-center mt-5">
      <img class="hw-i-w" style="border-color: #ffb514" src="../../../assets/images/home/cont-us.jpg" />
      <h5 class="fw-500 txt-size-list text-capitalize my-2 text-center mx-n3">
        <span class="active-text">step 3: </span>contact our expert
      </h5>
    </div>
  </div>
</section>
<!-- How it works End -->

<!-- Top Astro Start -->
<section>
  <app-top-astro [sectionTitle]="sectionTitle"></app-top-astro>
</section>
<!-- Top Astro End -->

<!-- Faq Start -->
<section class="p-lg-0 py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12 faq">
        <ngb-accordion [closeOthers]="true" activeIds="static-1">
          <ngb-panel id="static-1" title="HOW AN ONLINE ASTROLOGER CAN HELP?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>An experienced and learned Astrologer is well-versed in reading the unique alignment and position of
                  planets in the sky at the time you were born.
                </li>
                <li>This information can help to give you a greater understanding of your unique personality, natural
                  talents, strengths, and weaknesses.
                </li>
                <li>It can also provide valuable insights into your relationships, career, and life path.
                </li>
                <li>Consultation with an Astrologer can offer guidance and clarity during challenging times and can help
                  you to make better decisions by understanding the cosmic influences at play in your life.
                </li>
                <li>AstroRiver is the perfect place to find an expert online Astrologer who can offer you insights into
                  your birth chart and help you to navigate life's challenges with greater ease and understanding.
                </li>
                <li>If you are looking to make some changes in your life, an astrologer can help you set goals and
                  create a plan to achieve them.
                </li>
                <li>An Astrologer can also counsel you in tough times and help you achieve peace of mind by providing
                  positive planetary insights and clarity of the situation.
                </li>
                <li>And finally, an astrologer can help you find your purpose in life by understanding your unique
                  connection to the cosmos.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-2" title="WHY SPEAK TO ASTROLOGERS?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>Maybe you're feeling lost and need some direction, or maybe you're just curious about what the
                  future holds for you. No matter what your reason is, AstroRiver can help you find the answers you're
                  looking for.
                </li>
                <li>Astro River experts' are hand-picked for their accuracy, knowledge, and compassion. We only work
                  with the best of the best to ensure that you're getting the most accurate reading possible.
                </li>
                <li>No matter what your question is, or the area of your life, our astrologers can help guide you in the
                  right direction.
                </li>
                <li>Your phone number is never revealed and all conversations are always secure, confidential and
                  anonymous. We want you to feel comfortable and safe when you're exploring your future.
                </li>
                <li>An astrological chart is simply a 2D snapshot of the actual position of the planets in the sky at
                  the time of your birth, however reading it is a task reserved for a few. <span
                    class="font-weight-bold">Only a learned Astrologer can read and decode it correctly.</span>
                </li>
                <li><span class="font-weight-bold">"Asking an astrologer is like going to a doctor. The doctor cannot
                    change your destiny, but he can guide you on how to lead a healthy life."
                  </span></li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-3" title="WHY CHAT WITH ASTROLOGERS?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>Chat is sometimes easier than a phone call, especially if you're not comfortable talking on the
                  phone.
                </li>
                <li>Plus, our expert astrologers are available 24/7, so you can get help and advice whenever you need
                  it.
                </li>
                <li>You can take your time to compose your thoughts. This can be especially helpful when you have a lot
                  to say or want to ask detailed questions.
                </li>
                <li>Additionally, written communication can be referred to anytime later and help you to avoid
                  misunderstandings or confusion.
                </li>
                <li>You can chat with our astrologers anytime, anywhere. All you need is an internet connection and you
                  can start exploring your future.
                </li>
                <li>Chatting is better in quiet settings than a phone call. You don't have to worry about being
                  interrupted by noise in the background.
                </li>
                <li>Your chats are always private and confidential. We use the latest security measures to ensure that
                  your conversations with our online astrologers are safe.
                </li>
                <li>You can ask our astrologers anything that's on your mind. Some popular topics include love and
                  relationships, career and finance, family and friends, health and well-being, life purpose and
                  spirituality.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-4" title="WHY ASK A QUERY TO AN ASTROLOGER?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>There are many reasons why you might want to ask a question to an astrologer. Perhaps you're facing
                  a major life decision and need some guidance. Maybe you're dealing with a difficult situation and
                  could use some clarity. Or maybe you're just curious about what the future holds.
                </li>
                <li>No matter what your reason, our expert astrologers are here to help. They have years of experience
                  and knowledge, and they can offer insights into your past, present, and future.
                </li>
                <li>Asking a question is a great way to get specific advice about your situation. It also allows you to
                  get to know our astrologers and how they work.
                </li>
                <li>Sometimes all you need is clarity to reach a decision. Query may prove to be the best way to go for
                  it.
                </li>
                <li>So if you're ready to ask a question, we're ready to answer.
                </li>
                <li>It's easy! Just choose one of our expert astrologers, and then type your question into the box
                  below. We'll send your question directly to the astrologer, who will answer it as soon as possible.
                </li>
                <li>Plus, our astrologers are available 24/7, so you can get help and advice whenever you need it.</li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-5" title="WHY ORDER A REPORT FROM AN ASTROLOGER?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>An astrology report is a manually and painstakingly prepared report by an expert Astrologer by
                  analyzing your chart carefully. It's a great way to get detailed insights about yourself, your life
                  and future.
                </li>
                <li>AstroRiver's astrology reports are comprehensive and go into great detail to give you a clear
                  understanding of your situation. Our astrologers make sure to customize the report according to your
                  specific needs so that you can make the best out of it.
                </li>
                <li>An astrology report from AstroRiver will give you a detailed analysis of your birth chart and how
                  the planets are influencing your life.
                </li>
                <li>A report will also give you predictions about your future and advice on what you can do to improve
                  your current situation.
                </li>
                <li>A detailed report also contains detailed remedies to improve your current situation.
                </li>
                <li>If you are looking for a detailed and thorough astrology report for any area of your life, be it
                  love and relationship, career, business, finance, health, education or any other, then AstroRiver is
                  the right place for you.
                </li>
                <li>So what are you waiting for? Order your astrology report today!
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <!-- <div class="border col-12 rounded px-4 py-3">
          <h4 class="text-uppercase fw-500">WHY SPEAK TO ASTROLOGERS?</h4>
          <ul>
            <li>
              Maybe you're feeling lost and need some direction, or maybe you're just curious about what the future
              holds for you. No matter what your reason is, AstroRiver can help you find the answers you're looking for.

            </li>
            <li>
              Astro River experts' are hand-picked for their accuracy, knowledge, and compassion. We only work with the
              best of the best to ensure that you're getting the most accurate reading possible.

            </li>
            <li>
              No matter what your question is, or the area of your life, our astrologers can help guide you in the right
              direction.

            </li>
            <li>
              Your phone number is never revealed and all conversations are always secure, confidential and anonymous.
              We want you to feel comfortable and safe when you're exploring your future.

            </li>
            <li>An astrological chart is simply a 2D snapshot of the actual position of the planets in the sky at the
              time of your birth, however reading it is a task reserved for a few. <strong>Only a learned Astrologer can
                read and decode it correctly.</strong>
            </li>
            <li><strong>"Asking an astrologer is like going to a doctor. The doctor cannot change your destiny, but he
                can guide you on how to lead a healthy life."
              </strong></li>
          </ul>
        </div> -->
      </div>
      <div class="col-lg-6 col-md-6 col-12 faq m-lg-0 mt-3">
        <ngb-accordion [closeOthers]="true" activeIds="static-a">
          <ngb-panel id="static-a" title="WHAT ARE THE VARIOUS MODES OF CONSULTATION?
          ">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>AstroRiver offers various modes of online consultation, including call, chat, and query. You can
                  also purchase a detailed report from an astrologer on AstroRiver.
                </li>
                <li>Every mode has its unique benefits. To understand which mode of consultation you should choose, and
                  its unique benefits, <span class="font-weight-bold font-italic text-underline">please click on the
                    relevant tab available on this page.</span>
                </li>
                <li><span class="font-weight-bold">CALL:</span> The call feature on AstroRiver enables you to connect
                  with an astrologer of your choice through a voice call. This is a convenient way to get answers to
                  your burning questions without having to type out everything. To know more, <span
                    class="font-weight-bold">click on the CALL tab.</span>
                </li>
                <li><span class="font-weight-bold">CHAT:</span> The chat feature allows you to connect through secure
                  chat where you can keep a written record of your conversation for future reference. To know more,
                  <span class="font-weight-bold">click on the CHAT tab.</span>
                </li>
                <li><span class="font-weight-bold">QUERY:</span> The query feature on AstroRiver enables you to post
                  your queries on the app/website and get personalized answers from the astrologer of your choice. To
                  know more, <span class="font-weight-bold">click on the QUERY tab.</span>
                </li>
                <li><span class="font-weight-bold">REPORT:</span> You can also purchase a detailed report from an
                  astrologer of your choice on AstroRiver. This is a comprehensive report that will give you detailed
                  insights into your life. To know more, <span class="font-weight-bold">click on the REPORT tab.</span>
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-b" title="HOW CALLING AN ASTROLOGER HELPS?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>Have you ever been in a situation where money is tight? Or perhaps you're just unsure of when to
                  host your magnificent, all-encompassing dream wedding?</li>
                <li>AstroRiver's astrology consultation services, which connect you with a curated selection of
                  astrologers online, may be the perfect solution for all such concerns. 24*7 astrology consultation is
                  available.
                </li>
                <li>We always want to stay in control of our love life, career and other areas of life, but it's simply
                  not possible. We all have good and bad times. However, astrology and astrologers may turn out to be a
                  blessing in tough times.
                </li>
                <li>Planets continue to shift in the sky with each passing second impacting our lives in various ways,
                  causing mood shifts and happenings in life. Everything is predictable.</li>
                <li>A planet like Jupiter moving to your second house in the chart can bless you with a sudden income,
                  knowledge and even inheritance of wealth whereas a planet like Rahu may cause sudden loss in wealth or
                  separation from family.
                </li>
                <li><span class="font-weight-bold">"Astrology is like a weather report. It tells you when the sun will
                    shine and when it will pour."
                  </span></li>
                <li>Astrology is also much more. It's a way of life.</li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-c" title="HOW CHATTING WITH AN ASTROLOGER HELP?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>If you're going through a tough time, our astrologers can provide guidance and support.
                </li>
                <li>If you're struggling to make decisions, our astrologers can help you to explore your options and
                  find the best path forward.
                </li>
                <li>If you're feeling stuck or uncertain, our astrologers can help you to identify your obstacles and
                  find new solutions.
                </li>
                <li>If you're feeling lost or confused, they can help you find clarity and peace of mind by providing a
                  deep analysis of the situation.
                </li>
                <li>If you need advice about an important area of life, you can receive it with absolute clarity by
                  using the chat with an astrologer service.
                </li>
                <li>If you're looking for someone to talk to about the big questions in life, our astrologers are here
                  for you.</li>
                <li>In addition to offering support and guidance, our astrologers can also provide insights into your
                  past, present, and future.
                </li>
                <li>If you're curious about your love life, they can offer insights into your past, existing or future
                  relationships and for any other area of your life.
                </li>
                <li>Our astrologers are here to help you in whatever way they can. All you need to do is reach out and
                  start chatting.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-d" title="HOW TO ASK A QUERY?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>It's easy to ask a question from an online astrologer. Just click on the Ask Query button and follow
                  simple instructions, and one of our astrologers will get back to you as soon as possible.
                </li>
                <li>In order to get the most accurate advice, please be as specific as possible about your situation.
                  The more information you can provide, the better.
                </li>
                <li>Please <span class="font-weight-bold text-underline">ask only one question</span> about any
                  one area of your life. Multiple queries shall not be entertained due to the scope of this service.
                </li>
                <li>For eg., <span class="font-weight-bold">When shall I get married</span> is one query. <span
                    class="font-weight-bold">How will be my life partner</span> OR <span class="font-weight-bold">where
                    shall I find my life partner</span> are different queries.
                </li>
                <li>Once you've submitted your question, the Astrologer/Counselor will take a look at it and provide you
                  with insights and advice.
                </li>
                <li>You can expect to receive a response within 24 hours. However, in some cases it may take longer
                  depending on the complexity of your question.
                </li>
                <li>If you have any difficulty in understanding the answer, there's a feedback system in place for your
                  assistance. Your satisfaction is our priority.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-d" title="HOW CAN I ORDER A REPORT?">
            <ng-template ngbPanelContent>
              <ul class="accordian-ul">
                <li>It's easy! Just choose your desired Astrologer and click on the Get Report button. You'll be
                  presented with various types of reports available for order.
                </li>
                <li>Choose a desired report and place your order. It's extremely quick.
                </li>
                <li>Once you've ordered your report, the Astrologer/Counselor will take a look at it and request for any
                  additional information, if needed.
                </li>
                <li>The report is prepared manually by the expert by going through your chart with utmost care.
                </li>
                <li>A detailed report usually contains a deep astrological analysis of the selected life area along with
                  detailed remedial solutions.
                </li>
                <li>You can expect to receive a response within 72 hours. However, in some cases it may take longer
                  depending on the complexity of your question and number of orders in the queue.
                </li>
                <li>If you have any difficulty in understanding your report, there's a feedback system in place for your
                  assistance. Your satisfaction is our priority.
                </li>
              </ul>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <!-- <div class="border col-12 rounded px-4 py-3">
          <h4 class="text-uppercase fw-500">How Calling An Astrologer Helps?</h4>
          <ul>
            <li>
              Have you ever been in a situation where money is tight? Or perhaps you're just unsure of when to host your
              magnificent, all-encompassing dream wedding?

            </li>
            <li>
              AstroRiver's astrology consultation services, which connect you with a curated selection of astrologers
              online, may be the perfect solution for all such concerns. 24*7 astrology consultation is available.

            </li>
            <li>
              We always want to stay in control of our love life, career and other areas of life, but it's simply not
              possible. We all have good and bad times. However, astrology and astrologers may turn out to be a blessing
              in tough times.

            </li>
            <li>
              Planets continue to shift in the sky with each passing second impacting our lives in various ways, causing
              mood shifts and happenings in life. Everything is predictable.

            </li>
            <li>A planet like Jupiter moving to your second house in the chart can bless you with a sudden income,
              knowledge and even inheritance of wealth whereas a planet like Rahu may cause sudden loss in wealth or
              separation from family.
            </li>
            <li><strong>"Astrology is like a weather report. It tells you when the sun will shine and when it will
                pour."
              </strong></li>
            <li>Astrology is also much more. It's a way of life.
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="row m-0 my-3 faq">
      <div class="col-12 border rounded px-4 py-3">
        <h4 class="text-uppercase fw-500 mb-2">FAQs
        </h4>
        <ngb-accordion [closeOthers]="true" activeIds="static-i">
          <ngb-panel id="static-i" title="GENERAL">
            <ng-template ngbPanelContent>
              <dl>
                <dt>How is Astrology so Real and Accurate?
                </dt>
                <dd>Astrology is a real and accurate science that has been practiced for thousands of years. It is based
                  on the movement of the planets and stars, and their impact on human affairs. Astrology can be used to
                  understand your past, present, and future. It can provide insights into your personality, strengths,
                  and weaknesses. It can also provide guidance on your relationships, career, and finances.
                </dd>
                <dt>Is Online Astrology as good as an in-person consultation?
                </dt>
                <dd>Yes, online astrology is just as good as an in-person consultation. In fact, it has several
                  advantages. With online astrology, you can consult with an astrologer from the comfort of your own
                  home. You can also choose from a wide range of astrologers, each with their own specialties and areas
                  of expertise. And, you can get a detailed report that you can refer to again and again. There are many
                  benefits of an online astrology consultation. Online astrology is convenient, affordable, and provides
                  you with a wide range of options.
                </dd>
                <dt>How accurate is Astrology Prediction?
                </dt>
                <dd>Astrology predictions are based on the movement of the planets and stars. The accuracy of astrology
                  predictions depends on the astrologer's skill and experience. It is important to choose an experienced
                  and reputable astrologer for accurate predictions. At AstroRiver, we have a team of experienced and
                  qualified astrologers who can provide you with accurate predictions.
                </dd>
                <dt>What Is The Average Time It Takes For An Online Astrology Consultation?
                </dt>
                <dd>The length of the consultation may vary depending on the nature of your question and the astrologer
                  you choose. </dd>
                <dt>What can I consult with Online Astrologers?
                </dt>
                <dd>You can consult with online astrologers about anything that is bothering you. Whether you are facing
                  a challenge in your personal life or looking for guidance in your career, an online astrology
                  consultation can help. Whether it's Love and Relationships, Career, or Money Matters, our astrologers
                  can help.
                </dd>
                <dt>How beneficial are online Astrology Services?
                </dt>
                <dd>may use the insights supplied by our astrologers about your future or present self to be cautious in
                  areas that will assist you deal with critical issues such as finance, career, and love. Next, you can
                  avail various offers which are available on the AstroRiver app from time to time. That can result in
                  some significant savings.
                  An astrologer can tell you a lot about yourself, as well as your family, friends, coworkers, and other
                  individuals in your life. These actions might make you appear better in the eyes of those that matter.
                  As a result of this, astrology may help you develop your personality by offering several suggestions
                  and ways.
                </dd>
              </dl>
              <a class="btn btn-red bg-green border-0 accordion-a  " [routerLink]="['/faq']">See All FAQs <i
                  class="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-ii" title="FOR CALL">
            <ng-template ngbPanelContent>
              <dl>
                <dt>What should I ask an Astrologer?
                </dt>
                <dd>You can ask an Astrologer anything that's on your mind. Some popular topics include love and
                  relationships, career and finance, family and friends, health and well-being, life purpose and
                  spirituality.
                </dd>
                <dt>What if I don't know my time of birth?
                </dt>
                <dd>If you don't know your time of birth, our Astrologers can still give you a reading. You may choose a
                  numerologist who works exclusively with your date of birth only, or with any Astrologer who can use
                  Prashna Paddhati (horary) to answer your questions. Also, you can have your Birth-Time Rectification
                  (BTR) performed.
                </dd>
                <dt>Why are some experts so expensive?
                </dt>
                <dd>Prices for readings vary depending on the expert's experience, skills, popularity, and demand. In
                  fact, consulting an expensive looking expert may sometimes give you quicker understanding of your
                  chart. It's qualitative, not quantitative.
                </dd>
                <dt>How do I know if an expert is good?
                </dt>
                <dd>You can read reviews from other clients to get an idea of what to expect. Also, all of our experts
                  have been thoroughly screened and vetted by our team of experts.
                </dd>
                <dt>What if I'm not satisfied with my reading?
                </dt>
                <dd>If you're not satisfied with your reading, please contact us and we'll be happy to help you find
                  another expert who may be a better fit for you. We however do not offer any refunds unless there's
                  been no consultation at all.
                </dd>
              </dl>
              <a class="btn btn-red border-0 bg-green accordion-a" [routerLink]="['/faq']">See All FAQs <i
                  class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-iii" title="FOR CHAT">
            <ng-template ngbPanelContent>
              <dl>
                <dt>How can I contact an astrologer for a chat session?
                </dt>
                <dd>To chat with an online astrologer, simply top up your wallet and go to our app or website. After you
                  click on chat with an astrologer, you can choose from hundreds of astrologers who are available to
                  chat with you at any time.
                </dd>
                <dt>Every Astrologer/Expert on AstroRiver has a 5 star rating. Why?
                </dt>
                <dd>Every expert available on AstroRiver has to go through a very detailed 5 step system to be approved
                  for the website/app. Only the highest-rated profiles listed on the AstroRiver website and app are in a
                  visible state; all others are intentionally removed until we are sure they comply with our standards
                  through our intensive training system. Some of these may also be removed permanently if they are
                  unable to meet the AstroRiver's quality standards.
                </dd>
                <dt>How confidential are my chats?
                </dt>
                <dd>Your chats are completely confidential. We use the highest level of encryption to protect your
                  information and they can only be accessed by you or when you specifically ask for and allow our team
                  to access them. Your phone number is also kept completely confidential by AstroRiver.
                </dd>
                <dt>Is there a limit to how long I can chat with an Expert/Astrologer?
                </dt>
                <dd>Duration of your chat depends upon (a) Your Wallet Balance (b) Price of Expert/Astrologer per
                  minute. You can chat for as long as you want as long as you have balance in your AstroRiver wallet.
                </dd>
                <dt>How do I pay for my chat sessions?
                </dt>
                <dd>You will be asked to top-up your wallet before starting the chat session. The amount will be
                  deducted from your wallet according to the price per minute of the Expert/Astrologer after the chat
                  ends. You may also extend an ongoing session by recharging your wallet during the chat.
                </dd>
              </dl>
              <a class="btn btn-red bg-green border-0 accordion-a" [routerLink]="['/faq']">See All FAQs <i
                  class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-iv" title="FOR QUERY">
            <ng-template ngbPanelContent>
              <dl>
                <dt>Why is AstroRiver the Best Choice For An Astrology Horoscope?
                </dt>
                <dd>AstroRiver has some of the most experienced and knowledgeable astrologers and services in the
                  business. We have a very large following of astrology enthusiasts that is nothing but pleased with all
                  the astrological services we provide. The selection process for astrologers on the AstroRiver platform
                  is quite rigorous, and only the top practitioners can survive it to ensure you get the best service
                  each time you collaborate with us.
                  We take pride in our exceptional customer support. We offer 24/7 support so you can get help and
                  advice whenever you need it. You can also write directly to the management at ceo@astroriver.com for
                  any issues you are facing.
                </dd>
                <dt>Which services are available on AstroRiver?
                </dt>
                <dd>AstroRiver offers a host of online astrology services, ranging from live consultations, online
                  Kundli matching, horoscope predictions, daily horoscope, weekly horoscope, numerology, tarot reading,
                  astro shopping, completely free articles and blogs, and many more other services.
                  You can find the complete list of services on our website.
                </dd>
                <dt>Is Online Astrology Consultation Really Authentic?
                </dt>
                <dd>AstroRiver's online astrology consultation is absolutely authentic. We have some of the most
                  experienced and knowledgeable astrologers on our platform who can offer you insights into your past,
                  present, and future.
                </dd>
                <dt>Can Astrology Predictions Change?
                </dt>
                <dd>If an Astrologer does not have the correct time of birth (or any other credential) of an individual,
                  the predictions may not be accurately reflected. In such cases, the astrologer may need to recalculate
                  the horoscope with the correct information to get more accurate results. So yes, astrology predictions
                  can change.
                  The planets are constantly moving, and their positioning impacts our lives in different ways. As such,
                  your astrological predictions may change over time.
                </dd>
              </dl>
              <a class="btn btn-red accordion-a bg-green border-0" [routerLink]="['/faq']">See All FAQs <i
                  class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="static-v" title="FOR REPORT">
            <ng-template ngbPanelContent>
              <dl>
                <dt>What type of online astrology reports are available for order?
                </dt>
                <dd>AstroRiver offers a wide range of online astrology reports for various life areas such as love and
                  relationship, career, business, finance, health, education etc. You may choose your desired Astrologer
                  and type of report and order it using the website/app.</dd>
                <dt>Will I get a detailed report?
                </dt>
                <dd>AstroRiver's astrology reports are comprehensive and go into great detail to give you a clear
                  understanding of your situation. Our astrologers make sure to customize the report according to your
                  specific needs so that you can make the best out of it.
                </dd>
                <dt>What if the report is not to my liking?
                </dt>
                <dd>Your satisfaction is extremely important to us and our criteria for selection of report writers is
                  also very strict. If the report consists of any grammatical errors or there's a problem understanding
                  it for any reason, we shall ensure that you receive timely and proper assistance to get the most out
                  of your report. However, since each and every Astrologer has his own expertise and way of astrological
                  predictions, we are unable to provide refunds for the reports.
                </dd>
                <dt>How quickly will I receive the report?
                </dt>
                <dd>You can expect to receive a response within 72 hours (sometimes sooner). However, in some cases it
                  may take longer depending on the complexity of your question and number of orders in the queue.
                </dd>
                <dt>I have more questions. Who can I contact?
                </dt>
                <dd>You may contact us at +91-7009127641 (Whatsapp - Live Chat Support/Call - Limited Support) or write
                  to us at contact@astroriver.com
                </dd>
              </dl>
              <a class="btn btn-red border-0 bg-green accordion-a" [routerLink]="['/faq']">See All FAQs <i
                  class="fa-solid fa-arrow-up-right-from-square"></i></a>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <!-- <dl>
          <dt>What should I ask an Astrologer?
          </dt>
          <dd>You can ask an Astrologer anything that's on your mind. Some popular topics include love and
            relationships, career and finance, family and friends, health and well-being, life purpose and spirituality.
          </dd>

          <dt>What if I don't know my time of birth?
          </dt>
          <dd>If you don't know your time of birth, our Astrologers can still give you a reading. You may choose a
            numerologist who works exclusively with your date of birth only, or with any Astrologer who can use Prashna
            Paddhati (horary) to answer your questions. Also, you can have your Birth-Time Rectification (BTR)
            performed.
          </dd>

          <dt>Why are some experts so expensive?
          </dt>
          <dd>Prices for readings vary depending on the expert's experience, skills, popularity, and demand. In fact,
            consulting an expensive looking expert may sometimes give you quicker understanding of your chart. It's
            qualitative, not quantitative.
          </dd>

          <dt>How do I know if an expert is good?</dt>
          <dd>You can read reviews from other clients to get an idea of what to expect. Also, all of our experts have
            been thoroughly screened and vetted by our team of experts.
          </dd>

          <dt>What if I'm not satisfied with my reading?
          </dt>
          <dd>If you're not satisfied with your reading, please contact us and we'll be happy to help you find another
            expert who may be a better fit for you. We however do not offer any refunds unless there's been no
            consultation at all.
          </dd>
        </dl> -->
      </div>
    </div>
  </div>
</section>
<!-- Faq End -->
<!-- Banner Start-->
<section class="my-5 cursor-pointer" [routerLink]="['/call-astro-list']">
  <div class="container">
    <img src="../../../assets/images/astro/banner.png" class="w-100" />
  </div>
</section>
<!-- Banner End-->

<!-- Find Expert Start -->
<section class="mt-5">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="d-none d-lg-block text-capitalize text-center">
        Find best expert
      </h4>
      <h5 class="d-lg-none text-capitalize text-center">Find best expert</h5>
      <h4 class="text-center"><span></span></h4>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 col-md-6 col-12 col-lg-3 col-6">
        <div class="card h-100">
          <div class="card-header bg-red text-capitalize text-white fw-500">
            Consult experts
          </div>
          <div class="card-body">
            <ul class="txt-size-list expert-list">
              <li class="text-capitalize">
                <a routerLink="../call-astro-list">talk to experts</a>
              </li>
              <li class="text-capitalize">
                <a routerLink="../chat-astro-list">chat with experts</a>
              </li>
              <li class="text-capitalize">
                <a routerLink="../query-astro-list">ask query</a>
              </li>
              <li class="text-capitalize">
                <a routerLink="../report-astro-list">get report</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 col-lg-3 col-6 m-lg-0 m-md-0 mt-3">
        <div class="card h-100">
          <div class="card-header bg-red text-capitalize text-white fw-500">
            Experts(city wise)
          </div>
          <div class="card-body">
            <ul class="txt-size-list expert-list">
              <li class="text-capitalize">Experts in Delhi</li>
              <li class="text-capitalize">Experts in Gurgaon</li>
              <li class="text-capitalize">Experts in Mumbai</li>
              <li class="text-capitalize">Experts in Kolkata</li>
              <li class="text-capitalize">Experts in Bangalore</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 col-lg-3 col-6 m-lg-0 m-md-0 mt-3">
        <div class="card h-100">
          <div class="card-header bg-red text-capitalize text-white fw-500">
            International Experts
          </div>
          <div class="card-body">
            <ul class="txt-size-list expert-list">
              <li class="text-capitalize">Experts in USA</li>
              <li class="text-capitalize">Experts in California</li>
              <li class="text-capitalize">Experts in new york</li>
              <li class="text-capitalize">Experts in houston</li>
              <li class="text-capitalize">Experts in new jersey</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 col-lg-3 col-6 m-lg-0 m-md-0 mt-3">
        <div class="card h-100">
          <div class="card-header bg-red text-capitalize text-white fw-500">
            Category wise
          </div>
          <div class="card-body">
            <ul class="txt-size-list expert-list">
              <li class="text-capitalize">Vedic Astrology</li>
              <li class="text-capitalize">Nadi Astrology</li>
              <li class="text-capitalize">Numerology</li>
              <li class="text-capitalize">Vasthu</li>
              <li class="text-capitalize">Gemology</li>
              <li class="text-capitalize">Prashana Astrology</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Find Expert End -->

<app-footer></app-footer>