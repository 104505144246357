import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { ProductService } from 'src/app/service/product/product.service';
import { ProductcategoryService } from 'src/app/service/productcategory/productcategory.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-astro-subcategory-product',
  templateUrl: './astro-subcategory-product.component.html',
  styleUrls: ['./astro-subcategory-product.component.css']
})
export class AstroSubcategoryProductComponent implements OnInit {

  searchConfig = {
    displayKey: 'productname',
    searchPlaceholder: 'Search product here',
    search: true,
    placeholder: 'Search Product',
    noResultsFound: 'No Product found!',
  };
  BaseUrl = '';
  dataList: any = [];
  id: any;
  searchList: any = [];
  formModel = {};
  category_id = '';
  code: any;
  isLogin: boolean = false;
  constructor(
    private product: ProductService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private categoryService: ProductcategoryService,
    private loginService: LoginService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  data = {};
  //productcategoryId: this.category_id,
  walletBalance = 0; 
  totalPages = 0;
  breadcrumb = '';
  ngOnInit(): void {
    this.category_id = this.route.snapshot.paramMap.get('id') ?? '';
    //const type = this.route.snapshot.paramMap.get('type') ?? '';
    this.breadcrumb = 'Astro Mall';
    this.data = { status: true, categoryId: this.category_id };

    this.getSubcategoryListByCatId();
    this.getProductCategoryList()
    this.code = this.userdata.isIndia();
    this.isLogin = this.userdata.isLogin();
    if(this.userdata.isLogin()){
      this.walletBalance = Number(this.userdata.getCurrentWallet()) ?? 0;
      this.getMyProfile()
    }
  }
  goToRecharge() {
    if (this.userdata.isLogin()) this.router.navigateByUrl('/recharge-wallet');
    else this.toast.error('Please login to recharge');
  }
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  filterType = 0;
  initPage = 0;
  applyFilter(id: number, filterType: number) {
    this.filterType = filterType;
  }
  selectProduct(event: any) {
    this.router.navigate(['/product-detail', event.value.product_slug]);
  }
  getSubcategoryListByCatId() {
    this.spinner.show();
    this.product.getSubcategoryListByCatId(this.data).subscribe(
      (res: any) => {
        this.categoryName = res?.data[0]?.categoryId?.categoryname
        const total = res.totalobj / 10;
        var temp = Math.round(total);
        if (temp < total) {
          this.totalPages = temp + 1;
        } else {
          this.totalPages = temp;
        }
        this.dataList = res.data;
        this.initPage++;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  loadMore() {}
  getCurrentPrice(product: any) {
    return this.code ? product.price : product.usd_price || 0;
  }
  getOrginialPrice(product: any) {
    return this.code
      ? product.original_amount
      : product.usd_original_amount || 0;
  }
  /**
   * Search Product
   */
  searchProducts = new Array();
  searchProduct(event: any) {
    if (event.length < 3) return;
    this.spinner.show();
    this.product.searchProduct({ startpoint: 0, name: event }).subscribe({
      next: (res: any) => {
        this.searchProducts = res.data;
      },
      error: (err) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  /** Add to cart */
  @ViewChild("headerOne") headerOne:any;
  book(id: any) {
    if (this.userdata.getToken() == (null || '')) {
      this.toast.error('Please Login to use Service', 'Info');
      this.spinner.hide();
      sessionStorage.setItem('product-detail', id);
      this.headerOne?.openFromAnotherComponent()
      return;
    }else if(this.userdata.getEmailVerify() == ('false' || false)){
      sessionStorage.setItem('product-detail', id);
      this.toast.error('Please Verify Email');
      this.router.navigateByUrl('/verify-email-otp')
      return
    } else {
      this.router.navigate(['/order-product', id]);
    }
  }

  /** Category List */
  categoryList = new Array();
  /**
   * Get All Product Category
   */
   getProductCategoryList() {
    this.spinner.show();
    this.categoryService.allProductCategory({ status: true }).subscribe(
      (res: any) => {
        this.categoryList = res.data;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  getCategoryId(id:any){
    this.data = { status: true, categoryId: id };
    this.getSubcategoryListByCatId();
  }

  categoryName:string = ''

  /**Wallet currencycode */
  myProfile: any;
  getMyProfile() {
    this.loginService.myProfile({ _id: this.userdata.getCustomerId()}).subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          this.myProfile = res.data;
          this.userdata.setCurrentWallet(this.myProfile.current_wallet)
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

}
