<app-header></app-header>
<!-- hs breadcrumb Start -->
<div class="hs_indx_title_main_wrapper">
  <div class="hs_title_img_overlay"></div>
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
              <div class="hs_indx_title_left_wrapper">
                  <h2>Notifications</h2>
              </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
              <div class="hs_indx_title_right_wrapper text-capitalize">
                  <ul>
                      <li><a [routerLink]="['/home']">Home</a> &nbsp;&nbsp;&nbsp;&gt; </li>
                      <li>Notifications</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- /hs breadcrumb -->
<div class="hs_service_main_wrapper pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <div class="hs_kd_five_heading_sec_wrapper">
                    <h2>Notifications</h2>
                    <h4><span></span></h4>
                </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                <table class="table table-bordered table-stripped">
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Date</th>
                    </tr>
                    <tr *ngFor="let item of dataList  | slice: (page-1) * pageSize : page * pageSize;let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.notification_title}}</td>
                        <td>{{item.notification_description}}</td>
                        <td>{{item.created_at||'' | date:'medium'}}</td>
                    </tr>
                </table>
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="dataList.length" [maxSize]="9" [boundaryLinks]="true"></ngb-pagination>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
