import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
})
export class MyProfileComponent implements OnInit {
  BaseUrl = '';
  dataList: any = '';
  peopleList: any = [];
  isSelfProfile = false;
  isChangePassword = false;
  isLoggedIn: boolean = false;
  passwordForm: FormGroup;
  isSocialLogin = 'false';
  wallet = 0;
  constructor(
    private loginService: LoginService,
    private toast: ToastrService,
    private trusturl: DomSanitizer,
    private userdata: UserdataService,
    private router: Router,
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
    this.passwordForm = this.formBuilder.group(
      {
        userId: '',
        oldpassword: ['', [Validators.required]],
        newpassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()_+-]).{8,}$'
            ),
          ],
        ],
        confirmpassword: ['', [Validators.required]],
      },
      { validators: this.password.bind(this) }
    );
  }
  currency = '';
  ngOnInit(): void {
    this.wallet = Number(this.userdata.getCurrentWallet() ?? '0');
    this.getMyProfile();
    this.getMyPeople();
    this.isSocialLogin = this.userdata.getIsSocialLogin() || 'false';
    //console.log(this.isSocialLogin)
  }
  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  myProfile: any;
  getMyProfile() {
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          this.myProfile = res.data;
          if (this.dataList.selfprofileId) {
            this.isSelfProfile = true;
          }
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  getMyPeople() {
    this.loginService
      .myPeople({ customerId: this.userdata.getCustomerId(),is_hide: false })
      .subscribe(
        (res: any) => {
          this.peopleList = res.data;
        },
        (err) => {}
      );
  }

  hidePersonProfile(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loginService
          .hidePersonProfile({
            customerId: this.userdata.getCustomerId(),
            _id: id,
          })
          .subscribe(
            (res: any) => {
              this.toast.success(res.message, 'Success');
              this.getMyProfile();
              this.getMyPeople();
            },
            (err) => {
              this.toast.error(err.error.message, 'Alert');
            }
          );
      }
    });
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.controls['newpassword'];
    const { value: confirmPassword } = formGroup.controls['confirmpassword'];
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  get f() {
    return this.passwordForm.controls;
  }

  openChangePassword() {
    this.isChangePassword = !this.isChangePassword;
  }

  changePassword() {
    this.passwordForm.patchValue({ userId: this.userdata.getUserId() });
    this.loginService.updatePassword(this.passwordForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
        this.passwordForm.patchValue({ oldpassword: '' });
        this.passwordForm.patchValue({ newpassword: '' });
        this.passwordForm.patchValue({ confirmpassword: '' });
        this.f;
        this.isChangePassword = !this.isChangePassword;
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  /**
   * Logout
   */
  logout() {
    this.loginService
      .logout({
        is_web: true,
        email: this.userdata.getEmail(),
        fcm_token: this.userdata.getFcm(),
      })
      .subscribe(
        (res: any) => {
          this.userdata.removeData();
          this.toast.success(res.message, 'Success');
          this.isLoggedIn = false;
          // this.modalService.dismissAll();
          this.router.navigateByUrl('/home');
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
        },
        () => {}
      );
  }
  closeResult: string = '';
  openDialog(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.logout();
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  currentPasswordT = false;
  newPasswordT = false;
  confirmPasswordT = false;
  newPasswordToggle() {
    this.newPasswordT = !this.newPasswordT;
  }
  currentPasswordToggle() {
    this.currentPasswordT = !this.currentPasswordT;
  }
  confirmPasswordToggle() {
    this.confirmPasswordT = !this.confirmPasswordT;
  }

  goToRecharge() {
    if (this.userdata.isLogin()) this.router.navigateByUrl('/recharge-wallet');
    else this.toast.error('Please login to recharge');
  }
}
