import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-horoscope',
  templateUrl: './horoscope.component.html',
  styleUrls: ['./horoscope.component.css']
})
export class HoroscopeComponent implements OnInit {

  dataList:any = ''
  id:any = ''
  BaseUrl = '';
  imageName = ''
  constructor(
    private openService: OpenApiService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,@Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
    }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getTodayHoroscope()
    this.imageName = this.id+'.png'
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+'open/zodiacsign/'+url)
  }

  getTodayHoroscope(){
    this.spinner.show()
    this.openService.dailyHoroscope({sign:this.id}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
      }
    )
  }

}
