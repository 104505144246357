import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { Label, MultiDataSet, Color } from 'ng2-charts';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-kundli-show',
  templateUrl: './kundli-show.component.html',
  styleUrls: ['./kundli-show.component.css']
})
export class KundliShowComponent implements OnInit {

  kundliData: any;
  currentMainTab = 'Basic'
  currentSubTab = 'Details'
  searchData: any = {};
  constructor(private router: Router, private openService: OpenApiService, private toast: ToastrService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (localStorage.getItem('kundli') == undefined || localStorage.getItem('kundli') == '' || localStorage.getItem('kundli') == null) {
      this.router.navigateByUrl('/home')
    }
    this.kundliData = JSON.parse(localStorage.getItem('kundli') ?? '')?.[0]
  }

  changeSubTab(data: any) {
    this.searchData = ''
    this.currentSubTab = data
    if (data == 'Panchang') {
      this.searchData = {
        apiaddress: 'panchang/panchang',
        date: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0],
      }
    }
    if (data == 'Avakhada') {
      this.searchData = {
        apiaddress: 'extended-horoscope/extended-kundli-details',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Sati') {
      this.currentMainTab = 'Sati'
      this.searchData = {
        apiaddress: 'extended-horoscope/current-sade-sati',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }

    if (data == 'Mangal') {
      this.currentMainTab = 'Dosh'
      this.currentSubTab = 'Mangal'
      this.searchData = {
        apiaddress: 'dosha/mangal-dosh',
        planet: 'Mars',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Sarp') {
      this.currentMainTab = 'Dosh'
      this.currentSubTab = 'Sarp'
      this.searchData = {
        apiaddress: 'dosha/kaalsarp-dosh',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Pitr') {
      this.currentMainTab = 'Dosh'
      this.currentSubTab = 'Pitr'
      this.searchData = {
        apiaddress: 'dosha/pitra-dosh',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Paapsamay') {
      this.currentMainTab = 'Dosh'
      this.currentSubTab = 'Paapsamay'
      this.searchData = {
        apiaddress: 'dosha/papasamaya',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Gem') {
      this.currentMainTab = 'Gem'
      this.searchData = {
        apiaddress: 'extended-horoscope/gem-suggestion',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Rudhraksh') {
      this.currentMainTab = 'Rudhraksh'
      this.searchData = {
        apiaddress: 'extended-horoscope/rudraksh-suggestion',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Ashtakvarga') {
      this.currentMainTab = 'Ashtakvarga'
      this.searchData = {
        apiaddress: 'horoscope/ashtakvarga',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Ascendant') {
      this.currentMainTab = 'Ascendant'
      this.searchData = {
        apiaddress: 'horoscope/ascendant-report',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Manglik') {
      this.currentMainTab = 'Manglik'
      this.searchData = {
        apiaddress: 'dosha/mangal-dosh',
        planet: 'Mars',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Sign' || data == 'Nakshatra') {
      this.currentMainTab = 'Planets'
      this.currentSubTab = data
      this.searchData = {
        apiaddress: 'horoscope/planet-details',
        class: 'planet_details',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Lagna') {
      this.currentMainTab = 'Lagna'
      this.currentSubTab = ''
      this.searchData = {
        apiaddress: 'horoscope/planet-details',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Navamsa') {
      this.currentMainTab = 'Navamsa'
      this.searchData = {
        apiaddress: 'horoscope/divisional-charts',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0],
        div: "D9"
      }
    }
    if (data == 'D3' || data == 'D3s' || data == 'D7' || data == 'D9' || data == 'D10' || data == 'D12' || data == 'D10R' || data == 'D16' || data == 'D20' || data == 'D24' || data == 'D24R' || data == 'D30' || data == 'D40' || data == 'D45' || data == 'D60') {
      this.currentMainTab = 'Chart'
      this.currentSubTab = data
      this.searchData = {
        apiaddress: 'horoscope/divisional-charts',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0],
        div: data
      }
    }
    if (data == 'MahaDasha') {
      this.currentMainTab = 'Dasha'
      this.currentSubTab = 'MahaDasha'
      this.searchData = {
        apiaddress: 'dashas/maha-dasha',
        class: 'planet_details',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'AntarDasha') {
      this.currentMainTab = 'Dasha'
      this.currentSubTab = 'AntarDasha'
      this.searchData = {
        apiaddress: 'dashas/antar-dasha',
        class: 'antar-dasha',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'ParyantarDasha') {
      this.currentMainTab = 'Dasha'
      this.currentSubTab = 'ParyantarDasha'
      this.searchData = {
        apiaddress: 'dashas/paryantar-dasha',
        // class: 'antar-dasha',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Shookshamadasha') {
      var arr = this.selectedShookshamadashaPlanet.split('/')
      this.currentMainTab = 'Dasha'
      this.currentSubTab = 'Shookshamadasha'
      this.searchData = {
        apiaddress: 'dashas/specific-sub-dasha',
        class: 'specific-sub-dasha',
        md: this.getPlanetFullName(arr[0]),
        ad: this.getPlanetFullName(arr[1]),
        pd: this.getPlanetFullName(arr[2]),
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'PranDasha') {
      var arr = this.selectedPranDashaPlanet.split('/')
      this.currentMainTab = 'Dasha'
      this.currentSubTab = 'PranDasha'
      this.searchData = {
        apiaddress: 'dashas/specific-sub-dasha',
        class: 'specific-sub-dasha',
        md: this.getPlanetFullName(arr[0]),
        ad: this.getPlanetFullName(arr[1]),
        pd: this.getPlanetFullName(arr[2]),
        sd: this.getPlanetFullName(arr[3]),
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    if (data == 'Numerology') {
      this.currentMainTab = 'Numerology'
      this.searchData = {
        apiaddress: 'prediction/numerology',
        date: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        name: this.kundliData?.form?.name,
      }
    }
    if (data == 'Bio') {
      this.currentMainTab = 'Bio'
      this.searchData = {
        apiaddress: 'prediction/biorhythm',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        name: this.kundliData?.form?.name,
      }
    }
    if (data != 'Details') {
      this.getDataFromApi()
    }
    if (data == 'Details') {
      this.currentMainTab = 'Basic'
      this.currentSubTab = 'Details'
    }
  }

  kundliResponse: any;
  getDataFromApi() {
    this.spinner.show()
    this.openService.getGeoLocation(this.searchData).subscribe(
      (res: any) => {
        this.kundliResponse = res.data
        if (this.currentMainTab == 'Lagna') {
          this.getChartImage('', 'D1')
        }
        if (this.currentMainTab == 'Navamsa') {
          this.getChartImage('', 'north')
        }
        if (this.currentMainTab == 'Chart') {
          this.getChartImage('', 'north')
        }
        if (this.currentSubTab == 'AntarDasha') {
          this.getDashaData()
        }
        if (this.currentSubTab == 'ParyantarDasha') {
          this.getDashaData()
        }
        if (this.currentSubTab == 'Bio') {
          this.doughnutChartData = [[this.getPercentage(this.kundliResponse?.physical?.score), this.getPercentage(this.kundliResponse?.emotional?.score), this.getPercentage(this.kundliResponse?.intellectual?.score)]];
        }
        //console.log(this.kundliResponse.length)
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message)
      }
    )
  }

  getJoinArray(data: any) {
    return data.join()
  }

  zodiacSigns = ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces']

  chart: any;
  svg: SafeHtml | undefined;
  getChartImage(data: any, val: any) {
    var search: any;
    if (this.currentMainTab == 'Lagna') {
      if (data == null || data == '' || data == undefined) {
        search = {
          apiaddress: "horoscope/chart-image",
          style: 'north',
          color: 'b10712',
          dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
          tob: this.kundliData?.form?.tob,
          name: this.kundliData?.form?.name,
          tz: this.kundliData?.location?.tz,
          lat: this.kundliData?.location?.coordinates[1],
          lon: this.kundliData?.location?.coordinates[0],
          div: val
        };
      } else {
        search = {
          apiaddress: "horoscope/chart-image",
          style: 'north',
          color: 'b10712',
          dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
          tob: this.kundliData?.form?.tob,
          name: this.kundliData?.form?.name,
          tz: this.kundliData?.location?.tz,
          lat: this.kundliData?.location?.coordinates[1],
          lon: this.kundliData?.location?.coordinates[0],
          div: data.target.value
        };
      }
    }
    if (this.currentMainTab == 'Navamsa') {
      search = {
        apiaddress: "horoscope/chart-image",
        style: val,
        color: 'b10712',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0],
        div: "D9"
      };
    }
    if (this.currentMainTab == 'Chart') {
      search = {
        apiaddress: "horoscope/chart-image",
        style: val,
        color: 'b10712',
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0],
        div: this.currentSubTab
      };
    }
    this.spinner.show()
    this.openService.getGeoLocation(search).subscribe(
      (res: any) => {
        this.chart = res.data
        this.svg = this.sanitizer.bypassSecurityTrustHtml(this.chart);
        //console.log(this.kundliResponse.length)
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message)
      }
    )
  }

  getInnerLagnaData(data: any) {
    if (this.currentMainTab == 'Lagna') {
      this.currentSubTab = 'Lagna Detail'
      this.searchData = {
        apiaddress: 'horoscope/planet-report',
        planet: data,
        dob: moment(this.kundliData?.form?.dob).format('DD/MM/YYYY'),
        tob: this.kundliData?.form?.tob,
        name: this.kundliData?.form?.name,
        tz: this.kundliData?.location?.tz,
        lat: this.kundliData?.location?.coordinates[1],
        lon: this.kundliData?.location?.coordinates[0]
      }
    }
    this.getDataFromApi()
  }

  getChartTableData(event: any) {
    this.changeSubTab(event.target.value)
  }

  matchDate(index: any) {
    var start: any; var end: any; var current: any;
    if (index == 0) {
      start = moment(this.kundliData?.form?.dob).format('YYYY-MM-DD')
    } else {
      start = moment(this.kundliResponse?.mahadasha_order?.[index - 1]).format('YYYY-MM-DD')
    }
    end = moment(this.kundliResponse?.mahadasha_order?.[index]).format('YYYY-MM-DD')
    current = moment().format('YYYY-MM-DD')
    if (moment(current).isBetween(start, end) || moment(current).isSame(start) || moment(current).isSame(end)) {
      return true
    } else {
      return false
    }
  }

  planets = ["Sun", "Moon", "Mars", "Rahu", "Jupiter", "Saturn", "Mercury", "Ketu", "Venus"]

  /** Dasha */
  selectedAntraDashaPlanet: any = ''
  selectedParyantarDashaPlanet: any = ''
  selectedShookshamadashaPlanet: any = ''
  selectedPranDashaPlanet: any = ''

  selectDasha(planet: any) {
    if (this.currentSubTab == 'MahaDasha') {
      this.selectedAntraDashaPlanet = planet;
      this.changeSubTab('AntarDasha')
      return;
    }
    if (this.currentSubTab == 'AntarDasha') {
      this.selectedParyantarDashaPlanet = planet
      this.changeSubTab('ParyantarDasha')
      return;
    }
    if (this.currentSubTab == 'ParyantarDasha') {
      this.selectedShookshamadashaPlanet = planet
      this.changeSubTab('Shookshamadasha')
      return;
    }
    if (this.currentSubTab == 'Shookshamadasha') {
      this.selectedPranDashaPlanet = this.selectedShookshamadashaPlanet + '/' + this.getSmallName(planet)
      this.changeSubTab('PranDasha')
      return;
    }
    if (this.currentSubTab == 'PranDasha') {
      //No action
    }
  }
  antarDashaIndex = 0;
  paryantarDashaIndexMain = 0;
  paryantarDashaIndexSub = 0;
  getDashaData() {
    var string: any;
    if (this.currentSubTab == 'AntarDasha') {
      string = String(this.selectedAntraDashaPlanet + '/' + this.selectedAntraDashaPlanet)
      this.kundliResponse?.antardashas?.forEach((elem: any, index: any) => {
        if (elem.findIndex((x: any) => x == string) > -1) {
          this.antarDashaIndex = index
        }
      });
    }
    if (this.currentSubTab == 'ParyantarDasha') {
      var arr = this.selectedParyantarDashaPlanet.split('/')
      arr[0] = arr[0].substring(0, 2)
      arr[1] = arr[1].substring(0, 2)
      string = String(arr[0] + '/' + arr[1] + '/' + arr[1])
      this.kundliResponse?.paryantardasha?.forEach((elem: any, index: any) => {
        elem.forEach((val: any, indx: any) => {
          if (val.findIndex((x: any) => x == string) > -1) {
            this.paryantarDashaIndexMain = index
            this.paryantarDashaIndexSub = indx
          }
        })
      });
    }
  }

  matchDateAntarDasha(index: any) {
    var start: any; var end: any; var current: any;
    if (index == 0) {
      start = moment(this.kundliData?.form?.dob).format('YYYY-MM-DD')
    } else {
      start = moment(this.kundliResponse?.antardasha_order?.[this.antarDashaIndex]?.[index - 1]).format('YYYY-MM-DD')
    }
    end = moment(this.kundliResponse?.antardasha_order?.[this.antarDashaIndex]?.[index]).format('YYYY-MM-DD')
    current = moment().format('YYYY-MM-DD')
    if (moment(current).isBetween(start, end) || moment(current).isSame(start) || moment(current).isSame(end)) {
      return true
    } else {
      return false
    }
  }

  matchDateParyantarDasha(index: any) {
    var start: any; var end: any; var current: any;
    if (index == 0) {
      start = moment(this.kundliData?.form?.dob).format('YYYY-MM-DD')
    } else {
      start = moment(this.kundliResponse?.paryantardasha_order?.[this.paryantarDashaIndexMain]?.[this.paryantarDashaIndexSub]?.[index - 1]).format('YYYY-MM-DD')
    }
    end = moment(this.kundliResponse?.paryantardasha_order?.[this.paryantarDashaIndexMain]?.[this.paryantarDashaIndexSub]?.[index]).format('YYYY-MM-DD')
    current = moment().format('YYYY-MM-DD')
    if (moment(current).isBetween(start, end) || moment(current).isSame(start) || moment(current).isSame(end)) {
      return true
    } else {
      return false
    }
  }

  matchDateShookshamadasha(data: any) {
    var start: any; var end: any; var current: any;
    start = moment(data?.start).format('YYYY-MM-DD')
    end = moment(data?.end).format('YYYY-MM-DD')
    current = moment().format('YYYY-MM-DD')
    if (moment(current).isBetween(start, end) || moment(current).isSame(start) || moment(current).isSame(end)) {
      return true
    } else {
      return false
    }
  }

  getPlanetFullName(string: String) {
    if (string.toLowerCase() == "su") {
      return "Sun"
    } else if (string.toLowerCase() == "mo") {
      return "Moon"
    } else if (string.toLowerCase() == "ma") {
      return "Mars"
    } else if (string.toLowerCase() == "ra") {
      return "Rahu"
    } else if (string.toLowerCase() == "ju") {
      return "Jupiter"
    } else if (string.toLowerCase() == "sa") {
      return "Saturn"
    } else if (string.toLowerCase() == "me") {
      return "Mercury"
    } else if (string.toLowerCase() == "ke") {
      return "Ketu"
    } else if (string.toLowerCase() == "ve") {
      return "Venus"
    }
    return ""
  }

  getSmallName(string: String) {
    if (string.toLowerCase() == "sun") {
      return "Su"
    } else if (string.toLowerCase() == "moon") {
      return "Mo"
    } else if (string.toLowerCase() == "mars") {
      return "Ma"
    } else if (string.toLowerCase() == "rahu") {
      return "Ra"
    } else if (string.toLowerCase() == "jupiter") {
      return "Ju"
    } else if (string.toLowerCase() == "saturn") {
      return "Sa"
    } else if (string.toLowerCase() == "mercury") {
      return "Me"
    } else if (string.toLowerCase() == "ketu") {
      return "Ke"
    } else if (string.toLowerCase() == "venus") {
      return "Ve"
    }
    return ""
  }

  doughnutChartLabels: Label[] = ['Physical', 'Emotional', 'Intellectual'];
  doughnutChartData: MultiDataSet = [[0, 0, 7]];
  doughnutChartType: ChartType = 'doughnut';
  public chartColors: any[] = [
    {
      backgroundColor: ["#19af0f", "#33b5e5", "#fc6118"]
    }];

  getPercentage(data: any) {
    var obj = (data/3)
    return Number(obj)
  }

  getOverAll(){
    var val =(this.getPercentage(this.kundliResponse?.physical?.score)+this.getPercentage(this.kundliResponse?.emotional?.score)+this.getPercentage(this.kundliResponse?.intellectual?.score))
    return Math.round(val)
  }
}
