import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-call-order-list',
  templateUrl: './call-order-list.component.html',
  styleUrls: ['./call-order-list.component.css']
})
export class CallOrderListComponent implements OnInit {

  dataList:any = []
  filterType = 'all'
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;
  
  constructor(
    private userdata: UserdataService,
    private orderService: OrderServicesService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getCallOrders()
  }

  getCallOrders(){
    this.spinner.show()
    this.filterType = 'all'
    this.orderService.showCallOrders({customerId:this.userdata.getCustomerId()}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
        //console.log(res)
      },err=>{
        this.spinner.hide()
          this.toast.error(err.error.message,'Error')
      }
    )
  }

  noReview(){
    this.spinner.show()
    this.filterType = 'review'
    this.orderService.showCallOrders({customerId:this.userdata.getCustomerId(),is_order_reviewed:false,is_order_completed:true}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  noFeedback(){
    this.spinner.show()
    this.filterType = 'feedback'
    this.orderService.showCallOrders({customerId:this.userdata.getCustomerId(),is_feedback_given:false}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  promoCall(){
    this.spinner.show()
    this.filterType = 'promo'
    this.orderService.showCallOrders({customerId:this.userdata.getCustomerId(),is_promotional_call:true}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        //console.log(err)
      }
    )
  }

  public convertSecondstoTime(val:any) { 
    var given_seconds = val;   
    var dateObj = new Date(given_seconds * 1000); 
    var hours = dateObj.getUTCHours(); 
    var minutes = dateObj.getUTCMinutes(); 
    var seconds = dateObj.getSeconds(); 
    var timeString
    if(hours >0){
      timeString = hours.toString().padStart(2, '0')
                + ':' + minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0')+' Hrs.';
    }else{
      timeString = minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0')+' Min.';
    }
    return timeString
  } 

}
