<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
      <div class="container d-flex">
        <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Chat Details</h3>
        <ul class="
            d-none d-lg-flex
            fw-500
            text-white
            w-100
            float-right
            justify-content-end
          ">
          <li>
            <a [routerLink]="['/']" class="text-white">Home</a>
          </li>
          <li>
            <span class="fn-2 material-icons-round bottom-align">
              arrow_forward_ios
            </span>
          </li>
          <li>
            <a class="text-capitalize text-white" hre="#">Chat Details</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- BradCrumbs End -->
<div class="container">
    <div class="row">
        <div class="col-md-12 mb-4 chat-body bg-inactive">
          <div *ngFor="let chat of dataList?.chatinfo">
            <div class="container-chat lighter" *ngIf="chat?.type == 3">
              <p *ngIf="!chat?.isImage" [innerHTML]="chat?.message"></p>
              <a *ngIf="chat?.isImage" target="_blank" [href]="getOpenUrl(chat?.message)">File Attached. Click To
                View.</a>
              <span class="time-right">{{ chat?.date | date: "medium" }}</span>
              <span *ngIf="!chat?.is_to_show" class="time-right">This message will only appear to you.</span>
            </div>

            <div class="container-chat darker" *ngIf="chat?.type == 1 || chat?.type == 2">
              <ng-container *ngIf="chat?.is_to_show">
                <p *ngIf="!chat?.isImage" [innerHTML]="chat?.message" class="text-white"></p>
                <a *ngIf="chat?.isImage" target="_blank" [href]="getOpenUrl(chat?.message)" class="text-white">File
                  Attached. Click To View.</a>
                <span class="time-left">{{ chat?.date | date: "medium" }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
</div>
<app-footer></app-footer>