import { Injectable, Output, EventEmitter } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ChatBotHeaderService {
  @Output() fire = new EventEmitter();
  @Output() support = new EventEmitter<string>();
  constructor() {}
  openBot(id: any) {
    this.fire.emit(id);
  }
  openSupportBot(id: string) {
    //console.log('support', id);
    this.support.emit(id);
  }
}
