import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { defaultSingleBlogImage } from 'src/app/endpoints';
import { BlogsService } from 'src/app/service/blogs/blogs.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  BaseUrl: any;defaultSingleBlogImage=defaultSingleBlogImage;
  featuredBlog: any = [];
  recentBlogs: any = [];
  firstBlogs: any = [];
  categoryWiseBlog: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private blogsService:BlogsService,
    private route:ActivatedRoute,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
    }

  id:any;
  blogDetails: any; blogCategory: any;
  ngOnInit(): void {
    this.getAllBlogs()
    this.getBlogCategory()
  }

    // get all blogs
    blogs:any = [];
    getAllBlogs() {
      this.spinner.show()
      this.blogsService.getAllBlogs({isApproved: true,status :true , isFeaturedRequired:true}).subscribe(
        (res: any) => {
          this.blogs = res.data;
          this.featuredBlog = res.featuredBlog
          this.recentBlogs = res.recent5
          this.firstBlogs = res.first5
          this.categoryWiseBlog = res.categoryWise
          this.spinner.hide()
        }, err => {
          this.toast.error(err.error.message, 'Alert')
          this.spinner.hide()
        }
      )
    }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  searchText(key:any){
    if(key.length==0){
      this.getAllBlogs()
    }
  }

  searchBlog(key:any) {
    this.spinner.show()
    this.blogsService.getAllBlogs({search: key}).subscribe(
      (res: any) => {
        this.blogs = res.data;
        this.spinner.hide()
      }, err => {
        this.toast.error(err.error.message, 'Alert')
        this.spinner.hide()
      }
    )
  }

  /** Get Blog All Category */
  categoryId = ''
  getBlogCategory(){
    this.spinner.show()
    this.blogsService.getBlogCategory({status :true}).subscribe(
      (res: any) => {
        this.blogCategory = res.data
        this.spinner.hide()
      }, err => {
        this.toast.error(err.error.message, 'Alert')
        this.spinner.hide()
      }
    )
  }

  blogByCategory(id:any){
    this.spinner.show()
    this.categoryId = id 
    var obj:any = {isApproved: true,status :true , isFeaturedRequired:true}
    if(id != ''){
      obj.categoryId = id
    }
    this.blogsService.getAllBlogs(obj).subscribe(
      (res: any) => {
        this.categoryWiseBlog = res.categoryWise
        this.spinner.hide()
      }, err => {
        this.toast.error(err.error.message, 'Alert')
        this.spinner.hide()
      }
    )
  }

}

