import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from 'src/app/service/support/support.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-detail-support',
  templateUrl: './detail-support.component.html',
  styleUrls: ['./detail-support.component.css']
})
export class DetailSupportComponent implements OnInit {

  dataList:any = ''
  id:any = ''
  constructor(
    private userdata: UserdataService,
    private route: ActivatedRoute,
    private supportService: SupportService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getSingleSupport()
  }

  getSingleSupport(){
    this.supportService.supportDetail({customerId:this.userdata.getCustomerId(),_id:this.id}).subscribe(
      (res:any) =>{
        this.dataList = res.data
      },err=>{
        this.router.navigateByUrl('/support')
      }
    )
  }

}
