<!-- Top Astro Start  -->
<section class="d-none d-lg-block container">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="text-capitalize text-center">{{sectionTitle}}</h4>
      <h4 class="text-center"><span></span></h4>
    </div>
    <div class="row my-2 position-relative">
      <a class="nav-left position-absolute">
        <i class="fi fi-angle-left"></i>
      </a>
      <a class="nav-right position-absolute">
        <i class="fi fi-angle-right"></i>
      </a>
      <swiper class="py-5" [config]="astroConfig">
        <ng-template swiperSlide *ngFor="let key of topList">
          <div class="h-100 w-100 card trans astro-box p-2 shadow">
            <div class="h-100 w-100 card-body bg-astro py-2">
              <div class="row p-1 position-relative">
                <div class="col-3 p-0 pr-1">
                  <div class="d-flex flex-column align-items-center">
                    <img class="shadow size-60 border-white rounded-circle" [src]="getSanitizeUrl(key.profile_image)" />
                    <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astrologer_rating" [readonly]="true"></ngb-rating>
                    <span class="fn-1 inactive-text fw-500">({{ key.astrologer_rating }})</span>
                  </div>
                </div>
                <span class="material-icons position-absolute"
                  [ngClass]="checkOnlineOffLine(key) ? 'online' : 'offline'">
                  fiber_manual_record
                </span>
                <a (click)="notifyAstrologer(key._id)" class="position-absolute noti"><span
                    class="material-icons-outlined">
                    notifications_active
                  </span></a>
                <a [routerLink]="['/astrologer',key._id ]" class="
                    shadow-0
                    float-right
                    btn
                    px-2
                    py-1
                    m-0
                    rounded
                    bg-secondary
                    fw-500
                    text-white
                    position-absolute
                    bottom-0
                     v-size
                    end-0
                  " style="z-index: 100">
                  <i class="material-icons-outlined fn-2 bottom-align ">
                    person
                  </i>
                  View
                </a>
                <div class="col-9 p-0 pl-1">
                  <div class="d-flex flex-column">
                    <span class="text-capitalize fw-600">{{
                      key.displayname ? key.displayname : key.name
                      }}</span>
                    <p class="fn-1 inactive-text line-size-1">
                      <span class="text-capitalize" *ngFor="let skill of key.skill">{{ skill.skillId?.skillname
                        }}{{ key.skill.length > 1 ? ", " : "" }}</span>
                    </p>
                    <p class="fn-1 inactive-text">
                      <strong>Exp:</strong> {{ key.experience }}
                      {{ key.experience > 1 ? "Years" : "Year" }}
                    </p>
                    <p class="fn-1 inactive-text line-size-1">
                      <span class="text-capitalize" *ngFor="let lg of key.lang">{{ lg.langId?.name
                        }}{{ key.lang.length > 1 ? ", " : "" }}</span>
                    </p>
                    <ul class="fn-1 w-100">
                      <li class="d-inline" *ngIf="key?.isCallOfferApplicable; else price">
                        <s class="inactive-text">
                          {{ key?.currencySymbol }}
                          {{ key?.call_charges * mul }}/Min.</s>&nbsp;

                        {{ key?.currencySymbol }}
                        {{ key?.astroCallChargesWithOffer }}/Min.
                      </li>
                      <ng-template #price>
                        <li class="d-inline">
                          {{ key?.currencySymbol }}
                          {{ key?.call_charges * mul }}/Min.
                        </li>
                      </ng-template>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
</section>
<!-- Mobile -->
<section class="d-lg-none py-4 bg-inactive">
  <div class="row d-flex flex-column new-product">
    <h5 class="fw-600 text-center text-capitalize">{{sectionTitle}}</h5>
    <h4 class=""><span></span></h4>
  </div>
  <div class="row mt-3 px-4 d-flex justify-content-center">
    <swiper [config]="astromConfig" class="astroSwiper">
      <ng-template class="pt-3" swiperSlide *ngFor="let key of topList">
        <div class="card position-relative mt-5 text-center bg-active">
          <img style="top: -3.3rem; left: 22%" [src]="getSanitizeUrl(key.profile_image)"
            class="shadow size-100 position-absolute rounded-circle" />
          <div class="card-body bg-astro p-1 pt-5">
            <div class="d-flex flex-column">
              <p class="mx-n2">
                <ngb-rating class="ml-n1" [max]="5" [(rate)]="key.astrologer_rating" [readonly]="true"></ngb-rating>
              </p>
              <span class="text-capitalize fw-600 fn-2">{{
                key.displayname ? key.displayname : key.name
                }}</span>
              <p class="line-size-1 skill inactive-text fn-1">
                <span class="text-capitalize" *ngFor="let skill of key.skill">{{ skill.skillId?.skillname
                  }}{{ key.skill.length > 1 ? ", " : "" }}</span>
              </p>
              <p class="inactive-text fn-1">
                <strong>Exp:</strong> {{ key.experience }}
                {{ key.experience > 1 ? "Years" : "Year" }}
              </p>
            </div>
          </div>
          <div class="card-footer p-2 d-flex border-0 bg-astro shadow">
            <a [routerLink]="['/astrologer', key._id]"
              class="btn bg-secondary p-2 flex-grow-1 rounded fw-500 text-white"><i class="far fa-eye"></i>&nbsp;View
              Profile</a>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="mt-3 text-center">
    <a routerLink="/call-astro-list" class="fw-500 btn text-white bg-green text-capitalize rounded-pill">
      View More
    </a>
  </div>
</section>
<!-- Top Astro End  -->