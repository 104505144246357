<app-header></app-header>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Select Report</h3>
      <ul
        class="fw-500 text-white w-100 float-right d-flex justify-content-end"
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <i class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </i>
        </li>

        <li>Select Report</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- Talk to astrologer page start-->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <h4 class="fw-500">Select A Report</h4>
    </div>
    <div class="row mt-5">
      <div class="col-sm-12" *ngFor="let data of reportList">
        <div class="card">
          <img
            [src]="getSanitizeUrl(data.report_image)"
            alt="team-img"
            class="img img-thumbnail"
          />
          <div class="card-body">
            <h5 class="card-title">{{ data.reportname }}</h5>
            <p class="card-text text-justify">{{ data.reportdescription }}</p>
            <button
              class="btn bg-secondary text-white fw-500 shadow-0 btn-block"
              (click)="selectReport(data)"
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Talk to astrologer page end-->
<app-footer></app-footer>
