<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Blog Detail</h3>
            <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>Blog Detail</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-3">
    <div class="container">
        <!-- trending section start -->
        <div class="col">
            <div class=" d-flex mob-dis-trend">
                <div class="bg-warning text-center btn-warning btn-sm small border-0 fw-500 col-md-2 text-black">
                    TRENDING
                    NOW
                </div>
                <div class="col text-truncate">
                    <a href="javascript:void(0)" class="text-dark align-middle">Read Latest Astrology Updates Only At
                        AstroRiver!</a>
                </div>
            </div>
        </div>
        <!-- Trending Section End -->
        <div class="d-flex m-blog-res" *ngIf="blogs.length > 0">
            <div class="col-md-6">
                <div class="row m-blog-img">
                    <div class="bg-white mob-card-b fix-width">
                        <div class="b-bar1 d-flex mt-3 cursor-pointer" [routerLink]="['/blog', blogs[0]?.slug]">
                            <img [src]="getSanitizeUrl(blogs[0]?.blogImage)" class="w-100 b-img1 rounded" />
                            <div class="content-blog1">
                                <div class="d-flex align-middle">
                                    <button
                                        class="btn btn-success btn-sm round-btn py-1">{{blogs[0]?.categoryId?.categoryname}}</button>
                                </div>
                                <h5 class="text-truncate text-white mt-2">{{blogs[0]?.blogTitle}}</h5>
                                <p class="text-desc text-break text-white side-txt small mt-1"
                                    [innerHTML]="blogs[0]?.blogsmallDescription"></p>
                                <p class="text-white fn-0">{{blogs[0]?.userName}} /<i
                                        class="fa fa-calendar text-warning mx-1"></i>{{blogs[0]?.created_at |
                                    date:'mediumDate'}}</p>
                                <!-- <div class="small">
                        <p class="text-break text-white side-txt" [innerHTML]="randomBlog?.blogDescription"></p>
                      </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mob-mar">
                <div class="mb-3" *ngFor="let blog of blogs.slice(1)">
                    <div class="b-bar1 d-flex mt-3 cursor-pointer" [routerLink]="['/blog', blog?.slug]">
                        <img [src]="getSanitizeUrl(blog?.blogImage)" class="w-100 b-img4 rounded" />
                        <div class="content-blog1">
                            <div class="d-flex align-middle">
                                <button
                                    class="btn btn-success btn-sm round-btn py-1">{{blog?.categoryId?.categoryname}}</button>
                            </div>
                            <h5 class="text-truncate text-white mt-2">{{blog?.blogTitle}}</h5>
                            <p class="text-desc text-break text-white side-txt small mt-1"
                                [innerHTML]="blog?.blogsmallDescription"></p>
                            <p class="text-white fn-0">{{blog?.userName}} /<i
                                    class="fa fa-calendar text-warning mx-1"></i>{{blog?.created_at |
                                date:'mediumDate'}}</p>
                            <!-- <div class="small">
                            <p class="text-break text-white side-txt" [innerHTML]="randomBlog?.blogDescription"></p>
                          </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex m-blog-res" *ngIf="(featuredBlog | json) != '{}'">
            <div class="col-md-9">
                <div class="border-bottom mt-4 p-0">
                    <h5 class="mb-2"><b><span class="text-danger">FEATURED</span> BLOG</b></h5>
                </div>
                <div class="d-flex mt-3 m-blog-res">
                    <div class=" col-md-5 p-0">
                        <img [src]="getSanitizeUrl(featuredBlog?.blogImage)" class="w-100 ps-0" />
                    </div>
                    <div class="align-middle">
                        <div class=" col ps-3">
                            <h6 class=" mb-0 text-truncate text-danger fw-500 mt-1">
                                {{featuredBlog?.categoryId?.categoryname}} </h6>
                            <h5 class="my-2 text-break fw-600 line-size-2">{{featuredBlog?.blogTitle}}</h5>
                            <p class="text-muted small">{{featuredBlog?.userName}} /<i
                                    class="fa fa-calendar text-warning mx-1"></i>{{featuredBlog?.created_at |
                                date:'mediumDate'}}</p>
                            <p class="text-muted mt-1 text-justify line-size-5"
                                [innerHTML]="featuredBlog?.blogsmallDescription"></p>

                            <div class="mt-3">
                                <a [routerLink]="['/blog',featuredBlog?.slug]" class=" btn-sm btn-success py-2"> View
                                    Details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-md-3">
                <div class="border-bottom mt-4 p-0">
                    <h5 class="mb-2"><b><span class="text-danger">FOLLOW</span> US</b></h5>
                </div>
                <div class="d-flex justify-content-between ">
                    <div class="mt-3 border rounded p-1">
                        <a target="_blank" href="https://www.facebook.com/astroriverdotcom/"><i
                                class="fa-brands fa-facebook-square fb-icon-1 align-middle  p-3 fa-2x"></i></a>
                    </div>
                    <div class="mt-3 border rounded p-1">
                        <a target="_blank" href="https://api.whatsapp.com/send?phone=917009127641 "><i
                                class="fa-brands fa-whatsapp  text-success align-middle p-3 fa-2x"></i></a>
                    </div>
                    <div class="mt-3 border rounded p-1">
                        <a target="_blank" href="https://www.instagram.com/astroriverofficial/"><i
                                class="fa-brands fa-instagram align-middle ins-icon p-3 fa-2x"></i></a>
                    </div>


                </div>
                <div class="d-flex justify-content-between">
                    <div class="mt-3 border rounded p-1">
                        <a target="_blank" href="https://astroriver.quora.com/"><i
                                class="fa-brands fa-quora  align-middle q-icon p-3 fa-2x"></i></a>
                    </div>
                    <div class="mt-3 border rounded p-1">
                        <a target="_blank" href="https://www.pinterest.com/astroriver/"><i
                                class="fa-brands fa-pinterest  text-pinterest align-middle p-3 fa-2x"></i></a>
                    </div>
                    <div class="mt-3 border rounded p-1">
                        <a target="_blank" href="https://twitter.com/astroriver/"><i
                                class="fa-brands fa-twitter  text-info align-middle p-3 fa-2x"></i></a>
                    </div>
                </div>
            </div>

        </div>
        <div class="container mt-4">
            <div class="">
                <img src="../../../assets/images/b-banner.png" class="w-100 b-img" [routerLink]="['/report-astro-list']"
                    style="cursor: pointer;" />
            </div>


        </div>


        <!-- Latest predictions -->
        <div class="container">
            <div class="row gx-1">
                <div class="col-md-8">
                    <div class="border-bottom mt-4">
                        <h5 class="mb-2"><b><span class="text-danger">LATEST</span> PREDICTIONS</b></h5>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="border-bottom mt-4 ">
                        <h5 class="mb-2"><b><span class="text-danger">RECENT</span> BLOGS</b></h5>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4" *ngIf="firstBlogs.length > 0">
                        <div class="row">
                            <div class="mt-3 p-0 cursor-pointer"
                                [routerLink]="['/blog', firstBlogs[firstBlogs.length-1]?.slug]">
                                <div class="p-0">
                                    <img [src]="getSanitizeUrl(firstBlogs[firstBlogs.length-1]?.blogImage)"
                                        class="w-100 ps-0 rounded" />

                                </div>
                                <div class="align-middle">
                                    <div class="col p-0 mt-2">
                                        <!-- <h6 class=" mb-0 text-truncate text-danger fw-500 mt-1">Astrology </h6> -->
                                        <h5 class="my-2 text-truncate fw-600">
                                            {{firstBlogs[firstBlogs.length-1]?.blogTitle}} </h5>
                                        <p class="text-muted mt-1 text-justify"
                                            [innerHTML]="firstBlogs[firstBlogs.length-1]?.blogsmallDescription"></p>
                                        <div class="small mt-2">
                                            <span
                                                class="text-muted">{{firstBlogs[firstBlogs.length-1]?.userName}}</span>/
                                            <span class="material-icons align-middle fa-lg text-muted">
                                                calendar_month
                                            </span>
                                            {{firstBlogs[firstBlogs.length-1]?.created_at | date:'dd-MM-yyyy hh:mm:ss
                                            a'}}
                                        </div>
                                        <button
                                            class="btn btn-success btn-sm type-btn1 py-1">{{firstBlogs[firstBlogs.length-1]?.categoryId?.categoryname}}</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-4" *ngIf="firstBlogs.length > 0">
                        <div class="d-flex mt-3 pointer border rounded " *ngFor="let first of firstBlogs.slice(0,4);"
                            [routerLink]="['/blog',first?.slug]">
                            <div class="">
                                <img [src]="getSanitizeUrl(first?.blogImage)" class="ps-0 b-img2 m-height rounded" />
                            </div>
                            <div class="align-middl">
                                <div class="pl-2 p-1">
                                    <h6 class=" text-truncate text-danger fw-500 small">
                                        {{first?.categoryId?.categoryname}} </h6>
                                    <span class="my-1 line-size-1 w-600 ">{{first?.blogTitle}}</span>
                                    <p class="text-muted mt-1 text-justify small lh-2 line-size-2"
                                        [innerHTML]="first?.blogsmallDescription"></p>
                                    <div class="small mt-1">
                                        <span class="text-muted small">{{first?.userName}} </span>/ <span
                                            class="material-icons align-middle fa-lg text-muted">
                                            calendar_month
                                        </span>
                                        {{first?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="recentBlogs.length > 0">
                        <div class="d-flex mt-3 pointer" *ngFor="let recent of recentBlogs.slice(0,4)"
                            [routerLink]="['/blog',recent?.slug]">
                            <div class="">
                                <img [src]="getSanitizeUrl(recent?.blogImage)" class="ps-0 b-img2 m-height rounded" />
                            </div>
                            <div class="">
                                <div class="pl-2 p-1">
                                    <h6 class=" text-truncate text-danger fw-500 small">
                                        {{recent?.categoryId?.categoryname}} </h6>
                                    <span class="my-1 fw-600 line-size-1">{{recent?.blogTitle}} </span>
                                    <p class="text-muted mt-1 text-justify small lh-2 line-size-2"
                                        [innerHTML]="recent?.blogsmallDescription"></p>
                                    <div class="small mt-1">
                                        <span class="text-muted small">{{recent?.userName}} </span>/ <span
                                            class="material-icons align-middle fa-lg text-muted">
                                            calendar_month
                                        </span>
                                        {{recent?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-4 m-mob1">
                <a [routerLink]="['/blog']" class="btn-success btn-sm px-5 py-3 "> Read More</a>
            </div>
        </div>
        <!-- Latest predictions end -->


    </div>


</section>
<section class=" mt-5 bg-light py-5">
    <div class="container mt-3 m-zero">
        <div class="row m-blog-cat">
            <div class=" p-0">
                <h4 class=""><b><span class="text-danger">BLOGS </span> CATEGORIES</b></h4>
            </div>
        </div>
        <div class="row my-2 tab-blogs m-blog-cat bg-red rounded mr-2 mb-3">
            <a (click)="blogByCategory('')" class="mr-2 text-white btn-sm pt-2"
                [ngClass]="{'bg-warning rounded-left text-dark py-2':categoryId == ''}">All</a>
            <a (click)="blogByCategory(category._id)" class="mr-2 text-white btn-sm py-2"
                *ngFor="let category of blogCategory;"
                [ngClass]="{'bg-warning rounded-0 text-dark ':categoryId == category?._id}">{{category?.categoryname}}</a>
        </div>
        <div class="row gx-1" *ngIf="categoryWiseBlog.length > 0">
            <div class="col-md-5">
                <div class="row m-blog-img">
                    <div class="bg-white mob-card-b fix-width">
                        <div class="p-0">
                            <img [src]="getSanitizeUrl(categoryWiseBlog[0]?.blogImage)"
                                class="w-100 ps-0 img-rounded-top btn-cursor"
                                [routerLink]="['/blog', categoryWiseBlog[0]?.slug]" />
                        </div>
                        <div class="card-body b-white glob-blog">
                            <div class=" p-0 ">
                                <a [routerLink]="['/blog-categories', categoryWiseBlog[0]?.categoryId?.categoryname, categoryWiseBlog[0]?.categoryId?._id]"
                                    class=" btn shadow-0 btn-b-type rounded text-danger btn-sm px-3  py-1">{{categoryWiseBlog[0]?.categoryId?.categoryname}}</a>
                                <div class="btn-cursor" [routerLink]="['/blog', categoryWiseBlog[0]?.slug]">
                                    <h5 class="mb1- mt-2 text-truncate fw-600">{{categoryWiseBlog[0]?.blogTitle}} </h5>
                                    <p class="text-muted mt-1 text-justify line-size-2"
                                        [innerHTML]="categoryWiseBlog[0]?.blogsmallDescription"></p>
                                    <div class="small">
                                        <span class="text-muted">{{categoryWiseBlog[0]?.userName}}</span>/ <span
                                            class="material-icons align-middle fa-lg text-muted">
                                            calendar_month
                                        </span>
                                        {{categoryWiseBlog[0]?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-7  mob-mar">
                <div class="card mb-3" *ngFor="let categoryBlog of categoryWiseBlog.slice(1,4)">
                    <div class="d-flex">
                        <div class="col-md-8 p-2">
                            <a [routerLink]="['/blog-categories', categoryBlog?.categoryId?.categoryname, categoryBlog?.categoryId?._id]"
                                class="mb-0 text-truncate text-danger small btn-b-type px-2 py-1 rounded fw-500">{{categoryBlog?.categoryId?.categoryname}}
                            </a>
                            <div class="btn-cursor" [routerLink]="['/blog', categoryBlog?.slug]">
                                <h5 class="my-1 text-truncate h6 fw-600">{{categoryBlog?.blogTitle}}</h5>
                                <p class="text-muted text-justify small line-size-2"
                                    [innerHTML]="categoryBlog?.blogsmallDescription">
                                </p>
                                <div class="small">
                                    {{categoryBlog?.userName}} /
                                    <span class="material-icons align-middle fa-lg text-danger">
                                        calendar_month
                                    </span>
                                    {{categoryBlog?.created_at | date:'dd-MM-yyyy hh:mm:ss a'}}
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4 p-0">
                            <img [src]="getSanitizeUrl(categoryBlog?.blogImage)" class="img img-fluid h-100 btn-cursor"
                                [routerLink]="['/blog', categoryBlog?.slug]" />
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>



</section>

<app-footer></app-footer>