import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-kundli',
  templateUrl: './kundli.component.html',
  styleUrls: ['./kundli.component.css']
})
export class KundliComponent implements OnInit {

  dataList:any = []
  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private openService: OpenApiService
  ) { }

  ngOnInit(): void {
    this.getKundli()
  }

  getKundli(){
    this.spinner.show()
    this.openService.getKundli({}).subscribe(
      (res:any) =>{
        this.dataList = res.data
        this.spinner.hide()
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  show(val:any){
    return JSON.stringify(val)
  }

}
