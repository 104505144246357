import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.css']
})
export class DynamicPageComponent implements OnInit {
  id: any;pageContent:any
  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService, private _openService:OpenApiService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('slug');
    this.getPageData()
  }

  getPageData(){
    this.spinner.show();
    this._openService.getPage(this.id).subscribe({
      next: (v: any) => {
        this.pageContent = v.result.description;
        if (!v.success) {
          this.toastr.error(v.message);
        }
      },
      error: (error) => {
        this.router.navigateByUrl('/home')
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

}
