<app-header></app-header>
<!-- hs About Title Start -->
<div class="hs_indx_title_main_wrapper">
  <div class="hs_title_img_overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
        <div class="hs_indx_title_left_wrapper">
          <h2>Astrologer</h2>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
        <div class="hs_indx_title_right_wrapper">
          <ul>
            <li><a [routerLink]="['/']">Home</a> &nbsp;&nbsp;&nbsp;> </li>
            <li>Astrologer</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- hs About Title End -->
<!-- hs sidebar Start -->
<div class="hs_blog_categories_main_wrapper">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="hs_blog_left_sidebar_main_wrapper">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div class="hs_kd_first_sec_wrapper" id="showresult">
                <h2> Astrologer List</h2>
                <h4><span></span></h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
          </div>
          <div class="row" id="productresult">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let astro of dataList">
              <div class="hs_shop_prodt_main_box">
                <div class="hs_shop_prodt_img_wrapper">
                  <img [src]="getSanitizeUrl(astro.astroId.profile_image)" alt="shop_product" class="img img-responsive img-category">
                  <a (click)="booking(astro.astroId._id,astro.astroId.name)">Book Now</a>
                </div>
                <div class="hs_shop_prodt_img_cont_wrapper">
                  <h2><a (click)="booking(astro.astroId._id,astro.astroId.name)">{{astro.astroId.displayname? astro.astroId.displayname : astro.astroId.name}}</a></h2>
                  <h3>{{astro.price | currency :'INR':'symbol-narrow'}}</h3>
                  Rating: {{astro.astroId.astrologer_rating == 0 ? 'No Rating Yet' : astro.astroId.astrologer_rating }} <i class="fa fa-star" *ngIf="astro.astroId.astrologer_rating != 0"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- hs sidebar End -->
<app-footer></app-footer>
