import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogsService } from 'src/app/service/blogs/blogs.service';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { ProductService } from 'src/app/service/product/product.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { UserdetailsService } from 'src/app/service/userdetails/userdetails.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { Autoplay, SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { PassDataService } from 'src/app/service/data/pass-data.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderServicesService } from 'src/app/service/order/order-services.service';

SwiperCore.use([Navigation, Autoplay]);
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {
  BaseUrl: any;
  isLogedIn = false;
  isCallGoing: any;
  isChatGoing: any;
  isWaitingGoing: any;
  latestProduct = new Array();
  code: any;
  zodiacData = new Array();
  originalZodiaList = new Array();
  signIndex = 0;
  signName = '';
  singleZodiacSign: any;
  testimonialList = new Array();
  testimonialData = new Array();
  today: any;

  //Constructor
  constructor(
    private loginService: LoginService,
    private toast: ToastrService,
    private router: Router,
    private userdata: UserdataService,
    private _product: ProductService,
    private openService: OpenApiService,
    private trusturl: DomSanitizer,
    private spinner: NgxSpinnerService,
    private userDeatilService: UserdetailsService,
    private blogsService: BlogsService,
    private dataService: PassDataService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private orderService: OrderServicesService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  mul = 2;
  panchangData: any;
  panchang: any;
  region: any;
  todaysDate: any;
  blogs: any = new Array();
  featuredBlog: any;
  halfWidth = 0;
  blogImage = 0;
  screenWidth = 0;
  ngOnInit(): void {
    this.today = moment().format('YYYY-MM-DD');
    this.screenWidth = window.innerWidth;
    this.halfWidth = this.screenWidth / 2;
    this.blogImage = this.halfWidth / 2;
    this.halfWidth = this.halfWidth - 30;
    this.code = this.userdata.isIndia();
    this.region = this.userdata.getRegion();
    var curDate = Date();
    this.todaysDate = moment(curDate).format('LL');
    var date = moment(curDate).format('Z'); //
    var GMT = date.replace(':', '.');
    GMT = GMT.replace('+', '');
    var loc = this.userdata.getLatLong() || '';
    var locarr = loc.split(',');

    this.panchangData = {
      tz: parseFloat(GMT),
      lat: locarr[0],
      lon: locarr[1],
      apiaddress: 'panchang/panchang/',
      islive: false,
      date: moment(curDate).format('DD/MM/YYYY'),
    };
    if (!this.userdata.isIndia()) {
      this.mul = 1;
    }
    if (this.userdata.getToken().length > 0) {
      this.isLogedIn = true;
      this.getMyProfile();
    }
    this.userDeatilService.loginUserData.subscribe(
      (res) => (this.isLogedIn = res ? true : false)
    );
    this.spinner.show();
    this.getCallAstrologerList();
    this.getCustomerBanner();
    this.getAllBlogs();
    this.getLatest();
    this.getTodayPanchang();
    this.getZodiac();
    this.getTestimonial();
  }
  getTestimonial() {
    this.openService.getTestimonials().subscribe(
      (res: any) => {
        this.testimonialData = res.data;
        var limit = res.data.length / 3;
        var i = 0;
        while (i < limit) {
          var j = 0;
          var start = i * 3;
          var temp = [];
          while (j < 3) {
            temp[j] = res.data[start + j];
            j++;
          }
          this.testimonialList[i] = temp;
          i++;
        }
      },
      (err: any) => console.log('test', err)
    );
  }

  getTodayPanchang() {
    this.openService.getPanchang(this.panchangData).subscribe(
      (res: any) => {
        this.panchang = res.data;
        this.spinner.hide();
      },
      (err: any) => {
        //console.log(err);
        this.spinner.hide();
      }
    );
  }
  featuredProduct: any;
  getLatest() {
    this._product
      .getProductByCategory({
        status: true,
        startpoint: 0,
        isFeaturedRequired: true,
        is_astrologer_associate: false,
      })
      .subscribe(
        (res: any) => {
          this.featuredProduct = res.featuredProduct;
          const list = new Array();
          // list.push(res.featuredProduct);
          this.latestProduct = list.concat(res.data);
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }
  isPromo = false;
  registerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()_+-]).{8,}$'
      ),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{10,}'),
    ]),
    is_terms_accepted: new FormControl(true, [Validators.requiredTrue]),
    is_social_login: new FormControl(false),
    social_type: new FormControl(''), //1.Google 2.FB 3.Apple
    social_id: new FormControl(''),
    regCountryName: new FormControl(''),
  });

  registerCustomer() {
    this.loginService.register(this.registerForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
        this.router.navigateByUrl('/login');
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  get f() {
    return this.registerForm.controls;
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  /**
   * Promo
   */
  promoRes = {
    isPromoAllow: false,
    message: '',
  };
  isPromoAllow = false;
  getMyProfile() {
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          this.promoRes.isPromoAllow = res.promoallow;
          this.promoRes.message = res.msg;
          if (res.data.is_allow_promotional) {
            this.isPromo = true;
          }
          this.userdata.setCurrentWallet(res.data.current_wallet);
          this.userdata.setEmailVerify(res.data.is_email_verified);
          this.userdata.setPhoneVerify(res.data.is_otp_verified);
          this.userdata.setIsCallGoing(res.data.is_current_call);
          this.userdata.setIsChatGoing(res.data.is_current_chat);
          this.userdata.setIsPromo(res.data.is_allow_promotional);
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  promoOrder() {
    // if (!this.promoRes.isPromoAllow) {
    //   this.toast.error(this.promoRes.message);
    //   return;
    // }
    if (this.userdata.getCountryCode() != 'in') {
      Swal.fire({
        title: 'Promotional Offer',
        text: 'Do you want to avail the benefit of 5 minutes free call or chat?',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: '5 minutes complementary chat',
      }).then((result) => {
        if (result.value) {
          this.placePromo(false);
        }
      });
    } else {
      Swal.fire({
        title: 'Promotional Offer',
        text: 'Do you want to avail the benefit of 5 minutes free call or chat?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '5 minutes complementary call',
        cancelButtonText: '5 minutes complementary chat',
      }).then((result) => {
        if (result.value) {
          this.placePromo(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.placePromo(false);
        }
      });
    }
  }

  placePromo(type: boolean) {
    this.spinner.show();
    if (this.userdata.getToken().length == 0) {
      this.spinner.hide();
      this.toast.error('Please Login to use Service', 'Info');
      return;
    }
    if (this.userdata.getEmailVerify() == 'false') {
      this.router.navigateByUrl('/verify-email-otp');
      return;
    }

    /**
     * Check if user is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe((res: any) => {
        this.userdata.setProfileData(res.data)
        this.spinner.hide();
        if (res.data.is_current_call || res.data.is_current_chat) {
          Swal.fire(
            "Order can't be placed",
            'Complete your ongoing order to place another one',
            'info'
          );
          return;
        } else if (!this.promoRes.isPromoAllow) {
          this.toast.error(this.promoRes.message);
          return;
        } else {
          /**  Check if user is already in promo waiting list */
          this.orderService.showWaitingList({ customerId: this.userdata.getCustomerId(), is_user_notified: false }).subscribe(
            (res: any) => {
              var i = res.data.findIndex((x: any) => (x.isFreePromomotionalCall || x.isFreePromomotionalChat))
              if (i > -1) {
                this.toast.error('You are already in promo waiting list', 'Error')
                this.router.navigate(['/wait-list'])
                return
              } else {
                if (type) this.router.navigate(['/promo-call-astro-list']);
                else this.router.navigate(['/promo-chat-astro-list']);
              }
            })
        }
      });
  }
  /**
   * Call astro List
   */
  dataList: any = [];
  getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  getCallAstrologerList() {
    var data: any = { startpoint: 0 };
    if (this.userdata.getToken().length > 0) {
      data = { customerId: this.userdata.getCustomerId(), startpoint: 0 };
    }
    this.openService.getAstrologersDashboard(data).subscribe(
      (res: any) => {
        this.dataList = res.data;
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  convertSecondstoTime(val: any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString =
      hours.toString().padStart(2, '0') +
      'hrs. ' +
      minutes.toString().padStart(2, '0') +
      'min.' +
      seconds.toString().padStart(2, '0') +
      'sec.';
    return timeString;
  }

  checkOfferValue(val: any) {
    var charges: any = 0;
    if (val.isnewforastro) {
      if (val.callofferis.user_type == 1 || val.callofferis.user_type == 3) {
        var charge =
          Number(val.call_charges) * 2 -
          Number(val.call_charges) * 2 * (val.callofferis.offer_discount / 100);
        charges = charge + '/Min' + ' (' + val.callofferis.offer_name + ')';
        // charges = Number(val.call_charges) * 2 - ((Number(val.call_charges) * 2) * (Number(val.callofferis.offer_discount) / 100))
      } else {
        charges = Number(val.call_charges) * 2 + '/Min';
      }
    } else {
      if (val.callofferis.user_type == 1 || val.callofferis.user_type == 2) {
        var charge =
          Number(val.call_charges) * 2 -
          Number(val.call_charges) * 2 * (val.callofferis.offer_discount / 100);
        charges = charge + '/Min' + ' (' + val.callofferis.offer_name + ')';
      } else {
        charges = Number(val.call_charges) * 2 + '/Min';
      }
    }
    return charges;
  }

  askCall(key: any, val: any) {
    localStorage.setItem(val, key._id);
    this.spinner.show();
    var isOffer = false;
    var offerId = '';
    if (this.userdata.getToken().length == 0) {
      this.toast.error('Please Login to use Service', 'Info');
      this.spinner.hide();
      return;
    } else {
      if (this.userdata.getEmailVerify() == 'false') {
        this.spinner.hide();
        this.router.navigateByUrl('/verify-email-otp');
        return;
      } else {
        this.loginService
          .myProfile({ _id: this.userdata.getCustomerId() })
          .subscribe(
            (res: any) => {
              this.userdata.setProfileData(res.data)
              if (res.data.is_waiting) {
                this.spinner.hide();
                Swal.fire(
                  'Unable to place call-wait order.',
                  "You have already joined an Astrologer's waiting list.",
                  'error'
                );
                return;
              }
              this.isCallGoing = res.data.is_current_call;
              this.isChatGoing = res.data.is_current_chat;
              this.isWaitingGoing = res.data.is_waiting;
              /**
               * Check if user is busy
               */
              if (res.data.is_current_call || res.data.is_current_chat) {
                this.spinner.hide();
                Swal.fire(
                  "Call can't be connected!",
                  'Complete your ongoing order to place another one',
                  'info'
                );
                return;
              }
              /** Check Wallet amount with offer and waiting amount */
              var wallet: Number = res.data.current_wallet;
              var charges = 0;
              if (
                key.astroofferId == null ||
                !key.astroofferId?.is_call_offer_activate ||
                key.callofferis == null
              ) {
                charges = key.call_charges * 2;
              } else {
                if (key.isnewforastro) {
                  if (
                    key.callofferis.user_type == 1 ||
                    key.callofferis.user_type == 3
                  ) {
                    charges =
                      key.call_charges * 2 -
                      key.call_charges *
                      2 *
                      (key.callofferis.offer_discount / 100);
                    isOffer = true;
                    offerId = key.callofferis._id;
                  } else {
                    charges = key.call_charges * 2;
                  }
                } else {
                  if (
                    key.callofferis.user_type == 1 ||
                    key.callofferis.user_type == 2
                  ) {
                    charges =
                      key.call_charges * 2 -
                      key.call_charges *
                      2 *
                      (key.callofferis.offer_discount / 100);
                    isOffer = true;
                    offerId = key.callofferis._id;
                  } else {
                    charges = key.call_charges * 2;
                  }
                }
              }
              var waitingAmount = res.data.waiting_amount;
              var currentWallet = res.data.current_wallet;
              var customerWalletAmount = currentWallet - waitingAmount;
              //var astroCharges = key.chat_charges*2//
              var astroCharges = charges * 5; //001
              if (currentWallet < astroCharges) {
                this.spinner.hide();
                this.userdata.setCurrentRoute(this.router.url);
                this.toast.error('Please Recharge to place order', 'Alert');
                this.router.navigateByUrl('/recharge-wallet');
                return;
              }
              if (astroCharges > customerWalletAmount) {
                this.spinner.hide();
                this.userdata.setCurrentRoute(this.router.url);
                this.toast.error('Please Recharge to place order', 'Alert');
                this.router.navigateByUrl('/recharge-wallet');
                return;
              }
              var orderObject: any = '';
              if (isOffer) {
                orderObject = {
                  customerId: this.userdata.getCustomerId(),
                  astroId: key._id,
                  orderType: 'call',
                  orderCheck: val,
                  is_offer: isOffer,
                  offerId: offerId,
                };
              } else {
                orderObject = {
                  customerId: this.userdata.getCustomerId(),
                  astroId: key._id,
                  orderType: 'call',
                  orderCheck: val,
                  is_offer: isOffer,
                  offerId: null,
                };
              }
              sessionStorage.setItem(
                'orderObject',
                JSON.stringify(orderObject)
              );
              this.spinner.hide();
              this.router.navigateByUrl('/order-list-person');
            },
            (err: any) => {
              this.spinner.hide();
              this.toast.error(
                'Internal Server Error. Please Try After Some Time',
                'Error'
              );
            }
          );
      }
    }
  }

  banners: any = new Array();
  getCustomerBanner() {
    var data: any = {
      banner_status: true,
      is_astro_banner: false,
      is_desktop_banner: true,
    };
    this.openService.getCustomerBanner(data).subscribe(
      (res: any) => {
        this.banners = res.data;
      },
      (err: any) => {
        this.toast.error('Something went wrong', 'Alert');
      }
    );
  }
  previousSign() {
    this.signIndex--;
    this.getSingleSign();
  }
  nextSign() {
    this.signIndex++;
    this.getSingleSign();
  }
  getSingleSign() {
    this.signName = this.originalZodiaList[this.signIndex].name;
    this.getSignInfo();
  }
  getZodiac() {
    this.openService.dailyHoroscopeZodiac({ isRandomRequired: true }).subscribe(
      (res: any) => {
        this.singleZodiacSign = res.randomZodiacData;
        this.originalZodiaList = res.data;
        this.signName = this.originalZodiaList[0].name;
        this.getSignInfo();
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }
  zodiacImage = '';
  getSignInfo() {
    this.openService.dailyHoroscope({ sign: this.signName }).subscribe(
      (res: any) => {
        this.singleZodiacSign = res.data;
        this.zodiacImage = res.image;
      },
      (err: any) => { }
    );
  }
  getAllBlogs() {
    this.blogsService.getWithFeaturedBlog().subscribe(
      (res: any) => {
        this.featuredBlog = res.featuredBlog;
        this.blogs = res.data;
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }
  getCurrentPrice(product: any) {
    return this.code ? product.price : product.usd_price || 0;
  }
  getOrginialPrice(product: any) {
    return this.code
      ? product.original_amount
      : product.usd_original_amount || 0;
  }

  //Swiper Configurations

  zodiacConfig: SwiperOptions = {
    slidesPerView: 4,
    direction: 'horizontal',
    spaceBetween: 5,
    effect: 'slide',
    loop: true,
    autoplay: {
      delay: 5000,
    },
    scrollbar: false,
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
  };

  blogCellNumber = window.innerWidth < 550 ? 1 : 2;
  latestConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 'auto',
    direction: 'horizontal',
    spaceBetween: 5,
    effect: 'slide',
  };
  callConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 'auto',
    direction: 'horizontal',
    spaceBetween: 40,
    effect: 'slide',
  };
  blogConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: this.blogCellNumber,
    direction: 'horizontal',
    spaceBetween: 40,
    autoplay: {
      delay: 5000,
    },
    effect: 'slide',
  };
  homeBannerConfig: SwiperOptions = {
    scrollbar: false,
    slidesPerView: 1,
    direction: 'horizontal',
    spaceBetween: 0,
    autoplay: {
      delay: 3000,
    },
    effect: 'slide',
  };

  /**kundliForm */
  openKundliForm(content: any) {
    this.modalService.open(content);
  }
  kundliForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    dob: new FormControl('', Validators.required),
    tob: new FormControl('', Validators.required),
    place: new FormControl('', Validators.required),
    gender: new FormControl('male', Validators.required)
  })

  getKundli() {
    if (this.selectedLocation == '' || this.selectedLocation == null || this.selectedLocation == undefined) {
      this.toast.error('Please select valid location')
      return
    }
    var obj: any = [];
    obj.push({ form: this.kundliForm.value, location: this.selectedLocation })
    this.modalService.dismissAll()
    localStorage.setItem('kundli', JSON.stringify(obj))
    this.router.navigateByUrl('/kundli-view')
  }

  locationList: any;
  selectedLocation: any
  getLocation(event: any) {
    this.selectedLocation = ''
    if (event.target.value.length < 3) {
      return
    }
    this.openService.getGeoLocation({ apiaddress: 'utilities/geo-search', islive: true, city: event.target.value }).subscribe(
      (res: any) => {
        this.locationList = res.data
      }, err => {

      }
    )
  }

  selectLocation(event: any) {
    this.selectedLocation = event
    //this.kundliForm.patchValue({place:this.selectedLocation})
  }

  bannerLink(data: any) {
    if (this.userdata.isLogin()) {
      console.log("data?.bannerInfo?", data?.bannerInfo)
      if (data?.bannerInfo?.is_product) {
        this.router.navigateByUrl('/product-detail/' + data?.bannerInfo?.productId)
      }
      if (data?.bannerInfo?.is_astro) {
        this.router.navigateByUrl('/astrologer/' + data?.bannerInfo?.astroId)
      }
      if (data?.bannerInfo?.is_link) {
        window.location.href = data?.bannerInfo?.link_value;
      }
      switch (data.bannerInfo.bannerType) {

        case 4:
          this.router.navigateByUrl('/call-astro-list')
          break;

        case 5:
          this.router.navigateByUrl('/chat-astro-list')
          break;

        case 6:
          this.router.navigateByUrl('/query-astro-list')
          break;

        case 7:
          this.router.navigateByUrl('/report-astro-list')
          break;

        case 8:
          this.router.navigateByUrl('/wallet')
          break;

        case 9:
          this.router.navigateByUrl('/recharge-wallet')
          break;
      }
    } else {
      this.toast.error('Please Login First', 'Error')
    }
  }

  getAstrologerCurrentPrice(product: any) {
    return this.code ? product?.astrologerList[0].price : product?.astrologerList[0].usd_price || 0;
  }
}
