<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">{{ breadcrumb }}</h3>
      <ul
        class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        "
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
          &nbsp;&nbsp;>&nbsp;&nbsp;
        </li>
        <li>{{ breadcrumb }}</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- Page Content Start -->
<section class="mt-2">
  <div class="container">
    <div class="row">
      <div innerHTML="{{ pageContent }}"></div>
    </div>
  </div>
  <hr />
</section>

<!-- Page Content End -->
<app-footer></app-footer>
