<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Message From CEO</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
          &nbsp;&nbsp;>&nbsp;&nbsp;
        </li>
        <li>Message From CEO</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!-- hs about ind wrapper Start -->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <img src="../../../assets/images/nisha-profile.jpg" class="w-100 rounded border shadow" alt="about_img" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex flex-column">
        <h2 class="pb-2">Message From CEO</h2>
        <!-- <p class="text-justify">
          Dr Nisha is one of the Senior Astrologers in the Astro River’s team.
          She works as the Managing Director of Astro River, and is the founder
          of&nbsp;<span style="color: #b42d37"
            ><em><strong>Astro Jewels</strong></em></span
          >, a subsidiary of Astro River which deals exclusively in fine
          Gemstones and astrological jewellery.&nbsp;
        </p>
        <p class="text-justify mt-2">
          She is an International Astrologer and an experienced Gemologist with
          12+ years of experience in various occult fields, especially in
          Astrology, Vastu &amp; Tarot. In her illustrious astrological career,
          she has advised numerous celebrities, politicians and people from
          various walks of life.
        </p>
        <p class="text-justify mt-2">
          Ms. Sharma is also an author. Her works have been recognized and duly
          rewarded by many reputed occult organisations across the globe. She
          has also spoken at various platforms and teaches Astrology and Tarot
          with utmost passion.&nbsp;
        </p> -->
        <p class="text-justify mt-2">After spending over 10+ years with AstroRiver, I feel very privileged to be writing
          this message as the CEO. During this journey, I have also had the privilege of being the Founder of
          <span style="color: #b42d37"><em><strong>Astro Jewels</strong></em></span>, our line of fine
          astrological jewellery and exquisite gemstones.</p>
        <p class="text-justify mt-2">&nbsp;<span style="color: #b42d37"><em><strong>Astro River</strong></em></span>
          began it's journey in 2006, and our team has spent the past decade continuing to touch human lives, helping
          them to grow and progress, achieve peace of mind and solve their love, career and health challenges through
          the sheer power of Astrology. We are proud to have reached 50 Lakh+ customers around the globe (that's a
          Million!) And we're not done yet!
        </p>
        <p class="text-justify mt-2">
          AstroRiver has always been about so much more than just providing world-class astrological services. It's also
          AstroRiver is much more than just an astrology app, it is a way of life.

        </p>
        <p class="text-justify mt-2">
          Our team of dedicated professionals comprising world-class Astrologers, Counsellors, Life Coaches and
          Consultants specialising in different fields strive to offer the best possible insights and guidance about
          your love life, career, or anything else you might need help with. We are constantly innovating and expanding
          our services to make sure that we can offer you the best possible astrological insights and guidance.
        </p>
        <p class="text-justify mt-2">
          With the AstroRiver app, you will never have to worry about making tough life decisions again! I invite you to
          download the app and am confident that it will be a game-changer in the astrology industry, and I cannot wait
          to see how it helps even more clients around the world live better lives.

        </p>
        <p class="text-justify mt-2">
          The future vision of Astro River is to be the go-to source for all astrological and motivational needs. We
          want to help people from all walks of life gain insights and guidance about their lives, and make it easier
          for them to navigate through the challenges they face. We will continue to innovate and offer new and exciting
          products and services that will help us achieve our goal.

        </p>
        <p class="text-justify mt-2">
          Thank you for being part of the Astro River family, and I look forward to continued success together.

        </p>
        <p class="mt-2">
          Warmest regards,
          <br>
          Dr Nisha Shharma
          <br>
          Chief Executive Officer - Astro River

        </p>
        <a [routerLink]="['/about-us']" class="mt-3 font-italic" >Read more about Dr. Nisha Shharma on the About Page.
        </a>
      </div>
    </div>
  </div>
</section>
<!-- hs about ind wrapper End -->
<app-footer></app-footer>