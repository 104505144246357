import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class FestivalsService {

  BaseUrl: string = ''
  Token: any = '';
header_object
  constructor(
    private userdata: UserdataService,
    private http: HttpClient, @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
    this.header_object = new HttpHeaders({
      "Authorization": this.userdata.getToken()||"",
      'country_code':this.userdata.getCountryCode()||"in"
    })
  }

  public getAllFestivals(data: any) {
   return this.http.post(this.BaseUrl + "/allYearlyFestivals", data,{ headers: this.header_object });
  }

  public searchFestivals(data: any) {
   return this.http.post(this.BaseUrl + "/searchFestival", data,{ headers: this.header_object });
  }

  public getSingleFestivalById(id: any) {
   return this.http.post(this.BaseUrl + "/getSingleYearlyFestival", id,{ headers: this.header_object });
  }

}


