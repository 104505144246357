import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FestivalsService } from 'src/app/service/festivals/festivals.service';

@Component({
  selector: 'app-festivals-list',
  templateUrl: './festivals-list.component.html',
  styleUrls: ['./festivals-list.component.css']
})
export class FestivalsListComponent implements OnInit {
  BaseUrl: any;
  constructor(
    private festivalsService: FestivalsService,
    private route: ActivatedRoute,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  id: any;
  blogDetails: any;
  current_year:any
  ngOnInit(): void {
    this.current_year=(new Date()).getFullYear()
    this.getAllFestivals()
  }

  // get all blogs
  festivals: any = [];
  getAllFestivals() {
    this.festivalsService.getAllFestivals({year:this.current_year}).subscribe(
      (res: any) => {
        this.festivals = res.data;
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
  searchKey(key:any){
    if(key.length==0){
      this.getAllFestivals()
    }
  }
  searchFestival(key: any) {
    this.festivalsService.searchFestivals({ festivalYear:this.current_year,search: key }).subscribe(
      (res: any) => {
        this.festivals = res.data;
      }, err => {
        // this.toast.error(err.error.message, 'Error')
      }
    )
  }

}

