import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-panchang',
  templateUrl: './panchang.component.html',
  styleUrls: ['./panchang.component.css']
})
export class PanchangComponent implements OnInit {
  todaysDate: any;
  datepicker:any;
  kundliResponse: any;
  region: any;

  constructor(private openService: OpenApiService, private spinner: NgxSpinnerService, private toast: ToastrService, private userdata: UserdataService) { }
  location: any;
  ngOnInit(): void {
    var curDate = Date();
    this.todaysDate = moment(curDate).format('LL');
    this.datepicker = moment(curDate).format('yyyy-MM-DD');
    this.getDataFromApi()
  }

  getDataFromApi() {
    this.spinner.show()
    this.location = this.openService.getLatLon()
    this.region = this.userdata.getRegion();
    this.getPanchang()
    this.spinner.hide()
  }

  getPanchang() {
    var curDate = Date();
    var date = moment(curDate).format('Z'); //
    var GMT = date.replace(':', '.');
    GMT = GMT.replace('+', '');
    var locarr = this.location.split(',');
    var searchData = {
      apiaddress: 'panchang/panchang',
      date: moment(this.todaysDate).format('DD/MM/YYYY'),
      tz: parseFloat(GMT),
      lat: locarr[0],
      lon: locarr[1],
    }
    this.openService.getPanchang(searchData).subscribe(
      (res: any) => {
        this.kundliResponse = res.data;
        this.spinner.hide();
      },
      (err: any) => {
        //console.log(err);
        this.spinner.hide();
      }
    );
  }

  changeDate(event:any){
    this.todaysDate = moment(event.target.value).format('LL');
    this.getPanchang()
  }

}
