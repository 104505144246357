<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- hs About Title Start -->

<!-- hs sidebar Start -->

<div class="container px-4">
  <div class="row gx-5">
    <div class="col-md-3">

    </div>
    <div class="col-md-9">
      <div class="hs_blog_categories_main_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="hs_blog_left_sidebar_main_wrapper">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="hs_kd_first_sec_wrapper" id="showresult">
                      <h2>Products</h2>
                      <h4><span></span></h4>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>

                  <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="hs_blog_right_search_wrapper">
                      <form>
                        <input type="text" placeholder="Search.." (keyup)="searchProduct($event)" />
                        <button type="button"><i class="fa fa-search"></i></button>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pr-4" *ngIf="dataList.length <= 0">
                    <h2>No Data Available.</h2>
                  </div>
                  <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let products of dataList">
                    <div class="hs_shop_prodt_main_box">
                      <div class="hs_shop_prodt_img_wrapper">
                        <img [src]="getSanitizeUrl(products.product_image[0])" alt="shop_product"
                          class="img img-responsive img-category">
                        <a [routerLink]="['/product-detail',products._id]">View Details</a>
                      </div>
                      <div class="hs_shop_prodt_img_cont_wrapper">
                        <h2><a [routerLink]="['/product-detail',products._id]">{{ products.productname }}</a></h2>
                        <h6>{{ products.productdescription }}</h6>
                        <a [routerLink]="['/booking',products._id]" class="btn btn-warning float-right">Book Now</a>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let products of dataList">
                    <div class="hs_shop_prodt_main_box">
                      <div class="hs_shop_prodt_img_wrapper">
                        <img [src]="getSanitizeUrl(products.product_image[0])" alt="shop_product"
                          class="img img-responsive img-category" />
                        <a [routerLink]="['/product-detail', products.product_slug]">View Details</a>
                      </div>
                      <div class="hs_shop_prodt_img_cont_wrapper">
                        <h2>
                          <a [routerLink]="['/product-detail', products.product_slug]">{{
                            products.productname
                            }}</a>
                        </h2>
                        <ng-container *ngIf="products.is_direct_price">
                          <ng-container *ngIf="products.is_offer_applied; else noOffer">
                            <h3 *ngIf="code; else usd">
                              {{
                              products.price | currency: "INR":"symbol-narrow"
                              }}
                              &nbsp;<del>{{
                                products.original_amount
                                | currency: "INR":"symbol-narrow"
                                }}</del>&nbsp;
                              <span>({{ products.offer_percentage }}% off)</span>
                            </h3>
                            <ng-template #usd>
                              <h3>
                                {{
                                products.usd_price | currency: "USD":"symbol-narrow"
                                }}
                                &nbsp;<del>{{
                                  products.usd_original_amount
                                  | currency: "USD":"symbol-narrow"
                                  }}</del>&nbsp;
                                <span>({{ products.offer_percentage }}% off)</span>
                              </h3>
                            </ng-template>
                          </ng-container>
                          <ng-template #noOffer>
                            <h3 *ngIf="code; else usd">
                              {{ products.price | currency: "INR":"symbol-narrow" }}
                            </h3>
                            <ng-template #usd>
                              <h3>
                                {{
                                products.usd_price | currency: "USD":"symbol-narrow"
                                }}
                              </h3>
                            </ng-template>
                          </ng-template>
                        </ng-container>
                        <a (click)="book(products._id)" class="btn btn-sm btn-block btn-warning">Book Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- hs sidebar End -->
<app-footer></app-footer>