<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Match Making</h3>
            <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end 
        ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>Match-Making</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->

<section class="container my-5">


    <!-- Match making form start here  -->
    <ng-container *ngIf="formData == ''">
        <div class="row">
            <div class="col-md-4 col-12 my-5">
                <div class="sign-up-form-wrap position-relative z-index shadow-lg rounded px-3 py-4 w-100 bg-white">
                    <div class="sign-up-form-header  mb-3">
                        <h5 class="mb-1 fw-600 ">Boy Details</h5>
                    </div>
                    <div class="message-box d-none">
                        <div class="alert alert-danger"></div>
                    </div>
                    <form [formGroup]="formGroup" id="getQuoteFrm" class="login-signup-form get-quote-form-wrap">

                        <div class="my-3 match-making-form">
                            <!-- <i class="fa fa-user txt-red" aria-hidden="true"></i>  -->
                            <input type="text"
                                formControlName="boy_name" class="border-0
                           ml-1 " placeholder="Enter your name">
                        </div>
                        <div class="my-3 match-making-form">
                            <!-- <i class="fa-solid fa-calendar-days txt-red"></i>  -->
                            <input type="date"
                                formControlName="boy_dob" class="border-0
                                           ml-1 w-100" placeholder="Enter Date of Birth" [max]="today">
                        </div>
                        <div class="my-3 match-making-form">
                            <!-- <i class="fa-solid   fa-clock txt-red"></i> -->
                            <input type="time" formControlName="boy_tob" class="border-0
                                           ml-1 w-100" placeholder="Enter Time of Birth">
                        </div>
                        <div class="my-3 match-making-form d-flex justify-content-between">
                            <!-- <i class="fa fa-map txt-red" aria-hidden="true"></i> -->
                            <ng-select class="flex-grow-1 ml-1" [items]="boyLocationList" bindLabel="name"
                                bindValue="name" (keyup)="getLocation($event,'boy')"
                                (change)="selectLocation($event,'boy')" placeholder="Enter Birth Place">
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <p>{{item?.name}} ({{item.country}})</p>
                                </ng-template>
                            </ng-select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-4 col-12 my-5 ">
                <img src="../../../assets/images/match-making.svg" class="w-100 align-middle">
            </div>
            <div class="col-md-4 col-12 my-5">
                <div class="sign-up-form-wrap position-relative z-index shadow-lg rounded px-3 py-4 w-100 bg-white">
                    <div class="sign-up-form-header  mb-3">
                        <h5 class="mb-1 fw-600 ">Grils Details</h5>
                    </div>
                    <div class="message-box d-none">
                        <div class="alert alert-danger"></div>
                    </div>
                    <form [formGroup]="formGroup" id="getQuoteFrm" class="login-signup-form get-quote-form-wrap">

                        <div class="my-3 match-making-form">
                            <!-- <i class="fa fa-user txt-red" aria-hidden="true"></i>  -->
                            <input type="text" formControlName="girl_name" class="border-0
                                   ml-1 " placeholder="Enter your name">
                        </div>
                        <div class="my-3 match-making-form">

                            <!-- <i class="fa-solid fa-calendar-days txt-red"></i>  -->
                            <input type="date" formControlName="girl_dob" class="border-0
                                                   ml-1 w-100" placeholder="Enter Date of Birth" [max]="today">
                        </div>
                        <div class="my-3 match-making-form">
                            <!-- <i class="fa-solid   fa-clock txt-red"></i> -->
                            <input type="time" formControlName="girl_tob" class="border-0
                                                   ml-1 w-100" placeholder="Enter Time of Birth">
                        </div>
                        <div class="my-3 match-making-form d-flex justify-content-between">
                            <!-- <i class="fa fa-map txt-red" aria-hidden="true"></i> -->
                            <ng-select class="flex-grow-1 ml-1" [items]="girlLocationList" bindLabel="name"
                                bindValue="name" (keyup)="getLocation($event,'girl')"
                                (change)="selectLocation($event,'girl')" placeholder="Enter Birth Place">
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <p>{{item?.name}} ({{item.country}})</p>
                                </ng-template>
                            </ng-select>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="text-center ">
            <button type="button" class="btn btn-md px-5 bg-green text-white mt-2 mb-5"
                (click)="submit()" [disabled]="formGroup.invalid">
                Get Details
            </button>
        </div>
    </ng-container>
    <!-- Match making form end here  -->

    <div class=" d-flex text-center f-style mb-3" *ngIf="formData != ''">
        <button (click)="getMatchMaking('Aggregate')" class="btn btn-sm mr-2 rounded  py-2" [ngClass]="{'active':currentMainTab == 'Aggregate'}">Aggregate</button>
        <button (click)="getMatchMaking('Details')" class="btn btn-sm mr-2 rounded  py-2" [ngClass]="{'active':currentMainTab == 'Details'}">Details</button>
        <button (click)="getMatchMaking('Ashtakoot')" class="btn btn-sm mr-2 rounded py-2" [ngClass]="{'active':currentMainTab == 'Ashtakoot'}">Ashtakoot</button>
        <button (click)="getMatchMaking('Dashakoot')" class="btn btn-sm mr-2 rounded py-2" [ngClass]="{'active':currentMainTab == 'Dashakoot'}">Dashakoot</button>
        <button (click)="getMatchMaking('Papasamya')" class="btn btn-sm mr-2 rounded py-2" [ngClass]="{'active':currentMainTab == 'Papasamya'}">Papasamya</button>
        <button (click)="getMatchMaking('Starmatching')" class="btn btn-sm mr-2 rounded py-2" [ngClass]="{'active':currentMainTab == 'Starmatching'}">Starmatching</button>
    </div>
    <!-- After get detail output design start here -->
    <div class="" *ngIf="currentMainTab == 'Aggregate'">
        <div class="my-3  border rounded p-3 w-100">
            <h5 class="mb-1 txt-red">Managaldosh</h5>
            <p class="text-justify ">{{matchResponse?.mangaldosh}}</p>

        </div>
        <div class="my-3  border rounded p-3 w-100">
            <h5 class="mb-1 txt-red">Pitrdosh</h5>
            <p class="text-justify text-break">{{matchResponse?.pitradosh}}</p>

        </div>
        <div class="my-3  border rounded p-3 w-100">
            <h5 class="mb-1 txt-red">KaalSarpdosh</h5>
            <p class="text-justify ">{{matchResponse?.kaalsarpdosh}}</p>

        </div>
        <div class="my-3  border rounded p-3 w-100">
            <h5 class="mb-1 txt-red">Score</h5>
            <p class="text-justify ">{{matchResponse?.score}}</p>

        </div>
        <div class="my-3  border rounded p-3 w-100">
            <h5 class="mb-1 txt-red">Response</h5>
            <p class="text-justify ">{{matchResponse?.bot_response}}</p>

        </div>

    </div>
    <!-- After get detail output design end here -->
    <!-- Common tableformat for all tabs common format in matchmaking start -->
    <div class="" *ngIf="currentMainTab == 'Details'">
        <table class="table table-striped table-bordered">
            <thead class="table table-dark  k-table fw-400">
                <tr>
                    <th scope="col">Astro Details</th>
                    <th scope="col">Male</th>
                    <th scope="col">Female</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="col">Gana </td>
                    <td scope="col">{{matchResponse?.gana?.boy_gana}}</td>
                    <td scope="col">{{matchResponse?.gana?.girl_gana}}</td>
                </tr>
                <tr>
                    <td scope="col">Yoni </td>
                    <td scope="col">{{matchResponse?.yoni?.boy_yoni}}</td>
                    <td scope="col">{{matchResponse?.yoni?.girl_yoni}}</td>
                </tr>
                <tr>
                    <td scope="col">Vasya </td>
                    <td scope="col">{{matchResponse?.vasya?.boy_vasya}}</td>
                    <td scope="col">{{matchResponse?.vasya?.girl_vasya}}</td>
                </tr>
                <tr>
                    <td scope="col">Nadi </td>
                    <td scope="col">{{matchResponse?.nadi?.boy_nadi}}</td>
                    <td scope="col">{{matchResponse?.nadi?.girl_nadi}}</td>
                </tr>
                <tr>
                    <td scope="col">Varna </td>
                    <td scope="col">{{matchResponse?.varna?.boy_varna}}</td>
                    <td scope="col">{{matchResponse?.varna?.girl_varna}}</td>
                </tr>
                <tr>
                    <td scope="col">Paya </td>
                    <td scope="col">{{matchResponse?.boy_astro_details?.paya}}</td>
                    <td scope="col">{{matchResponse?.girl_astro_details?.paya}}</td>
                </tr>
                <tr>
                    <td scope="col">Tatva </td>
                    <td scope="col">{{matchResponse?.boy_astro_details?.tatva}}</td>
                    <td scope="col">{{matchResponse?.girl_astro_details?.tatva}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="" *ngIf="currentMainTab == 'Ashtakoot'">
        <table class="table table-striped table-bordered">
            <thead class="table table-dark  k-table fw-400">
                <tr>
                    <th scope="col">Astro Details</th>
                    <th scope="col">Male</th>
                    <th scope="col">Female</th>
                    <th scope="col">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="col">Varna </td>
                    <td scope="col">{{matchResponse?.varna?.boy_varna}}</td>
                    <td scope="col">{{matchResponse?.varna?.girl_varna}}</td>
                    <td scope="col">{{matchResponse?.varna?.varna}}</td>
                </tr>
                <tr>
                    <td scope="col">Vasya </td>
                    <td scope="col">{{matchResponse?.vasya?.boy_vasya}}</td>
                    <td scope="col">{{matchResponse?.vasya?.girl_vasya}}</td>
                    <td scope="col">{{matchResponse?.vasya?.vasya}}</td>
                </tr>
                <tr>
                    <td scope="col">Yoni </td>
                    <td scope="col">{{matchResponse?.yoni?.boy_yoni}}</td>
                    <td scope="col">{{matchResponse?.yoni?.girl_yoni}}</td>
                    <td scope="col">{{matchResponse?.yoni?.yoni}}</td>
                </tr>
                <tr>
                    <td scope="col">Gana </td>
                    <td scope="col">{{matchResponse?.gana?.boy_gana}}</td>
                    <td scope="col">{{matchResponse?.gana?.girl_gana}}</td>
                    <td scope="col">{{matchResponse?.gana?.gana}}</td>
                </tr>
                <tr>
                    <td scope="col">Nadi </td>
                    <td scope="col">{{matchResponse?.nadi?.boy_nadi}}</td>
                    <td scope="col">{{matchResponse?.nadi?.girl_nadi}}</td>
                    <td scope="col">{{matchResponse?.nadi?.nadi}}</td>
                </tr>
                <tr>
                    <td scope="col">Tara </td>
                    <td scope="col">{{matchResponse?.tara?.boy_tara}}</td>
                    <td scope="col">{{matchResponse?.tara?.girl_tara}}</td>
                    <td scope="col">{{matchResponse?.tara?.tara}}</td>
                </tr>
                <tr>
                    <td scope="col">Bhakoot </td>
                    <td scope="col">{{matchResponse?.bhakoot?.boy_rasi_name}}</td>
                    <td scope="col">{{matchResponse?.bhakoot?.girl_rasi_name}}</td>
                    <td scope="col">{{matchResponse?.bhakoot?.bhakoot}}</td>
                </tr>
                <tr>
                    <td scope="col">Grahamaitri </td>
                    <td scope="col">{{matchResponse?.grahamaitri?.boy_lord}}</td>
                    <td scope="col">{{matchResponse?.grahamaitri?.girl_lord}}</td>
                    <td scope="col">{{matchResponse?.grahamaitri?.grahamaitri}}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-striped table-bordered mt-2">
            <tbody>
                <tr>
                    <td scope="col">Score </td>
                    <td scope="col">{{matchResponse?.score}}</td>
                </tr>
                <tr>
                    <td scope="col">Result </td>
                    <td scope="col">{{matchResponse?.bot_response}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="" *ngIf="currentMainTab == 'Dashakoot' || currentMainTab == 'Starmatching'">
        <table class="table table-striped table-bordered">
            <thead class="table table-dark  k-table fw-400">
                <tr>
                    <th scope="col">Astro Details</th>
                    <th scope="col">Male</th>
                    <th scope="col">Female</th>
                    <th scope="col">Points</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="col">Dina </td>
                    <td scope="col">{{matchResponse?.dina?.boy_star}}</td>
                    <td scope="col">{{matchResponse?.dina?.girl_star}}</td>
                    <td scope="col">{{matchResponse?.dina?.dina}}</td>
                </tr>
                <tr>
                    <td scope="col">Gana </td>
                    <td scope="col">{{matchResponse?.gana?.boy_gana}}</td>
                    <td scope="col">{{matchResponse?.gana?.girl_gana}}</td>
                    <td scope="col">{{matchResponse?.gana?.gana}}</td>
                </tr>
                <tr>
                    <td scope="col">Mahendra </td>
                    <td scope="col">{{matchResponse?.mahendra?.boy_star}}</td>
                    <td scope="col">{{matchResponse?.mahendra?.girl_star}}</td>
                    <td scope="col">{{matchResponse?.mahendra?.mahendra}}</td>
                </tr>
                <tr>
                    <td scope="col">Sthree </td>
                    <td scope="col">{{matchResponse?.sthree?.boy_star}}</td>
                    <td scope="col">{{matchResponse?.sthree?.girl_star}}</td>
                    <td scope="col">{{matchResponse?.sthree?.sthree}}</td>
                </tr>
                <tr>
                    <td scope="col">Yoni </td>
                    <td scope="col">{{matchResponse?.yoni?.boy_yoni}}</td>
                    <td scope="col">{{matchResponse?.yoni?.girl_yoni}}</td>
                    <td scope="col">{{matchResponse?.yoni?.yoni}}</td>
                </tr>
                <tr>
                    <td scope="col">Rasi </td>
                    <td scope="col">{{matchResponse?.rasi?.boy_rasi}}</td>
                    <td scope="col">{{matchResponse?.rasi?.girl_rasi}}</td>
                    <td scope="col">{{matchResponse?.rasi?.rasi}}</td>
                </tr>
                <tr>
                    <td scope="col">Rasiathi </td>
                    <td scope="col">{{matchResponse?.rasiathi?.boy_lord}}</td>
                    <td scope="col">{{matchResponse?.rasiathi?.girl_lord}}</td>
                    <td scope="col">{{matchResponse?.rasiathi?.rasiathi}}</td>
                </tr>
                <tr>
                    <td scope="col">Vasya </td>
                    <td scope="col">{{matchResponse?.vasya?.boy_rasi}}</td>
                    <td scope="col">{{matchResponse?.vasya?.girl_rasi}}</td>
                    <td scope="col">{{matchResponse?.vasya?.vasya}}</td>
                </tr>                
                
                <tr>
                    <td scope="col">Rajju </td>
                    <td scope="col">{{matchResponse?.rajju?.boy_rajju}}</td>
                    <td scope="col">{{matchResponse?.rajju?.girl_rajju}}</td>
                    <td scope="col">{{matchResponse?.rajju?.rajju}}</td>
                </tr>
                <tr>
                    <td scope="col">Vedha </td>
                    <td scope="col">{{matchResponse?.vedha?.boy_star}}</td>
                    <td scope="col">{{matchResponse?.vedha?.girl_star}}</td>
                    <td scope="col">{{matchResponse?.vedha?.vedha}}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-striped table-bordered mt-2">
            <tbody>
                <tr>
                    <td scope="col">Score </td>
                    <td scope="col">{{matchResponse?.score}}</td>
                </tr>
                <tr *ngIf="currentMainTab == 'Dashakoot'">
                    <td scope="col">Rajju Dosha </td>
                    <td scope="col">{{dosha?.is_rajju_dosha_present ? 'Dosha Present':'Dosha Not Present'}}</td>
                </tr>
                <tr *ngIf="currentMainTab == 'Dashakoot'">
                    <td scope="col">Vedha Dosha </td>
                    <td scope="col">{{dosha?.is_vedha_dosha_present ? 'Dosha Present':'Dosha Not Present'}}</td>
                </tr>
                <tr>
                    <td scope="col">Result </td>
                    <td scope="col">{{matchResponse?.bot_response}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="" *ngIf="currentMainTab == 'Papasamya'">
        <table class="table table-striped table-bordered">
            <thead class="table table-dark  k-table fw-400">
                <tr>
                    <th scope="col">Astro Details</th>
                    <th scope="col">Male</th>
                    <th scope="col">Female</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="col">Rahu Papa </td>
                    <td scope="col">{{matchResponse?.boy_papa?.rahu_papa}}</td>
                    <td scope="col">{{matchResponse?.girl_papa?.rahu_papa}}</td>
                </tr>
                <tr>
                    <td scope="col">Sun Papa </td>
                    <td scope="col">{{matchResponse?.boy_papa?.sun_papa}}</td>
                    <td scope="col">{{matchResponse?.girl_papa?.sun_papa}}</td>
                </tr>
                <tr>
                    <td scope="col">Saturn Papa </td>
                    <td scope="col">{{matchResponse?.boy_papa?.saturn_papa}}</td>
                    <td scope="col">{{matchResponse?.girl_papa?.saturn_papa}}</td>
                </tr>
                <tr>
                    <td scope="col">Mars Papa </td>
                    <td scope="col">{{matchResponse?.boy_papa?.mars_papa}}</td>
                    <td scope="col">{{matchResponse?.girl_papa?.mars_papa}}</td>
                </tr>
                <tr>
                    <td scope="col">Total </td>
                    <td scope="col">{{matchResponse?.boy_total}}</td>
                    <td scope="col">{{matchResponse?.girl_total}}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-striped table-bordered mt-2">
            <tbody>
                <tr>
                    <td scope="col">Score </td>
                    <td scope="col">{{matchResponse?.score | number : '1.2-2'}}</td>
                </tr>
                <tr>
                    <td scope="col">Result </td>
                    <td scope="col">{{matchResponse?.bot_response}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Common table for all tabs in matchmaking start -->



</section>
<app-footer></app-footer>