import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-show-report-list',
  templateUrl: './show-report-list.component.html',
  styleUrls: ['./show-report-list.component.css']
})
export class ShowReportListComponent implements OnInit {

  BaseUrl = ''
  reportList:any = []
  constructor(
    private openService: OpenApiService,
    private toast: ToastrService,
    private trusturl: DomSanitizer,
    private userdata: UserdataService,
    private router: Router,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngOnInit(): void {
    this.getAllReport()
  }

  getAllReport(){
    this.openService.showReportList({}).subscribe(
      (res:any)=>{
        this.reportList = res.data
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizeUrl(url: string){
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl+url)
  }

  selectReport(key:any){
    var object = JSON.parse(this.userdata.getOrderObject()||'{}')
    // console.log(object)
    var orderObject = {
      customerId: this.userdata.getCustomerId(), astroId: object.astroId, orderType: 'report',reportId:key._id,is_offer: object.is_offer, offerId: object.offerId
    }
    sessionStorage.setItem("orderObject", JSON.stringify(orderObject))
    this.router.navigateByUrl('/report-order-list-person')
  }

}
