<app-header></app-header>
<ngx-spinner></ngx-spinner>

<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">issue Detail</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li>
          <a *ngIf="dataList?.orderserviceId?.is_call_order" [routerLink]="['/call-order-list',dataList?.orderserviceId?._id]" class="text-white">Call Detail</a>
          <a *ngIf="dataList?.orderserviceId?.is_chat_order" [routerLink]="['/chat-order-list',dataList?.orderserviceId?._id]" class="text-white">Chat Detail</a>
          <a *ngIf="dataList?.orderserviceId?.is_query_order" [routerLink]="['/query-order-list',dataList?.orderserviceId?._id]" class="text-white">Query Detail</a>
          <a *ngIf="dataList?.orderserviceId?.is_report_order" [routerLink]="['report-order-list',dataList?.orderserviceId?._id]" class="text-white">Report Detail</a>
          <a *ngIf="dataList?.is_order_issue" [routerLink]="['/store-order-list',dataList?.orderId?._id]" class="text-white">Store Order Detail</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">issue Detail</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="row chat-content" style="border: 1px solid #e0e0e0;" #scrollMe [scrollTop]="scrollMe.scrollHeight">
          <div class="w-100" *ngFor="let chat of dataList?.replylogs">
            <div class="container-chat lighter" *ngIf="chat.isCustomerReply">
              <p [innerHTML]="chat.new_reply"></p>
              <span class="time-right fn-0 mb-n2">{{
                chat.replyDate | date: "medium"
                }}</span>
            </div>

            <div class="container-chat darker" *ngIf="chat.isSystemReply">
              <p [innerHTML]="chat.new_reply" class="text-white"></p>
              <span class="time-left fn-0 mb-n2">{{
                chat.replyDate | date: "medium"
                }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <form class="col-12 d-flex justify-content-center p-0" [formGroup]="issueChatForm" (submit)="replyIssue()" (keyup.enter)="replyIssue()">
            <textarea formControlName="reply_by_system" rows="1" class="mr-1 form-control" type="text"
              placeholder="Enter your issue here"></textarea>
            <button class="p-0 btn bt-floating bg-green" type="submit" [disabled]="!issueChatForm.valid">
              <i class="pl-2 pr-1 mt-2 material-icons-round text-white">
                send
              </i>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>