import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserdataService } from '../service/userdata/userdata.service';
import { UserdetailsService } from '../service/userdetails/userdetails.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userdata: UserdataService,
    private router: Router,
    private toast: ToastrService,
    private userDetailService: UserdetailsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.userdata.getToken().length > 0) {
      if (
        this.userdata.getPhoneVerify() != 'false' &&
        this.userdata.getPhoneVerify() != null &&
        typeof this.userdata.getPhoneVerify() != 'undefined'
      ) {
        if (
          this.userdata.getRegCountry().toLowerCase() !==
          this.userdata.getCountryCode().toLowerCase()
        ) {
          this.userdata.removeData();
          this.userDetailService.onChangeOfUserDetailHandler(null);
          this.router.navigate(['/home']);
          this.toast.error('Sorry register country is different than current!');
          return false;
        } else {
          return true;
        }
      } else {
        this.toast.info('Login Required', 'Note:');
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }
    } else {
      this.toast.info('Login Required', 'Note:');
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
