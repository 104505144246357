import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-verify-person-phone-otp',
  templateUrl: './verify-person-phone-otp.component.html',
  styleUrls: ['./verify-person-phone-otp.component.css'],
})
export class VerifyPersonPhoneOtpComponent implements OnInit {
  id: any = '';
  orderObject: any = {};
  astroData: any;
  constructor(
    private router: Router,
    private orderService: OrderServicesService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private userdata: UserdataService,
    private loginService: LoginService,
    private openService: OpenApiService
  ) {}
  isCallOrder = false;
  isChatOrder = false;
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.orderObject = JSON.parse(this.userdata.getOrderObject() || '{}');

    if (this.orderObject.orderType === 'call') {
      this.isCallOrder = true;
      this.addToWaitingListForm.patchValue({ is_call: true });
    }
    if (this.orderObject.orderType === 'chat') {
      this.isChatOrder = true;
      this.addToWaitingListForm.patchValue({ is_chat: true });
    }

    this.addToWaitingListForm.patchValue({ astroId: this.orderObject.astroId });
    this.addToWaitingListForm.patchValue({ promoId: this.orderObject.promoId });
    this.addToWaitingListForm.patchValue({
      is_offer: this.orderObject.is_offer,
    });
    this.addToWaitingListForm.patchValue({ offerId: this.orderObject.offerId });
    this.addToWaitingListForm.patchValue({
      personId: this.orderObject.personId,
    });
    this.addToWaitingListForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });
    this.resendOtp();
    if (
      this.orderObject.astroId != null &&
      typeof this.orderObject.astroId != 'undefined' &&
      this.orderObject.astroId != ''
    ) {
      this.getAstroProfile();
    }
  }

  getAstroProfile() {
    this.openService
      .singleAstrologerDetails({ _id: this.orderObject.astroId })
      .subscribe(
        (res: any) => {
          this.astroData = res.data;
        },
        (err) => {}
      );
  }

  onOtpChange(event: any) {
    if (event.length == 4) {
      this.spinner.show();
      this.orderService.verifyPersonOtp({ _id: this.id, otp: event }).subscribe(
        (res: any) => {
          this.spinner.hide();
          localStorage.setItem('isPhoneVerify', 'true');
          this.toast.success(res.message, 'Success');
          if (this.orderObject.orderType === 'callPromo') {
            this.placeCallPromoOrder();
          } else {
            if (this.orderObject.orderCheck == 'reportpromo') {
              this.placeReportCallOrder();
            } else if (this.orderObject.orderCheck == 'promoadmin') {
              this.placeAdminPromo();
            } else {
              if (this.orderObject.orderCheck === 'wait') {
                this.placeCallWaitOrder();
              } else {
                this.placeCallOrder();
              }
            }
          }
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
    }
  }

  resendOtp() {
    this.orderService.sendPersonOtp({ _id: this.id }).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
      },
      (err) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  callForm = new FormGroup({
    customerId: new FormControl(''),
    astroId: new FormControl(''),
    personId: new FormControl(''),
    is_offer: new FormControl(''),
    offerId: new FormControl(''),
  });

  placeCallOrder() {
    this.spinner.show();
    /**
     * Check If User is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.spinner.hide();
            Swal.fire(
              "Call can't be connected",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          if (res.data.is_waiting) {
            this.spinner.hide();
            Swal.fire(
              "Order can't be placed",
              'You are already added in an astrologer waiting list.',
              'info'
            );
            return;
          }
          /**Check Wallet amount */
          var charges = 0;
          if (
            this.astroData.astroofferId == null ||
            !this.astroData.astroofferId?.is_call_offer_activate ||
            this.astroData.callofferis == null
          ) {
            charges = this.astroData.call_charges * 2;
          } else {
            if (this.astroData.isnewforastro) {
              if (
                this.astroData.callofferis.user_type == 1 ||
                this.astroData.callofferis.user_type == 3
              ) {
                charges =
                  this.astroData.call_charges * 2 -
                  this.astroData.call_charges *
                    2 *
                    (this.astroData.callofferis.offer_discount / 100);
              } else {
                charges = this.astroData.call_charges * 2;
              }
            } else {
              if (
                this.astroData.callofferis.user_type == 1 ||
                this.astroData.callofferis.user_type == 2
              ) {
                charges =
                  this.astroData.call_charges * 2 -
                  this.astroData.call_charges *
                    2 *
                    (this.astroData.callofferis.offer_discount / 100);
              } else {
                charges = this.astroData.call_charges * 2;
              }
            }
          }
          var waitingAmount = res.data.waiting_amount;
          var currentWallet = res.data.current_wallet;
          var customerWalletAmount = currentWallet - waitingAmount;
          var astroCharges = charges * 5; //001 05-08-2021
          if (currentWallet < astroCharges) {
            this.spinner.hide();
            //this.userdata.setCurrentRoute(this.router.url)
            this.toast.error('Please Recharge to place order', 'Alert');
            //this.router.navigateByUrl('/recharge-wallet')
            return;
          }
          if (astroCharges > customerWalletAmount) {
            this.spinner.hide();
            //this.userdata.setCurrentRoute(this.router.url)
            this.toast.error('Please Recharge to place order', 'Alert');
            //this.router.navigateByUrl('/recharge-wallet')
            return;
          }
          /**
           * Place Order
           */
          this.callForm.patchValue({ customerId: this.orderObject.customerId });
          this.callForm.patchValue({ astroId: this.orderObject.astroId });
          this.callForm.patchValue({ personId: this.orderObject.personId });
          this.callForm.patchValue({ is_offer: this.orderObject.is_offer });
          this.callForm.patchValue({ offerId: this.orderObject.offerId });
          this.orderService.placeCallOrders(this.callForm.value).subscribe(
            (res: any) => {
              this.spinner.hide();
              if (res.success) {
                this.spinner.hide();
                this.toast.success(res.message, 'Success');
                // sessionStorage.setItem("isPromo",'false')
                this.router.navigateByUrl('/call-placed');
              } else {
                this.spinner.hide();
                this.toast.error(res.message, 'Alert');
              }
            },
            (err) => {
              if (err.error.status == 400) {
                this.placeCallWaitOrder();
              } else {
                this.toast.error(err.error.message, 'Alert');
              }
            }
          );
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /**
   * Avail Admin Promo Form
   */
  availPromoForm = new FormGroup({
    customerId: new FormControl('', [Validators.required]),
    promoId: new FormControl('', [Validators.required]),
    personId: new FormControl('', [Validators.required]),
    connect: new FormControl('', [Validators.required]),
  });

  public convertSecondstoTime(val: any) {
    var given_seconds = val;
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();
    var timeString;
    if (hours > 0) {
      timeString = minutes;
    } else {
      timeString = hours * 60;
    }
    return timeString;
  }

  /**
   * Add to waiting list
   */
  addToWaitingList() {
    this.orderService
      .placeWaitOrders(this.addToWaitingListForm.value)
      .subscribe(
        (res) => {
          this.toast.success(
            "You are added to Astrologer's waiting list",
            'Success'
          );
          return;
        },
        (err) => {
          this.toast.error('Something went wrong', 'Alert');
          return;
        }
      );
  }

  addToWaitingListForm = new FormGroup({
    customerId: new FormControl('', [Validators.required]),
    promoId: new FormControl('', [Validators.required]),
    astroId: new FormControl('', [Validators.required]),
    personId: new FormControl('', [Validators.required]),
    is_call: new FormControl(false),
    is_chat: new FormControl(false),
    is_offer: new FormControl(''),
    offerId: new FormControl(''),
    is_current_active: new FormControl(true),
  });

  finallOrderPlacement() {
    var data = JSON.parse(this.userdata.getOrderObject() || '{}');
    // return
    if (data.isAstroBusy) {
      this.addToWaitingList();
      this.router.navigateByUrl('/wait-list');
      return;
    }
    this.availPromoForm.patchValue({ personId: data.personId });
    // return
    this.orderService.availPromoOrder(this.availPromoForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success');
        if (res.success == false) {
          this.addToWaitingList();
          return;
        }
        sessionStorage.setItem('isPromo', 'false');
        if (this.isCallOrder) {
          this.spinner.hide();
          this.router.navigateByUrl('/call-placed');
          return;
        }
        if (this.isChatOrder) {
          this.spinner.hide();
          this.router.navigateByUrl('/chat-placed');
          return;
        }
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  placeAdminPromo() {
    this.spinner.show();
    // new code: testing is remaining!
    if (this.userdata.getToken() == (null || '')) {
      this.spinner.hide();
      this.toast.error('Please Login to use Service', 'Info');
      return;
    }
    if (this.userdata.getEmailVerify() == 'false') {
      this.spinner.hide();
      this.router.navigateByUrl('/verify-email-otp');
      return;
    }
    /*
     * Check if wallet is recharged after detucting reserved amount
     */
    var id = this.userdata.getId();
    this.loginService.myProfile({ _id: id }).subscribe(
      (res: any) => {
        this.userdata.setProfileData(res.data)
        /**
         * Check if user is busy
         */
        if (res.data.is_current_call || res.data.is_current_chat) {
          this.spinner.hide();
          Swal.fire(
            "Call can't be connected!",
            'Complete your ongoing order to place another one',
            'info'
          );
          return;
        }
        if (res.data.is_waiting) {
          this.spinner.hide();
          Swal.fire(
            "Order can't be placed",
            'You are already added in an astrologer waiting list.',
            'info'
          );
          return;
        }
        var data = JSON.parse(this.userdata.getOrderObject() || '{}');

        /**
         * check if admin promo service is paid
         */
        if (this.orderObject.isPaid) {
          // return
          var wallet: Number = res.data.current_wallet;
          var isOffer = false;
          var offerId = '';
          var charges = 0;
          if (this.orderObject.isPaid) {
            if (data.orderType == 'call') {
              if (
                this.astroData.astroId.offerdetail?.astroofferId == null ||
                !this.astroData.astroId.offerdetail?.astroofferId
                  ?.is_call_offer_activate ||
                this.astroData.astroId.offerdetail.call_offerId == null
              ) {
                charges = this.astroData.astroId.call_charges * 2;
              } else {
                if (this.astroData.astroId.isnewforastro) {
                  if (
                    this.astroData.astroId.offerdetail.call_offerId.user_type ==
                      1 ||
                    this.astroData.astroId.offerdetail.call_offerId.user_type ==
                      3
                  ) {
                    var charge =
                      Number(this.astroData.astroId.call_charges) * 2 -
                      Number(this.astroData.astroId.call_charges) *
                        2 *
                        (this.astroData.astroId.offerdetail.call_offerId
                          .offer_discount /
                          100);
                    charges = charge;
                  } else {
                    charges = this.astroData.astroId.call_charges * 2;
                  }
                } else {
                  if (
                    this.astroData.astroId.offerdetail.call_offerId.user_type ==
                      1 ||
                    this.astroData.astroId.offerdetail.call_offerId.user_type ==
                      2
                  ) {
                    var charge =
                      Number(this.astroData.astroId.call_charges) * 2 -
                      Number(this.astroData.astroId.call_charges * 2) *
                        (this.astroData.astroId.offerdetail.call_offerId
                          .offer_discount /
                          100);
                    charges = charge;
                  } else {
                    charges = this.astroData.astroId.call_charges * 2;
                  }
                }
              }
            }

            if (data.orderType == 'chat') {
              if (this.astroData.astroId?.isnewforastro) {
                if (
                  this.astroData.astroId?.offerdetail?.chat_offerId
                    ?.user_type == 1 ||
                  this.astroData.astroId?.offerdetail?.chat_offerId
                    ?.user_type == 3
                ) {
                  var charge =
                    Number(this.astroData.astroId.chat_charges) * 2 -
                    Number(this.astroData.astroId.chat_charges) *
                      2 *
                      (this.astroData.astroId?.offerdetail?.chat_offerId
                        ?.offer_discount /
                        100);
                  charges = charge;
                } else {
                  charges = Number(this.astroData.astroId.chat_charges) * 2;
                }
              } else {
                if (
                  this.astroData.astroId?.offerdetail?.chat_offerId
                    ?.user_type == 1 ||
                  this.astroData.astroId?.offerdetail?.chat_offerId
                    ?.user_type == 2
                ) {
                  var charge =
                    Number(this.astroData.astroId.chat_charges) * 2 -
                    Number(this.astroData.astroId.chat_charges) *
                      2 *
                      (this.astroData.astroId?.offerdetail?.chat_offerId
                        ?.offer_discount /
                        100);
                  charges = charge;
                } else {
                  charges = Number(this.astroData.astroId.chat_charges) * 2;
                }
              }
            }
          }
          var waitingAmount = res.data.waiting_amount;
          var currentWallet = res.data.current_wallet;
          var customerWalletAmount = currentWallet - waitingAmount;
          //var astroChatCharges = this.astroData.chat_charges*2//
          var astroCharges = charges; //001
          if (
            Number(wallet) <
            Number(charges) * this.convertSecondstoTime(this.astroData.duration)
          ) {
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error(
              'Your Wallet amount is not sufficient. You should have atleast' +
                this.convertSecondstoTime(this.astroData.duration) +
                'minutes calltime in your wallet.',
              'Error'
            );
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          if (currentWallet < astroCharges) {
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error('Please Recharge to place order', 'Alert');
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          if (astroCharges > customerWalletAmount) {
            this.userdata.setCurrentRoute(this.router.url);
            this.toast.error('Please Recharge to place order', 'Alert');
            this.router.navigateByUrl('/recharge-wallet');
            return;
          }
          this.finallOrderPlacement();
        }
        if (!this.orderObject.isPaid) {
          this.finallOrderPlacement();
        }
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
        return;
      }
    );

    /**
     * Check If User is busy
     */
    // this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
    //   (res: any) => {
    //     if (res.data.is_current_call || res.data.is_current_chat) {
    //       this.spinner.hide()
    //       Swal.fire("Call can't be connected", 'Complete your ongoing order to place another one', 'info')
    //       return
    //     }
    //     if (res.data.is_waiting) {
    //       this.spinner.hide()
    //       Swal.fire("Order can't be placed", 'You are already added in an astrologer waiting list.', 'info')
    //       return
    //     }
    //     /**
    //      * Place Order
    //      */
    //     this.availPromoForm.patchValue({ customerId: this.orderObject.customerId })
    //     this.availPromoForm.patchValue({ promoId: this.orderObject.promoId })
    //     this.availPromoForm.patchValue({ personId: this.orderObject.personId })
    //     this.availPromoForm.patchValue({ connect: 'call' })
    //     this.orderService.availPromoOrder(this.availPromoForm.value).subscribe(
    //       (res: any) => {
    //         this.spinner.hide()
    //         this.toast.success(res.message, 'Success')
    //         //localStorage.setItem("isPromo",'false')
    //         this.router.navigateByUrl('/call-placed')
    //       }, err => {
    //         this.spinner.hide()
    //         this.toast.error(err.error.message, 'Error')
    //       }
    //     )
    //   }, err => {
    //     this.spinner.hide()
    //     this.toast.error(err.error.message, 'Error')
    //   }
    // )
  }

  /**
   * Place Promo Report Call Order
   */
  placeReportCallOrder() {
    this.spinner.show();
    /**
     * Check If User is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.spinner.hide();
            Swal.fire(
              "Call can't be connected",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          if (res.data.is_waiting) {
            this.spinner.hide();
            Swal.fire(
              "Order can't be placed",
              'You are already added in an astrologer waiting list.',
              'info'
            );
            return;
          }
          /**
           * Place Order
           */
          var orderObject = JSON.parse(this.userdata.getOrderObject() || '{}');
          var formdata = {
            connect: 'call',
            reportId: orderObject.reportId,
            customerId: this.userdata.getId(),
          };
          this.orderService.promoReportOrders(formdata).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.toast.success(res.message, 'Success');
              this.router.navigateByUrl('/call-placed');
            },
            (err) => {
              this.spinner.hide();
              this.toast.error(err.error.message, 'Alert');
            }
          );
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /**
   * Place Promo Call order
   */
  placeCallPromoOrder() {
    //Five minute free call - waitlist not applicable
    /**
     * Check If User is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.spinner.hide();
            Swal.fire(
              "Call can't be connected",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          if (res.data.is_waiting) {
            this.spinner.hide();
            Swal.fire(
              "Order can't be placed",
              'You are already added in an astrologer waiting list.',
              'info'
            );
            return;
          }
          /**
           * Place Order
           */
          var data = JSON.parse(this.userdata.getOrderObject() || '{}');
          var formData = {
            customerId: data.customerId,
            personId: data.personId,
            is_promotional_call: true,
          };
          this.orderService.placeCallPromoOrders(formData).subscribe(
            (res: any) => {
              if (res.success) {
                this.spinner.hide();
                this.toast.success(res.message, 'Success');
                // sessionStorage.setItem("isPromo",'false')
                this.router.navigateByUrl('/call-placed');
              } else {
                this.spinner.hide();
                this.toast.error(res.message, 'Alert');
              }
            },
            (err) => {
              this.spinner.hide();
              this.toast.error(err.error.message, 'Alert');
            }
          );
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  /**
   * Place Wait Call Order
   */
  placeCallWaitOrder() {
    this.spinner.show();
    /**
     * Check If User is busy
     */
    this.loginService
      .myProfile({ _id: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.spinner.hide();
            Swal.fire(
              "Call can't be connected",
              'Complete your ongoing order to place another one',
              'info'
            );
            return;
          }
          if (res.data.is_waiting) {
            this.spinner.hide();
            Swal.fire(
              'Unable to place call-wait order.',
              "You have already joined an Astrologer's waiting list.",
              'error'
            );
            return;
          }
          /**Check Wallet amount */
          var charges = 0;
          if (
            this.astroData.astroofferId == null ||
            !this.astroData.astroofferId?.is_call_offer_activate ||
            this.astroData.callofferis == null
          ) {
            charges = this.astroData.call_charges * 2;
          } else {
            if (this.astroData.isnewforastro) {
              if (
                this.astroData.callofferis.user_type == 1 ||
                this.astroData.callofferis.user_type == 3
              ) {
                charges =
                  this.astroData.call_charges * 2 -
                  this.astroData.call_charges *
                    2 *
                    (this.astroData.callofferis.offer_discount / 100);
              } else {
                charges = this.astroData.call_charges * 2;
              }
            } else {
              if (
                this.astroData.callofferis.user_type == 1 ||
                this.astroData.callofferis.user_type == 2
              ) {
                charges =
                  this.astroData.call_charges * 2 -
                  this.astroData.call_charges *
                    2 *
                    (this.astroData.callofferis.offer_discount / 100);
              } else {
                charges = this.astroData.call_charges * 2;
              }
            }
          }
          var waitingAmount = res.data.waiting_amount;
          var currentWallet = res.data.current_wallet;
          var customerWalletAmount = currentWallet - waitingAmount;
          var astroCharges = charges * 5; //001 05-08-2021
          if (currentWallet < astroCharges) {
            this.spinner.hide();
            //this.userdata.setCurrentRoute(this.router.url)
            this.toast.error('Please Recharge to place order', 'Alert');
            //this.router.navigateByUrl('/recharge-wallet')
            return;
          }
          if (astroCharges > customerWalletAmount) {
            this.spinner.hide();
            //this.userdata.setCurrentRoute(this.router.url)
            this.toast.error('Please Recharge to place order', 'Alert');
            //this.router.navigateByUrl('/recharge-wallet')
            return;
          }

          /**
           * Place Call Wait Order
           */
          var data = JSON.parse(this.userdata.getOrderObject() || '{}');
          this.openService
            .singleAstrologerDetails({ _id: data.astroId })
            .subscribe(
              (res: any) => {
                var formData = {
                  customerId: data.customerId,
                  astroId: data.astroId,
                  personId: data.personId,
                  is_current_active: res.data.is_current_active,
                  is_chat: false,
                  is_call: true,
                  is_offer: data.is_offer,
                  offerId: data.offerId,
                };
                this.orderService.placeWaitOrders(formData).subscribe(
                  (res: any) => {
                    this.spinner.hide();
                    this.toast.success(res.message, 'Success');
                    this.router.navigateByUrl('/wait-list');
                  },
                  (err) => {
                    this.spinner.hide();
                    this.toast.error(err.error.message, 'Alert');
                  }
                );
              },
              (err) => {
                this.spinner.hide();
                this.toast.error('Astrologer Not Available', 'Alert');
                this.router.navigateByUrl('/');
              }
            );
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }
}
