import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-verify-phone-otp',
  templateUrl: './verify-phone-otp.component.html',
  styleUrls: ['./verify-phone-otp.component.css']
})
export class VerifyPhoneOtpComponent implements OnInit {

  id: any = ''; 
  constructor(private router:Router,private loginService: LoginService, private toast: ToastrService, private spinner: NgxSpinnerService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
  }

  onOtpChange(event:any){
    if(event.length == 4){
      this.loginService.verifyMobileOtp({email:this.id,otp:event}).subscribe(
        (res:any)=>{
          localStorage.setItem("isPhoneVerify",'true')
          this.toast.success(res.message,'Success')
          this.router.navigateByUrl('/')
        },err =>{
          this.toast.error(err.error.message,'Error')
        }
      )
    }
  }

  resendOtp(){
    this.loginService.resendMobileOtp({email:this.id}).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
