import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { ChatBotHeaderService } from 'src/app/service/chat-bot/chat-bot-header.service';
import { LoginService } from 'src/app/service/login/login.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(-100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  ordeServiceId = '';
  supportId = '';
  isLogin = false;
  isActive = false;
  currentURL: any = '';
  closeResult: string = '';
  walletBalance = 0;
  userName = 'Guest';
  isChatGoing = false;
  isSupportGoing = false;
  BaseUrl = '';
  constructor(
    private userdataService: UserdataService,
    private toast: ToastrService,
    private loginService: LoginService,
    private router: Router,
    private modalService: NgbModal,
    private authService: SocialAuthService,
    private chatBot: ChatBotHeaderService,
    private orderService: OrderServicesService,
    private trusturl: DomSanitizer,
    private datePipe: DatePipe,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  selectedLanguage = '';
  selectedCurrency = '';
  cur = false;
  lang = false;
  i = 0;
  getAstroName(astro: any): string {
    if (
      astro.displayname != null &&
      typeof astro.displayname != 'undefined' &&
      astro.displayname.length > 0
    )
      return astro.displayname;
    else return astro.name;
  }
  getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }
  gotToWaiting() {
    this.router.navigate(['/wait-list']);
  }
  ngOnInit(): void {
    this.loadScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    this.chatBot.fire.subscribe((id) => {
      if (id != null) {
        //console.log('if')
        console.log("isChat going in chat*****************")
        this.isChatGoing = true;
        this.ordeServiceId = id;
        localStorage.setItem('orderServiceID', id);
      }
    });
    this.chatBot.support.subscribe((id) => {
      if (id != null) {
        this.isSupportGoing = true;
        this.supportId = id;
        localStorage.setItem('supportID', id);
      }
    });
    const serviceId = localStorage.getItem('orderServiceID');
    if (typeof serviceId != 'undefined' && serviceId != null) {
      this.isChatGoing = true;
      this.ordeServiceId = serviceId;
    }
    const supportID = localStorage.getItem('supportID');
    if (typeof supportID != 'undefined' && supportID != null) {
      this.isSupportGoing = true;
      this.supportId = supportID;
    }
    this.currentURL = this.router.url;
    this.isLogin = this.userdataService.isLogin();
    this.walletBalance = Number(this.userdataService.getCurrentWallet() ?? '0');
    if (this.userdataService.isLogin()) {
      this.userName = this.userdataService.getName();
      this.getWaitingList();
      this.getMyProfile();
    }
  }
  isGoingOn = false;
  message = '';
  closeSupport() {
    this.isSupportGoing = false;
    localStorage.removeItem('supportID');
  }
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    is_terms_accepted: new FormControl(true, [Validators.requiredTrue]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
    is_social_login: new FormControl(true),
    social_id: new FormControl(''),
  });
  getMyProfile() {
    this.loginService
      .myProfile({ _id: this.userdataService.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdataService.setProfileData(res.data)
          //console.log('header', res);
          if (!res.data.is_otp_verified) {
            this.router.navigate(['/verify-phone-otp', res.data.email]);
            return;
          }
          if (res.data.is_current_call || res.data.is_current_chat) {
            this.isGoingOn = true;
            this.message = 'A ';
            if (res.data.is_current_call) this.message += 'call';
            else {
              this.message += 'chat';
              this.isChatGoing = true;
              this.ordeServiceId = res.data.currentchatserviceId;

              localStorage.setItem(
                'orderServiceID',
                res.data.currentchatserviceId
              );
            }
            this.message += ' order is currently active. Please, complete your order.';
          } else {
            console.log("isChat going set to false*****************")
            this.isChatGoing = false;
            this.ordeServiceId = '';
            this.userdataService.setIsCallGoing(res.data.is_current_call);
            this.userdataService.setIsChatGoing(res.data.is_current_chat);
          }
        },
        (err) => {
          this.toast.error(err.error.message, 'Alert');
          this.router.navigateByUrl('/home');
        }
      );
  }
  get f() {
    return this.loginForm.controls;
  }
  toggleView = false;
  toggleButton() {
    this.toggleView = !this.toggleView;
  }
  onLogin() {
    this.loginService.login(this.loginForm.value).subscribe(
      (res: any) => {
        this.userdataService.setData(res);

        if (!res.customerdetail.is_otp_verified) {
          this.router.navigate(['/verify-phone-otp', res.data.email]);
        } else {
          this.toast.success('Login Successful', 'Success');
          this.isLogin = this.userdataService.isLogin();
          if (this.currentURL == '/') {
            this.router.navigateByUrl('/home');
          } else {
            this.reloadCurrentRoute();
          }
        }
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

  logout() {
    this.loginService
      .logout({
        is_web: true,
        email: this.userdataService.getEmail(),
        fcm_token: this.userdataService.getFcm(),
      })
      .subscribe(
        (res: any) => {
          this.userdataService.removeData();
          this.toast.success(res.message, 'Success');
          window.location.reload();
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        },
        () => { }
      );
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  /**
   * signIn/signUp model popup
   */
  @ViewChild("login") modalContent: TemplateRef<any> | undefined;
  openFromAnotherComponent() {
    this.openModal(this.modalContent)
  }
  openModal(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  open(content: any) {
    this.modalService.open(content, { windowClass: 'loginModal' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  /**
   * Login for Mobile view
   */
  /**
   * Login with Google
   */
  public signInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((data) => {
        this.loginForm.patchValue({ social_id: data.id });
        this.loginForm.patchValue({ password: data.id });
        this.signOut();
        this.onLogin();
      })
      .catch((err: any) => {
        //console.log(err);
      });
  }

  /**
   * Register for Mobile view
   */
  /**
   * Register with Google
   */
  registerData: any = '';
  public signUpWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((data) => {
        this.registerData = data;
        this.signOut();
      })
      .catch((err: any) => {
        //console.log(err);
      });
  }
  public signOut(): void {
    this.authService.signOut(true);
  }
  isExpanded = false;
  toggleChatBot() {
    this.isExpanded = !this.isExpanded;
  }
  waitingList = new Array();
  getWaitingList() {
    this.orderService
      .showWaitingList({
        customerId: this.userdataService.getCustomerId(),
        is_user_notified: false,
      })
      .subscribe({
        next: (res) => {
          this.waitingList = res.data;
        },
        error: (err) => {
          this.toast.error(err.error.message);
        },
        complete: () => { },
      });
  }
  public convertSecondstoTime(val: any) {
    return this.datePipe.transform(val * 1000, 'mm:ss');
  }

  public loadScript(url: string) {
    //console.log('ghghg')
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  onWindowScroll(event: any) {
    //console.log('yes')
    //console.log(event)
  }
}
