<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Blogs</h3>
            <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                </li>
                <li>
                    <span class="fn-2 material-icons-round bottom-align">
                        arrow_forward_ios
                    </span>
                </li>

                <li>Blogs</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<!-- hs sidebar Start -->
<section class="mt-lg-5 mt-3">
    <div class="container">
        <div class="row">
            <div class="col-3 d-none d-lg-block">
                <div class="list-group card bg-active p-0 h-auto">
                    <ul class="mall-category">
                        <li>
                            <span class="list-group-item list-group-item-action bg-green fw-500 text-white text-center"
                                aria-current="true">
                                <i class="fas fa-th-large float-left"></i>Blog Categories
                            </span>
                        </li>
                        <li (click)="blogByCategory('')" class="category-list">
                            <a class="text-black-50 align-items-center collapsed text-capitalize">All</a>
                        </li>
                        <ng-container *ngIf="blogCategory">
                            <li (click)="blogByCategory(category?._id)" class="category-list"
                                *ngFor="let category of blogCategory; let i = index">
                                <a class="text-black-50 align-items-center collapsed text-capitalize">{{
                                    category?.categoryname }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="rounded">
                    <div class="hs_blog_right_search_wrapper s-bar-bg mb-3">
                        <input type="text" #searchInput placeholder="Search" />
                        <button type="submit" (click)="searchblog(searchInput.value)">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <!-- my code wiil be here -->
                <div class="container p-0">
                    <div class="row">

                        <!-- no data available -->
                        <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3" *ngIf="blogs.length == 0">
                            <section class="container  order-bg1 mt-5 mb-5 py-3">
                                <div class=" row n-data-img justify-content-center">
                                    <img src="../../../../assets/images/no-data.svg" />
                                </div>
                                <div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-6 mb-3" *ngFor="let blog of blogs">
                            <!-- card start here -->
                            <div class="card shadow border-0">
                                <img [src]="getSanitizeUrl(blog?.blogImage)" alt="blog_img" class="p-2 card-img-top">
                                <div class="card-body glob-blog">
                                    <div class="row d-flex justify-content-between">
                                        <p class="col-8 card-title font-weight-semibold" [innerHTML]="blog?.blogTitle">
                                        </p>
                                        <span class="col-4 p-0 font-weight-bold">{{blog?.created_at |
                                            date:'mediumDate'}}</span>
                                    </div>
                                    <div class = "small text-muted">
                                       ({{blog?.userName}})
                                    </div>
                                    <p [title]="blog?.blogDescription" class="card-text text-muted line-size-3"
                                        [innerHTML]="blog?.blogsmallDescription">
                                    </p>
                                    <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                                    <a class="btn btn-primary bg-secondary border-0 rounded mt-2"
                                        [routerLink]="['/blog/', blog?._id]">Read
                                        More</a>
                                </div>
                            </div>
                            <!-- card end here -->
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</section>
<app-footer></app-footer>