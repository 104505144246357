<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Place order</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Place order</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row card p-3 shadow mb-5" *ngIf="isChatOrder">
      <div class="d-flex">
        <h4 class="mb-2 flex-grow-1">Choose Profile</h4>
        <a (click)="showAddPersonForm()" class="text-capitalize green-text"><i
            class="material-icons-round center-align"> add_circle_outline </i>Add new Profile</a>
      </div>
      <table class="table border rounded">
        <ng-container *ngFor="let data of peopleList">
          <tr *ngIf="!data.is_hide">
            <th class="w-100">{{ data.name }}</th>
            <td>
              <button class="
                  btn
                  px-5
                  shadow-0
                  py-2
                  bg-secondary-2
                  text-body
                  rounded
                  m-0
                  fw-500
                  rounded
                " (click)="chatOrderSelected(data._id)">
                Select
              </button>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="row card  border-2 p-3 shadow mb-5" *ngIf="isChatPromo">
      <div class="d-flex">
        <h4 class="mb-2 flex-grow-1">Choose Profile</h4>
        <a (click)="showAddPersonForm()" class="text-capitalize green-text"><i class="material-icons-round">
            add_circle_outline </i>Add new
          Profile</a>
      </div>
      <table class="table border rounded">
        <ng-container *ngFor="let data of peopleList">
          <tr *ngIf="!data.is_hide">
            <th class="w-100">{{ data.name }}</th>
            <td>
              <button class="
                  btn
                  px-5
                  py-2
                  bg-secondary-2
                  text-body
                  rounded
                  m-0
                  shadow-0
                  fw-500
                  rounded
                " (click)="chatPromoOrderSelected(data._id)">
                Select
              </button>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="row border p-3 rounded" *ngIf="isChatOrder && isPersonSelectedChat">
      <!-- update selected user profile -->
      <form [formGroup]="updatePersonForm">
        <div class="form-row">
          <div class="form-group col-md-6 cus-border-bottom">
            <h4>Person Profile</h4>
          </div>
          <div class="form-group col-md-6">
            <button type="submit" class="
                btn
                float-right
                bg-secondary
                shadow-0
                px-3
                py-2
                m-0
                text-white
                fw-500
                rounded
              " [disabled]="!updatePersonForm.valid" (click)="onUpdatePersonHandler()">
              Update Profile
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputAddress">Name *</label>
            <input type="text" class="form-control" formControlName="name" />
            <div *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress2">Gender *</label><br />
            <div class="custom-control-inline">
              <input type="radio" value="male" formControlName="gender" />&nbsp;
              <label class="m-0"> Male</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" value="female" formControlName="gender" />&nbsp;
              <label class="m-0"> Female</label>
            </div>
            <div *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputCity">Country Code *</label>
            <!-- <select class="form-control" formControlName="country_code">
              <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                {{ country.emoji }}&nbsp;&nbsp;{{
                country.name
                }}&nbsp;&nbsp;(+{{ country.phone_code }})
              </option>
            </select> -->
            <ng-select [searchFn]="customSearchFn" formControlName="country_code"
              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
              bindValue="phone_code">
              <ng-template ng-option-tmp let-item="item">
                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                item.name
                }}&nbsp;&nbsp;(+{{ item.phone_code }})
              </ng-template>
            </ng-select>
            <div *ngIf="
                u.country_code.invalid &&
                (u.country_code.dirty || u.country_code.touched)
              " class="alert alert-danger mt-1">
              Required.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="inputState">Mobile Number *</label>
            <input type="text" class="form-control" formControlName="mobile" id="inputZip" />
            <div *ngIf="u.mobile.invalid && (u.mobile.dirty || u.mobile.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Marital Status *</label>
            <select class="form-control" formControlName="marital_status">
              <option value="" selected disabled>Select Status</option>
              <option>Single</option>
              <option>Married</option>
              <option>Widowed</option>
              <option>Divorced</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Employed-In *</label>
            <select class="form-control" formControlName="employed_in">
              <option value="" selected disabled>Select Occupation</option>
              <option>Private Job</option>
              <option>Govt Job</option>
              <option>Not Employed</option>
              <option>Student</option>
              <option>None of the above</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Date Of Birth *</label>
            <input type="date" class="form-control" formControlName="dob" [max]="today" (keydown)="disableDate()" />
            <div *ngIf="u.dob.invalid && (u.dob.dirty || u.dob.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Time Of Birth *</label>
            <input type="time" class="form-control" formControlName="tob" />
            <div *ngIf="u.tob.invalid && (u.tob.dirty || u.tob.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Place Of Birth (City) *</label>
            <input type="text" class="form-control" formControlName="city" />
            <div *ngIf="u.city.invalid && (u.city.dirty || u.city.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">State *</label>
            <input type="text" class="form-control" formControlName="state" />
            <div *ngIf="u.state.invalid && (u.state.dirty || u.state.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="inputZip">Country *</label>
            <input type="text" class="form-control" formControlName="country" />
            <div *ngIf="
                u.country.invalid && (u.country.dirty || u.country.touched)
              " class="alert alert-danger mt-1"></div>
          </div>
          <div class="form-group col-md-12">
            <label for="inputEmail4">Link Partner's Details? *</label><br />
            <div class="custom-control-inline">
              <input type="radio" value="true" formControlName="is_partner_details"
                (change)="onChangePartner($event)" />&nbsp;
              <label class="m-0"> Yes</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" value="false" formControlName="is_partner_details"
                (change)="onChangePartner($event)" />&nbsp;
              <label class="m-0"> No</label>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="updatePartnerDetails">
          <div class="form-group col-md-6">
            <label for="inputPassword4">Partner's Name</label>
            <input type="text" class="form-control" formControlName="partner_name" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputPassword4">Partner's Date Of Birth</label>
            <input type="date" class="form-control" formControlName="partner_dob" [max]="today"
              (keydown)="disableDate()" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's Time Of Birth</label>
            <input type="time" class="form-control" formControlName="partner_tob" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's Birth City</label>
            <input type="text" class="form-control" formControlName="partner_city" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's State</label>
            <input type="text" class="form-control" formControlName="partner_state" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's Country</label>
            <input type="text" class="form-control" formControlName="partner_country" />
          </div>
        </div>
      </form>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Mention your problem</td>
            <td>
              <form [formGroup]="chatForm">
                <textarea formControlName="comments" placeholder="Comment" class="form-control"></textarea>
              </form>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colspan="2">
              <button type="button" class="
                  btn
                  bg-secondary
                  shadow-0
                  px-3
                  py-2
                  m-0
                  text-white
                  fw-500
                  rounded
                " [disabled]="!chatForm.valid" *ngIf="!isWait" (click)="placeChatOrder()">
                Place Order
              </button>
              <button type="button" class="
                  btn
                  bg-secondary
                  shadow-0
                  px-3
                  py-2
                  m-0
                  text-white
                  fw-500
                  rounded
                " [disabled]="!waitForm.valid" *ngIf="isWait" (click)="placeWaitOrder()">
                Place Order
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row border p-3 rounded" *ngIf="isChatPromo && isPersonSelectedChatPromo">
      <form [formGroup]="updatePersonForm">
        <div class="form-row">
          <div class="form-group col-md-6 cus-border-bottom">
            <h4 class="fw-500">Person Profile</h4>
          </div>
          <div class="form-group col-md-6">
            <button type="submit" class="
                btn
                float-right
                bg-secondary
                shadow-0
                px-3
                py-2
                m-0
                text-white
                fw-500
                rounded
              " [disabled]="!updatePersonForm.valid" (click)="onUpdatePersonHandler()">
              Update Profile
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputAddress">Name *</label>
            <input type="text" class="form-control" formControlName="name" />
            <div *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress2">Gender *</label><br />
            <div class="custom-control-inline">
              <input type="radio" value="male" formControlName="gender" />&nbsp;
              <label class="m-0"> Male</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" value="female" formControlName="gender" />&nbsp;
              <label class="m-0"> Female</label>
            </div>
            <div *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
        </div>
        <!-- <option data-countryCode="IN" value="91">India (+91)</option> -->
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputCity">Country Code *</label>
            <!-- <select class="form-control" formControlName="country_code">
              <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
                {{ country.emoji }}&nbsp;&nbsp;{{
                country.name
                }}&nbsp;&nbsp;(+{{ country.phone_code }})
              </option>
            </select> -->
            <ng-select [searchFn]="customSearchFn" formControlName="country_code"
              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
              bindValue="phone_code">
              <ng-template ng-option-tmp let-item="item">
                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                item.name
                }}&nbsp;&nbsp;(+{{ item.phone_code }})
              </ng-template>
            </ng-select>
            <div *ngIf="
                u.country_code.invalid &&
                (u.country_code.dirty || u.country_code.touched)
              " class="alert alert-danger mt-1">
              Required.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="inputState">Mobile Number *</label>
            <input type="text" class="form-control" formControlName="mobile" id="inputZip" />
            <div *ngIf="u.mobile.invalid && (u.mobile.dirty || u.mobile.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Marital Status *</label>
            <select class="form-control" formControlName="marital_status">
              <option value="" selected disabled>Select Status</option>
              <option>Single</option>
              <option>Married</option>
              <option>Widowed</option>
              <option>Divorced</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Employed-In *</label>
            <select class="form-control" formControlName="employed_in">
              <option value="" selected disabled>Select Occupation</option>
              <option>Private Job</option>
              <option>Govt Job</option>
              <option>Not Employed</option>
              <option>Student</option>
              <option>None of the above</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Date Of Birth *</label>
            <input type="date" class="form-control" formControlName="dob" [max]="today" (keydown)="disableDate()" />
            <div *ngIf="u.dob.invalid && (u.dob.dirty || u.dob.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Time Of Birth *</label>
            <input type="time" class="form-control" formControlName="tob" />
            <div *ngIf="u.tob.invalid && (u.tob.dirty || u.tob.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Place Of Birth (City) *</label>
            <input type="text" class="form-control" formControlName="city" />
            <div *ngIf="u.city.invalid && (u.city.dirty || u.city.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">State *</label>
            <input type="text" class="form-control" formControlName="state" />
            <div *ngIf="u.state.invalid && (u.state.dirty || u.state.touched)" class="alert alert-danger mt-1">
              Required.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputZip">Country *</label>
            <input type="text" class="form-control" formControlName="country" />
            <div *ngIf="
                u.country.invalid && (u.country.dirty || u.country.touched)
              " class="alert alert-danger mt-1"></div>
          </div>
          <div class="form-group col-md-12">
            <label for="inputEmail4">Is Partner's Details? *</label><br />
            <div class="custom-control-inline">
              <input type="radio" value="true" formControlName="is_partner_details"
                (change)="onChangePartner($event)" />&nbsp;
              <label class="m-0"> Yes</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" value="false" formControlName="is_partner_details"
                (change)="onChangePartner($event)" />&nbsp;
              <label class="m-0"> No</label>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="updatePartnerDetails">
          <div class="form-group col-md-12">
            <label for="inputPassword4">Partner's Name</label>
            <input type="text" class="form-control" formControlName="partner_name" />
          </div>
          <div class="form-group col-md-12">
            <label for="inputPassword4">Partner's Date Of Birth</label>
            <input type="date" class="form-control" formControlName="partner_dob" [max]="today" />
          </div>
          <div class="form-group col-md-12">
            <label for="inputAddress">Partner's Time Of Birth</label>
            <input type="time" class="form-control" formControlName="partner_tob" />
          </div>
          <div class="form-group col-md-12">
            <label for="inputAddress">Partner's Birth City</label>
            <input type="text" class="form-control" formControlName="partner_city" />
          </div>
          <div class="form-group col-md-12">
            <label for="inputAddress">Partner's State</label>
            <input type="text" class="form-control" formControlName="partner_state" />
          </div>
          <div class="form-group col-md-12">
            <label for="inputAddress">Partner's Country</label>
            <input type="text" class="form-control" formControlName="partner_country" />
          </div>
        </div>
      </form>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Mention your problem</td>
            <td>
              <form [formGroup]="chatForm">
                <textarea formControlName="comments" placeholder="Comment" class="form-control"></textarea>
              </form>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colspan="2">
              <button type="button" class="
                  btn
                  bg-secondary
                  shadow-0
                  px-3
                  py-2
                  m-0
                  text-white
                  fw-500
                  rounded
                " [disabled]="!chatPromoForm.valid && !chatForm.valid" (click)="placeChatPromoOrder()">
                Place Order
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row border p-3 rounded" *ngIf="isAddNewPerson">
      <div class="form-group col-md-6 cus-border-bottom">
        <h4 class="fw-500">Add Profile</h4>
      </div>
      <form [formGroup]="personForm" class="row m-0">
        <div class="form-group col-md-12">
          <div class="custom-control-inline">
            <input type="radio" value="true" formControlName="is_self_profile" />&nbsp;
            <label class="m-0"> Self</label>
          </div>
          <div class="custom-control-inline">
            <input type="radio" id="customRadioInline1" value="false" formControlName="is_self_profile" />&nbsp;
            <label class="m-0"> Other</label>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="inputPassword4">Profile Name</label>
          <input type="text" class="form-control" formControlName="detail_name"
            placeholder="Eg. Mother, Father, Daughter, Son etc." />
        </div>
        <div class="form-group col-md-6">
          <label for="inputAddress">Name</label>
          <input type="text" class="form-control" formControlName="name" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputAddress2">Gender</label><br />
          <div class="form-group col-md-12">
            <div class="custom-control-inline">
              <input type="radio" value="male" formControlName="gender" />&nbsp;
              <label class="m-0"> Male</label>
            </div>
          </div>
          <div class="custom-control-inline">
            <input type="radio" id="customRadioInline1" value="female" formControlName="gender" />&nbsp;
            <label class="m-0"> Female</label>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="inputCity">Country Code</label>
          <!-- <select class="form-control" formControlName="country_code">
            <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
              {{ country.emoji }}&nbsp;&nbsp;{{ country.name }}&nbsp;&nbsp;(+{{
              country.phone_code
              }})
            </option>
          </select> -->
          <ng-select [searchFn]="customSearchFn" formControlName="country_code"
            class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
            bindValue="phone_code">
            <ng-template ng-option-tmp let-item="item">
              <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
              item.name
              }}&nbsp;&nbsp;(+{{ item.phone_code }})
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group col-md-6">
          <label for="inputState">Mobile Number</label>
          <input type="text" class="form-control" formControlName="mobile" id="inputZip" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">Marital Status</label>
          <select class="form-control" formControlName="marital_status">
            <option value="" selected disabled>Select Status</option>
            <option>Single</option>
            <option>Married</option>
            <option>Widowed</option>
            <option>Divorced</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">Employed-In</label>
          <select class="form-control" formControlName="employed_in">
            <option value="" selected disabled>Select Occupation</option>
            <option>Private Job</option>
            <option>Govt Job</option>
            <option>Not Employed</option>
            <option>Student</option>
            <option>None of the above</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">Date Of Birth</label>
          <input type="date" class="form-control" formControlName="dob" [max]="today" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">Time Of Birth</label>
          <input type="time" class="form-control" formControlName="tob" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">Place Of Birth (City)</label>
          <input type="text" class="form-control" formControlName="city" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">State</label>
          <input type="text" class="form-control" formControlName="state" />
        </div>
        <div class="form-group col-md-6">
          <label for="inputZip">Country</label>
          <input type="text" class="form-control" formControlName="country" />
        </div>
        <div class="form-group col-md-12">
          <label for="inputEmail4">Is Partner's Details?</label><br />
          <div class="custom-control-inline">
            <input type="radio" value="true" formControlName="is_partner_details" (change)="onChange($event)" />&nbsp;
            <label class="m-0"> Yes</label>
          </div>
          <div class="custom-control-inline">
            <input type="radio" id="customRadioInline1" value="false" formControlName="is_partner_details"
              (change)="onChange($event)" />&nbsp;
            <label class="m-0"> No</label>
          </div>
        </div>
        <div class="form-row" *ngIf="partnerDetails">
          <div class="form-group col-md-6">
            <label for="inputPassword4">Partner's Name</label>
            <input type="text" class="form-control" formControlName="partner_name" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputPassword4">Partner's Date Of Birth</label>
            <input type="date" class="form-control" formControlName="partner_dob" [max]="today" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's Time Of Birth</label>
            <input type="time" class="form-control" formControlName="partner_tob" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's Birth City</label>
            <input type="text" class="form-control" formControlName="partner_city" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's State</label>
            <input type="text" class="form-control" formControlName="partner_state" />
          </div>
          <div class="form-group col-md-6">
            <label for="inputAddress">Partner's Country</label>
            <input type="text" class="form-control" formControlName="partner_country" />
          </div>
        </div>
      </form>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Mention your problem</td>
            <td>
              <form [formGroup]="chatForm">
                <textarea formControlName="comments" placeholder="Comment" class="form-control"></textarea>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" class="
          btn
          bg-secondary
          shadow-0
          px-3
          py-2
          m-0
          text-white
          fw-500
          rounded
        " [disabled]="!personForm.valid || !chatForm.valid" (click)="addPerson()" *ngIf="isChatOrder">
        Save
      </button>
      <button type="submit" class="
          btn
          bg-secondary
          shadow-0
          px-3
          py-2
          m-0
          text-white
          fw-500
          rounded
        " [disabled]="!personForm.valid || !chatForm.valid" (click)="addPerson()" *ngIf="isChatPromo">
        Save
      </button>
    </div>
  </div>
</section>
<app-footer></app-footer>