import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueService } from 'src/app/service/issue/issue.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-store-order-detail',
  templateUrl: './store-order-detail.component.html',
  styleUrls: ['./store-order-detail.component.css']
})
export class StoreOrderDetailComponent implements OnInit {

  dataList: any = ''
  id:any = ''
  /**
   *
   * Collapse
   */
  public isPersonCollapsed = true;
  public isIssueGenerateCollapsed = true;
  public isIssueCollapsed = true;
  public isRemedyCollapsed = true;
  /**------------------ */
  constructor(
    private orderService: OrderServicesService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private issueService: IssueService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.detailStoreOrder()
  }

  serverTimeFormat:any;
  detailStoreOrder() {
    this.orderService.detailStoreOrders({ customerId: this.userdata.getCustomerId(), _id: this.id }).subscribe(
      (res: any) => {
        this.dataList = res.data
        var serverTime = moment(res.server_current_time)
        this.serverTimeFormat = serverTime.utc().format('YYYY-MM-DD')
        // console.log(res)
      }, err => {
        this.router.navigateByUrl('/store-order-list')
      }
    )
  }

  /**
   * Check Seven days
   */
  checkSevenDays(val:any) {
    var str = val;
    var date = moment(str);
    //var strend = '2021-05-01T04:28:41.058Z';
    //var dateEnd = moment()
    var startdateComponent = date.utc().format('YYYY-MM-DD');
    var enddateComponent = moment().utc().format('YYYY-MM-DD');
    //console.log(startdateComponent,enddateComponent);
    var startDate = moment(startdateComponent, 'YYYY-MM-DD');
    var endDate = moment(this.serverTimeFormat, 'YYYY-MM-DD');
    var dayDiff = endDate.diff(startDate, 'days');
    if (dayDiff <= 7) {
      return true
    } else {
      return false
    }
  }

  changeReview = false
  showReviewForm = false


  updateReview() {
    this.changeReview = !this.changeReview
  }

  showReviewFormDetails() {
    this.showReviewForm = !this.showReviewForm
  }

  /**
   * Review
   */
  reviewForm: FormGroup = new FormGroup({
    orderId: new FormControl(''),
    productId: new FormControl(''),
    customerId: new FormControl(''),
    // customerReviewStars: new FormControl(4, [Validators.required]),
    customerReviewComments: new FormControl('', [Validators.required]),
  });

  get r() {
    return this.reviewForm.controls
  }

  giveReview() {
    var msg = (this.reviewForm.get('customerReviewComments')?.value).trim()
    var count = (msg.match(/\d/g) || []).length
    var emailsArray = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    if (count == 10 || (emailsArray != null && emailsArray.length)) {
      Swal.fire('Invalid Review', "This message cannot be sent as our policy doesn't allows to share number or email", 'error')
      return;
    }
    this.reviewForm.patchValue({ customerReviewComments: msg })
    this.spinner.show()
    this.reviewForm.patchValue({ orderId: this.dataList._id })
    this.reviewForm.patchValue({ productId: this.dataList.productId?._id})
    this.reviewForm.patchValue({ customerId: this.userdata.getCustomerId() })
    this.orderService.reviewStoreOrders(this.reviewForm.value).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.toast.success(res.message, 'Success')
        this.changeReview = false
        this.detailStoreOrder()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Error')
      }
    )
  }
  currentRate = 4

  /**
   * Issue
   */
  issueForm: FormGroup = new FormGroup({
    is_order_issue: new FormControl(true, [Validators.required]),
    orderId: new FormControl(''),
    customerId: new FormControl(''),
    issue_type: new FormControl('3', [Validators.required]),//1=Wallet Issue, 2=Service Issue, 3=Order Issue
    issue_title: new FormControl('', [Validators.required]),
    issue_description: new FormControl('', [Validators.required])
  });

  get i() {
    return this.issueForm.controls
  }

  addIssue() {
    this.issueForm.patchValue({ customerId: this.userdata.getCustomerId() })
    this.issueForm.patchValue({ orderId: this.dataList._id })
    this.issueService.addIssue(this.issueForm.value).subscribe(
      (res: any) => {
        this.detailStoreOrder()
        this.toast.success(res.message, 'Success')
        this.modalService.dismissAll()
      }, err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  /**
   * Cancel Order
   */
  cancelOrderForm:FormGroup = new FormGroup({
    _id : new FormControl(''),
    updatedId : new FormControl(''),
    orderStatus : new FormControl('6'),
    updatedBy : new FormControl(''),
    userComments : new FormControl('',[Validators.required]),
  })

  get c() {
    return this.cancelOrderForm.controls
  }

  cancelOrder() {
    this.spinner.show()
    this.cancelOrderForm.patchValue({_id:this.dataList._id})
    this.cancelOrderForm.patchValue({updatedId:this.userdata.getCustomerId()})
    this.cancelOrderForm.patchValue({updatedBy:this.userdata.getName()})
    this.orderService.cancelStoreOrders(this.cancelOrderForm.value).subscribe(
      (res:any)=>{
        this.detailStoreOrder()
        this.spinner.hide()
        this.toast.success(res.message,'Success')
      },err=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  /** Open Feedback modal */
  open(content: any, data: any) {
    this.modalService.open(content);
  }

}
