import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RemedyService } from 'src/app/service/remedy/remedy.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-list-all-remedy',
  templateUrl: './list-all-remedy.component.html',
  styleUrls: ['./list-all-remedy.component.css']
})
export class ListAllRemedyComponent implements OnInit {
  showSuggested:boolean = true;dataList:any;code:any;
  BaseUrl: string;
  id: any;
  constructor(private userdata:UserdataService, private remedyService: RemedyService, private spinner: NgxSpinnerService, private toast:ToastrService, private route:ActivatedRoute, private trusturl: DomSanitizer,@Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.code = this.userdata.isIndia();
    this.getAllRemedy()
    // this.getPurchasedRemedy()
    // this.getSuggestedRemedy()
  }

  getAllRemedy(){
    this.spinner.show()
    this.remedyService.getAllRemedy({customerId: this.userdata.getCustomerId(),_id:this.id}).subscribe(
      (res:any) =>{
        this.spinner.hide()
        this.dataList = res.data
        var notBought:any = this.dataList.remedysuggestIds.filter((x:any)=> x.remedyId?.isSuggestedProductBought == false) 
        var bought:any = this.dataList.remedysuggestIds.filter((x:any)=> x.remedyId?.isSuggestedProductBought == true)
        this.totalSuggestedRemedy = notBought.length
        this.totalPurchasedRemedy = bought.length
      },(err:any)=>{
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  totalSuggestedRemedy = 0;
  // getSuggestedRemedy(){
  //   this.spinner.show()
  //   this.remedyService.getAllRemedy({customerId: this.userdata.getCustomerId(),_id:this.id, isSuggestedProductBought: false}).subscribe(
  //     (res:any) =>{
  //       this.spinner.hide()
  //       this.totalSuggestedRemedy = res.data.remedysuggestIds.length
  //       console.log(this.totalSuggestedRemedy)
  //     },(err:any)=>{
  //       this.spinner.hide()
  //       this.toast.error(err.error.message,'Error')
  //     }
  //   )
  // }

  totalPurchasedRemedy = 0;
  // getPurchasedRemedy(){
  //   this.spinner.show()
  //   this.remedyService.getAllRemedy({customerId: this.userdata.getCustomerId(),_id:this.id, isSuggestedProductBought: true}).subscribe(
  //     (res:any) =>{
  //       this.spinner.hide()
  //       this.totalPurchasedRemedy = res.data.remedysuggestIds.length
  //     },(err:any)=>{
  //       this.spinner.hide()
  //       this.toast.error(err.error.message,'Error')
  //     }
  //   )
  // }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

}
