<app-header #headerOne></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Astro Shop</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li>Astro Shop</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<!-- Big Banner Start -->
<section class="my-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3 mall-cat-left d-lg-block">
        <div class="list-group card bg-active p-0 h-100">
          <ul class="mall-category">
            <li>
              <span class="
                  list-group-item list-group-item-action
                  bg-green
                  fw-500
                  text-white text-center
                  cat-green-title
                " aria-current="true">
                <i class="fas fa-th-large float-left"></i>
                All Categories
              </span>
            </li>
            <ng-container *ngIf="categoryList">
              <ng-container *ngFor="let category of categoryList; let i = index">
                <li [routerLink]="['/product/category', category._id]" class="category-list "
                  *ngIf="category.is_product_associated">
                  <a class="
                      text-muted
                      align-items-center
                      collapsed
                      text-capitalize
                    ">
                    {{ category?.categoryname }}
                  </a>
                </li>
                <li [routerLink]="['/astro-shop',category._id]" class="category-list"
                  *ngIf="category.is_subcategory_associated">
                  <a class="
                      text-muted
                      align-items-center
                      collapsed
                      text-capitalize
                    ">
                    {{ category?.categoryname }}
                  </a>
                  <!-- <a class="
                      text-black-50
                      align-items-center
                      collapsed
                      text-capitalize
                    " data-bs-toggle="collapse" attr.data-bs-target="#home-collapse-{{ i }}" aria-expanded="false">
                    {{ category?.categoryname
                    }}<i class="fas fa-chevron-down float-right"></i>
                  </a>
                  <div *ngIf="category.is_subcategory_associated" class="collapse bg-inactive p-2"
                    id="{{ 'home-collapse-' + i }}">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li *ngFor="let sub of category.subcategorylist">
                        <a [routerLink]="['/product/subcategory', sub._id]" class="link-dark rounded">{{
                          sub.subcategoryname }}</a>
                      </li>
                    </ul>
                  </div> -->
                </li>
              </ng-container>
            </ng-container>
            <!-- <li>
              <a href="#" class="list-group-item list-group-item-action text-center" aria-current="true">
                <i class="fas fa-plus float-left"></i>More Categories
              </a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="col-lg-9 col-12 p-lg-0">
        <ngx-select-dropdown class="search-background" (searchChange)="searchProduct($event)"
          (change)="selectProduct($event)" [config]="searchConfig" [options]="searchProducts"></ngx-select-dropdown>
        <div class="mt-3 d-none d-lg-flex">

          <div class="col-9 p-0 pr-1">
            <a [routerLink]="['/product-detail/lakshmi-potli']">
              <img src="../../../assets/images/product/bigbanner.jpg" class="w-100 rounded mr-2" />

            </a>
          </div>
          <div class="d-flex flex-column justify-content-between col-3 p-0 pl-1 b-size">
            <a routerLink="/chat-astro-list"><img class="ml-2 mb-2"
                src="../../../assets/images/product/second-banner1.jpg" />
            </a>
            <a routerLink="/call-astro-list">
              <img class="ml-2 mt-2" src="../../../assets/images/product/talk-banner1.jpg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Big Banner End -->

<!-- Product Banner Start-->
<section class="mb-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-6">
        <div class="d-flex rounded py-2 px-3 cus-border-secondary">
          <img class="rounded-circle size-60" src="../../../assets/images/product/24-by-7.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            Support 24/7
          </h4>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="d-flex rounded py-2 px-3 cus-border-orange">
          <img class="rounded-circle size-60 bg-orange p-1" src="../../../assets/images/product/kalasha.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            online pooja
          </h4>
        </div>
      </div>
      <div class="col-lg-3 col-6 m-lg-0 mt-3">
        <div class="d-flex rounded py-2 px-3 cus-border-green">
          <img class="rounded-circle size-60" src="../../../assets/images/product/mandala.png" />
          <h4 class="
              text-capitalize
              fw-500
             sb-font-size
              text-center
              align-self-center
              w-100
            ">
            vedic yantras
          </h4>
        </div>
      </div>
      <div class="col-lg-3 col-6 m-lg-0 mt-3">
        <div class="d-flex rounded py-2 px-3 cus-border-active1">
          <img class="rounded-circle size-60" src="../../../assets/images/product/rudhraksh.png" />
          <h4 class="
              text-capitalize
              fw-500
              sb-font-size
              text-center
              align-self-center
              w-100
            ">
            Rudraksha
          </h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Banner End-->

<!-- Category Products Start -->
<section class="bg-inactive d-none d-lg-block  ">
  <div class="container-fluid positon-relative d-flex flex-column h-75 my-5">
    <div class="d-flex py-4 justify-content-center cat-pill">
      <ng-container *ngIf="categoryList">
        <ng-container *ngFor="let cat of categoryList; let i = index">
          <a class="
              rounded-pill
              text-capitalize
              fw-500
              mx-2
              fn-1
              px-3
              py-1
              text-white
              bg-grey-tabs
              
            " *ngIf="i < 5" [class.active]="selectedCat == cat._id" (click)="productByCategory(cat._id)">{{
            cat.categoryname }}</a>
        </ng-container>
      </ng-container>
    </div>
    <div class="font-anton text-uppercase astro-river w-100 text-center" style="margin-top: 5%">
      astro river
    </div>
    <div class="d-flex mt-n5 justify-content-center pb-5 mb-5">
      <ng-container *ngFor="let latest of productList.slice(0,5)">
        <div class="product-grid rounded bg-active mb-5">
          <div class="product-image prd-image new-p">
            <a [routerLink]="['/product-detail', latest.product_slug]">
              <img class="pic-1 rounded " [src]="getSanitizeUrl(latest.product_image[0])" />
              <img class="pic-2 rounded " [src]="getSanitizeUrl(latest.product_image[0])" />
            </a>
            <span class="product-sale-label fn-1 fw-500" *ngIf="latest.is_offer_applied">{{ latest.offer_percentage }}%
              OFF</span>
          </div>
          <div class="product-content ">
            <h3 class="fn-1 text-left fw-500 text-uppercase text-truncate text-black">
              <a [routerLink]="['/product-detail', latest.product_slug]" class="text-dark">{{ latest.productname
                }}</a>
            </h3>
            <div class="price text-left fn-1 fw-700">
              {{
              getCurrentPrice(latest)
              | currency: (code ? "INR" : "USD"):"symbol-narrow"
              }}
              <span *ngIf="latest.is_offer_applied">{{
                getOrginialPrice(latest)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}</span>
            </div>
            <div class="product-button-group">
              <a class="
                    add-to-cart
                    fn-1
                    fw-500
                    text-uppercase
                    text-white
                    rounded
                  " (click)="addToCart(latest._id)"><i class="fa fa-shopping-bag"></i> &nbsp;ADD TO
                CART</a>
            </div>
          </div>
        </div>
      </ng-container>
      <h4 *ngIf="productList.length == 0" class="py-5 text-center">
        No Product Available!
      </h4>
    </div>
    <!--  -->
    <p class="text-center mb-5" *ngIf="productList.length > 5">
      <a [routerLink]="['/product/category', selectedCat]"
        class="fn-2 fw-500 btn text-white bg-green py-2 px-5 rounded">
        View More
      </a>
    </p>
  </div>
</section>
<!-- Category Products End -->

<!-- New Product Start-->
<section class="my-5 d-none d-lg-block container">
  <div class="container">
    <div class="row">
      <div class="col-3 bg-secondary rounded">
        <div class="card border-0  ">
          <div class="card-header bg-secondary  border-0">
            <h4 class="text-center text-white text-uppercase fw-600">
              Featured
            </h4>
          </div>
          <div class="card-body p-0  rounded-bottom ">
            <a [routerLink]=" ['/product-detail', featuredProduct?.product_slug]"><img
                *ngIf="featuredProduct?.product_image.length > 0; else noimg" class="w-100 f-height rounded-top"
                [src]="getSanitizeUrl(featuredProduct?.product_image[0])" alt="..." />
              <ng-template #noimg>
                <img class="w-100 h-100" src="../../../assets/images/kundli.png" alt="..." />
              </ng-template>
            </a>
          </div>
          <div class="prod-txt-left card-footer bg-active shadow-3 border px-2 pt-1 pb-2 d-flex flex-column rouned-bottom
            ">
            <h5 class="line-size-1 fn-2 fw-600 text-uppercase text-truncate mt-1 text-black">
              {{ featuredProduct?.productname }}
            </h5>

            <div *ngIf="featuredProduct?.is_direct_price; else AstrologerPrice">
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                getCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                &nbsp;<del class="inactive-text small" *ngIf="featuredProduct?.is_offer_applied">{{
                  getOrginialPrice(featuredProduct)
                  | currency: (code ? "INR" : "USD"):"symbol-narrow"
                  }}</del>&nbsp;
                <span class="txt-red" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </div>

            <ng-template #AstrologerPrice>
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                getAstrologerCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                <span class="txt-red" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-template>

            <a (click)="addToCart(featuredProduct?._id)" type="button" class="
                btn
                fw-500
                bg-secondary-2
                rounded
                dark-text
                px-5
                py-2
                m-0
                mt-2
              ">
              Add To Cart
            </a>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="new-product row d-flex flex-column">
          <h4 class="text-capitalize text-center ">new products</h4>
          <h4 class="text-center mb-2"><span></span></h4>
          <a class="nav-left rounded cus-border-secondary-1 position-absolute ">
            <i class="fi fi-angle-left"></i>
          </a>
          <a class="nav-right rounded cus-border-secondary-1 position-absolute ">
            <i class="fi fi-angle-right"></i>
          </a>
        </div>

        <div class="row mt-2 pl-2">


          <swiper [config]="relatedProduct" class="detail-swiper" *ngIf="latestProduct">
            <ng-template swiperSlide *ngFor="let latest of latestProduct">
              <div class="product-grid product-card rounded">
                <div class="product-image" style="height: 165px">
                  <a [routerLink]="['/product-detail', latest.product_slug]">
                    <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                    <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                  </a>
                  <span *ngIf="latest.is_offer_applied" class="product-sale-label fn-1 fw-500">{{
                    latest.offer_percentage }}% OFF</span>
                </div>
                <div class="product-content1">
                  <h3 class="fn-1 text-left fw-500 text-uppercase text-truncate text-black">
                    <a [routerLink]="['/product-detail', latest.product_slug]" class="font-weight-bold text-dark">{{
                      latest.productname }}</a>
                  </h3>
                  <div class="price fn-1 fw-700 text-left ">
                    {{
                    getCurrentPrice(latest)
                    | currency: (code ? "INR" : "USD"):"symbol-narrow"
                    }}
                    <span class="small">{{
                      getOrginialPrice(latest)
                      | currency: (code ? "INR" : "USD"):"symbol-narrow"
                      }}</span>
                  </div>
                  <div class="product-button-group">
                    <a class="
                        add-to-cart
                        fn-1
                        fw-500
                        text-uppercase
                        text-white
                        rounded
                      " (click)="addToCart(latest?._id)"><i class="fa fa-shopping-bag"></i> &nbsp;ADD TO
                      CART</a>
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>


      </div>
    </div>
  </div>
</section>
<section class="my-3 d-lg-none">
  <!-- <div class="container">
    <div class="row">
      <div class="d-flex position-relative w-100 my-2">
        <h4 class="text-capitalize text-center w-100">new products</h4>
        <a class="m-nav-left rounded cus-border-secondary-1 position-absolute">
          <i class="fi fi-angle-left"></i>
        </a>
        <a class="m-nav-right rounded cus-border-secondary-1 position-absolute">
          <i class="fi fi-angle-right"></i>
        </a>
      </div>
      <div class="row m-0 mt-2 pl-3">
        <swiper [config]="relatedMProduct" class="detail-swiper" *ngIf="latestProduct">
          <ng-template swiperSlide *ngFor="let latest of latestFtProduct; let i = index">
            <div class="card border-0 bg-secondary p-3" *ngIf="i == 0">
              <div class="card-header bg-secondary pb-1 border-0">
                <h4 class="text-center text-white text-uppercase fw-600">
                  Featured
                </h4>
              </div>
              <div class="card-body p-0 bg-secondary rounded-bottom" style="height: 8rem">
                <a [routerLink]="['/product-detail', latest?._id]"><img
                    *ngIf="latest?.product_image.length > 0; else noimg" class="w-100 h-100 rounded-top"
                    [src]="getSanitizeUrl(latest?.product_image[0])" alt="..." />
                  <ng-template #noimg>
                    <img class="w-100 h-100" src="../../../assets/images/kundli.png" alt="..." />
                  </ng-template>
                </a>
              </div>
              <div class="
                  card-footer
                  bg-active
                  shadow-3
                  border
                  px-2
                  pt-1
                  pb-2
                  d-flex
                  flex-column
                  rouned-bottom
                ">
                <h5 class="line-size-1 fn-2 fw-600 text-captilize mt-1 text-black">
                  {{ latest?.productname }}
                </h5>

                <ng-container *ngIf="latest?.is_direct_price">
                  <h6 class="text-center fw-400 active-text">
                    {{
                    getCurrentPrice(latest)
                    | currency: (code ? "INR" : "USD"):"symbol-narrow"
                    }}
                    &nbsp;<del class="inactive-text" *ngIf="latest?.is_offer_applied">{{
                      getOrginialPrice(latest)
                      | currency: (code ? "INR" : "USD"):"symbol-narrow"
                      }}</del>&nbsp;
                    <span class="text-black text-left" *ngIf="latest?.is_offer_applied">{{ latest?.offer_percentage }}%
                      OFF</span>
                  </h6>
                </ng-container>

                <a (click)="addToCart(latest?._id)" type="button" class="
                    btn
                    fw-500
                    bg-secondary-2
                    rounded
                    text-white
                    py-2
                    m-0
                    mt-2
                  ">
                  Add To Cart
                </a>
              </div>
            </div>
            <div class="product-grid rounded" *ngIf="i > 0">
              <div class="product-image" style="height: 10rem">
                <a [routerLink]="['/product-detail', latest._id]">
                  <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                  <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                </a>
                <span *ngIf="latest.is_offer_applied" class="product-sale-label fn-1 fw-500">{{ latest.offer_percentage
                  }}% OFF</span>
              </div>
              <div class="product-content prod-txt-left">
                <h3 class="fn-2 fw-600 text-capitalize text-black line-size-1">
                  <a [routerLink]="['/product-detail', latest._id]">{{ latest.productname }}</a>
                </h3>
                <div class="price fn-1 fw-500">
                  {{
                  getCurrentPrice(latest)
                  | currency: (code ? "INR" : "USD"):"symbol-narrow"
                  }}
                  <span *ngIf="latest.is_offer_applied">{{
                    getOrginialPrice(latest)
                    | currency: (code ? "INR" : "USD"):"symbol-narrow"
                    }}</span>
                </div>
                <div class="product-button-group">
                  <a class="
                      add-to-cart
                      fn-1
                      fw-500
                      text-uppercase
                      dark-text
                      rounded
                    " (click)="addToCart(latest._id)"><i class="fa fa-shopping-bag"></i> &nbsp;ADD TO
                    CART</a>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
  </div> -->

  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 bg-secondary p-3">
          <div class="card-header bg-secondary pb-1 border-0">
            <h4 class="text-center text-white text-uppercase fw-600">
              Featured
            </h4>
          </div>
          <div class="card-body p-0 bg-secondary rounded-bottom" style="height: 12rem">
            <a [routerLink]="['/product-detail', featuredProduct?.product_slug]"><img
                *ngIf="featuredProduct?.product_image.length > 0; else noimg" class="w-100 h-100 rounded-top"
                [src]="getSanitizeUrl(featuredProduct?.product_image[0])" alt="..." />
              <ng-template #noimg>
                <img class="w-100 h-100" src="../../../assets/images/kundli.png" alt="..." />
              </ng-template>
            </a>
          </div>
          <div class="
          prod-txt-left
              card-footer
              bg-active
              shadow-3
              border
              px-2
              pt-1
              pb-2
              d-flex
              flex-column
              rouned-bottom
            ">
            <h5 class="line-size-1 fn-2 fw-600 text-uppercase text-truncate mt-1 text-black">
              {{ featuredProduct?.productname }}
            </h5>

            <ng-container *ngIf="featuredProduct?.is_direct_price; else AstrologerPrice">
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                getCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                &nbsp;<del class="inactive-text small" *ngIf="featuredProduct?.is_offer_applied">{{
                  getOrginialPrice(featuredProduct)
                  | currency: (code ? "INR" : "USD"):"symbol-narrow"
                  }}</del>&nbsp;
                <span class="txt-red" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-container>

            <ng-template #AstrologerPrice>
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                getAstrologerCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                <span class="txt-red" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-template>

            <a (click)="addToCart(featuredProduct?._id)" type="button" class="
                btn
                fw-500
                bg-secondary-2
                rounded
                dark-text
                px-5
                py-2
                m-0
                mt-2
              ">
              Add To Cart
            </a>
          </div>
        </div>
      </div>
      <div class="col mt-3">
        <div class="new-product row d-flex flex-column">
          <h4 class="text-capitalize text-center mob-txt-head">new products</h4>
          <h4 class="text-center mb-2"><span></span></h4>
          <a class="nav-left rounded cus-border-secondary-1 position-absolute ml-3">
            <i class="fi fi-angle-left"></i>
          </a>
          <a class="nav-right rounded cus-border-secondary-1 position-absolute mr-3">
            <i class="fi fi-angle-right"></i>
          </a>
        </div>
        <div class="row mt-2 pl-2">


          <swiper [config]="relatedMProduct" class="detail-swiper" *ngIf="latestProduct">
            <ng-template swiperSlide *ngFor="let latest of latestProduct">
              <div class="product-grid product-card rounded">
                <div class="product-image" style="height: 137px">
                  <a [routerLink]="['/product-detail', latest.product_slug]">
                    <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                    <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                  </a>
                  <span *ngIf="latest.is_offer_applied" class="product-sale-label fn-1 fw-500">{{
                    latest.offer_percentage }}% OFF</span>
                </div>
                <div class="product-content1">
                  <h3 class="fn-1 text-left fw-500 text-uppercase text-truncate text-black">
                    <a [routerLink]="['/product-detail', latest.product_slug]" class="font-weight-bold text-dark">{{
                      latest.productname }}</a>
                  </h3>
                  <div class="price fn-1 fw-700 text-left ">
                    {{
                    getCurrentPrice(latest)
                    | currency: (code ? "INR" : "USD"):"symbol-narrow"
                    }}
                    <span *ngIf="latest.is_offer_applied" class="small">{{
                      getOrginialPrice(latest)
                      | currency: (code ? "INR" : "USD"):"symbol-narrow"
                      }}</span>
                  </div>
                  <div class="product-button-group">
                    <a class="
                        add-to-cart
                        fn-1
                        fw-500
                        text-uppercase
                        text-white
                        rounded
                      " (click)="addToCart(latest?._id)"><i class="fa fa-shopping-bag"></i> &nbsp;ADD TO
                      CART</a>
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>


      </div>
    </div>
  </div>
</section>
<!-- New Product End-->

<!-- Big Banner Start-->
<section class="">
  <div class="container">
    <a routerLink="/product/subcategory/6368b39fde6e656b44df4bf9">
      <img src="../../../assets/images/product/banner-bar.jpg" class="w-100" />
    </a>
  </div>
</section>
<!-- Big Banner End-->

<!-- Reports Start-->
<section class="my-lg-5 my-3" *ngIf="blogs.length > 0">
  <div class="container">
    <div class="row new-product d-flex flex-column">
      <h4 class="d-none d-lg-block fw-600 text-capitalize text-center">
        latest blog posts
      </h4>
      <h5 class="d-lg-none fw-600 text-capitalize text-center">
        latest blog posts
      </h5>
      <h4 class=""><span></span></h4>
    </div>

    <div class="row mt-lg-5 mt-md-3 mt-xs-3 mt-sm-3 glob-blog">
      <div class="card border-0 col-3 p-0 d-none d-lg-block">
        <div class="card-header bg-secondary border-0">
          <h4 class="fn-2 fw-600 text-center text-uppercase text-white">
            FEATURED
          </h4>
        </div>
        <div class="card-body p-0 px-3 bg-secondary rounded-bottom" style="height: 17em">
          <img class="w-100 h-100" [src]="getSanitizeUrl(featuredBlog.blogImage)" />
        </div>
        <div class="
            card-footer
            bg-active
            mx-3
            shadow-3
            border
            px-2
            pt-1
            pb-2
            d-flex
            flex-column
          ">
          <small class="acitve-text">{{
            featuredBlog.categoryId.categoryname
            }}</small><br />
          <h4 class="fw-600 fn-2 py-2">
            {{ featuredBlog.blogTitle }}
          </h4>
          <p class="text-muted fn-0">{{featuredBlog?.userName}} /<i
              class="fa fa-calendar text-warning mx-1"></i>{{featuredBlog?.created_at |
            date:'mediumDate'}}</p>
          <p class="blog-desc line-size-7 fn-1" [innerHTML]="featuredBlog.blogsmallDescription"></p>
          <br />
          <a [routerLink]="['/blog', featuredBlog.slug]" class="
              shadow-0
              btn
              bg-secondary-2
              text-capitalize
              dark-text
              py-1
              fw-500
            ">
            Know More
          </a>
        </div>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div class="border-bottom m-2 pb-2 d-flex align-items-top" *ngFor="let blog of blogs.slice(0,3)">
          <div class="d-lg-none mr-1">
            <img class="rounded" width="{{ blogImage }}" height="{{ blogImage }}"
              [src]="getSanitizeUrl(blog?.blogImage)" />
          </div>
          <div class="flex-grow-1 d-flex flex-column ml-1">
            <h4 class="fn-2 fw-500 active-text">
              {{ blog?.categoryId.categoryname }}
            </h4>

            <h5 class="fn-1 font-weight-bold">{{ blog?.blogTitle }}</h5>
            <small class="inactive-text">{{blog?.userName}} / {{
              blog?.created_at | date: "medium"
              }}</small>
            <p class="line-size-3 fn-1 blg-text" [innerHTML]="blog?.blogsmallDescription"></p>
            <span><a [routerLink]="['/blog', blog.slug]" class="
                  btn
                  shadow-0
                  bg-secondary-2
                  text-capitalize
                  px-3
                  py-2
                  rounded
                  m-0
                  my-3
                  dark-text
                " type="button">
                Know more <i class="fas fa-chevron-right"></i>
              </a>
            </span>
          </div>
          <div class="d-none d-lg-block">
            <img class="blog-image" width="{{ blogImage }}" height="{{ blogImage }}"
              [src]="getSanitizeUrl(blog?.blogImage)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Reports Start-->

<!-- Why Choose us Start-->
<section class="my-5 mob-res-margin">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <iframe class="w-100 h-100" src="https://www.youtube.com/embed/HqVgMK5Ub0Y"></iframe>
      </div>
      <div class="col-lg-6 col-md-6 col-12 bg-secondary-2 p-lg-5 p-md-5 p-3 w-choose-mob">
        <h4 class="text-capitalize fw-600">Why choose us ?</h4>
        <p class="text-justify mt-3">
          AstroRiver proudly offers AstroShop, the one of a kind exclusive online store for a wide variety of
          high-quality astro products. We want you to be able to find the perfect product, whether it's remedial or
          general, and our team is always here to help you with anything.
        </p>
        <p class="text-justify mt-3">
          We are essentially healers, not sellers. We are committed to offering the best possible prices on high-quality
          astro products including gemstones, rudrakshas and many others. Our price-match guarantee and commitment to
          quality products is assured to bring only the best for you. AstroRiver is also the exclusive distributor for
          many leading edge astro products in India.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose us End-->

<app-footer></app-footer>

<!-- <select>
  <option value="">
    <input type="checkbox" name="" value="id" onChange="changeFunction($event)" />
  </option>
</select> -->