import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlogsService } from 'src/app/service/blogs/blogs.service';
import { ProductService } from 'src/app/service/product/product.service';
import { ProductcategoryService } from 'src/app/service/productcategory/productcategory.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { Autoplay, SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper';
import { ToastrService } from 'ngx-toastr';
SwiperCore.use([Navigation, Autoplay]);
@Component({
  selector: 'app-astro-mall',
  templateUrl: './astro-mall.component.html',
  styleUrls: ['./astro-mall.component.css'],
})
export class AstroMallComponent implements OnInit {
  searchConfig = {
    displayKey: 'productname',
    searchPlaceholder: 'Search product here',
    search: true,
    placeholder: 'Search Product',
    noResultsFound: 'No Product found!',
  };
  searchProducts = new Array();
  BaseUrl = '';
  categoryList = new Array();
  searchList: any;
  code: any;
  productList = new Array();
  selectedCat = '';

  constructor(
    private categoryService: ProductcategoryService,
    private productservice: ProductService,
    private router: Router,
    private userdata: UserdataService,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,
    private blogsService: BlogsService,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  halfWidth = 0;
  blogImage = 0;
  ngOnInit(): void {
    this.halfWidth = window.innerWidth / 2;
    this.blogImage = this.halfWidth / 2;
    this.halfWidth = this.halfWidth - 30;
    this.getProductCategoryList();
    this.getAllBlogs();
    this.getLatest();

    this.code = this.userdata.isIndia();
  }

  getCurrentPrice(product: any) {
    if (product?.is_direct_price){
      return this.code ? product.price : product.usd_price || 0;
    }else{
      return this.code ? product?.astrologerList[0].price : product?.astrologerList[0].usd_price || 0;
    }
  }
  getOrginialPrice(product: any) {
    return this.code
      ? product.original_amount
      : product.usd_original_amount || 0;
  }

  getAstrologerCurrentPrice(product:any){
    return this.code ? product?.astrologerList[0].price : product?.astrologerList[0].usd_price || 0;
  }

  productByCategory(id: string) {
    this.selectedCat = id;
    this.productservice
      .getProductByCategory({
        productcategoryId: this.selectedCat,
        status: true,
      })
      .subscribe(
        (res: any) => {
          this.productList = res.data;
        },
        (err: any) => console.log(err)
      );
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  /**
   * Get All Product Category
   */
  getProductCategoryList() {
    this.spinner.show();
    this.categoryService.allProductCategory({ status: true }).subscribe(
      (res: any) => {
        this.categoryList = res.data;
        this.productByCategory(this.categoryList[0]._id);
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }
  latestProduct = new Array();
  originalProduct = new Array();
  featuredProduct: any;
  latestFtProduct = new Array();
  getLatest() {
    this.productservice
      .getProductByCategory({
        status: true,
        startpoint: 0,
        isFeaturedRequired: true,
        is_astrologer_associate: false,
      })
      .subscribe(
        (res: any) => {
          this.latestProduct = res.data;
          this.featuredProduct = res.featuredProduct;
          this.latestFtProduct.push(this.featuredProduct);
          this.latestFtProduct.push.apply(
            this.latestFtProduct,
            this.latestProduct
          );
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }
  /**
   * Search Product
   */
  isSearch: boolean = false;
  searchProduct(event: any) {
    if (event.length < 3) return;
    //  this.spinner.show();
    this.productservice
      .searchProduct({ startpoint: 0, name: event })
      .subscribe({
        next: (res: any) => {
          this.searchProducts = res.data;
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }
  selectProduct(event: any) {
    console.log("event is ", event)
    this.router.navigate(['/product-detail', event.value.product_slug]);
  }
  /**
   * Redirect to subcategory or product
   */
  checkRedirect(data: any) {
    if (data.is_subcategory_associated) {
      this.router.navigateByUrl('/products/' + data._id);
    } else {
      this.router.navigateByUrl('/product/' + data._id);
    }
  }
  featuredBlog: any;
  blogs = new Array();
  getAllBlogs() {
    this.blogsService.getWithFeaturedBlog().subscribe(
      (res: any) => {
        this.featuredBlog = res.featuredBlog;
        this.blogs = res.data;
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }
  relatedMProduct: SwiperOptions = {
    slidesPerView: 'auto',
    direction: 'horizontal',
    spaceBetween: 10,
    effect: 'slide',
    loop: false,
    scrollbar: false,
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
  };
  relatedProduct: SwiperOptions = {
    slidesPerView: 3,
    direction: 'horizontal',
    spaceBetween: 10,
    effect: 'slide',
    loop: true,
    scrollbar: false,
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
  };

  /** Add to cart */
  @ViewChild("headerOne") headerOne:any;
  addToCart(id:any) {
    if(!this.userdata.isLogin()){
      sessionStorage.setItem('product-detail', id);
      this.toast.error('Please login first'); 
      this.headerOne?.openFromAnotherComponent()
      return
    }else if(this.userdata.getEmailVerify() == ('false' || false)){
      sessionStorage.setItem('product-detail', id);
      this.toast.error('Please Verify Email');
      this.router.navigateByUrl('/verify-email-otp')
      return
    }else{      
      this.router.navigateByUrl('/order-product/'+id);
    }
    // if (this.userdata.isLogin()) this.router.navigateByUrl('/order-product/'+id);
    // else {this.toast.error('Please login first'); this.headerOne?.openFromAnotherComponent()}
  }
}
