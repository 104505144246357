<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- Wallet Transactions page start-->
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Wallet Transactions
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Wallet Transactions</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-lg-5 mt-3">
  <div class="container">
    <!-- <div class="row d-flex flex-column new-product">
      <h4 class="text-capitalize text-center d-none d-lg-block">
        wallet transactions
      </h4>
      <h5 class="text-capitalize text-center d-lg-none">wallet transactions</h5>
      <h4 class="text-center"><span></span></h4>
    </div> -->
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="row ">
          <div class="col-lg-12 col-md-12 col-12 p-0">
            <div class="bg-light p-3 d-flex flex-row search align-items-end justify-content-between">
              <div class="bg-green rounded py-2 px-4 bb-balance">
                <h6 class="text-center text-white fn-0">Your Balance</h6>
                <h4 class="fn-2 fw-500 text-white">
                  {{ currencySymbol }}{{ currentWallet }}
                  <span *ngIf="reservedAmount > 0">(Reserved Amount-
                    {{ reservedAmount | currency: "INR":"symbol-narrow" }})</span>
                </h4>
              </div>
              <div>
                <a [routerLink]="['/recharge-wallet']"
                  class="shadow-0 btn cus-border-green p-0 py-2 m-0 px-3 mx-2 text-body">Recharge Now</a>
              </div>
            </div>
          </div>
          <!-- <div class="d-flex align-items-center rounded border-0 shadow p-2 w-100">
            <p class=" pl-2 text-black mr-2 font-weight-bold">
              Wallet : {{ currencySymbol }}{{ currentWallet }}
              <span *ngIf="reservedAmount > 0">(Reserved Amount-
                {{ reservedAmount | currency: "INR":"symbol-narrow" }})</span>
            </p>
            <span class="flex-grow-1"></span>
            <a [routerLink]="['/recharge-wallet']" class="
                btn
                bg-success
                py-2
                px-3
                text-white
                fw-500
                mr-2
                float-right
                rounded
              ">Recharge Now</a>
          </div> -->
          <div class="col-md-12 p-0 mt-3">
            <ngx-datatable class="border material cust-table shadow-0 rounded" [rows]="dataList" [columnMode]="'force'"
              [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10"
              [sorts]="[{ dir: 'desc' }]">
              <ngx-datatable-column [flexGrow]="1" name="Title">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.txn_type == 1" class="badge bdg-txt-fw  bg-success py-2">Added</span>
                  <span *ngIf="row.txn_type == 2" class="badge bdg-txt-fw  bg-danger py-2">Deducted</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="3" name="Description" prop="wallet_comments">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.wallet_comments }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="2" name="Amount" prop="amount">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="row.txn_type == 1" class="text-success">{{ row.currencySymbol }}{{ row.amount }}</span>
                  <span *ngIf="row.txn_type == 2" class="text-danger">{{ row.currencySymbol }}{{ row.amount }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="1" name="Balance" prop="balance">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.currencySymbol }} {{ row.balance }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="2" name="Help">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a *ngIf="!row.is_issue_created" class="btn btn-sm bg-secondary text-white  rounded py-1"
                    [routerLink]="['/add-wallet-issue', row._id]">Help?</a>
                  <a *ngIf="row.is_issue_created" class="btn-sm bg-secondary-2 text-dark fw-500 rounded py-2"
                    [routerLink]="['/issue', row.walletissueId?._id]">Issue Details</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [flexGrow]="3" name="Date" prop="created_at">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.created_at | date: "medium" }}
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Wallet Transactions page end-->
<app-footer></app-footer>