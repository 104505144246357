<app-header></app-header>

<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Add wallet issue
      </h3>
      <ul
        class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        "
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">Add wallet issue</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="row new-product d-flex flex-column">
          <h4 class="d-none d-lg-block text-capitalize text-center">
            Generate Wallet Issue
          </h4>
          <h5 class="d-lg-none text-capitalize text-center">
            Generate Wallet Issue
          </h5>
          <h4 class="text-center"><span></span></h4>
        </div>
        <div class="row mt-lg-5 mt-3 card">
          <div class="card-header bg-active">Write us about your problem</div>
          <div class="card-body">
            <form [formGroup]="issueForm" (submit)="addIssue()">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Issue Title</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="issue_title"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="inputAddress">Description</label>
                  <textarea
                    class="form-control"
                    formControlName="issue_description"
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                class="btn bg-secondary py-2 px-4 m-0 rounded fw-500 text-white"
                [disabled]="!issueForm.valid"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
