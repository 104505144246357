import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ChatBotHeaderService } from './service/chat-bot/chat-bot-header.service';
import { MessagingService } from './service/messaging/messaging.service';
import { OpenApiService } from './service/open-api/open-api.service';
import { OrderServicesService } from './service/order/order-services.service';
import { ScrolltopService } from './service/scrolltop.service';
import { SideNavServiceService } from './service/side-nav-service.service';
import { UserdataService } from './service/userdata/userdata.service';
import { UserdetailsService } from './service/userdetails/userdetails.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'astro-customer';
  message: any;
  token: any;
  toggleView = false;
  readonly VAP_KEY =
    'BFD019yI8cnKmoH4YwihvJiTMRLUihU9Uc6ojxKBPOMsDZ_1nkZhrFea1IlXBFTk52i_uL1EoKpKWtf5JL0OrFE';
  readonly privateKey = '1TwhU6_sC5A9xmSNUFfBj2YY9rd5gMO11eNkI6EX6jE';
  constructor(
    private scrollTopService: ScrolltopService,
    private messagingService: MessagingService,
    private userdata: UserdataService,
    private orderService: OrderServicesService,
    private toast: ToastrService,
    private router: Router,
    private userDetailService: UserdetailsService,
    private openApiService: OpenApiService,
    private sideBarService: SideNavServiceService,
    private headerBot: ChatBotHeaderService, private readonly titleService: Title
  ) { 

    this.router.events.subscribe(route => {
      const title = 'Astro River';
      this.titleService.setTitle(title);

    });

   }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.sideBarService.setInit(this);
    this.openApiService.getCountryCode();
    this.scrollTopService.setScrollTop();
    this.onDataChangeReceived();
    if (this.userdata.getRegCountry().length > 0) {
      if (
        this.userdata.getRegCountry().toLowerCase() !==
        this.userdata.getCountryCode().toLowerCase()
      ) {
        this.userdata.removeData();
        this.userDetailService.onChangeOfUserDetailHandler(null);
        this.router.navigate(['/home']);
        this.toast.error('Sorry register country is different than current!');
        return false;
      }
    }
    if (sessionStorage.getItem('move') == 'dashboard') {
      sessionStorage.removeItem('move')
      this.router.navigate(['/dashboard']);
    }
    return true;
  }

  closeSideBar() {
    this.sideBarService.toggleSideBar();
  }
  onDataChangeReceived = () => {

    this.messagingService.receiveMessage();

    console.log('receive message ', this.messagingService.receiveMessage())
    this.messagingService.currentMessage.subscribe((change: any) => {
      if (change) {
        this.message = change.notification.body;
        var data = JSON.parse(change.data.fulldata);
        console.log("data in onMessage received", data)
        //console.log('this is notification data '+JSON.stringify(data))
        if (typeof data.is_chat != 'undefined') {
          var date = moment(data.customer_notification_time);
          var notify = date.utc().format('YYYY-MM-DD hh:mm:ss');
          var current = moment().utc().format('YYYY-MM-DD hh:mm:ss');
          var notifyTime = moment(notify, 'YYYY-MM-DD hh:mm:ss');
          var currentTime = moment(current, 'YYYY-MM-DD hh:mm:ss');
          var secondsDiff = currentTime.diff(notifyTime, 'seconds') + 10;
          var timeLeft = 180 - secondsDiff;
          if (timeLeft <= 0) {
            Swal.fire(
              'Timeout',
              "Chat request can't be accepted due to chat timeout.",
              'error'
            );
            return;
          }
          if (data.is_popup) {

            let timerInterval: any;
            this.startTimer();
            Swal.fire({
              title: change.notification.title,
              html: change.notification.body + '<br/><br/>Time Left: <b></b>.',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'ACCEPT',
              cancelButtonText: 'REJECT',
              allowOutsideClick: false,
              timer: timeLeft * 1000,
              timerProgressBar: true,
              didOpen: () => {
                //Swal.showLoading()
                timerInterval = setInterval(() => {
                  const content = Swal.getHtmlContainer();
                  if (content) {
                    const b = content.querySelector('b');
                    if (b) {
                      b.textContent = String(
                        moment.utc(Swal.getTimerLeft()).format('mm:ss')
                      );
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.value) {
                this.orderService
                  .acceptRejectChat({
                    chatId: data.chatId,
                    is_customer_accept: true,
                  })
                  .subscribe(
                    (res: any) => {
                      this.headerBot.openBot(data.orderserviceId);
                      location.reload()
                    },
                    (err: any) => {
                      this.toast.error(err.error.message, 'Alert');
                    }
                  );
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.orderService
                  .acceptRejectChat({
                    chatId: data.chatId,
                    is_customer_accept: false,
                  })
                  .subscribe(
                    (res: any) => {
                      this.toast.success(res.message, 'Success');
                      location.reload()

                    },
                    (err: any) => {
                      this.toast.error(err.error.message, 'Alert');
                    }
                  );
              }
            });
          }
        }
      }
    });
  };

  /**
   * Counter For 3 minutes
   */
  counter: any;
  startTimer() {
    this.counter = { min: 3, sec: 0 }; // choose whatever you want
    let intervalId = setInterval(() => {
      if (this.counter.sec - 1 == -1) {
        this.counter.min -= 1;
        this.counter.sec = 59;
      } else this.counter.sec -= 1;
      if (this.counter.min === 0 && this.counter.sec == 0)
        clearInterval(intervalId);
    }, 1000);
  }

}
