<form [formGroup]="loginForm" (submit)="onLogin()" class=" text-left mb-2 loginForm d-flex flex-column">
  <div class="form-group">
    <label class="cus-label text-uppercase" for="exampleInputEmail1">Email</label>
    <input type="email" class="form-control border" id="exampleInputEmail1" aria-describedby="emailHelp"
      placeholder="Enter email" formControlName="email" />
    <small class="text-warning" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
      This field is required with valid email.
    </small>
  </div>

  <div class="form-group">
    <label class="cus-label text-uppercase" for="exampleInputPassword1">Password</label>
    <div class="input-group mob-p-eye-bg bg-white rounded">
      <input [type]="tooglePassword ? 'text' : 'password'" class="form-control border" id="exampleInputPasswordLogin"
        placeholder="Password" formControlName="password" />
      <button (click)="toggleEye()" class="btn mob-p-eye rounded-0 p-0 px-2 btn border-0 rounded-end shadow-0"
        type="button" id="button-addon2" data-mdb-ripple-color="dark">
        <i class="material-icons-round center-align d-flex align-middle eye-size">
          {{ tooglePassword ? "visibility" : "visibility_off" }}
        </i>
      </button>
    </div>
    <small class="text-warning" *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
      This field is required.
    </small>
  </div>
  <div class="text-right pb-1">
    <a class="fn-1 text-warning fw-500" [routerLink]="['/forget-pass']">Forgot Password ?</a>
  </div>
  <div class="form-check d-flex align-items-center">
    <input style="height: 1rem; width: 1rem" type="checkbox" class="form-check-input"
      formControlName="is_terms_accepted" />
    <label class="form-check-label text-capitalize lh-1">
      <span class="text-warning form-check-label privacy-font">
        I've read and accept the
        <a class="text-white  txt-t-color" href="https://astroriver.com/terms-and-conditions-of-use/"
          target="_blank">Terms &
          Conditions,</a>
        <a href="https://astroriver.com/privacy-policy/" target="_blank" class="text-light">Privacy Policy.</a>
      </span>
    </label>
    <small class="text-white" *ngIf="
        f.is_terms_accepted.invalid &&
        (f.is_terms_accepted.dirty || f.is_terms_accepted.touched)
      ">
      This field is required.
    </small>
  </div>

  <button (click)="login()" type="submit" [disabled]="!loginForm.valid"
    class="yellow-btn mt-3 m-0 fw-500 btn rounded py-2 text-body">
    Login
  </button>

  <a class="mt-4 google-btn rounded text-white d-flex btn-h" (click)="signInWithGoogle()" type="button">
    <svg class="p-1 bg-active mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44" height="44"
      viewBox="0 0 48 48" style="fill: #000000">
      <path fill="#FFC107"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z">
      </path>
      <path fill="#FF3D00"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z">
      </path>
      <path fill="#4CAF50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z">
      </path>
      <path fill="#1976D2"
        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z">
      </path>
    </svg>
    <span class="fn-81 flex-grow-1 text-center align-self-center mr-3">
      SIGNIN WITH GOOGLE
    </span>
  </a>
  <button type="button" (click)="loginWithFacebook()" class="
      rounded
      btn btn-facebook-login
      d-flex
      align-items-center
      p-0
      rounded
      border-white
      shadow-0
      m-0
      btn-h
      mt-2
    ">
    <i class="fi fi-facebook f-icon"></i><span class="fn-81 flex-grow-1    text-center text-white">Signin with
      Facebook</span>
  </button>
</form>
<!-- </div></div>-->