import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { MessagingService } from 'src/app/service/messaging/messaging.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { UserdetailsService } from 'src/app/service/userdetails/userdetails.service';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private userdataService: UserdataService,
    private loginService: LoginService,
    private toast: ToastrService,
    private router: Router,
    private openService: OpenApiService,
    private messagingService: MessagingService,
    private userDetailsService: UserdetailsService,
    private modalService: NgbModal,
    private authService: SocialAuthService,
    private route: ActivatedRoute
  ) { }
  isGoogle = true;
  isLogin = false;
  ngOnInit(): void {
    if (this.userdataService.getToken() != null) {
      if (this.userdataService.getPhoneVerify() != 'true') {
        // this.router.navigate([
        //   '/verify-phone-otp',
        //   this.userdataService.getEmail(),
        // ]);
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    }
    this.messagingService.requestPermission();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.getLink();

    this.authService.authState.subscribe((user) => {
      //console.log(user)
      if (user != null && user != undefined) {
        this.loginForm.patchValue({ is_social_login: true });
        this.loginForm.patchValue({ social_id: user.id });
        this.loginForm.patchValue({ password: user.id });
        this.loginForm.patchValue({
          social_type: this.isGoogle ? 1 : 2,
        });
        // this.loginSocialForm.patchValue({email:data.email})
        if (this.isLogin) {
          this.onLogin();
          this.signOut();
        }
      }
    });
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    is_terms_accepted: new FormControl(true, [Validators.requiredTrue]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
    is_social_login: new FormControl(false),
    social_id: new FormControl(''),
    social_type: new FormControl(''),
  });

  get f() {
    return this.loginForm.controls;
  }
  tooglePassword = false;
  toggleEye() {
    this.tooglePassword = !this.tooglePassword;
  }
  isLoggedIn: boolean = false;
  login() {
    this.loginForm.patchValue({ is_social_login: false });
  }
  onLogin() {
    this.loginForm.patchValue({ fcm_token: this.userdataService.getFcm() });
    this.loginService.login(this.loginForm.value).subscribe(
      (res: any) => {
        if (res.success) {
          this.userdataService.setData(res);
          this.userDetailsService.onChangeOfUserDetailHandler(res.token);
          this.isLoggedIn = true;
          this.modalService.dismissAll();
          if (!res.customerdetail.is_otp_verified) {
            this.router.navigate(['/verify-phone-otp', res.data.email]);
          } else {
            let returnUrl: any = this.route.snapshot.queryParamMap.get('returnUrl')
            console.log(returnUrl)
            if (returnUrl != '' && returnUrl != null) {
              this.router.navigateByUrl(returnUrl);
              return
            }
            let call = sessionStorage.getItem('call');
            // console.log("call is ", call)
            let pro_detail = sessionStorage.getItem('product-detail');
            if (call !== null) {
              sessionStorage.removeItem('call');
              this.router.navigate(['/astrologer', call]);
              return;
            }
            if (pro_detail !== null) {
              sessionStorage.removeItem('product-detail');
              this.router.navigate(['/order-product', pro_detail]);
              return;
            }
            sessionStorage.setItem('move', 'dashboard')
            window.location.reload();
            this.toast.success('Login Successful', 'Success');
            // location.reload()
          }
        } else {
          this.toast.error(res.message, 'Alert');
        }
      },
      (err: any) => {
        if (err.error.message == 'Please fill your detail to proceed.') {
          this.toast.error("User doesn't exist.", 'Alert');
        } else {
          this.toast.error(err.error.message, 'Alert');
        }
      }
    );
  }

  link: any = '';
  getLink() {
    this.openService
      .getSingleCustomization({ customization_type: 5 })
      .subscribe(
        (res: any) => {
          this.link = res.data.customization_value;
        },
        (err: any) => { }
      );
  }
  /**
   * Login with Google
   */
  signInWithGoogle(): void {
    this.isLogin = true;
    this.isGoogle = true;
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  loginWithFacebook() {
    this.isLogin = true;
    this.isGoogle = false;
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  signOut(): void {
    this.authService.signOut(true);
  }
}
