<!-- hs top header Start -->
<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- hs top header End -->
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Festival Detail</h3>
            <ul class="
            d-none d-lg-flex
            fw-500
            text-white
            w-100
            float-right
            justify-content-end
          ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>Festival Detail</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
    <div class="container">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="">
                <div class="row">
                    <div class="col-3 d-none d-lg-block">
                        <app-customer-sidebar></app-customer-sidebar>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                        <div class="">
                            <div class="d-flex justify-content-between">
                                <h2 [innerHTML]="festival?.festivalId?.festivalName" class="font-weight-bold mb-2"></h2>
                                <span class="font-weight-bold">{{festival?.festivalDate | date:'fullDate'}}</span>
                            </div>

                            <div class="hs_blog_box1_img_wrapper mb-2">
                                <img [src]="getSanitizeUrl(festival?.festivalId?.festivalImage)" alt="blog_img"
                                    class="img-fluid p-2" />
                            </div>
                            <div class="">
                                <div class="">
                                    <div id="blog_description" [innerHTML]="festival?.festivalId?.festivalDescription">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 d-none d-lg-block">
                        <div class="list-group card bg-active p-0 h-auto">
                            <ul class="mall-category">
                                <li>
                                    <span class="
                                  list-group-item list-group-item-action
                                  bg-green
                                  fw-500
                                  text-white text-center
                                " aria-current="true">
                                        <i class="fas fa-th-large float-left"></i>
                                        All Festivals List
                                    </span>
                                </li>
                                <ng-container *ngIf="festivalList?.length > 0">
                                    <li class="category-list" *ngFor="let festival of festivalList">
                                        <a [routerLink]="['/festivals', festival?._id]" class="
                                  text-black-50
                                  align-items-center
                                  collapsed
                                  text-capitalize
                                ">
                                            {{ festival?.festivalId?.festivalName}}
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
<!-- hs footer wrapper End -->