<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
    <div class="product-crumb-bg w-100 py-4">
        <div class="container d-flex">
            <h3 class="fw-600 fn-3 text-white w-100">Kundli View</h3>
            <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end 
        ">
                <li>
                    <a [routerLink]="['/']" class="text-white">Home</a>
                    &nbsp;&nbsp;>&nbsp;&nbsp;
                </li>
                <li>kundi-view</li>
            </ul>
        </div>
    </div>
</section>
<!-- BradCrumbs End -->
<section class="container my-5">
    <div class="row gx-5">
        <div class="col-12 col-md-3 col-lg-3 my-2">
            <div class="border rounded shadow-sm">
                <table class="table  table-hover rounded">
                    <tbody>
                        <tr>
                            <th scope="col" class="bg-secondary  border-0 rounded-top h5 text-white"><i
                                    class="fa-solid fa-hand-dots p-1 text-warning"></i> Kundli</th>
                        </tr>

                        <tr [class.tab-active]="currentMainTab == 'Basic'" (click)="changeSubTab('Details')">
                            <td>Basic Details</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Lagna'" (click)="changeSubTab('Lagna')">
                            <td>Lagna</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Navamsa'" (click)="changeSubTab('Navamsa')">
                            <td>Navamsa</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Dasha'" (click)="changeSubTab('MahaDasha')">
                            <td>Dasha</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Planets'" (click)="changeSubTab('Sign')">
                            <td>Planets</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Chart'" (click)="changeSubTab('D3')">
                            <td>Varga Charts</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Manglik'" (click)="changeSubTab('Manglik')">
                            <td>Manglik Report</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Ascendant'" (click)="changeSubTab('Ascendant')">
                            <td>Ascendant</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Ashtakvarga'" (click)="changeSubTab('Ashtakvarga')">
                            <td>Ashtakvarga</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Rudhraksh'" (click)="changeSubTab('Rudhraksh')">
                            <td>Rudraksha</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Gem'" (click)="changeSubTab('Gem')">
                            <td>Gem Suggestion</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Dosh'" (click)="changeSubTab('Mangal')">
                            <td>Dosh</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Sati'" (click)="changeSubTab('Sati')">
                            <td>Get Sade Sati</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Numerology'" (click)="changeSubTab('Numerology')">
                            <td>Numerology</td>
                        </tr>
                        <tr [class.tab-active]="currentMainTab == 'Bio'" (click)="changeSubTab('Bio')">
                            <td>Bio Rhythm</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <div class="col-12 col-md-9 col-lg-9 my-2">
            <!-- Basic detail section start  -->
            <ng-container *ngIf="currentMainTab == 'Basic'">
                <div class=" d-flex text-center f-style mb-3">
                    <button (click)="changeSubTab('Details')" [class.active]="currentSubTab=='Details'"
                        class="btn btn-sm mr-2 rounded py-2">Basic Details</button>
                    <button (click)="changeSubTab('Panchang')" [class.active]="currentSubTab=='Panchang'"
                        class="btn btn-sm mr-2 rounded py-2">Panchang</button>
                    <button (click)="changeSubTab('Avakhada')" [class.active]="currentSubTab=='Avakhada'"
                        class="btn btn-sm mr-2 rounded py-2">Avakhada</button>

                </div>
                <ng-container *ngIf="currentSubTab == 'Details'">
                    <table class="table table-striped kt-res table-bordered ">
                        <thead class=" table table-dark k-table fw-400">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Date of birth</th>
                                <th scope="col">Time of birth</th>
                                <th scope="col">Place</th>
                                <th scope="col">Lattitude</th>
                                <th scope="col">Longitude</th>
                                <th scope="col">TimeZone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{{kundliData?.form?.name}}</td>
                                <td scope="col">{{kundliData?.form?.gender}}</td>
                                <td scope="col">{{kundliData?.form?.dob}}</td>
                                <td scope="col">{{kundliData?.form?.tob}}</td>
                                <td scope="col">{{kundliData?.location?.name}}</td>
                                <td scope="col">{{kundliData?.location?.coordinates[1]}}</td>
                                <td scope="col">{{kundliData?.location?.coordinates[0]}}</td>
                                <td scope="col">{{kundliData?.location?.tz}}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <!-- tabs section start -->
                <!-- <div class=" d-flex text-center f-style mb-3">
                    <button class="btn btn-sm mr-2 rounded  py-2">Basic Details</button>
                    <button class="btn btn-sm mr-2 rounded  py-2">Panchang</button>
                    <button class="btn btn-sm mr-2 rounded py-2 active">Avakhada</button>

                </div> -->
                <!-- tabs section end -->

                <!-- Punchang section Start Here -->
                <ng-container *ngIf="currentSubTab == 'Panchang'">
                    <div class="row ">
                        <div class=" col">
                            <div class="p-3 border-0 bg-red rounded">
                                <h6 class="fw-600 text-warning">Rashi : <span
                                        class=" text-white">{{kundliResponse?.rasi?.name}}</span></h6>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border-0 bg-warning rounded">
                                <h6 class="fw-600 txt-red">Date : <span class=" text-dark">{{kundliData?.form?.dob}}
                                    </span>
                                    ({{kundliResponse?.day?.name}})
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm bg-light">
                                <h5 class="fw-600 text-dark">Tithi</h5>
                                <hr>
                                <h6 class="txt-red">{{kundliResponse?.tithi?.name}}</h6>
                                <p class="fw-500 small text-dark">{{kundliResponse?.tithi?.start}} -
                                    {{kundliResponse?.tithi?.end}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm bg-light">
                                <h5 class=" fw-600 text-dark">Karan</h5>
                                <hr>
                                <h6 class="txt-red">{{kundliResponse?.karana?.name}}</h6>
                                <p class="fw-500 small text-dark">{{kundliResponse?.karana?.start}} -
                                    {{kundliResponse?.karana?.end}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm bg-light">
                                <h5 class=" fw-600 text-dark">Yog</h5>
                                <hr>
                                <h6 class="txt-red">{{kundliResponse?.yoga?.name}}</h6>
                                <p class=" fw-500 small text-dark">{{kundliResponse?.yoga?.start}} -
                                    {{kundliResponse?.yoga?.end}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm bg-light">
                                <h5 class="fw-600 text-dark">Nakshatra</h5>
                                <hr>
                                <h6 class="txt-red">{{kundliResponse?.nakshatra?.name}}</h6>
                                <p class=" fw-500 small text-dark">{{kundliResponse?.nakshatra?.start}} -
                                    {{kundliResponse?.nakshatra?.end}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm ">
                                <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                        <img src="../../../../assets/images/sunrise.png " /> Sunrise:</span>
                                    {{kundliResponse?.advanced_details?.sun_rise}}
                                </h6>
                                <hr class="m-0">
                                <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                        <img src="../../../../assets/images/sunset.png " /> Sunset:</span>
                                    {{kundliResponse?.advanced_details?.sun_set}}
                                </h6>

                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1 ">
                            <div class="p-3 border rounded shadow-sm ">
                                <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                        <img src="../../../../assets/images/moon-rise.png " /> Moonrise:</span>
                                    {{kundliResponse?.advanced_details?.moon_rise}}
                                </h6>
                                <hr class="m-0">
                                <h6 class="fw-600 "><span class=" h6 fw-600 txt-red">
                                        <img src="../../../../assets/images/moon-set.png " /> Moonset:</span>
                                    {{kundliResponse?.advanced_details?.moon_set}}
                                </h6>

                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm bg-red">
                                <h5 class="fw-600 text-white">Rahukaal</h5>
                                <hr class="bg-white">
                                <p class=" small text-white">{{kundliResponse?.rahukaal}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border rounded shadow-sm bg-green">
                                <h5 class="fw-600 text-white">Gulika</h5>
                                <hr class="bg-white">
                                <p class=" small text-white">{{kundliResponse?.gulika}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3  border-white rounded bg-orange shadow-sm">
                                <h5 class="fw-600 text-white">Yamakanta</h5>
                                <hr class="bg-white">
                                <p class=" small text-white">{{kundliResponse?.yamakanta}}</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 col-lg-6 my-1">
                            <div class="p-3 border-white rounded shadow-sm bg-warning">
                                <h5 class=" fw-600 text-white">Ayanamsa</h5>
                                <hr class="bg-white
                                            ">
                                <p class=" small text-white">{{kundliResponse?.ayanamsa?.name}}</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- punchang section End here -->

                <!-- Avakhada tabs selected  design start  -->
                <ng-container *ngIf="currentSubTab == 'Avakhada'">
                    <table class="table table-striped table-bordered table-responsive ">
                        <thead class="table table-dark k-table fw-400">
                            <tr>
                                <th scope="col">Gona</th>
                                <th scope="col">Yoni</th>
                                <th scope="col">Vasya</th>
                                <th scope="col">Nadi</th>
                                <th scope="col">Varna</th>
                                <th scope="col">Paya</th>
                                <th scope="col">Tatva</th>
                                <th scope="col">Name Start</th>
                                <th scope="col">Karna</th>
                                <th scope="col">Yoga</th>
                                <th scope="col">Ascendant Sign</th>
                                <th scope="col">Ascendant Nakshatra</th>
                                <th scope="col">Rasi</th>
                                <th scope="col">Rasi Lord</th>
                                <th scope="col">Nakshatra </th>
                                <th scope="col">Nakshatra Lord</th>
                                <th scope="col">Nakshatra Pada</th>
                                <th scope="col">Sun Sign</th>
                                <th scope="col">Nakshatra Pada</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{{kundliResponse?.gana}}</td>
                                <td scope="col">{{kundliResponse?.yoni}}</td>
                                <td scope="col">{{kundliResponse?.vasya}}</td>
                                <td scope="col">{{kundliResponse?.nadi}}</td>
                                <td scope="col">{{kundliResponse?.varna}}</td>
                                <td scope="col">{{kundliResponse?.paya}}</td>
                                <td scope="col">{{kundliResponse?.tatva}}</td>
                                <td scope="col">{{kundliResponse?.name_start}}</td>
                                <td scope="col">{{kundliResponse?.karana}}</td>
                                <td scope="col">{{kundliResponse?.yoga}}</td>
                                <td scope="col">{{kundliResponse?.ascendant_sign}}</td>
                                <td scope="col">{{kundliResponse?.ascendant_nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.rasi}}</td>
                                <td scope="col">{{kundliResponse?.rasi_lord}}</td>
                                <td scope="col">{{kundliResponse?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.nakshatra_lord}}</td>
                                <td scope="col">{{kundliResponse?.nakshatra_pada}}</td>
                                <td scope="col">{{kundliResponse?.sun_sign}}</td>
                                <td scope="col">{{kundliResponse?.tithi}}</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <!-- Avakhada tabs selected design end -->
            </ng-container>
            <!-- Basic detail section End -->



            <!-- Lagna main tab design start here  -->
            <ng-container *ngIf="currentMainTab == 'Lagna'">
                <ng-container *ngIf="currentSubTab == ''">
                    <div class=" d-flex col-12 col-md-12  pl-0 pr-0 ">
                        <h6 class="my-2 col-md-2 pl-0 fw-600">Lagna Chart </h6>

                        <select class="form-select  col-md-10 mb-2 py-2" aria-label="Default select example"
                            (change)="getChartImage($event,'')">
                            <option value="D1" selected>Lagna</option>
                            <option value="sun">Sun</option>
                            <option value="moon">Moon</option>
                            <option value="chalit">Chalit</option>
                        </select>
                    </div>
                    <div class=" d-flex col-12 col-md-12  pl-0 pr-0 ">
                        <div class="svg" [innerHTML]="svg"></div>
                    </div>

                    <table class="table table-striped table-bordered kt-res">
                        <thead class=" table table-dark  k-table fw-400">
                            <tr>
                                <th scope="col">Planets</th>
                                <th scope="col">Sign</th>
                                <th scope="col">Degree</th>
                                <th scope="col">Nakshatra</th>
                                <th scope="col">Nakshatra Lord</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[0]?.full_name)">
                                <td scope="col">{{kundliResponse?.[0]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[0]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[0]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[0]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[0]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[1]?.full_name)">
                                <td scope="col">{{kundliResponse?.[1]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[1]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[1]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[1]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[1]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[2]?.full_name)">
                                <td scope="col">{{kundliResponse?.[2]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[2]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[2]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[2]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[2]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[3]?.full_name)">
                                <td scope="col">{{kundliResponse?.[3]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[3]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[3]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[3]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[3]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[4]?.full_name)">
                                <td scope="col">{{kundliResponse?.[4]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[4]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[4]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[4]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[4]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[5]?.full_name)">
                                <td scope="col">{{kundliResponse?.[5]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[5]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[5]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[5]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[5]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[6]?.full_name)">
                                <td scope="col">{{kundliResponse?.[6]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[6]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[6]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[6]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[6]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[7]?.full_name)">
                                <td scope="col">{{kundliResponse?.[7]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[7]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[7]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[7]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[7]?.nakshatra_lord}}</td>

                            </tr>
                            <tr (click)="getInnerLagnaData(kundliResponse?.[8]?.full_name)">
                                <td scope="col">{{kundliResponse?.[8]?.full_name}}</td>
                                <td scope="col">{{kundliResponse?.[8]?.zodiac}}</td>
                                <td scope="col">{{kundliResponse?.[8]?.local_degree | number:'1.2-2'}}</td>
                                <td scope="col">{{kundliResponse?.[8]?.nakshatra}}</td>
                                <td scope="col">{{kundliResponse?.[8]?.nakshatra_lord}}</td>

                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container *ngIf="currentSubTab == 'Lagna Detail'">
                    <table class="table table-striped table-responsive table-bordered">
                        <thead class="table table-dark  k-table fw-400">
                            <tr>
                                <th scope="col">Planet Considered</th>
                                <th scope="col">Planet Zodiac</th>
                                <th scope="col">Zodiac Lord</th>
                                <th scope="col">Zodiac Lord Location</th>
                                <th scope="col">Zodiac Lord House</th>
                                <th scope="col">Zodiac Lord Strength</th>
                                <th scope="col">Planet Strength</th>
                                <th scope="col">Verbal Location</th>
                                <th scope="col">Character Keywords Positive</th>
                                <th scope="col">Character Keywords Negative</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{{kundliResponse[0]?.planet_considered}} </td>
                                <td scope="col">{{kundliResponse[0]?.planet_zodiac}}</td>
                                <td scope="col">{{kundliResponse[0]?.zodiac_lord}}</td>
                                <td scope="col">{{kundliResponse[0]?.zodiac_lord_location}}</td>
                                <td scope="col">{{kundliResponse[0]?.zodiac_lord_house_location}}</td>
                                <td scope="col">{{kundliResponse[0]?.zodiac_lord_strength}}</td>
                                <td scope="col">{{kundliResponse[0]?.planet_strength}}</td>
                                <td scope="col">{{kundliResponse[0]?.verbal_location}}</td>
                                <td scope="col">{{getJoinArray(kundliResponse[0]?.character_keywords_positive)}}</td>
                                <td scope="col">{{getJoinArray(kundliResponse[0]?.character_keywords_negative)}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="my-3  border rounded p-3">
                        <h5 class="mb-1 txt-red">General Prediction</h5>
                        <p class="text-justify ">{{kundliResponse[0]?.general_prediction}}</p>

                    </div>
                    <div class="my-3  border rounded p-3">
                        <h5 class="mb-1 txt-red">Personalised Prediction</h5>
                        <p class="text-justify ">{{kundliResponse[0]?.personalised_prediction}}</p>

                    </div>
                    <div class="my-3  border rounded p-3">
                        <h5 class="mb-1 txt-red">Planet Zodiac Prediction</h5>
                        <p class="text-justify ">{{kundliResponse[0]?.planet_zodiac_prediction}}</p>

                    </div>
                </ng-container>
            </ng-container>
            <!-- lagna main tab design end here -->

            <!-- Navamsa main tab design start here -->
            <ng-container *ngIf="currentMainTab == 'Navamsa'">
                <div class=" d-flex col-12 col-md-12  pl-0 pr-0 mb-3 ">
                    <h6 class="pl-0 fw-600 mb-0 pr-0 align-middle">Chart Style </h6>

                    <div class="form-check  mx-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                            checked (change)="getChartImage('','north')">
                        <label class="form-check-label" for="flexRadioDefault1">
                            North Indian
                        </label>
                    </div>
                    <div class="form-check  mx-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            (change)="getChartImage('','south')">
                        <label class="form-check-label" for="flexRadioDefault2">
                            South Indian
                        </label>
                    </div>
                </div>
                <div class=" d-flex col-12 col-md-12  pl-0 pr-0 mb-3 ">
                    <div class="svg" [innerHTML]="svg"></div>
                </div>
                <table class="table table-striped table-bordered kt-res">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planets</th>
                            <th scope="col">Sign</th>
                            <th scope="col">Rasi Number</th>
                            <th scope="col">House</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{{kundliResponse?.[0]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[0]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[1]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[1]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[2]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[2]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[3]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[3]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[4]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[4]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[5]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[5]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[6]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[6]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[7]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[7]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[8]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[8]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[9]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[9]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[9]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[9]?.house}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- Navamsa main tab design close here  -->


            <!-- Dasha main tab design start here -->
            <ng-container *ngIf="currentMainTab == 'Dasha'">
                <div class="text-center mb-3 d-flex justify-content-center">
                    <h5 (click)="changeSubTab('MahaDasha')" class="fw-600 mb-0  align-middle text-center"
                        *ngIf="currentSubTab == 'MahaDasha' || currentSubTab == 'AntarDasha' || currentSubTab == 'ParyantarDasha' || currentSubTab == 'Shookshamadasha' || currentSubTab == 'PranDasha'">
                        MahaDasha </h5>
                    <h5 (click)="changeSubTab('AntarDasha')" class="fw-600 mb-0  align-middle text-center"
                        *ngIf="currentSubTab == 'AntarDasha' || currentSubTab == 'ParyantarDasha' || currentSubTab == 'Shookshamadasha' || currentSubTab == 'PranDasha'">
                        -> AntarDasha </h5>
                    <h5 (click)="changeSubTab('ParyantarDasha')" class="fw-600 mb-0  align-middle text-center"
                        *ngIf="currentSubTab == 'ParyantarDasha' || currentSubTab == 'Shookshamadasha' || currentSubTab == 'PranDasha'">
                        -> ParyantarDasha </h5>
                    <h5 (click)="changeSubTab('Shookshamadasha')" class="fw-600 mb-0  align-middle text-center"
                        *ngIf="currentSubTab == 'Shookshamadasha' || currentSubTab == 'PranDasha'">-> Shookshamadasha
                    </h5>
                    <h5 class="fw-600 mb-0  align-middle text-center" *ngIf="currentSubTab == 'PranDasha'">-> PranDasha
                    </h5>
                </div>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'MahaDasha'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planet</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [class.currentDate]="matchDate(i)" *ngFor="let number of [1,2,3,4,5,6,7,8,9];let i = index"
                            (click)="selectDasha(kundliResponse?.mahadasha?.[i])">
                            <td scope="col">{{kundliResponse?.mahadasha?.[i]}} </td>
                            <td scope="col">
                                <span *ngIf="i == 0;else showOtherDate">{{kundliData?.form?.dob | date:'EEE MMM dd
                                    yyyy'}}</span>
                                <ng-template #showOtherDate>
                                    <span>{{kundliResponse?.mahadasha_order?.[i-1]}}</span>
                                </ng-template>
                            </td>
                            <td scope="col">{{kundliResponse?.mahadasha_order?.[i]}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'AntarDasha'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planet</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [class.currentDate]="matchDateAntarDasha(i)"
                            *ngFor="let number of [1,2,3,4,5,6,7,8,9];let i = index"
                            (click)="selectDasha(kundliResponse?.antardashas?.[antarDashaIndex]?.[i])">
                            <td scope="col">{{kundliResponse?.antardashas?.[antarDashaIndex]?.[i]}} </td>
                            <td scope="col">
                                <span *ngIf="i == 0;else showOtherDate">{{kundliData?.form?.dob | date:'EEE MMM dd
                                    yyyy'}}</span>
                                <ng-template #showOtherDate>
                                    <span>{{kundliResponse?.antardasha_order?.[antarDashaIndex]?.[i-1]}}</span>
                                </ng-template>
                            </td>
                            <td scope="col">{{kundliResponse?.antardasha_order?.[antarDashaIndex]?.[i]}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'ParyantarDasha'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planet</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [class.currentDate]="matchDateParyantarDasha(i)"
                            *ngFor="let number of [1,2,3,4,5,6,7,8,9];let i = index"
                            (click)="selectDasha(kundliResponse?.paryantardasha?.[paryantarDashaIndexMain]?.[paryantarDashaIndexSub]?.[i])">
                            <td scope="col">
                                {{kundliResponse?.paryantardasha?.[paryantarDashaIndexMain]?.[paryantarDashaIndexSub]?.[i]}}
                            </td>
                            <td scope="col">
                                <span *ngIf="i == 0;else showOtherDate">{{kundliData?.form?.dob | date:'EEE MMM dd
                                    yyyy'}}</span>
                                <ng-template #showOtherDate>
                                    <span>{{kundliResponse?.paryantardasha_order?.[paryantarDashaIndexMain]?.[paryantarDashaIndexSub]?.[i-1]}}</span>
                                </ng-template>
                            </td>
                            <td scope="col">
                                {{kundliResponse?.paryantardasha_order?.[paryantarDashaIndexMain]?.[paryantarDashaIndexSub]?.[i]}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'Shookshamadasha'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planet</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [class.currentDate]="matchDateShookshamadasha(data)"
                            *ngFor="let data of kundliResponse?.shookshamadasha;let i = index"
                            (click)="selectDasha(data?.name)">
                            <td scope="col">{{this.selectedShookshamadashaPlanet}}/{{getSmallName(data?.name)}} </td>
                            <td scope="col">
                                {{data?.start}}
                            </td>
                            <td scope="col">{{data?.end}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'PranDasha'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planet</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [class.currentDate]="matchDateShookshamadasha(data)"
                            *ngFor="let data of kundliResponse?.pranadasha;let i = index">
                            <td scope="col">{{this.selectedPranDashaPlanet}}/{{getSmallName(data?.name)}} </td>
                            <td scope="col">
                                {{data?.start}}
                            </td>
                            <td scope="col">{{data?.end}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- Dasha main tab design end here -->


            <!-- Planets main tab design start here -->
            <ng-container *ngIf="currentMainTab == 'Planets'">
                <div class="f-style d-flex text-center  mb-3">
                    <button (click)="changeSubTab('Sign')" class="btn btn-sm mr-2 rounded py-2"
                        [ngClass]="{'active':currentSubTab == 'Sign'}">Sign</button>
                    <button (click)="changeSubTab('Nakshatra')" class="btn btn-sm mr-2 rounded py-2"
                        [ngClass]="{'active':currentSubTab == 'Nakshatra'}">Nakshatra</button>
                </div>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'Sign'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planets</th>
                            <th scope="col">Sign</th>
                            <th scope="col">Zodiac Lord</th>
                            <th scope="col">Degree</th>
                            <th scope="col">House</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{{kundliResponse?.[0]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[1]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[2]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[3]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[4]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[5]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[6]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[7]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.house}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[8]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.zodiac_lord}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.local_degree | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.house}}</td>

                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'Nakshatra'">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planets</th>
                            <th scope="col">Nakshatra</th>
                            <th scope="col">Nakshatra Lord</th>
                            <th scope="col">Pada</th>
                            <th scope="col">House</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{{kundliResponse?.[0]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[1]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[2]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[3]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[4]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[5]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[6]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[7]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.nakshatra_no}}</td>

                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[8]?.full_name}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.nakshatra}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.nakshatra_lord}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.nakshatra_pada | number:'1.2-2'}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.nakshatra_no}}</td>

                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- Planets main tab design end here -->


            <!-- Chart main tabs design start here -->
            <ng-container *ngIf="currentMainTab == 'Chart'">
                <div class=" d-flex col-12 col-md-12  pl-0 pr-0 mb-3 ">
                    <select class="form-select col-12 col-md-12 col-lg-12 mb-2 py-2" aria-label="Default select example"
                        (change)="getChartTableData($event)">
                        <option value="D3" selected>Dreshkana</option>
                        <option value="D3s">Somanatha</option>
                        <option value="D7">Saptamsa</option>
                        <option value="D9">Navamsa</option>
                        <option value="D10">Dasamsa</option>
                        <option value="D12">Dwadasamsa</option>
                        <option value="D10R">DasamsaEvenReverse</option>
                        <option value="D16">Shodashamsa</option>
                        <option value="D20">Vimsamsa</option>
                        <option value="D24">ChaturVimshamsha</option>
                        <option value="D24R">D24R</option>
                        <option value="D30">Trimshamsha</option>
                        <option value="D40">KhaVedamsa</option>
                        <option value="D45">AkshaVedamsa</option>
                        <option value="D60">Shastiamsha</option>
                    </select>
                </div>
                <div class=" d-flex col-12 col-md-12  pl-0 pr-0 mb-3 ">
                    <h6 class="pl-0 fw-600 mb-0 pr-0 align-middle">Chart Style </h6>

                    <div class="form-check  mx-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                            checked (change)="getChartImage('','north')">
                        <label class="form-check-label" for="flexRadioDefault1">
                            North Indian
                        </label>
                    </div>
                    <div class="form-check  mx-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            (change)="getChartImage('','south')">
                        <label class="form-check-label" for="flexRadioDefault2">
                            South Indian
                        </label>
                    </div>
                </div>
                <div class=" d-flex col-12 col-md-12  pl-0 pr-0 mb-3 ">
                    <div class="svg" [innerHTML]="svg"></div>
                </div>

                <table class="table table-striped table-bordered kt-res">
                    <thead class=" table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Planets</th>
                            <th scope="col">Sign</th>
                            <th scope="col">Rasi Number</th>
                            <th scope="col">House</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{{kundliResponse?.[0]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[0]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[0]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[1]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[1]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[1]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[2]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[2]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[2]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[3]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[3]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[3]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[4]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[4]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[4]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[5]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[5]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[5]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[6]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[6]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[6]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[7]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[7]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[7]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[8]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[8]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[8]?.house}}</td>
                        </tr>
                        <tr>
                            <td scope="col">{{kundliResponse?.[9]?.name}} </td>
                            <td scope="col">{{kundliResponse?.[9]?.zodiac}}</td>
                            <td scope="col">{{kundliResponse?.[9]?.rasi_no}}</td>
                            <td scope="col">{{kundliResponse?.[9]?.house}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- Chart main tabs design end here -->


            <!-- Manglik Report main tab design start here -->
            <ng-container *ngIf="currentMainTab == 'Manglik'">
                <div class="d-flex bg-light p-4 rounded border">
                    <div class="col-3 col-md-1 col-lg-1">
                        <div class="manglik-circle ">
                            <p class="text-white h4 text-center">{{kundliResponse?.is_dosha_present ?'Yes': 'No'}}</p>
                        </div>
                    </div>
                    <div class="pl-3 col-9 col-md-11 col-lg-11">
                        <p class="text-justify ml-4">{{kundliResponse?.bot_response}}
                        </p>
                    </div>
                </div>
                <div class="my-3 border rounded p-3" *ngIf="kundliResponse?.score > 0">
                    <h5 class="mb-1 txt-red">Conclusion</h5>
                    <p class="text-justify text-muted">{{kundliResponse?.factors?.mars}}</p>

                </div>
            </ng-container>
            <!-- Manglik Report main tab design end here -->


            <!-- Ascendant main tab design start here  -->
            <ng-container *ngIf="currentMainTab == 'Ascendant'">
                <table class="table table-striped table-responsive table-bordered">
                    <thead class="table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Ascendant</th>
                            <th scope="col">Ascendant Lord</th>
                            <th scope="col">Ascendant Lord Location</th>
                            <th scope="col">Ascendant Lord House Location</th>
                            <th scope="col">Verbal Location</th>
                            <th scope="col">Ascendant Lord Strength</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{{kundliResponse[0]?.ascendant}} </td>
                            <td scope="col">{{kundliResponse[0]?.ascendant_lord}}</td>
                            <td scope="col">{{kundliResponse[0]?.ascendant_lord_location}}</td>
                            <td scope="col">{{kundliResponse[0]?.ascendant_lord_house_location}}</td>
                            <td scope="col">{{kundliResponse[0]?.verbal_location}}</td>
                            <td scope="col">{{kundliResponse[0]?.ascendant_lord_strength}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">General Prediction</h5>
                    <p class="text-justify ">{{kundliResponse[0]?.general_prediction}}</p>

                </div>
                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">Personalised Prediction</h5>
                    <p class="text-justify ">{{kundliResponse[0]?.personalised_prediction}}</p>

                </div>
            </ng-container>
            <!-- Ascendant main tab design end here  -->


            <!-- Ashtakvarga main tabs design start here -->
            <ng-container *ngIf="currentMainTab == 'Ashtakvarga'">
                <table class="table table-striped  table-bordered kt-res">
                    <thead class="table table-dark text-warning k-table fw-400">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Sun</th>
                            <th scope="col">Moo</th>
                            <th scope="col">Mar</th>
                            <th scope="col">Mer</th>
                            <th scope="col">Jup</th>
                            <th scope="col">Ven</th>
                            <th scope="col">Sat</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sign of zodiacSigns;let i = index">
                            <th scope="col" class="bg-red text-white">{{sign}} </th>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[0]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[1]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[2]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[3]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[4]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[5]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_points[6]?.[i]}}</td>
                            <td scope="col">{{kundliResponse?.ashtakvarga_total[i]}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- Ashtakvarga main tabs design end here -->

            <!-- Rudhraksh  main tab design start here -->
            <ng-container *ngIf="currentMainTab == 'Rudhraksh'">
                <div class="mb-3  rounded p-3 bg-red-light">
                    <h5 class="mb-1 txt-red">Suggestion</h5>
                    <p class="text-justify ">{{kundliResponse?.bot_response}}</p>

                </div>
                <div class="mb-3  rounded p-3 bg-yellow-light">
                    <h5 class="mb-1 txt-red">Description</h5>
                    <p class="text-justify ">{{kundliResponse?.mukhi_description}}
                    </p>

                </div>
            </ng-container>
            <!-- Rudhraksh  main tab design end here -->


            <!-- Gem Suggestion main tab design start here  -->
            <ng-container *ngIf="currentMainTab == 'Gem'">
                <table class="table table-striped  table-bordered kt-res">
                    <thead class="table table-dark  k-table fw-400">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Other Name</th>
                            <th scope="col">Planet</th>
                            <th scope="col">Mantra</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{{kundliResponse?.name}} </td>
                            <td scope="col">{{kundliResponse?.other_name}}</td>
                            <td scope="col">{{kundliResponse?.planet}}</td>
                            <td scope="col">{{kundliResponse?.mantra}}</td>
                        </tr>

                    </tbody>
                </table>

                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">Description</h5>
                    <p class="text-justify ">{{kundliResponse?.description}}</p>

                </div>
                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">Good Result</h5>
                    <p class="text-justify ">{{getJoinArray(kundliResponse?.good_results)}}</p>

                </div>
                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">Diseases Cure</h5>
                    <p class="text-justify ">{{getJoinArray(kundliResponse?.diseases_cure)}}</p>

                </div>
                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">Time to wear</h5>
                    <p class="text-justify ">{{kundliResponse?.time_to_wear}}</p>

                </div>
                <div class="my-3  border rounded p-3">
                    <h5 class="mb-1 txt-red">Methods</h5>
                    <p class="text-justify ">{{kundliResponse?.methods}}</p>

                </div>
                <div class="my-3   rounded ">
                    <h5 class="mb-1 txt-red">Effects</h5>
                    <ol type="1" class="border rounded">
                        <li class="my-2 border-bottom pb-2" *ngFor="let flaw of kundliResponse?.flaw_results">
                            <strong class=" fw-600">{{flaw?.flaw_type}}- </strong><span>{{flaw?.flaw_effects}}</span>
                        </li>
                    </ol>

                </div>
            </ng-container>
            <!-- Gem Suggestion main tab design end here  -->


            <!-- Dosh main tab design start here -->
            <ng-container *ngIf="currentMainTab == 'Dosh'">
                <div class="f-style d-flex text-center  mb-3">
                    <button (click)="changeSubTab('Mangal')" class="btn btn-sm mr-2 rounded py-2"
                        [ngClass]="{'active':currentSubTab == 'Mangal'}">Mangal Dosh</button>
                    <button (click)="changeSubTab('Sarp')" class="btn btn-sm mr-2 rounded py-2"
                        [class.active]="currentSubTab == 'Sarp'">Kaal Sarp Dosh</button>
                    <button (click)="changeSubTab('Pitr')" class="btn btn-sm mr-2 rounded py-2"
                        [class.active]="currentSubTab == 'Pitr'">Pitr Dosh</button>
                    <button (click)="changeSubTab('Paapsamay')" class="btn btn-sm mr-2 rounded py-2"
                        [class.active]="currentSubTab == 'Paapsamay'">Paapsamay Dosh</button>


                </div>
                <div class="d-flex bg-light p-4 rounded border" *ngIf="currentSubTab != 'Paapsamay'">
                    <div class="col-3 col-md-1 col-lg-1">
                        <div class="manglik-circle ">
                            <p class="text-white h4 text-center">{{kundliResponse?.is_dosha_present ?'Yes': 'No'}}</p>
                        </div>
                    </div>
                    <div class="pl-3 col-9 col-md-11 col-lg-11">
                        <p class="text-justify ml-4">{{kundliResponse?.bot_response}}
                        </p>
                    </div>
                </div>
                <div class="my-3 border rounded p-3" *ngIf="currentSubTab == 'Mangal' && kundliResponse?.score > 0">
                    <h5 class="mb-1 txt-red">Conclusion</h5>
                    <p class="text-justify pb-3">{{kundliResponse?.factors?.mars}}</p>

                </div>
                <table class="table table-striped table-bordered kt-res" *ngIf="currentSubTab == 'Paapsamay'">
                    <thead class=" table table-dark k-table fw-400">
                        <tr>
                            <th scope="col">Planet</th>
                            <th scope="col">Dosh</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">Rahu</td>
                            <td scope="col">{{kundliResponse?.rahu_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Sun</td>
                            <td scope="col">{{kundliResponse?.sun_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Saturn</td>
                            <td scope="col">{{kundliResponse?.saturn_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Mars</td>
                            <td scope="col">{{kundliResponse?.mars_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Moon</td>
                            <td scope="col">{{kundliResponse?.moon_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Mercury</td>
                            <td scope="col">{{kundliResponse?.mercury_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Jupiter</td>
                            <td scope="col">{{kundliResponse?.jupiter_papa ?? 0}}</td>
                        </tr>
                        <tr>
                            <td scope="col">Venus</td>
                            <td scope="col">{{kundliResponse?.venus_papa ?? 0 }}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <!-- Dosh main tab design end here -->


            <!-- Get Sade Sati main tab design layout start here-->
            <ng-container *ngIf="currentMainTab == 'Sati'">
                <div class="card-body border-0 p-0 ">
                    <table class="table table-striped table-bordered kt-res">
                        <thead class=" table table-dark  k-table fw-400">
                            <tr>
                                <th scope="col">Considered Date</th>
                                <th scope="col">Shani Period Type</th>
                                <th scope="col">Saturn Retrograde</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{{kundliResponse?.date_considered}} </td>
                                <td scope="col">{{kundliResponse?.shani_period_type}}</td>
                                <td scope="col">{{kundliResponse?.saturn_retrograde}}</td>
                            </tr>


                        </tbody>
                    </table>
                </div>
                <div class="my-3 border rounded p-3">
                    <h5 class="mb-1 txt-red">Title</h5>
                    <p class="text-justify">{{kundliResponse?.bot_response}}</p>

                </div>
                <div class="my-3 border rounded p-3">
                    <h5 class="mb-1 txt-red">Description</h5>
                    <p class="text-justify">{{kundliResponse?.description}}</p>

                </div>
            </ng-container>
            <!-- Get Sade Sati main tab design layout end here-->

            <!-- Get Numerology main tab design layout start here-->
            <ng-container *ngIf="currentMainTab == 'Numerology'">

                <div class="mb-3  rounded p-3 bg-red-light">
                    <h5 class="mb-1 txt-red text-capitalize">destiny <span
                            class="pull-right">{{kundliResponse?.destiny?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.destiny?.meaning}}</p>

                </div>
                <div class="mb-3  rounded p-3 bg-yellow-light">
                    <h5 class="mb-1 txt-red text-capitalize">personality <span
                            class="pull-right">{{kundliResponse?.personality?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.personality?.meaning}}</p>

                </div>

                <div class="mb-3  rounded p-3 bg-red-light">
                    <h5 class="mb-1 txt-red text-capitalize">attitude <span
                            class="pull-right">{{kundliResponse?.attitude?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.attitude?.meaning}}</p>

                </div>
                <div class="mb-3  rounded p-3 bg-yellow-light">
                    <h5 class="mb-1 txt-red text-capitalize">character <span
                            class="pull-right">{{kundliResponse?.character?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.character?.meaning}}</p>

                </div>
                <div class="mb-3  rounded p-3 bg-red-light">
                    <h5 class="mb-1 txt-red text-capitalize">soul <span
                            class="pull-right">{{kundliResponse?.soul?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.soul?.meaning}}</p>

                </div>
                <div class="mb-3  rounded p-3 bg-yellow-light">
                    <h5 class="mb-1 txt-red text-capitalize">agenda <span
                            class="pull-right">{{kundliResponse?.agenda?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.agenda?.meaning}}</p>

                </div>
                <div class="mb-3  rounded p-3 bg-red-light">
                    <h5 class="mb-1 txt-red text-capitalize">purpose <span
                            class="pull-right">{{kundliResponse?.purpose?.number}}</span></h5>
                    <p class="text-justify text-capitalize">{{kundliResponse?.purpose?.meaning}}</p>

                </div>

            </ng-container>
            <!-- Get Numerology main tab design layout end here-->

            <!-- Get Bio Rhythm main tab design layout start here-->
            <ng-container *ngIf="currentMainTab == 'Bio'">

                <div class="row align-items-center d-flex p-2 shadow-sm rounded gpb-border-grnr">
                    <div class=" pl-0 d-flex align-items-center col-10">
                        <i class="fa-solid fa-person-running p-3 bg-green text-white rounded fa-lg"></i>
                        <h5 class="pl-2">Physical</h5>
                    </div>

                    <div class="text-right col-2">
                        <h6 class="">{{kundliResponse?.physical?.score}}%</h6>
                    </div>

                </div>
                <div class="row align-items-center d-flex p-2 shadow-sm rounded gpb-border-orgr  my-3">
                    <div class=" pl-0 d-flex align-items-center col-10">
                        <i class="fa-solid fa-lightbulb p-3 bg-info text-white rounded fa-lg"></i>
                        <h5 class="pl-2">Emotional</h5>
                    </div>

                    <div class="text-right col-2">
                        <h6 class="">{{kundliResponse?.emotional?.score}}%</h6>
                    </div>

                </div>
                <div class=" mb-3 row align-items-center d-flex p-2 shadow-sm rounded gpb-border-skyr">
                    <div class=" pl-0 d-flex align-items-center col-10">
                        <i class="fa-solid fa-face-grin p-3 bg-orange text-white rounded fa-2s"></i>
                        <h5 class="pl-2">Intellectual</h5>
                    </div>

                    <div class="text-right col-2">
                        <h6 class="">{{kundliResponse?.intellectual?.score}}%</h6>
                    </div>

                </div>
                <div class=" mb-3 row align-items-center d-flex p-2 shadow-sm rounded gpb-border-skyr">
                    <div class=" pl-0 d-flex align-items-center col-10">
                        <span class="material-icons p-3 bg-primary text-white rounded fa-2s">
                            description
                        </span>
                        <h5 class="pl-2">Overall</h5>
                    </div>

                    <div class="text-right col-2">
                        <h6 class="">{{getOverAll()}}%</h6>
                    </div>

                </div>
                <div class="chartjs-container d-block">
                    <canvas baseChart [colors]="chartColors" [data]="doughnutChartData" [labels]="doughnutChartLabels"
                        [chartType]="doughnutChartType">
                    </canvas>
                </div>
            </ng-container>
            <!-- Get Bio Rhythm main tab design layout end here-->

        </div>
    </div>
</section>
<app-footer></app-footer>