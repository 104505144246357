import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  Token: any = '';
  header_object;
  BASEURI: string = '';
  constructor(
    private http: HttpClient,
    private userdata: UserdataService,
    @Inject('BASE_URL') _baseUrl: string
  ) {
    this.BASEURI = _baseUrl;
    this.Token = userdata.getToken();
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
  }

  myWalletHistory(data: any) {
    // customerId
    return this.http.post(this.BASEURI + '/mywallethistory', data, {
      headers: this.header_object,
    });
  }

  singleWalletDetail(data: any) {
    // _id
    return this.http.post(this.BASEURI + '/fetchcustomerwalletbyid', data, {
      headers: this.header_object,
    });
  }

  showRechargeAmount(data: any) {
    // status:true
    return this.http.post(this.BASEURI + '/allamounts', data, {
      headers: this.header_object,
    });
  }

  rechargeAmount(data: any) {
    return this.http.post(this.BASEURI + '/generateorder', data, {
      headers: this.header_object,
    });
  }

  /**
   * Get Razorpay order_id from Server for wallet recharge
   */
  razorpayOrderId(data: any) {
    return this.http.post(this.BASEURI + '/razorPay', data, {
      headers: this.header_object,
    });
  }

  /** Apply coupon on custom amount */
  checkIsCouponApplicable(data: any) {
    return this.http.post(this.BASEURI + '/checkIsCouponApplicable', data, {
      headers: this.header_object,
    });
  }
}
