<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!--Verify Person Phone OTP-->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Verify Your Phone Number</h4>
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button> -->
  </div>
  <div class="row modal-body m-0 p-0">
    <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 m-0 p-0">
      <p class="lead p-4">The mobile number in this profile is not verified. An OTP has been sent on
        {{personForm.value.mobile}}. Kindly enter the OTP to verify your number to place order.</p>
      <form>
        <div class="text-center">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 4 }"></ng-otp-input>
        </div>
        <div class="text-center mb-4">
          <button class="w-100 btn btn-link text-danger" (click)="resendOtp()">
            Resend OTP.
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!--Verify Person Phone OTP end-->
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">Place order</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <i class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </i>
        </li>
        <li class="text-capitalize">Place order</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row card p-3 border-0 bg-transparent mb-2">
      <div class="d-flex">
        <h4 class="mb-2 flex-grow-1">Choose Profile</h4>
        <span>
          <a class="text-capitalize green-text" (click)="addNewPerson()"><i class="bottom-align material-icons-round">
              add_circle_outline </i>Add new Profile</a>

          <a *ngIf="formShow" class="ml-2 text-capitalize green-text"
            (click)="isSelectPerson=false;isAddNewPerson=false;formShow=false;"><i
              class="bottom-align material-icons-round">
              add_circle_outline </i>Back</a>
        </span>
      </div>
      <div class="row mt-2" *ngIf="!isSelectPerson && !isAddNewPerson">
        <ng-container *ngFor="let data of peopleList">
          <div class="col-sm-4 mb-2" *ngIf="!data.is_hide">
            <div class="card border-0">
              <div class=" card-body shadow-sm rounded selct-profile-bg">
                <h5 class="card-title">{{ data.name }}</h5>
                <a (click)="selectedPerson(data)"
                  class="btn btn-warning text-white bg-secondary border-white shadow-sm">Select</a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- </ng-container> -->
    </div>
    <!-- person profile new design  -->
    <div class="row justify-content-left align-items-center h-100" *ngIf="formShow">

      <div class="container shadow-lg p-5 rounded" *ngIf="formShow">
        <form [formGroup]="personForm" (ngSubmit)="addPerson()">
          <div class="form-row">
            <div class="form-group p-0 mb-2 col-md-6 cus-border-bottom">
              <h4>Person Profile</h4>
            </div>
            <div class="form-group mb-0 col-md-6">
              <!-- <button *ngIf="!isNew" type="submit" class="
                    btn
                    float-right
                    bg-secondary
                    shadow-0
                    px-3
                    py-2
                    m-0
                    text-white
                    fw-500
                    rounded
                  " [disabled]="!personForm.valid">
                <span>Update Profile</span>
              </button> -->
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-md-3">
              <label for="inputgender">Profile Type*</label><br />
              <div class="custom-control-inline">
                <input (change)="onSelfSelect()" type="radio" [value]="true" formControlName="is_self_profile" />&nbsp;
                <label class="m-0"> Self</label>
              </div>
              <div class="custom-control-inline">
                <input (change)="onSelfSelect()" type="radio" [value]="false" formControlName="is_self_profile" />&nbsp;
                <label class="m-0"> Other</label>
              </div>
              <div *ngIf="u.gender.invalid && (u.gender.touched || u.gender.dirty)">
                <small class="error-msg text-danger font-10">Gender is required. Please Enter a Valid Gender</small>
              </div>
            </div>

            <div class="form-group col-md-3">
              <label for="inputAddress">Name *</label>
              <input type="text" class="form-control" formControlName="name" />
              <div *ngIf="u.name.invalid && (u.name.dirty || u.name.touched)" class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3" *ngIf="!personForm.get('is_self_profile')?.value">
              <label for="inputAddress">Alias Name *</label>
              <input type="text" class="form-control" formControlName="detail_name" />
              <div *ngIf="
                    u.detail_name.invalid &&
                    (u.detail_name.dirty || u.detail_name.touched)
                  " class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputgender">Gender*</label><br />
              <div class="custom-control-inline">
                <input type="radio" value="male" formControlName="gender" />&nbsp;
                <label class="m-0"> Male</label>
              </div>
              <div class="custom-control-inline">
                <input type="radio" value="female" formControlName="gender" />&nbsp;
                <label class="m-0"> Female</label>
              </div>
              <div *ngIf="u.gender.invalid && (u.gender.touched || u.gender.dirty)">
                <small class="error-msg text-danger font-10">Gender is required. Please Enter a Valid Gender</small>
              </div>
            </div>
          </div>
          <div class="form-row ">
            <div class="form-group col-md-3">
              <label for="inputCity">Country Code *</label>
              <!-- <select class="form-control" formControlName="country_code" (change)="onCountrySelect($event)">
                <option *ngFor="let country of countryList" [value]="country.phone_code" data-foo="dogs">
                  {{ country.emoji }}&nbsp;&nbsp;{{
                  country.name
                  }}&nbsp;&nbsp;(+{{ country.phone_code }})
                </option>
              </select> -->
              <ng-select [searchFn]="customSearchFn" formControlName="country_code"
                class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
                bindValue="phone_code">
                <ng-template ng-option-tmp let-item="item">
                  <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                  item.name
                  }}&nbsp;&nbsp;(+{{ item.phone_code }})
                </ng-template>
              </ng-select>
              <div *ngIf="
                    u.country_code.invalid &&
                    (u.country_code.dirty || u.country_code.touched)
                  " class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputState">Mobile Number *</label>
              <input type="text" class="form-control" formControlName="mobile" id="inputZip" />
              <div *ngIf="u.mobile.invalid && (u.mobile.dirty || u.mobile.touched)" class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputZip">Marital Status *</label>
              <select class="form-control" formControlName="marital_status">
                <option value="" selected disabled>Select Status</option>
                <option>Single</option>
                <option>Married</option>
                <option>Widowed</option>
                <option>Divorced</option>
              </select>
              <div *ngIf="
                    u.marital_status.invalid &&
                    (u.marital_status.dirty || u.marital_status.touched)
                  " class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputZip">Employed-In *</label>
              <select class="form-control" formControlName="employed_in">
                <option value="" selected disabled>Select Occupation</option>
                <option>Private Job</option>
                <option>Govt Job</option>
                <option>Not Employed</option>
                <option>Student</option>
                <option>None of the above</option>
              </select>
              <div *ngIf="
                    u.employed_in.invalid &&
                    (u.employed_in.dirty || u.employed_in.touched)
                  " class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group p-0 col-md-6 cus-border-bottom">
              <h4 class="mb-2">Birth Details</h4>
            </div>
          </div>
          <div class="form-row mt-1">
            <div class="form-group  col-md-3">
              <label for="dob">Date of Birth *</label>
              <input type="date" class="form-control" formControlName="dob" name="dob" id="dob" [max]="today"
                (keydown)="disableDate()" />
              <div *ngIf="u.dob.invalid && (u.dob.dirty || u.dob.touched)" class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputZip">Time Of Birth *</label>
              <input type="time" class="form-control" formControlName="tob" />
              <div *ngIf="u.tob.invalid && (u.tob.dirty || u.tob.touched)" class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputZip">Place Of Birth (City) *</label>
              <input type="text" class="form-control" formControlName="city" />
              <div *ngIf="u.city.invalid && (u.city.dirty || u.city.touched)" class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputZip">State *</label>
              <input type="text" class="form-control" formControlName="state" />
              <div *ngIf="u.state.invalid && (u.state.dirty || u.state.touched)" class="alert alert-danger mt-1">
                Required.
              </div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputZip">Country *</label>
              <input type="text" class="form-control" formControlName="country" />
              <div *ngIf="
                    u.country.invalid && (u.country.dirty || u.country.touched)
                  " class="alert alert-danger mt-1">Required.</div>
            </div>
          </div>
          <div class="form-group p-0 col-md-12">
            <label for="inputEmail4" class="fw-500">Link Partner's Details? *</label><br />
            <div class="custom-control-inline">
              <input (change)="onChangePartner()" type="radio" [value]="false"
                formControlName="is_partner_details" />&nbsp;
              <label class="m-0"> No</label>
            </div>
            <div class="custom-control-inline">
              <input (change)="onChangePartner()" type="radio" [value]="true"
                formControlName="is_partner_details" />&nbsp;
              <label class="m-0"> Yes</label>
            </div>
          </div>
          <div class="form-row mt-3" *ngIf="this.personForm.get('is_partner_details')?.value">
            <div class="form-group p-0 col-md-6 cus-border-bottom">
              <h4 class="mb-2">Partner Details</h4>
            </div>
          </div>

          <div class="form-row mt-2" *ngIf="this.personForm.get('is_partner_details')?.value">
            <div class="form-group col-md-3">
              <label for="inputPassword4">Partner's Name</label>
              <input type="text" class="form-control" formControlName="partner_name" />
              <div *ngIf="
                    u.partner_name.invalid &&
                    (u.partner_name.dirty || u.partner_name.touched)
                  " class="alert alert-danger mt-1"></div>
            </div>

            <div class="form-group col-md-3">
              <label for="inputPassword4">Partner's Date Of Birth</label>
              <input type="date" class="form-control" formControlName="partner_dob" [max]="today"
                (keydown)="disableDate()" />
              <div *ngIf="
                    u.partner_dob.invalid &&
                    (u.partner_dob.dirty || u.partner_dob.touched)
                  " class="alert alert-danger mt-1"></div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputAddress">Partner's Time Of Birth</label>
              <input type="time" class="form-control" formControlName="partner_tob" />
              <div *ngIf="
                    u.partner_tob.invalid &&
                    (u.partner_tob.dirty || u.partner_tob.touched)
                  " class="alert alert-danger mt-1"></div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputAddress">Partner's Birth City</label>
              <input type="text" class="form-control" formControlName="partner_city" />
              <div *ngIf="
                    u.partner_city.invalid &&
                    (u.partner_city.dirty || u.partner_city.touched)
                  " class="alert alert-danger mt-1"></div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputAddress">Partner's State</label>
              <input type="text" class="form-control" formControlName="partner_state" />
              <div *ngIf="
                    u.partner_state.invalid &&
                    (u.partner_state.dirty || u.partner_state.touched)
                  " class="alert alert-danger mt-1"></div>
            </div>
            <div class="form-group col-md-3">
              <label for="inputAddress">Partner's Country</label>
              <input type="text" class="form-control" formControlName="partner_country" />
              <div *ngIf="
                    u.country.invalid &&
                    (u.partner_country.dirty || u.partner_country.touched)
                  " class="alert alert-danger mt-1"></div>
            </div>
          </div>
        </form>
        <table class="table table-bordered">
          <tbody *ngIf="
                recievedData.endpoint != 'call-astro-list' &&
                recievedData.endpoint != 'promo-call-astro-list' &&
                recievedData.endpoint != 'promo-astro-list-call'
              ">
            <tr>
              <td>Mention your problem</td>
              <td>
                <form [formGroup]="orderForm">
                  <textarea (keyup)="addQuestion($event)" (paste)="onPaste($event)" formControlName="comments"
                    placeholder="Comment" class="form-control"></textarea>
                </form>
              </td>
              <td *ngIf="
                    recievedData.endpoint != 'chat-astro-list' &&
                    recievedData.endpoint != 'promo-chat-astro-list' &&
                    recievedData.endpoint != 'promo-astro-list-chat'
                  ">
                Language
              </td>
              <td *ngIf="
                    recievedData.endpoint != 'chat-astro-list' &&
                    recievedData.endpoint != 'promo-chat-astro-list' &&
                    recievedData.endpoint != 'promo-astro-list-chat'
                  ">
                <select class="form-control" (change)="languageSelect($event)">
                  <option value="">Select Language</option>
                  <option *ngFor="let lang of langList" value="{{ lang.langId._id }}">
                    {{ lang.langId.name }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="2">
                <button *ngIf="isSelectPerson" type="button" class="
                      btn
                      text-right
                      bg-secondary
                      shadow-0
                      px-5
                      py-2
                      m-0
                      text-white
                      fw-500
                      rounded
                    " [disabled]="!orderForm.valid || !personForm.valid" (click)="addPerson()">
                  Place Order
                </button>
                <button *ngIf="isAddNewPerson" type="button" class="
                      btn
                      text-right
                      bg-secondary
                      shadow-0
                      px-5
                      py-2
                      m-0
                      text-white
                      fw-500
                      rounded
                    " [disabled]="!personForm.valid" (click)="addPerson()">
                  Place Order
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <!-- person design new end here  -->

  </div>
</section>
<app-footer></app-footer>