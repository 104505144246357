<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Checkout</h3>
      <ul
        class="fw-500 text-white w-100 float-right d-flex justify-content-end"
      >
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li><a (click)="goBack()"> Checkout</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- http://localhost:4200/#/checkout/60f65b5ccb635c4f0860d2f4 -->
<!-- hs sidebar Start -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <h3 class="fw-500">Book Now</h3>
    </div>
    <div class="d-flex mt-lg-5 mt-3 border rounded flex-column">
      <div class="row p-3">
        <div class="col-md-6 text-capitalize">
          {{ dataList.productId?.productname }}
        </div>
        <div class="col-md-6">
          <span class="float-right">
            <small
              *ngIf="
                dataList.userDetails.quantity != '' &&
                dataList.userDetails.quantity != null &&
                dataList.userDetails.quantity != undefined &&
                dataList.userDetails.quantity > 0
              "
              >Qty. {{ dataList.userDetails.quantity }} X
            </small>
            {{ dataList.productPrice | currency: cur:"symbol-narrow" }}</span
          >
        </div>
      </div>
      <div class="row p-3" *ngIf="dataList.addOnsTotal > 0">
        <div class="col-md-6">Add-On Amount</div>
        <div class="col-md-6">
          <span class="float-right">
            {{ dataList.addOnsTotal | currency: cur:"symbol-narrow" }}</span
          >
        </div>
      </div>
      <div class="row p-3" *ngIf="dataList.isCouponApplied">
        <div class="col-md-6">Coupon Discount</div>
        <div class="col-md-6">
          <span class="float-right"
            >-
            {{ dataList.couponDiscount | currency: cur:"symbol-narrow" }}</span
          >
        </div>
      </div>
      <hr />
      <div class="row p-3">
        <div class="col-md-6">Payable Amount</div>
        <div class="col-md-6">
          <span class="float-right">{{
            dataList.payableAmountWithoutGst | currency: cur:"symbol-narrow"
          }}</span>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-md-6">GST @{{ dataList.gstPercent }}%</div>
        <div class="col-md-6">
          <span class="float-right">{{
            dataList.gst | currency: cur:"symbol-narrow"
          }}</span>
        </div>
      </div>
      <hr />
      <div class="row p-3">
        <div class="col-md-6">Total Payable Amount</div>
        <div class="col-md-6">
          <span class="float-right text-success">{{
            dataList.grandTotal | currency: cur:"symbol-narrow"
          }}</span>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-md-12">
          <small class="text-muted"
            >*Astroriver wallet is not used as GST could be different</small
          >
        </div>
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <form
            #form="ngForm"
            (submit)="submit()"
            class="form-inline"
            *ngIf="!dataList.isCouponApplied; else other_content"
          >
            <label class="sr-only" for="inlineFormInputName2"
              >Apply Coupon</label
            >
            <input
              type="text"
              class="form-control mb-2 mr-sm-2"
              id="inlineFormInputName2"
              placeholder="Apply Coupon"
              #couponCode="ngModel"
              name="couponCode"
              [(ngModel)]="formModel.couponCode"
              required
            />
            <button
              class="btn bg-secondary py-2 px-5 rounded shadow-0 text-white"
              [disabled]="!form.valid"
            >
              Apply Coupon
            </button>
            <div
              *ngIf="
                couponCode.invalid && (couponCode.touched || couponCode.dirty)
              "
            >
              <p class="error-msg" *ngIf="couponCode.hasError('required')">
                Coupon Code is required
              </p>
            </div>
          </form>
          <ng-template #other_content>
            <div class="alert alert-warning text-capitalize">
              <i class="fa fa-tag mr-4"></i> {{ dataList.couponCode }}
              <button class="btn float-right" (click)="removeCoupon()">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </ng-template>
        </div>
        <div class="col-md-6">
          <button
            class="
              btn
              bg-secondary
              py-2
              px-5
              rounded
              shadow-0
              text-white
              float-right
            "
            (click)="placeOrder()"
          >
            PROCEED TO PAY
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- hs sidebar End -->
<app-footer></app-footer>
