<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">{{isNew ? 'Add New':'Update'}} Person</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <i class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </i>
        </li>
        <li>
          <a [routerLink]="['/my-profile']" class="text-white">My Profile</a>
        </li>
        <li>
          <i class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </i>
        </li>
        <li><a (click)="goBack()">{{isNew ? 'Add':'Update'}} New Person</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->

<section class="mt-5">
  <div class="container">
    <div class="row">
      <h4 class="cus-border-bottom fw-500 mb-3">{{isNew ? 'Add':'Update'}} Person</h4>
    </div>
    <div class="row rounded border p-3">
      <form [formGroup]="personForm" (submit)="addPerson()">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputEmail4">Profile Type</label><br />
            <div class="custom-control-inline">
              <input type="radio" [value]="true" (change)="onSelfSelect()" formControlName="is_self_profile" />&nbsp;
              <label class="m-0"> Self</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" (change)="onSelfSelect()" id="customRadioInline1" [value]="false"
                formControlName="is_self_profile" />&nbsp;
              <label class="m-0"> Other</label>
            </div>
            <div *ngIf="
                f.is_self_profile.invalid &&
                (f.is_self_profile.dirty || f.is_self_profile.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3" *ngIf="!personForm.get('is_self_profile')?.value">
            <label for="inputPassword4">Alias Name*</label>
            <input type="text" class="form-control" formControlName="detail_name"
              placeholder="Eg. Mother, Father, Daughter, Son etc." />
            <div *ngIf="
                f.detail_name.invalid &&
                (f.detail_name.dirty || f.detail_name.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputAddress">Name</label>
            <input type="text" class="form-control" formControlName="name" placeholder="Name" />
            <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputAddress2">Gender</label><br />
            <div class="custom-control-inline">
              <input type="radio" value="male" formControlName="gender" />&nbsp;
              <label class="m-0"> Male</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" value="female" formControlName="gender" />&nbsp;
              <label class="m-0"> Female</label>
            </div>
            <div *ngIf="f.gender.invalid && (f.gender.dirty || f.gender.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputCity">Country Code</label>
            <!-- <select class="form-control" formControlName="country_code">
              <option
                *ngFor="let country of countryList"
                value="{{ country.phone_code }}"
              >
                {{ country.emoji }}&nbsp;&nbsp;{{
                  country.name
                }}&nbsp;&nbsp;(+{{ country.phone_code }})
              </option>
            </select> -->
            <ng-select [searchFn]="customSearchFn" formControlName="country_code"
              class="c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
              bindValue="phone_code">
              <ng-template ng-option-tmp let-item="item">
                <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
                item.name
                }}&nbsp;&nbsp;(+{{ item.phone_code }})
              </ng-template>
            </ng-select>
            <div *ngIf="
                f.country.invalid && (f.country.dirty || f.country.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputState">Mobile Number</label>
            <input type="text" class="form-control" formControlName="mobile" id="inputZip" />
            <div *ngIf="f.mobile.invalid && (f.mobile.dirty || f.mobile.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">Marital Status</label>
            <select class="form-control" formControlName="marital_status">
              <option selected disabled value="">Select Marital Status</option>
              <option>Single</option>
              <option>Married</option>
              <option>Widowed</option>
              <option>Divorced</option>
            </select>
            <div *ngIf="
                f.marital_status.invalid &&
                (f.marital_status.dirty || f.marital_status.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">Employed-In</label>
            <select class="form-control" formControlName="employed_in">
              <option selected disabled value="">Select Occupation</option>
              <option>Private Job</option>
              <option>Govt Job</option>
              <option>Not Employed</option>
              <option>Student</option>
              <option>None of the above</option>
            </select>
            <div *ngIf="
                f.employed_in.invalid &&
                (f.employed_in.dirty || f.employed_in.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">Birth Place (City)</label>
            <input type="text" class="form-control" formControlName="city" placeholder="Birth Place (City)" />
            <div *ngIf="f.city.invalid && (f.city.dirty || f.city.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">State</label>
            <input type="text" class="form-control" formControlName="state" placeholder="State" />
            <div *ngIf="f.state.invalid && (f.state.dirty || f.state.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">Country</label>
            <input type="text" class="form-control" formControlName="country" placeholder="Country" />
            <div *ngIf="
                f.country.invalid && (f.country.dirty || f.country.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">Date Of Birth</label>
            <input type="date" class="form-control" formControlName="dob" [max]="today" (keydown)="disableDate()" />
            <div *ngIf="f.dob.invalid && (f.dob.dirty || f.dob.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-3">
            <label for="inputZip">Time Of Birth</label>
            <input type="time" class="form-control" formControlName="tob" />
            <div *ngIf="f.tob.invalid && (f.tob.dirty || f.tob.touched)" class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-2">
            <label for="inputEmail4">Is Partner Details?</label><br />
            <div class="custom-control-inline">
              <input type="radio" [value]="true" formControlName="is_partner_details"
                (change)="onChangePartner()" />&nbsp;
              <label class="m-0"> Yes</label>
            </div>
            <div class="custom-control-inline">
              <input type="radio" id="customRadioInline1" [value]="false" formControlName="is_partner_details"
                (change)="onChangePartner()" />&nbsp;
              <label class="m-0"> No</label>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="personForm.get('is_partner_details')?.value">
          <div class="form-group col-md-4">
            <label for="inputPassword4">Partner Name</label>
            <input type="text" class="form-control" formControlName="partner_name" />
            <div *ngIf="
                f.partner_name.invalid &&
                (f.partner_name.dirty || f.partner_name.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="inputPassword4">Partner Date Of Birth</label>
            <input type="date" class="form-control" formControlName="partner_dob" [max]="today"
              (keydown)="disableDate()" />
            <div *ngIf="
                f.partner_dob.invalid &&
                (f.partner_dob.dirty || f.partner_dob.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="inputAddress">Partner Time Of Birth</label>
            <input type="time" class="form-control" formControlName="partner_tob" />
            <div *ngIf="
                f.partner_tob.invalid &&
                (f.partner_tob.dirty || f.partner_tob.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="inputAddress">Partner City</label>
            <input type="text" class="form-control" formControlName="partner_city" />
            <div *ngIf="
                f.partner_city.invalid &&
                (f.partner_city.dirty || f.partner_city.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="inputAddress">Partner State</label>
            <input type="text" class="form-control" formControlName="partner_state" />
            <div *ngIf="
                f.partner_state.invalid &&
                (f.partner_state.dirty || f.partner_state.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="inputAddress">Partner Country</label>
            <input type="text" class="form-control" formControlName="partner_country" />
            <div *ngIf="
                f.partner_country.invalid &&
                (f.partner_country.dirty || f.partner_country.touched)
              " class="alert alert-danger mt-1">
              Required
            </div>
          </div>
        </div>
        <div class="form-row d-flex justify-content-center">
          <button type="submit" class="col-xs-4 btn bg-secondary text-white fw-500 px-5 py-2"
            [disabled]="!personForm.valid">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
<app-footer></app-footer>