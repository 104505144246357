import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChatBotHeaderService } from 'src/app/service/chat-bot/chat-bot-header.service';
import { IssueService } from 'src/app/service/issue/issue.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.css'],
})
export class CustomerSupportComponent implements OnInit {
  constructor(
    private userdata: UserdataService,
    private issueService: IssueService,
    private spinner: NgxSpinnerService,
    private chatBot: ChatBotHeaderService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private modalService: NgbModal
  ) {}
  chatForm = this.formBuilder.group({
    support_description: ['', [Validators.required]],
    support_title: ['', [Validators.required]],
  });
  ngOnInit(): void {
    this.getSupport();
  }
  supportList = new Array();
  isNew = true;
  getSupport() {
    this.spinner.show();
    this.issueService
      .getSupport({ customerId: this.userdata.getCustomerId() })
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.supportList = res.data;
          if (!this.supportList[0].is_issue_solved) this.isNew = false;
        },
        error: (err) => {
          this.spinner.hide();
          //console.log(err);
        },
      });
  }
  showChat(id: string) {
    this.chatBot.openSupportBot(id);
  }
  sendMsg() {}


  /**
   * Add Support
   */
  open(content: any) {    
    if(!this.isNew){
      this.toast.error('Sorry! You cannot raise ticket as last is opened.')
      return
    }
    this.supportForm.patchValue({ customerId: this.userdata.getCustomerId() });
    this.modalService.open(content);
  }
   supportForm: FormGroup = new FormGroup({
    is_customer: new FormControl(true),
    customerId: new FormControl('', [Validators.required]),
    support_title: new FormControl('', [Validators.required]),
    support_description: new FormControl('', [Validators.required]),
  });

  get i() {
    return this.supportForm.controls;
  }

  addSupport() {
    this.issueService.addSupport(this.supportForm?.value,true).subscribe(
      (res: any) => {
        this.getSupport();
        this.toast.success(res.message, 'Success');
        this.modalService.dismissAll()
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }
}
