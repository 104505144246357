import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from 'src/app/service/booking/booking.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.css'],
})
export class AddressDialogComponent implements OnInit {
  constructor(
    private _bookingService: BookingService,
    private formBuilder: FormBuilder,
    private _userService: UserdataService,
    private toast: ToastrService,
    private dialogRef: MatDialogRef<AddressDialogComponent>,
    private openService: OpenApiService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}
  addAddressForm = this.formBuilder.group({
    name: ['', [Validators.required]],
    _id: '',
    customerId: ['', [Validators.required]],
    countryCodePhoneNum: ['', [Validators.required]],
    phoneNum: [
      '',
      [Validators.required, Validators.pattern('[0-9]{7,10}')],
    ],
    countryCodeAltPhoneNum: '91',
    alternatePhoneNum: [
      '',
      [Validators.pattern('[0-9]{7,10}')],
    ],
    flatNo: ['', [Validators.required]],
    locality: ['', [Validators.required]],
    landmark: [''],
    city: ['', [Validators.required, Validators.pattern('[A-Za-z ]{3,}')]],
    state: ['', [Validators.required, Validators.pattern('[A-Za-z ]{3,}')]],
    country: ['', [Validators.required, Validators.pattern('[A-Za-z ]{3,}')]],
    pinCode: ['', [Validators.required]],
  });

  ngOnInit(): void {
    this.getCountries();
    //console.log('addressId', this.data);
    if (this.data.length > 0) {
      this.addAddressForm.patchValue({ _id: this.data });
      this.getSingleAddress();
    }else{
      this.addAddressForm.reset()
    }
    this.addAddressForm.patchValue({ customerId: this._userService.getId() });
  }
  saveAddress() {
    this._bookingService
      .saveAddress(this.addAddressForm.value, this.data)
      .subscribe(
        (res: any) => {
          this.toast.success(res.message, 'Success');
          this.dialogRef.close();
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  get a() {
    return this.addAddressForm.controls;
  }
  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => {},
    });
  }
  getSingleAddress() {
    this._bookingService.getSingleAddressList({ _id: this.data }).subscribe({
      next: (v) => {
        this.updateForm(v.result);
      },
      error: (e) => {
        //console.log(e);
      },
    });
  }
  updateForm(data: any) {
    this.addAddressForm.patchValue({ name: data.name });
    this.addAddressForm.patchValue({ _id: data._id });
    this.addAddressForm.patchValue({ customerId: data.customerId._id });
    this.addAddressForm.patchValue({ phoneNum: data.phoneNum });
    this.addAddressForm.patchValue({
      countryCodePhoneNum: data.countryCodePhoneNum,
    });
    this.addAddressForm.patchValue({
      countryCodeAltPhoneNum: (data.countryCodeAltPhoneNum == 0 || data.countryCodeAltPhoneNum == '0' || data.countryCodeAltPhoneNum == null || data.countryCodeAltPhoneNum == undefined) ? '':data.countryCodeAltPhoneNum,
    });
    if(data.alternatePhoneNum != 0 && data.alternatePhoneNum != '0' && data.alternatePhoneNum != null && data.alternatePhoneNum != undefined){
      this.addAddressForm.patchValue({alternatePhoneNum: data.alternatePhoneNum});
    }
    
    this.addAddressForm.patchValue({ flatNo: data.flatNo });
    this.addAddressForm.patchValue({ locality: data.locality });
    this.addAddressForm.patchValue({ landmark: data.landmark });
    this.addAddressForm.patchValue({ city: data.city });
    this.addAddressForm.patchValue({ state: data.state });
    this.addAddressForm.patchValue({ country: data.country });
    this.addAddressForm.patchValue({ pinCode: data.pinCode });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji:any){
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }
}
