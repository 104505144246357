import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-add-person',
  templateUrl: './add-person.component.html',
  styleUrls: ['./add-person.component.css'],
})
export class AddPersonComponent implements OnInit {
  langList = [];
  partnerDetails = false;
  approvednums: any = [];
  today: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';;
  constructor(
    private userdata: UserdataService,
    private loginService: LoginService,
    private toast: ToastrService,
    private openService: OpenApiService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }
  personForm: FormGroup = this.formBuilder.group({});
  selfId = '';
  userId = '';
  ngOnInit(): void {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
    this.selfId = localStorage.getItem('selfprofileId') ?? '';
    // this.getLanguages();
    this.getPeople();
    this.getCountries();
    if (this.route.snapshot.paramMap.has('id')) {
      this.userId = this.route.snapshot.paramMap.get('id') ?? '';
      this.getPerson();
    }
    this.resetForm();
  }
  getPerson() {
    this.spinner.show();
    this.loginService.getSinglePerson({ _id: this.userId }).subscribe({
      next: (v) => {
        this.spinner.hide();
        this.updateForm(v.data);
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
  isMobileVerified = false;
  isSelfProfile = false;
  isNew = false;
  updateForm(v: any) {
    this.personForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });

    this.isMobileVerified = v.is_number_verified;
    this.isSelfProfile = v._id == this.selfId;
    this.personForm.patchValue({
      is_self_profile: this.isSelfProfile,
    });
    this.personForm.patchValue({ _id: v._id });
    this.personForm.patchValue({ name: v.name });
    this.personForm.patchValue({ gender: v.gender });
    this.personForm.patchValue({ gender: v.gender });
    this.personForm.patchValue({ mobile: v.mobile });
    var dob = moment(v.dob, ['DD-MM-YYYY']).format('YYYY-MM-DD');
    this.personForm.patchValue({ dob: dob });
    var tob = moment(v.tob, ['hh:mm a']).format('HH:mm');
    this.personForm.patchValue({ tob: tob });
    this.personForm.patchValue({ city: v.city });
    this.personForm.patchValue({ state: v.state });
    this.personForm.patchValue({ country: v.country });
    this.personForm.patchValue({
      marital_status: v.marital_status,
    });
    this.personForm.patchValue({
      employed_in: v.employed_in,
    });
    if (v.is_partner_details) {
      this.personForm.patchValue({
        is_partner_details: v.is_partner_details,
      });
      this.personForm.patchValue({
        partner_name: v.partner_name,
      });
      var partner_dob = moment(v.partner_dob, ['DD-MM-YYYY']).format(
        'YYYY-MM-DD'
      );
      this.personForm.patchValue({ partner_dob: partner_dob });
      var partner_tob = moment(v.partner_tob, ['hh:mm a']).format('HH:mm');
      this.personForm.patchValue({ partner_tob: partner_tob });
      this.personForm.patchValue({
        partner_city: v.partner_city,
      });
      this.personForm.patchValue({
        partner_state: v.partner_state,
      });
      this.personForm.patchValue({
        partner_country: v.partner_country,
      });
      this.onChangePartner();
    }
    this.personForm.patchValue({
      is_number_verified: v.is_number_verified,
    });
    this.personForm.patchValue({
      country_code: v.country_code,
    });
    this.isNew = false;
  }
  resetForm() {
    this.personForm = this.formBuilder.group({
      customerId: ['', [Validators.required]],
      name: ['', [Validators.required]],
      gender: ['male', [Validators.required]],
      mobile: ['', [Validators.required]],
      dob: ['', [Validators.required, Validators.max(this.today)]],
      tob: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      marital_status: ['', [Validators.required]],
      employed_in: ['', [Validators.required]],
      is_partner_details: [false, [Validators.required]],
      is_number_verified: [true, [Validators.required]],
      partner_name: '',
      partner_dob: ['', [Validators.max(this.today)]],
      partner_tob: '',
      partner_city: '',
      partner_state: '',
      partner_country: '',
      detail_name: '',
      is_self_profile: [false, [Validators.required]],
      country_code: ['', [Validators.required]],
      _id: '',
    });
    this.personForm.patchValue({
      customerId: this.userdata.getCustomerId(),
    });
    this.isNew = true;
  }
  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }
  getPeople() {
    this.loginService
      .myPeople({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.userdata.setProfileData(res.data)
          this.approvednums = res.approvednums;
          //console.log(this.userdata.getCustomerId())
          this.loginService.myProfile({ _id: this.userdata.getCustomerId() }).subscribe(
            (res: any) => {
              this.approvednums.push({ '_id': this.userdata.getCustomerId(), 'mobile': String(res.data.phone) })
              this.userdata.setCurrentWallet(res.data.current_wallet)
            }, err => {
              this.toast.error(err.error.message, 'Error')
            }
          )
        },
        (err) => { }
      );
  }
  goBack() {
    window.history.back();
  }

  get f() {
    return this.personForm.controls;
  }
  addPerson() {
    if (this.approvednums.some((data: any) => data.mobile === this.personForm.get('mobile')?.value)) {
      this.personForm.patchValue({ is_number_verified: true });
    } else {
      this.personForm.patchValue({ is_number_verified: false });
    }
    this.spinner.show();
    this.loginService.addPeople(this.personForm.value, this.isNew).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (!res.message.is_number_verified) {
          this.isMobileVerified = false;
        }
        this.toast.success(
          `Person ${this.isNew ? 'added' : 'updated'} successfully`
        );
        //this.resetForm();
        window.history.back();
      },
      (err) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }
  onSelfSelect() {
    //console.log('self');
    if (this.personForm.get('is_self_profile')?.value == true) {
      this.personForm.get('detail_name')?.addValidators(Validators.required);
    } else {
      this.personForm.get('detail_name')?.clearValidators();
    }
  }
  onChangePartner() {
    if (this.personForm.get('is_partner_details')?.value == true) {
      this.personForm.get('partner_name')?.addValidators(Validators.required);
      this.personForm.get('partner_dob')?.addValidators(Validators.required);
      this.personForm.get('partner_tob')?.addValidators(Validators.required);
      this.personForm.get('partner_city')?.addValidators(Validators.required);
      this.personForm.get('partner_state')?.addValidators(Validators.required);
      this.personForm
        .get('partner_country')
        ?.addValidators(Validators.required);
    } else {
      this.personForm.get('partner_name')?.clearValidators();
      this.personForm.get('partner_dob')?.clearValidators();
      this.personForm.get('partner_tob')?.clearValidators();
      this.personForm.get('partner_city')?.clearValidators();
      this.personForm.get('partner_state')?.clearValidators();
      this.personForm.get('partner_country')?.clearValidators();
    }
  }
  // addPerson() {
  //   var dob = moment(this.personForm.get('dob')?.value, ['YYYY-MM-DD']).format(
  //     'DD-MM-YYYY'
  //   );
  //   this.personForm.patchValue({ dob: dob });
  //   var tob = moment(this.personForm.get('tob')?.value, ['HH:mm']).format(
  //     'hh:mm a'
  //   );
  //   this.personForm.patchValue({ tob: tob });
  //   if (
  //     this.approvednums.some(
  //       (data: any) => data.mobile === this.personForm.get('mobile')?.value
  //     )
  //   ) {
  //     this.personForm.patchValue({ is_number_verified: true });
  //   } else {
  //     this.personForm.patchValue({ is_number_verified: false });
  //   }
  //   this.loginService.addPeople(this.personForm.value, true).subscribe(
  //     (res: any) => {
  //       this.toast.success('Person Added Successfully.', 'Success');
  //       this.router.navigateByUrl('/my-profile');
  //     },
  //     (err) => {
  //       this.toast.error(err.error.message, 'Alert');
  //     }
  //   );
  // }

  // getLanguages() {
  //   this.openService.getLanguages({}).subscribe(
  //     (res: any) => {
  //       this.langList = res.data;
  //     },
  //     (err) => {
  //       console.log(err.error.message);
  //     }
  //   );
  // }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji: any) {
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }
  disableDate() {
    return false;
  }
}
