import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-single-person',
  templateUrl: './single-person.component.html',
  styleUrls: ['./single-person.component.css'],
})
export class SinglePersonComponent implements OnInit {
  id: any = '';
  dataList: any = '';
  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getSinglePerson();
  }

  getSinglePerson() {
    this.loginService.getSinglePerson({ _id: this.id }).subscribe(
      (res: any) => {
        this.dataList = res.data;
      },
      (err) => {}
    );
  }
  goBack() {
    window.history.back();
  }
}
