import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ProductcategoryService {

  BaseUrl: string = ''
  Token: any = '';

  constructor(
    private userdata: UserdataService,
    private http: HttpClient, @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  /**
   * Show all product Categories
   */
   public allProductCategory(data: any) {
    return this.http.post(this.BaseUrl + "/allproductcategory", data);
  }

  /**
   * Detail Category
   */
   public detailCategory(data: any) {
    return this.http.post(this.BaseUrl + "/fetchproductcategorybyid", data);
  }

}
