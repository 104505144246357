import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { SupportService } from 'src/app/service/support/support.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private supportService: SupportService,
    private openService: OpenApiService
  ) { }

  ngOnInit(): void {
  }

  contactForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[+]{1}[0-9]{5,}')]),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    terms: new FormControl('', Validators.requiredTrue),
  })

  get f() {
    return this.contactForm.controls
  }

  submit() {
    if (this.contactForm.get('name')?.value == '' || this.contactForm.get('email')?.value == '' || this.contactForm.get('phone')?.value == '' || this.contactForm.get('subject')?.value == '' || this.contactForm.get('message')?.value == '') {
      this.toast.error('Please Fill All Fields', 'Error')
      return
    }
    this.spinner.show()
    this.openService.addContactUs(this.contactForm.value).subscribe(
      (res: any) => {
        this.toast.success(res.message, 'Success')
        this.contactForm.reset()
        this.spinner.hide()
      }, err => {
        this.toast.error(err.error.message, 'Error')
        this.spinner.hide()
      }
    )
  }

  openSocialLink(data: any) {
    var url: any;
    switch (data) {
      case 'facebook': {
        url = 'https://www.facebook.com/astroriverdotcom/'
        break;
      }
      case 'instagram': {
        url = 'https://www.instagram.com/astroriverofficial/'
        break;
      }
      case 'quora': {
        url = 'https://astroriver.quora.com'
        break;
      }
      case 'pinterest': {
        url = 'https://www.pinterest.com/astroriver/'
        break;
      }
      case 'twitter': {
        url = 'https://www.twitter.com/astroriver'
        break;
      }
      case 'linkedin': {
        url = 'https://www.linkedin.com/company/astro-river/'
        break;
      }
    }
    (window as any).open(url, "_blank");
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
