import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IssueService } from 'src/app/service/issue/issue.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-issue-detail',
  templateUrl: './issue-detail.component.html',
  styleUrls: ['./issue-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueDetailComponent implements OnInit {

  dataList:any = ''
  id:any = ''
  constructor(
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private issueService: IssueService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getSingleIssue()
    this.modalService.dismissAll()
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getSingleIssue(){
    this.issueService.issueDetail({customerId:this.userdata.getCustomerId(),_id:this.id}).subscribe(
      (res:any) =>{
        this.dataList = res.data
        //console.log(res)
        this.issueChatForm.patchValue({
          _id: this.dataList?._id,
        });
        this.issueChatForm.patchValue({
          customerId: this.userdata.getCustomerId(),
        });
      },err=>{
        this.router.navigateByUrl('/issue')
      }
    )
  }

  /**
   * issue form
   */
   issueChatForm: FormGroup = new FormGroup({
    customerId: new FormControl(''),
    _id: new FormControl('', [Validators.required]),
    reply_by_system: new FormControl('', [Validators.required]),
    is_reply_by_customer: new FormControl(true),
  });

  replyIssue() {
    this.issueService.replyIssue(this.issueChatForm?.value).subscribe(
      (res: any) => {
        this.getSingleIssue();
        this.toast.success(res.message, 'Success');
        this.issueChatForm.patchValue({
          reply_by_system: '',
        });
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
      }
    );
  }

}
