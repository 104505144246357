<app-header #headerOne></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">
        Astro Mall
      </h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize">
          <a class="text-white" [routerLink]="['/astro-shop']">Astro Shop</a>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- info Cards Start -->
<section class="my-4">
  <div class="container pl-0">
    <div class="row m-0">
      <div class="col-12 col-md-4 my-2 m-left">
        <div class="col-12 d-flex slign-items-center">
          <img class="size-50 rounded-circle border p-2 mr-2" src="../../../assets/icons/trust.png" />
          <h5 class="">
            <strong>Trusted</strong><br />
            <span>Since 2000</span>
          </h5>
        </div>
      </div>
      <div class="col-12 col-md-4 my-2 m-left">
        <div class="col-12 d-flex slign-items-center">
          <img class="size-50 rounded-circle border p-2 mr-2" src="../../../assets/icons/smile.png" />
          <h5 class="">
            <strong>50,000,000</strong><br />
            <span>Happy Customers</span>
          </h5>
        </div>
      </div>
      <div class="col-12 col-md-4 my-2 m-left">
        <div class="col-12 d-flex slign-items-center">
          <img class="size-50 rounded-circle border p-2 mr-2" src="../../../assets/icons/world.png" />
          <h5 class="">
            <strong>Users From</strong><br />
            <span>180 Countries</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</section>
<!--  info Cards End -->
<!-- Search and Filter Start -->
<section class="">
  <div class="container">    
    <div class="d-lg-none d-md-none mt-3">
      <ngx-select-dropdown (searchChange)="searchProduct($event)" (change)="selectProduct($event)"
        [config]="searchConfig" [options]="searchProducts"></ngx-select-dropdown>
    </div>
  </div>
</section>
<!-- Search and Filter End -->

<!-- Product List start -->
<section class="mt-lg-5 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-3 d-none d-lg-block">
        <div class="container mb-2" *ngIf="isLogin" >
          <div class="row d-flex flex-row search align-items-end">
            <div *ngIf="isLogin" class="bg-green rounded py-2 px-4">
              <h6 class="text-center text-white fn-0">Your Balance</h6>
              <h4 class="fn-2 fw-500 text-white">
                {{ walletBalance | currency: myProfile?.currencyCode }}
              </h4>
            </div>
            <a *ngIf="isLogin" (click)="goToRecharge()"
              class="shadow-0 btn cus-border-green p-0 py-2 m-0 px-3 mx-2 text-body">
              Recharge
            </a>
      
          </div>
        </div>
        <div class="list-group card bg-active p-0 h-100">
          <ul class="mall-category">
            <li>
              <span class="
                  list-group-item list-group-item-action
                  bg-green
                  fw-500
                  text-white text-center
                " aria-current="true">
                <i class="fas fa-th-large float-left"></i>
                All Categories
              </span>
            </li>
            <ng-container *ngIf="categoryList">
              <ng-container *ngFor="let category of categoryList; let i = index">
                <li (click)="showCategoryProduct(category?._id)" class="category-list"
                  *ngIf="category.is_product_associated">
                  <a class="
                      text-black-50
                      align-items-center
                      collapsed
                      text-capitalize
                    ">
                    {{ category?.categoryname }}
                  </a>
                </li>
                <li [routerLink]="['/astro-shop',category?._id]" class="category-list"
                  *ngIf="category.is_subcategory_associated">
                  <a class="
                      text-black-50
                      align-items-center
                      collapsed
                      text-capitalize
                    ">
                    {{ category?.categoryname }}
                  </a>
                </li>
              </ng-container>
            </ng-container>

            <!-- <li>
              <a href="#" class="list-group-item list-group-item-action text-center" aria-current="true">
                <i class="fas fa-plus float-left"></i>More Categories
              </a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-9 col-12">
        <div class="container mb-4">
          <div class="row d-flex justify-content-between">
            <ngx-select-dropdown class="d-none d-lg-flex d-md-flex flex-grow-1" (searchChange)="searchProduct($event)"
              (change)="selectProduct($event)" [config]="searchConfig" [options]="searchProducts"></ngx-select-dropdown>
            <div ngbDropdown class="d-inline-block">
              <button class="shadow-0 btn cus-border-active p-0 m-0 mx-1 px-1" id="dropdownBasic1" ngbDropdownToggle>
                <span class="material-icons"> sort </span>
              </button>
              <div class="filter p-0" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <ul class="list-group">
                  <li class="list-group-item" [ngClass]="{ 'filter-active': filterType == 1 }">
                    <a class="" (click)="applyFilter(-1, 1)">Price: High to Low</a>
                  </li>
                  <li class="list-group-item" [ngClass]="{ 'filter-active': filterType == 2 }">
                    <a class="" (click)="applyFilter(1, 2)"> Price: Low to High</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- no data available -->
        <section class="container  order-bg1 mb-5 py-3" *ngIf="dataList.length == 0">
          <div class=" row n-data-img justify-content-center">
            <img src="../../../../assets/images/no-data.svg" />
          </div>
          <div>
          </div>
        </section>
        <div class="row" *ngIf="dataList.length > 0">
          <h4 class="col-12 mb-4 text-capitalize">{{categoryName}}</h4>
          <ng-container *ngFor="let latest of dataList">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 my-2">
              <div class="product-grid rounded">
                <div class="product-image">
                  <a [routerLink]="['/product-detail', latest.product_slug]">
                    <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                    <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                  </a>
                  <span class="product-sale-label fn-1 fw-500">{{ latest.offer_percentage }}% OFF</span>
                </div>
                <div class="product-content">
                  <h3 class="fn-2 fw-600 text-capitalize text-black text-left">
                    <a [routerLink]="['/product-detail', latest.product_slug]">{{ latest.productname }}</a>
                  </h3>
                  <div class="price fn-1 fw-500 text-left">
                    {{
                    getCurrentPrice(latest)
                    | currency: (code ? "INR" : "USD"):"symbol-narrow"
                    }}
                    <span *ngIf = "latest?.is_direct_price">{{
                      getOrginialPrice(latest)
                      | currency: (code ? "INR" : "USD"):"symbol-narrow"
                      }}</span>
                  </div>
                  <div class="product-button-group">
                    <a class="add-to-cart fn-1 fw-500 text-uppercase rounded" (click)="book(latest._id)"><i class="fa fa-shopping-bag"></i> &nbsp;ADD TO
                      CART</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="d-flex justify-content-center" *ngIf="totalPages > 1">
          <button (click)="loadMore()" class="
              shadow-0
              btn
              rounded-pill
              px-4
              py-2
              text-capitalize
              bg-green
              text-white
            ">
            <i class="fi fi-spinner-refresh text-white"></i>&nbsp;&nbsp;Load More
          </button>
        </div>
      </div>

    </div>

  </div>
</section>
<!-- Product List End -->

<!-- Product Banner Start-->
<section class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-orange">
          <img class="rounded-circle size-60 bg-orange p-1" src="../../../assets/icons/fast-delivery.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              text-center
              align-self-center
              w-100
            ">
            Fast <strong>Delivery</strong>
          </h4>
        </div>
      </div>
      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-secondary h-100">
          <img class="size-50" src="../../../assets/images/product/24-by-7.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              text-center
              align-self-center
              w-100
            ">
            Support <strong>24/7</strong>
          </h4>
        </div>
      </div>

      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-green">
          <img class="rounded-circle size-60 bg-green p-2" src="../../../assets/icons/shield-w.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              text-center
              align-self-center
              w-100
            ">
            100% <strong>Safe & Secure</strong>
          </h4>
        </div>
      </div>
      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-active1">
          <img class="rounded-circle size-60 bg-secondary p-2" src="../../../assets/icons/telemarketer.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              text-center
              align-self-center
              w-100
            ">
            Customer Support <strong>+91 70091-27641</strong>
          </h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Banner End-->
<app-footer></app-footer>