import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.css'],
})
export class WaitingListComponent implements OnInit {
  dataList: any = [];

  constructor(
    private userdata: UserdataService,
    private router: Router,
    private toast: ToastrService,
    private orderService: OrderServicesService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getWaitingList();
  }

  getWaitingList() {
    this.spinner.show()
    this.orderService
      .showWaitingList({
        customerId: this.userdata.getCustomerId(),
        is_user_notified: false,
      })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.spinner.hide()
        },
        (err) => {
          this.toast.error(err.error.message);
          this.spinner.hide()
        }
      );
  }

  removeWaitingList(id: any) {
    this.spinner.show();
    this.orderService
      .removeWaitingList({
        customerId: this.userdata.getCustomerId(),
        waitingId: id,
      })
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.toast.success(res.message, 'Success');
          this.getWaitingList();
        },
        (err) => {
          this.spinner.hide();
          this.toast.error(err.error.message, 'Alert');
        }
      );
  }

  convertSecondstoTime(val: any) {
    return this.datePipe.transform(val * 1000, 'mm:ss');
  }

  confirmFirst(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.removeWaitingList(id);
      }
    });
  }
}
