<h4 mat-dialog-title>Add Address</h4>
<mat-dialog-content class="mat-typography order-bg">
  <form class="row" [formGroup]="addAddressForm" (submit)="saveAddress()">
    <div class="col-4 form-group">
      <label for="name">Name*</label>
      <input type="text" class="form-control" placeholder="Example: Home, Office, Work etc." formControlName="name" />
      <div *ngIf="a.name.invalid && (a.name.touched || a.name.dirty)">
        <small class="error-msg text-danger fn-0">Name is required. Please Enter a Valid Name</small>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="flatNo">Flat/House Number*</label>
      <input type="text" class="form-control" placeholder="Enter Your Flat or House Number" formControlName="flatNo" />
      <div *ngIf="a.flatNo.invalid && (a.flatNo.touched || a.flatNo.dirty)">
        <small class="error-msg text-danger fn-0">Flat No is Required</small>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="locality">Locality*</label>
      <input type="text" class="form-control" placeholder="Enter Your Locality" formControlName="locality" />
      <div *ngIf="a.locality.invalid && (a.locality.touched || a.locality.dirty)">
        <small class="error-msg text-danger fn-0">Locality is Required</small>
      </div>
    </div>

    <div class="col-4 form-group">
      <label for="landmark">LandMark</label>
      <input type="text" class="form-control" placeholder="Enter Your landmark (Optional)" formControlName="landmark" />
      <div *ngIf="a.landmark.invalid && (a.landmark.touched || a.landmark.dirty)">
        <small class="error-msg text-danger fn-0">LandMark is Required</small>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="city">City*</label>
      <input type="text" class="form-control" placeholder="Enter Your City" formControlName="city" />
      <div *ngIf="a.city.invalid && (a.city.touched || a.city.dirty)">
        <small class="error-msg text-danger fn-0">City is required.</small>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="state">State*</label>
      <input type="text" class="form-control" placeholder="Enter Your State" formControlName="state" />
      <div *ngIf="a.state.invalid && (a.state.touched || a.state.dirty)">
        <small class="error-msg text-danger fn-0">State is required.</small>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="country">Country*</label>
      <input type="text" class="form-control" placeholder="Enter Your Country" formControlName="country" />
      <div *ngIf="a.country.invalid && (a.country.touched || a.country.dirty)">
        <small class="error-msg text-danger fn-0">Country is required.</small>
      </div>
    </div>
    <div class="col-4 form-group">
      <label for="pinCode">Pincode*</label>
      <input type="text" class="form-control" placeholder="Enter Your Pincode" formControlName="pinCode" />
      <div *ngIf="a.pinCode.invalid && (a.pinCode.touched || a.pinCode.dirty)">
        <small class="error-msg text-danger fn-0">Pincode is required.</small>
      </div>
    </div>
    <div class="col-6 form-group">
      <label for="name">Contact Number*</label>
      <div class="d-flex row px-3">
        <!-- <select name="countryCode" class="form-control w-40" formControlName="countryCodePhoneNum">
          <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
            {{ country.emoji }}&nbsp;&nbsp;{{ country.name }}&nbsp;&nbsp;(+{{
            country.phone_code
            }})
          </option>
        </select> -->
        <ng-select [searchFn]="customSearchFn" formControlName="countryCodePhoneNum"
          class="col-5 c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
          bindValue="phone_code" placeholder="Select Country Code">
          <ng-template ng-option-tmp let-item="item">
            <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
            item.name
            }}&nbsp;&nbsp;(+{{ item.phone_code }})
          </ng-template>
        </ng-select>
        <input type="text" class="col-7 form-control w-60" placeholder="Enter Contact Number" formControlName="phoneNum" />
      </div>
      <div *ngIf="
          (a.countryCodePhoneNum.invalid &&
            (a.countryCodePhoneNum.touched || a.countryCodePhoneNum.dirty)) ||
          (a.phoneNum.invalid && (a.phoneNum.touched || a.phoneNum.dirty))
        ">
        <small class="error-msg text-danger fn-0">Contact Number is required. Please Enter a Valid Contact
          Number.</small>
      </div>
    </div>
    <div class="col-6 form-group">
      <label for="name">Alternate Number(Optional)</label>
      <div class="d-flex row px-3">
        <!-- <select class="form-control w-40" formControlName="countryCodeAltPhoneNum">
          <option *ngFor="let country of countryList" value="{{ country.phone_code }}">
            {{ country.emoji }}&nbsp;&nbsp;{{ country.name }}&nbsp;&nbsp;(+{{
            country.phone_code
            }})
          </option>
        </select> -->
        <ng-select [searchFn]="customSearchFn" formControlName="countryCodeAltPhoneNum"
          class="col-5 c-size p-0 m-0 border-0 mob-f-bor form-control" [items]="countryList" bindLabel="phone_code"
          bindValue="phone_code" placeholder="Select Country Code">
          <ng-template ng-option-tmp let-item="item">
            <img [src]="getFlag(item.iso2)">&nbsp;&nbsp;{{
            item.name
            }}&nbsp;&nbsp;(+{{ item.phone_code }})
          </ng-template>
        </ng-select>
        <input type="text" class="col-7 form-control w-60" placeholder="Enter Alternate Number"
          formControlName="alternatePhoneNum" />
      </div>
      <div *ngIf="
          (a.countryCodeAltPhoneNum.invalid &&
            (a.countryCodeAltPhoneNum.touched ||
              a.countryCodeAltPhoneNum.dirty)) ||
          (a.alternatePhoneNum.invalid &&
            (a.alternatePhoneNum.touched || a.alternatePhoneNum.dirty))
        ">
        <small class="error-msg text-danger fn-0">Please Enter a Valid Alternate Contact Number.</small>
      </div>
    </div>
    <div class="w-100 row m-0 p-2">
      <div class="col-12 text-center form-group text-danger" *ngIf="addAddressForm.invalid">
        Fields with * are required.
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button type="submit" class="btn btn-success mr-2" [disabled]="addAddressForm.invalid">
          Submit
        </button>
        <button mat-button mat-dialog-close class="btn py-2 px-3 bg-inactive shadow-0 fw-500">
          Cancel
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>


<!-- <div class="card">
  <div class="card-header"><h4 class="fw-500"></h4></div>
  <div class="card-body">
    
  </div>
</div> -->