import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(
    private toast: ToastrService,
    private loginService: LoginService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  showCheckOtp = false
  email = ''
  isChangePassword = false

  ngOnInit(): void {
    this.modalService.dismissAll()
  }

  sendOtpForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });

  get f(){
    return this.sendOtpForm.controls
  }

  changePasswordForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('', [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()_+-]).{8,}$')]),
  });

  get c(){
    return this.changePasswordForm.controls
  }


  requestOtpForget(){
    this.email = this.sendOtpForm.get('email')?.value
    this.loginService.sendOtpForgetPassword(this.sendOtpForm.value).subscribe(
      (res:any)=>{
        this.showCheckOtp = true
        this.toast.success(res.message,'Success')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  onOtpChange(event:any){
    if(event.length == 4){
      this.loginService.verifyOtpForgetPassword({email:this.email,otp:event}).subscribe(
        (res:any)=>{
          this.isChangePassword = true
          this.toast.success(res.message,'Success')
        },err =>{
          this.toast.error(err.error.message,'Error')
        }
      )
    }
  }

  changePassword(){
    this.changePasswordForm.patchValue({'email':this.email})
    this.loginService.changePassword(this.changePasswordForm.value).subscribe(
      (res:any)=>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
