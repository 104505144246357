<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- hs About Title Start -->
<div class="hs_indx_title_main_wrapper">
  <div class="hs_title_img_overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
        <div class="hs_indx_title_left_wrapper">
          <h2>Astro Mall</h2>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 full_width">
        <div class="hs_indx_title_right_wrapper">
          <ul>
            <li><a [routerLink]="['/']">Home</a> &nbsp;&nbsp;&nbsp;> </li>
            <li><a [routerLink]="['/astro-shop']">&nbsp; Astro Mall</a> &nbsp;&nbsp;&nbsp;></li>
            <li>{{dataList[0]?.categoryId.categoryname}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- hs About Title End -->
<!-- hs sidebar Start -->
<div class="hs_blog_categories_main_wrapper">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="hs_kd_first_sec_wrapper" id="showresult">
          <h2>subCategory</h2>
          <h4><span></span></h4>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="hs_blog_right_search_wrapper">
          <form>
            <input type="text" placeholder="Search.." name="name" (keyup)="searchProduct($event)">
            <button type="button"><i class="fa fa-search"></i></button>
          </form>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isSearch">
      <div [routerLink]="['/astro-product',subcategory._id]" class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let subcategory of dataList">
        <div class="hs_shop_prodt_main_box">
          <div class="hs_shop_prodt_img_wrapper">
            <img [src]="getSanitizeUrl(subcategory.subcategory_image)" class="img img-responsive img-category"
              alt="shop_product">
            <a href="javascript:void(0)">View More</a>
          </div>
          <div class="hs_shop_prodt_img_cont_wrapper">
            <h2><a href="javascript:void(0)">{{ subcategory.subcategoryname }}</a></h2>
            <div class="text-truncate font-14">{{subcategory.subcategorydescription}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isSearch">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-4 pr-4" *ngIf="searchList?.length <= 0">
        <h2>No Data Available.</h2>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngFor="let products of searchList">
        <div class="hs_shop_prodt_main_box">
          <div class="hs_shop_prodt_img_wrapper">
            <img [src]="getSanitizeUrl(products.product_image[0])" alt="shop_product"
              class="img img-responsive img-category">
            <a [routerLink]="['/product-detail',products.product_slug]">View Details</a>
          </div>
          <div class="hs_shop_prodt_img_cont_wrapper">
            <h2><a [routerLink]="['/product-detail',products.product_slug]">{{ products.productname }}</a></h2>
            <h6>{{ products.productdescription }}</h6>
            <!-- <span *ngIf="products.is_astrologer_associate">
              <a [routerLink]="['/booking-astrologer',products._id]" class="btn btn-warning float-right">Book Now</a>
            </span> -->
            <!-- <span *ngIf="!products.is_astrologer_associate"> -->
              <a [routerLink]="['/order-product',products._id]" class="btn btn-warning float-right">Book Now</a>
            <!-- </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- hs sidebar End -->

<app-footer></app-footer>