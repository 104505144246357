import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root',
})
export class IssueService {
  BaseUrl: string = '';
  Token: any = '';
  header_object;
  constructor(
    private userdata: UserdataService,
    private http: HttpClient,
    @Inject('BASE_URL') _base: string
  ) {
    this.BaseUrl = _base;
    this.header_object = new HttpHeaders({
      Authorization: this.userdata.getToken() || '',
      country_code: this.userdata.getCountryCode() || 'in',
    });
    this.Token = userdata.getToken();
  }
  // Get Support
  getSupport(data: any) {
    return this.http.post(this.BaseUrl + '/allmysupport', data, {
      headers: this.header_object,
    });
  }
  // Support Chat
  getSupportChat(data: any) {
    return this.http.post<any>(this.BaseUrl + '/singlesupportbyid', data, {
      headers: this.header_object,
    });
  }
  addSupport(data: any, isAdd: boolean) {
    if (isAdd)
      return this.http.post<any>(this.BaseUrl + '/addsupport', data, {
        headers: this.header_object,
      });
    else
      return this.http.post<any>(this.BaseUrl + '/replytosupport', data, {
        headers: this.header_object,
      });
  }

  /**
   * Add Issue
   */
  public addIssue(data: any) {
    return this.http.post(this.BaseUrl + '/addcustomerissue', data, {
      headers: this.header_object,
    });
  }
  /**
   * Add Issue
   */
  public replyIssue(data: any) {
    //console.log(data);
    return this.http.post(this.BaseUrl + '/replytoissue', data, {
      headers: this.header_object,
    });
  }

  /**
   * Issue List
   */
  public issueList(data: any) {
    //customerId
    return this.http.post(this.BaseUrl + '/allmyissues', data, {
      headers: this.header_object,
    });
  }

  /**
   * Issue Details
   */
  public issueDetail(data: any) {
    //customerId,_id
    return this.http.post(this.BaseUrl + '/singleissuebyid', data, {
      headers: this.header_object,
    });
  }
}
