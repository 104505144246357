import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { FirebaseServiceService } from 'src/app/service/firebase/firebase-service.service';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ScrollToBottomDirective } from '../ongoing-chat-customer/scroll-to-bottom.directive';
import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, update } from "firebase/database";

@Component({
  selector: 'app-ongoing-groupchat-customer',
  templateUrl: './ongoing-groupchat-customer.component.html',
  styleUrls: ['./ongoing-groupchat-customer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OngoingGroupchatCustomerComponent implements OnInit {
  /**
   * Scroll to bottom
   */
  @ViewChild(ScrollToBottomDirective) scroll: any;
  /**----------------------------- */

  BaseUrl: any;
  id: any;
  dataList: any;
  initialMsg: any;
  isChatComplete: any;
  chatsRef: any;
  chatList: any;
  //{ key: string; date: string; message: string; msgtype: string; name: number; type: string; is_to_show: string; maxChatDuration: string; }[];
  constructor(
    private route: ActivatedRoute,
    private orderService: OrderServicesService,
    private toast: ToastrService,
    private userdata: UserdataService,
    private db: AngularFireDatabase,
    private firebaseService: FirebaseServiceService,
    private router: Router,
    private _lightbox: Lightbox,
    private cdref: ChangeDetectorRef,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getGroupChatDetail();
  }

  getGroupChatDetail() {
    this.orderService.detailGroupChat({ _id: this.id }).subscribe(
      (res: any) => {
        this.dataList = res.data;
        this.initialMsg = this.dataList.initial_msg;
        this.isChatComplete = ((this.dataList.is_order_completed) || (this.dataList.orderId.refundStatus > 1));
        if (this.isChatComplete) {
          this.chatList = this.dataList.chatinfo;
        }
        this.chatsRef = this.db.object('groupchat/' + this.id);
        this.retrieveAllChats();
        this.childAdded();
        this.childChanged();
      },
      (err: any) => {
        this.toast.error(err.error.message, 'Alert');
        this.router.navigateByUrl(
          '/store-order-list/' + this.dataList?.orderId?._id
        );
      }
    );
  }

  /**
   * Retrieve all Chat from Backend
   */
  retrieveAllChats() {
    this.firebaseService
      .getGroupChatList(this.id)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
      .subscribe(
        (chat) => {
          this.chatList = chat;
          if (this.chatList.length <= 0) {
            this.chatList = this.dataList.chatinfo;
            this.isChatComplete = true;
          }
        },
        (error: any) => {
          //console.log(error);
        }
      );
  }

  public childAdded() {
    var demo = this;
    var i = 0;
    const db = getDatabase();
    const commentsRef = ref(db, 'groupchat/' + this.id);
    const chatId = this.id
    //this.chatsRef.on('child_added', function (snapshot: any) {
    onChildAdded(commentsRef, function (snapshot: any) {
      var data = snapshot.val();
      var key = snapshot.key;
      //console.log(data, key);
      if (
        typeof data.is_read_customer != 'undefined' &&
        !data.is_read_customer
      ) {
        const commentsRefNew = ref(db, 'groupchat/' + chatId + '/' + key);
        update(commentsRefNew, { is_read_customer: true })
        //demo.chatsRef.child(key).update({ is_read_customer: true });
        //console.log(data, i, key)
      }
      if (key == '- Typing') {
        if (data.astrologer == true) {
          demo.astrologerTyping = true;
        } else {
          demo.astrologerTyping = false;
        }
        if (data.admin == true) {
          //console.log(data.admin)
          demo.adminTyping = true;
        } else {
          demo.adminTyping = false;
        }
        //console.log(demo.adminTyping)
      }
      if (data.chat_status == 2 || (data.message == 'Your order has been completed')) {
        demo.isChatComplete = true;
        demo.chatList = demo.dataList.chatinfo;
      }
      // if (data.message == 'Your order has been completed') {
      //   demo.isChatComplete = true;
      //   demo.chatList = demo.dataList.chatinfo;
      // }
      // demo.chatList.push(data)
      i++;
    });
  }

  /**
   * Child Changed
   */
  astrologerTyping: boolean = false;
  adminTyping: boolean = false;

  childChanged() {
    var demo = this;
    const db = getDatabase();
    const commentsRef = ref(db, 'groupchat/' + this.id);
    onChildChanged(commentsRef, function (snapshot: any) {
      //this.chatsRef.on('child_changed', function (snapshot: any) {
      //console.log(snapshot.key)
      var data = snapshot.val();
      var key = snapshot.key;
      if (key == '- Typing') {
        if (data.astrologer == true) {
          demo.astrologerTyping = true;
        } else {
          demo.astrologerTyping = false;
        }
        if (data.admin == true) {
          //console.log(data.admin)
          demo.adminTyping = true;
        } else {
          demo.adminTyping = false;
        }
        //console.log(demo.adminTyping)
      }
    });
  }
  /**
   * On Input focus show customer typing true
   */
  onKeyUpInput(event: any) {
    //console.log(event)
    const db = getDatabase();
    const commentsRef = ref(db, 'groupchat/' + this.id + '/- Typing');
    if (event.target.value.length > 0) {
      update(commentsRef, { customer: true })
      // this.chatsRef.child('- Typing').update({ customer: true });
    } else {
      update(commentsRef, { customer: false })
      //this.chatsRef.child('- Typing').update({ customer: false });
    }
  }

  /**
   * On Input Blur show customer typing true
   */
  onBlurInput(event: any) {
    //console.log('blur')
    const db = getDatabase();
    const commentsRef = ref(db, 'groupchat/' + this.id + '/- Typing');
    update(commentsRef, { customer: false })
    //this.chatsRef.child('- Typing').update({ customer: false });
  }

  /**
   * Send Chat Message
   */
  isImageUpload = false;
  sendMsgForm = new FormGroup({
    chatId: new FormControl(''),
    customerId: new FormControl(''),
    message: new FormControl('', [Validators.required]),
    name: new FormControl(''),
    is_to_show: new FormControl(true),
    isImage: new FormControl(false),
    isFile: new FormControl(false),
    type: new FormControl(3), //1-Admin, 2-Astrologer, 3-Customer
  });

  get f() {
    return this.sendMsgForm.controls;
  }

  sendMsg() {
    this.sendMsgForm.patchValue({ chatId: this.id });
    this.sendMsgForm.patchValue({ customerId: this.userdata.getId() });
    this.sendMsgForm.patchValue({ name: this.userdata.getName() });
    if (!this.isImageUpload) {
      //console.log('not image')
      var msg = this.sendMsgForm.get('message')?.value.trim();
      if (msg != '' && msg != null) {
        //console.log('msg not null')
        var count = (msg.match(/\d/g) || []).length;
        var emailsArray = msg.match(
          /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
        );
        if (count == 10 || (emailsArray != null && emailsArray.length)) {
          //console.log('error yes')
          this.sendMsgForm.patchValue({ is_to_show: false });
          //console.log(this.sendMsgForm.get('is_to_show')?.value, 'if')
        }
        this.sendMsgForm.patchValue({ message: msg });
        const data = new FormData();
        data.append('chatId', this.sendMsgForm.get('chatId')?.value);
        data.append('customerId', this.sendMsgForm.get('customerId')?.value);
        data.append('message', this.sendMsgForm.get('message')?.value);
        data.append('name', this.sendMsgForm.get('name')?.value);
        data.append('is_to_show', this.sendMsgForm.get('is_to_show')?.value);
        data.append('isImage', this.sendMsgForm.get('isImage')?.value);
        data.append('isFile', this.sendMsgForm.get('isFile')?.value);
        data.append('type', '3');
        //console.log(this.sendMsgForm.get('is_to_show')?.value, 'after if')
        this.orderService.sendMsgGroupChat(data).subscribe(
          (res: any) => {
            this.sendMsgForm.reset();
            const db = getDatabase();
            const commentsRef = ref(db, 'groupchat/' + this.id + '/- Typing');
            //this.chatsRef.child('- Typing').update({ customer: false });
            update(commentsRef, { customer: false })
            this.sendMsgForm.patchValue({ message: '' });
            this.sendMsgForm.patchValue({ is_to_show: true });
            this.sendMsgForm.patchValue({ isImage: false });
            this.sendMsgForm.patchValue({ isFile: false });
            this.sendMsgForm.patchValue({ type: 3 });
            this.f;
            //console.log(this.sendMsgForm.get('is_to_show')?.value, 'result')
          },
          (err: any) => {
            this.toast.error(err.error.message, 'Alert');
          }
        );
      }
    }
    if (this.isImageUpload) {
      const data = new FormData();
      data.append('chatId', this.sendMsgForm.get('chatId')?.value);
      data.append('customerId', this.sendMsgForm.get('customerId')?.value);
      data.append('message', this.sendMsgForm.get('message')?.value);
      data.append('name', this.sendMsgForm.get('name')?.value);
      data.append('is_to_show', this.sendMsgForm.get('is_to_show')?.value);
      data.append('isImage', this.sendMsgForm.get('isImage')?.value);
      data.append('isFile', this.sendMsgForm.get('isFile')?.value);
      data.append('type', '3');
      //console.log(this.sendMsgForm.get('is_to_show')?.value, 'after if')
      this.orderService.sendMsgGroupChat(data).subscribe(
        (res: any) => {
          this.sendMsgForm.reset();
          this.chatsRef.child('- Typing').update({ customer: false });
          this.sendMsgForm.patchValue({ message: '' });
          this.sendMsgForm.patchValue({ is_to_show: true });
          this.sendMsgForm.patchValue({ isImage: false });
          this.sendMsgForm.patchValue({ isFile: false });
          this.sendMsgForm.patchValue({ type: 3 });
          this.f;
          //console.log(this.sendMsgForm.get('is_to_show')?.value, 'result')
        },
        (err: any) => {
          this.toast.error(err.error.message, 'Alert');
        }
      );
    }
  }

  checkEnterPressed(event: any) {
    if (event.keyCode === 13) {
      this.sendMsg();
    }
  }

  /**
   * M3- Attachements
   */

  /**
   * Get attachment type
   */
  checkExtenstion(val: any) {
    var fileName, fileExtension;
    fileName = val;
    fileExtension = fileName
      .substr(fileName.lastIndexOf('.') + 1)
      .toLowerCase();
    if (
      fileExtension == 'jpg' ||
      fileExtension == 'png' ||
      fileExtension == 'jpeg'
    ) {
      return 0;
    } else if (
      fileExtension == 'doc' ||
      fileExtension == 'docx' ||
      fileExtension == 'txt'
    ) {
      return 1;
    } else if (
      fileExtension == 'xlsx' ||
      fileExtension == 'xls' ||
      fileExtension == 'csv'
    ) {
      return 2;
    } else if (fileExtension == 'pdf') {
      return 3;
    } else {
      return 4;
    }
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  public getOpenUrl(url: string) {
    // return this.trusturl.bypassSecurityTrustUrl(
    //   'http://docs.google.com/viewer?url=' + this.BaseUrl + url
    // );
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
  private _albums: any = [];
  open(val: any): void {
    this._albums = [];
    const album = {
      src: this.BaseUrl + val,
    };
    this._albums.push(album);
    // open lightbox
    this._lightbox.open(this._albums, 0, {
      wrapAround: true,
      showImageNumberLabel: true,
      fitImageInViewPort: true,
      centerVertically: true,
    });
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onFileSelected(evnt: any) {
    // console.log(evnt.target.files[0].type)
    // return
    if (evnt.target.files.length > 0) {
      if (evnt.target.files.length > 0) {
        if (evnt.target.files && evnt.target.files[0]) {
          var ext = evnt.target.files[0].name.split('.').pop();
          const allowed_ext = ['png', 'jpeg', 'jpg', 'pdf', 'csv', 'doc', 'docx', 'xlsx']
          const allowed_types = [
            'image/png',
            'image/jpeg',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain',
            'text/csv',
            'application/doc',
            'application/ms-doc',
            'application/msword',
            'application/excel',
            'application/vnd.ms-excel',
          ];
          if (!allowed_types.includes(evnt.target.files[0].type) && !allowed_ext.includes(ext.toLowerCase())) {
            Swal.fire(
              'Invalid File Extension',
              'Only Images,Doc, PDF, Excel are allowed',
              'error'
            );
            return;
          } else {
            this.sendMsgForm.patchValue({
              message: evnt.target.files[0],
            });
            this.sendMsgForm.patchValue({
              isImage: true,
            });
            if (
              evnt.target.files[0].type == 'image/png' ||
              'image/jpeg' ||
              'image/jpg'
            ) {
              this.sendMsgForm.patchValue({
                isFile: false,
              });
            } else {
              this.sendMsgForm.patchValue({
                isFile: true,
              });
            }
            this.isImageUpload = true;
            this.sendMsg();
          }
        }
      }
    }
  }
}
