import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { razorpayKey } from 'src/app/endpoints';
import { BookingService } from 'src/app/service/booking/booking.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
declare var Razorpay: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  BaseUrl = '';
  dataList: any = [];
  saveOrderId: string = '';

  formModel = {
    _id: '',
    couponCode: '',
  };
  cur = 'INR';
  constructor(
    private booking: BookingService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngOnInit(): void {
    this.saveOrderId = this.route.snapshot.paramMap.get('_id') || '';
    if (!this.userdata.isIndia()) {
      this.cur = 'USD';
    }
    this.getOrderDetail();
    //console.log(this.previousUrl,this.currentUrl)
  }

  /**
   * Get Order Details
   */
  getOrderDetail() {
    this.spinner.show();
    this.booking.checkOut({ _id: this.saveOrderId }).subscribe(
      (res: any) => {
        //console.log(res.data);
        this.dataList = res.data;
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Apply Coupon
   */
  submit() {
    this.formModel._id = this.saveOrderId;
    this.booking.applyCoupon(this.formModel).subscribe(
      (res: any) => {
        // console.log(res)
        this.toast.success('Success', 'Coupon Applied Successfully');
        this.getOrderDetail();
      },
      (err: any) => {
        // console.log(err: any)
        this.toast.error('Error', err.error.message);
      }
    );
  }

  /**
   * Remove Coupon
   */
  removeCoupon() {
    this.booking.removeCoupon({ _id: this.saveOrderId }).subscribe(
      (res: any) => {
        // console.log(res)
        this.toast.success('Success', 'Coupon Removed Successfully');
        this.getOrderDetail();
      },
      (err: any) => {
        // console.log(err: any)
        this.toast.error('Error', err.error.message);
      }
    );
  }

  options = {
    key: razorpayKey, // Enter the Key ID generated from the Dashboard
    amount: this.dataList.grandTotal, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: this.cur,
    name: this.userdata.getName(),
    description: 'Using AstroRiver Service',
    // "order_id": '', //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: (res: any) => {
      this.spinner.show();
      this.booking
        .placeOrder({
          _id: this.saveOrderId,
          isOrderPaid: true,
          transactionId: res.razorpay_payment_id,
        })
        .subscribe(
          (res: any) => {
            this.toast.success(res.message, 'Success');
            //this.location.back()
            //if(this.previousUrl == null){
            this.router.navigateByUrl('/store-order-list');
            // }else{
            //   this.router.navigateByUrl(this.previousUrl)
            // }
            this.spinner.hide();
          },
          (err: any) => {
            this.spinner.hide();
            this.toast.error(err.error.message, 'Alert');
          }
        );
    },
    prefill: {
      name: this.userdata.getName(),
      email: this.userdata.getEmail(),
      contact: this.userdata.getPhone(),
    },
  };

  rzp1: any;
  placeOrder() {
    this.booking
      .razorPay({ amount: this.dataList.grandTotal, isProductOrder: true })
      .subscribe(
        (res: any) => {
          this.options.amount = res.value.amount;
          // this.options.order_id = this.saveOrderId;

          // console.log(this.options)
          // return
          this.options.currency = this.cur;
          this.rzp1 = new Razorpay(this.options);
          this.rzp1.open();
        },
        (err: any) => {
          //console.log(err);
        }
      );
  }
  goBack() {
    window.history.back();
  }
}
