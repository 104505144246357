import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IssueService } from 'src/app/service/issue/issue.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { WalletService } from 'src/app/service/wallet/wallet.service';

@Component({
  selector: 'app-add-wallet-issue',
  templateUrl: './add-wallet-issue.component.html',
  styleUrls: ['./add-wallet-issue.component.css']
})
export class AddWalletIssueComponent implements OnInit {

  dataList:any = ''
  id:any = ''
  
  constructor(
    private walletService: WalletService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private issueService: IssueService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getSingleWallet()
  }

  getSingleWallet(){
    this.walletService.singleWalletDetail({_id:this.id}).subscribe(
      (res:any)=>{
        this.dataList = res.data
        //console.log(res)
      },err=>{

      }
    )
  }

  /**
   * Issue
   */
   issueForm: FormGroup = new FormGroup({
    customerId: new FormControl(''),
    issue_type: new FormControl('1', [Validators.required]),
    is_wallet_issue: new FormControl(true, [Validators.required]),
    issue_title: new FormControl('', [Validators.required]),
    issue_description: new FormControl('', [Validators.required]),
    walletId: new FormControl(''),
    amount: new FormControl(''),
  });

  get i() {
    return this.issueForm.controls
  }

  addIssue(){
    //console.log(this.dataList._id)
    this.issueForm.patchValue({customerId:this.userdata.getCustomerId()})
    this.issueForm.patchValue({walletId:this.dataList._id})
    this.issueForm.patchValue({amount:this.dataList.amount})
    this.issueService.addIssue(this.issueForm.value).subscribe(
      (res:any)=>{
        this.router.navigateByUrl('/wallet')
        this.toast.success(res.message,'Success')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
