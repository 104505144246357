import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogsService } from 'src/app/service/blogs/blogs.service';

@Component({
  selector: 'app-show-blog-list',
  templateUrl: './show-blog-list.component.html',
  styleUrls: ['./show-blog-list.component.css']
})
export class ShowBlogListComponent implements OnInit {
  BaseUrl: any;
  blogCategory: any;

  constructor(
    private blogsService: BlogsService,
    private route: ActivatedRoute,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
    this.BaseUrl = _imageurl
  }

  ngOnInit(): void {
    this.getBlogCategory()
    this.getAllBlogs()
  }

  /** Get Blog All Category */
  categoryId = ''
  getBlogCategory() {
    this.blogsService.getBlogCategory({ status: true }).subscribe(
      (res: any) => {
        this.blogCategory = res.data
      }, err => {
        this.toast.error(err.error.message, 'Alert')
      }
    )
  }

  // get all blogs
  blogs: any = [];
  getAllBlogs() {
    this.spinner.show()
    this.blogsService.getBlogList({ isApproved: true }).subscribe(
      (res: any) => {
        this.blogs = res.data;
        this.spinner.hide()
      }, err => {
        this.toast.error(err.error.message, 'Alert')
        this.spinner.hide()
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }

  blogByCategory(id: any) {
    this.spinner.show()
    this.categoryId = id
    var obj: any = { isApproved: true }
    if (id != '') {
      obj.categoryId = id
    }
    this.blogsService.getBlogList(obj).subscribe(
      (res: any) => {
        this.blogs = res.data
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message, 'Alert')
      }
    )
  }

  searchblog(data: any) {
    var obj: any = { isApproved: true }
    if (data.length > 0) {
      obj.search = data
    }      
    this.blogsService.getBlogList(obj).subscribe(
      (res: any) => {
        this.blogs = res.data
      }, err => {
        this.toast.error(err.error.message, 'Alert')
      }
    )
  }

}
