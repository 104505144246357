import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-policy-page',
  templateUrl: './policy-page.component.html',
  styleUrls: ['./policy-page.component.css'],
})
export class PolicyPageComponent implements OnInit {
  constructor(
    private _openService: OpenApiService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  slug = '';
  breadcrumb = '';
  ngOnInit(): void {
    const url = this.router.url.split('/');
    switch (url[1]) {
      case 'privacy-policy':
        this.slug = 'privacy';
        this.breadcrumb = 'Privacy Policy';
        break;
      case 'pricing-policy':
        this.slug = 'pricing';
        this.breadcrumb = 'Pricing Policy';
        break;
      case 'terms-conditions':
        this.slug = 'terms';
        this.breadcrumb = 'Terms & Conditions';
        break;
      case 'disclaimer':
        this.slug = 'disclaimer';
        this.breadcrumb = 'Disclaimer';
        break;
      case 'refund-policy':
        this.slug = 'refund';
        this.breadcrumb = 'Refund Policy';
        break;
      case 'return-policy':
        this.slug = 'shipping';
        this.breadcrumb = 'Return Policy';
        break;
      case 'faq':
        this.slug = 'faq';
        this.breadcrumb = "FAQ's";
        break;
    }
    this.getPageContent();
  }
  pageContent = '';
  private getPageContent() {
    this.spinner.show();
    this._openService.getPage(this.slug).subscribe({
      next: (v: any) => {
        this.pageContent = v.result.description;
        if (!v.success) {
          this.toastr.error(v.message);
        }
      },
      error: (error) => {
        //console.log('page', error);
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }
}
