import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/service/login/login.service';
import { MessagingService } from 'src/app/service/messaging/messaging.service';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';
import { UserdetailsService } from 'src/app/service/userdetails/userdetails.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  @Input() registerData: any;
  isSocialRegister: boolean = false;

  constructor(
    private loginService: LoginService,
    private toast: ToastrService,
    private router: Router,
    private messagingService: MessagingService,
    private userdataService: UserdataService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private userDetailsService: UserdetailsService,
    private http: HttpClient,
    private authService: SocialAuthService,
    private openService: OpenApiService
  ) { }
  isRegister = false;
  ngOnInit(): void {
    this.getCountries()
    this.messagingService.requestPermission();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.authService.authState.subscribe((user) => {
      if (user != null && user != undefined) {
        this.registerData = user;
        this.isSocialRegister = true;
        this.registerForm.patchValue({ name: this.registerData.name });
        this.registerForm.patchValue({ email: this.registerData.email });
        this.registerForm.patchValue({ social_id: this.registerData.id });
        this.registerForm.patchValue({ password: this.registerData.id });
        this.registerForm.patchValue({ is_social_login: true });
        this.registerForm.patchValue({
          social_type: this.isGoogle ? 1 : 2,
        });
        if (this.isRegister) {
          this.registerCustomer();
          this.signOut();
        }
      }
    });
  }
  isGoogle = true;

  registerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('[0-9]{10,}'),
    ]),
    is_terms_accepted: new FormControl(true, [Validators.requiredTrue]),
    is_social_login: new FormControl(false),
    social_type: new FormControl(''), //Google=1, FB=2, Apple=3
    social_id: new FormControl(''),
    regCountryCode: new FormControl(''),
    country_code: new FormControl(''),
    regCountryName: new FormControl(''),
  });

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    is_terms_accepted: new FormControl(true, [Validators.requiredTrue]),
    is_web: new FormControl(true, [Validators.requiredTrue]),
    device_type: new FormControl('web', [Validators.required]),
    fcm_token: new FormControl(''),
    is_social_login: new FormControl(false),
    social_id: new FormControl(''),
  });

  isLoggedIn: boolean = false;
  registerCustomer() {
    if (!this.isSocialRegister) {
      var pattern =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()_+-]).{8,}$/;
      if (!pattern.test(this.registerForm.get('password')?.value)) {
        this.toast.error(
          'Your password must be of at least 8 characters, must contain a capital letter, a small letter, a number, a symbol and should not contain space.'
        );
        return;
      }
    }
    this.registerForm.patchValue({
      regCountryCode: (this.userdataService.getCountryCode()).toUpperCase(),
    });
    var obj = this.countryList.find((x: any) => x.iso2 == (this.userdataService.getCountryCode()).toUpperCase());
    if (obj == undefined) {
      this.toast.error('Your registered country and the current country are different. So signup with the current country mobile number')
      return
    } else {
      if (this.registerForm.get('country_code')?.value != obj.phone_code) {
        this.toast.error('Your registered country and the current country are different. So signup with the current country mobile number')
        return
      } else {
        this.registerForm.patchValue({
          regCountryName: obj.name,
        });
      }
    }
    // console.log(this.registerForm.value,obj)
    // return
    this.spinner.show();
    this.loginService.register(this.registerForm.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.toast.success(res.message, 'Success');
        //this.router.navigateByUrl('/login')
        this.loginForm.patchValue({
          fcm_token: this.userdataService.getFcm(),
        });
        this.loginForm.patchValue({
          password: this.registerForm.get('password')?.value,
        });
        this.loginForm.patchValue({
          is_social_login: this.registerForm.get('is_social_login')?.value,
        });
        if (this.isSocialRegister) {
          this.loginForm.patchValue({
            social_id: this.registerForm.get('social_id')?.value,
          });
        } else {
          this.loginForm.patchValue({
            email: this.registerForm.get('email')?.value,
          });
        }
        this.loginService.login(this.loginForm.value).subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res.success) {
              this.userdataService.setData(res);
              this.modalService.dismissAll();
              this.userDetailsService.onChangeOfUserDetailHandler(res.token);
              this.isLoggedIn = true;
              if (!res.customerdetail.is_otp_verified) {
                this.router.navigate(['/verify-phone-otp', res.data.email]);
              } else {
                this.modalService.dismissAll();
                this.toast.success('Thank you for signing up', 'Success');
                this.router.navigateByUrl('/home');
                // location.reload()
              }
            } else {
              this.toast.error(res.message, 'Alert');
            }
          },
          (err: any) => {
            this.spinner.hide();
            this.toast.error(err.error.message, 'Alert');
            this.modalService.dismissAll();
          }
        );
      },
      (err: any) => {
        this.spinner.hide();
        this.toast.error(err.error.message, 'Alert');
        this.modalService.dismissAll();
      }
    );
    // (err: any) => {
    //   console.log('No Country Found');
    // };
  }

  get f() {
    return this.registerForm.controls;
  }

  /**
   * Register with Google
   */
  public signUpWithGoogle(): void {
    this.isRegister = true;
    this.isGoogle = true;
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  regFacebook() {
    if (!this.checkPhone()) {
      this.toast.error('Please enter valid phone number');
      return;
    }

    this.isRegister = true;
    this.isGoogle = false;
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  signOut(): void {
    this.authService.signOut(true);
  }
  tooglePassword = false;
  toggleEye() {
    this.tooglePassword = !this.tooglePassword;
  }
  checkPhone() {
    const phone = this.registerForm.get('phone')?.value;
    if (
      typeof phone == 'undefined' ||
      phone == null ||
      phone.length == 0 ||
      phone.length < 7 ||
      phone.length > 10
    )
      return false;
    else return true;
  }

  countryList = new Array();
  getCountries() {
    this.openService.getCountryList().subscribe({
      next: (v) => {
        this.countryList = v.data;
      },
      error: (e) => {
        //console.log(e.error);
      },
      complete: () => { },
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.phone_code.toString().toLocaleLowerCase().indexOf(term) > -1;
  }

  getFlag(emoji: any) {
    var emojiU = emoji.toString().toLocaleLowerCase()
    return `https://flagcdn.com/24x18/${emojiU}.png`
  }
}
