import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-general-prediction',
  templateUrl: './general-prediction.component.html',
  styleUrls: ['./general-prediction.component.css']
})
export class GeneralPredictionComponent implements OnInit {
  originalZodiaList: any;
  BaseUrl: any;

  constructor(private router: Router, private openService: OpenApiService, private toast: ToastrService, private spinner: NgxSpinnerService, private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }

  ngOnInit(): void {
    this.changeSubTab('DAILY')
    this.getZodiac()
  }

  currentMainTab = 'DAILY'
  searchData: any = {};
  response: any;
  zodiac: any = 1

  changeSubTab(data: any) {
    this.searchData = ''
    if (data == 'DAILY') {
      this.currentMainTab = 'DAILY'
      this.searchData = {
        apiaddress: 'prediction/daily-moon',
        date: moment().format('DD/MM/YYYY'),
        zodiac: this.zodiac,
        show_same: true,
        split: true,
        type: 'big'
      }
    }
    if (data == 'WEEKLY') {
      this.currentMainTab = 'WEEKLY'
      this.searchData = {
        apiaddress: 'prediction/weekly-moon',
        date: moment().format('DD/MM/YYYY'),
        zodiac: this.zodiac,
        show_same: true,
        split: true,
        type: 'big',
        week: 'thisweek'
      }
    }
    if (data == 'DAILYSUN') {
      this.currentMainTab = 'DAILYSUN'
      this.searchData = {
        apiaddress: 'prediction/daily-sun',
        date: moment().format('DD/MM/YYYY'),
        zodiac: this.zodiac,
        show_same: true,
        split: true,
        type: 'big'
      }
    }
    if (data == 'WEEKLYSUN') {
      this.currentMainTab = 'WEEKLYSUN'
      this.searchData = {
        apiaddress: 'prediction/weekly-sun',
        date: moment().format('DD/MM/YYYY'),
        zodiac: this.zodiac,
        show_same: true,
        split: true,
        type: 'big',
        week: 'thisweek'
      }
    }
    this.getDataFromApi()
  }

  getDataFromApi() {
    this.spinner.show()
    this.openService.getGeoLocation(this.searchData).subscribe(
      (res: any) => {
        this.response = res.data
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message)
      }
    )
  }

  zodiacConfig: SwiperOptions = {
    slidesPerView: 5,
    direction: 'horizontal',
    spaceBetween: 5,
    effect: 'slide',
    loop: false,
    scrollbar: false,
    navigation: {
      nextEl: '.nav-left',
      prevEl: '.nav-right',
    },
  };

  getZodiac() {
    this.openService.dailyHoroscopeZodiac({ isRandomRequired: true }).subscribe(
      (res: any) => {
        this.originalZodiaList = res.data;
      },
      (err: any) => {
        //console.log(err);
      }
    );
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  changeZodiac(i:any){
    this.zodiac = Number(i)+1
    this.changeSubTab(this.currentMainTab)
  }

  getJoin(data:any){
    return data.join()
  }

}
