<app-header #headerOne></app-header>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Astro Shop</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li>
          <a class="text-white" [routerLink]="['/astro-shop']">
            Astro Shop</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li>
          <a class="text-white" [routerLink]="['/astro-shop',productData?.productcategoryId?._id]">
            {{productData?.productcategoryId?.categoryname}}</a>
        </li>
        <ng-container *ngIf="productData?.productcategoryId?.is_subcategory_associated">
          <li>
            <span class="fn-2 material-icons-round bottom-align">
              arrow_forward_ios
            </span>
          </li>

          <li>
            <a class="text-white" [routerLink]="['/product/subcategory',productData?.productsubcategoryId?._id]">
              {{productData?.productsubcategoryId?.subcategoryname}}</a>
          </li>
        </ng-container>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li>
          <a class="text-white">
            {{productData?.productname}}</a>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<!-- info Cards Start -->
<section class="my-4">
  <div class="container px-0">
    <div class="row m-0">
      <div class="col-12 col-md-4 my-2">
        <div class="p-0 d-flex slign-items-center">
          <img class="size-50 rounded-circle border p-2 mr-2" src="../../../assets/icons/trust.png" />
          <h5 class="">
            <strong>Trusted</strong><br />
            <span>Since 2000</span>
          </h5>
        </div>
      </div>
      <div class="col-12 col-md-4 my-2">
        <div class=" d-flex slign-items-center">
          <img class="size-50 rounded-circle border p-2 mr-2" src="../../../assets/icons/smile.png" />
          <h5 class="">
            <strong>50,000,000</strong><br />
            <span>Happy Customers</span>
          </h5>
        </div>
      </div>
      <div class="col-12 col-md-4 my-2">
        <div class=" d-flex slign-items-center">
          <img class="size-50 rounded-circle border p-2 mr-2" src="../../../assets/icons/world.png" />
          <h5 class="">
            <strong>Users From</strong><br />
            <span>180 Countries</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</section>
<!--  info Cards End -->

<!--Product Detail Start -->
<section class="my-5">
  <div class="container px-0">
    <div class="row justify-content-center">
      <div class="col-md-10 col-12 px-0">
        <div class="row m-0">
          <div class="col-md-1 col-3">
            <a class="position-absolute left-btn">
              <i class="fi fi-angle-up"></i>
            </a>
            <swiper [config]="config" class="row position-absolute top-0 bottom-0 img-swiper">
              <ng-template swiperSlide class="align-items-end d-flex"
                *ngFor="let pro_img of productData?.product_image">
                <img [src]="getSanitizeUrl(pro_img)" class="size-60 rounded border swiper-slide-img cursor-pointer"
                  (click)="productImage = pro_img" />
              </ng-template>
            </swiper>

            <a class="position-absolute right-btn">
              <i class="fi fi-angle-down"></i>
            </a>
          </div>
          <div class="col-7 col-md-4 px-0 mx-4 ml-0">
            <img [src]="getSanitizeUrl(productImage)" class="w-100 border rounded" style="height: 240px" />
          </div>
          <div class="col-md-6 col-11 p-detail text-justify">
            <h5 class="fw-600 text-capitalize">
              {{ productData?.productname }}
            </h5>
            <ngb-rating class="ml-n1" [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
            ({{reviewData?.totalcount}})
            <div class="fn-1 line-size-10 " [innerHtml]="productData?.productdescription"></div>
          </div>
        </div>
        <div class="row mt-4 px-5">
          <div class="col-md-3 col-12 p-0 pr-2 text-center">
            <div class=" border rounded bg-inactive p-1 my-1">
              <img src="../../../assets/icons/delivery-time.png" class="size-30 p-1" />
              <small class="text-capitalize fw-700">delivery 3-7 working days</small>
            </div>
          </div>
          <div class="col-md-3 col-12 p-0 pl-2 m-left my-1">
            <div class="border rounded bg-inactive p-1 text-center">
              <img src="../../../assets/icons/free.png" class="size-30 p-1" />
              <small class="text-capitalize fw-700">get free consultation</small>
            </div>
          </div>
          <div class="col-12 col-md-6 px-zero">
            <a (click)="book(productData?._id)" class="
                fw-500
                py-2
                btn
                bg-secondary
                text-white text-uppercase
                m-0
                w-100
              "><i class="fa fa-shopping-cart"></i>
              add to cart
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-6 order-bg p-3 border rounded">

        <div class="d-flex flex-column">
          <div class="card shadow py-5 border-0 text-center">

            <h4 class="fw-600 price text-success">
              {{getPrice(productData) | currency: (code ? "INR" : "USD"):"symbol-narrow"}}
            </h4>
            <p class="fn-1">
              <del class="inactive-text fw-500" *ngIf = "productData?.is_direct_price">{{
                productData?.original_amount | currency: "INR":"symbol-narrow"
                }}</del>
                <br *ngIf = "productData?.is_direct_price"><span class="active-text fw-600"> {{ productData?.offer_percentage }}% OFF</span>
            </p>
            <small class="text-captilize fw-500">All Inclusive</small>
          </div>
          <!-- <p>
            <ng-container *ngFor="let add of productData?.addon_detail.slice(0, 3)">
              <img [src]="getSanitizeUrl(add.addon_image)" class="size-30" />
            </ng-container>
          </p>
          <select class="bg-green mt-3 form-control" *ngIf="productData?.addon_detail.length > 0">
            <option *ngFor="let add of productData?.addon_detail" value="{{ add._id }}">
              {{ add.addon_name }}
            </option>
          </select> -->

          <!-- <div class="form-group" class="mt-3">
            <label class="">Select Quantity</label>

            <select class="form-control">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div> -->
          <div class="row m-0 mt-4 payment-card">
            <img src="../../../assets/images/payment-cards.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Detail End -->

<!-- Product Banner Start-->
<section class=" my-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-orange">
          <img class="rounded-circle size-50 bg-orange p-1" src="../../../assets/icons/fast-delivery.png" />
          <h4 class="
              text-capitalize
              fw-500
              txt-org
              fn-2
              text-center
              align-self-center
              w-100
            ">
            Fast <strong>Delivery</strong>
          </h4>
        </div>
      </div>
      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-secondary h-100">
          <img class="size-50" src="../../../assets/images/product/24-by-7.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              text-center
              align-self-center
              w-100
            ">
            Support <strong>24/7</strong>
          </h4>
        </div>
      </div>

      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-green">
          <img class="rounded-circle size-50 bg-green p-2" src="../../../assets/icons/shield-w.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              txt-green
              text-center
              align-self-center
              w-100
            ">
            100% <strong>Safe & Secure</strong>
          </h4>
        </div>
      </div>
      <div class="col-12 col-md-3 my-1">
        <div class="d-flex rounded py-2 px-3 cus-border-active1">
          <img class="rounded-circle size-50 bg-secondary p-2" src="../../../assets/icons/telemarketer.png" />
          <h4 class="
              text-capitalize
              fw-500
              fn-2
              text-center
              txt-red
              align-self-center
              w-100
            ">
            Customer Support <strong>+91 70091-27641</strong>
          </h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Banner End-->

<!-- Description & Review Start -->
<section class="">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="p-0 h-100  border card">
          <div class="card-header bg-green">
            <h5 class="text-capitalize fw-600 text-white">product detail</h5>
          </div>
          <div class="card-body  my-dots">
            <p class="fn-1" [innerHtml]="productData?.productdescription"></p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 m-top">
        <div class="p-0 h-100  border card">
          <div class="card-header bg-secondary-2">
            <h5 class="text-capitalize fw-600">rating & reviews</h5>
          </div>
          <div class="card-body">
            <div class="row bg-inactive rounded m-0 p-3 text-center">
              <div class="col-4">
                <p class="fn-3 fw-600">
                  {{ reviewData?.avgcount }}
                  <span class="material-icons-round"> grade </span>
                </p>
                <small class="inactive-text">{{ reviewData?.totalcount }} Ratings</small>
                <br>
                <small class="inactive-text">&</small>
                <br>
                <small class="inactive-text">{{ reviews?.length }} Reviews</small>
              </div>
              <!-- <small class="inactive-text"
                    >5 <span class="material-icons-round"> grade </span></small
                  > -->
              <div class="col-8 d-flex flex-column">
                <div class="rating mb-n1 d-flex align-items-center">
                  <span class="inactive-text fn-0 mr-2">5
                    <span class="material-icons-round fn-0"> grade </span>
                  </span>
                  <div class="progress w-75">
                    <div class="progress-bar bg-success" role="progressbar" aria-valuenow="25" id="prog-5"
                      aria-valuemin="0" aria-valuemax="reviewData.totalcount"></div>
                  </div>
                  <small class="inactive-text fn-0 ml-4 mr-2">{{reviewData?.star5}}</small>
                </div>
                <div class="rating mb-n1 d-flex align-items-center">
                  <span class="inactive-text fn-0 mr-2">4
                    <span class="material-icons-round fn-0"> grade </span></span>
                  <div class="progress w-75">
                    <div class="progress-bar bg-success" role="progressbar" id="prog-4" aria-valuenow="50"
                      aria-valuemin="0" aria-valuemax="reviewData.totalcount"></div>
                  </div>
                  <small class="inactive-text fn-0 ml-4 mr-2">{{reviewData?.star4}}</small>
                </div>
                <div class="rating mb-n1 d-flex align-items-center">
                  <span class="inactive-text fn-0 mr-2">3
                    <span class="material-icons-round fn-0"> grade </span></span>
                  <div class="progress w-75">
                    <div class="progress-bar bg-info" role="progressbar" aria-valuenow="75" id="prog-3"
                      aria-valuemin="0" aria-valuemax="reviewData.totalcount"></div>
                  </div>
                  <small class="inactive-text fn-0 ml-4 mr-2">{{reviewData?.star3}}</small>
                </div>
                <div class="rating mb-n1 d-flex align-items-center">
                  <span class="inactive-text fn-0 mr-2">2
                    <span class="material-icons-round fn-0"> grade </span></span>
                  <div class="progress w-75">
                    <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="100" id="prog-2"
                      aria-valuemin="0" aria-valuemax="reviewData.totalcount"></div>
                  </div>
                  <small class="inactive-text fn-0 ml-4 mr-2">{{reviewData?.star2}}</small>
                </div>
                <div class="rating mb-1 d-flex align-items-center">
                  <span class="inactive-text fn-0 mr-2">1
                    <span class="material-icons-round fn-0"> grade </span></span>
                  <div class="progress w-75">
                    <div class="progress-bar bg-danger" role="progressbar" id="prog-1" aria-valuenow="100"
                      aria-valuemin="0" aria-valuemax="reviewData.totalcount"></div>
                  </div>
                  <small class="inactive-text fn-0 ml-4 mr-2">{{reviewData?.star1}}</small>
                </div>
              </div>
            </div>
            <div class="row m-0">
              <h6 class="active-text text-capitlize mt-3 mb-2 fw-500">
                Customer reviews
              </h6>
              <ng-container *ngFor="let review of reviews.slice(0,10)">
                <div class="col-12 p-0 mb-3">
                  <p>
                    <strong class="">{{review?.customerId?.name}}</strong>
                    <ngb-rating [max]="5" [rate]="review.customerReviewStars" [readonly]="true"></ngb-rating>
                  </p>
                  <p>{{ review.customerReviewComments }}</p>
                </div>
                <hr />
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Description & Review End -->

<!-- Why Us Start -->
<section class="my-4">
  <div class="container">
    <div class="row position-relative">
      <div class="bg-green py-2 mx-3 pd-w-u w-100"></div>
      <h5 class="
          text-white text-uppercase
          fw-600
          position-absolute
          top-0
          start-0
          end-0
          text-center
          mt-2
        ">
        why us?
      </h5>
    </div>
    <div class="row bg-inactive border rounded mx-0">
      <div class="d-flex justify-content-between w-100 p-3 why-card-mob">
        <div class="text-center my-2">
          <img src="../../../assets/icons/yagna.png" class="size-60 rounded-circle p-2 bg-active p-2" />
          <p class="text-capitalize fn-1 fw-500">vedic pooja energization</p>
        </div>
        <div class="text-center my-2">
          <img src="../../../assets/icons/expert-advice.png" class="size-60 rounded-circle p-2 bg-active p-2" />
          <p class="text-capitalize fn-1 fw-500">
            experts advice & counselling
          </p>
        </div>
        <div class="text-center my-2">
          <img src="../../../assets/icons/safe-secure.png" class="size-60 rounded-circle p-2 bg-active p-2" />
          <p class="text-capitalize fn-1 fw-500">safe & secure</p>
        </div>
        <div class="text-center my-2">
          <img src="../../../assets/icons/verified-account.png" class="size-60 rounded-circle p-2 bg-active p-2" />
          <p class="text-capitalize fn-1 fw-500">verified experts</p>
        </div>
        <div class="text-center my-2">
          <img src="../../../assets/icons/worldwide-network.png" class="size-60 rounded-circle p-2 bg-active p-2" />
          <p class="text-capitalize fn-1 fw-500">worldwide network</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Why Us End -->

<!-- New Product Start-->
<section class="my-5 bg-inactive py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-12">
        <div class="card border-0 bg-secondary p-3">
          <div class="card-header bg-secondary pb-1 border-0">
            <h4 class="text-center text-white text-uppercase fw-600">
              Featured
            </h4>
          </div>
          <div class="card-body p-0 bg-secondary rounded-bottom" style="height: 12rem">
            <a [routerLink]="['/product-detail', featuredProduct?.product_slug]"><img
                *ngIf="featuredProduct?.product_image?.length > 0; else noimg" class="w-100 h-100 rounded-top"
                [src]="getSanitizeUrl(featuredProduct?.product_image[0])" alt="..." />
              <ng-template #noimg>
                <img class="w-100 h-100" src="../../../assets/images/kundli.png" alt="..." />
              </ng-template>
            </a>
          </div>
          <div class="
              card-footer
              bg-active
              shadow-3
              border
              px-2
              pt-1
              pb-2
              d-flex
              flex-column
              rouned-bottom
            ">
            <h5 class="line-size-1 fn-2 fw-600 text-captilize mt-1 text-black">
              {{ featuredProduct?.productname }}
            </h5>

            <ng-container *ngIf="featuredProduct?.is_direct_price; else AstrologerPrice">
              <h6 class="text-center fw-400 active-text">
                {{
                getCurrentPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                &nbsp;<del class="inactive-text" *ngIf="featuredProduct?.is_offer_applied">{{
                  getOrginialPrice(featuredProduct)
                  | currency: (code ? "INR" : "USD"):"symbol-narrow"
                  }}</del>&nbsp;
                <span class="text-black" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-container>
            <ng-template #AstrologerPrice>
              <h6 class="text-left fw-700 my-1 txt-green">
                {{
                  getPrice(featuredProduct)
                | currency: (code ? "INR" : "USD"):"symbol-narrow"
                }}
                <span class="txt-red" *ngIf="featuredProduct?.is_offer_applied">{{ featuredProduct?.offer_percentage
                  }}% OFF</span>
              </h6>
            </ng-template>

            <a (click)="book(featuredProduct?._id)" type="button"
              class="btn fw-500 bg-secondary-2 rounded px-5 py-2 m-0 mt-2">
              Add To Cart
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-12 m-top">
        <div class="row new-product d-flex flex-column m-top">
          <h4 class="text-capitalize fw-600 text-center">related products</h4>
          <h4 class="text-center"><span></span></h4>
          <a class="nav-left rounded cus-border-secondary-1 position-absolute">
            <i class="fi fi-angle-left"></i>
          </a>
          <a class="nav-right rounded cus-border-secondary-1 position-absolute">
            <i class="fi fi-angle-right"></i>
          </a>
        </div>
        <div class="row mt-2 pl-2">
          <swiper [config]="relatedProduct" class="detail-swiper">
            <ng-template swiperSlide *ngFor="let latest of latestProduct">
              <div class="product-grid rounded">
                <div class="product-image">
                  <a [routerLink]="['/product-detail', latest.product_slug]" class="image">
                    <img class="pic-1 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                    <img class="pic-2 rounded" [src]="getSanitizeUrl(latest.product_image[0])" />
                  </a>
                  <span class="product-sale-label fn-1 fw-500">{{ latest.offer_percentage }}% OFF</span>
                </div>
                <div class="product-content">
                  <h3 class="line-size-1 fw-500 fn-1 text-capitalize text-black">
                    <a [routerLink]="['/product-detail', latest.product_slug]">{{ latest.productname }}</a>
                  </h3>
                  <div class="price fn-1 fw-500">
                    {{
                    getCurrentPrice(latest)
                    | currency: (code ? "INR" : "USD"):"symbol-narrow"
                    }}
                    <span>{{
                      getOrginialPrice(latest)
                      | currency: (code ? "INR" : "USD"):"symbol-narrow"
                      }}</span>
                  </div>
                  <div class="product-button-group">
                    <a (click)="book(latest._id)" class="add-to-cart fn-1 fw-500 text-uppercase rounded"><i class="fa fa-shopping-bag"></i>
                      &nbsp;ADD TO CART</a>
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- New Product End-->

<!-- Faq Start -->
<section class="">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12 faq">
        <div class="border rounded px-4 py-3">
          <h4 class="text-uppercase fw-600 mt-3">Why Should I Buy From You?</h4>
          <ul class="why-talk-sec">
            <li>
              We are the best Astroshop around! Not only do we have a wide selection of high-quality Astroshop products,
              we love doing what we do and that makes all the difference.
            </li>
            <li>
              We also offer unbeatable prices and outstanding customer service.
            </li>
            <li>
              A dedicated team of gemologists and occultists selects and verifies the quality of each and every article,
              followed by a third party independent laboratory testing wherever applicable.
            </li>
            <li>
              Our Online Poojas are conducted by a team of experienced Brahmins who have devoted their lives to
              karmakanda.
            </li>
            <li>Each and every article is fully energized and cleansed through Poojans and only then dispatched to you.
            </li>
            <li>Our goal is to be your one-stop shop for all things Astroshop, and we're confident that we can provide
              you with everything you need.
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-12 faq m-top">
        <div class=" border rounded px-4 py-3">
          <h4 class="text-uppercase fw-600 mt-3">frequenty asked questions</h4>
          <!-- <ul class="why-talk-sec">
            <li>
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below for those interested. Sections 1.10.32 and
              1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
              reproduced in their exact original form, accompanied by English
              versions from the 1914 translation by H. Rackham.
            </li>
            <li>
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below for those interested. Sections 1.10.32 and
              1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
              reproduced in their exact original form, accompanied by English
              versions from the 1914 translation by H. Rackham.
            </li>
            <li>
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below for those interested. Sections 1.10.32 and
              1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
              reproduced in their exact original form, accompanied by English
              versions from the 1914 translation by H. Rackham.
            </li>
            <li>
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below for those interested. Sections 1.10.32 and
              1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
              reproduced in their exact original form, accompanied by English
              versions from the 1914 translation by H. Rackham.
            </li>
          </ul> -->
          <dl class="faq-dl">
            <dt>What is the approximate delivery time?
            </dt>
            <dd>
              <p>For physical products, it usually takes around 5-7 working days for the article to be delivered. Custom
                articles may take some more time.
              </p>
              <p>For Online Poojas and reports which are customized for you, the time taken for each is available on the
                product pages.
              </p>
            </dd>
            <dt>Can I trust the quality provided by AstroRiver?
            </dt>
            <dd>We have been around for more than 3 generations and have evolved into an online platform for the past
              20+ years. We assure you that the expertise and experience passed on by these three generations is
              irreplaceable and guaranteed to provide you with utmost satisfaction.
            </dd>
            <dt>What If I am not satisfied with the product?
            </dt>
            <dd>
              <p>If you are not satisfied with the physical product that you have received, please contact our customer
                service within 3 days of receiving the product and return the article in its original packaging along
                with the bill. We will send you a replacement free of cost. We do not provide cash refunds.
              </p>
              <p>In the case of digital products like online poojas or customized physical products, we have a no refund
                policy as the product is customized according to your planetary positions and provided to you
                accordingly after necessary modifications.
              </p>
            </dd>
          </dl>
          <a [routerLink]="['/faq']">See All FAQs</a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Faq End -->

<!-- Banner Start-->
<section class="my-4">
  <div class="container">
    <img src="../../../assets/images/astro/banner.png" class="w-100" />
  </div>
</section>
<!-- Banner End-->

<!-- Call Chat Box start -->
<section class="">
  <div class="container">
    <div class="row m-0">
      <div class="col-md-6 col-12 p-0 pr-2 cursor-pointer" [routerLink]="['/call-astro-list']">
        <div class="col-12 call-card position-relative">
          <div class="mask"></div>
          <img src="../../../assets/images/product/talk.jpg" class="w-100" />
          <div class="d-flex flex-column position-absolute">
            <span class="material-icons-round"> phone_in_talk </span>
            <h4 class="fw-500 text-capitalize text-white position-absolute">
              Talk to experts
            </h4>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 p-0 pl-2 m-left m-top cursor-pointer" [routerLink]="['/chat-astro-list']">
        <div class="col-12 chat-card position-relative">
          <div class="mask"></div>
          <img src="../../../assets/images/product/chat.jpg" class="w-100" />
          <div class="d-flex flex-column position-absolute">
            <span class="material-icons-round"> chat </span>
            <h4 class="fw-500 text-capitalize text-white position-absolute">
              chat with experts
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Call Chat Box end -->

<app-footer></app-footer>