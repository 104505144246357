import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/service/product/product.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-subcategory-product',
  templateUrl: './subcategory-product.component.html',
  styleUrls: ['./subcategory-product.component.css'],
})
export class SubcategoryProductComponent implements OnInit {
  BaseUrl = '';
  dataList: any = [];
  id: any;
  categoryId: any;
  categoryName: any;
  subcategoryName: any;

  constructor(
    private product: ProductService,
    private userdata: UserdataService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private trusturl: DomSanitizer,
    @Inject('BASE_IMAGE_URL') _imageurl: any
  ) {
    this.BaseUrl = _imageurl;
  }
  code: any;
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getProductList();
    this.code = this.userdata.isIndia();
    //console.log(this.code);
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url);
  }

  getProductList() {
    this.spinner.show();
    this.product
      .getProductByCategory({ productsubcategoryId: this.id, status: true })
      .subscribe(
        (res: any) => {
          // console.log(res)
          this.dataList = res.data;
          this.categoryId = this.dataList[0]?.productcategoryId?._id;
          this.categoryName = this.dataList[0]?.productcategoryId?.categoryname;
          this.subcategoryName =
            this.dataList[0]?.productsubcategoryId?.subcategoryname;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
  /**
   * Search Product
   */
  searchProduct(event: any) {
    var val = event.target.value;
    if (val == '' || val == null || typeof val == 'undefined') {
      this.getProductList();
    } else {
      this.spinner.show();
      this.product
        .searchProduct({ productsubcategoryId: this.id, name: val })
        .subscribe(
          (res: any) => {
            // console.log(res)
            this.dataList = res.data;
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
          }
        );
    }
  }
  book(id: any) {
    if (this.userdata.getToken() == (null || '')) {
      this.toast.error('Please Login to use Service', 'Info');
      this.spinner.hide();
      sessionStorage.setItem('product-detail', id);
      return;
    } else {
      this.router.navigate(['/order-product', id]);
    }
  }
}
