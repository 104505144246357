<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100 text-capitalize">My Addresses</h3>
      <ul class="
          d-none d-lg-flex
          fw-500
          text-white
          w-100
          float-right
          justify-content-end
        ">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>
        <li class="text-capitalize text-white">My Address</li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section class="mt-lg-5 mt-5">
  <div class="container">
    <!-- <div class="row">
      <h4 class="text-capitalize">Addresses</h4>
    </div> -->
    <div class="row">
      <div class="col-md-3">
        <app-customer-sidebar></app-customer-sidebar>
      </div>
      <div class="col-md-9">
        <div class="row d-flex add-new-btn justify-content-end">
          <button class="btn  py-2 px-3 rounded shadow-0 bg-secondary add-new-btn text-white" (click)="addAddress()">
            Add New Address
          </button>
        </div>
        <div class="row mt-2">
          <ngx-datatable class="material cust-table" [rows]="addressList" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" [rowHeight]="'auto'" [scrollbarV]="false" [limit]="10" [sorts]="[{ dir: 'desc' }]">
            <ngx-datatable-column [flexGrow]="2" name="Name" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="Phone" prop="phone">
              <ng-template let-row="row" ngx-datatable-cell-template>
                +{{ row.countryCodePhoneNum }}
                {{ row.phoneNum }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Address" prop="flatNo">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.flatNo }}, {{ row.locality }}, {{ row.pinCode }}<br />
                <span *ngIf="row.landmark?.length > 0">
                  <strong>Landmark:</strong>{{ row.landmark }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="State" prop="state">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.state }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [flexGrow]="1" name="Country" prop="country">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.country }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="3" name="Date" prop="createdAt">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.createdAt | date: "medium" }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="Action" prop="action">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a class="btn  shadow-0 text-white btn-sm bg-secondary  rounded fw-400"
                  (click)="openDialog(row._id)">Edit</a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>