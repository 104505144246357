import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlogsService } from 'src/app/service/blogs/blogs.service';

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.css']
})
export class BlogCategoriesComponent implements OnInit {

  id:any
  name:any
  BaseUrl:any
  constructor(private route: ActivatedRoute,  private spinner: NgxSpinnerService,
    private blogsService:BlogsService,
    private trusturl: DomSanitizer,
    private toast: ToastrService,
    @Inject('BASE_IMAGE_URL') _imageurl: any) {
      this.BaseUrl = _imageurl
     }

  ngOnInit(): void {
    this.route.params.subscribe(paramMap =>{
      this.id = paramMap['id']
      this.name = paramMap['name']
      this.getCategoryBlogs()
    })
  }

  categoryWiseBlog:any = []
  getCategoryBlogs(){
    this.spinner.show() 
    var obj:any = {isApproved: true,status :true , isFeaturedRequired:true}
    obj.categoryId = this.id
    this.blogsService.getAllBlogs(obj).subscribe(
      (res: any) => {
        this.categoryWiseBlog = res.categoryWise
        // console.log('res is ', res)
        // console.log('blogs are ', this.categoryWiseBlog)
        this.spinner.hide()
      }, err => {
        this.toast.error(err.error.message, 'Alert')
        this.spinner.hide()
      }
    )
  }

  public getSanitizeUrl(url: string) {
    return this.trusturl.bypassSecurityTrustUrl(this.BaseUrl + url)
  }
 

}
