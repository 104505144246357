import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from 'src/app/service/open-api/open-api.service';

@Component({
  selector: 'app-match-making',
  templateUrl: './match-making.component.html',
  styleUrls: ['./match-making.component.css']
})
export class MatchMakingComponent implements OnInit {

  formData: any = '';
  currentMainTab: any = 'form';
  searchData: any;
  today:any;
  constructor(private openService: OpenApiService, private spinner: NgxSpinnerService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.today = moment().format('YYYY-MM-DD');
  }

  formGroup: FormGroup = new FormGroup({
    boy_dob: new FormControl('', Validators.required),
    boy_tob: new FormControl('', Validators.required),
    boy_lat: new FormControl('', Validators.required),
    boy_lon: new FormControl('', Validators.required),
    boy_tz: new FormControl('', Validators.required),
    boy_name: new FormControl('', Validators.required),
    girl_dob: new FormControl('', Validators.required),
    girl_tob: new FormControl('', Validators.required),
    girl_lat: new FormControl('', Validators.required),
    girl_lon: new FormControl('', Validators.required),
    girl_tz: new FormControl('', Validators.required),
    girl_name: new FormControl('', Validators.required),
  })

  submit() {
    this.formData = this.formGroup.value
    this.searchData = {
      boy_dob: moment(this.formData?.boy_dob).format('DD/MM/yyyy'),
      boy_tob: this.formData?.boy_tob,
      boy_lat: this.formData?.boy_lat,
      boy_lon: this.formData?.boy_lon,
      boy_tz: this.formData?.boy_tz,
      girl_dob: moment(this.formData?.girl_dob).format('DD/MM/yyyy'),
      girl_tob: this.formData?.girl_tob,
      girl_lat: this.formData?.girl_lat,
      girl_lon: this.formData?.girl_lon,
      girl_tz: this.formData?.girl_tz,
    }
    this.getMatchMaking('Aggregate')
  }

  getMatchMaking(data: any) {
    this.currentMainTab = data
    if (this.currentMainTab == 'Aggregate') {
      this.searchData.apiaddress = "matching/aggregate-match"
    }
    if (this.currentMainTab == 'Details' || this.currentMainTab == 'Ashtakoot') {
      this.searchData.apiaddress = "matching/ashtakoot-with-astro-details"
    }
    if (this.currentMainTab == 'Dashakoot' || this.currentMainTab == 'Starmatching') {
      this.searchData.apiaddress = "matching/dashakoot"
    }
    if (this.currentMainTab == 'Papasamya') {
      this.searchData.apiaddress = "matching/papasamaya-match"
    }
    this.getDataFromApi()
  }

  boyLocationList: any;
  girlLocationList: any;
  selectedBoyLocation: any
  selectedGirlLocation: any
  getLocation(event: any, data: any) {
    if (data == 'boy') {
      this.selectedBoyLocation = ''
      if (event.target.value.length < 3) {
        return
      }
      this.openService.getGeoLocation({ apiaddress: 'utilities/geo-search', islive: true, city: event.target.value }).subscribe(
        (res: any) => {
          this.boyLocationList = res.data
        }, err => {

        }
      )
    }
    if (data == 'girl') {
      this.selectedGirlLocation = ''
      if (event.target.value.length < 3) {
        return
      }
      this.openService.getGeoLocation({ apiaddress: 'utilities/geo-search', islive: true, city: event.target.value }).subscribe(
        (res: any) => {
          this.girlLocationList = res.data
        }, err => {

        }
      )
    }
  }

  selectLocation(event: any, data: any) {
    if (data == 'boy') {
      this.selectedBoyLocation = event
      this.formGroup.patchValue({ boy_lat: this.selectedBoyLocation?.coordinates[1] })
      this.formGroup.patchValue({ boy_lon: this.selectedBoyLocation?.coordinates[0] })
      this.formGroup.patchValue({ boy_tz: this.selectedBoyLocation?.tz })
    }
    if (data == 'girl') {
      this.selectedGirlLocation = event
      this.formGroup.patchValue({ girl_lat: this.selectedGirlLocation?.coordinates[1] })
      this.formGroup.patchValue({ girl_lon: this.selectedGirlLocation?.coordinates[0] })
      this.formGroup.patchValue({ girl_tz: this.selectedGirlLocation?.tz })
    }

    //this.kundliForm.patchValue({place:this.selectedLocation})
  }
  matchResponse: any = ''
  getDataFromApi() {
    this.spinner.show()
    this.openService.getGeoLocation(this.searchData).subscribe(
      (res: any) => {
        this.matchResponse = res.data
        if (this.currentMainTab == 'Dashakoot') {
          this.searchData.apiaddress = "matching/rajju-vedha-details"
          this.getDoshaApi()
        }
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message)
      }
    )
  }
  dosha:any;
  getDoshaApi() {
    this.spinner.show()
    this.openService.getGeoLocation(this.searchData).subscribe(
      (res: any) => {
        this.dosha = res.data
        this.spinner.hide()
      }, err => {
        this.spinner.hide()
        this.toast.error(err.error.message)
      }
    )
  }

}
