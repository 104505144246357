<app-header></app-header>
<ngx-spinner></ngx-spinner>
<!-- BradCrumbs Start -->
<section class="">
  <div class="product-crumb-bg w-100 py-4">
    <div class="container d-flex">
      <h3 class="fw-600 fn-3 text-white w-100">Coming Soon</h3>
      <ul class="fw-500 text-white w-100 float-right d-flex justify-content-end">
        <li>
          <a [routerLink]="['/']" class="text-white">Home</a>
        </li>
        <li>
          <span class="fn-2 material-icons-round bottom-align">
            arrow_forward_ios
          </span>
        </li>

        <li><a (click)="goBack()"> Coming Soon</a></li>
      </ul>
    </div>
  </div>
</section>
<!-- BradCrumbs End -->
<section>
  <div class="container position-relative soon">
    <img src="../../../assets/images/coming-soon.png" class="w-100" />
    <div class="
        position-absolute
        top-0
        bottom-0
        d-flex
        start-0
        end-0
        justify-content-center
      ">
      <div class="col-md-8 align-self-center mb-5 pb-5 px-5">
        <h3 class="fw-500 text-center ap-text">
          Apologies! Our team is working day and night to provide, this feature ASAP.
        </h3>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>