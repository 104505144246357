import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class JsService {

  constructor() { }

  callJsClass() {
    $.getScript("../../../assets/js/jquery.menu-aim.js", function () {
      $('.house_toggle').on('click', function (event: any) {
        event.preventDefault();
        toggleNav();
      });
      $.getScript("../../../assets/js/custom.js", function () { });
      $.getScript("../../../assets/js/custom.js", function () {
        //console.log('func')
        $(window).on('scroll', function () {
          //console.log('okkkkkk');
          var scrolled = $(window).scrollTop(); if (scrolled > 300) $('.go-top').addClass('active'); if (scrolled < 300) $('.go-top').removeClass('active');
        });
        $('.go-top').on('click', function () {
          $("html, body").animate({ scrollTop: "0" }, 100);
        });
      });
      function toggleNav() {
        var navIsVisible = (!$('.cd-dropdown').hasClass('dropdown-is-active')) ? true : false;
        $('.cd-dropdown').toggleClass('dropdown-is-active', navIsVisible);
        $('.house_toggle').toggleClass('dropdown-is-active', navIsVisible);
        if (!navIsVisible) {
          $('.cd-dropdown').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
            $('.has-children ul').addClass('is-hidden');
            $('.move-out').removeClass('move-out');
            $('.is-active').removeClass('is-active');
          });
        }
      }
    });
  }
}