import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderServicesService } from 'src/app/service/order/order-services.service';
import { UserdataService } from 'src/app/service/userdata/userdata.service';

@Component({
  selector: 'app-store-order-list',
  templateUrl: './store-order-list.component.html',
  styleUrls: ['./store-order-list.component.css'],
})
export class StoreOrderListComponent implements OnInit {
  cur = 'INR';
  dataList: any = [];
  @ViewChild(DatatableComponent) myFilterTable: DatatableComponent | any;

  constructor(
    private userdata: UserdataService,
    private orderService: OrderServicesService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getStoreOrders();
    if (!this.userdata.isIndia()) {
      this.cur = 'USD';
    }
  }

  getStoreOrders() {
    this.spinner.show();
    this.orderService
      .showStoreOrders({ customerId: this.userdata.getCustomerId() })
      .subscribe(
        (res: any) => {
          this.dataList = res.data;
          this.spinner.hide();
          //console.log('store', res);
          //console.log(res)
        },
        (err) => {
          this.spinner.hide();
          //console.log(err)
        }
      );
  }
}
